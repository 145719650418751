import { IUserObject } from 'store/auth/auth.types';

export const getErrorText = (error: any) => {
  const { response } = error;
  const { data } = response;
  const message = Array.isArray(data.message) ? data.message[0] : data.message;
  console.error(message);
  return message;
};
export const getUserFullName = (user: IUserObject | undefined) =>
  user ? `${user.firstName} ${user.lastName}`.trim() : '';
