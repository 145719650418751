import { createSelector } from 'reselect';
import { ILeadState } from './lead.types';

export const leadSelector = (state: any) => state.lead;

// Lead Create State
export const leadCreateStateSelector = createSelector(
  leadSelector,
  (lead: ILeadState) => lead.leadCreateState,
);

// Lead Create Error
export const leadCreateErrorSelector = createSelector(
  leadSelector,
  (lead: ILeadState) => lead.leadCreateError,
);
