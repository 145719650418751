import { ThemeConfig } from 'antd';

export const theme: ThemeConfig = {
  components: {
    Select: {
      colorBgContainer: '#F4F7F8',
      borderRadius: 4,
      lineWidth: 0,
      fontWeightStrong: 600,
      controlHeight: 40,
    },
  },
};
