import React from 'react';
import styles from './Box.module.sass';

interface IBoxProps {
  children: any;
}

const Box: React.FC<IBoxProps> = ({ children }) => <div className={styles.box}>{children}</div>;

export default Box;
