import { all, call, put, takeLatest } from 'redux-saga/effects';
import PoolTenderOrganizationAPI from 'services/pool-tender-organization.service';
import { Organization } from 'store/intermediate/intermediate.types';
import {
  poolOrganizationsDeleteError,
  poolOrganizationsDeleteSuccess,
  poolOrganizationsGetError,
  poolOrganizationsGetSuccess,
  poolOrganizationsUpdateError,
  poolOrganizationsUpdateSuccess,
} from './pool-tender-organization.actions';
import {
  DeletePoolOrganizationsAction,
  DELETE_POOL_ORGANIZATIONS,
  GetPoolOrganizationsAction,
  GET_POOL_ORGANIZATIONS,
  UpdatePoolOrganizationsAction,
  UPDATE_POOL_ORGANIZATIONS,
} from './pool-tender-organization.types';

export function* getPoolOrgnizations(action: GetPoolOrganizationsAction) {
  try {
    const result: Organization[] = yield call(
      PoolTenderOrganizationAPI.getPoolOrganizationsByPoolId,
      action.payload,
    );
    if (result) {
      yield put(poolOrganizationsGetSuccess(result));
    } else {
      yield put(poolOrganizationsGetError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(poolOrganizationsGetError(message));
  }
}

export function* updatePoolOrgnizations(action: UpdatePoolOrganizationsAction) {
  try {
    const result: Organization = yield call(
      PoolTenderOrganizationAPI.updatePoolOrganizationsByPoolId,
      action.payload.id,
      action.payload.organization,
    );
    if (result) {
      yield put(poolOrganizationsUpdateSuccess(result));
    } else {
      yield put(poolOrganizationsUpdateError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(poolOrganizationsUpdateError(message));
  }
}

export function* deletePoolOrgnizations(action: DeletePoolOrganizationsAction) {
  try {
    const result: {
      generatedMaps: any[];
      raw: any[];
      affected: number;
    } = yield call(PoolTenderOrganizationAPI.deletePoolOrganizationsByPoolId, action.payload);
    if (result.affected) {
      yield put(poolOrganizationsDeleteSuccess(action.payload));
    } else {
      yield put(poolOrganizationsDeleteError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(poolOrganizationsDeleteError(message));
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_POOL_ORGANIZATIONS, getPoolOrgnizations),
    takeLatest(UPDATE_POOL_ORGANIZATIONS, updatePoolOrgnizations),
    takeLatest(DELETE_POOL_ORGANIZATIONS, deletePoolOrgnizations),
  ]);
}
