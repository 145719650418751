import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { FormInstance, Tag } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { CrudView } from 'components/layout/CrudView/CrudView';
import useLanguageChangeCallback from 'hooks/useLaguageChangeCallback';
import { IProduct, ProductStatus } from 'store/product/product.types';
import {
  productCreate,
  productDelete,
  productsGet,
  productUpdate,
} from 'store/product/product.actions';
import { productsGetStateSelector, productsSelector } from 'store/product/product.selector';
import { RequestState } from 'store/common.types';

import { IPool } from 'store/intermediate/intermediate.types';
import { EntityAction, FormLayout, RequestResult } from 'types/crud-view.types';
import { firstLetterUpper } from 'utils/commonUtils';

import { formatPayload, formatProduct, priceFieldFormatter } from 'utils/product';
import { FormBody } from './components/FormBody';
import styles from './Products.module.sass';
import { ProductName } from './components/ProductName';
import { useFilters } from '../Product Wizard/hooks/useFilter';
import { Filters } from '../Product Wizard/components/Filters/Filters';
import { formatPrice } from '../../../../../../utils/currencyUtils';

interface IProductsProps {
  pool: IPool;
}

const locizeKey = 'intermediate:myProjects:product';

export const Products: React.FC<IProductsProps> = ({ pool }) => {
  const { productId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [filtersPayload, onFilterChange, onSortChange] = useFilters({
    sortDirection: 1,
    sortField: 'createdAt',
  });

  const products = useSelector(productsSelector);
  const productsGetStatus = useSelector(productsGetStateSelector);

  const formattedProducts: IProduct[] = useMemo(
    () => products.map((product) => formatProduct(product)),
    [products],
  );

  const [form, setForm] = useState<FormInstance>();
  const fetchProducts = useCallback(() => {
    if (!productId) return;
    dispatch(productsGet(productId, filtersPayload));
  }, [productId, dispatch, filtersPayload]);
  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);
  useLanguageChangeCallback(fetchProducts);

  const onActionClick = (
    action: EntityAction,
    data: IProduct,
    onRequestDone?: (status: RequestResult) => void,
  ) => {
    const onActionSuccess = (status: RequestResult) => {
      dispatch(productsGet(productId!, filtersPayload));
      if (onRequestDone) {
        onRequestDone(status);
      }
    };

    switch (action) {
      case EntityAction.Create:
        dispatch(productCreate(productId!, formatPayload(data), onActionSuccess));
        break;
      case EntityAction.Edit:
        dispatch(productUpdate(data.id!, formatPayload(data), onActionSuccess));
        break;
      case EntityAction.Delete:
        dispatch(productDelete(data.id!, onRequestDone));
    }
  };

  const modalProps = {
    setForm: (value: FormInstance) => setForm(value),
    onSubmit: onActionClick,
    formBody: <FormBody form={form} />,
    width: '80vw',
    layout: FormLayout.VERTICAL,
    requiredMark: false,
  };

  const Columns: ColumnsType<IProduct> = [
    {
      title: t('intermediate:myProjects:product:table:reference'),
      dataIndex: 'referenceId',
      key: 'referenceId',
      defaultSortOrder: 'ascend',
      sorter: true,
    },
    {
      title: t('intermediate:myProjects:product:table:name'),
      dataIndex: 'title',
      key: 'product',
      width: '40%',
      ellipsis: {
        showTitle: false,
      },
      render: (_, product: IProduct) => <ProductName product={product} />,
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (status) => (
        <Tag className={clsx(styles.tag, status)}>
          {t(`intermediate:myProjects:product:status:${status}`)}
        </Tag>
      ),
    },
    {
      title: t('intermediate:myProjects:product:table:category'),
      dataIndex: 'category',
      key: 'category',
      render: (category) => firstLetterUpper(category),
    },
    {
      title: t('intermediate:myProjects:product:table:price'),
      dataIndex: 'prices',
      key: 'prices',
      render: (_, product) =>
        product.onRequestPrice
          ? product.onRequestPriceTitle
          : priceFieldFormatter(product.grossPrice, pool.country.currencySymbol),
    },
  ];

  const statusOptions = Object.keys(ProductStatus).map((key) => ({
    value: key.toLowerCase(),
    label: t(`intermediate:myProjects:product:status:${key.toLowerCase()}`),
  }));

  const filterRow = (
    <Filters
      options={statusOptions}
      filters={filtersPayload.filters}
      onFilterChange={onFilterChange}
      locizeKey={locizeKey}
      categoryFilter
    />
  );

  return (
    <CrudView<IProduct>
      tableLoading={productsGetStatus === RequestState.Loading}
      columns={Columns}
      entries={formattedProducts}
      locizeKey={locizeKey}
      createInitialValues={{
        localizedDescriptions: [
          {
            lang: pool.country.defaultLanguage,
            value: null,
          },
        ],
        localizedTitles: [
          {
            lang: pool.country.defaultLanguage,
            value: '',
          },
        ],
        localizedOnRequestPriceTitles: [
          {
            lang: pool.country.defaultLanguage,
            value: '',
          },
        ],
      }}
      modalProps={modalProps}
      filterRow={filterRow}
      onTableSort={onSortChange}
      onBackClick={() => navigate(`/my-projects/${pool.id}/settings`)}
      updateEntryFormat={(entry) => ({ ...entry, netPrice: formatPrice(entry.netPrice) })}
      backButton
      rowsSelectable
    />
  );
};
