import React, { useEffect, useState } from 'react';
import Input from 'antd/lib/input';
import Typography from 'antd/lib/typography';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BoxContent from 'components/layout/BoxContent/BoxContent';
import Button, { ButtonColor } from 'components/ui/Button/Button';
import TenderParticipationModal from 'components/static/TenderParticipationModal/TenderParticipationModal';
import { clientRequestAnswersGet } from 'store/client-request/client-request.actions';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import { RequestState } from 'store/common.types';
import { offerTransition, offerTransitionReset } from 'store/offer/offer.actions';
import { offerTransitionStateSelector } from 'store/offer/offer.selectors';
import { IOffer, OfferEventType, OfferState } from 'store/offer/offer.types';
import styles from './TenderParticipationPage.module.sass';

const TenderParticipationPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const clientRequest = useSelector(currentClientRequestSelector);
  const transitionState = useSelector(offerTransitionStateSelector);
  const [confirm, showConfirm] = useState(false);

  const [annotation, setAnnotation] = useState('');
  useEffect(() => {
    if (!transitionState) {
      return;
    }
    if (transitionState === RequestState.Success) {
      navigate('/installer/dashboard');
    }
  }, [transitionState, navigate]);

  useEffect(() => {
    return function cleanup() {
      dispatch(offerTransitionReset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!clientRequest || !clientRequest.id) {
      return;
    }
    dispatch(clientRequestAnswersGet(clientRequest.id));
    // eslint-disable-next-line
  }, [clientRequest]);

  const transitionOfferState = (event: OfferEventType) => {
    if (!clientRequest) {
      return; //TODO: Handle error
    }
    const offer: IOffer = {
      state: OfferState.None,
      annotation,
      clientRequest,
    };
    dispatch(offerTransition(event, offer));
  };

  return (
    <BoxContent title="Requirements summary" showBackButton={false} showNextButton={false}>
      <div className={styles.container}>
        <Typography.Title>{t('installerFlow:tenderParticipate:pageTitle')}</Typography.Title>
        <Typography.Text>{t('installerFlow:tenderParticipate:offer')}</Typography.Text>
        <Typography.Text>
          Use the spreadsheet below by filling the positions you need with the corresponding price
          or adding new positions.
        </Typography.Text>
        <Typography.Title>{t('installerFlow:tenderParticipate:commentField')}</Typography.Title>
        <Typography.Text>
          {t('installerFlow:tenderParticipate:commentFieldDescription')}
        </Typography.Text>
        <Input
          value={annotation}
          onChange={(event) => setAnnotation(event.target.value)}
          placeholder="Type here ... "
        ></Input>
        <div className={styles.participationButtons}>
          <Button
            className={styles.participateButton}
            type="primary"
            onClick={() => showConfirm(true)}
            color={ButtonColor.BLUE}
          >
            Save
          </Button>
          <Button
            className={styles.participateButton}
            type="primary"
            onClick={() => showConfirm(true)}
            color={ButtonColor.GREEN}
          >
            {t('common:buttons:continue')}
          </Button>
        </div>
      </div>
      <TenderParticipationModal
        open={confirm}
        onOk={() => {
          showConfirm(false);
          transitionOfferState(OfferEventType.INSTALLER_ACCEPTED);
        }}
        onCancel={() => showConfirm(false)}
        destroyOnClose={true}
        width={800}
      />
    </BoxContent>
  );
};

export default TenderParticipationPage;
