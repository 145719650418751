import { IAppState } from './app/app.types';
import { IAuthState } from './auth/auth.types';
import { IBundleState } from './bundle/bundle.types';
import { IClientRequestState } from './client-request/client-request.types';
import { IContactPersonState } from './contact-person/contact-person.types';
import { ICountryState } from './country/country.types';
import { IDocumentState } from './document/document.types';
import { IFeedbackState } from './feedback/feedback.types';
import { IInstallerState } from './installer/installer.types';
import { IIntermediateState } from './intermediate/intermediate.types';
import { ILeadState } from './lead/lead.types';
import { IOfferState } from './offer/offer.types';
import { IOrdersState } from './orders/orders.types';
import { IOrganizationDefaultSettingsState } from './organization/organization.types';
import { IPDFTemplateState } from './pdf-templates/pdf-templates.types';
import { IPoolTenderOrganizationState } from './pool-tender-organization/pool-tender-organization.types';
import { IProductComponentState } from './product-component/product-components.types';
import { IProductState } from './product/product.types';
import { IRegisterState } from './register/register.types';
import { ISurveysState } from './surveys/surveys.types';
import { IUserState } from './user/user.types';
import { IDeliveryDateModalState } from './delivery-date/delivery-date.module';

export enum RequestState {
  Loading,
  Saving,
  Success,
  Error,
  None,
}

export interface ISearchDto {
  sortField?: string;
  relations?: string[];
  limit?: number;
  offset?: number;
  filters?: any; //JSON string
  sortDirection?: -1 | 1;
}

export interface IFindWithRelations {
  id: string;
  relations?: string[];
}

export enum EntityApprovalState {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export interface IOption {
  value: any;
  label: string;
}

export type IGetResponse<T> = {
  items: T[];
  total: number;
  count: number;
};

export type IState = {
  app: IAppState;
  auth: IAuthState;
  clientRequest: IClientRequestState;
  offer: IOfferState;
  register: IRegisterState;
  intermediate: IIntermediateState;
  user: IUserState;
  installer: IInstallerState;
  lead: ILeadState;
  document: IDocumentState;
  poolTenderOrganization: IPoolTenderOrganizationState;
  feedback: IFeedbackState;
  bundle: IBundleState;
  contactPerson: IContactPersonState;
  pdfTemplate: IPDFTemplateState;
  country: ICountryState;
  surveys: ISurveysState;
  orders: IOrdersState;
  organization: IOrganizationDefaultSettingsState;
  productComponents: IProductComponentState;
  products: IProductState;
  deliveryDateModal: IDeliveryDateModalState;
};

export interface RouteParams {
  id: string;
}
