import React, { useState, useMemo } from 'react';
import moment from 'moment';
import { Col, Modal, ModalProps, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonColor } from 'components/ui/Button/Button';
import AnswerSelection from 'components/ui/AnswerSelection/AnswerSelection';
import OneValueDateTimePicker from 'components/ui/OneValueDateTimePicker/OneValueDateTimePicker';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from 'framework/constants';
import { updateOffer } from 'store/offer/offer.actions';
import { IOffer, OfferState } from 'store/offer/offer.types';
import styles from './Modals.module.sass';

interface Props extends ModalProps {
  offer?: IOffer | null;
  onCancel: () => void;
}

const OfferDatePickerModal: React.FC<Props> = ({ onCancel, open, offer }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentDate = new Date();
  const [time, setTime] = useState(moment(currentDate.toString()).format('HH:mm'));
  const [date, setDate] = useState(currentDate);
  const hasFinalTimestamp = !!offer?.finalHomeCheckTimestamp || !!offer?.finalInstallationTimestamp;
  const [question, setQuestion] = useState(hasFinalTimestamp);

  const onSelectTime = (time: string) => {
    setTime(time);

    let [hours, minutes] = time.split(':');
    let tempDate = new Date(date);

    tempDate.setHours(+hours, +minutes);
    setDate(tempDate);
  };

  const modalProps = {
    title: t('customerFlow:costCalculations:buttonSkipQuestionnaire'),
    visible: open,
    onCancel,
    centered: true,
    closable: true,
    footer: null,
    wrapClassName: styles.container,
  };

  const dateTimePickerProps = {
    date,
    time,
    onTimeClick: (time: string) => onSelectTime(time),
    onDayClick: (day: Date) => setDate(day),
  };

  const isHomeCheckPending = useMemo(
    () =>
      offer?.state === OfferState.OfferHomeCheckPending ||
      offer?.state === OfferState.OfferRemoteHomeCheckPending,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offer?.state],
  );

  const onSubmit = () => {
    if (offer) {
      const updateData = isHomeCheckPending
        ? { actualHomeCheckTimestamp: question ? offer.finalHomeCheckTimestamp : date }
        : { actualInstallationTimestamp: question ? offer.finalInstallationTimestamp : date };
      dispatch(
        updateOffer(offer.id!, updateData, (offer: IOffer) =>
          navigate(`/installer/order/${offer.id}/documentation`),
        ),
      );
    }
  };

  const renderQuestion = () => {
    let date = currentDate;
    if (isHomeCheckPending && offer?.finalHomeCheckTimestamp) {
      date = offer?.finalHomeCheckTimestamp;
    }
    if (offer?.state === OfferState.OfferAccepted && offer?.finalInstallationTimestamp) {
      date = offer?.finalInstallationTimestamp;
    }
    if (offer?.state === OfferState.InstallationPending && offer?.finalInstallationTimestamp) {
      date = offer?.finalInstallationTimestamp;
    }
    return t(
      `installerFlow:orderDetails:offerDateModal:${
        isHomeCheckPending ? 'homeCheckQuestion' : 'installationQuestion'
      }`,
      {
        date: moment(date).format(`${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`),
      },
    );
  };

  return (
    <Modal {...modalProps}>
      <p className={styles.text}>
        {hasFinalTimestamp
          ? renderQuestion()
          : t(
              `installerFlow:orderDetails:offerDateModal:${
                isHomeCheckPending
                  ? 'homeCheckOffTendergyQuestion'
                  : 'installationOffTendergyQuestion'
              }`,
            )}
      </p>
      {hasFinalTimestamp && (
        <Row gutter={[16, 0]} className={styles.question}>
          <Col span={12}>
            <AnswerSelection
              checked={question}
              title={t('installerFlow:orderDetails:offerDateModal:yes')}
              onClick={() => setQuestion(true)}
              fluid
            />
          </Col>
          <Col span={12}>
            <AnswerSelection
              checked={!question}
              title={t('installerFlow:orderDetails:offerDateModal:no')}
              onClick={() => setQuestion(false)}
              fluid
            />
          </Col>
        </Row>
      )}
      {(!question || !hasFinalTimestamp) && <OneValueDateTimePicker {...dateTimePickerProps} />}
      <div className={styles.buttonActions}>
        <Button type="primary" color={ButtonColor.GREEN} onClick={onSubmit}>
          {t(
            `installerFlow:orderDetails:offerDateModal:confirm${
              isHomeCheckPending ? 'HomeCheck' : 'Installation'
            }`,
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default OfferDatePickerModal;
