export interface IAppState {
  activeStep: number;
  activeSubStep: number;
  subStepsHidden: boolean;
  globalError: string;
}

export const CHANGE_STEP = 'CHANGE_STEP';
export const SET_SUB_STEPS_HIDDEN = 'SET_SUB_STEPS_HIDDEN';
export const SET_GLOBAL_ERROR = 'SET_GLOBAL_ERROR';

// Change step
export interface ChangeStepAction {
  type: typeof CHANGE_STEP;
  payload: {
    activeStep: number;
    activeSubStep: number;
  };
}

// Change subSteps visibility
export interface SetSubStepsHiddenAction {
  type: typeof SET_SUB_STEPS_HIDDEN;
  payload: {
    subStepsHidden: boolean;
  };
}

export interface SetGlobalError {
  type: typeof SET_GLOBAL_ERROR;
  payload: { error: string };
}

export type AppActionTypes = ChangeStepAction | SetSubStepsHiddenAction | SetGlobalError;
