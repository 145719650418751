import React, { useContext, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import { useSelector } from 'react-redux';
import { ReactComponent as DoneIcon } from 'assets/icons/check-white.svg';
import { CustomerDashboardContext } from 'context/customerDashboard.context';
import { ClientRequestState } from 'store/client-request/client-request.types';
import { feedbackListSelector } from 'store/feedback/feedback.selectors';
import { FlowSteps } from 'types/customer-flow.types';
import styles from './ToDo.module.sass';
import { ClientRequestActionRequiredStates, OfferActionRequiredState } from '../widget.types';

const ToDo = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { step, request } = useContext(CustomerDashboardContext);
  const { t } = useTranslation();

  const feedbackList = useSelector(feedbackListSelector);

  const steps = useMemo(() => {
    if (isExpanded) return FlowSteps;
    const startStep = step > 0 ? step - 1 : step;

    return FlowSteps.slice(
      FlowSteps.length - 1 === step ? startStep - 1 : startStep,
      startStep + 3,
    );
  }, [isExpanded, step]);

  const actionRequiered = useMemo(() => {
    if (!request?.state) return false;
    if (request.state === ClientRequestState.OfferSelectionHomeCheck && step === 5) return false;
    if (request.state === ClientRequestState.Done && !feedbackList.length) return true;
    if (
      ClientRequestActionRequiredStates.includes(request?.state) &&
      request?.offers?.some((offer) => OfferActionRequiredState.includes(offer.state!))
    )
      return true;
    return false;
  }, [request, step, feedbackList.length]);

  const isDone = useMemo(
    () => request?.state === ClientRequestState.Done && feedbackList.length,
    [request, feedbackList.length],
  );

  return (
    <div className={styles.todoContainer}>
      {steps.map((item) => (
        <div
          key={item.key}
          className={clsx(styles.stepContainer, {
            [styles.done]: item.step < step || isDone,
            [styles.current]: item.step === step && !isDone,
            [styles.required]: actionRequiered,
          })}
        >
          <div className={styles.progressCircle}>
            {item.step < step || isDone ? <DoneIcon /> : null}
          </div>
          {t(`customerFlow:dashboard:toDo:${item.key}:title`)}
        </div>
      ))}
      <Button
        onClick={() => setIsExpanded(!isExpanded)}
        type="text"
        className={styles.continueButton}
        icon={isExpanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
        block
      />
    </div>
  );
};

export default ToDo;
