import { RequestState } from 'store/common.types';

export interface IOrganizationDefaultSettings {
  appointmentOffTendergy: boolean;
  homeCheckPrice?: number;
  vat?: number;
  currency?: string;
  currencySymbol?: string;
  offerValidityDays?: number;
}

export interface IOrganizationDefaultSettingsState {
  defaultSettings: IOrganizationDefaultSettings | null;
  getDefaultSettingsState: RequestState | null;
  getDefaultSettingsError: string | null;

  saveDefaultSettingsState: RequestState | null;
  saveDefaultSettingsError: string | null;
}

export const GET_DEFAULT_SETTINGS = 'GET_DEFAULT_SETTINGS';
export const GET_DEFAULT_SETTINGS_SUCCESS = 'GET_DEFAULT_SETTINGS_SUCCESS';
export const GET_DEFAULT_SETTINGS_ERROR = 'GET_DEFAULT_SETTINGS_ERROR';

export const SAVE_DEFAULT_SETTINGS = 'SAVE_DEFAULT_SETTINGS';
export const SAVE_DEFAULT_SETTINGS_SUCCESS = 'SAVE_DEFAULT_SETTINGS_SUCCESS';
export const SAVE_DEFAULT_SETTINGS_ERROR = 'SAVE_DEFAULT_SETTINGS_ERROR';

export interface GetDefaultSettingsAction {
  type: typeof GET_DEFAULT_SETTINGS;
}
export interface GetDefaultSettingsSuccessAction {
  type: typeof GET_DEFAULT_SETTINGS_SUCCESS;
  payload: IOrganizationDefaultSettings;
}

export interface GetDefaultSettingsErrorAction {
  type: typeof GET_DEFAULT_SETTINGS_ERROR;
  payload: any;
}

export interface SaveDefaultSettingsAction {
  type: typeof SAVE_DEFAULT_SETTINGS;
  payload: IOrganizationDefaultSettings;
}

export interface SaveDefaultSettingsSuccessAction {
  type: typeof SAVE_DEFAULT_SETTINGS_SUCCESS;
  payload: IOrganizationDefaultSettings;
}

export interface SaveDefaultSettingsErrorAction {
  type: typeof SAVE_DEFAULT_SETTINGS_ERROR;
  payload: any;
}

export type OrganizationDefaultSettingsActionTypes =
  | GetDefaultSettingsAction
  | GetDefaultSettingsSuccessAction
  | GetDefaultSettingsErrorAction
  | SaveDefaultSettingsAction
  | SaveDefaultSettingsSuccessAction
  | SaveDefaultSettingsErrorAction;
