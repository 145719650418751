import React, { useState } from 'react';
import { PhoneOutlined, UserOutlined, MailOutlined } from '@ant-design/icons/lib';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Avatar, Modal } from 'antd';
import contactIcon from 'assets/icons/contact.svg';
import Icon from 'components/ui/Icon/Icon';
import { IPool } from 'store/intermediate/intermediate.types';
import {
  clientRequestPoolDataPrivacy,
  clientRequestPoolImprint,
  currentClientRequestSelector,
} from 'store/client-request/client-request.selectors';
import styles from './ContactModal.module.sass';

type ProfileValue = {
  name: string;
  companyName: string;
  phone: string;
  email: string;
};

export interface IContactModalProps {
  pool?: IPool | null;
  value?: ProfileValue;
}

const ContactModal: React.FC<IContactModalProps> = ({ pool }) => {
  const [isVisible, setIsVisible] = useState(false);
  const request = useSelector(currentClientRequestSelector);
  const crPoolImprint = useSelector(clientRequestPoolImprint);
  const crPoolDataPrivacy = useSelector(clientRequestPoolDataPrivacy);
  const { t } = useTranslation(['welcomePage']);

  const isContactPersons = pool && pool.contactPersons && !!pool.contactPersons.length;

  const handleCancel = () => {
    setIsVisible(false);
  };

  const modalProps = {
    closable: false,
    footer: null,
    mask: false,
    width: 'auto',
    height: 'auto',
    open: isVisible,
    transitionName: '',
    maskTransitionName: '',
    className: styles.container,
    onCancel: handleCancel,
  };

  const renderContactPersons = () => (
    <>
      <div className={styles.title}>{t('welcomePage:contact:person')}</div>
      {pool?.contactPersons?.map((person, index) => (
        <div key={index} className={styles.person}>
          <span>{person.roleDescription}</span>
          <span>{person.name}</span>
          {person.phone && (
            <span className={styles.contacts}>
              <PhoneOutlined />
              <a href={`tel:${person.phone}`}>{person.phone}</a>
            </span>
          )}
          {person.email && (
            <span className={styles.contacts}>
              <MailOutlined />
              <a href={`mailto:${person.email}`}>{person.email}</a>
            </span>
          )}
        </div>
      ))}
    </>
  );

  return (
    <>
      <div onClick={() => setIsVisible(!isVisible)} className={styles.cookiesButton}>
        <Icon icon={contactIcon} className={styles.icon} />
      </div>
      <Modal {...modalProps}>
        <div>
          {isContactPersons && renderContactPersons()}
          <div className={styles.generalInformation}>
            <div className={styles.section}>
              <span className={styles.title}>{t('welcomePage:contact:pool')}</span>
              <span className={styles.name}>{pool?.name}</span>
              <span className={styles.title}>{t('welcomePage:contact:requestId')}</span>
              <span className={styles.name}>{request?.displayId}</span>
            </div>
            <div className={styles.section}>
              <span className={styles.title}>{t('welcomePage:contact:poolOperator')}</span>
              <Avatar
                size="large"
                shape="square"
                src={pool?.intermediateOrganization?.logo || <UserOutlined />}
              />
              <span className={styles.name}>{pool?.intermediateOrganization?.name}</span>
            </div>
          </div>
          <div className={styles.privacyContainer}>
            {crPoolDataPrivacy && (
              <Link to={'/legal/privacy-policy'}>{t('welcomePage:contact:dataPrivacyTitle')}</Link>
            )}
            {crPoolImprint && (
              <Link to={'/legal/legal'}>{t('welcomePage:contact:imprintTitle')}</Link>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ContactModal;
