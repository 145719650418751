import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Typography from 'antd/lib/typography';
import Divider from 'antd/lib/divider';
import { DownloadOutlined } from '@ant-design/icons/lib';
import Button from 'components/ui/Button/Button';
import Rectangle from 'components/layout/Rectangle/Rectangle';
import PreviewsList from 'components/ui/PreviewsList/PreviewsList';
import DropZone from 'components/ui/DropZone/DropZone';

import { ButtonColor } from 'components/ui/Button/Button';
import { currentClientRequestFilesSelector } from 'store/client-request/client-request.selectors';
import {
  ClientRequestState,
  IClientRequest,
  IDocument,
  MediaCategoryType,
} from 'store/client-request/client-request.types';
import {
  downloadAllDocuments,
  getFileLink,
  uploadFile,
} from 'store/client-request/client-request.actions';
import { isIntermediateManagerSelector } from 'store/auth/auth.selectors';

import styles from './DocumentsWidget.module.sass';

interface IDocumentsWidgetProps {
  clientRequest?: IClientRequest;
}

const category = MediaCategoryType.RequestIntermediateOther;
const DocumentsWidget: React.FC<IDocumentsWidgetProps> = ({ clientRequest }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isPoolOperator = useSelector(isIntermediateManagerSelector);
  const tenderDocuments = useSelector(currentClientRequestFilesSelector);

  const categories: any[] = Object.keys(tenderDocuments) || [];

  const onPreviewClick = useCallback(
    (fileName: string) => {
      dispatch(getFileLink(fileName));
    },
    [dispatch],
  );

  const filterFiles = (category: MediaCategoryType) => {
    const { HomeCheck, InstallationPending, Done, Cancelled } = ClientRequestState;
    const state = clientRequest?.state;
    const files = tenderDocuments[category];

    // If category don't have files - don't show it
    if (Array.isArray(files) && !files.length) {
      return false;
    }

    switch (category) {
      case MediaCategoryType.RequestPermitAgreement:
        // If is Pool Operator - show always
        if (isPoolOperator) {
          return true;
        }

        // For the rest installers if CR state is "HomeCheck" / "InstallationPending" / "Done" / "Cancelled"
        return (
          state &&
          (state === HomeCheck ||
            state === InstallationPending ||
            state === Done ||
            state === Cancelled)
        );

      // the rest categories always showed
      default:
        return true;
    }
  };

  // In case if customer don't have documents (Skip Cost Estimate)
  // we don't need to show this section
  if (!categories.length) {
    return null;
  }
  const onUploadSuccess = (file: IDocument) => {
    setLoading(false);
  };

  const onFileAdded = ([file]: File[]) => {
    if (file && clientRequest?.id) {
      setLoading(true);
      dispatch(
        uploadFile(file, clientRequest.id, category, {}, null, onUploadSuccess, onUploadSuccess),
      );
    }
  };

  const dropZoneProps = {
    loading,
    multiple: true,
    acceptedFiles: 'PDF ',
    onFileDropAccepted: onFileAdded,
  };

  const handleDownloadDocuments = () => {
    // @ts-ignore
    dispatch(downloadAllDocuments(clientRequest.id));
  };

  return (
    <Rectangle title={t('installerFlow:tenderDetails:documents')}>
      {categories.filter(filterFiles).map((category) => (
        <div key={category}>
          <Typography.Title level={4} className={styles.category}>
            {t(`installerFlow:tenderDetails:categories:${category}`)}
          </Typography.Title>
          <PreviewsList
            className={styles.previews}
            previews={tenderDocuments[category]}
            onPreviewClick={onPreviewClick}
          />
        </div>
      ))}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          //@ts-ignore
          type="secondary"
          className={styles.downloadAll}
          color={ButtonColor.WHITE}
          onClick={handleDownloadDocuments}
        >
          <DownloadOutlined />
          {t('intermediate:myProjects:downloadDocuments:allImages')}
        </Button>
      </div>
      {isPoolOperator ? (
        <>
          <Divider />
          <DropZone {...dropZoneProps} />
        </>
      ) : null}
    </Rectangle>
  );
};

export default DocumentsWidget;
