import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Form from 'antd/lib/form';
import TextField from 'components/ui/TextField/TextField';
import DropDown from 'components/ui/DropDown/DropDown';
import { ValidationFactory } from 'framework/validations';
import { GENDER_OPTIONS } from 'framework/constants';

enum FieldType {
  INPUT = 'input',
  DROPDOWN = 'dropDown',
}

type CompanyValue = {
  companyName: string;
  gender: string;
  title: string;
  firstName: string;
  lastName: string;
};

interface Props {
  onChange?: Function;
  value?: CompanyValue;
  token?: string;
}

const CompanyStep: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState({
    companyName: '',
    gender: '',
    title: '',
    firstName: '',
    lastName: '',
  } as CompanyValue);

  const COMPANY_FIELDS = [
    {
      type: FieldType.INPUT,
      label: t('installerFlow:registration:company'),
      name: 'company',
      md: 24,
      sm: 24,
      xs: 24,
      value: props.value?.companyName || value.companyName,
      rules: [
        ValidationFactory.REQUIRED,
        ValidationFactory.NOT_ONLY_WHITE_SPACE,
        ValidationFactory.INCLUDES_URL,
      ],
      hidden: !!props.token,
    },
    {
      type: FieldType.DROPDOWN,
      label: t('installerFlow:registration:gender'),
      name: 'gender',
      md: 12,
      sm: 24,
      xs: 24,
      value: props.value?.gender || value.gender,
      rules: [ValidationFactory.REQUIRED],
      options: GENDER_OPTIONS.map((option) => {
        return { value: option.value, label: t(option.label) };
      }),
    },
    {
      type: FieldType.INPUT,
      label: t('installerFlow:registration:companyTitle'),
      name: 'title',
      md: 12,
      sm: 24,
      xs: 24,

      value: props.value?.title || value.title,
      rules: [ValidationFactory.NOT_ONLY_WHITE_SPACE, ValidationFactory.INCLUDES_URL],
    },
    {
      type: FieldType.INPUT,
      label: t('installerFlow:registration:firstName'),
      name: 'firstName',
      md: 12,
      sm: 24,
      xs: 24,

      value: props.value?.firstName || value.firstName,
      rules: [
        ValidationFactory.REQUIRED,
        ValidationFactory.NOT_ONLY_WHITE_SPACE,
        ValidationFactory.INCLUDES_URL,
      ],
    },
    {
      type: FieldType.INPUT,
      label: t('installerFlow:registration:lastName'),
      name: 'lastName',
      md: 12,
      sm: 24,
      xs: 24,

      value: props.value?.lastName || value.lastName,
      rules: [
        ValidationFactory.REQUIRED,
        ValidationFactory.NOT_ONLY_WHITE_SPACE,
        ValidationFactory.INCLUDES_URL,
      ],
    },
  ];

  const onInputChange = (value: CompanyValue) => {
    setValue(value);
    props.onChange && props.onChange(value);
  };

  return (
    <Row gutter={[16, 16]}>
      {COMPANY_FIELDS.map(
        (field) =>
          !field.hidden && (
            <Col
              md={field.md}
              sm={field.sm}
              xs={field.xs}
              key={`installer-register-field-${field.name}`}
            >
              <Form.Item key={field.name} name={field.name} rules={field.rules}>
                {field.type === FieldType.INPUT ? (
                  <TextField
                    label={field.label}
                    value={field.value}
                    onInput={(e) => {
                      const newVal = { ...value, [field.name]: e.currentTarget.value };
                      onInputChange(newVal);
                    }}
                  />
                ) : (
                  <DropDown
                    placeholder={field.label}
                    options={field.options}
                    style={{ width: '100%' }}
                  />
                )}
              </Form.Item>
            </Col>
          ),
      )}
    </Row>
  );
};

export default CompanyStep;
