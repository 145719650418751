import { MediaCategoryType, IDocument } from 'store/client-request/client-request.types';
import {
  USER_GET,
  USER_GET_DOCUMENTS,
  USER_GET_DOCUMENTS_ERROR,
  USER_GET_DOCUMENTS_SUCCESS,
  USER_GET_ERROR,
  USER_GET_SUCCESS,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE_SUCCESS,
  USER_DELETE_ACCOUNT,
  USER_DELETE_ACCOUNT_ERROR,
  USER_RESEND_VERIFICATION_EMAIL,
  USER_RESEND_VERIFICATION_EMAIL_ERROR,
  USER_GET_EMAIL_STATUS,
  USER_GET_EMAIL_STATUS_SUCCESS,
  USER_GET_EMAIL_STATUS_ERROR,
} from './user.types';

export const userGetDocuments = (payload: MediaCategoryType) => ({
  type: USER_GET_DOCUMENTS,
  payload,
});

export const userGetDocumentsSuccess = (payload: IDocument) => ({
  type: USER_GET_DOCUMENTS_SUCCESS,
  payload: payload,
});

export const userGetDocumentsError = (error: any) => ({
  type: USER_GET_DOCUMENTS_ERROR,
  payload: error,
});

export const userGet = () => ({
  type: USER_GET,
});

export const userGetSuccess = (payload: any) => ({
  type: USER_GET_SUCCESS,
  payload: payload,
});

export const userGetError = (error: any) => ({
  type: USER_GET_ERROR,
  payload: error,
});

export const updateUserProfile = (payload: any) => ({
  type: UPDATE_USER_PROFILE,
  payload,
});

export const updateUserProfileSuccess = (payload: any) => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  payload,
});

export const updateUserProfileError = (error: any) => ({
  type: UPDATE_USER_PROFILE_ERROR,
  payload: error,
});

export const userDeleteAccount = () => ({
  type: USER_DELETE_ACCOUNT,
});

export const userDeleteAccountError = (error: any) => ({
  type: USER_DELETE_ACCOUNT_ERROR,
  payload: error,
});

export const userResendVerificationEmail = (payload: any) => ({
  type: USER_RESEND_VERIFICATION_EMAIL,
  payload,
});

export const userResendVerificationEmailError = (error: any) => ({
  type: USER_RESEND_VERIFICATION_EMAIL_ERROR,
  payload: error,
});

export const getEmailStatus = () => ({
  type: USER_GET_EMAIL_STATUS,
});

export const getEmailStatusSuccess = (verifyingEmail: boolean) => ({
  type: USER_GET_EMAIL_STATUS_SUCCESS,
  payload: verifyingEmail,
});

export const getEmailStatusError = (error: any) => ({
  type: USER_GET_EMAIL_STATUS_ERROR,
  payload: error,
});
