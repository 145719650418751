import { AxiosResponse } from 'axios';
import { IOfferDocumentFormJsonData } from 'pages/MyProjects/ClientRequestPage/OfferDocumentGenerator/types';
import HttpService from './http.service';
import config from '../config';

class PdfFormDataAPI extends HttpService {
  static PDF_FORM_DATA_API = 'pdf-form-data';
  getFormData = async (offerId: string): Promise<IOfferDocumentFormJsonData> =>
    this.get(`${PdfFormDataAPI.PDF_FORM_DATA_API}/offer/${offerId}`);
  createFormData = async (
    offerId: string,
    formData: IOfferDocumentFormJsonData,
  ): Promise<IOfferDocumentFormJsonData> =>
    this.post(`${PdfFormDataAPI.PDF_FORM_DATA_API}/offer/${offerId}`, formData);
  updateFormData = async (
    formDataId: string,
    formData: IOfferDocumentFormJsonData,
  ): Promise<AxiosResponse<IOfferDocumentFormJsonData>> =>
    this.put(`${PdfFormDataAPI.PDF_FORM_DATA_API}/${formDataId}`, formData);
}
export default new PdfFormDataAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
