import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, Col, Flex, Form, Input, Row, Select, Switch, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import Idea from 'assets/icons/idea.svg';
import { ValidationFactory } from 'framework/validations';
import { poolsGet } from 'store/intermediate/intermediate.actions';
import { poolsSelector } from 'store/intermediate/intermediate.selectors';
import { IPool } from 'store/intermediate/intermediate.types';
import SurveyToggle from '../../components/SurveyToggle';

const PoolNameStep = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const pools = useSelector(poolsSelector);

  const dispatch = useDispatch();

  const parentOptions = useMemo(
    () => pools.map((pool: IPool) => ({ value: pool.id, label: pool.name })),
    [pools],
  );

  useEffect(() => {
    dispatch(poolsGet({ isParent: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = debounce((searchValue: string) => {
    setLoading(true);
    dispatch(poolsGet({ query: searchValue, isParent: true }, () => setLoading(false)));
  }, 500);

  return (
    <>
      <Flex vertical align="center">
        <Avatar size={56} src={Idea} shape="square" />
        <Typography.Title level={4}>
          {t('intermediate:myProjects:createPool:title')}
        </Typography.Title>
      </Flex>
      <Row align="middle" justify="center" gutter={[16, 16]}>
        <Col span={14} offset={5}>
          <Form.Item
            name="name"
            labelAlign="left"
            label={t('intermediate:myProjects:createPool:placeholder')}
            rules={[ValidationFactory.REQUIRED, ValidationFactory.NOT_ONLY_WHITE_SPACE]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col span={5} />
        <Col span={4}>
          <Form.Item
            name="poolType"
            labelAlign="left"
            label={t('intermediate:myProjects:createPool:survey')}
            shouldUpdate
          >
            <SurveyToggle />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            labelAlign="left"
            label={t('intermediate:myProjects:createPool:isParent')}
            name="isParent"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="parentId"
            dependencies={['isParent', 'isSurvey']}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const isParent = getFieldValue('isParent');
                  if ((value && !isParent) || (!value && isParent)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      t(
                        `common:errors:${
                          parentOptions.length ? 'selectParentPool' : 'createParentPool'
                        }`,
                      ),
                    ),
                  );
                },
              }),
            ]}
          >
            <Select
              loading={loading}
              disabled={parentOptions.length === 0}
              placeholder={t('intermediate:myProjects:createPool:parentPlaceholder')}
              options={parentOptions}
              filterOption={false}
              onSearch={handleSearch}
              showSearch
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default PoolNameStep;
