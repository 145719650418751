import config from 'config';
import { IPDFTemplate } from 'store/pdf-templates/pdf-templates.types';
import HttpService from './http.service';
class PDFTemplateAPI extends HttpService {
  headers = {
    'Content-Type': 'multipart/form-data',
  };

  dataFormat = (data: IPDFTemplate) => {
    const formData = new FormData();

    formData.append('name', data.name);
    formData.append('type', data.type);
    data.textBoxes.map((item) => formData.append('textBoxes[]', item));
    formData.append('file', data.file);

    return formData;
  };

  createPDFTemplate = (poolId: string, data: IPDFTemplate) => {
    return this.post(`pdf-template/pool/${poolId}`, this.dataFormat(data), true, this.headers);
  };

  updatePDFTemplate = (id: string, data: IPDFTemplate) => {
    return this.put(`pdf-template/${id}`, this.dataFormat(data));
  };

  deletePDFTemplate = (id: string) => {
    return this.delete(`pdf-template/${id}`);
  };

  getPDFTemplates = (poolId: string) => {
    return this.get(`pdf-template/pool/${poolId}`);
  };
}

export default new PDFTemplateAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
