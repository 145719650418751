import React from 'react';
import { default as ANTModal, ModalProps } from 'antd/lib/modal';
import { useTranslation } from 'react-i18next';
import Button, { ButtonColor } from 'components/ui/Button/Button';
import styles from './Modals.module.sass';

interface Props extends ModalProps {
  modalText?: string;
  okText?: string;
  cancelText?: string;
  onOk: () => void;
  onCancel: () => void;
}

const DeleteAccountModal: React.FC<Props> = ({
  onOk,
  onCancel,
  open,
  title,
  modalText,
  okText,
  cancelText,
}) => {
  const { t } = useTranslation();

  const modalProps = {
    title,
    open,
    onCancel,
    centered: true,
    closable: true,
    footer: null,
    width: 680,
    wrapClassName: styles.container,
  };

  return (
    <ANTModal {...modalProps}>
      <p className={styles.text}>{modalText}</p>
      <div className={styles.buttonActions}>
        <Button type="primary" onClick={onCancel} color={ButtonColor.BLUE}>
          {cancelText ? cancelText : t('common:buttons:cancel')}
        </Button>
        <Button type="primary" color={ButtonColor.GREEN} onClick={onOk}>
          {okText ? okText : t('common:buttons:okay')}
        </Button>
      </div>
    </ANTModal>
  );
};

export default DeleteAccountModal;
