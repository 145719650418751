import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import {
  currentClientRequestSelector,
  clientRequestStateSelector,
} from '../../../../store/client-request/client-request.selectors';
import EditRequestProductsComponent from '../../../../components/form/EditRequestProducts/EditRequestProducts';
import useIsCustomerAllowedToEditRequestProducts from '../../../../hooks/useIsCustomerAllowedToEditRequestProducts';

const EditCustomerRequestProductsPage = () => {
  const clientRequest = useSelector(currentClientRequestSelector);
  const clientRequestState = useSelector(clientRequestStateSelector);
  const navigate = useNavigate();
  const isCustomerAllowedToEditRequestProducts = useIsCustomerAllowedToEditRequestProducts();
  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);
  if (!isCustomerAllowedToEditRequestProducts) {
    goBack();
    return <Spin />;
  }
  return (
    <EditRequestProductsComponent
      clientRequest={clientRequest}
      clientRequestState={clientRequestState}
      successUpdateHandler={goBack}
      cancelEditHandler={goBack}
    />
  );
};

export default EditCustomerRequestProductsPage;
