import React from 'react';
import clsx from 'clsx';

import styles from './Notification.module.sass';

export type TNotificationType = 'action' | 'warning' | 'info' | 'success' | 'error';
export type TNotificationSize = 'small' | 'medium';

export interface INotificationProps {
  className?: string;
  title?: string;
  content?: any;

  type?: TNotificationType;
  size?: TNotificationSize;

  fluid?: boolean;

  onClick?: () => void;
  onClose?: () => void;
}

const Notification: React.FC<INotificationProps> = (props) => {
  const { className, title, content, type, size, fluid, onClick, onClose } = props;

  const showTitle = !!title && title.length && size !== 'small';
  const showClose = typeof onClose === 'function';

  const containerClassName = clsx(styles.container, className, {
    [styles.fluid]: fluid,
    [styles[type!]]: type,
    [styles[size!]]: size,
  });

  const Content = typeof content === 'function' ? content : null;

  return (
    <div className={containerClassName}>
      <i className={styles.icon} />

      <div className={styles.inner} onClick={onClick}>
        {showTitle ? (
          <div className={styles.title}>
            <h4 className={styles.text}>{title}</h4>
          </div>
        ) : null}

        {content ? <div className={styles.content}>{Content ? <Content /> : content}</div> : null}
      </div>

      {showClose ? <i className={styles.close} onClick={onClose} /> : null}
    </div>
  );
};

Notification.defaultProps = {
  type: 'info',
  size: 'medium',
  fluid: false,

  onClick: () => {},
};

export default Notification;
