import _get from 'lodash/get';
import { AppointmentPurpose, IOffer, OfferState } from 'store/offer/offer.types';

export const getActiveStep = function (offer?: IOffer | null, hasFeedBack?: boolean) {
  if (!offer) {
    return 0;
  }

  switch (offer.state) {
    case OfferState.TenderCancelled:
    case OfferState.TenderSubmittedHomeCheck:
    case OfferState.TenderSubmittedRemoteHomeCheck:
    case OfferState.TenderCreated:
    case OfferState.TenderSubmitted:
    case OfferState.TenderAcceptedHomeCheck:
    case OfferState.TenderAcceptedRemoteHomeCheck:
    case OfferState.OfferAcceptedHomeCheck:
    case OfferState.OfferAcceptedHomeCheckAppointmentNA:
    case OfferState.OfferAcceptedRemoteHomeCheckAppointmentNA:
    case OfferState.OfferAcceptedHomeCheckAppointmentTBD:
    case OfferState.OfferAcceptedRemoteHomeCheckAppointmentTBD:
    case OfferState.OfferAcceptedRemoteHomeCheck:
      return 1;
    case OfferState.TenderAccepted:
    case OfferState.OfferHomeCheckPending:
    case OfferState.OfferRemoteHomeCheckPending:
    case OfferState.OfferAccepted:
    case OfferState.OfferSubmitted:
      return 2;
    case OfferState.InstallationPending:
    case OfferState.InvoiceSubmittedHomeCheck:
    case OfferState.InvoiceSubmittedRemoteHomeCheck:
    case OfferState.InvoicePaymentPendingHomeCheck:
    case OfferState.InvoicePaymentPendingRemoteHomeCheck:
    case OfferState.InvoicePaidHomeCheck:
    case OfferState.InvoicePaidRemoteHomeCheck:
      return 3;
    case OfferState.InvoiceSubmittedInstallation:
    case OfferState.InvoiceAcceptedInstallation:
    case OfferState.OfferHomeCheckDone:
    case OfferState.OfferRemoteHomeCheckDone:
    case OfferState.InstallationDone:
      if (hasFeedBack) return 5;
      return 4;
    default:
      return 0;
  }
};

// When pools + replace enabled we need to show intermediate user instead of installer
export const getDataForInstallerCard = (offer: IOffer) => {
  const isHomePool = offer?.homeCheckOfferReplacedBy?.id;
  const isPool = offer?.offerReplacedBy?.id;

  const logo = offer?.homeCheckOfferReplacedBy?.organization?.logo;

  if (isPool) {
    return { ...offer.offerReplacedBy, organization: { logo } };
  }

  if (isHomePool) {
    return offer.homeCheckOfferReplacedBy;
  }

  return offer.installerUser;
};

// When pools + replace enabled we need to show intermediate organization instead of installer
export const getDataForInstallerOrganization = (offer: IOffer) => {
  const isHomePool = _get(offer, 'homeCheckOfferReplacedBy', null);
  const isPool = _get(offer, 'offerReplacedBy', null);

  const defaultOrganization = _get(offer, 'installerUser.organization', null);

  if (isPool) {
    return {
      name: _get(offer, 'offerReplacedBy.organization.name', ''),
    };
  }

  if (isHomePool) {
    return {
      name: _get(offer, 'homeCheckOfferReplacedBy.organization.name', ''),
    };
  }

  return defaultOrganization;
};

export const getSecondUserForInstallerCard = (offer: IOffer) => {
  const isHomePool = offer?.homeCheckOfferReplacedBy?.id;
  const isPool = offer?.offerReplacedBy?.id;
  return isHomePool || isPool ? offer.installerUser : undefined;
};

export const addAppointmentsType = (appointments: any[], type: AppointmentPurpose) =>
  appointments.map((app) => ({
    ...app,
    purpose: type,
  }));
