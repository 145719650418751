import { produce } from 'immer';
import { RequestState } from 'store/common.types';
import {
  ContactPersonActionTypes,
  CONTACT_PERSONS_GET,
  CONTACT_PERSONS_GET_ERROR,
  CONTACT_PERSONS_GET_SUCCESS,
  CONTACT_PERSON_CREATE,
  CONTACT_PERSON_CREATE_ERROR,
  CONTACT_PERSON_CREATE_SUCCESS,
  CONTACT_PERSON_DELETE,
  CONTACT_PERSON_DELETE_ERROR,
  CONTACT_PERSON_DELETE_SUCCESS,
  CONTACT_PERSON_UPDATE,
  CONTACT_PERSON_UPDATE_ERROR,
  CONTACT_PERSON_UPDATE_SUCCESS,
  IContactPersonState,
} from './contact-person.types';

const initialState: IContactPersonState = {
  contactPersonCreateState: null,
  contactPersonCreateError: null,

  contactPersonUpdateState: null,
  contactPersonUpdateError: null,

  contactPersonDeleteState: null,
  contactPersonDeleteError: null,

  currentContactPersons: [],
  contactPersonsState: null,
  contactPersonsError: null,
};

const ContactPersonReducer = (state = initialState, action: ContactPersonActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CONTACT_PERSON_CREATE:
        draft.contactPersonCreateState = RequestState.Loading;
        break;

      case CONTACT_PERSON_CREATE_ERROR:
        draft.contactPersonCreateState = RequestState.Error;
        draft.contactPersonCreateError = action.payload;
        break;

      case CONTACT_PERSON_CREATE_SUCCESS:
        draft.contactPersonCreateState = RequestState.Success;
        draft.contactPersonCreateError = null;
        draft.currentContactPersons = [...state.currentContactPersons, action.payload];
        break;

      case CONTACT_PERSONS_GET:
        draft.contactPersonsState = RequestState.Loading;
        break;

      case CONTACT_PERSONS_GET_ERROR:
        draft.contactPersonsState = RequestState.Error;
        draft.contactPersonsError = action.payload;
        break;

      case CONTACT_PERSONS_GET_SUCCESS:
        draft.contactPersonsState = RequestState.Success;
        draft.contactPersonsError = null;
        draft.currentContactPersons = action.payload;
        break;

      case CONTACT_PERSON_UPDATE:
        draft.contactPersonUpdateState = RequestState.Loading;
        break;

      case CONTACT_PERSON_UPDATE_ERROR:
        draft.contactPersonUpdateState = RequestState.Error;
        draft.contactPersonUpdateError = action.payload;
        break;

      case CONTACT_PERSON_UPDATE_SUCCESS:
        draft.contactPersonUpdateState = RequestState.Success;
        draft.contactPersonUpdateError = null;
        draft.currentContactPersons = state.currentContactPersons.map((contactPerson) =>
          contactPerson.id === action.payload.id
            ? {
                ...contactPerson,
                name: action.payload.name,
                roleDescription: action.payload.roleDescription,
                phone: action.payload.phone,
                email: action.payload.email,
              }
            : contactPerson,
        );
        break;

      case CONTACT_PERSON_DELETE:
        draft.contactPersonDeleteState = RequestState.Loading;
        break;

      case CONTACT_PERSON_DELETE_ERROR:
        draft.contactPersonDeleteState = RequestState.Error;
        draft.contactPersonUpdateError = action.payload;
        break;

      case CONTACT_PERSON_DELETE_SUCCESS:
        draft.contactPersonDeleteState = RequestState.Success;
        draft.contactPersonUpdateError = null;
        draft.currentContactPersons = state.currentContactPersons.filter(
          (contactPerson) => contactPerson.id !== action.payload,
        );
        break;
    }
  });

export default ContactPersonReducer;
