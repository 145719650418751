import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import Table from 'components/ui/Table/Table';
import Loader from 'components/ui/Loader/Loader';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import Button from 'components/ui/Button/Button';
import { installerInvitationsGet } from 'store/installer/installer.actions';
import { joiningPoolConfirm } from 'store/installer/installer.actions';
import {
  installerInvitationsSelector,
  joiningPoolConfirmStateSelector,
} from 'store/installer/installer.selectors';
import { RequestState } from 'store/common.types';

const PoolInvitesPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const invitations = useSelector(installerInvitationsSelector);
  const joiningPoolConfirmState = useSelector(joiningPoolConfirmStateSelector);

  const tableColumns = [
    {
      title: t('intermediate:myProjects:invitations:createdAt'),
      dataIndex: ['createdAt'],
      key: 'createdAt',
      // eslint-disable-next-line react/display-name
      render: (createdAt: Date) => <DateFormat date={createdAt} />,
    },
    {
      title: t('intermediate:myProjects:invitations:pool'),
      dataIndex: ['clientRequestPool', 'name'],
      key: 'poolName',
    },
    {
      title: t('intermediate:myProjects:invitations:invitedBy'),
      dataIndex: ['clientRequestPool', 'intermediateOrganization', 'name'],
      key: 'invitedBy',
    },
    {
      title: t('intermediate:myProjects:invitations:accept'),
      dataIndex: 'token',
      key: 'accept',
      // eslint-disable-next-line react/display-name
      render: (token: any) => (
        <Button type="primary" onClick={() => onButtonClick(token)}>
          {t('intermediate:myProjects:invitations:accept')}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    dispatch(installerInvitationsGet(onGetInvitesSuccess));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (joiningPoolConfirmState !== RequestState.Loading) {
      setIsLoading(false);
      dispatch(installerInvitationsGet(onGetInvitesSuccess));
    }
  }, [joiningPoolConfirmState]); // eslint-disable-line

  const onButtonClick = (token: string) => {
    dispatch(joiningPoolConfirm(token));
    setIsLoading(true);
  };

  const onGetInvitesSuccess = () => {
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Typography.Title level={3}>
        {t('intermediate:myProjects:invitations:title')}
      </Typography.Title>

      {isLoading ? (
        <Loader />
      ) : (
        <Table
          columns={tableColumns}
          dataSource={invitations.filter((i: any) => i.isEnabled)}
          rowKey="id"
        />
      )}
    </>
  );
};

export default PoolInvitesPage;
