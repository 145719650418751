import { ClientRequestState } from 'store/client-request/client-request.types';
import { OfferState } from 'store/offer/offer.types';

export enum ModalType {
  None = 'none',
  Offer = 'offer',
  Feedback = 'feedback',
}

export enum DashboardWidgets {
  ContactPerson = 'contactPerson',
  CostOverview = 'costOverview',
  Documents = 'documents',
  NextSteps = 'nextSteps',
  Progress = 'progress',
  ToDo = 'toDo',
  ImportantLinks = 'importantLinks',
  Installer = 'installer',
  Request = 'request',
}

export enum PricingTypes {
  Hardware = 'hardware',
  Installation = 'installation',
  HomeCheck = 'homeCheck',
}
export interface IWidgetProps {
  onContinueClick?: () => void;
}

export const ClientRequestActionRequiredStates = [
  ClientRequestState.Initiated,
  ClientRequestState.Submitted,
  ClientRequestState.PendingDocument,
  ClientRequestState.OfferSelectionHomeCheck,
  ClientRequestState.OfferSelectionInstallation,
  ClientRequestState.HomeCheck,
  ClientRequestState.InstallationPending,
  ClientRequestState.PaymentPending,
];

export const OfferActionRequiredState = [
  OfferState.OfferAcceptedHomeCheckAppointmentTBD,
  OfferState.OfferAcceptedRemoteHomeCheckAppointmentTBD,
  OfferState.TenderAcceptedRemoteHomeCheck,
  OfferState.TenderAcceptedHomeCheck,
  OfferState.OfferRemoteHomeCheckPending,
  OfferState.TenderAccepted,
  OfferState.OfferSubmitted,
  OfferState.InvoiceAcceptedInstallation,
];

export const UntitledWidgets = [DashboardWidgets.Progress, DashboardWidgets.NextSteps];
