import config from 'config';
import HttpService from './http.service';

class VerificationAPI extends HttpService {
  verifyPostalCode = (zipCode: string, country?: string) => {
    return this.post('zip-codes/validate', { zipCode, country });
  };
}

export default new VerificationAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
