import { all, call, put, takeLatest } from 'redux-saga/effects';
import DocumentsAPI from 'services/documents.service';
import { IDocument } from 'store/client-request/client-request.types';
import { uploadGenericFileError, uploadGenericFileSuccess } from './document.actions';
import { UploadGenericFileAction, UPLOAD_GENERIC_FILE } from './document.types';

export function* genericFileUpload(action: UploadGenericFileAction) {
  const { uploadSuccess, uploadError } = action.payload;
  try {
    const uploadedDocument: IDocument = yield call(DocumentsAPI.uploadGenericFile, action.payload);

    if (uploadedDocument) {
      yield put(uploadGenericFileSuccess(uploadedDocument));

      if (typeof uploadSuccess === 'function') {
        uploadSuccess(uploadedDocument);
      }
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(uploadGenericFileError(message));
    if (typeof uploadError === 'function') {
      uploadError(message);
    }
  }
}

export default function* root() {
  yield all([takeLatest(UPLOAD_GENERIC_FILE, genericFileUpload)]);
}
