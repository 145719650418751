import React, { useContext } from 'react';
import clsx from 'clsx';
import { default as ANTInput, InputProps } from 'antd/lib/input';

import FiltersContext from 'context/filters.context';

import styles from './FilterTextField.module.sass';

interface IFilterTextFieldProps extends InputProps {
  className?: string;
  value?: string;
  name: string;
}

const FilterTextField: React.FC<IFilterTextFieldProps> = ({
  className,
  name,
  onChange,
  ...rest
}) => {
  const { filters, setFilters } = useContext(FiltersContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      [name]: e.target.value,
    });
  };

  const inputClassName = clsx(styles.input, className);
  return <ANTInput {...rest} className={inputClassName} name={name} onChange={handleChange} />;
};

FilterTextField.defaultProps = {
  className: '',
  value: '',
};

export default FilterTextField;
