import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/lib/typography';
import Form from 'antd/lib/form';
import Input from 'components/ui/TextField/TextField';
import BoxContent from 'components/layout/BoxContent/BoxContent';
import PasswordResetSuccessModal from 'components/modals/PasswordResetSuccess';
import Box from 'components/layout/Box/Box';
import { ValidationFactory } from 'framework/validations';
import { PATH } from 'framework/path';
import { passwordResetRequest } from 'store/auth/auth.actions';
import styles from './PasswordResetPage.module.sass';

const PasswordResetPage = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  const onPasswordResetSuccess = () => {
    setModalVisible(true);
  };

  const onFormChange = (changedValues: any) => {
    setEmail(changedValues.email);
  };

  const onFormSubmit = () => {
    // @ts-ignore
    const { email } = formRef.current.getFieldsValue();
    // @ts-ignore
    const errors = formRef.current.getFieldError('email');

    if (email && !errors.length) {
      dispatch(passwordResetRequest(email, onPasswordResetSuccess));
    }
  };

  const onFormCancel = () => {
    navigate(PATH.LOGIN);
  };

  const onOkayClick = () => {
    setModalVisible(false);
    navigate(PATH.LOGIN);
  };

  const formProps = {
    name: 'basic',
    ref: formRef,
    onValuesChange: onFormChange,
  };

  const emailValidations = [ValidationFactory.REQUIRED, ValidationFactory.EMAIL];
  const showNextButton = !!email;

  const boxContentProps = {
    showNextButton,
    showBackButton: true,
    title: t('customerFlow:registration:createProfile'),
    onNextClick: onFormSubmit,
    onBackClick: onFormCancel,
  };

  return (
    <Box>
      <BoxContent {...boxContentProps}>
        <Form className={styles.form} {...formProps}>
          <Typography.Title level={2} className={styles.title}>
            {t('customerFlow:resetPassword:title')}
          </Typography.Title>
          <Typography.Paragraph className={styles.text}>
            {t('customerFlow:resetPassword:text')}
          </Typography.Paragraph>

          <Form.Item name="email" rules={emailValidations}>
            <Input label={t('common:forms:email')} width="100%" size="large" value={email} />
          </Form.Item>
        </Form>

        <PasswordResetSuccessModal
          text={t('common:resetPassword')}
          open={modalVisible!}
          onOkayClick={onOkayClick}
        />
      </BoxContent>
    </Box>
  );
};

export default PasswordResetPage;
