import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Typography, Form, Skeleton, notification } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { useSearchParams } from 'react-router-dom';
import Button, { ButtonColor } from 'components/ui/Button/Button';
import BackButton from 'components/ui/BackButton/BackButton';
import SuccessModal from 'components/modals/SuccessModal/SuccessModal';
import OfferReservations from 'components/form/OfferReservations/OfferReservations';
import { addAppointmentsType } from 'framework/helpUtils';
import {
  getOfferFiles,
  offerCreate,
  offerGet,
  offerTransition,
  updateOffer,
} from 'store/offer/offer.actions';
import {
  AppointmentPurpose,
  IAppointment,
  OfferEventType,
  OfferState,
} from 'store/offer/offer.types';
import { currentOfferFilesSelector, currentOfferSelector } from 'store/offer/offer.selectors';

import { clientRequestGet } from 'store/client-request/client-request.actions';
import { IDocument, MediaCategoryType } from 'store/client-request/client-request.types';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';

import { checkIsRejected } from 'utils/offerHelpers';
import { removeThousandSeparator } from 'utils/currencyUtils';
import styles from './TenderParticipate.module.sass';
import IntermediateDetailsWidget from './IntermediateDetailsWidget';
import TenderHomeCheckSelectDatePage from './TenderHomeCheckSelectDatePage';

enum CreationTypes {
  WITH_APPOINTMENTS = 'withAppointments',
  WITHOUT_APPOINTMENTS = 'withoutAppointments',
}

const TenderParticipateHomeCheckPage = () => {
  const fileCategory = MediaCategoryType.OfferHomeCheckInstaller;

  const [step, setStep] = useState(0);
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const offerFiles = useSelector(currentOfferFilesSelector);
  const currentOffer = useSelector(currentOfferSelector);
  const clientRequest = useSelector(currentClientRequestSelector);

  const offer = useMemo(
    () => (!checkIsRejected(currentOffer?.state!) ? currentOffer : null),
    [currentOffer],
  );

  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm();

  const [searchParams] = useSearchParams();
  const isRemoteHomeCheck = searchParams.get('remoteHomeCheck') === 'yes';

  useEffect(() => {
    if (!id) {
      return;
    }

    if (!clientRequest) {
      dispatch(clientRequestGet(id, false));
    }

    dispatch(offerGet({ id }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, clientRequest, id]);

  useEffect(() => {
    if (!offerFiles?.length && offer?.id) {
      dispatch(getOfferFiles(offer.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer]);

  useEffect(() => {
    const document = offerFiles?.find((file) => file.document.category === fileCategory);
    if (document) {
      form.setFieldValue('files', [document?.document]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerFiles]);

  // Set default currency on form.
  useEffect(() => {
    const country = clientRequest?.pool?.country;
    if (!country) return;
    form.setFieldsValue({
      vatRate: country.vatRate,
      currencySymbol: country.currencySymbol,
    });
  }, [form, clientRequest]);

  const handleModalOk = () => {
    setSuccessModal(false);
    navigate('/installer/dashboard');
  };

  const onBackButtonClick = useCallback(() => {
    navigate(`/installer/tender/${id}`);
  }, []); // eslint-disable-line

  const onCreationButtonClick = async (type: CreationTypes) => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      const vatRate = removeThousandSeparator(values.vatRate);
      const initialCostEstimateHomeCheck = removeThousandSeparator(values.costEstimate);

      const payload = {
        ...values,
        vatRate,
        costEstimate: null,
        offerValidityDate: form.getFieldValue('offerValidityDate'),
        initialCostEstimateHomeCheck,
        costEstimateHomeCheck: initialCostEstimateHomeCheck,
        documents: values.documents.map((doc: IDocument) => doc.id),
        remoteHomeCheck: isRemoteHomeCheck,
        initialVatRate: vatRate,
        initialCurrencySymbol: values.currencySymbol,
      };
      if (offer?.id) {
        type === CreationTypes.WITH_APPOINTMENTS
          ? setStep(1)
          : dispatch(updateOffer(offer.id, { ...payload, appointments: [] }));
      } else {
        dispatch(
          offerCreate(
            { ...payload, clientRequestId: id },
            () => {
              setLoading(false);
              type === CreationTypes.WITHOUT_APPOINTMENTS
                ? navigate('/installer/dashboard')
                : setStep(1);
            },
            (error: string) => {
              setLoading(false);
              notification.error({ message: error });
            },
            type === CreationTypes.WITHOUT_APPOINTMENTS
              ? OfferEventType.OFFER_HOMECHECK_WITHOUT_APPOINTMENTS
              : undefined,
          ),
        );
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleAcceptOffer = (appointments: IAppointment[]) => {
    const values = form.getFieldsValue();

    delete values.documents;

    const payload = {
      ...values,
      offerValidityDate: form.getFieldValue('offerValidityDate'),
      appointments: addAppointmentsType(appointments, AppointmentPurpose.HomeCheck),
    };

    if (!offer) return;
    if (
      offer.id &&
      (offer.state === OfferState.TenderSubmittedHomeCheck ||
        offer.state === OfferState.TenderSubmittedRemoteHomeCheck)
    ) {
      dispatch(updateOffer(offer.id, { ...payload, id: offer.id }));
    } else {
      dispatch(
        offerTransition(
          OfferEventType.INSTALLER_ACCEPTED_HOME_CHECK,
          {
            ...payload,
            id: offer.id,
          },
          () => setSuccessModal(true),
        ),
      );
    }
  };

  const buttons = [
    {
      text: t('installerFlow:tenderDetails:buttonSubmitHomeCheck'),
      color: ButtonColor.GREEN,
      type: CreationTypes.WITH_APPOINTMENTS,
      className: styles.submitButton,
    },
    {
      text: t('installerFlow:tenderDetails:withoutAppointmentButton'),
      color: ButtonColor.BLUE,
      type: CreationTypes.WITHOUT_APPOINTMENTS,
    },
  ];

  const renderReservations = () => (
    <Row gutter={[16, 16]} className={styles.container}>
      <Col span={24} className={styles.header}>
        <BackButton className={styles.back} onClick={onBackButtonClick}>
          {t('common:buttons.back')}
        </BackButton>

        <Typography.Title level={3} className={styles.title}>
          {t('installerFlow:tenderParticipate:homeCheck:pageTitle')}
        </Typography.Title>
      </Col>
      <Col span={24}>
        <IntermediateDetailsWidget clientRequest={clientRequest} />
      </Col>
      <Col span={24}>
        <Typography className={styles.text}>
          {t('installerFlow:tenderParticipate:sectionParticipate:description')}
        </Typography>
        <div className={styles.informationBox}>
          <InfoCircleOutlined style={{ color: '#1C6EF7' }} />
          <span className={styles.informationBoxText}>
            {t('installerFlow:tenderParticipate:sectionParticipate:informationText')}
          </span>
        </div>
      </Col>
      <Col span={24}>
        <Form form={form}>
          <OfferReservations offerId={offer?.id} />
        </Form>
      </Col>
      <Col span={24}>
        {buttons.map((button, index) => (
          <Button
            key={index}
            color={button.color}
            className={button.className}
            onClick={() => onCreationButtonClick(button.type)}
            type="primary"
            loading={loading}
          >
            {button.text}
          </Button>
        ))}
      </Col>
    </Row>
  );

  const renderSteps = () => {
    switch (step) {
      case 0:
        return renderReservations();
      case 1:
        return (
          <TenderHomeCheckSelectDatePage
            startDate={clientRequest?.startDate}
            onSubmit={handleAcceptOffer}
          />
        );
      default:
        return <Skeleton />;
    }
  };

  return (
    <>
      {renderSteps()}
      <SuccessModal
        open={successModal}
        modalText={t('installerFlow:tenderParticipate:successModalHomeCHeck')}
        onOkayClick={handleModalOk}
      />
    </>
  );
};

export default TenderParticipateHomeCheckPage;
