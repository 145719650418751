import React, { useState } from 'react';
import { default as ANTModal } from 'antd/lib/modal';
import { useTranslation } from 'react-i18next';
import DropDown from 'components/ui/DropDown/DropDown';
import Button from 'components/ui/Button/Button';
import { QuestionKey } from 'store/client-request/client-request.types';
import styles from './SelectQuestionModal.module.sass';

const modalProps = {
  centered: true,
  closable: true,
  footer: null,
  width: 400,
};

export interface ISelectQuestionModalProps {
  changeVisible: (value: boolean) => void;
  onClose?: () => void;
  visible: boolean;
  onAdd: (v: any) => void;
}

const SelectQuestionModal: React.FC<ISelectQuestionModalProps> = ({
  changeVisible,
  onClose,
  visible,
  onAdd,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(null);

  const mockOptions = [
    {
      value: QuestionKey.ChargingStation,
      label: t('intermediate:myProjects:questionModal:chargingQuestion'),
    },
    // Hide car model question temporarily
    /*
    {
      value: QuestionKey.CarModel,
      label: t('intermediate:myProjects:questionModal:carModelQuestion'),
    },
    */
  ];

  const dropDownProps = {
    options: mockOptions,
    placeholder: t('intermediate:myProjects:questionModal:select'),
    value,
    onChange: (v: any) => setValue(v),
  };

  const onSelectClick = () => {
    onAdd(value);
    setValue(null);
    changeVisible(false);
  };

  return (
    <ANTModal
      {...modalProps}
      onCancel={onClose}
      open={visible}
      title={t('intermediate:myProjects:questionModal:title')}
    >
      <div className={styles.container}>
        <DropDown {...dropDownProps} />
        <Button className={styles.button} type="primary" onClick={onSelectClick} disabled={!value}>
          {t('intermediate:myProjects:questionModal:select')}
        </Button>
      </div>
    </ANTModal>
  );
};

export default SelectQuestionModal;
