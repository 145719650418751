import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import FeedbackAPI from 'services/feedback.service';
import {
  feedbackCreateError,
  feedbackCreateSuccess,
  feedbackGetError,
  feedbackGetSuccess,
} from './feedback.actions';
import {
  FeedbackCreateAction,
  FeedbackGetAction,
  FEEDBACK_CREATE,
  FEEDBACK_GET,
  IFeedback,
} from './feedback.types';

export function* getFeedback(action: FeedbackGetAction): Generator<any, void, any> {
  try {
    const state = yield select();

    const feedback: {
      items: IFeedback[];
      count: number;
    } = yield call(
      FeedbackAPI.getFeedback,
      action.payload.referenceId,
      action.payload.referenceType,
      state?.auth?.user?.id,
    );
    yield put(feedbackGetSuccess(feedback.items));
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(feedbackGetError(message));
  }
}

export function* createFeedback(action: FeedbackCreateAction) {
  try {
    const result: IFeedback[] = yield all(
      action.payload.map((item: IFeedback) =>
        call(FeedbackAPI.createFeedback, item.text === '' ? { ...item, text: undefined } : item),
      ),
    );
    yield put(feedbackCreateSuccess(result));
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(feedbackCreateError(message));
  }
}

export default function* root() {
  yield all([takeLatest(FEEDBACK_GET, getFeedback), takeLatest(FEEDBACK_CREATE, createFeedback)]);
}
