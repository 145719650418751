import React from 'react';
import { DoubleLeftOutlined, DoubleRightOutlined, DownloadOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';
import Button, { ButtonColor } from 'components/ui/Button/Button';
import styles from './ShowMore.module.sass';
import DocumentsTable from '../DocumentsTable/DocumentsTable';

interface Props {
  details: boolean;
  setDetails: Function;
  setShowUploadDoc?: Function;
  handleDownloadDocuments?: Function;
  documents?: any[];
  headerChild?: React.ReactElement;
  footerChild?: React.ReactElement;
}

export const ShowMore: React.FC<Props> = (props) => {
  const {
    details,
    setDetails,
    setShowUploadDoc,
    handleDownloadDocuments,
    headerChild,
    documents,
    footerChild,
  } = props;
  const { t } = useTranslation();
  return (
    <>
      {details && (
        <div className={styles.details}>
          {headerChild}
          <div className={styles.docTitle}>
            <span>Documents</span>
            <div>
              {setShowUploadDoc && (
                <Button
                  type="primary"
                  color={ButtonColor.WHITE}
                  onClick={() => setShowUploadDoc && setShowUploadDoc(true)}
                >
                  + {t('intermediate:myProjects:uploadDocumentModal:title')}
                </Button>
              )}

              <Button
                type="primary"
                color={ButtonColor.WHITE}
                onClick={() => handleDownloadDocuments && handleDownloadDocuments()}
              >
                <DownloadOutlined />
                {t('intermediate:myProjects:downloadDocuments:allFiles')}
              </Button>
            </div>
          </div>
          <DocumentsTable documents={documents ? documents : []} />
          {footerChild}
        </div>
      )}
      <Row
        justify="center"
        align="middle"
        className={clsx([styles.detailsButton, styles.bold])}
        onClick={() => setDetails(!details)}
      >
        <Col>
          {details ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
          {details
            ? t('intermediate:myProjects:offer:hide')
            : t('intermediate:myProjects:offer:show')}
          {` ${t('intermediate:myProjects:offer:details')}`}
        </Col>
      </Row>
    </>
  );
};
