import { produce } from 'immer';
import {
  ILeadState,
  LeadActionTypes,
  LEAD_CREATE,
  LEAD_CREATE_ERROR,
  LEAD_CREATE_SUCCESS,
} from './lead.types';
import { RequestState } from '../common.types';

const initialState: ILeadState = {
  leadCreateState: null,
  leadCreateError: null,
};

const LeadReducer = (state = initialState, action: LeadActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LEAD_CREATE:
        draft.leadCreateState = RequestState.Loading;
        break;

      case LEAD_CREATE_ERROR:
        draft.leadCreateState = RequestState.Error;
        draft.leadCreateError = action.payload;
        break;

      case LEAD_CREATE_SUCCESS:
        draft.leadCreateState = RequestState.Success;
        draft.leadCreateError = null;
        break;
    }
  });

export default LeadReducer;
