import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Alert, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Attention from 'assets/icons/attention.svg';
import Icon from 'components/ui/Icon/Icon';

import styles from './ProfileInfoTab.module.sass';

type ListItemType = {
  label?: string;
  value?: string;
  isAlert?: boolean;
};

type ProfileValue = {
  firstName: string;
  lastName: string;
  gender: string;
  email: string;
  password: string;
  fax: string;
  isMailUpdated?: boolean;
};

export interface IProfileInfoTabProps {
  user: any;
  isCustomer: boolean;
  value?: ProfileValue;
  refresh?: any;
}

const ProfileInfoTab: React.FC<IProfileInfoTabProps> = ({ user, isCustomer, refresh }) => {
  const { t } = useTranslation();
  const isEmailNotUpdated = !user?.authUser?.emailVerified;
  const isCurrentEmail = '';
  const list: ListItemType[] = [
    {
      label: t('profile:gender'),
      value: [t('profile:female'), t('profile:male'), t('profile:other')][user.gender],
    },
    {
      isAlert: true,
      label: '',
    },
    {
      label: t('profile:email'),
      value: user.email,
    },
    {
      label: t('profile:phone'),
      value: user.phone,
    },
  ];

  if (!isCustomer) {
    list.push({
      label: t('profile:faxNumber'),
      value: user.fax,
    });
  }

  const renderListItem = (field: ListItemType, index: number) => (
    <li key={index} className={styles.profileListEl}>
      <span className={styles.profileListTitle}>{field.label}</span>
      <span className={styles.profileListDate}>{field.value}</span>
    </li>
  );

  const renderAlert = (field: ListItemType, index: number) =>
    isEmailNotUpdated ? (
      <li key={index} className={styles.alertContainer}>
        <Alert
          banner
          className={styles.alertError}
          type="error"
          icon={<Icon className={styles.iconAlert} icon={Attention} />}
          message={`${t('profile:changeEmailSuccess')} ${isCurrentEmail}`}
        />
      </li>
    ) : null;

  return (
    <>
      <div className={styles.profileWrapper}>
        <div className={styles.editProfile}>
          <h2 className={styles.title}>{t('profile:profile')}</h2>
          <Link to={'/profile/edit'} className={styles.link}>
            {t('profile:editProfile')}
          </Link>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.cardUser}>
          <div className={styles.userInfo}>
            <Avatar size={80} icon={<UserOutlined />} />
            <span className={styles.userInfoName}>
              {user.firstName} {user.lastName}
            </span>
            {user && user.position && <span className={styles.specialty}>{user.position}</span>}
          </div>
          <ul className={styles.profileList}>
            {list.map((field, index) =>
              field.isAlert ? renderAlert(field, index) : renderListItem(field, index),
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ProfileInfoTab;
