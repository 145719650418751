import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Typography from 'antd/lib/typography';
import Tag from 'antd/lib/tag';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';

import FiltersIcon from 'assets/icons/filters.svg';
import FiltersBlueIcon from 'assets/icons/filters-blue.svg';
import SearchIcon from 'assets/icons/search.svg';
import Icon, { IconSize } from 'components/ui/Icon/Icon';
import Button from 'components/ui/Button/Button';
import TextField from 'components/ui/TextField/TextField';

import FiltersContext, { FiltersType } from 'context/filters.context';

import FilterTextField from './FilterTextField/FilterTextField';
import FilterSelect from './FilterSelect/FilterSelect';
import FilterMultiSelect from './FilterMultiSelect/FilterMultiSelect';
import styles from './Filters.module.sass';

interface IFiltersProps {
  filters: FiltersType;
  setFilters: any;
  inputValue: string;
  inputName: string;
  inputPlaceholder?: string;
  isActive?: boolean;
  onFiltersChange?(name: string, value: any): void;
  onClearFilters?(): void;
  children?: React.ReactNode;
}

const Filters: React.FC<IFiltersProps> & {
  MultiSelect: typeof FilterMultiSelect;
  Select: typeof FilterSelect;
  TextField: typeof FilterTextField;
} = ({
  children,
  filters,
  setFilters,
  inputName,
  inputPlaceholder = 'Search',
  isActive,
  onClearFilters,
  onFiltersChange,
}) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  const handleInputChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      onFiltersChange && onFiltersChange(inputName, e.currentTarget.value);
    },
    [inputName, onFiltersChange],
  );

  const handleTagClick = (key: string, value?: any) => {
    const currentFilter = filters[key];

    // Special case for radius (used on Dashboard/Tenders) because default value is NULL
    if (key === 'radius') {
      onFiltersChange && onFiltersChange(key, null);
      return;
    }

    // Clear string filter
    if (typeof currentFilter === 'string') {
      onFiltersChange && onFiltersChange(key, '');
      return;
    }

    // Remove some item from filter which type is Array
    if (Array.isArray(currentFilter)) {
      const index = currentFilter.findIndex((i) => i === value);
      const newValue = currentFilter;

      if (index !== -1) {
        currentFilter.splice(index, 1);
      }

      onFiltersChange && onFiltersChange(key, newValue);
      return;
    }
  };

  const renderFilters = () => {
    return (
      <div className={styles.filters}>
        <div className={styles.header}>
          <Typography.Title level={5}>
            {t('intermediate:myProjects:filters:title')}
          </Typography.Title>
          <span className={styles.closeButton} onClick={() => setOpened(false)}>
            <CloseOutlined />
          </span>
        </div>

        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <div className={styles.filtersList}>{children}</div>

          <button className={styles.clearButton} onClick={onClearFilters}>
            {t('intermediate:myProjects:filters:clearFilters')}
          </button>
        </div>
      </div>
    );
  };

  const renderTags = () => {
    const filtersKeys = Object.keys(filters);

    return (
      <div className={styles.tags}>
        {filtersKeys.map((key) => {
          const filter = filters[key];
          const filterType = typeof filter;

          // render tag for String filter type
          if (filterType === 'string' && filter) {
            return (
              <Tag key={`filter-${key}`} closable onClose={() => handleTagClick(key)}>
                {filter}
              </Tag>
            );
          }

          // render tags for Array filter type
          if (Array.isArray(filter) && filter.length) {
            return filter.map((item) => (
              <Tag key={`filter-${key}-${item}`} closable onClose={() => handleTagClick(key, item)}>
                {item}
              </Tag>
            ));
          }

          return null;
        })}
      </div>
    );
  };

  const filtersContext = {
    filters,
    setFilters,
  };

  return (
    <FiltersContext.Provider value={filtersContext}>
      <div className={styles.container}>
        <div className={styles.controls}>
          {opened ? (
            <div className={styles.searchFieldBig}>
              <TextField
                type="text"
                className={styles.searchFieldBigInput}
                placeholder={inputPlaceholder}
                value={filters[inputName] as string}
                prefix={<SearchOutlined />}
                onInput={handleInputChange}
              />
            </div>
          ) : (
            <Button className={styles.searchButton} size="small" onClick={() => setOpened(!opened)}>
              <Icon className={styles.searchIcon} size={IconSize.MEDIUM} icon={SearchIcon} />
            </Button>
          )}
          <Button
            className={clsx(styles.filtersButton, { [styles.isActive]: isActive })}
            size="small"
            onClick={() => setOpened(!opened)}
          >
            <Icon
              className={styles.searchIcon}
              size={IconSize.MEDIUM}
              icon={isActive ? FiltersBlueIcon : FiltersIcon}
            />
          </Button>
        </div>

        {opened ? renderFilters() : null}

        {!opened && isActive ? renderTags() : null}
      </div>
    </FiltersContext.Provider>
  );
};

Filters.MultiSelect = FilterMultiSelect;
Filters.Select = FilterSelect;
Filters.TextField = FilterTextField;

export default Filters;
