import { createSelector } from 'reselect';
import { IBundleState } from './bundle.types';

export const bundleSelector = (state: any) => state.bundle;

// Bundle Create State
export const bundleCreateStateSelector = createSelector(
  bundleSelector,
  (bundle: IBundleState) => bundle.bundleCreateState,
);

// Bundle Get State
export const bundleGetStateSelector = createSelector(
  bundleSelector,
  (bundle: IBundleState) => bundle.bundlesState,
);

// Current Bundles
export const currentBundlesSelector = createSelector(
  bundleSelector,
  (bundle: IBundleState) => bundle.currentBundles,
);

// Bundle Update State
export const bundleUpdateStateSelector = createSelector(
  bundleSelector,
  (bundle: IBundleState) => bundle.bundleUpdateState,
);
