import _ from 'lodash';
import { SupportedLanguages } from 'framework/constants';
import { CustomDocumentTextType, ICustomDocument } from 'store/intermediate/intermediate.types';
import { ICountry } from 'store/country/country.types';
import {
  CostCalculationModeOptionsType,
  CustomDocumentCategory,
  ILegalForm,
  InstallationSelectionModeOptionsType,
  PoolSettingsFieldsType,
  PoolType,
} from 'types/pool-settings.types';
import { TSupportedLanguagesKey } from 'types/language.types';
import { formatEditorField } from './product';
import { removeThousandSeparator } from './currencyUtils';
import { getLanguageKey } from './supprotedLanguagesUtils';
import { TLegalFormValues } from '../pages/MyProjects/EditPool/ContactInformationTab/LegalTab/LegalTab';

export const formatPoolPayload = (values: any, countries: ICountry[]) => {
  const {
    isSurvey,
    requestValidity,
    offerMinimumValidity,
    appointmentUntil,
    appointmentFrom,
    costEstimateMin,
    costEstimateMax,
    serviceFeeCost,
  } = values;
  const country = countries.find((country) => country.iso31661Alpha3 === values?.country);
  return {
    ...values,
    [PoolSettingsFieldsType.PoolType]: isSurvey ? PoolType.SurveyPool : PoolType.RequestPool,
    [PoolSettingsFieldsType.HomeCheckOfferApproval]:
      values.homeCheckOfferApproval === 'checkByIntermediate'
        ? 'directlySendToCustomer'
        : values.homeCheckOfferApproval,
    [PoolSettingsFieldsType.RequestValidity]:
      values.requestValidationSelectionMode === 'unlimited' ? null : requestValidity,
    [PoolSettingsFieldsType.OfferMinimumValidity]: offerMinimumValidity,

    [PoolSettingsFieldsType.InstallationOfferApproval]:
      values.installationOfferApproval === 'checkByIntermediate'
        ? 'directlySendToCustomer'
        : values.installationOfferApproval,
    [PoolSettingsFieldsType.InstallationInvoiceApproval]:
      values.installationInvoiceApproval === 'checkByIntermadiadte'
        ? 'directlySendToCustomer'
        : values.installationInvoiceApproval,
    [PoolSettingsFieldsType.ServiceFeeCost]: serviceFeeCost
      ? removeThousandSeparator(serviceFeeCost)
      : null,

    ...(values.installationSelectionMode === InstallationSelectionModeOptionsType.Range && {
      [PoolSettingsFieldsType.AppointmentFrom]: appointmentFrom,
      [PoolSettingsFieldsType.AppointmentUntil]: appointmentUntil,
    }),

    ...(values.costCalculationMode === CostCalculationModeOptionsType.Range && {
      [PoolSettingsFieldsType.CostEstimateMin]: removeThousandSeparator(costEstimateMin),
      ...(values.requestValidationSelectionMode === 'unlimited' && {
        [PoolSettingsFieldsType.RequestValidity]: null,
      }),
      [PoolSettingsFieldsType.CostEstimateMax]: removeThousandSeparator(costEstimateMax),
    }),
    country,
  };
};

export const handleOfferInvoiceSettings = (values: any) => {
  const isHomeCheckByIntermediate = values?.homeCheckOfferApproval === 'replaceByIntermediate';
  const isInvoiceCheckByIntermediate =
    values?.installationInvoiceApproval === 'replaceByIntermediate';
  const isOfferCheckByIntermediate = values?.installationOfferApproval === 'replaceByIntermediate';

  if (isHomeCheckByIntermediate) {
    if (!isInvoiceCheckByIntermediate && !isOfferCheckByIntermediate) {
      return PoolSettingsFieldsType.HomeCheckOfferApproval;
    }
    if (!isInvoiceCheckByIntermediate || !isOfferCheckByIntermediate) {
      return !isInvoiceCheckByIntermediate
        ? PoolSettingsFieldsType.InstallationInvoiceApproval
        : PoolSettingsFieldsType.InstallationOfferApproval;
    }
  } else {
    if (isOfferCheckByIntermediate) {
      return PoolSettingsFieldsType.InstallationOfferApproval;
    }
  }
  return;
};
export const formatLegalDocumentsFormData = (legalDocuments: ICustomDocument[]) => {
  const payload: TLegalFormValues = {
    termsConditions: {},
    dataPrivacy: {},
    cancelationPolicy: {},
    imprint: {},
  };

  Object.keys(CustomDocumentCategory).forEach((key) => {
    const categoryBasedDocuments = legalDocuments.filter(
      (doc) => doc.category === CustomDocumentCategory[key as keyof typeof CustomDocumentCategory],
    );
    categoryBasedDocuments.forEach((_categoryBasedDocument) => {
      payload[key as keyof ILegalForm][getLanguageKey(_categoryBasedDocument.lang)] = {
        id: _categoryBasedDocument.id,
        link:
          _categoryBasedDocument.textType === CustomDocumentTextType.LINK
            ? _categoryBasedDocument.text || null
            : null,
        text:
          _categoryBasedDocument.textType === CustomDocumentTextType.EDITOR_TEXT
            ? _categoryBasedDocument.text || null
            : null,
      };
    });
  });

  return payload;
};
export const getDeletedDocumentsIDs = (
  values: TLegalFormValues,
  legalDocuments: ICustomDocument[],
) => {
  const formValuesIDs = _.flatMap(values, (value) =>
    _.flatMap(value, (content) => content?.id),
  ).filter((id) => !!id);
  return legalDocuments
    .filter((_document) => !formValuesIDs.includes(_document.id))
    .map((_document) => _document.id);
};
export const saveLegalDocumentsPayload = (values: TLegalFormValues) =>
  _.flatMap(values, (value, categoryKey) =>
    _.flatMap(value, (innerValue, language) => ({
      id: innerValue?.id,
      text: innerValue?.link ? (innerValue.link as string) : formatEditorField(innerValue?.text),
      textType: innerValue?.link ? CustomDocumentTextType.LINK : CustomDocumentTextType.EDITOR_TEXT,
      lang: SupportedLanguages[language as TSupportedLanguagesKey],
      category: CustomDocumentCategory[categoryKey as keyof typeof CustomDocumentCategory],
    })),
  ).filter((item) => item.text);
