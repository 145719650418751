import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { InfoCircleOutlined } from '@ant-design/icons/lib';
import ExportDate from 'assets/icons/export.svg';
import Button, { ButtonColor } from 'components/ui/Button/Button';
import Table from 'components/ui/Table/Table';
import Icon from 'components/ui/Icon/Icon';
import DateFormat from 'components/ui/DateFormat/DateFormat';

import { DEFAULT_META_VALUE, DEFAULT_PAGINATION_PAGE_SIZE } from 'framework/constants';

import SurveysAPI from 'services/surveys.service';
import { ISurvey } from 'store/surveys/surveys.types';
import { surveysGet } from 'store/surveys/surveys.actions';
import { surveysListSelector } from 'store/surveys/surveys.selectors';

import styles from './SurveysTab.module.sass';
import editStyles from '../EditPool.module.sass';

interface ISurveysTabProps {
  poolId: string;
}

const SurveysTab: React.FC<ISurveysTabProps> = ({ poolId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [meta, setMeta] = useState(DEFAULT_META_VALUE);
  const { items: surveys, total } = useSelector(surveysListSelector);

  useEffect(() => {
    dispatch(surveysGet(poolId));
  }, []); // eslint-disable-line

  const renderDate = (_: string, row: ISurvey) =>
    row?.createdAt ? <DateFormat date={new Date(row.createdAt)} /> : null;

  const SurveysTableColumns: ColumnsType<ISurvey> = [
    {
      title: 'installerFlow:dashboard:id',
      dataIndex: 'displayId',
      key: 'displayId',
    },
    {
      title: 'installerFlow:dashboard:dateCreated',
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      render: renderDate,
    },
  ];

  const onExportDate = () => {
    SurveysAPI.exportPoolSurveys(poolId);
  };

  const handlePageChange = (page: number, pageSize: number = DEFAULT_PAGINATION_PAGE_SIZE) => {
    const newMeta = {
      offset: pageSize !== meta.limit ? 0 : pageSize * (page - 1),
      limit: pageSize,
    };

    setMeta(newMeta);
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-between" align="top" style={{ marginBottom: '20px' }}>
          <Col span={12}>
            <Typography className={editStyles.boxTitle}>
              {t('intermediate:myProjects:editProjectPools:surveys')}
              <Tooltip
                title={t('intermediate:myProjects:projectPoolsTab:notificationContent')}
                placement="bottom"
                className={editStyles.tooltip}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Typography>
          </Col>
          <Button
            type="primary"
            color={ButtonColor.WHITE}
            className={styles.exportDate}
            onClick={onExportDate}
          >
            <Icon icon={ExportDate} />
            <span>{t('Export Data')}</span>
          </Button>
        </Row>

        <div className={styles.table}>
          <Table
            columns={SurveysTableColumns.map((tender: any) => ({
              ...tender,
              title: t(`${tender.title}`),
            }))}
            rowKey="id"
            // loading={surveyState === RequestState.Loading || requestsState === null}
            dataSource={surveys}
            onRowClick={(record: any) => `/my-projects/survey/${record.id}`}
            pagination={{
              total,
              showSizeChanger: true,
              position: ['bottomCenter'],
              pageSize: meta.limit,
              current: meta.offset ? meta.offset / meta.limit + 1 : 1,
              onChange: handlePageChange,
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default SurveysTab;
