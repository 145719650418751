import { ISearchDto } from 'store/common.types';
import { RequestResult } from 'types/crud-view.types';
import {
  IProduct,
  IProductModel,
  IProductVendor,
  MODELS_GET,
  MODELS_GET_ERROR,
  MODELS_GET_SUCCESS,
  MODEL_CREATE,
  MODEL_CREATE_ERROR,
  MODEL_CREATE_SUCCESS,
  PRODUCTS_GET,
  PRODUCTS_GET_ERROR,
  PRODUCTS_GET_SUCCESS,
  PRODUCT_CREATE,
  PRODUCT_CREATE_ERROR,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_DELETE,
  PRODUCT_DELETE_ERROR,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_UPDATE,
  PRODUCT_UPDATE_ERROR,
  PRODUCT_UPDATE_SUCCESS,
  VENDORS_GET,
  VENDORS_GET_ERROR,
  VENDORS_GET_SUCCESS,
  VENDOR_CREATE,
  VENDOR_CREATE_ERROR,
  VENDOR_CREATE_SUCCESS,
} from './product.types';

export const productCreate = (
  componentId: string,
  product: IProduct,
  onRequestDone?: (status: RequestResult) => void,
) => ({
  type: PRODUCT_CREATE,
  payload: {
    componentId,
    product,
    onRequestDone,
  },
});

export const productCreateSuccess = (product: IProduct) => ({
  type: PRODUCT_CREATE_SUCCESS,
  payload: product,
});

export const productCreateError = (error: any) => ({
  type: PRODUCT_CREATE_ERROR,
  payload: error,
});

export const productUpdate = (
  id: string,
  body: IProduct,
  onRequestDone?: (status: RequestResult) => void,
) => ({
  type: PRODUCT_UPDATE,
  payload: {
    id,
    body,
    onRequestDone,
  },
});

export const productUpdateSuccess = (product: IProduct) => ({
  type: PRODUCT_UPDATE_SUCCESS,
  payload: product,
});

export const productUpdateError = (error: any) => ({
  type: PRODUCT_UPDATE_ERROR,
  payload: error,
});

export const productDelete = (id: string, onRequestDone?: (status: RequestResult) => void) => ({
  type: PRODUCT_DELETE,
  payload: { id, onRequestDone },
});

export const productDeleteSuccess = (id: string) => ({
  type: PRODUCT_DELETE_SUCCESS,
  payload: id,
});

export const productDeleteError = (error: any) => ({
  type: PRODUCT_DELETE_ERROR,
  payload: error,
});

export const productsGet = (componentId: string, filtersPayload?: ISearchDto) => ({
  type: PRODUCTS_GET,
  payload: { componentId, filtersPayload },
});

export const productsGetSuccess = (products: IProduct[]) => ({
  type: PRODUCTS_GET_SUCCESS,
  payload: products,
});

export const productsGetError = (error: any) => ({
  type: PRODUCTS_GET_ERROR,
  payload: error,
});

export const vendorCreate = (name: string, poolId: string) => ({
  type: VENDOR_CREATE,
  payload: { name, poolId },
});

export const vendorCreateSuccess = (vendor: IProductVendor) => ({
  type: VENDOR_CREATE_SUCCESS,
  payload: vendor,
});

export const vendorCreateError = (error: any) => ({
  type: VENDOR_CREATE_ERROR,
  payload: error,
});

export const modelCreate = (name: string, poolId: string, vendorId: string) => ({
  type: MODEL_CREATE,
  payload: { name, poolId, vendorId },
});

export const modelCreateSuccess = (model: IProductModel) => ({
  type: MODEL_CREATE_SUCCESS,
  payload: model,
});

export const modelCreateError = (error: any) => ({
  type: MODEL_CREATE_ERROR,
  payload: error,
});

export const getVendors = (poolId: string) => ({
  type: VENDORS_GET,
  payload: {
    poolId,
  },
});

export const getVendorsSuccess = (payload: IProductVendor[]) => ({
  type: VENDORS_GET_SUCCESS,
  payload,
});

export const getVendorsError = (error: any) => ({
  type: VENDORS_GET_ERROR,
  payload: error,
});

export const getModelsByVendor = (poolId: string, vendorId: string, category?: string) => ({
  type: MODELS_GET,
  payload: {
    poolId,
    vendorId,
    category,
  },
});

export const getModelsByVendorSuccess = (payload: IProductModel[]) => ({
  type: MODELS_GET_SUCCESS,
  payload,
});

export const getModelsByVendorError = (error: any) => ({
  type: MODELS_GET_ERROR,
  payload: error,
});
