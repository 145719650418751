import React, { ReactElement } from 'react';
import clsx from 'clsx';

import { IFile } from 'store/client-request/client-request.types';
import styles from './PreviewsList.module.sass';
import PreviewDocument from '../PreviewDocument/PreviewDocument';

interface IPreviewsListProps {
  className: string;

  previews: IFile[];
  onPreviewClick: (fileName: string) => void;
}

function PreviewsList(props: IPreviewsListProps): ReactElement {
  const { className, previews, onPreviewClick } = props;
  const containerClassName = clsx(styles.container, className);

  return (
    <div className={containerClassName}>
      {previews.map((preview: IFile) => (
        <PreviewDocument
          {...preview}
          className={styles.preview}
          onDownloadClick={() => onPreviewClick(preview.name)}
          key={preview.id}
        />
      ))}
    </div>
  );
}

PreviewsList.defaultProps = {
  previews: [],
  onPreviewClick: () => {},
};

export default PreviewsList;
