import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Affix, Button, Card, Col, Collapse, Flex, Form, message, Row } from 'antd';
import Editor from '@monaco-editor/react';
import { useParams } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import NormalizeAntd from 'components/containers/NormalizeAntd';
import { SupportedLanguages } from 'framework/constants';
import { useDebounce } from 'hooks/useDebounceCallback';
import ClientRequestAPI from 'services/client-request.service';
import pdfMaker from 'services/pdf-maker';
import OfferAPI from 'services/offer.service';
import PdfFormDataAPI from 'services/pdf-form-data.service';
import { IClientRequest } from 'store/client-request/client-request.types';
import { userDataSelector } from 'store/auth/auth.selectors';
import { IOffer, OfferEventType, OfferState } from 'store/offer/offer.types';
import { offerFileUpload, offerTransition } from 'store/offer/offer.actions';
import PDFGenerator from 'utils/pdfGenerator';
import {
  checkIsCustomerPriceEditable,
  checkIsHomeCheck,
  generateOfferFileName,
  getUploadOfferUploadCategory,
} from 'utils/offerHelpers';
import OfferDocumentSimpleForm from './SimpleForm';
import OfferDocumentProductForm from './ProductsForm';
import OfferDocumentPdfPreview from './OfferDocumentPdfPreview';
import OfferDocumentBundlesForm from './BundlesForm';
import useCalculateCosts from './useCalculateCosts';
import CostTable from './CostTable';
import ActionButtons from './ActionButtons';
import styles from './OfferDocumentGenerator.module.scss';
import { IOfferDocumentForm, IOfferDocumentFormJsonData } from './types';

const { jsonToPDF, downloadPDF } = PDFGenerator();

const OfferDocumentGenerator = () => {
  const [pdfJSON, setPdfJSON] = useState<string>();
  const [pdfFormData, setPdfFormData] = useState<IOfferDocumentFormJsonData>();
  const [formDataLoading, setFormDataLoading] = useState(true);
  const [isFormDataSaving, setIsFormDataSaving] = useState(false);
  const [clientRequest, setClientRequest] = useState<IClientRequest>();
  const [offer, setOffer] = useState<IOffer>();
  const [shouldShowCostOverview, setShowCostOverview] = useState<boolean>(false);
  const [pdfContainerSize, setPdfContainerSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });
  const pdfContainerRef = useRef<HTMLDivElement>(null);
  const { requestId, offerId } = useParams();
  const [form] = useForm<IOfferDocumentForm>();
  const { t } = useTranslation();
  const [isOfferSubmitting, setIsOfferSubmitting] = useState(false);
  const [pdfBlob, setPdfBlob] = useState<Blob>();
  const totalCosts = useCalculateCosts(form, clientRequest?.pool?.country.vatRate || 0);
  const dispatch = useDispatch();
  const userData = useSelector(userDataSelector);
  const navigate = useNavigate();
  const goBackToRequest = () => {
    navigate(`/my-projects/client-request/${clientRequest?.id}`);
  };

  useEffect(() => {
    const node = pdfContainerRef.current;

    const updateSize = () => {
      if (node) {
        const { offsetWidth, offsetHeight } = node!;
        setPdfContainerSize({
          width: offsetWidth,
          height: offsetHeight,
        });
      }
    };

    const observer = new ResizeObserver(() => {
      updateSize();
    });

    if (node) observer.observe(node);

    updateSize(); // Set initial size when component mounts

    return () => {
      if (observer && node) {
        observer.unobserve(node);
      }
    };
    // eslint-disable-next-line
  }, [pdfContainerRef?.current]);

  const loadUserLanguage = useCallback(() => {
    if (clientRequest?.owner?.defaultLanguage.isoCode)
      i18next.loadLanguages(clientRequest?.owner?.defaultLanguage.isoCode);
  }, [clientRequest]);
  useEffect(() => {
    loadUserLanguage();
  }, [loadUserLanguage]);
  const fetchClientRequest = async () => {
    if (requestId) {
      try {
        const clientRequest = await ClientRequestAPI.getByIdWithRelations(requestId, [
          'bundle',
          'createdBy',
          'pool',
          'offers',
          'products',
          'owner',
        ]);
        setClientRequest(clientRequest);
      } catch (err) {
        console.log(err);
      }
    }
  };
  const fetchOfferDocument = async () => {
    if (!offerId) return;
    setFormDataLoading(true);
    const formData = await PdfFormDataAPI.getFormData(offerId);
    setFormDataLoading(false);
    if (formData) setPdfFormData(formData);
  };
  const fetchTemplate = async () => {
    if (!clientRequest) return;
    const template = await pdfMaker.template(
      'offer',
      clientRequest.owner?.defaultLanguage.isoCode || 'en',
    );
    setPdfJSON(template.template);
  };
  useEffect(() => {
    fetchTemplate();
    fetchOfferDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientRequest]);
  useEffect(() => {
    fetchClientRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId]);
  const fetchOffer = async () => {
    if (!offerId) return;
    const _offer = await OfferAPI.getById({ id: offerId, relations: ['documents'] });
    setOffer(_offer);
  };
  useEffect(() => {
    fetchOffer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId]);
  const formWatcher = Form.useWatch([], form);
  const debouncedPDFURL = useDebounce((json: string, formData: IOfferDocumentForm) => {
    jsonToPDF(
      json,
      formData,
      clientRequest?.pool?.country.vatRate || 0,
      clientRequest?.pool?.country.currencySymbol || '',
      totalCosts,
      clientRequest?.owner?.defaultLanguage.isoCode || SupportedLanguages.DE,
    )
      .then((pdfBlob) => setPdfBlob(pdfBlob))
      .catch((e) => console.log(e));
  }, 500);
  const downloadPDFHandler = async () => {
    if (!pdfJSON) return;
    try {
      if (!offer) return;
      const uploadCategory = getUploadOfferUploadCategory(offer);
      if (!uploadCategory) return;
      const fileName = generateOfferFileName(userData.id, 'offer', uploadCategory);
      await form.validateFields();
      await downloadPDF(
        fileName,
        pdfJSON,
        formWatcher,
        clientRequest?.pool?.country.vatRate || 0,
        clientRequest?.pool?.country.currencySymbol || '',
        totalCosts,
        clientRequest?.owner?.defaultLanguage.isoCode || SupportedLanguages.DE,
      );
    } catch (error) {
      message.error(t('common:error:pdfGenInputs'));
    }
  };
  const onJSONChanged = useCallback(() => {
    if (pdfJSON) debouncedPDFURL(pdfJSON, formWatcher);
  }, [debouncedPDFURL, pdfJSON, formWatcher]);
  useEffect(() => {
    onJSONChanged();
  }, [onJSONChanged]);
  const cancelHandler = () => {
    goBackToRequest();
  };
  const saveFormDataHandler = async () => {
    try {
      if (!formWatcher) return;
      setIsFormDataSaving(true);
      if (!pdfFormData?.id && offerId) {
        const formData = await PdfFormDataAPI.createFormData(offerId, { jsonField: formWatcher });
        setPdfFormData(formData);
        message.success(t('intermediate:pdfGen:save:success'));
        return;
      }
      if (pdfFormData?.id) {
        await PdfFormDataAPI.updateFormData(pdfFormData.id, { jsonField: formWatcher });
        message.success(t('intermediate:pdfGen:save:success'));
      }
    } catch (e) {
      message.error(t('intermediate:pdfGen:save:error'));
    } finally {
      setIsFormDataSaving(false);
    }
  };
  const offerTransitionHandler = () => {
    if (!offer || !offer.state || !clientRequest) return;
    const isHomeCheck = checkIsHomeCheck(offer.state);

    const clearPrice = totalCosts.total.net;
    const isCustomerPriceEditable = checkIsCustomerPriceEditable(offer, clientRequest);
    let costFields: any = isHomeCheck
      ? {
          costEstimateHomeCheck: isCustomerPriceEditable
            ? clearPrice
            : offer.initialCostEstimateHomeCheck,
        }
      : {
          costEstimate: isCustomerPriceEditable ? clearPrice : offer.initialCostEstimateHomeCheck,
        };

    if (
      offer.state === OfferState.InvoiceSubmittedHomeCheck ||
      offer.state === OfferState.InvoiceSubmittedRemoteHomeCheck ||
      offer.state === OfferState.InvoiceSubmittedInstallation
    ) {
      costFields = isHomeCheck
        ? {
            invoiceHomeCheck: isCustomerPriceEditable ? clearPrice : offer.initialInvoiceHomeCheck,
          }
        : {
            invoiceAmount: isCustomerPriceEditable ? clearPrice : offer.initialInvoiceAmount,
          };
    }
    dispatch(
      offerTransition(
        offer.state === OfferState.InvoiceSubmittedHomeCheck ||
          offer.state === OfferState.InvoiceSubmittedRemoteHomeCheck ||
          offer.state === OfferState.InvoiceSubmittedInstallation
          ? OfferEventType.INTERMEDIATE_ACCEPTED
          : OfferEventType.COORDINATOR_ACCEPTED,
        {
          id: offer.id,
          offerAnnotation: '',
          ...costFields,
        },
        (data: any) => {
          setOffer((prevOffer: any) => ({
            ...prevOffer,
            ...data,
            documents: prevOffer.documents,
            installerUser: prevOffer.installerUser,
          }));
          message.info(t('intermediate:myProjects:offerPublished'));
          setIsOfferSubmitting(false);
          goBackToRequest();
        },
        () => {
          setIsOfferSubmitting(false);
        },
      ),
    );
  };
  const sendOfferHandler = async () => {
    if (!offer || !offerId || !pdfBlob) return;
    try {
      await form.validateFields();
      setIsOfferSubmitting(true);
      const uploadCategory = getUploadOfferUploadCategory(offer);
      if (!uploadCategory) return;
      const file = new File([pdfBlob], 'offer.pdf', {
        type: 'application/pdf',
      });
      dispatch(
        offerFileUpload(
          offerId,
          uploadCategory,
          file,
          () => {},
          offerTransitionHandler,
          () => {
            setIsOfferSubmitting(false);
          },
        ),
      );
    } catch (error) {
      console.log(error);
    }
  };
  const address = clientRequest?.owner?.billingAddress || clientRequest?.owner?.address;

  const initialFormData = useMemo(() => {
    if (!pdfFormData?.jsonField) {
      return {
        offer: {
          date: dayjs(offer?.createdAt),
        },
        request: {
          id: clientRequest?.displayId,
          poolName: clientRequest?.pool?.name,
          agent: `${userData.firstName} ${userData.lastName}`,
        },
        owner: {
          lastName: clientRequest?.owner?.lastName,
          firstName: clientRequest?.owner?.firstName,
          gender: clientRequest?.owner?.gender,
          street: address?.street,
          address2: address?.address2,
          zipCode: address?.postalCode,
          city: address?.city,
          no: address?.houseNumber,
        },
        bundles: [
          {
            id: clientRequest?.bundleId,
          },
        ],
        products: clientRequest?.products,
      };
    }
    return {
      ...pdfFormData.jsonField,
      offer: {
        ...pdfFormData.jsonField.offer,
        date: pdfFormData.jsonField?.offer?.date ? dayjs(pdfFormData.jsonField.offer.date) : '',
      },
    };
  }, [pdfFormData, clientRequest, offer, address, userData]);
  return (
    <NormalizeAntd>
      <Card loading={!clientRequest || !offer || formDataLoading}>
        <Form form={form} layout={'vertical'} initialValues={initialFormData}>
          <Row gutter={8}>
            <Col span={12}>
              <Flex vertical={true} gap={4}>
                <OfferDocumentSimpleForm />
                <OfferDocumentProductForm form={form} clientRequest={clientRequest} />
                <OfferDocumentBundlesForm
                  pdfFormData={pdfFormData}
                  form={form}
                  clientRequest={clientRequest}
                  installationCosts={totalCosts.installation}
                />
                <Collapse ghost={true}>
                  <Collapse.Panel key={'Editor'} header={t('intermediate:pdfGen:editor')}>
                    <Editor
                      height="70vh"
                      language="json"
                      theme="vs-dark"
                      value={pdfJSON}
                      onChange={(value) => setPdfJSON(value)}
                      options={{
                        fontSize: '16px',
                        formatOnType: true,
                        autoClosingBrackets: true,
                        minimap: { scale: 10 },
                      }}
                    />
                  </Collapse.Panel>
                </Collapse>
              </Flex>
            </Col>
            <Col span={12}>
              <div ref={pdfContainerRef}>
                <Flex vertical={true} gap={4} className={styles.pdfPreviewContainer}>
                  <Affix offsetTop={100}>
                    <OfferDocumentPdfPreview pdfBlob={pdfBlob} />
                  </Affix>
                  <Flex
                    vertical={true}
                    gap={10}
                    className={styles.costDiv}
                    style={{ width: `${pdfContainerSize.width}px` }}
                  >
                    <Button
                      type="dashed"
                      className={styles.toggleCostOverview}
                      onClick={() => setShowCostOverview((prev) => !prev)}
                    >
                      {shouldShowCostOverview ? t('common:hide') : t('common:show')}
                      &nbsp;
                      {t('intermediate:pdfGen:costOverviewHeader')}
                    </Button>
                    {shouldShowCostOverview && (
                      <CostTable
                        currency={clientRequest?.pool?.country.currency || ''}
                        totalCosts={totalCosts}
                      />
                    )}
                    <ActionButtons
                      sendOfferClickHandler={sendOfferHandler}
                      saveFormData={saveFormDataHandler}
                      cancelButtonClickHandler={cancelHandler}
                      isFormDataSaving={isFormDataSaving}
                      downloadPDFClickHandler={downloadPDFHandler}
                      isOfferPublishable={totalCosts.total.gross >= 0}
                      isOfferSubmitting={isOfferSubmitting}
                    />
                  </Flex>
                </Flex>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
    </NormalizeAntd>
  );
};

export default OfferDocumentGenerator;
