import React, { useCallback, useEffect, useState } from 'react';
import Typography from 'antd/lib/typography';
import { useDispatch, useSelector } from 'react-redux';
import FilesList from 'components/ui/FilesList/FilesList';
import DropZone from 'components/ui/DropZone/DropZone';
import {
  deleteFile,
  getClientRequestFiles,
  uploadFile,
} from 'store/client-request/client-request.actions';
import { MediaCategoryType } from 'store/client-request/client-request.types';
import { getOfferFiles, offerFileUpload } from 'store/offer/offer.actions';
import { currentOfferFilesSelector } from 'store/offer/offer.selectors';
import { currentClientRequestFilesSelector } from 'store/client-request/client-request.selectors';
import { userDataSelector } from 'store/auth/auth.selectors';
import styles from '../TenderParticipatePage/TenderParticipate.module.sass';

interface IProps {
  title: string;
  offerId?: string;
  requestId?: string;
  fileCategory: MediaCategoryType;
  acceptedFiles?: string;
  accept?: { [key: string]: string[] };
  setFileUploaded: (v: boolean) => void;
  multiple?: boolean;
}

const SectionFileUpload: React.FC<IProps> = (props) => {
  const {
    title,
    fileCategory,
    offerId,
    requestId,
    setFileUploaded,
    multiple = false,
    ...rest
  } = props;

  const dispatch = useDispatch();

  const offerFiles = useSelector(currentOfferFilesSelector);
  const requestFiles = useSelector(currentClientRequestFilesSelector);
  const user = useSelector(userDataSelector);

  const [fileUploading, setFileUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  useEffect(() => {
    if (requestId) {
      dispatch(getClientRequestFiles(requestId));
    }
  }, [dispatch, requestId]);

  useEffect(() => {
    const files = offerId ? offerFiles : requestFiles[fileCategory] || [];
    if (files && files.length) {
      const categoryFiles: any[] = files.filter((f: any) =>
        [f?.document?.category, f?.category].includes(fileCategory),
      );
      if (
        categoryFiles.length &&
        categoryFiles.every((item) =>
          [item.uploadedFiles, item.document?.uploadedById].includes(user.id),
        )
      ) {
        setUploadedFiles(categoryFiles.map((item) => item.document || item));
        setFileUploaded(true);
      } else {
        setUploadedFiles([]);
      }
    } else {
      setUploadedFiles([]);
    }
  }, [offerFiles, requestFiles, fileCategory]); // eslint-disable-line

  const onUploadSuccess = useCallback(
    (document: any) => {
      setFileUploading(false);
      setUploadedFiles([document]);
      setFileUploaded(true);

      if (document.category === MediaCategoryType.OfferInvoiceHomeCheckInstaller) {
        const bidInstallerDoc = offerFiles?.find(
          (f) => f.document.category === MediaCategoryType.OfferBidInstaller,
        );
        if (bidInstallerDoc) {
          dispatch(
            deleteFile(
              bidInstallerDoc.document.name,
              bidInstallerDoc.document.id,
              MediaCategoryType.OfferBidInstaller,
            ),
          );
        }
      }
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onUploadError = useCallback(
    (error: any) => {
      setFileUploading(false);
      setFileUploaded(false);
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onFileAdded = useCallback(
    (file: File) => {
      if (file) {
        setFileUploading(true);
        if (offerId) {
          dispatch(
            offerFileUpload(offerId, fileCategory, file, null, onUploadSuccess, onUploadError),
          );
        }
        if (requestId) {
          dispatch(
            uploadFile(file, requestId, fileCategory, {}, null, onUploadSuccess, onUploadError),
          );
        }
      }
    },
    [offerId, requestId], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onDeleteFile = (fileName: string, fileId: string) => {
    dispatch(
      deleteFile(fileName, fileId, fileCategory, () => offerId && dispatch(getOfferFiles(offerId))),
    );
    setUploadedFiles([]);
    setFileUploaded(false);
  };

  const renderFileUploader = () => {
    const dropZoneProps = {
      onFileDropAccepted: ([file]: File[]) => onFileAdded(file),
      loading: fileUploading,
      multiple: multiple,
      ...rest,
    };

    return (
      <div className={styles.fileUploader}>
        <DropZone {...dropZoneProps} />
      </div>
    );
  };

  return (
    <>
      <Typography className={styles.uploadTitle}>{title}</Typography>
      <FilesList
        files={uploadedFiles}
        onFileDelete={onDeleteFile}
        className={styles.uploadContainer}
      />
      {(Boolean(uploadedFiles.length) && !multiple) || renderFileUploader()}
    </>
  );
};

export default SectionFileUpload;
