import React from 'react';
import Form, { Rule } from 'antd/lib/form';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useTranslation } from 'react-i18next';

import { Tooltip, Typography } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { ValidationFactory } from 'framework/validations';
import styles from './SingleInputContainer.module.sass';
import SingleInputControl from './SingleInputControl';

interface IProps {
  data: string;
  handleChange(path: string, value: any): void;
  path: string;
  visible: boolean;
  uischema: any;
  schema: any;
}

const SingleInputContainer: React.FC<IProps> = (props) => {
  const { visible, uischema, schema, path, data, handleChange } = props;

  const uiOptions = uischema.options;
  const coverImage = uischema.coverImage?.url;

  const { question, description, customErrors, singleFields = {} } = uiOptions; //FIXME: Check why this is NULL in certain cases
  const { title, isInputTextArea } = singleFields;

  const { t } = useTranslation();

  const isDistanceQuestion = uischema.scope === '#/properties/installationDistance';
  const ajvValidationError = customErrors?.ajvValidation
    ? customErrors.ajvValidation
    : 'common:errors:correctNumberFormat';
  const ajvValidation = ValidationFactory.AJV_VALIDATION(schema, ajvValidationError);
  const validationRules: Rule[] = [ajvValidation, ValidationFactory.REQUIRED];

  if (isDistanceQuestion) {
    validationRules.push(ValidationFactory.REQUIRED_DISTANCE, ValidationFactory.NUMBER_INPUT);
  }

  if (!visible) return null;

  return (
    <Form.Item noStyle>
      <Typography.Title level={4}>
        {t(question)}
        {description && (
          <Tooltip title={t(description)} className={styles.description}>
            <InfoCircleTwoTone twoToneColor="#bfbfbf" />
          </Tooltip>
        )}
      </Typography.Title>
      {!!coverImage && <img alt="question cover" src={coverImage} className={styles.coverImage} />}
      <Form.Item rules={validationRules} name={path} initialValue={data}>
        <SingleInputControl
          placeholder={title}
          textArea={isInputTextArea}
          handleInputChange={(value: string) => handleChange(path, value)}
        />
      </Form.Item>
    </Form.Item>
  );
};

export default withJsonFormsControlProps(SingleInputContainer);
