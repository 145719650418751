// Contains all login, logout, register and reset / change password actions
import { IClientRequest, IClientRequestAnswer } from 'store/client-request/client-request.types';
import {
  AUTH_INIT,
  AUTH_FINGERPRINT,
  AUTH_LOGIN,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  AUTH_LOGOUT_ERROR,
  AUTH_LOGOUT_SUCCESS,
  AUTH_PASSWORD_RESET_CONFIRM,
  AUTH_PASSWORD_RESET_CONFIRM_ERROR,
  AUTH_PASSWORD_RESET_CONFIRM_SUCCESS,
  AUTH_PASSWORD_RESET_REQUEST,
  AUTH_PASSWORD_RESET_REQUEST_ERROR,
  AUTH_PASSWORD_RESET_REQUEST_SUCCESS,
  AUTH_PASSWORD_RESET_UPDATE,
  AUTH_PASSWORD_RESET_UPDATE_ERROR,
  AUTH_PASSWORD_RESET_UPDATE_SUCCESS,
  AUTH_REGISTER,
  AUTH_REGISTER_ERROR,
  AUTH_REGISTER_SUCCESS,
  AUTH_TOKEN_REFRESH,
  AUTH_TOKEN_REFRESH_ERROR,
  AUTH_TOKEN_REFRESH_SUCCESS,
  AUTH_VERIFY_EMAIL,
  AUTH_VERIFY_EMAIL_ERROR,
  AUTH_VERIFY_EMAIL_SUCCESS,
  AUTH_VERIFY_CHANGE_EMAIL,
  AUTH_VERIFY_CHANGE_EMAIL_SUCCESS,
  AUTH_VERIFY_CHANGE_EMAIL_ERROR,
  IUserCredentials,
  IUserObject,
  IUserRegister,
  IVerifyEmail,
  AUTH_VERIFY_INSTALLER,
  AUTH_VERIFY_INSTALLER_SUCCESS,
  AUTH_VERIFY_INSTALLER_ERROR,
  AUTH_CHANGE_PASSWORD,
  AUTH_CHANGE_PASSWORD_SUCCESS,
  ChangePasswordPayloadType,
  AUTH_CHANGE_PASSWORD_ERROR,
  AUTH_CHANGE_EMAIL,
  AUTH_CHANGE_EMAIL_SUCCESS,
  AUTH_CHANGE_EMAIL_ERROR,
  ChangeEmailPayloadType,
  AUTH_TOKEN_LOGIN,
  AUTH_TOKEN_LOGIN_SUCCESS,
  AUTH_TOKEN_LOGIN_ERROR,
  VERIFY_PASSWORD_RESET_TOKEN,
  VERIFY_PASSWORD_RESET_TOKEN_SUCCESS,
  VERIFY_PASSWORD_RESET_TOKEN_ERROR,
  IPassResetTypes,
} from './auth.types';

export const authInit = (onSuccess?: any) => ({
  type: AUTH_INIT,
  payload: { onSuccess },
});

export const getFingerprint = (fingerprint: string) => ({
  type: AUTH_FINGERPRINT,
  payload: { fingerprint },
});

export const login = (credentials: IUserCredentials) => ({
  type: AUTH_LOGIN,
  payload: credentials,
});

export const loginSuccess = (user: IUserObject, token: string) => ({
  type: AUTH_LOGIN_SUCCESS,
  payload: { user, token },
});

export const loginError = (error: any) => ({
  type: AUTH_LOGIN_ERROR,
  payload: error,
});

export const tokenLogin = (token: string) => ({
  type: AUTH_TOKEN_LOGIN,
  payload: token,
});

export const tokenLoginSuccess = (user: IUserObject, token: string) => ({
  type: AUTH_TOKEN_LOGIN_SUCCESS,
  payload: { user, token },
});

export const tokenLoginError = (error: any) => ({
  type: AUTH_TOKEN_LOGIN_ERROR,
  payload: error,
});

export const tokenRefresh = (onSuccess?: any) => ({
  type: AUTH_TOKEN_REFRESH,
  payload: { onSuccess },
});

export const tokenRefreshSuccess = (user: IUserObject, token: string) => ({
  type: AUTH_TOKEN_REFRESH_SUCCESS,
  payload: { user, token },
});

export const tokenRefreshError = (error: any) => ({
  type: AUTH_TOKEN_REFRESH_ERROR,
  payload: error,
});

export const passwordResetRequest = (email: string, onSuccess: any) => ({
  type: AUTH_PASSWORD_RESET_REQUEST,
  payload: { email, onSuccess },
});

export const passwordResetRequestSuccess = () => ({
  type: AUTH_PASSWORD_RESET_REQUEST_SUCCESS,
});

export const passwordResetRequestError = (error: any) => ({
  type: AUTH_PASSWORD_RESET_REQUEST_ERROR,
  payload: error,
});

export const verifyPasswordResetToken = (token: string) => ({
  type: VERIFY_PASSWORD_RESET_TOKEN,
  payload: token,
});

export const verifyPasswordResetTokenSuccess = (data: IPassResetTypes) => ({
  type: VERIFY_PASSWORD_RESET_TOKEN_SUCCESS,
  payload: data,
});

export const verifyPasswordResetTokenError = (error: any) => ({
  type: VERIFY_PASSWORD_RESET_TOKEN_ERROR,
  payload: error,
});

export const passwordResetConfirm = (token: string) => ({
  type: AUTH_PASSWORD_RESET_CONFIRM,
  payload: token,
});

export const passwordResetConfirmSuccess = () => ({
  type: AUTH_PASSWORD_RESET_CONFIRM_SUCCESS,
});

export const passwordResetConfirmError = (error: any) => ({
  type: AUTH_PASSWORD_RESET_CONFIRM_ERROR,
  payload: error,
});

export const passwordResetUpdate = (token: string, password: string, userId?: string) => ({
  type: AUTH_PASSWORD_RESET_UPDATE,
  payload: { token, password, userId },
});

export const passwordResetUpdateSuccess = () => ({
  type: AUTH_PASSWORD_RESET_UPDATE_SUCCESS,
});

export const passwordResetUpdateError = (error: any) => ({
  type: AUTH_PASSWORD_RESET_UPDATE_ERROR,
  payload: error,
});

export const changePassword = (payload: ChangePasswordPayloadType) => ({
  type: AUTH_CHANGE_PASSWORD,
  payload,
});

export const changePasswordSuccess = () => ({
  type: AUTH_CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordError = (error: any) => ({
  type: AUTH_CHANGE_PASSWORD_ERROR,
  payload: error,
});

export const logout = () => ({
  type: AUTH_LOGOUT,
});

export const logoutSuccess = () => ({
  type: AUTH_LOGOUT_SUCCESS,
});

export const logoutError = (error: any) => ({
  type: AUTH_LOGOUT_ERROR,
});

export const register = (
  user: IUserRegister,
  clientRequest?: IClientRequest,
  answers?: IClientRequestAnswer[],
) => ({
  type: AUTH_REGISTER,
  payload: { user, clientRequest, answers },
});

export const registerSuccess = (user: IUserObject, token: string) => ({
  type: AUTH_REGISTER_SUCCESS,
  payload: { user, token },
});

export const registerError = (error: any) => ({
  type: AUTH_REGISTER_ERROR,
  payload: error,
});

export const verifyEmail = (verifyEmailToken: IVerifyEmail) => ({
  type: AUTH_VERIFY_EMAIL,
  payload: verifyEmailToken,
});

export const verifyEmailSuccess = () => ({
  type: AUTH_VERIFY_EMAIL_SUCCESS,
});

export const verifyEmailError = (error: any) => ({
  type: AUTH_VERIFY_EMAIL_ERROR,
  payload: error,
});

export const verifyChangeEmail = (changeEmailToken: IVerifyEmail) => ({
  type: AUTH_VERIFY_CHANGE_EMAIL,
  payload: changeEmailToken,
});

export const verifyChangeEmailSuccess = () => ({
  type: AUTH_VERIFY_CHANGE_EMAIL_SUCCESS,
});

export const verifyChangeEmailError = (error: any) => ({
  type: AUTH_VERIFY_CHANGE_EMAIL_ERROR,
  payload: error,
});

export const changeEmail = (payload: ChangeEmailPayloadType) => ({
  type: AUTH_CHANGE_EMAIL,
  payload,
});

export const changeEmailSuccess = () => ({
  type: AUTH_CHANGE_EMAIL_SUCCESS,
});

export const changeEmailError = (error: any) => ({
  type: AUTH_CHANGE_EMAIL_ERROR,
  payload: error,
});

export const verifyInstaller = () => ({
  type: AUTH_VERIFY_INSTALLER,
});

export const verifyInstallerSuccess = (status: boolean) => ({
  type: AUTH_VERIFY_INSTALLER_SUCCESS,
  payload: status,
});

export const verifyInstallerError = (error: any) => ({
  type: AUTH_VERIFY_INSTALLER_ERROR,
  payload: error,
});
