import { IPool } from 'store/intermediate/intermediate.types';
import {
  IInstallerData,
  INSTALLER_DATA_GET,
  INSTALLER_DATA_GET_ERROR,
  INSTALLER_DATA_GET_SUCCESS,
  INSTALLER_DATA_UPDATE,
  INSTALLER_DATA_UPDATE_ERROR,
  INSTALLER_DATA_UPDATE_SUCCESS,
  JOINING_POOL_CONFIRM,
  JOINING_POOL_CONFIRM_ERROR,
  JOINING_POOL_CONFIRM_SUCCESS,
  INSTALLER_INVITATIONS_GET,
  INSTALLER_INVITATIONS_GET_SUCCESS,
  INSTALLER_INVITATIONS_GET_ERROR,
  INSTALLER_ORGANIZATION_GET,
  INSTALLER_ORGANIZATION_GET_SUCCESS,
  INSTALLER_ORGANIZATION_GET_ERROR,
  INSTALLER_SUBMIT_LICENSES,
  INSTALLER_SUBMIT_LICENSES_SUCCESS,
  INSTALLER_SUBMIT_LICENSES_ERROR,
  INSTALLER_GET_POOLS,
  INSTALLER_GET_POOLS_SUCCESS,
  INSTALLER_GET_POOLS_ERROR,
} from './installer.types';

//Confirm Joining Pool
export const joiningPoolConfirm = (tokenId: string) => ({
  type: JOINING_POOL_CONFIRM,
  payload: tokenId,
});

export const joiningPoolConfirmSuccess = (payload: any) => ({
  type: JOINING_POOL_CONFIRM_SUCCESS,
  payload: payload,
});

export const joiningPoolConfirmError = (error: any) => ({
  type: JOINING_POOL_CONFIRM_ERROR,
  payload: error,
});

//Get Installer Data
export const installerDataGet = () => ({
  type: INSTALLER_DATA_GET,
});

export const installerDataGetSuccess = (payload: IInstallerData) => ({
  type: INSTALLER_DATA_GET_SUCCESS,
  payload: payload,
});

export const installerDataGetError = (error: any) => ({
  type: INSTALLER_DATA_GET_ERROR,
  payload: error,
});

//Update Installer Data
export const installerDataUpdate = (payload: IInstallerData) => ({
  type: INSTALLER_DATA_UPDATE,
  payload: payload,
});

export const installerDataUpdateSuccess = (payload: IInstallerData) => ({
  type: INSTALLER_DATA_UPDATE_SUCCESS,
  payload: payload,
});

export const installerDataUpdateError = (error: any) => ({
  type: INSTALLER_DATA_UPDATE_ERROR,
  payload: error,
});

// Get list of invites
export const installerInvitationsGet = (onSuccess: Function) => ({
  type: INSTALLER_INVITATIONS_GET,
  payload: {
    onSuccess,
  },
});

export const installerInvitationsGetSuccess = (payload: any) => ({
  type: INSTALLER_INVITATIONS_GET_SUCCESS,
  payload,
});

export const installerInvitationsGetError = (error: any) => ({
  type: INSTALLER_INVITATIONS_GET_ERROR,
  payload: error,
});

// Get installer organization data
export const getInstallerOrganization = (id: string) => ({
  type: INSTALLER_ORGANIZATION_GET,
  payload: id,
});

export const getInstallerOrganizationSuccess = (payload: any) => ({
  type: INSTALLER_ORGANIZATION_GET_SUCCESS,
  payload: payload,
});

export const getInstallerOrganizationError = (error: any) => ({
  type: INSTALLER_ORGANIZATION_GET_ERROR,
  payload: error,
});

// Submit Licenses
export const submitLicenses = () => ({
  type: INSTALLER_SUBMIT_LICENSES,
});

export const submitLicensesSuccess = () => ({
  type: INSTALLER_SUBMIT_LICENSES_SUCCESS,
});

export const submitLicensesError = (error: any) => ({
  type: INSTALLER_SUBMIT_LICENSES_ERROR,
  payload: error,
});

// Get pools an installer has access to
export const getInstallerPools = () => ({
  type: INSTALLER_GET_POOLS,
});

export const getInstallerPoolsSuccess = (payload: Array<IPool>) => ({
  type: INSTALLER_GET_POOLS_SUCCESS,
  payload,
});

export const getInstallerPoolsError = (error: any) => ({
  type: INSTALLER_GET_POOLS_ERROR,
  payload: error,
});
