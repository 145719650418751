import { all, call, put, takeLatest } from 'redux-saga/effects';
import OrganizationAPI from 'services/organization.service';
import {
  GetDefaultSettingsAction,
  GET_DEFAULT_SETTINGS,
  IOrganizationDefaultSettings,
  SaveDefaultSettingsAction,
  SAVE_DEFAULT_SETTINGS,
} from './organization.types';
import {
  getDefaultSettingsError,
  getDefaultSettingsSuccess,
  saveDefaultSettingsError,
  saveDefaultSettingsSuccess,
} from './organization.actions';

export function* getDefaultSettings(_action: GetDefaultSettingsAction) {
  try {
    const result: IOrganizationDefaultSettings = yield call(
      OrganizationAPI.getOrganizationSettings,
    );
    if (result) {
      yield put(getDefaultSettingsSuccess(result));
    } else {
      yield put(getDefaultSettingsError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(getDefaultSettingsError(message));
  }
}

export function* saveDefaultSettings(action: SaveDefaultSettingsAction) {
  try {
    const result: IOrganizationDefaultSettings = yield call(
      OrganizationAPI.saveOrganizationSettings,
      action.payload,
    );
    if (result) {
      yield put(saveDefaultSettingsSuccess(result));
    } else {
      yield put(saveDefaultSettingsError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(saveDefaultSettingsError(message));
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_DEFAULT_SETTINGS, getDefaultSettings),
    takeLatest(SAVE_DEFAULT_SETTINGS, saveDefaultSettings),
  ]);
}
