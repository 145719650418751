import React from 'react';

import { SupportedLanguages } from 'framework/constants';
import ImprintComponentDe from './Imprint.de';
import ImprintComponentEn from './Imprint.en';
import ImprintComponentFr from './Imprint.fr';
import ImprintComponentIt from './Imprint.it';

type ImprintProps = {
  language: string;
};

const ImprintComponents: Record<SupportedLanguages, React.FC> = {
  de: ImprintComponentDe,
  en: ImprintComponentEn,
  fr: ImprintComponentFr,
  it: ImprintComponentIt,
};

const Imprint = (props: ImprintProps) => {
  const Content = ImprintComponents[props.language as SupportedLanguages];
  return <Content />;
};

export default Imprint;
