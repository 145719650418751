import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { SupportedLanguages } from 'framework/constants';
import { TLanguageBasedContent, TSupportedLanguagesKey } from 'types/language.types';
import { getLanguageKey, keyBasedLanguageSelectorOptions } from 'utils/supprotedLanguagesUtils';

const useStateBasedLanguageHandler = <Content>(
  defaultLanguage: SupportedLanguages,
  defaultContent: Content,
  languageBasedContents: TLanguageBasedContent<Content>[],
  setLanguageBasedContents: Dispatch<SetStateAction<TLanguageBasedContent<Content>[]>>,
) => {
  const defaultLanguageKey = useMemo(() => getLanguageKey(defaultLanguage), [defaultLanguage]);

  const getLanguageChanger = useCallback(
    (languageIndex: number) => (language: TSupportedLanguagesKey) =>
      setLanguageBasedContents((_languageBasedContents) => {
        const clonedLanguageBasedContents = [..._languageBasedContents];
        clonedLanguageBasedContents[languageIndex].language = language;
        return clonedLanguageBasedContents;
      }),
    [setLanguageBasedContents],
  );
  const possibleOptions = useMemo(
    () =>
      keyBasedLanguageSelectorOptions.filter(
        (_languageSelectorOption) =>
          !(
            languageBasedContents.some(
              (_languageBasedContents) =>
                _languageBasedContents.language === _languageSelectorOption.value,
            ) || _languageSelectorOption.value === defaultLanguageKey
          ),
      ),
    [languageBasedContents, defaultLanguageKey],
  );

  const addTranslation = useCallback(() => {
    if (possibleOptions.length === 0) return;
    setLanguageBasedContents((_languageBasedContents) =>
      _languageBasedContents.concat({
        language: possibleOptions[0].value as TSupportedLanguagesKey,
        content: defaultContent,
      }),
    );
  }, [setLanguageBasedContents, possibleOptions, defaultContent]);
  const removeTranslation = useCallback(
    (removingLanguage: TSupportedLanguagesKey) => {
      if (removingLanguage === defaultLanguageKey) return;
      setLanguageBasedContents((_languageBasedContents) =>
        _languageBasedContents.filter(
          (_languageBasedContents) => _languageBasedContents.language !== removingLanguage,
        ),
      );
    },
    [setLanguageBasedContents, defaultLanguageKey],
  );
  return {
    addTranslation,
    removeTranslation,
    getLanguageChanger,
    possibleOptions,
    defaultLanguageKey,
  };
};
export default useStateBasedLanguageHandler;
