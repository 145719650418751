import { createSelector } from 'reselect';
import {
  USER_ROLE_ADMIN,
  USER_ROLE_INSTALLER,
  USER_ROLE_CUSTOMER,
  USER_ROLE_INSTALLER_MANAGER,
  USER_ROLE_INTERMEDIATE_MANAGER,
} from 'framework/constants';
import { IAuthState } from './auth.types';

export const authSelector = (state: any) => state.auth;

export const loginStateSelector = createSelector(
  authSelector,
  (auth: IAuthState) => auth.loginState,
);

export const isAdminSelector = createSelector(
  authSelector,
  (auth: IAuthState) => auth.user.roles && auth.user.roles.indexOf(USER_ROLE_ADMIN) !== -1,
);
export const isCustomerSelector = createSelector(
  authSelector,
  (auth: IAuthState) => auth.user.roles && auth.user.roles.indexOf(USER_ROLE_CUSTOMER) !== -1,
);
export const isInstallerSelector = createSelector(
  authSelector,
  (auth: IAuthState) => auth.user.roles && auth.user.roles.indexOf(USER_ROLE_INSTALLER) !== -1,
);
export const isInstallerManagerSelector = createSelector(
  authSelector,
  (auth: IAuthState) =>
    auth.user.roles && auth.user.roles.indexOf(USER_ROLE_INSTALLER_MANAGER) !== -1,
);
export const isIntermediateManagerSelector = createSelector(
  authSelector,
  (auth: IAuthState) =>
    auth.user.roles && auth.user.roles.indexOf(USER_ROLE_INTERMEDIATE_MANAGER) !== -1,
);
export const isAuthorizedSelector = createSelector(
  authSelector,
  (auth: IAuthState) => !!auth.token,
);

export const customerRegistrationSelector = createSelector(authSelector, (auth: IAuthState) => ({
  registrationState: auth.customerRegistrationState,
  registrationError: auth.customerRegistrationError,
}));

export const userSelector = createSelector(authSelector, (auth: IAuthState) => auth.user);
export const isUserFetchingSelector = createSelector(
  authSelector,
  (auth: IAuthState) => auth.loading,
);
export const userDataSelector = createSelector(authSelector, (auth: IAuthState) => auth.user);

export const passVerifyTokenDataSelector = createSelector(
  authSelector,
  (auth: IAuthState) => auth.dataByToken,
);
export const passVerifyTokenLoadingSelector = createSelector(
  authSelector,
  (auth: IAuthState) => auth.verifyPasswordResetTokenLoading,
);

// Flag to show if password reset request succeed
export const isPasswordResetRequestSucceedSelector = createSelector(
  authSelector,
  (auth: IAuthState) => auth.passwordResetRequestSuccess,
);

// Flags to show if actual password update after password
// reset request succeed or failed.
export const isPasswordResetUpdateSucceedSelector = createSelector(
  authSelector,
  (auth: IAuthState) => auth.passwordResetUpdateSuccess,
);
export const isPasswordResetUpdateFailedSelector = createSelector(
  authSelector,
  (auth: IAuthState) => auth.passwordResetUpdateError,
);

// Flags for email verification
export const isVerifyingEmailSelector = createSelector(
  authSelector,
  (auth: IAuthState) => auth.verifyingEmail,
);
export const isVerifyEmailSucceededSelector = createSelector(
  authSelector,
  (auth: IAuthState) => auth.verifyEmailSuccess,
);
export const isVerifyEmailFailedSelector = createSelector(
  authSelector,
  (auth: IAuthState) => auth.verifyEmailError,
);

// Flags for change email verification
export const isVerifyChangeEmailLoadingSelector = createSelector(
  authSelector,
  (auth: IAuthState) => auth.changeEmailLoading,
);
export const isVerifyChangeEmailSuccessSelector = createSelector(
  authSelector,
  (auth: IAuthState) => auth.changeEmailSuccess,
);
export const isVerifyChangeEmailErrorSelector = createSelector(
  authSelector,
  (auth: IAuthState) => auth.changeEmailError,
);

// Flags for installer verification
export const isVerifyingInstallerSelector = createSelector(
  authSelector,
  (auth: IAuthState) => auth.verifyingInstaller,
);
export const isVerifyInstallerSucceededSelector = createSelector(
  authSelector,
  (auth: IAuthState) => auth.verifyInstallerSuccess,
);
export const isVerifyInstallerFailedSelector = createSelector(
  authSelector,
  (auth: IAuthState) => auth.verifyInstallerError,
);

// Fingerprint
export const fingerprintSelector = createSelector(
  authSelector,
  (auth: IAuthState) => auth.fingerprint,
);
