import { RequestState } from '../common.types';

export enum FeedbackReceiverType {
  Installers = 'installers',
  Organizations = 'organizations',
  Platform = 'platform',
}

export enum FeedbackReferenceType {
  ClientRequests = 'client_requests',
  Offers = 'offers',
}

export interface IFeedback {
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  authorId?: string;
  receiverId?: string;
  receiverType?: FeedbackReceiverType;
  referenceId: string;
  referenceType: FeedbackReferenceType;
  text?: string;
  rating: number;
}

export interface IFeedbackState {
  feedback: IFeedback[];
  feedbackState: RequestState | null;
  feedbackError: string | null;

  createFeedbackState: RequestState | null;
  createFeedbackError: string | null;
}

export const FEEDBACK_GET = 'FEEDBACK_GET';
export const FEEDBACK_GET_SUCCESS = 'FEEDBACK_GET_SUCCESS';
export const FEEDBACK_GET_ERROR = 'FEEDBACK_GET_ERROR';

export const FEEDBACK_CREATE = 'FEEDBACK_CREATE';
export const FEEDBACK_CREATE_SUCCESS = 'FEEDBACK_CREATE_SUCCESS';
export const FEEDBACK_CREATE_ERROR = 'FEEDBACK_CREATE_ERROR';

// Get feedback
export interface FeedbackGetAction {
  type: typeof FEEDBACK_GET;
  payload: {
    referenceId: string;
    referenceType: string;
  };
}

export interface FeedbackGetSuccessAction {
  type: typeof FEEDBACK_GET_SUCCESS;
  payload: IFeedback[];
}

export interface FeedbackGetErrorAction {
  type: typeof FEEDBACK_GET_ERROR;
  payload: any;
}

// Create feedback
export interface FeedbackCreateAction {
  type: typeof FEEDBACK_CREATE;
  payload: IFeedback[];
}

export interface FeedbackCreateSuccessAction {
  type: typeof FEEDBACK_CREATE_SUCCESS;
  payload: IFeedback[];
}

export interface FeedbackCreateErrorAction {
  type: typeof FEEDBACK_CREATE_ERROR;
  payload: any;
}

export type FeedbackActionTypes =
  | FeedbackGetAction
  | FeedbackGetSuccessAction
  | FeedbackGetErrorAction
  | FeedbackCreateAction
  | FeedbackCreateSuccessAction
  | FeedbackCreateErrorAction;
