import React from 'react';
import Typography from 'antd/lib/typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { default as ANTModal } from 'antd/lib/modal';
import manWithWoman from 'assets/images/man-with-woman.svg';
import NextButton from 'components/ui/NextButton/NextButton';
import Loader from 'components/ui/Loader/Loader';
import { updateTransitionState } from 'store/client-request/client-request.actions';
import { ClientRequestTransitionTypes } from 'store/client-request/client-request.types';
import {
  currentClientRequestSelector,
  transitionStateSelector,
} from 'store/client-request/client-request.selectors';
import { RequestState } from 'store/common.types';
import styles from './RequestSuccessStep.module.sass';

const modalProps = {
  centered: true,
  closable: false,
  footer: null,
  open: true,
  width: '1312px',
};

const RequestSuccessStep: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const request = useSelector(currentClientRequestSelector);
  const { id } = useParams();
  const transitionState = useSelector(transitionStateSelector);

  const onNextClick = () => {
    dispatch(
      updateTransitionState(
        id!,
        ClientRequestTransitionTypes.documentsUploaded,
        undefined,
        () => navigate(`/customer/dashboard`),
        { step: request?.step! + 1, subStep: 1 },
      ),
    );
  };

  return transitionState === RequestState.Loading ? (
    <Loader />
  ) : (
    <ANTModal {...modalProps}>
      <div className={styles.container}>
        <div>
          <h3 className={styles.title}>{t('customerFlow:clientRequest:requestSuccessTitle')}</h3>
          <Typography.Paragraph className={styles.text}>
            {t('customerFlow:clientRequest:requestSuccessText')}
          </Typography.Paragraph>
          <NextButton onClick={onNextClick}>{t('common:buttons:further')}</NextButton>
        </div>
        <img className={styles.manImage} src={manWithWoman} alt="Man with woman" />
      </div>
    </ANTModal>
  );
};

export default RequestSuccessStep;
