import React from 'react';
import { default as ANTModal, ModalProps } from 'antd/lib/modal';
import Typography from 'antd/lib/typography';
import { useTranslation } from 'react-i18next';
import styles from './SuccessModal.module.sass';
import icon from '../../../assets/icons/correct-green.svg';
import Button, { ButtonColor } from '../../ui/Button/Button';

interface Props extends ModalProps {
  onOkayClick: () => void;
  modalText?: string;
  open: boolean;
}

const SuccessModal: React.FC<Props> = (props) => {
  const { onOkayClick, modalText, open } = props;
  const { t } = useTranslation();

  const modalProps = {
    centered: true,
    closable: false,
    footer: null,
    width: 680,
    open,
  };

  return (
    <ANTModal {...modalProps} wrapClassName={styles.container}>
      <img src={icon} alt="" className={styles.icon} />
      <Typography.Title className={styles.title}>{t('common:texts:success')}</Typography.Title>
      <p className={styles.text}>{modalText}</p>
      <div className={styles.button}>
        <Button onClick={onOkayClick} color={ButtonColor.GREEN} type="primary">
          Okay
        </Button>
      </div>
    </ANTModal>
  );
};

export default SuccessModal;
