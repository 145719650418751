import React from 'react';
import { DEFAULT_STEPPER_CONFIG } from '../framework/constants';

type StepperConfigType = {
  wallboxStep: number;
  costCalculationStep: number;
  requestStep: number;
  offerStep: number;
  installationStep: number;
};

const StepperConfigContext = React.createContext(DEFAULT_STEPPER_CONFIG as StepperConfigType);

export default StepperConfigContext;
