import React, { useContext, useEffect, useState } from 'react';
import { redirect, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import _get from 'lodash/get';

import { notification } from 'antd';
import Skeleton from 'antd/lib/skeleton';
import BoxContent from 'components/layout/BoxContent/BoxContent';
import OfferWelcomeStep, {
  OfferWelcomeStepType,
} from 'components/steps/OfferWelcomeStep/OfferWelcomeStep';
import OfferReviewStep from 'components/steps/OfferReviewStep/OfferReviewStep';
import OfferSubmitStep, {
  OfferSubmitStepType,
} from 'components/steps/OfferSubmitStep/OfferSubmitStep';
import OffersListStep from 'components/steps/OffersListStep/OffersListStep';
import OfferWaitingForOffersStep from 'components/steps/OfferWaitingForOffers/OfferWaitingForOffersStep';
import WaitingStep, { WaitingStepType } from 'components/steps/WaitingStep/WaitingStep';

import StepperConfigContext from 'context/stepper.context';
import { STEPS_CONFIG } from 'framework/constants';
import { changeStep } from 'store/app/app.actions';
import {
  clientRequestSelector,
  isQuestionsSelector,
} from 'store/client-request/client-request.selectors';
import { activeSubStepSelector } from 'store/app/app.selectors';
import {
  currentOfferSelector,
  offersListSelector,
  offersListStateSelector,
} from 'store/offer/offer.selectors';
import { getOffersList } from 'store/offer/offer.actions';
import { RequestState } from 'store/common.types';
import {
  ClientRequestState,
  WallboxDeliveryStatus,
} from 'store/client-request/client-request.types';
import { IOffer, OfferApproval, OfferState } from 'store/offer/offer.types';
import { getComments } from 'store/client-request/client-request.actions';

import { showModal } from 'store/delivery-date/delivery-date.module';
import styles from './OfferPage.module.sass';

const OfferPage: React.FC = () => {
  const { t } = useTranslation();
  const { id: clientRequestId } = useParams() as { id: string };
  const [selectOfferStep, setSelectOfferStep] = useState<number>(1);
  const [selectedOffer, setSelectedOffer] = useState<IOffer | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const activeSubStep = useSelector(activeSubStepSelector);
  const request = useSelector(clientRequestSelector);
  const offer = useSelector(currentOfferSelector);
  const offersList = useSelector(offersListSelector); // all-offers-for-customer
  const offersListState = useSelector(offersListStateSelector);
  const questionsList = useSelector(isQuestionsSelector);

  const { offerStep } = useContext(StepperConfigContext);
  const isElto = !!_get(request, 'clientRequest.createdBy.createdByClientId', false);

  const isOfferWaiting = offer?.state === OfferState.InstallationPending;
  const isOfferSelection =
    (_get(request, 'clientRequest.state', '') === ClientRequestState.OfferSelectionHomeCheck ||
      _get(request, 'clientRequest.state', '') === ClientRequestState.HomeCheck ||
      _get(request, 'clientRequest.state', '') === ClientRequestState.OfferSelectionInstallation) &&
    offersList; // customer need to select one from Offers List, CR state is HomeCheck when the home check offer is completed but final installation offer is not selected

  const HAS_OFFER = Boolean(offer);
  const SHOW_EDIT_BUTTON =
    _get(request, 'clientRequest.state', '') === ClientRequestState.ApprovalPending &&
    !_get(request, 'clientRequest.createdBy.createdByClientId', '');
  const SHOW_QUESTIONS_STEPS = Boolean(selectedOffer);
  const SHOW_SELECT_OFFER_STEPS = Boolean(!HAS_OFFER && isOfferSelection && offersList?.length);
  const SHOW_ONLY_COMMENTS = Boolean(!offer && !offersList?.length && questionsList.length);
  const SHOW_BACK_BUTTON = activeSubStep > 0 && !isOfferWaiting;

  const wallboxDeliveryStatus = _get(request, 'clientRequest.wallboxDeliveryStatus', null);
  const hasProducts = !!_get(request, 'clientRequest.products.length', null);

  const showDeliveryButton =
    !hasProducts &&
    wallboxDeliveryStatus &&
    wallboxDeliveryStatus !== WallboxDeliveryStatus.DeliveredManually;

  useEffect(() => {
    dispatch(getComments(clientRequestId));
  }, []); // eslint-disable-line

  useEffect(() => {
    dispatch(changeStep(offerStep, STEPS_CONFIG.offer.defaultActiveSubStep));
  }, [offerStep]); // eslint-disable-line

  useEffect(() => {
    const isRejected = _get(request, 'clientRequest.rejectReason', '');

    dispatch(getOffersList(clientRequestId));

    if (isRejected) {
      notification.error({
        message: t('customerFlow:offer:requestRejected'),
        description: isRejected,
        duration: 0,
      });
    }
  }, []); // eslint-disable-line

  const isSkipStep = (offer: IOffer) => {
    return (
      ((offer.state === OfferState.TenderAcceptedHomeCheck ||
        offer.state === OfferState.TenderAcceptedRemoteHomeCheck) &&
        request?.clientRequest?.homeCheckOfferApproval === OfferApproval.PayByIntermediate) ||
      (offer.state === OfferState.TenderAccepted &&
        request?.clientRequest?.installationOfferApproval === OfferApproval.PayByIntermediate)
    );
  };

  const nextStep = (newOffer?: IOffer) => {
    const offer = newOffer || selectedOffer;
    const skipStep = !!offer && isSkipStep(offer);
    dispatch(changeStep(offerStep, activeSubStep + 1 + +skipStep));
  };

  const handleBackClick = () => {
    const skipStep = !!selectedOffer && isSkipStep(selectedOffer);
    if (selectedOffer && (activeSubStep === 1 || skipStep)) {
      setSelectedOffer(null);
    }
    dispatch(changeStep(offerStep, activeSubStep - 1 - +skipStep));
  };

  const handleEditClientRequest = () => {
    const { id } = request.clientRequest;
    navigate(`/customer/client-request/${id}/review`);
  };

  const handleOfferItemClick = (index: number) => {
    const selectedOffer = offersList ? offersList[index] : null;
    setSelectedOffer(selectedOffer);
    nextStep(selectedOffer);
  };

  const renderWaitingForOffer = () => (
    <OfferWaitingForOffersStep
      isElto={isElto}
      showButton={SHOW_EDIT_BUTTON}
      onEditClick={handleEditClientRequest}
      isDeliveryButton={showDeliveryButton}
      onDeliveryDateClick={() => dispatch(showModal(true))}
    />
  );

  const renderLoader = () => (
    <div className={styles.loader}>
      <Skeleton
        active={true}
        paragraph={{
          rows: 3,
        }}
        round={true}
      />
    </div>
  );

  const renderSelectOfferSteps = () => {
    const welcomeStepType = SHOW_ONLY_COMMENTS
      ? OfferWelcomeStepType.HAS_ONLY_COMMENTS
      : OfferWelcomeStepType.HAS_OFFERS;

    switch (selectOfferStep) {
      case 1:
        return (
          <OfferWelcomeStep type={welcomeStepType} onNextClick={() => setSelectOfferStep(2)} />
        );

      case 2:
        // @ts-ignore
        return <OffersListStep list={offersList} onOfferClick={handleOfferItemClick} />;

      default:
        return null;
    }
  };

  const renderQuestionsSteps = () => {
    if (!selectedOffer) return renderWaitingForOffer();

    const { state } = selectedOffer;
    const isHomeCheck =
      state === OfferState.TenderAcceptedHomeCheck ||
      state === OfferState.TenderAcceptedRemoteHomeCheck;
    const submitScreenType = isHomeCheck
      ? OfferSubmitStepType.SELECTING
      : OfferSubmitStepType.CUSTOMER_ACCEPTED;

    switch (activeSubStep) {
      case 1:
        return <OfferReviewStep offer={selectedOffer} onConfirm={nextStep} />;

      case 2:
        return <OfferSubmitStep offer={selectedOffer} type={submitScreenType} />;

      default:
        return <div>Something went wrong! Unknown sub-step {activeSubStep}</div>;
    }
  };

  const renderContentBasedOnOffer = () => {
    if (SHOW_QUESTIONS_STEPS) {
      return renderQuestionsSteps();
    }

    if (SHOW_SELECT_OFFER_STEPS) {
      return renderSelectOfferSteps();
    }

    if (SHOW_ONLY_COMMENTS) {
      return renderSelectOfferSteps();
    }

    if (offer) {
      switch (offer.state) {
        case OfferState.OfferAccepted:
        case OfferState.OfferAcceptedHomeCheckAppointmentNA:
        case OfferState.OfferAcceptedRemoteHomeCheckAppointmentNA:
          if (request?.clientRequest?.homeCheckOfferApproval === OfferApproval.PayByIntermediate) {
            return renderWaitingForOffer();
          }
          return <WaitingStep offer={offer} type={WaitingStepType.WAITING_DATES} />;

        case OfferState.OfferHomeCheckPending:
        case OfferState.OfferRemoteHomeCheckPending:
          return <WaitingStep offer={offer} type={WaitingStepType.WAITING_HOME_CHECK} />;

        case OfferState.OfferSubmitted:
        case OfferState.OfferAcceptedHomeCheckAppointmentTBD:
        case OfferState.OfferAcceptedRemoteHomeCheckAppointmentTBD: {
          const type =
            offer.state === OfferState.OfferSubmitted
              ? OfferSubmitStepType.SUBMITTING
              : OfferSubmitStepType.SELECTING;
          return <OfferSubmitStep offer={offer} type={type} />;
        }
        case OfferState.OfferRejected:
        case OfferState.OfferRejectedHomeCheck:
          return <WaitingStep offer={offer} />;

        case OfferState.InstallationDone:
          return redirect(`/customer/client-request/${offer.clientRequestId}/installation`);

        default:
          return <div>Unknown offer state {offer.state}</div>;
      }
    }

    return renderWaitingForOffer();
  };

  const renderContent = () => {
    switch (offersListState) {
      case RequestState.Loading:
        return renderLoader();

      case RequestState.Error:
        return <h1>Error</h1>;

      case RequestState.Success:
        return renderContentBasedOnOffer();

      default:
        return;
    }
  };

  const boxProps = {
    title: t('customerFlow:offer:offer'),
    showNextButton: false,
    showBackButton: SHOW_BACK_BUTTON,
    onBackClick: handleBackClick,
  };

  const titleText = `${t('stepper:customer:offer:title')} - ${t(
    `stepper:customer:offer:steps.${activeSubStep}`,
  )} - Tendergy`;

  return (
    <>
      <Helmet>
        <title>{titleText}</title>
      </Helmet>
      <BoxContent {...boxProps}>{renderContent()}</BoxContent>
    </>
  );
};

export default OfferPage;
