import { createSelector } from 'reselect';
import { IOrdersState } from './orders.types';

export const orderSelector = (state: any) => state.orders;

export const getOrderByIdStateSelector = createSelector(
  orderSelector,
  (order: IOrdersState) => order.getOrderByIdState,
);

export const currentOrderSelector = createSelector(
  orderSelector,
  (order: IOrdersState) => order.order,
);
