import React from 'react';
import clsx from 'clsx';

import styles from './ProjectsSidebar.module.sass';

export interface ListItem {
  title: string;
  value: string;
  action: any;
  sublist?: ListItem[];
  icon?: any;
  count?: null | number;
}
export interface IProjectsSidebarProps {
  color?: string;
  className?: string;
  list: ListItem[];
  selected?: string;
}

const ProjectsSidebar: React.FC<IProjectsSidebarProps> = (props) => {
  const { className, color, list, selected } = props;

  const checkSelected = (item: ListItem) =>
    item.value === selected ||
    !!(item.sublist && item.sublist.findIndex((i: ListItem) => i.value === selected) !== -1);

  const renderItem = (item: ListItem) => {
    const isSelected = checkSelected(item);
    const itemClassName = clsx([styles.listItem, { [styles.selected]: isSelected }]);

    return (
      <div key={`sidebar-item-${item.value}`}>
        <div
          onClick={item.action}
          className={itemClassName}
          style={
            isSelected && color
              ? {
                  color,
                }
              : undefined
          }
        >
          {item.icon ? (
            <span className={styles.icon}>{item.icon}</span>
          ) : (
            <span
              className={styles.dot}
              style={
                isSelected && color
                  ? {
                      backgroundColor: color,
                    }
                  : undefined
              }
            />
          )}

          {item.title}
          {!!item.count && <span className={styles.count}>{item.count}</span>}
        </div>

        {Array.isArray(item.sublist) ? (
          <div className={clsx(styles.subList, { [styles.fade]: item.sublist.length > 10 })}>
            {item.sublist.map(renderItem)}
          </div>
        ) : null}
      </div>
    );
  };

  return <div className={clsx(styles.sidebar, className)}>{list.map(renderItem)}</div>;
};

ProjectsSidebar.defaultProps = {
  list: [],
};

export default ProjectsSidebar;
