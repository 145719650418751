import {
  ACCEPT_ORDER,
  ACCEPT_ORDER_ERROR,
  ACCEPT_ORDER_SUCCESS,
  GET_ORDER_BY_ID,
  GET_ORDER_BY_ID_ERROR,
  GET_ORDER_BY_ID_SUCCESS,
  IOrder,
  LINK_ORGANIZATION_TO_REQUEST,
  LINK_ORGANIZATION_TO_REQUEST_ERROR,
  LINK_ORGANIZATION_TO_REQUEST_SUCCESS,
  OrderConfirmationType,
  REJECT_ORDER,
  REJECT_ORDER_ERROR,
  REJECT_ORDER_SUCCESS,
} from './orders.types';

export const linkOrganizationToRequest = (clientRequestId: string, organizationId: string) => ({
  type: LINK_ORGANIZATION_TO_REQUEST,
  payload: {
    clientRequestId,
    organizationId,
  },
});

export const linkOrganizationToRequestSuccess = (payload: any) => ({
  type: LINK_ORGANIZATION_TO_REQUEST_SUCCESS,
  payload: payload,
});

export const linkOrganizationToRequestError = (error: any) => ({
  type: LINK_ORGANIZATION_TO_REQUEST_ERROR,
  payload: error,
});

export const getOrderById = (id: string) => ({
  type: GET_ORDER_BY_ID,
  payload: id,
});

export const getOrderByIdSuccess = (payload: any) => ({
  type: GET_ORDER_BY_ID_SUCCESS,
  payload: payload,
});

export const getOrderByIdError = (error: any) => ({
  type: GET_ORDER_BY_ID_ERROR,
  payload: error,
});

export const acceptOrder = (
  id: string,
  token?: string,
  type?: OrderConfirmationType,
  onSuccess?: (id: string) => void,
) => ({
  type: ACCEPT_ORDER,
  payload: { id, token, type, onSuccess },
});

export const acceptOrderSuccess = (payload: IOrder) => ({
  type: ACCEPT_ORDER_SUCCESS,
  payload: payload,
});

export const acceptOrderError = (error: any) => ({
  type: ACCEPT_ORDER_ERROR,
  payload: error,
});

export const rejectOrder = (id: string, token?: string) => ({
  type: REJECT_ORDER,
  payload: {
    id,
    token,
  },
});

export const rejectOrderSuccess = (payload: IOrder) => ({
  type: REJECT_ORDER_SUCCESS,
  payload: payload,
});

export const rejectOrderError = (error: any) => ({
  type: REJECT_ORDER_ERROR,
  payload: error,
});
