import React from 'react';
import clsx from 'clsx';
import styles from './Icon.module.sass';

export enum IconSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export interface IIconProps {
  icon: string;

  className?: string;
  size?: IconSize;
  width?: string;
  height?: string;
  onClick?: (e: any) => void;
}

const Icon: React.FC<IIconProps> = (props) => {
  const { className, icon, size, width, height, onClick } = props;

  return (
    <i
      className={clsx(styles.icon, styles[size!], className)}
      style={{ backgroundImage: `url(${icon})`, width, height }}
      onClick={onClick}
    />
  );
};

Icon.defaultProps = {
  className: '',
  size: IconSize.SMALL,
  width: '',
  height: '',
};

export default Icon;
