import { IClientRequest, MediaCategoryType } from 'store/client-request/client-request.types';
import {
  homecheckStates,
  installationStates,
  IOffer,
  OfferApproval,
  OfferState,
  rejectedStates,
  remoteHomeCheckStates,
} from 'store/offer/offer.types';

export const checkIsHomeCheck = (offerState: OfferState) => homecheckStates.includes(offerState);
export const checkIsRemoteHomeCheck = (offerState: OfferState) =>
  remoteHomeCheckStates.includes(offerState);

export const checkIsInstallation = (offerState: OfferState) =>
  installationStates.includes(offerState);
export const checkIsRejected = (offerState: OfferState) => rejectedStates.includes(offerState);

export const checkIsOfferPublished = (offerState: OfferState) =>
  offerState !== OfferState.TenderSubmittedHomeCheck &&
  offerState !== OfferState.TenderSubmittedRemoteHomeCheck &&
  offerState !== OfferState.TenderSubmitted &&
  offerState !== OfferState.InvoiceSubmittedHomeCheck &&
  offerState !== OfferState.InvoiceSubmittedRemoteHomeCheck &&
  offerState !== OfferState.InvoiceSubmittedInstallation &&
  offerState !== OfferState.InvoiceAcceptedInstallation &&
  offerState !== OfferState.InvoiceAcceptedHomeCheck &&
  offerState !== OfferState.InvoicePaymentPendingHomeCheck &&
  offerState !== OfferState.InvoicePaymentPendingRemoteHomeCheck;

export const checkIsInvoicePublished = (offerState: OfferState) =>
  offerState === OfferState.InvoiceSubmittedInstallation ||
  offerState === OfferState.InvoiceCheckingSubmitInstallation;

export const checkIsPayByIntermediate = (offer: IOffer, clientRequest?: IClientRequest | null) => {
  const isPayByIntermediateInstallation =
    clientRequest?.installationOfferApproval === OfferApproval.PayByIntermediate;

  const isPayByIntermediateHomeCheck =
    clientRequest?.homeCheckOfferApproval === OfferApproval.PayByIntermediate;

  return checkIsHomeCheck(offer.state!)
    ? isPayByIntermediateHomeCheck
    : isPayByIntermediateInstallation;
};
export const generateOfferFileName = (
  uploaderId: string,
  originalFileName: string,
  category: MediaCategoryType,
) => {
  const rootDir = category.split(':').join('∕');
  const fileName = [originalFileName, '_', crypto.randomUUID()].join('');
  return [rootDir, uploaderId, fileName].join('∕');
};
export const getUploadOfferUploadCategory = (offer: IOffer): MediaCategoryType | null => {
  if (!offer?.documents || !offer.state) return null;

  const isHomeCheck = checkIsHomeCheck(offer.state);

  if (
    offer.state === OfferState.InvoiceSubmittedHomeCheck ||
    offer.state === OfferState.InvoiceSubmittedRemoteHomeCheck
  ) {
    return MediaCategoryType.OfferInvoiceHomeCheck;
  }

  if (offer.state === OfferState.InvoiceSubmittedInstallation) {
    return MediaCategoryType.OfferInvoiceInstallation;
  }

  if (offer.documents.length === 3) {
    return MediaCategoryType.InstallationOrderConfirmation;
  }

  return isHomeCheck
    ? MediaCategoryType.OfferHomeCheck
    : MediaCategoryType.InstallationOrderConfirmation;
};
const isOfferReplaceable = (offer: IOffer, clientRequest: IClientRequest): boolean => {
  if (!offer.state) return false;

  const isHomeCheck = checkIsHomeCheck(offer.state);
  if (isHomeCheck) {
    return clientRequest?.homeCheckOfferApproval === OfferApproval.ReplaceByIntermediate;
  }

  const isInvoicePublished = checkIsInvoicePublished(offer.state);
  if (isInvoicePublished) {
    return clientRequest?.installationInvoiceApproval === OfferApproval.ReplaceByIntermediate;
  }

  return clientRequest?.installationOfferApproval === OfferApproval.ReplaceByIntermediate;
};

const isPayByIntermediate = (offer: IOffer, clientRequest: IClientRequest): boolean => {
  if (!offer.state) return false;

  const isHomeCheck = checkIsHomeCheck(offer.state);
  if (isHomeCheck) {
    return clientRequest?.homeCheckOfferApproval === OfferApproval.PayByIntermediate;
  }

  const isInvoicePublished = checkIsInvoicePublished(offer.state);
  if (isInvoicePublished) {
    return clientRequest?.installationInvoiceApproval === OfferApproval.PayByIntermediate;
  }

  return clientRequest?.installationOfferApproval === OfferApproval.PayByIntermediate;
};
export const checkIsCustomerPriceEditable = (
  offer: IOffer,
  clientRequest: IClientRequest,
): boolean => {
  return isOfferReplaceable(offer, clientRequest) || isPayByIntermediate(offer, clientRequest);
};

export const getCurrentOffer = (offers: IOffer[]): IOffer | undefined => {
  const installationOffer = offers.find(
    (offer) =>
      offer.state &&
      checkIsInstallation(offer.state) &&
      offer.state !== OfferState.InstallationDone,
  );
  if (installationOffer) return installationOffer;
  return offers.find((offer) => offer.state && checkIsHomeCheck(offer.state));
};
