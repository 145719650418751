import React from 'react';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Input } from 'antd';
import FloatingLabel, { LabelPositionType } from 'components/ui/FloatingLabel/FloatingLabel';
import styles from './RadioContainer.module.sass';
import { RadioOption } from '../control.types';

type InputProps = {
  title: string;
};

export interface IRadioControlProps {
  value?: any;
  onChange?: (value: any) => void;
  handleOptionSelection?: (value: any) => void;
  hasInput?: boolean;
  inputProps?: InputProps;
  options?: RadioOption[];
  question?: string;
}
const RadioControl: React.FC<IRadioControlProps> = (props) => {
  const { onChange, hasInput, value, inputProps, options, question, handleOptionSelection } = props;

  const { t } = useTranslation();

  const handleOptionSelect = (newValue: string, text?: string) => {
    const payload = hasInput ? { selectedOption: newValue, text } : newValue;

    onChange?.(payload);
    handleOptionSelection?.(payload);
  };

  return (
    <>
      {question && <span className={styles.question}>{props.question}</span>}
      <div className={styles.optionsContainer}>
        {options?.map((item) => (
          <div
            key={item.value}
            className={clsx(styles.option, {
              [styles.selected]: value === item.value || value?.selectedOption === item.value,
            })}
            onClick={() => handleOptionSelect(item.value)}
          >
            {item.imageUrl && <img src={item.imageUrl} alt="answer cover" />}
            <div className={styles.content}>{t(item.label)}</div>
          </div>
        ))}
      </div>
      {hasInput && (
        <FloatingLabel
          label={inputProps?.title || t('customerFlow:answers:parkingSpaceLocation:other')}
          labelPosition={LabelPositionType.CENTER}
          value={value?.text}
        >
          <Input
            value={value?.text}
            onClick={() => handleOptionSelect('other', value?.text)}
            onChange={(e) => handleOptionSelect('other', e.target.value)}
          />
        </FloatingLabel>
      )}
    </>
  );
};

export default RadioControl;
