import { convertToRaw, EditorState } from 'draft-js';
import { IProduct } from 'store/product/product.types';
import { ILocalizedValue } from 'types/language.types';
import { formatPrice, removeThousandSeparator } from 'utils/currencyUtils';

export const priceFieldFormatter = (price?: number, currencySymbol?: string) => {
  if (price && currencySymbol) {
    return `${formatPrice(price)} ${currencySymbol ?? '€'}`;
  }
  return '-';
};

export const getTotalPrice = (vat: string, netPrice: string) => {
  if (!vat || !netPrice) return '0';
  const vatValue = +vat;
  const netPriceValue = +removeThousandSeparator(netPrice);
  return formatPrice(netPriceValue * (vatValue / 100) + netPriceValue);
};

export const formatEditorField = (field: any) =>
  typeof field === 'string' ? field : JSON.stringify(field);

export const formatProduct = (product: IProduct) => ({
  ...product,
  vendor: product.model?.vendor,
  category: product.model?.category,
});

export const formatPayload = (data: any) => {
  const localizedDescriptions = data.localizedDescriptions.map(
    (_localizedDescription: ILocalizedValue) => ({
      ..._localizedDescription,
      value: formatEditorField(_localizedDescription.value),
    }),
  );
  const dataWithLocalizedDescriptions = { ...data, localizedDescriptions };
  if (data.onRequestPrice) return { ...dataWithLocalizedDescriptions, netPrice: null };
  return {
    ...dataWithLocalizedDescriptions,
    netPrice: +removeThousandSeparator(data.netPrice),
  };
};

export const getEditorValue = (data: any) => {
  if (typeof data === 'string') {
    return JSON.parse(data);
  }
  if (!data) {
    convertToRaw(EditorState.createEmpty().getCurrentContent());
  }
  return data;
};
