import {
  REGISTER_INSTALLER,
  REGISTER_INSTALLER_SUCCESS,
  REGISTER_INSTALLER_ERROR,
  VALIDATE_INVITE_TOKEN,
  VALIDATE_INVITE_TOKEN_ERROR,
  VALIDATE_INVITE_TOKEN_SUCCESS,
} from './register.types';

export const registerInstaller = (user: any, token: string) => ({
  type: REGISTER_INSTALLER,
  payload: { user, token },
});

export const registerInstallerSuccess = () => ({
  type: REGISTER_INSTALLER_SUCCESS,
  payload: {},
});

export const registerInstallerError = (error: any) => ({
  type: REGISTER_INSTALLER_ERROR,
  payload: error,
});

export const validateInviteToken = (token: string) => ({
  type: VALIDATE_INVITE_TOKEN,
  payload: { token },
});

export const validateInviteTokenSuccess = (payload: any) => ({
  type: VALIDATE_INVITE_TOKEN_SUCCESS,
  payload,
});

export const validateInviteTokenError = (error: any) => ({
  type: VALIDATE_INVITE_TOKEN_ERROR,
  payload: error,
});
