import React, { useMemo } from 'react';
import clsx from 'clsx';
import Typography from 'antd/lib/typography';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styles from './InstallerCard.module.sass';

interface IUser {
  firstName?: string;
  lastName?: string;
  name?: string;
  phone?: string;
  email?: string;
  installer?: {
    mobile?: string;
    email?: string;
  };
  organization?: {
    logo?: string;
  };
}
interface IInstallerCardProps {
  user?: IUser;
  small?: boolean;
  organizationLogo?: string;
  operator?: boolean;
  installer?: boolean;
}

const { Paragraph } = Typography;

const InstallerCard: React.FC<IInstallerCardProps> = ({
  user,
  small,
  organizationLogo,
  operator,
  installer,
}) => {
  const { t } = useTranslation();

  const logo = useMemo(
    () => user?.organization?.logo ?? organizationLogo,
    [user, organizationLogo],
  );

  const name = useMemo(() => user?.name ?? `${user?.firstName} ${user?.lastName}`, [user]);

  return (
    <>
      {user && (
        <div className={clsx(styles.card, { [styles.small]: small })}>
          {operator && (
            <Paragraph className={styles.header}>
              {t('customerFlow:offerReview:intermediateDetails')}
            </Paragraph>
          )}
          {installer && (
            <Paragraph className={styles.header}>
              {t('customerFlow:offerReview:installerDetails')}
            </Paragraph>
          )}
          {logo ? (
            <img className={styles.organizationLogo} src={logo} alt="logo" width="70" height="70" />
          ) : (
            <Avatar
              shape="square"
              className={styles.organizationLogo}
              size={64}
              icon={<UserOutlined />}
            />
          )}
          {name && <Paragraph className={styles.name}>{name}</Paragraph>}
          {user.phone && <Paragraph className={styles.phone}>{user.phone}</Paragraph>}
          {user.email && <Paragraph className={styles.phone}>{user.email}</Paragraph>}
        </div>
      )}
    </>
  );
};

export default InstallerCard;
