import { createSelector } from 'reselect';
import { IContactPersonState } from './contact-person.types';

export const contactPersonSelector = (state: any) => state.contactPerson;

export const contactPersonCreateStateSelector = createSelector(
  contactPersonSelector,
  (contactPerson: IContactPersonState) => contactPerson.contactPersonCreateState,
);

export const contactPersonGetStateSelector = createSelector(
  contactPersonSelector,
  (contactPerson: IContactPersonState) => contactPerson.contactPersonsState,
);

export const currentContactPersonsSelector = createSelector(
  contactPersonSelector,
  (contactPerson: IContactPersonState) => contactPerson.currentContactPersons,
);

export const contactPersonUpdateStateSelector = createSelector(
  contactPersonSelector,
  (contactPerson: IContactPersonState) => contactPerson.contactPersonUpdateState,
);

export const contactPersonDeleteStateSelector = createSelector(
  contactPersonSelector,
  (contactPerson: IContactPersonState) => contactPerson.contactPersonDeleteState,
);
