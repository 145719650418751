import { IAddress } from 'store/client-request/client-request.types';
import { RequestState } from '../common.types';

export interface ICountry {
  iso31661Alpha3: string;
  vatRate: number;
  currency: string;
  addresses?: IAddress[];
  isDefault?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ICountryState {
  countries: ICountry[];
  countriesGetState: RequestState | null;
  countriesGetError: string | null;
}

export const COUNTRIES_GET = 'COUNTRIES_GET';
export const COUNTRIES_GET_SUCCESS = 'COUNTRIES_GET_SUCCESS';
export const COUNTRIES_GET_ERROR = 'COUNTRIES_GET_ERROR';

// Get Countries
export interface CountriesGetAction {
  type: typeof COUNTRIES_GET;
}

export interface CountriesGetSuccessAction {
  type: typeof COUNTRIES_GET_SUCCESS;
  payload: ICountry[];
}

export interface CountriesGetErrorAction {
  type: typeof COUNTRIES_GET_ERROR;
  payload: any;
}

export type LeadActionTypes =
  | CountriesGetAction
  | CountriesGetSuccessAction
  | CountriesGetErrorAction;
