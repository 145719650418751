import _get from 'lodash/get';
import {
  ClientRequestFields,
  IClientRequestAnswer,
  QuestionKey,
  TypeDistance,
  WallboxesType,
} from 'store/client-request/client-request.types';
import { IProductModel } from 'store/product/product.types';
import i18n from './../i18n';

export const transformPersonalDetailsBeforeRequest = (values: any) => {
  const fieldsKeys = Object.keys(values);
  const updatedValues: any = {};

  fieldsKeys.forEach((fieldKey: string) => {
    const value = values[fieldKey];
    const today = new Date();

    switch (fieldKey) {
      case ClientRequestFields.Availability:
        updatedValues[ClientRequestFields.StartDate] = _get(
          value,
          ClientRequestFields.StartDate,
          today,
        );
        updatedValues[ClientRequestFields.SoonestPossible] = _get(
          value,
          ClientRequestFields.SoonestPossible,
          true,
        );
        break;
      case ClientRequestFields.Address:
      case ClientRequestFields.BillingAddress:
        updatedValues[fieldKey] = {
          ...value,
          country: { iso31661Alpha3: value.country },
        };
        break;
      default:
        updatedValues[fieldKey] = value;
        break;
    }
  });

  return updatedValues;
};

export const transformInstallerBeforeRequest = (user: any, token?: string) => {
  return {
    recaptchaToken: _get(user, 'captcha', ''),
    email: _get(user, 'email', ''),
    password: _get(user, 'password', ''),
    firstName: _get(user, 'firstName', ''),
    lastName: _get(user, 'lastName', ''),
    title: _get(user, 'title', '') || undefined, // is optional
    gender: _get(user, 'gender', ''),
    phone: _get(user, 'mobile', ''),
    fax: _get(user, 'fax', '') || undefined, // is optional
    inviteToken: token || undefined, // if registration by invite send this field
    installer: {},
    organization: {
      name: _get(user, 'company', '') || undefined, // if registration by invite don't send this field
      address: {
        street: _get(user, 'street', ''),
        houseNumber: _get(user, 'house', ''),
        address2: _get(user, 'address2', '') || undefined, // is optional
        postalCode: _get(user, 'postalCode', ''),
        city: _get(user, 'city', ''),
        country: { iso31661Alpha3: _get(user, 'country', '') },
      },
    },
  };
};

export const getDistance = (distances: TypeDistance[] | null) => {
  if (!Array.isArray(distances)) return 'n/a';
  const numbers = distances.map((d) => d.distance);
  const min = Math.min(...numbers);
  return min < 5 ? '~5' : min;
};

export const transformWallboxes = (manufacturers: any[], needOtherOption = true): WallboxesType => {
  const vendors = manufacturers.reduce((prev, curr) => {
    const exist = prev.find((item: any) => item.id === curr.vendor?.id);
    if (exist || !curr.vendor) {
      return prev;
    }
    const models = manufacturers?.filter((model: any) => model.vendor?.id === curr.vendor?.id);
    return [...prev, { ...curr.vendor, models }];
  }, []);

  const wallboxes: WallboxesType = {};

  vendors.forEach((manufacturer: any) => {
    wallboxes[manufacturer.id] = {
      value: manufacturer.id,
      label: manufacturer.name,
      models: manufacturer.models.map((item: IProductModel) => ({
        value: item.id,
        label: item.name,
      })),
    };

    if (needOtherOption) {
      // Adding 'OTHER' option for each models list
      wallboxes[manufacturer.id]?.models?.push({
        value: 'other',
        label: i18n.t('customerFlow:questions:chargingStation:otherModel'),
      });
    }
  });

  if (needOtherOption) {
    // Adding 'OTHER' option for manufacturers list
    wallboxes['other'] = {
      value: 'other',
      label: i18n.t('customerFlow:questions:chargingStation:otherManufacturer'),
      models: [],
    };
  }

  return wallboxes;
};

export const transformFindOrganizations = (organizations: any[]) =>
  organizations.map((organization) => ({
    automaticApproval: false,
    createdAt: organization.createdAt,
    deletedDate: organization.deletedDate,
    id: organization.id,
    organization: organization,
    updatedAt: organization.updatedAt,
  }));

export const formatAnswersToArray = (fields: any) => {
  return Object.keys(fields)?.map((changeQuestionKey) => ({
    questionKey: changeQuestionKey as QuestionKey,
    answerValue: { value: fields[changeQuestionKey] },
  }));
};

export const transformAnswersToFormData = (schema: any, answersData: IClientRequestAnswer[]) => {
  const formData: { [key: string]: any } = {};
  Object.keys(schema?.properties).forEach((key) => {
    formData[key] = answersData?.find((item) => item.questionKey === key)?.answerValue?.value;
  });
  return formData;
};
