import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, message, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Loader from 'components/ui/Loader/Loader';
import { validateInviteToken } from 'store/register/register.actions';
import {
  isRegistrationErrorSelector,
  isRegistrationInviteTokenSelector,
} from 'store/register/register.selectors';
import { joiningPoolConfirm } from 'store/installer/installer.actions';
import { joiningPoolConfirmStateSelector } from 'store/installer/installer.selectors';
import { RequestState } from 'store/common.types';
import styles from './JoinPoolPage.module.sass';

const JoinPoolPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const inviteTokenData: any = useSelector(isRegistrationInviteTokenSelector);
  const registrationError = useSelector(isRegistrationErrorSelector);
  const joiningPoolConfirmState = useSelector(joiningPoolConfirmStateSelector);

  useEffect(() => {
    dispatch(validateInviteToken(token!));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (joiningPoolConfirmState === RequestState.Success) {
      setIsLoading(false);
      message.info(
        `${t('installerFlow:registration:successfullyJoinedToPoll')} ${inviteTokenData
          ?.clientRequestPool?.name}`,
      );
      navigate('/installer/dashboard');
    }
  }, [joiningPoolConfirmState]); // eslint-disable-line

  if (!inviteTokenData && !registrationError) {
    return <Loader />;
  }

  const onButtonClick = () => {
    dispatch(joiningPoolConfirm(inviteTokenData.token));
    setIsLoading(true);
  };

  return registrationError && !inviteTokenData?.clientRequestPool ? (
    <div className={styles.container}>
      <div>{t('installerFlow:registration:invalidInvitationLink')}</div>
    </div>
  ) : (
    <Row gutter={[16, 16]}>
      <Col className={styles.container}>
        <Typography.Title level={2} className={styles.title}>
          {t('installerFlow:registration:organizationInvite', {
            poolName: inviteTokenData.clientRequestPool.name,
            organizationName: inviteTokenData.clientRequestPool.intermediateOrganization.name,
          })}
        </Typography.Title>
        <Button
          type="primary"
          onClick={onButtonClick}
          className={styles.joinButton}
          loading={isLoading}
        >
          {t('installerFlow:registration:join')}
        </Button>
      </Col>
    </Row>
  );
};

export default JoinPoolPage;
