import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxChangeEvent } from 'antd/es/checkbox';
import AnswerSelection, { AnswerType } from 'components/ui/AnswerSelection/AnswerSelection';
import Checkbox from 'components/ui/Checkbox/Checkbox';
import { QuestionKey } from 'store/client-request/client-request.types';

import styles from '../../controls/MultipleInputContainer/MultipleInputContainer.module.sass';

const MANUFACTURER_KEY = 'manufacturer';
const MODEL_KEY = 'model';
const NO_VEHICLE_KEY = 'noVehicle';

type CarModelType = {
  [MANUFACTURER_KEY]: string;
  [MODEL_KEY]: string;
  [NO_VEHICLE_KEY]: boolean;
};

interface Props {
  onChange?: (value: CarModelType) => void;
  onKeyPress?(event: React.FormEvent<HTMLInputElement>): void;
  id?: string;
  value?: CarModelType;
}

const CarModelQuestion: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<CarModelType>({
    [MANUFACTURER_KEY]: '',
    [MODEL_KEY]: '',
    [NO_VEHICLE_KEY]: false,
  });

  useEffect(() => {
    if (props.value) {
      setValue({
        [MANUFACTURER_KEY]: props.value[MANUFACTURER_KEY] || '',
        [MODEL_KEY]: props.value[MODEL_KEY] || '',
        [NO_VEHICLE_KEY]: props.value[NO_VEHICLE_KEY] || false,
      });
    }
  }, [props.value]);

  const onInputChange = (event: any, field: string) => {
    const text = event.target.value.toString();
    const newVal = { ...value, [NO_VEHICLE_KEY]: false, [field]: text };

    setValue(newVal);

    props.onChange && props.onChange(newVal);
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const newValue = {
      [MODEL_KEY]: '',
      [MANUFACTURER_KEY]: '',
      [NO_VEHICLE_KEY]: !value[NO_VEHICLE_KEY],
    };

    setValue(newValue);
    props.onChange && props.onChange(newValue);
  };

  return (
    <div className={styles.container} id={props.id}>
      <div className={styles.question}>
        {t(`customerFlow:questions:${QuestionKey.CarModel}:question`)}
      </div>

      <div className={styles.description}>
        {t(`customerFlow:questions:${QuestionKey.CarModel}:description`)}
      </div>

      <div className={styles.answers}>
        <AnswerSelection
          className={styles.selection}
          type={AnswerType.INPUT}
          value={value[MANUFACTURER_KEY]}
          title={t('common:texts:manufacturer')}
          onInput={(event) => onInputChange(event, MANUFACTURER_KEY)}
        />

        <AnswerSelection
          className={styles.selection}
          type={AnswerType.INPUT}
          value={value[MODEL_KEY]}
          title={t('common:texts:model')}
          onInput={(event) => onInputChange(event, MODEL_KEY)}
        />

        <div className={styles.option}>
          <Checkbox checked={value[NO_VEHICLE_KEY]} onChange={handleCheckboxChange}>
            <div className={styles.checkboxText}>
              {t(`customerFlow:questions:${QuestionKey.CarModel}:noVehicle`)}
            </div>
          </Checkbox>
        </div>
      </div>
    </div>
  );
};

export default CarModelQuestion;
