import React, { useCallback } from 'react';
import clsx from 'clsx';
import styles from './FileCard.module.sass';
import Icon from '../Icon/Icon';

import TrashRed from '../../../assets/icons/trash-red.svg';
import JpgBlue from '../../../assets/icons/jpg.svg';
import PdfBlue from '../../../assets/icons/pdf.svg';
import { FileType, IFile } from '../../../store/client-request/client-request.types';

interface IFileCardProps {
  className?: string;
  fluid?: boolean;
  size?: number;
  onDeleteClick?: (fileName: string, fileId: string) => void;
}

export const FileTypesFactory = {
  [FileType.PDF]: PdfBlue,
  [FileType.JPG]: JpgBlue,
};

const FileCard: React.FC<IFileCardProps & IFile> = (props: IFileCardProps & IFile) => {
  const { mime, name, originalName, fluid, id, size, className, onDeleteClick } = props;

  const fileTypeIcon = FileTypesFactory[mime!] || FileTypesFactory[FileType.PDF];
  const showDeleteButton = onDeleteClick && typeof onDeleteClick === 'function';
  const containerClassName = clsx(styles.container, className, {
    [styles.fluid]: fluid,
  });

  const handleDeleteClick = useCallback(() => {
    if (onDeleteClick && typeof onDeleteClick === 'function') {
      onDeleteClick(name, id);
    }
  }, [name, id, onDeleteClick]);

  return (
    <div className={containerClassName}>
      <div className={styles.left}>
        <Icon height="28px" width="26px" icon={fileTypeIcon} className={styles.type} />
      </div>

      <div className={styles.middle}>
        <div className={styles.name}>{originalName}</div>
        {size ? <div className={styles.size}>{size}</div> : null}
      </div>

      {showDeleteButton ? (
        <div className={styles.right} onClick={handleDeleteClick}>
          <Icon height="16px" width="13px" icon={TrashRed} className={styles.trash} />
        </div>
      ) : null}
    </div>
  );
};

FileCard.defaultProps = {
  fluid: false,
};

export default FileCard;
