import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Skeleton, Typography } from 'antd';
import wonderWoman from 'assets/images/wonder-woman.svg';
import BoxContent from 'components/layout/BoxContent/BoxContent';
import WaitingStep from 'components/steps/WaitingStep/WaitingStep';
import CustomerInstallationDoneStep from 'components/steps/CustomerInstallationDoneStep/CustomerInstallationDoneStep';
import OfferWaitingForOffersStep from 'components/steps/OfferWaitingForOffers/OfferWaitingForOffersStep';
import InvoiceAcceptedHomeCheck from 'components/steps/InvoiceAcceptedHomeCheck/InvoiceAcceptedHomeCheck';
import FeedbackStep from 'components/steps/FeedbackStep/FeedbackStep';
import { FormValues } from 'components/ui/FeedbackForm/FeedbackForm';
import StepperConfigContext from 'context/stepper.context';
import { activeSubStepSelector } from 'store/app/app.selectors';
import { feedbackListSelector, feedbackStateSelector } from 'store/feedback/feedback.selectors';
import { feedbackCreate, feedbackGet } from 'store/feedback/feedback.actions';
import { FeedbackReceiverType, FeedbackReferenceType } from 'store/feedback/feedback.types';
import { RequestState } from 'store/common.types';
import { changeStep } from 'store/app/app.actions';
import { currentOfferSelector } from 'store/offer/offer.selectors';
import { OfferApproval, OfferState } from 'store/offer/offer.types';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';

import styles from './InstallationPage.module.sass';

const InstallationPage = () => {
  const { t } = useTranslation(['feedback']);
  const dispatch = useDispatch();
  const clientRequest = useSelector(currentClientRequestSelector);
  const offer = useSelector(currentOfferSelector);
  const activeSubStep = useSelector(activeSubStepSelector);
  const [tendergyFeedback, setTendergyFeedback] = useState<FormValues>({ rate: 0, comment: '' });
  const [installerFeedback, setInstallerFeedback] = useState<FormValues>({ rate: 0, comment: '' });
  const feedbackList = useSelector(feedbackListSelector);
  const loadingFeedback = useSelector(feedbackStateSelector);

  const { installationStep } = useContext(StepperConfigContext);

  useEffect(() => {
    dispatch(changeStep(installationStep, 0));
  }, [installationStep]); // eslint-disable-line

  useEffect(() => {
    if (clientRequest && clientRequest.id) {
      dispatch(feedbackGet(clientRequest.id, FeedbackReferenceType.ClientRequests));
    }
  }, [clientRequest]); // eslint-disable-line

  useEffect(() => {
    if (feedbackList.length) {
      dispatch(changeStep(installationStep, 1));
    }
  }, [feedbackList]); // eslint-disable-line

  const onGiveFeedbackClick = () => {
    if (clientRequest && clientRequest.id && offer?.installerUser?.installer?.id) {
      dispatch(
        feedbackCreate([
          {
            referenceType: FeedbackReferenceType.ClientRequests,
            referenceId: clientRequest.id,
            receiverType: FeedbackReceiverType.Platform,
            rating: tendergyFeedback.rate,
            text: tendergyFeedback.comment,
          },
          {
            referenceType: FeedbackReferenceType.ClientRequests,
            referenceId: clientRequest.id,
            receiverType: FeedbackReceiverType.Installers,
            receiverId: offer.installerUser?.installer?.id,
            rating: installerFeedback.rate,
            text: installerFeedback.comment,
          },
        ]),
      );
    }
  };

  const isFeedbackLoading = loadingFeedback === null || loadingFeedback === RequestState.Loading;

  const renderSteps = () => {
    if (isFeedbackLoading) {
      return (
        <Skeleton
          active={true}
          paragraph={{
            rows: 3,
          }}
          round={true}
          className={styles.skeleton}
        />
      );
    }
    if (!offer) return <OfferWaitingForOffersStep />;

    switch (offer.state) {
      case OfferState.InstallationPending:
        return <WaitingStep isDirect={clientRequest?.orderDirectlyToInstaller} offer={offer} />;

      case OfferState.InvoicePaymentPendingHomeCheck:
      case OfferState.InvoicePaymentPendingRemoteHomeCheck:
        return <InvoiceAcceptedHomeCheck />;

      case OfferState.InvoiceAcceptedInstallation:
      case OfferState.InvoiceSubmittedInstallation:
      case OfferState.InstallationDone:
        if (feedbackList.length) {
          return (
            <>
              <Typography.Paragraph className={styles.text}>
                {t('feedback:feedbackExist')}
              </Typography.Paragraph>
              <img className={styles.womanImage} src={wonderWoman} alt="Wonder woman" />
            </>
          );
        }
        if (activeSubStep === 1) {
          return (
            <FeedbackStep
              setTendergyFeedback={setTendergyFeedback}
              setInstallerFeedback={setInstallerFeedback}
            />
          );
        } else {
          return <CustomerInstallationDoneStep />;
        }
      default:
        return <div>Unknown offer state {offer.state} </div>;
    }
  };

  const boxProps = {
    title: t('customerFlow:installation:title'),
    showBackButton: false,
    showNextButton:
      !isFeedbackLoading &&
      !feedbackList.length &&
      ((activeSubStep === 0 &&
        (offer?.state === OfferState.InstallationDone ||
          clientRequest?.installationOfferApproval === OfferApproval.PayByIntermediate)) ||
        (activeSubStep !== 0 && !!tendergyFeedback.rate && !!installerFeedback.rate)),
    nextButtonText: t('feedback:giveFeedback'),
    onNextClick: activeSubStep
      ? onGiveFeedbackClick
      : () => dispatch(changeStep(installationStep, 1)),
    boxContentClassName: styles.container,
  };

  const titleText = `${t('stepper:customer:installation:title')} - ${t(
    `stepper:customer:installation:steps.${activeSubStep}`,
  )} - Tendergy`;

  return (
    <>
      <Helmet>
        <title>{titleText}</title>
      </Helmet>
      <BoxContent {...boxProps}>{renderSteps()}</BoxContent>
    </>
  );
};

export default InstallationPage;
