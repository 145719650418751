import React, { useMemo } from 'react';
import { Form, Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import EditorContainer from 'components/ui/EditorContainer/EditorContainer';
import MultiLangEntrySection from 'components/ui/MultiLangEntries/MultiLangEntrySection';
import TextField from 'components/ui/TextField/TextField';
import { SupportedLanguages } from 'framework/constants';
import { ValidationFactory } from 'framework/validations';
import { ICustomDocument } from 'store/intermediate/intermediate.types';
import { TSupportedLanguagesKey } from 'types/language.types';
import { CustomDocumentCategory } from 'types/pool-settings.types';
import { getLanguageKey } from 'utils/supprotedLanguagesUtils';
import styles from './LegalTab.module.sass';
interface ILegalSectionEditorProps {
  sectionName: string;
  defaultLanguage: SupportedLanguages;
}

const LegalSectionEditor = (props: ILegalSectionEditorProps) => {
  const { t } = useTranslation();
  const { sectionName } = props;

  const legalDocuments = useSelector((state: any) => state.intermediate.legalDocuments);

  const sectionSupportedLangs: TSupportedLanguagesKey[] = useMemo(
    () =>
      legalDocuments
        ?.filter(
          (_legalDocument: ICustomDocument) =>
            _legalDocument.category ===
            CustomDocumentCategory[props.sectionName as keyof typeof CustomDocumentCategory],
        )
        .map((_legalDocument: ICustomDocument) => getLanguageKey(_legalDocument.lang)),
    [props.sectionName, legalDocuments],
  );

  return (
    <>
      <Typography.Title level={4} className={styles.sectionHeader}>
        {t(`intermediate:myProjects:legalInformation:${sectionName}`)}
      </Typography.Title>
      <MultiLangEntrySection
        sectionSupportedLanguages={sectionSupportedLangs}
        defaultLanguage={props.defaultLanguage}
      >
        {(language) => (
          <>
            <Form.Item name={[sectionName, language, 'id']}>
              <Input type={'hidden'} />
            </Form.Item>
            <Form.Item
              name={[sectionName, language, 'text']}
              rules={[ValidationFactory.ONLY_ONE_FILLED([sectionName, language, 'link'])]}
              dependencies={[[sectionName, language, 'link']]}
            >
              <EditorContainer />
            </Form.Item>
            <Form.Item
              label={t('intermediate:myProjects:legalInformation:link:label')}
              name={[sectionName, language, 'link']}
              rules={[
                ValidationFactory.ONLY_ONE_FILLED([sectionName, language, 'text']),
                ValidationFactory.IS_URL,
              ]}
              dependencies={[[sectionName, language, 'text']]}
            >
              <TextField
                placeholder={t('intermediate:myProjects:legalInformation:link:placeholder')}
              />
            </Form.Item>
          </>
        )}
      </MultiLangEntrySection>
    </>
  );
};
export default LegalSectionEditor;
