import { all, call, put, takeLatest } from 'redux-saga/effects';
import SurveysAPI from 'services/surveys.service';

import {
  surveysGetSuccess,
  surveysGetError,
  surveyGetSuccess,
  surveyGetError,
  surveyCreateError,
  surveyCreateSuccess,
} from './surveys.actions';
import {
  SURVEY_GET,
  SurveyGetAction,
  SURVEYS_GET,
  SurveyCreateAction,
  SURVEY_CREATE,
  SurveysListType,
  ISurvey,
} from './surveys.types';

export function* getSurveys(action: SurveyGetAction) {
  try {
    const { payload } = action;
    const surveysRaw: SurveysListType = yield call(SurveysAPI.getSurveys, payload);
    if (surveysRaw) {
      yield put(surveysGetSuccess(surveysRaw));
    } else {
      yield put(surveysGetError('No surveys'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(surveysGetError(message));
  }
}

export function* getSurveyById(action: SurveyGetAction) {
  try {
    const survey: ISurvey = yield call(SurveysAPI.getSurveyById, action.payload);
    if (survey) {
      yield put(surveyGetSuccess(survey));
    } else {
      yield put(surveyGetError('No survey'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(surveysGetError(message));
  }
}

export function* createSurvey(action: SurveyCreateAction) {
  try {
    const survey: ISurvey = yield call(SurveysAPI.createSurvey, action.payload);
    if (survey) {
      yield put(surveyCreateSuccess(survey));
    } else {
      yield put(surveyCreateError(survey));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(surveyCreateError(message));
  }
}

export default function* root() {
  yield all([
    takeLatest(SURVEYS_GET, getSurveys),
    takeLatest(SURVEY_GET, getSurveyById),
    takeLatest(SURVEY_CREATE, createSurvey),
  ]);
}
