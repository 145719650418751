import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Flex, Menu, Row, Tag, Typography } from 'antd';
import { Helmet } from 'react-helmet';
import { MenuProps } from 'antd/lib';
import {
  AlignLeftOutlined,
  FileTextOutlined,
  InboxOutlined,
  SafetyCertificateOutlined,
  SettingOutlined,
  SnippetsOutlined,
  ToolOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import BackButton from 'components/ui/BackButton/BackButton';
import Loader from 'components/ui/Loader/Loader';
import {
  currentPool,
  currentPoolState,
  questionnaireConfigsSelector,
  questionnaireConfigsStateSelector,
} from 'store/intermediate/intermediate.selectors';
import {
  customDocumentGet,
  poolFilesGet,
  poolGetById,
  questionnaireConfigGet,
  resetInstallationWizardCustomImageUrls,
  resetPoolFiles,
} from 'store/intermediate/intermediate.actions';
import { RequestState } from 'store/common.types';
import { MediaCategoryType } from 'store/client-request/client-request.types';
import PoolSettingsTab from './PoolSettingsTab/PoolSettingsTab';

import styles from './EditPool.module.sass';
import RequestsTab from './RequestsTab/RequestsTab';
import SurveysTab from './SurveysTab/SurveysTab';
import OffersTab from './OffersTab/OffersTab';
import ContactInformationTab from './ContactInformationTab/ContactInformationTab';
import InstallerOrganizationsTab from './InstallerOrganizationsTab/InstallerOrganizationsTab';
import TextAdjustmentsTab from './TextAdjustmentsTab/TextAdjustmentsTab';
import QuestionnaireTab from './QuestionnaireTab/QuestionnaireTab';
import CustomerLinkTab from './CustomerLinkTab/CustomerLinkTab';
import { Products } from './PoolSettingsTab/Tabs/Product/Products';
import { PoolType } from '../../../types/pool-settings.types';

export enum EditPoolTabs {
  REQUESTS = 'requests',
  ARCHIVEDREQUESTS = 'archivedRequests',
  SURVEYS = 'surveys',
  OFFERS = 'offers',
  EXPIREDOFFERS = 'expiredOffers',
  SETTINGS = 'settings',
  ORGANIZATIONS = 'organizations',
  TEXT_ADJUSTMENTS = 'adjustments',
  QUESTIONNAIRE = 'questionnaire',
  LINK = 'invite',
  CONTACT = 'contact',
}

export interface IEditPoolProps {
  isNew: boolean;
}

const EditPool: React.FC<IEditPoolProps> = ({ isNew }) => {
  const { t } = useTranslation();
  const { poolId, activeTab, productId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pool = useSelector(currentPool);
  const poolState = useSelector(currentPoolState);
  const configs = useSelector(questionnaireConfigsSelector);
  const configState = useSelector(questionnaireConfigsStateSelector);
  const isError = poolState === RequestState.Error;
  const isLoading =
    poolState === RequestState.Loading ||
    poolState === null ||
    configState === null ||
    configState === RequestState.Loading;

  const isSurvey = pool?.poolType === PoolType.SurveyPool;

  useEffect(() => {
    return () => {
      dispatch(resetPoolFiles());
      dispatch(resetInstallationWizardCustomImageUrls());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!poolId) return;
    dispatch(poolGetById(poolId));
    dispatch(customDocumentGet(poolId));
    dispatch(questionnaireConfigGet(poolId));
    dispatch(poolFilesGet(poolId, MediaCategoryType.PoolAvatar));
    dispatch(poolFilesGet(poolId, MediaCategoryType.RequestWallboxOnly));
    dispatch(poolFilesGet(poolId, MediaCategoryType.RequestWallboxAndInstallation));
  }, [poolId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const selected = isNew ? EditPoolTabs.SETTINGS : activeTab;
    const active = selected || EditPoolTabs.REQUESTS;
    navigate(`/my-projects/${poolId}/${active}`);
  }, [isNew]); // eslint-disable-line

  const projectsSidebarProps: MenuProps['items'] = useMemo(() => {
    const list = [
      { key: EditPoolTabs.REQUESTS, icon: <InboxOutlined /> },
      { key: EditPoolTabs.SURVEYS, icon: <FileTextOutlined /> },
      { key: EditPoolTabs.OFFERS, icon: <SnippetsOutlined /> },
      { key: EditPoolTabs.SETTINGS, icon: <SettingOutlined /> },
      { key: EditPoolTabs.CONTACT, icon: <SafetyCertificateOutlined /> },
      { key: EditPoolTabs.ORGANIZATIONS, icon: <ToolOutlined /> },
      { key: EditPoolTabs.TEXT_ADJUSTMENTS, icon: <AlignLeftOutlined /> },
      { key: EditPoolTabs.QUESTIONNAIRE, icon: <UnorderedListOutlined /> },
      { key: EditPoolTabs.LINK, icon: <UsergroupAddOutlined /> },
    ];

    return list.map((item) => ({
      icon: item.icon,
      label: t(`intermediate:myProjects:editProjectPools:${item.key}`),
      key: item.key,
    }));
  }, [t]);

  const renderContent = useCallback(() => {
    if (productId) {
      return <Products pool={pool} />;
    }
    switch (activeTab) {
      case EditPoolTabs.REQUESTS:
      case EditPoolTabs.ARCHIVEDREQUESTS:
        return <RequestsTab />;
      case EditPoolTabs.SURVEYS:
        return <SurveysTab poolId={pool?.id} />;
      case EditPoolTabs.OFFERS:
      case EditPoolTabs.EXPIREDOFFERS:
        return <OffersTab poolId={pool?.id} activeTabParam={activeTab!} />;
      case EditPoolTabs.SETTINGS:
        return <PoolSettingsTab pool={pool} />;
      case EditPoolTabs.CONTACT:
        return <ContactInformationTab pool={pool} />;
      case EditPoolTabs.ORGANIZATIONS:
        return <InstallerOrganizationsTab pool={pool} />;
      case EditPoolTabs.TEXT_ADJUSTMENTS:
        return <TextAdjustmentsTab />;
      case EditPoolTabs.QUESTIONNAIRE:
        return <QuestionnaireTab pool={pool} configs={configs} />;
      case EditPoolTabs.LINK:
        return <CustomerLinkTab pool={pool} />;
    }

    return <Typography.Title level={3}>Error</Typography.Title>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, productId, poolId, pool]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Typography.Title level={3}>Error</Typography.Title>;
  }

  return (
    <>
      <Helmet>
        <title>{`${pool.name} - ${t(
          'intermediate:myProjects:projectPoolsTab:title',
        )} - Tendergy`}</title>
      </Helmet>
      <Row gutter={[16, 16]} align="middle">
        <Col span={5}>
          <BackButton onClick={() => navigate('/my-projects')}>
            {t('common:buttons:back')}
          </BackButton>
        </Col>
        <Col span={19}>
          <Flex align="center" gap="8px">
            <Typography.Title level={3}>
              {t('intermediate:myProjects:editProjectPools:title')} - {pool.name}
            </Typography.Title>
            {(isSurvey || pool.isParent) && (
              <Tag color="#FF9E56">
                {isSurvey ? 'Survey' : t('intermediate:myProjects:projectPoolsTab:parentTag')}
              </Tag>
            )}
          </Flex>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={5}>
          <Menu
            className={styles.sidebar}
            mode="inline"
            selectedKeys={[activeTab!]}
            items={projectsSidebarProps}
            onSelect={(info) => navigate(`/my-projects/${pool.id}/${info.key}`)}
          />
        </Col>
        <Col span={19}>
          <div className={styles.container}>{renderContent()}</div>
        </Col>
      </Row>
    </>
  );
};

export default EditPool;
