import { RequestState } from 'store/common.types';
import { IDocument, MediaCategoryType } from 'store/client-request/client-request.types';

export interface IUserState {
  userGetDocumentsState: RequestState | null;
  getDocumentsError: string | null;
  files: IDocument[];
  user: any;
  userGetState: RequestState | null;
  userGetError: string | null;
  emailVerified: boolean;
}

export const USER_GET_DOCUMENTS = 'USER_GET_DOCUMENTS';
export const USER_GET_DOCUMENTS_SUCCESS = 'USER_GET_DOCUMENTS_SUCCESS';
export const USER_GET_DOCUMENTS_ERROR = 'USER_GET_DOCUMENTS_ERROR';

export const USER_GET = 'USER_GET';
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
export const USER_GET_ERROR = 'USER_GET_ERROR';

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_ERROR = 'UPDATE_USER_PROFILE_ERROR';

export const USER_DELETE_ACCOUNT = 'USER_DELETE_ACCOUNT';
export const USER_DELETE_ACCOUNT_ERROR = 'USER_DELETE_ACCOUNT_ERROR';

export const USER_RESEND_VERIFICATION_EMAIL = 'USER_RESEND_VERIFICATION_EMAIL';
// export const USER_RESEND_VERIFICATION_EMAIL_SUCCESS = 'USER_RESEND_VERIFICATION_EMAIL_SUCCESS';
export const USER_RESEND_VERIFICATION_EMAIL_ERROR = 'USER_RESEND_VERIFICATION_EMAIL_ERROR';

// Email Verification Check

export const USER_GET_EMAIL_STATUS = 'USER_GET_EMAIL_STATUS';
export const USER_GET_EMAIL_STATUS_SUCCESS = 'USER_GET_EMAIL_STATUS_SUCCESS';
export const USER_GET_EMAIL_STATUS_ERROR = 'USER_GET_EMAIL_STATUS_ERROR';

export interface UserGetDocumentsAction {
  type: typeof USER_GET_DOCUMENTS;
  payload: MediaCategoryType;
}

export interface UserGetDocumentsSuccessAction {
  type: typeof USER_GET_DOCUMENTS_SUCCESS;
  payload: IDocument[];
}

export interface UserGetDocumentsErrorAction {
  type: typeof USER_GET_DOCUMENTS_ERROR;
  payload: any;
}

export interface UserGetAction {
  type: typeof USER_GET;
}

export interface UserGetSuccessAction {
  type: typeof USER_GET_SUCCESS;
  payload: any;
}

export interface UserGetErrorAction {
  type: typeof USER_GET_ERROR;
  payload: any;
}

export interface UpdateUserProfileAction {
  type: typeof UPDATE_USER_PROFILE;
  payload: any;
}

export interface UpdateUserProfileSuccessAction {
  type: typeof UPDATE_USER_PROFILE_SUCCESS;
  payload: any;
}

export interface UpdateUserProfileErrorAction {
  type: typeof UPDATE_USER_PROFILE_ERROR;
  payload: any;
}

export interface UserDeleteAccountAction {
  type: typeof USER_DELETE_ACCOUNT;
}

export interface UserDeleteAccountErrorAction {
  type: typeof USER_DELETE_ACCOUNT_ERROR;
  payload: any;
}

export interface UserResendVerificationEmailAction {
  type: typeof USER_RESEND_VERIFICATION_EMAIL;
  payload: {
    onSuccess(): void;
  };
}

export interface UserResendVerificationEmailErrorAction {
  type: typeof USER_RESEND_VERIFICATION_EMAIL_ERROR;
  payload: any;
}

export interface GetEmailStausRequestAction {
  type: typeof USER_GET_EMAIL_STATUS;
}

export interface GetEmailStausRequestSuccessAction {
  type: typeof USER_GET_EMAIL_STATUS_SUCCESS;
  payload: boolean;
}

export interface GetEmailStausRequestErrorAction {
  type: typeof USER_GET_EMAIL_STATUS_ERROR;
  payload: any;
}

export type GetEmailStausRequestType =
  | GetEmailStausRequestAction
  | GetEmailStausRequestSuccessAction
  | GetEmailStausRequestErrorAction;

export type UserActionTypes =
  | UserGetDocumentsAction
  | UserGetDocumentsSuccessAction
  | UserGetDocumentsErrorAction
  | UserGetAction
  | UserGetSuccessAction
  | UserGetErrorAction
  | UpdateUserProfileAction
  | UpdateUserProfileSuccessAction
  | UpdateUserProfileErrorAction
  | UserDeleteAccountAction
  | UserDeleteAccountErrorAction
  | UserResendVerificationEmailAction
  | UserResendVerificationEmailErrorAction
  | GetEmailStausRequestType;
