import { SupportedLanguages } from 'framework/constants';
import {
  ClientRequestState,
  IAddress,
  IClientRequest,
  IDocument,
  MediaCategoryType,
} from 'store/client-request/client-request.types';
import { IOffer } from 'store/offer/offer.types';
import { IInviteTokenData } from 'store/register/register.types';
import { IContactPerson } from 'store/contact-person/contact-person.types';
import { IUserObject } from 'store/auth/auth.types';
import { CostCalculationModeOptionsType, PoolType } from 'types/pool-settings.types';
import { ISearchDto, RequestState } from '../common.types';

export interface IOrganizationMembershipPlan {
  id?: number;
  organization?: IOrganization;
  monthlyPrice?: number;
  commission?: number;
  createdAt?: Date;
  dateSubscribed?: Date;
  validUntil?: Date;
  startFrom?: Date;
  optionsIncluded?: IMembershipOption[];
}

export interface ISearchPoolDto extends ISearchDto {
  isParent?: boolean;
  parentId?: string;
  includeParent?: boolean;
  query?: string;
}

interface IMembershipOption {
  id?: number;
  name: string;
  description?: string;
}

export interface IOrganization {
  address: IAddress;
  apiClientId: string;
  createdAt: Date;
  deletedDate: Date;
  displayId: string;
  id: string;
  inviteTokens: IInviteTokenData[];
  logo: string;
  members: IUserObject[];
  name: string;
  ownerId: string;
  state: string;
  type: string;
  updatedAt: Date;
  variableFee: number;
}

export interface ICurrency {
  currency: string;
  currencySymbol: string;
}

export interface IPool {
  createdAt: string;
  customAccentColor: string | null;
  customFont: string | null;
  deletedDate: string | null;
  id: string;
  intermediateHomeCheckOffer: boolean | null;
  homeCheckOfferApproval: string | null;
  installationOfferApproval: string | null;
  installationInvoiceApproval: string | null;
  intermediateOffer: boolean | null;
  isDefault: boolean | null;
  isPublicPool: boolean | null;
  deactivateQuickCheck: boolean | null;
  documentsAllowed: string[] | null;
  name: string;
  isParent: boolean;
  requireRequestConfirmation: boolean | null;
  updatedAt: string;
  poolTenderOrganizations: Organization[];
  clientRequests: IClientRequest[];
  childClientRequestPool: IPool[];
  questionnaire: IQuestionnaire;
  appointmentFrom: number | null;
  appointmentUntil: number | null;
  costCalculationMode: CostCalculationModeOptionsType | null;
  costEstimateMax: number | null;
  costEstimateMin: number | null;
  homeCheckEstimate: number | null;
  poolAvatar: null | string;
  installationSelectionMode: string | null;
  isEditingAllowed: null | boolean;
  isServiceFeeEnabled: boolean;
  serviceFeeCost: number | null;
  membership?: {
    optionsIncluded?: IMembershipOption[];
    validUntil?: Date;
  };
  intermediateOrganizationId: string;
  intermediateOrganization?: any;
  inviteTokens?: IInviteTokenData[];
  contactPersons: IContactPerson[];
  countries?: ICountry[];
  country: ICountry;
  poolType: PoolType;
  clientRequestsCount?: number | null;
  imprint?: string | null;
  dataPrivacy?: string | null;
  requestValidity?: string | null;
  offerMinimumValidity?: string | null;
  wizardWallboxJourney?: boolean | null;
  wizardInstallationJourney?: boolean | null;
  parentId?: string | null;
}

export interface ICountry {
  iso31661Alpha3: string;
  vatRate: number;
  currency: string;
  addresses?: IAddress[];
  defaultLanguage: SupportedLanguages;
  isDefault?: boolean;
  defaultTimezone: string;
  currencySymbol: string;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
}

export interface Organization {
  automaticApproval: boolean;
  createdAt?: string;
  deletedDate?: string | null;
  id?: string;
  organization?: any;
  updatedAt?: string;
}

export enum InviteTokenType {
  Installer = 'installer',
  User = 'enduser',
  InstallerPool = 'installerPool',
}

export interface InviteToken {
  createdAt: string;
  expires: string;
  id: string;
  organization: string | null;
  token: string;
  type: InviteTokenType;
  updatedAt: string;
}

export interface IPoolCreate {
  poolData: any;
  isDefault: boolean;
  requireRequestConfirmation?: boolean;
}

export interface IStats {
  pendingClientRequests: number;
  upcomingInstallations: number;
  finalizedJobs: number;
}
export interface IOrganizationInvite {
  firstName: string;
  lastName: string;
  email: string;
  organization: {
    name: string;
  };
  poolId: string;
}

export enum CustomDocumentTextType {
  LINK = 'link',
  EDITOR_TEXT = 'editor_text',
}

export enum CustomDocumentsCategoryType {
  UserLandingPage = 'user_landing_page',
  UserCostCalculatorDescription = 'user_cost_calculator',
  UserFAQPage = 'user_faq_page',
  SurveyLandingPage = 'survey_landing_page',
  ParentLandingPage = 'parent_pool_registration_link_page',
  OfferAnnotation = 'offer_annotation',
  PoolTermsConditions = 'pool_terms_conditions',
  PoolPrivacyPolicy = 'pool_privacy_policy',
  PoolCancelationPolicy = 'pool_cancelation_policy',
  PoolServiceFreeDescription = 'pool_service_fee_description',
  PoolImprint = 'pool_imprint',
  UserInfoPage = 'user_faq_page',
}

export enum SlugCategoryType {
  UserLandingPage = 'landing-page',
  UserCostCalculatorDescription = 'cost-calculator',
  UserFAQPage = 'faq-page',
  SurveyLandingPage = 'survey-landing-page',
  ParentLandingPage = 'parent-pool-registration-link-page',
  PoolServiceFreeDescription = 'pool-service-fee-description',
}

export interface ICustomDocument {
  id: string;
  createdAt: string;
  updatedAt: string;
  scopeId: string;
  scopeType: string;
  category: CustomDocumentsCategoryType;
  text?: string;
  textType?: string;
  lang: SupportedLanguages;
  imageLink?: string;
  media: any[];
  heading?: string;
}

export interface IQuestionnaireConfig {
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  questionKey: string;
  isDisabled: boolean;
  isVisible: boolean;
  defaultAnswer: any;
  possibleAnswers?: any;
}

export interface IStep {
  id: string;
  createdAt?: string;
  order: number;
  updatedAt?: string;
  parentId: string | null;
  questionnaireId: string | null;
  schema: any;
  uiSchema: any;
  title: string;
  children: IStep[];
  stepType: string;
}

export interface IQuestionnaire {
  id: string;
  createdAt?: string;
  isDefault: boolean;
  updatedAt?: string;
  name: string;
  steps: IStep[];
}

export interface IPoolOffersParams {
  poolId?: string;
  query?: string;
  state?: string[];
  clientRequestId?: string[];
  organizationId?: string[];
  limit?: number;
  offset?: number;
}

export interface IUnapprovedOffersParams {
  limit?: number;
  offset?: number;
}

export interface IPoolTag {
  id: string;
  title: string;
  colorName: string;
  colorCode: string;
}
export interface IIntermediateState {
  pools: IPool[];
  totalPools: number;
  poolsRequestState: RequestState | null;

  poolCreateState: RequestState | null;
  poolCreateError: string | null;

  poolUpdateSuccess: boolean | null;
  poolUpdateError: string | null;

  currentPool: IPool | null;
  currentPoolState: RequestState | null;
  currentPoolError: string | null;

  currentPoolRequests: {
    items: IClientRequest[];
    total: number;
  };
  currentPoolRequestsState: RequestState | null;
  currentPoolRequestsError: string | null;

  unapprovedClientRequests: IClientRequest[];

  clientRequests: {
    state: RequestState;
    data: {
      items: IClientRequest[];
      total: number;
    };
  };
  unapprovedOffers: IOffer[];
  findOrganizations: Organization[] | [];

  stats: IStats | null;
  statsState: RequestState | null;

  poolFiles: IDocument[];
  poolFilesState: RequestState | null;

  getCustomDocumentsState: RequestState | null;
  customDocument: ICustomDocument[];
  customerQuestionnaireConfigs: IQuestionnaireConfig[];

  questionnaireConfigs: IQuestionnaireConfig[];
  questionnaireConfigsState: RequestState | null;

  questionnaire: IQuestionnaire[] | null;
  questionnaireState: RequestState | null;
  orderInstallerDirectlyState: RequestState | null;
  landingPage: ICustomDocument | null;
  landingPageState: RequestState | null;
  servicesFeeDescription: ICustomDocument | null;
  servicesFeeDescriptionRequestState: RequestState | null;
  inviteTokenUpdateState: RequestState | null;

  customDocumentImages: IDocument[];
  customDocumentMediaLink: any;
  organizationsInvites: any;

  poolClientRequests: IClientRequest[];
  poolClientRequestsState: RequestState | null;
  poolClientRequestsError: string | null;

  poolOffers: {
    items: IOffer[];
    total: number;
  };
  poolOffersState: RequestState | null;
  poolOffersError: string | null;

  inviteTokens: IInviteTokenData[];
  inviteTokensState: RequestState | null;
  inviteTokensError: string | null;
  orderInstallerDirectly: any;

  currencies: any[];
  currenciesState: RequestState | null;
  currenciesError: string | null;

  legalDocuments: ICustomDocument[];
  legalDocumentsState: RequestState | null;
  legalDocumentsError: string | null;

  saveLegalDocumentsState: RequestState | null;
  saveLegalDocumentsError: string | null;

  installationWizardCustomImageUrls: {
    state: RequestState;
    wallboxOnly: string;
    wallboxAndInstallation: string;
  };

  poolTags: {
    state: RequestState;
    tags: Array<IPoolTag & { state: RequestState }>;
  };
}

export const INTERMEDIATE_ARCHIVE_SUCCESS = 'INTERMEDIATE_ARCHIVE_SUCCESS';

export const POOLS_GET = 'POOLS_GET';
export const POOLS_GET_SUCCESS = 'POOLS_GET_SUCCESS';
export const POOLS_GET_ERROR = 'POOLS_GET_ERROR';

export const POOL_CREATE = 'POOL_CREATE';
export const POOL_CREATE_SUCCESS = 'POOL_CREATE_SUCCESS';
export const POOL_CREATE_ERROR = 'POOL_CREATE_ERROR';

export const POOL_GET_BY_ID = 'POOL_GET_BY_ID';
export const POOL_GET_BY_ID_SUCCESS = 'POOL_GET_BY_ID_SUCCESS';
export const POOL_GET_BY_ID_ERROR = 'POOL_GET_BY_ID_ERROR';

export const POOL_GET_REQUESTS_BY_ID = 'POOL_GET_REQUESTS_BY_ID';
export const POOL_GET_REQUESTS_BY_ID_SUCCESS = 'POOL_GET_REQUESTS_BY_ID_SUCCESS';
export const POOL_GET_REQUESTS_BY_ID_ERROR = 'POOL_GET_REQUESTS_BY_ID_ERROR';

export const POOL_UPDATE = 'POOL_UPDATE';
export const POOL_UPDATE_SUCCESS = 'POOL_UPDATE_SUCCESS';
export const POOL_UPDATE_ERROR = 'POOL_UPDATE_ERROR';

export const CONTACT_PERSONS_DELETE = 'CONTACT_PERSONS_DELETE';
export const CONTACT_PERSONS_DELETE_SUCCESS = 'CONTACT_PERSONS_DELETE_SUCCESS';
export const CONTACT_PERSONS_DELETE_ERROR = 'CONTACT_PERSONS_DELETE_ERROR';

export const UNAPPROVED_CLIENT_REQUESTS_GET = 'UNAPPROVED_CLIENT_REQUESTS_GET';
export const UNAPPROVED_CLIENT_REQUESTS_GET_SUCCESS = 'UNAPPROVED_CLIENT_REQUESTS_GET_SUCCESS';
export const UNAPPROVED_CLIENT_REQUESTS_GET_ERROR = 'UNAPPROVED_CLIENT_REQUESTS_GET_ERROR';

export const CLIENT_REQUESTS_GET = 'CLIENT_REQUESTS_GET';
export const CLIENT_REQUESTS_GET_SUCCESS = 'CLIENT_REQUESTS_GET_SUCCESS';
export const CLIENT_REQUESTS_GET_ERROR = 'CLIENT_REQUESTS_GET_ERROR';

export const UNAPPROVED_OFFERS_GET = 'UNAPPROVED_OFFERS_GET';
export const UNAPPROVED_OFFERS_GET_SUCCESS = 'UNAPPROVED_OFFERS_GET_SUCCESS';
export const UNAPPROVED_OFFERS_GET_ERROR = 'UNAPPROVED_OFFERS_GET_ERROR';

export const ORGANIZATIONS_FIND = 'ORGANIZATIONS_FIND';
export const ORGANIZATIONS_FIND_SUCCESS = 'ORGANIZATIONS_FIND_SUCCESS';
export const ORGANIZATIONS_FIND_ERROR = 'ORGANIZATIONS_FIND_ERROR';

export const STATS_GET = 'STATS_GET';
export const STATS_GET_SUCCESS = 'STATS_GET_SUCCESS';
export const STATS_GET_ERROR = 'STATS_GET_ERROR';

export const POOL_FILES_GET = 'POOL_FILES_GET';
export const POOL_FILES_GET_SUCCESS = 'POOL_FILES_GET_SUCCESS';
export const POOL_FILES_GET_ERROR = 'POOL_FILES_GET_ERROR';
export const RESET_POOL_FILES = 'RESET_POOL_FILES_GET';

export const POOL_FILE_UPLOAD = 'POOL_FILE_UPLOAD';
export const POOL_FILE_UPLOAD_SUCCESS = 'POOL_FILE_UPLOAD_SUCCESS';
export const POOL_FILE_UPLOAD_ERROR = 'POOL_FILE_UPLOAD_ERROR';

export const CUSTOM_DOCUMENTS_GET = 'CUSTOM_DOCUMENTS_GET';
export const CUSTOM_DOCUMENTS_GET_SUCCESS = 'CUSTOM_DOCUMENTS_GET_SUCCESS';
export const CUSTOM_DOCUMENTS_GET_ERROR = 'CUSTOM_DOCUMENTS_GET_ERROR';

export const CUSTOM_DOCUMENTS_CREATE = 'CUSTOM_DOCUMENTS_CREATE';
export const CUSTOM_DOCUMENTS_CREATE_SUCCESS = 'CUSTOM_DOCUMENTS_CREATE_SUCCESS';
export const CUSTOM_DOCUMENTS_CREATE_ERROR = 'CUSTOM_DOCUMENTS_CREATE_ERROR';

export const CUSTOM_DOCUMENTS_UPDATE = 'CUSTOM_DOCUMENTS_UPDATE';
export const CUSTOM_DOCUMENTS_UPDATE_SUCCESS = 'CUSTOM_DOCUMENTS_UPDATE_SUCCESS';
export const CUSTOM_DOCUMENTS_UPDATE_ERROR = 'CUSTOM_DOCUMENTS_UPDATE_ERROR';

export const CUSTOM_DOCUMENTS_DELETE = 'CUSTOM_DOCUMENTS_DELETE';
export const CUSTOM_DOCUMENTS_DELETE_SUCCESS = 'CUSTOM_DOCUMENTS_DELETE_SUCCESS';
export const CUSTOM_DOCUMENTS_DELETE_ERROR = 'CUSTOM_DOCUMENTS_DELETE_ERROR';

export const INVITE_ORGANIZATION = 'INVITE_ORGANIZATION';
export const INVITE_ORGANIZATION_SUCCESS = 'INVITE_ORGANIZATION_SUCCESS';
export const INVITE_ORGANIZATION_ERROR = 'INVITE_ORGANIZATION_ERROR';

export const GET_QUESTIONNAIRE_CONFIGS_FOR_CUSTOMER = 'GET_QUESTIONNAIRE_CONFIGS_FOR_CUSTOMER';
export const GET_QUESTIONNAIRE_CONFIGS_FOR_CUSTOMER_SUCCESS =
  'GET_QUESTIONNAIRE_CONFIGS_FOR_CUSTOMER_SUCCESS';
export const GET_QUESTIONNAIRE_CONFIGS_FOR_CUSTOMER_ERROR =
  'GET_QUESTIONNAIRE_CONFIGS_FOR_CUSTOMER_ERROR';

export const GET_QUESTIONNAIRE_CONFIGS = 'GET_QUESTIONNAIRE_CONFIGS';
export const GET_QUESTIONNAIRE_CONFIGS_SUCCESS = 'GET_QUESTIONNAIRE_CONFIGS_SUCCESS';
export const GET_QUESTIONNAIRE_CONFIGS_ERROR = 'GET_QUESTIONNAIRE_CONFIGS_ERROR';

export const GET_QUESTIONNAIRE = 'GET_QUESTIONNAIRE';
export const GET_QUESTIONNAIRE_SUCCESS = 'GET_QUESTIONNAIRE_SUCCESS';
export const GET_QUESTIONNAIRE_ERROR = 'GET_QUESTIONNAIRE_ERROR';

export const CREATE_QUESTIONNAIRE_CONFIGS = 'CREATE_QUESTIONNAIRE_CONFIGS';
export const CREATE_QUESTIONNAIRE_CONFIGS_SUCCESS = 'CREATE_QUESTIONNAIRE_CONFIGS_SUCCESS';
export const CREATE_QUESTIONNAIRE_CONFIGS_ERROR = 'CREATE_QUESTIONNAIRE_CONFIGS_ERROR';

export const UPDATE_QUESTIONNAIRE_CONFIGS = 'UPDATE_QUESTIONNAIRE_CONFIGS';
export const UPDATE_QUESTIONNAIRE_CONFIGS_SUCCESS = 'UPDATE_QUESTIONNAIRE_CONFIGS_SUCCESS';
export const UPDATE_QUESTIONNAIRE_CONFIGS_ERROR = 'UPDATE_QUESTIONNAIRE_CONFIGS_ERROR';

export const DELETE_QUESTIONNAIRE_CONFIGS = 'DELETE_QUESTIONNAIRE_CONFIGS';
export const DELETE_QUESTIONNAIRE_CONFIGS_SUCCESS = 'DELETE_QUESTIONNAIRE_CONFIGS_SUCCESS';
export const DELETE_QUESTIONNAIRE_CONFIGS_ERROR = 'DELETE_QUESTIONNAIRE_CONFIGS_ERROR';

export const LANDING_PAGE_GET = 'LANDING_PAGE_GET';
export const LANDING_PAGE_GET_SUCCESS = 'LANDING_PAGE_GET_SUCCESS';
export const LANDING_PAGE_GET_ERROR = 'LANDING_PAGE_GET_ERROR';

export const SERVICE_FEE_DESCRIPTION_GET = 'SERVICE_FEE_DESCRIPTION_GET';
export const SERVICE_FEE_DESCRIPTION_GET_SUCCESS = 'SERVICE_FEE_DESCRIPTION_GET_SUCCESS';
export const SERVICE_FEE_DESCRIPTION_GET_ERROR = 'SERVICE_FEE_DESCRIPTION_GET_ERROR';

export const INVITE_TOKEN_UPDATE = 'INVITE_TOKEN_UPDATE';
export const INVITE_TOKEN_UPDATE_SUCCESS = 'INVITE_TOKEN_UPDATE_SUCCESS';
export const INVITE_TOKEN_UPDATE_ERROR = 'INVITE_TOKEN_UPDATE_ERROR';

export const CUSTOM_DOCUMENTS_IMAGE_UPLOAD = 'CUSTOM_DOCUMENTS_IMAGE_UPLOAD';
export const CUSTOM_DOCUMENTS_IMAGE_UPLOAD_SUCCESS = 'CUSTOM_DOCUMENTS_IMAGE_UPLOAD_SUCCESS';
export const CUSTOM_DOCUMENTS_IMAGE_UPLOAD_ERROR = 'CUSTOM_DOCUMENTS_IMAGE_UPLOAD_ERROR';

export const CUSTOM_DOCUMENTS_IMAGES_GET = 'CUSTOM_DOCUMENTS_IMAGE_GET';
export const CUSTOM_DOCUMENTS_IMAGES_GET_SUCCESS = 'CUSTOM_DOCUMENTS_IMAGE_GET_SUCCESS';
export const CUSTOM_DOCUMENTS_IMAGES_GET_ERROR = 'CUSTOM_DOCUMENTS_IMAGE_GET_ERROR';

export const CUSTOM_DOCUMENTS_MEDIA_LINK_GET = 'CUSTOM_DOCUMENTS_MEDIA_LINK_GET';
export const CUSTOM_DOCUMENTS_MEDIA_LINK_GET_SUCCESS = 'CUSTOM_DOCUMENTS_MEDIA_LINK_GET_SUCCESS';
export const CUSTOM_DOCUMENTS_MEDIA_LINK_GET_ERROR = 'CUSTOM_DOCUMENTS_MEDIA_LINK_GET_ERROR';

export const EXISTING_ORGANIZATION_INVITE = 'EXISTING_ORGANIZATION_INVITE';
export const EXISTING_ORGANIZATION_INVITE_SUCCESS = 'EXISTING_ORGANIZATION_INVITE_SUCCESS';
export const EXISTING_ORGANIZATION_INVITE_ERROR = 'EXISTING_ORGANIZATION_INVITE_ERROR';

export const ORGANIZATIONS_INVITES_GET = 'ORGANIZATIONS_INVITES_GET';
export const ORGANIZATIONS_INVITES_GET_SUCCESS = 'ORGANIZATIONS_INVITES_GET_SUCCESS';
export const ORGANIZATIONS_INVITES_GET_ERROR = 'ORGANIZATIONS_INVITES_GET_ERROR';

export const ORGANIZATIONS_INVITE_DELETE = 'ORGANIZATIONS_INVITE_DELETE';
export const ORGANIZATIONS_INVITE_DELETE_SUCCESS = 'ORGANIZATIONS_INVITE_DELETE_SUCCESS';
export const ORGANIZATIONS_INVITE_DELETE_ERROR = 'ORGANIZATIONS_INVITE_DELETE_ERROR';

export const POOL_CLIENT_REQUESTS_GET = 'POOL_CLIENT_REQUESTS_GET';
export const POOL_CLIENT_REQUESTS_GET_SUCCESS = 'POOL_CLIENT_REQUESTS_GET_SUCCESS';
export const POOL_CLIENT_REQUESTS_GET_ERROR = 'POOL_CLIENT_REQUESTS_GET_ERROR';

export const POOL_OFFERS_GET = 'POOL_OFFERS_GET';
export const POOL_OFFERS_GET_SUCCESS = 'POOL_OFFERS_GET_SUCCESS';
export const POOL_OFFERS_GET_ERROR = 'POOL_OFFERS_GET_ERROR';

export const POOL_INVITE_TOKENS_GET = 'POOL_INVITE_TOKENS_GET';
export const POOL_INVITE_TOKENS_GET_SUCCESS = 'POOL_INVITE_TOKENS_GET_SUCCESS';
export const POOL_INVITE_TOKENS_GET_ERROR = 'POOL_INVITE_TOKENS_GET_ERROR';

export const SEND_EMAIL_REMINDER = 'SEND_EMAIL_REMINDER';
export const SEND_EMAIL_REMINDER_SUCCESS = 'SEND_EMAIL_REMINDER_SUCCESS';
export const SEND_EMAIL_REMINDER_ERROR = 'SEND_EMAIL_REMINDER_ERROR';

export const POOL_GET_OFFER_ANNOTATION = 'POOL_GET_OFFER_ANNOTATION';
export const POOL_GET_OFFER_ANNOTATION_SUCCESS = 'POOL_GET_OFFER_ANNOTATION_SUCCESS';
export const POOL_GET_OFFER_ANNOTATION_ERROR = 'POOL_GET_OFFER_ANNOTATION_ERROR';

export const ORDER_INSTALLER_DIRECTLY = 'ORDER_INSTALLER_DIRECTLY';
export const ORDER_INSTALLER_DIRECTLY_SUCCESS = 'ORDER_INSTALLER_DIRECTLY_SUCCESS';
export const ORDER_INSTALLER_DIRECTLY_ERROR = 'ORDER_INSTALLER_DIRECTLY_SUCCESS';

export const ORDER_DOWNLOAD_PDF = 'ORDER_DOWNLOAD_PDF';
export const ORDER_DOWNLOAD_PDF_SUCCESS = 'ORDER_DOWNLOAD_PDF_SUCCESS';
export const ORDER_DOWNLOAD_PDF_ERROR = 'ORDER_DOWNLOAD_PDF_ERROR';

export const GET_CURRENCIES_BY_ORGANIZATION = 'GET_CURRENCIES_BY_ORGANIZATION';
export const GET_CURRENCIES_BY_ORGANIZATION_SUCCESS = 'GET_CURRENCIES_BY_ORGANIZATION_SUCCESS';
export const GET_CURRENCIES_BY_ORGANIZATION_ERROR = 'GET_CURRENCIES_BY_ORGANIZATION_ERROR';

export const GET_LEGAL_DOCUMENTS = 'GET_LEGAL_DOCUMENTS';
export const GET_LEGAL_DOCUMENTS_SUCCESS = 'GET_LEGAL_DOCUMENTS_SUCCESS';
export const GET_LEGAL_DOCUMENTS_ERROR = 'GET_LEGAL_DOCUMENTS_ERROR';

export const SAVE_LEGAL_DOCUMENTS = 'SAVE_LEGAL_DOCUMENTS';
export const SAVE_LEGAL_DOCUMENTS_SUCCESS = 'SAVE_LEGAL_DOCUMENTS_SUCCESS';
export const SAVE_LEGAL_DOCUMENTS_ERROR = 'SAVE_LEGAL_DOCUMENTS_ERROR';

export const GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS =
  'GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS';
export const GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS_SUCCESS =
  'GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS_SUCCESS';
export const GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS_ERROR =
  'GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS_ERROR';
export const RESET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS =
  'RESET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS';

export const GET_POOL_TAGS = 'GET_POOL_TAGS';
export const GET_POOL_TAGS_SUCCESS = 'GET_POOL_TAGS_SUCCESS';
export const GET_POOL_TAGS_ERROR = 'GET_POOL_TAGS_ERROR';

export const CREATE_POOL_TAG = 'CREATE_POOL_TAG';
export const CREATE_POOL_TAG_SUCCESS = 'CREATE_POOL_TAG_SUCCESS';
export const CREATE_POOL_TAG_ERROR = 'CREATE_POOL_TAG_ERROR';

export const EDIT_POOL_TAG = 'EDIT_POOL_TAG';
export const EDIT_POOL_TAG_SUCCESS = 'EDIT_POOL_TAG_SUCCESS';
export const EDIT_POOL_TAG_ERROR = 'EDIT_POOL_TAG_ERROR';

export const DELETE_POOL_TAG = 'DELETE_POOL_TAG';
export const DELETE_POOL_TAG_SUCCESS = 'DELETE_POOL_TAG_SUCCESS';
export const DELETE_POOL_TAG_ERROR = 'DELETE_POOL_TAG_ERROR';

//Get Pool Invite Tokens
export interface PoolInviteTokensGetAction {
  type: typeof POOL_INVITE_TOKENS_GET;
  payload: string;
}

export interface PoolInviteTokensGetSuccessAction {
  type: typeof POOL_INVITE_TOKENS_GET_SUCCESS;
  payload: IInviteTokenData[];
}

export interface PoolInviteTokensGetErrorAction {
  type: typeof POOL_INVITE_TOKENS_GET_ERROR;
  payload: any;
}

//Delete Organization Invite
export interface OrganizationInviteDeleteAction {
  type: typeof ORGANIZATIONS_INVITE_DELETE;
  payload: string;
}

export interface OrganizationInviteDeleteSuccessAction {
  type: typeof ORGANIZATIONS_INVITE_DELETE_SUCCESS;
  payload: any;
}

export interface OrganizationInviteDeleteErrorAction {
  type: typeof ORGANIZATIONS_INVITE_DELETE_ERROR;
  payload: any;
}

//Get Organizations Invites
export interface OrganizationsInvitesGetAction {
  type: typeof ORGANIZATIONS_INVITES_GET;
  payload: string;
}

export interface OrganizationsInvitesGetSuccessAction {
  type: typeof ORGANIZATIONS_INVITES_GET_SUCCESS;
  payload: any;
}

export interface OrganizationsInvitesGetErrorAction {
  type: typeof ORGANIZATIONS_INVITES_GET_ERROR;
  payload: any;
}

//Invite Existing Organization
export interface ExistingOrganizationInviteAction {
  type: typeof EXISTING_ORGANIZATION_INVITE;
  payload: {
    poolId: string;
    organizationId: string;
  };
}

export interface ExistingOrganizationInviteSuccessAction {
  type: typeof EXISTING_ORGANIZATION_INVITE_SUCCESS;
  payload: any;
}

export interface ExistingOrganizationInviteErrorAction {
  type: typeof EXISTING_ORGANIZATION_INVITE_ERROR;
  payload: any;
}

//Get Custom Document Media Link
export interface CustomDocumentMediaLinkGetAction {
  type: typeof CUSTOM_DOCUMENTS_MEDIA_LINK_GET;
  payload: {
    customDocumentId: string;
    mediaName: string;
  };
}

export interface CustomDocumentMediaLinkGetSuccessAction {
  type: typeof CUSTOM_DOCUMENTS_MEDIA_LINK_GET_SUCCESS;
  payload: any;
}

export interface CustomDocumentMediaLinkGetErrorAction {
  type: typeof CUSTOM_DOCUMENTS_MEDIA_LINK_GET_ERROR;
  payload: any;
}

//Get Custom Document Images
export interface CustomDocumentImagesGetAction {
  type: typeof CUSTOM_DOCUMENTS_IMAGES_GET;
  payload: string;
}

export interface CustomDocumentImagesGetSuccessAction {
  type: typeof CUSTOM_DOCUMENTS_IMAGES_GET_SUCCESS;
  payload: IDocument[];
}

export interface CustomDocumentImagesGetErrorAction {
  type: typeof CUSTOM_DOCUMENTS_IMAGES_GET_ERROR;
  payload: any;
}

//Upload Custom Document Image
export interface CustomDocumentImageUploadAction {
  type: typeof CUSTOM_DOCUMENTS_IMAGE_UPLOAD;
  payload: {
    document: File;
    customDocumentId: string;
    category: MediaCategoryType;
    uploadProgress: any;
    uploadSuccess?: any;
    uploadError?: any;
  };
}

export interface CustomDocumentImageUploadSuccessAction {
  type: typeof CUSTOM_DOCUMENTS_IMAGE_UPLOAD_SUCCESS;
  payload: IDocument;
}

export interface CustomDocumentImageUploadErrorAction {
  type: typeof CUSTOM_DOCUMENTS_IMAGE_UPLOAD_ERROR;
  payload: any;
}

//Update Invite Token
export interface InviteTokenUpdateAction {
  type: typeof INVITE_TOKEN_UPDATE;
  payload: {
    tokenId: string;
    token: IInviteTokenData;
  };
}

export interface InviteTokenUpdateSuccessAction {
  type: typeof INVITE_TOKEN_UPDATE_SUCCESS;
  payload: any;
}

export interface InviteTokenUpdateErrorAction {
  type: typeof INVITE_TOKEN_UPDATE_ERROR;
  payload: any;
}

//Get Landing Page
export interface LandingPageGetAction {
  type: typeof LANDING_PAGE_GET;
  payload: {
    poolId: string;
    category: string;
    onSuccess?: Function;
  };
}

export interface LandingPageGetSuccessAction {
  type: typeof LANDING_PAGE_GET_SUCCESS;
  payload: ICustomDocument;
}

export interface LandingPageGetErrorAction {
  type: typeof LANDING_PAGE_GET_ERROR;
  payload: any;
}
export interface ServicesFeeDescriptionGetAction {
  type: typeof SERVICE_FEE_DESCRIPTION_GET;
  payload: {
    poolId: string;
  };
}
export interface ServicesFeeDescriptionGetSuccessAction {
  type: typeof SERVICE_FEE_DESCRIPTION_GET_SUCCESS;
  payload: ICustomDocument;
}
export interface ServicesFeeDescriptionGetErrorAction {
  type: typeof SERVICE_FEE_DESCRIPTION_GET_ERROR;
  payload: string;
}

//Get Questionnaire Config For Customer
export interface QuestionnaireConfigForCustomerGetAction {
  type: typeof GET_QUESTIONNAIRE_CONFIGS_FOR_CUSTOMER;
}

export interface QuestionnaireConfigForCustomerGetSuccessAction {
  type: typeof GET_QUESTIONNAIRE_CONFIGS_FOR_CUSTOMER_SUCCESS;
  payload: IQuestionnaireConfig[];
}

export interface QuestionnaireConfigForCustomerGetErrorAction {
  type: typeof GET_QUESTIONNAIRE_CONFIGS_FOR_CUSTOMER_ERROR;
  payload: any;
}

//Get Questionnaire Config
export interface QuestionnaireConfigGetAction {
  type: typeof GET_QUESTIONNAIRE_CONFIGS;
  payload: string;
}

export interface QuestionnaireConfigGetSuccessAction {
  type: typeof GET_QUESTIONNAIRE_CONFIGS_SUCCESS;
  payload: IQuestionnaireConfig[];
}

export interface QuestionnaireConfigGetErrorAction {
  type: typeof GET_QUESTIONNAIRE_CONFIGS_ERROR;
  payload: any;
}

export interface QuestionnaireGetAction {
  type: typeof GET_QUESTIONNAIRE;
  payload: string;
}

export interface QuestionnaireGetSuccessAction {
  type: typeof GET_QUESTIONNAIRE_SUCCESS;
  payload: [];
}

export interface QuestionnaireGetErrorAction {
  type: typeof GET_QUESTIONNAIRE_ERROR;
  payload: any;
}

//Create Questionnaire Config
export interface QuestionnaireConfigCreateAction {
  type: typeof CREATE_QUESTIONNAIRE_CONFIGS;
  payload: {
    poolId: string;
    config: IQuestionnaireConfig;
  };
}

export interface QuestionnaireConfigCreateSuccessAction {
  type: typeof CREATE_QUESTIONNAIRE_CONFIGS_SUCCESS;
  payload: IQuestionnaireConfig;
}

export interface QuestionnaireConfigCreateErrorAction {
  type: typeof CREATE_QUESTIONNAIRE_CONFIGS_ERROR;
  payload: any;
}

//Update Questionnaire Config
export interface QuestionnaireConfigUpdateAction {
  type: typeof UPDATE_QUESTIONNAIRE_CONFIGS;
  payload: {
    poolId: string;
    configId: string;
    config: IQuestionnaireConfig;
  };
}

export interface QuestionnaireConfigUpdateSuccessAction {
  type: typeof UPDATE_QUESTIONNAIRE_CONFIGS_SUCCESS;
  payload: IQuestionnaireConfig;
}

export interface QuestionnaireConfigUpdateErrorAction {
  type: typeof UPDATE_QUESTIONNAIRE_CONFIGS_ERROR;
  payload: any;
}

//Delete Questionnaire Config
export interface QuestionnaireConfigDeleteAction {
  type: typeof DELETE_QUESTIONNAIRE_CONFIGS;
  payload: {
    poolId: string;
    configId: string;
  };
}

export interface QuestionnaireConfigDeleteSuccessAction {
  type: typeof DELETE_QUESTIONNAIRE_CONFIGS_SUCCESS;
  payload: string;
}

export interface QuestionnaireConfigDeleteErrorAction {
  type: typeof DELETE_QUESTIONNAIRE_CONFIGS_ERROR;
  payload: any;
}

//Send Invitation Link to Organization
export interface OrganizationInviteAction {
  type: typeof INVITE_ORGANIZATION;
  payload: {
    inviteData: IOrganizationInvite;
    onSuccess?: any;
  };
}

export interface OrganizationInviteSuccessAction {
  type: typeof INVITE_ORGANIZATION_SUCCESS;
  payload: any;
}

export interface OrganizationInviteErrorAction {
  type: typeof INVITE_ORGANIZATION_ERROR;
  payload: any;
}

//Create Custom Document
export interface CustomDocumentUpdateAction {
  type: typeof CUSTOM_DOCUMENTS_UPDATE;
  payload: {
    poolId: string;
    documentId: string;
    document: ICustomDocument;
  };
}
export interface CustomDocumentDeleteAction {
  type: typeof CUSTOM_DOCUMENTS_DELETE;
  payload: {
    documentId: string;
  };
}

export interface CustomDocumentUpdateSuccessAction {
  type: typeof CUSTOM_DOCUMENTS_UPDATE_SUCCESS;
  payload: ICustomDocument;
}

export interface CustomDocumentUpdateErrorAction {
  type: typeof CUSTOM_DOCUMENTS_UPDATE_ERROR;
  payload: any;
}

//Create Custom Document
export interface CustomDocumentCreateAction {
  type: typeof CUSTOM_DOCUMENTS_CREATE;
  payload: {
    poolId: string;
    document: {
      category: CustomDocumentsCategoryType;
      text?: string;
      imageLink?: string;
      media: any[];
    };
    createSuccess?: any;
  };
}

export interface CustomDocumentCreateSuccessAction {
  type: typeof CUSTOM_DOCUMENTS_CREATE_SUCCESS;
  payload: ICustomDocument;
}
export interface CustomDocumentDeleteSuccessAction {
  type: typeof CUSTOM_DOCUMENTS_DELETE_SUCCESS;
  payload: {
    documentId: string;
  };
}

export interface CustomDocumentCreateErrorAction {
  type: typeof CUSTOM_DOCUMENTS_CREATE_ERROR;
  payload: any;
}

//Get Custom Documents
export interface CustomDocumentGetAction {
  type: typeof CUSTOM_DOCUMENTS_GET;
  payload: string;
}

export interface CustomDocumentGetSuccessAction {
  type: typeof CUSTOM_DOCUMENTS_GET_SUCCESS;
  payload: ICustomDocument[];
}

export interface CustomDocumentGetErrorAction {
  type: typeof CUSTOM_DOCUMENTS_GET_ERROR;
  payload: any;
}

//Upload Pool File
export interface PoolFileUploadAction {
  type: typeof POOL_FILE_UPLOAD;
  payload: {
    document: File;
    poolId: string;
    category: MediaCategoryType;
    uploadProgress: any;
    uploadSuccess?: any;
    uploadError?: any;
    onSuccess?: () => void;
  };
}

export interface PoolFileUploadSuccessAction {
  type: typeof POOL_FILE_UPLOAD_SUCCESS;
  payload: IDocument;
}

export interface PoolFileUploadErrorAction {
  type: typeof POOL_FILE_UPLOAD_ERROR;
  payload: any;
}

//Get Pool Files
export interface PoolFilesGetAction {
  type: typeof POOL_FILES_GET;
  payload: {
    poolId: string;
    category: MediaCategoryType;
  };
}

export interface PoolFilesGetSuccessAction {
  type: typeof POOL_FILES_GET_SUCCESS;
  payload: IDocument[];
}

export interface PoolFilesGetErrorAction {
  type: typeof POOL_FILES_GET_ERROR;
  payload: any;
}

export interface ResetPoolFilesAction {
  type: typeof RESET_POOL_FILES;
}

//Get Stats
export interface StatsGetAction {
  type: typeof STATS_GET;
}

export interface StatsGetSuccessAction {
  type: typeof STATS_GET_SUCCESS;
  payload: IStats;
}

export interface StatsGetErrorAction {
  type: typeof STATS_GET_ERROR;
  payload: any;
}

//Get Client Request Pools
export interface PoolsGetAction {
  type: typeof POOLS_GET;
  payload?: {
    payload?: ISearchPoolDto;
    onSuccess?: Function;
  };
}

export interface PoolsGetSuccessAction {
  type: typeof POOLS_GET_SUCCESS;
  payload: { pools: IPool[]; totalPools: number };
}

export interface PoolsGetErrorAction {
  type: typeof POOLS_GET_ERROR;
  payload: any;
}

//Create Client Request Pool
export interface PoolCreateAction {
  type: typeof POOL_CREATE;
  payload: { pool: IPoolCreate; onSuccess: () => void };
}

export interface PoolCreateSuccessAction {
  type: typeof POOL_CREATE_SUCCESS;
  payload: IPool;
}

export interface PoolCreateErrorAction {
  type: typeof POOL_CREATE_ERROR;
  payload: any;
}

//Get Client Request Pool By ID
export interface PoolGetByIdAction {
  type: typeof POOL_GET_BY_ID;
  payload: string;
}

export interface PoolGetByIdSuccessAction {
  type: typeof POOL_GET_BY_ID_SUCCESS;
  payload: IPool;
}

export interface PoolGetByIdErrorAction {
  type: typeof POOL_GET_BY_ID_ERROR;
  payload: any;
}

//Get requests by pool id
export interface PoolGetRequestsByIdAction {
  type: typeof POOL_GET_REQUESTS_BY_ID;
  payload: {
    searchDto: ISearchDto;
    poolId: string;
    filters?: {
      query: string;
      state: string[];
    };
  };
}

export interface PoolGetRequestsByIdSuccessAction {
  type: typeof POOL_GET_REQUESTS_BY_ID_SUCCESS;
  payload: {
    items: IClientRequest[];
    total: number;
  };
}

export interface PoolGetRequestsByIdErrorAction {
  type: typeof POOL_GET_REQUESTS_BY_ID_ERROR;
  payload: any;
}

//Update Client Request Pool
export interface PoolUpdateAction {
  type: typeof POOL_UPDATE;
  payload?: {
    poolId: string;
    pool: IPool;
  };
}

export interface PoolUpdateSuccessAction {
  type: typeof POOL_UPDATE_SUCCESS;
  payload: IPool;
}

export interface PoolUpdateErrorAction {
  type: typeof POOL_UPDATE_ERROR;
  payload: any;
}

//Get Unapproved Client Requests

export interface UnapprovedClientRequestsGetSuccessAction {
  type: typeof UNAPPROVED_CLIENT_REQUESTS_GET_SUCCESS;
  payload: IClientRequest[];
}

export interface UnapprovedClientRequestsGetAction {
  type: typeof UNAPPROVED_CLIENT_REQUESTS_GET;
  payload: {
    offset: number;
    archived: any;
  };
}

// Get client requests (search)
export interface ClientRequestsGetAction {
  type: typeof CLIENT_REQUESTS_GET;
  payload: {
    query?: string;
    states?: Array<ClientRequestState>;
    excludedStates?: Array<ClientRequestState>;
    offset: number;
    limit: number;
    archived?: any;
  };
}
export interface ClientRequestsGetSuccessAction {
  type: typeof CLIENT_REQUESTS_GET_SUCCESS;
  payload: {
    items: IClientRequest[];
    total: number;
  };
}
export interface ClientRequestsGetErrorAction {
  type: typeof CLIENT_REQUESTS_GET_ERROR;
}

//Get Unapproved Offers
export interface UnapprovedOffersGetAction {
  type: typeof UNAPPROVED_OFFERS_GET;
}

export interface UnapprovedOffersGetSuccessAction {
  type: typeof UNAPPROVED_OFFERS_GET_SUCCESS;
  payload: IOffer[];
}

export interface UnapprovedOffersGetErrorAction {
  type: typeof UNAPPROVED_OFFERS_GET_ERROR;
  payload: any;
}

//Find Organizations
export interface OrganizationsFindAction {
  type: typeof ORGANIZATIONS_FIND;
  payload: {
    searchValue: string;
    intermediateOrganizationId?: string;
    limit?: number;
  };
}

export interface OrganizationsFindSuccessAction {
  type: typeof ORGANIZATIONS_FIND_SUCCESS;
  payload: Organization[];
}

export interface OrganizationsFindErrorAction {
  type: typeof ORGANIZATIONS_FIND_ERROR;
  payload: any;
}

//Get Pool Offers
export interface PoolOffersGetAction {
  type: typeof POOL_OFFERS_GET;
  payload: IPoolOffersParams;
}

export interface PoolOffersGetSuccessAction {
  type: typeof POOL_OFFERS_GET_SUCCESS;
  payload: {
    items: IOffer[];
    total: number;
  };
}

export interface PoolOffersGetErrorAction {
  type: typeof POOL_OFFERS_GET_ERROR;
  payload: any;
}

//Get Pool Client Requests
export interface PoolClientRequestsGetAction {
  type: typeof POOL_CLIENT_REQUESTS_GET;
  payload: string;
}

export interface PoolClientRequestsGetSuccessAction {
  type: typeof POOL_CLIENT_REQUESTS_GET_SUCCESS;
  payload: IClientRequest[];
}

export interface PoolClientRequestsGetErrorAction {
  type: typeof POOL_CLIENT_REQUESTS_GET_ERROR;
  payload: any;
}

// Send Email Reminder
export interface SendEmailReminderAction {
  type: typeof SEND_EMAIL_REMINDER;
  payload: {
    userId: string;
    text: string;
    onSuccess?: Function;
  };
}

export interface SendEmailReminderErrorAction {
  type: typeof SEND_EMAIL_REMINDER_ERROR;
  payload: any;
}

export interface IntermediateArchiveSuccessAction {
  type: typeof INTERMEDIATE_ARCHIVE_SUCCESS;
  payload: any;
}

export interface orderInstallerDirectlySuccessAction {
  type: typeof ORDER_INSTALLER_DIRECTLY_SUCCESS;
  payload: any;
}

export interface orderInstallerDirectlyErrorAction {
  type: typeof ORDER_INSTALLER_DIRECTLY_ERROR;
  payload: any;
}

export interface CurrenciesByOrganizationGetAction {
  type: typeof GET_CURRENCIES_BY_ORGANIZATION;
  payload: string;
}

export interface CurrenciesByOrganizationGetSuccessAction {
  type: typeof GET_CURRENCIES_BY_ORGANIZATION_SUCCESS;
  payload: any[];
}

export interface CurrenciesByOrganizationGetErrorAction {
  type: typeof GET_CURRENCIES_BY_ORGANIZATION_ERROR;
  payload: any;
}

export interface PoolLegalDocumentsGetAction {
  type: typeof GET_LEGAL_DOCUMENTS;
  payload: {
    poolId: string;
    isPublic: boolean;
  };
}
export interface PoolLegalDocumentsGetSuccessAction {
  type: typeof GET_LEGAL_DOCUMENTS_SUCCESS;
  payload: ICustomDocument[];
}
export interface PoolLegalDocumentsGetErrorAction {
  type: typeof GET_LEGAL_DOCUMENTS_ERROR;
  payload: any;
}

export interface PoolLegalDocumentsSaveAction {
  type: typeof SAVE_LEGAL_DOCUMENTS;
  payload: {
    poolId: string;
    legalDocuments: ICustomDocument[];
    onSuccess?: () => void;
  };
}

export interface PoolLegalDocumentsSaveSuccessAction {
  type: typeof SAVE_LEGAL_DOCUMENTS_SUCCESS;
  payload: ICustomDocument[];
}

export interface PoolLegalDocumentsSaveErrorAction {
  type: typeof SAVE_LEGAL_DOCUMENTS_ERROR;
  payload: any;
}

export interface GetInstallationWizardCustomImagesUrlsAction {
  type: typeof GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS;
  payload: { wallboxOnlyFileName: string; wallboxAndInstallationFileName: string };
}
export interface GetInstallationWizardCustomImagesUrlsSuccessAction {
  type: typeof GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS_SUCCESS;
  payload: { wallboxOnlyUrl: string; wallboxAndInstallationUrl: string };
}
export interface GetInstallationWizardCustomImagesUrlsErrorAction {
  type: typeof GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS_ERROR;
  payload: { error: string };
}
export interface ResetInstallationWizardCustomImagesUrlsAction {
  type: typeof RESET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS;
}

export interface GetPoolTagsAction {
  type: typeof GET_POOL_TAGS;
  payload: { poolId: string };
}

export interface GetPoolTagsSuccessAction {
  type: typeof GET_POOL_TAGS_SUCCESS;
  payload: Array<IPoolTag & { state: RequestState }>;
}
export interface GetPoolTagsErrorAction {
  type: typeof GET_POOL_TAGS_ERROR;
}

export interface CreatePoolTagAction {
  type: typeof CREATE_POOL_TAG;
  payload: {
    colorCode: string;
    colorName: string;
    onFinish: (isSuccess: boolean, poolTagId: string) => void;
    poolId: string;
    title: string;
  };
}

export interface CreatePoolTagSuccessAction {
  type: typeof CREATE_POOL_TAG_SUCCESS;
  payload: IPoolTag & { pool: IPool };
}
export interface CreatePoolTagErrorAction {
  type: typeof CREATE_POOL_TAG_ERROR;
  payload: { error: string; colorCode: string };
}

export interface EditPoolTagAction {
  type: typeof EDIT_POOL_TAG;
  payload: { onFinish: (isSuccess: boolean) => void; poolTagId: string; title: string };
}

export interface EditPoolTagSuccessAction {
  type: typeof EDIT_POOL_TAG_SUCCESS;
  payload: IPoolTag;
}
export interface EditPoolTagErrorAction {
  type: typeof EDIT_POOL_TAG_ERROR;
  payload: { error: string; colorCode: string };
}

export interface DeletePoolTagAction {
  type: typeof DELETE_POOL_TAG;
  payload: { onFinish: (isSuccess: boolean) => void; poolTagId: string };
}

export interface DeletePoolTagSuccessAction {
  type: typeof DELETE_POOL_TAG_SUCCESS;
  payload: IPoolTag;
}
export interface DeletePoolTagErrorAction {
  type: typeof DELETE_POOL_TAG_ERROR;
  payload: { error: string };
}

export type PoolsActionTypes =
  | IntermediateArchiveSuccessAction
  | PoolsGetAction
  | PoolsGetSuccessAction
  | PoolsGetErrorAction
  | PoolCreateAction
  | PoolCreateSuccessAction
  | PoolCreateErrorAction
  | PoolGetByIdAction
  | PoolGetByIdSuccessAction
  | PoolGetByIdErrorAction
  | PoolUpdateAction
  | PoolUpdateSuccessAction
  | PoolUpdateErrorAction
  | UnapprovedClientRequestsGetAction
  | UnapprovedClientRequestsGetSuccessAction
  | ClientRequestsGetAction
  | ClientRequestsGetSuccessAction
  | ClientRequestsGetErrorAction
  | UnapprovedOffersGetAction
  | UnapprovedOffersGetSuccessAction
  | UnapprovedOffersGetErrorAction
  | OrganizationsFindAction
  | OrganizationsFindErrorAction
  | OrganizationsFindSuccessAction
  | StatsGetAction
  | StatsGetErrorAction
  | StatsGetSuccessAction
  | PoolFilesGetAction
  | PoolFilesGetErrorAction
  | PoolFilesGetSuccessAction
  | ResetPoolFilesAction
  | PoolFileUploadAction
  | PoolFileUploadErrorAction
  | PoolFileUploadSuccessAction
  | CustomDocumentGetAction
  | CustomDocumentGetErrorAction
  | CustomDocumentGetSuccessAction
  | CustomDocumentCreateAction
  | CustomDocumentCreateErrorAction
  | CustomDocumentCreateSuccessAction
  | CustomDocumentDeleteSuccessAction
  | CustomDocumentUpdateAction
  | CustomDocumentUpdateSuccessAction
  | CustomDocumentUpdateErrorAction
  | OrganizationInviteAction
  | OrganizationInviteSuccessAction
  | OrganizationInviteErrorAction
  | QuestionnaireConfigGetAction
  | QuestionnaireConfigGetSuccessAction
  | QuestionnaireConfigGetErrorAction
  | QuestionnaireGetAction
  | QuestionnaireGetSuccessAction
  | QuestionnaireGetErrorAction
  | QuestionnaireConfigCreateAction
  | QuestionnaireConfigCreateSuccessAction
  | QuestionnaireConfigCreateErrorAction
  | QuestionnaireConfigUpdateAction
  | QuestionnaireConfigUpdateSuccessAction
  | QuestionnaireConfigUpdateErrorAction
  | QuestionnaireConfigDeleteAction
  | QuestionnaireConfigDeleteSuccessAction
  | QuestionnaireConfigDeleteErrorAction
  | QuestionnaireConfigForCustomerGetAction
  | QuestionnaireConfigForCustomerGetErrorAction
  | QuestionnaireConfigForCustomerGetSuccessAction
  | LandingPageGetErrorAction
  | LandingPageGetSuccessAction
  | LandingPageGetAction
  | ServicesFeeDescriptionGetAction
  | ServicesFeeDescriptionGetErrorAction
  | ServicesFeeDescriptionGetSuccessAction
  | InviteTokenUpdateAction
  | InviteTokenUpdateSuccessAction
  | InviteTokenUpdateErrorAction
  | CustomDocumentImageUploadAction
  | CustomDocumentImageUploadSuccessAction
  | CustomDocumentImageUploadErrorAction
  | CustomDocumentImagesGetAction
  | CustomDocumentImagesGetSuccessAction
  | CustomDocumentImagesGetErrorAction
  | CustomDocumentMediaLinkGetAction
  | CustomDocumentMediaLinkGetSuccessAction
  | CustomDocumentMediaLinkGetErrorAction
  | ExistingOrganizationInviteAction
  | ExistingOrganizationInviteSuccessAction
  | ExistingOrganizationInviteErrorAction
  | OrganizationsInvitesGetAction
  | OrganizationsInvitesGetSuccessAction
  | OrganizationsInvitesGetErrorAction
  | PoolGetRequestsByIdAction
  | PoolGetRequestsByIdErrorAction
  | PoolGetRequestsByIdSuccessAction
  | OrganizationInviteDeleteAction
  | OrganizationInviteDeleteSuccessAction
  | OrganizationInviteDeleteErrorAction
  | PoolOffersGetErrorAction
  | PoolOffersGetSuccessAction
  | PoolOffersGetAction
  | PoolClientRequestsGetErrorAction
  | PoolClientRequestsGetSuccessAction
  | PoolClientRequestsGetAction
  | PoolInviteTokensGetErrorAction
  | PoolInviteTokensGetSuccessAction
  | PoolInviteTokensGetAction
  | SendEmailReminderAction
  | SendEmailReminderErrorAction
  | orderInstallerDirectlySuccessAction
  | orderInstallerDirectlyErrorAction
  | CurrenciesByOrganizationGetAction
  | CurrenciesByOrganizationGetSuccessAction
  | CurrenciesByOrganizationGetErrorAction
  | PoolLegalDocumentsGetAction
  | PoolLegalDocumentsGetSuccessAction
  | PoolLegalDocumentsGetErrorAction
  | PoolLegalDocumentsSaveAction
  | PoolLegalDocumentsSaveSuccessAction
  | PoolLegalDocumentsSaveErrorAction
  | GetInstallationWizardCustomImagesUrlsAction
  | GetInstallationWizardCustomImagesUrlsSuccessAction
  | GetInstallationWizardCustomImagesUrlsErrorAction
  | ResetInstallationWizardCustomImagesUrlsAction
  | GetPoolTagsAction
  | GetPoolTagsSuccessAction
  | GetPoolTagsErrorAction
  | CreatePoolTagAction
  | CreatePoolTagSuccessAction
  | CreatePoolTagErrorAction
  | EditPoolTagAction
  | EditPoolTagSuccessAction
  | EditPoolTagErrorAction
  | DeletePoolTagAction
  | DeletePoolTagSuccessAction
  | DeletePoolTagErrorAction;
