import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Form, message, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { useForm } from 'antd/es/form/Form';
import CostCalculatorSetting from 'pages/MyProjects/CreatePool/CostCalculatorSetting';

import GeneralSettings from 'pages/MyProjects/CreatePool/GeneralSettings/GeneralSettings';
import DocumentationSetting from 'pages/MyProjects/CreatePool/DocumentationSetting';
import QuickCheckSetting from 'pages/MyProjects/CreatePool/QuickCheckSetting';
import ProductBundlesSetting from 'pages/MyProjects/EditPool/PoolSettingsTab/Tabs/Bundle/Bundle';

import {
  CustomDocumentsCategoryType,
  ICustomDocument,
  IPool,
} from 'store/intermediate/intermediate.types';
import {
  customDocumentCreate,
  customDocumentDelete,
  customDocumentUpdate,
  poolUpdate,
} from 'store/intermediate/intermediate.actions';
import {
  customDocumentSelector,
  poolUpdateErrorSelector,
  poolUpdateSuccessSelector,
} from 'store/intermediate/intermediate.selectors';
import { countriesListSelector } from 'store/country/country.selectors';
import { PoolType, RequestValidationOptionsType } from 'types/pool-settings.types';
import { formatPoolPayload } from 'utils/poolUtils';
import { ProductWizard } from './Tabs/Product Wizard/ProductWizard';
import { PDFTemplate } from './Tabs/PDFTemplate/PDFTemplate';
import ServicesFeeSettings from '../../CreatePool/ServicesFeeSettings';
import { SupportedLanguages } from '../../../../framework/constants';

const { TabPane } = Tabs;

export interface IEditPoolProps {
  pool: IPool;
}

const PoolSettingsTab: React.FC<IEditPoolProps> = ({ pool }) => {
  const poolUpdateSuccess = useSelector(poolUpdateSuccessSelector);
  const poolUpdateError = useSelector(poolUpdateErrorSelector);
  const countries = useSelector(countriesListSelector);
  const [activeTab, setActiveTab] = useState('generalSettings');
  const dispatch = useDispatch();
  const { t } = useTranslation('errors');
  const [form] = useForm();

  const isSurvey = useMemo(() => pool?.poolType === PoolType.SurveyPool, [pool]);

  React.useEffect(() => {
    if (poolUpdateSuccess) {
      message.success(t('intermediate:myProjects:editProjectPools:poolSaved'));
    } else if (poolUpdateError) {
      message.error(poolUpdateError);
    }
  }, [poolUpdateError, poolUpdateSuccess, t]);

  useEffect(() => {
    form.setFieldsValue({
      ...pool,
      requestValidationSelectionMode: pool?.requestValidity
        ? RequestValidationOptionsType.Manual
        : RequestValidationOptionsType.Default,
      country: pool?.country?.iso31661Alpha3,
    });
  }, [form, pool]);

  const isSurveyDisabled = !!(
    !isSurvey &&
    pool?.clientRequestsCount &&
    pool.clientRequestsCount > 0
  );
  const customDocuments = useSelector(customDocumentSelector) as ICustomDocument[];
  const upsertServicesFeeDescriptions = (
    servicesFeeLocalizedDescriptions: { value: string; lang: SupportedLanguages }[],
  ) => {
    servicesFeeLocalizedDescriptions.forEach(
      (_servicesFeeLocalizedDescription: { value: string; lang: SupportedLanguages }) => {
        const existingDocument = customDocuments.find(
          (_document) =>
            _document.lang === _servicesFeeLocalizedDescription.lang &&
            _document.category === CustomDocumentsCategoryType.PoolServiceFreeDescription,
        );
        if (existingDocument) {
          dispatch(
            customDocumentUpdate(pool.id, existingDocument.id, {
              ...existingDocument,
              text: _servicesFeeLocalizedDescription.value,
              lang: _servicesFeeLocalizedDescription.lang,
            }),
          );
        } else {
          dispatch(
            customDocumentCreate(pool.id, {
              category: CustomDocumentsCategoryType.PoolServiceFreeDescription,
              text: _servicesFeeLocalizedDescription.value,
              lang: _servicesFeeLocalizedDescription.lang,
              media: [],
            }),
          );
        }
      },
    );
  };
  const deleteServicesFeeDescriptions = (
    servicesFeeLocalizedDescriptions?: { value: string; lang: SupportedLanguages }[],
  ) => {
    const nonExistingDescriptionsIds = customDocuments
      .filter(
        (_customDocument) =>
          _customDocument.category === CustomDocumentsCategoryType.PoolServiceFreeDescription &&
          servicesFeeLocalizedDescriptions?.every(
            (_servicesFeeDescription) => _servicesFeeDescription.lang !== _customDocument.lang,
          ),
      )
      .map((_customDocument) => _customDocument.id);
    nonExistingDescriptionsIds.forEach((nonExistingDescriptionsId) => {
      dispatch(customDocumentDelete(nonExistingDescriptionsId));
    });
  };
  const onSaveClick = async () => {
    try {
      const values = await form.validateFields();
      const { servicesFeeLocalizedDescriptions, ...poolSettingsValues } = values;
      // Update and create description fields.
      if (servicesFeeLocalizedDescriptions) {
        upsertServicesFeeDescriptions(servicesFeeLocalizedDescriptions);
      }
      // Delete not existing documents.
      if (poolSettingsValues?.isServiceFeeEnabled) {
        deleteServicesFeeDescriptions(servicesFeeLocalizedDescriptions);
      }
      dispatch(poolUpdate(pool.id, formatPoolPayload(poolSettingsValues, countries)));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Form form={form} layout="vertical">
        <Tabs activeKey={activeTab} onTabClick={setActiveTab}>
          {!isSurvey &&
            [
              {
                key: 'generalSettings',
                component: (
                  <GeneralSettings
                    pool={pool}
                    isSurveyDisabled={isSurveyDisabled}
                    isSurvey={isSurvey}
                    isParent={pool.isParent}
                    form={form}
                  />
                ),
              },
              { key: 'quickCheck', component: <QuickCheckSetting /> },
              { key: 'costCalculator', component: <CostCalculatorSetting /> },
              { key: 'servicesFee', component: <ServicesFeeSettings form={form} pool={pool} /> },
              { key: 'documentation', component: <DocumentationSetting /> },
              { key: 'productBundles', component: <ProductBundlesSetting pool={pool} /> },
              { key: 'pdfTemplates', component: <PDFTemplate pool={pool} /> },
              { key: 'productWizard', component: <ProductWizard pool={pool} /> },
            ].map((tab) => (
              <TabPane
                key={tab.key}
                tab={t(`intermediate:myProjects:poolSettings:tabs:${tab.key}`)}
              >
                {tab.component}
              </TabPane>
            ))}
        </Tabs>
      </Form>

      {!(activeTab === '4' || activeTab === '5' || activeTab === '6') && (
        <Flex justify="flex-end" align="flex-end">
          <Button type="primary" onClick={onSaveClick} size="small">
            {t('common:buttons:saveChanges')}
          </Button>
        </Flex>
      )}
    </>
  );
};

export default PoolSettingsTab;
