import React from 'react';
import clsx from 'clsx';
import { default as ANTTag } from 'antd/lib/tag';

import styles from './Tag.module.sass';

interface ITagProps {
  children: any;
  className?: string;
}

const Tag: React.FC<ITagProps> = ({ children, className, ...rest }) => {
  const tagClassName = clsx(styles.tag, className);

  return (
    <ANTTag {...rest} className={tagClassName}>
      {children}
    </ANTTag>
  );
};

export default Tag;
