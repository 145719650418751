import { JSX } from 'react';
import { FormInstance, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/lib/table';

export interface ICrudProps {
  locizeKey: string;
}

export enum FormLayout {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export interface ICrudModalProps<T> extends ICrudProps {
  onSubmit: (
    action: EntityAction,
    data: T,
    onRequestDone?: (status: RequestResult) => void,
  ) => void;
  formBody: JSX.Element;
  dataToUpdate: T | null;
  open: boolean;
  onClose: () => void;
  action?: EntityAction;
  setForm?: (value: FormInstance) => void;
  onFormValuesChange?: (_field: Partial<T>, values: T) => void;
  width?: number | string;
  layout?: FormLayout;
  requiredMark?: boolean;
}

export interface ICrudViewProps<T> extends ICrudProps {
  columns: ColumnsType<T>;
  entries: T[];
  onTableSort?: (sortField: string, sortDirection: 1 | -1) => void;
  backButton?: boolean;
  onBackClick?: () => void;
  paginationProps?: TablePaginationConfig;
  isMaxEntries?: boolean;
  maxKey?: string;
  updateEntryFormat?: (values: T) => any;
  actionDisabled?: (row: T) => boolean;
  rowsSelectable?: boolean;
  filterRow?: JSX.Element;
  rowActions?: IRowAction[];
  onRowClick?: (row: T) => void | string;
  tableLoading?: boolean;
  modalProps: Omit<
    ICrudModalProps<T>,
    'dataToUpdate' | 'open' | 'onClose' | 'action' | 'locizeKey'
  >;
  createInitialValues?: T;
}

interface IRowAction {
  label: string;
}

export enum RequestResult {
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum EntityAction {
  Create = 'create',
  Edit = 'edit',
  Delete = 'delete',
}
