import { RequestState } from '../common.types';
import { Organization } from '../intermediate/intermediate.types';

export interface IPoolTenderOrganizationState {
  organizations: Organization[];
  organizationsState: RequestState | null;
  organizationsError: string | null;

  organizationsUpdateState: RequestState | null;
  organizationsUpdateError: string | null;

  organizationsDeleteState: RequestState | null;
  organizationsDeleteError: string | null;
}

export const GET_POOL_ORGANIZATIONS = 'GET_POOL_ORGANIZATIONS';
export const GET_POOL_ORGANIZATIONS_SUCCESS = 'GET_POOL_ORGANIZATIONS_SUCCESS';
export const GET_POOL_ORGANIZATIONS_ERROR = 'GET_POOL_ORGANIZATIONS_ERROR';

export const UPDATE_POOL_ORGANIZATIONS = 'UPDATE_POOL_ORGANIZATIONS';
export const UPDATE_POOL_ORGANIZATIONS_SUCCESS = 'UPDATE_POOL_ORGANIZATIONS_SUCCESS';
export const UPDATE_POOL_ORGANIZATIONS_ERROR = 'UPDATE_POOL_ORGANIZATIONS_ERROR';

export const DELETE_POOL_ORGANIZATIONS = 'DELETE_POOL_ORGANIZATIONS';
export const DELETE_POOL_ORGANIZATIONS_SUCCESS = 'DELETE_POOL_ORGANIZATIONS_SUCCESS';
export const DELETE_POOL_ORGANIZATIONS_ERROR = 'DELETE_POOL_ORGANIZATIONS_ERROR';

// Get Pool Organizations
export interface GetPoolOrganizationsAction {
  type: typeof GET_POOL_ORGANIZATIONS;
  payload: string;
}

export interface GetPoolOrganizationsSuccessAction {
  type: typeof GET_POOL_ORGANIZATIONS_SUCCESS;
  payload: Organization[];
}

export interface GetPoolOrganizationsErrorAction {
  type: typeof GET_POOL_ORGANIZATIONS_ERROR;
  payload: any;
}

// Update Pool Organizations
export interface UpdatePoolOrganizationsAction {
  type: typeof UPDATE_POOL_ORGANIZATIONS;
  payload: {
    id: string;
    organization: Organization;
  };
}

export interface UpdatePoolOrganizationsSuccessAction {
  type: typeof UPDATE_POOL_ORGANIZATIONS_SUCCESS;
  payload: Organization;
}

export interface UpdatePoolOrganizationsErrorAction {
  type: typeof UPDATE_POOL_ORGANIZATIONS_ERROR;
  payload: any;
}

// Delete Pool Organizations
export interface DeletePoolOrganizationsAction {
  type: typeof DELETE_POOL_ORGANIZATIONS;
  payload: string;
}

export interface DeletePoolOrganizationsSuccessAction {
  type: typeof DELETE_POOL_ORGANIZATIONS_SUCCESS;
  payload: string;
}

export interface DeletePoolOrganizationsErrorAction {
  type: typeof DELETE_POOL_ORGANIZATIONS_ERROR;
  payload: any;
}

export type PoolTenderOrganizationActionTypes =
  | GetPoolOrganizationsAction
  | GetPoolOrganizationsSuccessAction
  | GetPoolOrganizationsErrorAction
  | UpdatePoolOrganizationsAction
  | UpdatePoolOrganizationsSuccessAction
  | UpdatePoolOrganizationsErrorAction
  | DeletePoolOrganizationsAction
  | DeletePoolOrganizationsSuccessAction
  | DeletePoolOrganizationsErrorAction;
