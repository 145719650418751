import React, { FC } from 'react';
import { Checkbox as AntCheckbox } from 'antd';
import clsx from 'clsx';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import styles from './Checkbox.module.sass';

interface Props {
  className?: string;
  checked: boolean;
  onChange: (e: CheckboxChangeEvent) => void;
  disabled?: boolean;
  children?: React.ReactNode;
}

const Checkbox: FC<Props> = ({ children, className, checked, onChange, disabled, ...rest }) => {
  const checkboxProps = {
    checked,
    disabled,
    className: clsx(styles.checkbox, className),
    onChange,
    ...rest,
  };

  return (
    <div>
      <AntCheckbox {...checkboxProps}>{children}</AntCheckbox>
    </div>
  );
};

export default Checkbox;
