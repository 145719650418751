import React from 'react';
import { useTranslation } from 'react-i18next';

import IconBilling from 'assets/icons/location-billing.svg';
import IconLocation from 'assets/icons/location-location.svg';
import IconUser from 'assets/icons/location-user.svg';
import Icon from 'components/ui/Icon/Icon';
import Rectangle from 'components/layout/Rectangle/Rectangle';
import { getDistance } from 'framework/dataTransformations';
import { NOT_APPLICABLE_ABBR } from 'framework/constants';
import {
  IAddress,
  IClientRequest,
  QuestionCalculatedKey,
  TypeDistance,
} from 'store/client-request/client-request.types';

import styles from './TenderDetailsPage.module.sass';

interface ILocationWidget {
  clientRequest: IClientRequest;
  distance: TypeDistance[] | null;
  fullInfo?: boolean;
}

const LocationWidget: React.FC<ILocationWidget> = ({ clientRequest, distance, fullInfo }) => {
  const { t } = useTranslation();
  const currentDistance = getDistance(distance);
  const label = t(`installerFlow:tenderDetails:${QuestionCalculatedKey.Distance}`);

  const getFullAddress = (address?: IAddress) =>
    address
      ? `${address?.street} , ${address?.houseNumber} , ${address.postalCode} , ${address.city}`
      : NOT_APPLICABLE_ABBR;

  const renderAddress = (clientRequest: IClientRequest) => {
    if (!clientRequest.address) return <div>n/a</div>;

    return (
      <h5 className={styles.titleSmallRange}>
        {clientRequest.address.postalCode} {clientRequest.address.city}
      </h5>
    );
  };

  return (
    <Rectangle title={t('installerFlow:tenderDetails:installationLocation')}>
      <div className={!fullInfo ? styles.centerBlock : ''}>
        {!fullInfo ? (
          <>
            {renderAddress(clientRequest)}
            <p className={styles.label}>{label}</p>
            <p>{`${currentDistance} km`}</p>
          </>
        ) : (
          <>
            <div className={styles.location}>
              <div>
                {clientRequest.address?.postalCode} {clientRequest.address?.city}
              </div>
              <div>
                <span className={styles.label}>{`${label} `}</span>
                <span>{`${currentDistance} km`}</span>
              </div>
            </div>
            <div className={styles.additionalInfo}>
              <ul className={styles.additionalInfoList}>
                <li>
                  <div className={styles.additionalInfoEmail}>
                    <span className={styles.icon}>
                      <Icon icon={IconUser} />
                    </span>
                    <div className="information">
                      <span className={styles.info}>
                        {`${clientRequest.createdBy?.firstName} ${clientRequest.createdBy?.lastName}`}
                      </span>
                      <span className={styles.info}>{clientRequest.createdBy?.phone!}</span>
                      <div className={styles.info}>{clientRequest.createdBy?.email!}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className={styles.additionalTitle}>
                    {t('installerFlow:tenderDetails:shippingAddress')}
                  </div>
                  <span className={styles.icon}>
                    <Icon icon={IconLocation} />
                  </span>
                  <span className={styles.info}>{getFullAddress(clientRequest.address)}</span>
                </li>
                <li>
                  <div className={styles.additionalTitle}>
                    {t('installerFlow:tenderDetails:companyName')}
                  </div>
                  <span className={styles.icon}>
                    <Icon icon={IconLocation} />
                  </span>
                  <span className={styles.info}>
                    {clientRequest.address?.companyName
                      ? clientRequest.address.companyName
                      : NOT_APPLICABLE_ABBR}
                  </span>
                </li>
                <li>
                  <div className={styles.additionalTitle}>
                    {t('installerFlow:tenderDetails:billingAddress')}
                  </div>
                  <span className={styles.icon}>
                    <Icon icon={IconBilling} />
                  </span>
                  <span className={styles.info}>
                    {getFullAddress(clientRequest.billingAddress)}
                  </span>
                </li>
                <li>
                  <div className={styles.additionalTitle}>
                    {t('installerFlow:tenderDetails:companyName')}
                  </div>
                  <span className={styles.icon}>
                    <Icon icon={IconBilling} />
                  </span>
                  <span className={styles.info}>
                    {clientRequest.billingAddress?.companyName
                      ? clientRequest.billingAddress.companyName
                      : NOT_APPLICABLE_ABBR}
                  </span>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </Rectangle>
  );
};

export default LocationWidget;
