import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Table from 'components/ui/Table/Table';
import { componentColumns } from 'pages/MyProjects/EditPool/PoolSettingsTab/Tabs/Bundle/constants';
import { IBundle } from 'store/bundle/bundle.types';
import { isIntermediateManagerSelector } from 'store/auth/auth.selectors';
import { formatFormData } from 'utils/bundle';

import styles from './BundleView.module.sass';

interface IBundleViewProps {
  bundle?: IBundle;
}

export const BundleView: React.FC<IBundleViewProps> = (props) => {
  const { bundle } = props;
  const bundleData = bundle ? formatFormData(bundle) : null;
  const { t } = useTranslation();

  const isIntermediateManager = useSelector(isIntermediateManagerSelector);

  const componentsCols = componentColumns.map((col) => {
    return {
      title: t(`intermediate:myProjects:bundle.${col.heading}`),
      dataIndex: col.name,
      key: col.name,
    };
  });

  const filteredCols = isIntermediateManager
    ? componentsCols
    : componentsCols.filter(
        (item) => item.dataIndex !== 'price' && item.dataIndex !== 'totalPrice',
      );
  return (
    <>
      <Typography.Title level={5}>
        {t('installerFlow:tenderDetails:productBundle')}
      </Typography.Title>
      <div className={styles.section}>
        <Typography.Text type="secondary">
          {t('intermediate:myProjects:bundle:name')}
        </Typography.Text>
        <Typography.Text strong>{bundleData?.name}</Typography.Text>
      </div>
      <div className={styles.section}>
        {bundleData?.components?.map((item, index) => (
          <React.Fragment key={`${item.name}-${index}`}>
            <Typography.Text type="secondary">
              {t('intermediate:myProjects:bundle.componentCategoryName')}
            </Typography.Text>
            <Typography.Text>{item.name}</Typography.Text>
            <Table rowKey="description" columns={filteredCols} dataSource={item.component} />
          </React.Fragment>
        ))}
      </div>
    </>
  );
};
