import {
  GET_DEFAULT_SETTINGS,
  GET_DEFAULT_SETTINGS_ERROR,
  GET_DEFAULT_SETTINGS_SUCCESS,
  IOrganizationDefaultSettings,
  SAVE_DEFAULT_SETTINGS,
  SAVE_DEFAULT_SETTINGS_ERROR,
  SAVE_DEFAULT_SETTINGS_SUCCESS,
} from './organization.types';

export const getDefaultSettings = (payload?: any) => ({
  type: GET_DEFAULT_SETTINGS,
  payload: payload,
});

export const getDefaultSettingsSuccess = (payload: IOrganizationDefaultSettings) => ({
  type: GET_DEFAULT_SETTINGS_SUCCESS,
  payload: payload,
});

export const getDefaultSettingsError = (error: any) => ({
  type: GET_DEFAULT_SETTINGS_ERROR,
  payload: error,
});

export const saveDefaultSettings = (payload: IOrganizationDefaultSettings) => ({
  type: SAVE_DEFAULT_SETTINGS,
  payload: payload,
});

export const saveDefaultSettingsSuccess = (payload: IOrganizationDefaultSettings) => ({
  type: SAVE_DEFAULT_SETTINGS_SUCCESS,
  payload: payload,
});

export const saveDefaultSettingsError = (error: any) => ({
  type: SAVE_DEFAULT_SETTINGS_ERROR,
  payload: error,
});
