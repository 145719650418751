import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Locize from 'i18next-locize-backend';

import { LANGUAGE_KEY, SupportedLanguages } from 'framework/constants';

/* eslint-disable no-undef */

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
  apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
  version: process.env.REACT_APP_LOCIZE_VERSION,
  referenceLng: SupportedLanguages.DE,
  allowedHosts: ['localhost'],
  allowedAddOrUpdateHosts: ['localhost'],
};
// eslint-disable-next-line no-undef
i18n
  .use(Locize)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: SupportedLanguages.DE,
    defaultNS: 'common',
    ns: ['installerFlow', 'customerFlow', 'stepper', 'intermediate', 'profile', 'organization'],
    interpolation: {
      escapeValue: false,
    },
    load: 'currentOnly',
    detection: {
      order: ['localStorage'],
      caches: ['localStorage'],
      lookupLocalStorage: LANGUAGE_KEY,
    },
    backend: locizeOptions,
  });

export default i18n;
