import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import { ClientRequestState } from 'store/client-request/client-request.types';
import { currentOfferSelector } from 'store/offer/offer.selectors';
import { IOffer, homecheckStates, installationStates } from 'store/offer/offer.types';
import { IProduct } from 'store/product/product.types';
import { IHardwareProduct, IRequestDashboardConfig } from 'types/customer-flow.types';
import {
  acceptedHomeCheckOfferStates,
  acceptedInstallationOfferStates,
} from 'utils/dashboardUtils';
import { checkIsHomeCheck, checkIsInstallation } from 'utils/offerHelpers';

// [TODO]: Refactor this file with calculations.
export const useDashboard = () => {
  const request = useSelector(currentClientRequestSelector);
  const offer = useSelector(currentOfferSelector);
  const [config, setConfig] = useState<IRequestDashboardConfig>({
    step: 0,
  });

  const isHomeCheck = useMemo(
    () =>
      request?.state === ClientRequestState.HomeCheck ||
      request?.state === ClientRequestState.OfferSelectionHomeCheck,
    [request],
  );

  const isOfferAccepted = useMemo(
    () =>
      [ClientRequestState.HomeCheck, ClientRequestState.InstallationPending].includes(
        request?.state!,
      ),
    [request],
  );

  const states = useMemo(() => (isHomeCheck ? homecheckStates : installationStates), [isHomeCheck]);

  useEffect(() => {
    if (!request) return;

    const installerUser = isOfferAccepted
      ? request?.offers?.find((offer) => states.includes(offer?.state!))?.installerUser
      : undefined;

    const { products } = request;
    const prices = fetchPrices(products);
    const offers = request?.offers?.filter((item) => {
      if (isHomeCheck) {
        return checkIsHomeCheck(item.state!);
      }
      return checkIsInstallation(item.state!);
    });

    setConfig({
      installerUser,
      request,
      prices,
      offers,
      contactPerson: request.pool?.contactPersons?.find((person) => person.active),
      step: request.step ? request.step - 1 : 0,
      requestState: request.state,
      offer,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, offer]);

  const processProducts = (products: IProduct[]) => {
    let gross = 0;
    let net = 0;
    const vat: Record<number, number> = {};
    const items: IHardwareProduct[] = [];
    const tax = request?.pool?.country.vatRate;
    products.forEach((product) => {
      const { netPrice, grossPrice: totalPrice } = product;
      if (totalPrice && netPrice && tax) {
        const numberTax = +tax;
        const numberTotalPrice = +totalPrice;
        const numberNetPrice = +netPrice;
        const taxPrice = numberTotalPrice - numberNetPrice;

        gross += numberTotalPrice;
        net += numberNetPrice;
        vat[numberTax] = (vat[numberTax] || 0) + taxPrice;

        items.push({
          title: product.title || 'Default Title',
          mediaUrl: product.mediaUrl || 'Default Media URL',
          totalPrice: numberTotalPrice || 0,
          netPrice: numberNetPrice || 0,
          id: product.id || '1',
        });
      }
    });
    return { vat, net, items, gross };
  };

  const calculateTaxOfNetPrice = (netPrice: number, taxRate: number): number => {
    return netPrice * (taxRate / 100);
  };

  const fetchPrices = (products?: IProduct[]) => {
    let net = 0;
    const vat: Record<number, number> = {};
    // Process hardware products if they exist
    let hardwareGross = 0;
    let hardwareNet = 0;
    const hardwareItems: IHardwareProduct[] = [];
    if (products?.length) {
      const { gross, net: productNet, vat: productVat, items } = processProducts(products);

      hardwareGross += gross;
      hardwareNet += productNet;
      hardwareItems.push(...items);

      // Merge product VAT into the vat object
      for (const key in productVat) {
        vat[key] = (vat[key] ?? 0) + +productVat[key].toFixed(2);
      }
    }
    let gross = 0;

    const offers = request?.offers?.filter((offer) =>
      [...acceptedHomeCheckOfferStates, ...acceptedInstallationOfferStates].includes(offer?.state!),
    );

    let installationGross = 0;
    let homecheckGross = 0;
    let installationNet = 0;
    let homecheckNet = 0;
    offers?.forEach((offer: IOffer) => {
      const { vatRate, invoiceAmount, invoiceHomeCheck, state } = offer;
      const price = invoiceAmount || invoiceHomeCheck;
      if (vatRate && price) {
        const vatPrice = calculateTaxOfNetPrice(price, vatRate);
        vat[vatRate] = (vat[vatRate] ?? 0) + vatPrice;
        net += price;
        const grossOfferPrice = price + vatPrice;
        gross += grossOfferPrice;
        if (checkIsHomeCheck(state!)) {
          homecheckNet += price;
          homecheckGross += grossOfferPrice;
        }
        if (checkIsInstallation(state!)) {
          installationNet += price;
          installationGross += grossOfferPrice;
        }
      }
    });

    const currencySymbol = request?.pool?.country?.currencySymbol || '€';
    return {
      gross,
      vat,
      net,
      currencySymbol,
      hardware: {
        gross: hardwareGross,
        net: hardwareNet,
        products: hardwareItems,
      },
      installation: {
        gross: installationGross,
        net: installationNet,
      },
      homeCheck: {
        gross: homecheckGross,
        net: homecheckNet,
      },
    };
  };
  return { config };
};
