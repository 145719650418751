import React from 'react';
import { Form, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import UploadDocumentQuestion from 'components/questions/UploadDocumentQuestion/UploadDocumentQuestion';
import { ValidationFactory } from 'framework/validations';
import { MediaCategoryType } from 'store/client-request/client-request.types';
import styles from './UploadDocumentsStep.module.sass';

interface IUploadDocumentBody {
  appartments?: boolean;
  token?: string;
  requestId?: string;
  fotoWizard?: boolean;
}

const UploadDocumentBody: React.FC<IUploadDocumentBody> = ({ appartments, ...rest }) => {
  const { t } = useTranslation();
  const acceptTypes = {
    'application/pdf': ['.pdf'],
  };
  return (
    <>
      <Typography.Title level={4} className={styles.uploadTitle}>
        {t('customerFlow:clientRequest:uploadStep:mandatory:title')}
      </Typography.Title>
      <Form.Item name={MediaCategoryType.RequestFuseBox} rules={[ValidationFactory.REQUIRED]}>
        <UploadDocumentQuestion
          title={t('customerFlow:clientRequest:uploadConnection:title')}
          description={t('customerFlow:clientRequest:uploadConnection:description')}
          category={MediaCategoryType.RequestFuseBox}
          {...rest}
        />
      </Form.Item>
      <Typography.Title level={4} className={styles.uploadTitle}>
        {t('customerFlow:clientRequest:uploadStep:optional:title')}
      </Typography.Title>
      <Form.Item name={MediaCategoryType.RequestPhotos}>
        <UploadDocumentQuestion
          title={t('customerFlow:clientRequest:uploadPhotos:title')}
          description={t('customerFlow:clientRequest:uploadPhotos:description')}
          category={MediaCategoryType.RequestPhotos}
          {...rest}
        />
      </Form.Item>
      {appartments && (
        <Form.Item name={MediaCategoryType.RequestPermitAgreement}>
          <UploadDocumentQuestion
            acceptedFiles="PDF "
            accept={acceptTypes}
            title={t('customerFlow:clientRequest:landlordPermission:title')}
            description={t('customerFlow:clientRequest:landlordPermission:description')}
            category={MediaCategoryType.RequestPermitAgreement}
            {...rest}
          />
        </Form.Item>
      )}
    </>
  );
};

export default UploadDocumentBody;
