import { produce } from 'immer';
import { RequestState } from 'store/common.types';
import {
  IProductState,
  MODELS_GET,
  MODELS_GET_ERROR,
  MODELS_GET_SUCCESS,
  MODEL_CREATE,
  MODEL_CREATE_ERROR,
  MODEL_CREATE_SUCCESS,
  ProductActionTypes,
  PRODUCTS_GET,
  PRODUCTS_GET_ERROR,
  PRODUCTS_GET_SUCCESS,
  PRODUCT_CREATE,
  PRODUCT_CREATE_ERROR,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_DELETE,
  PRODUCT_DELETE_ERROR,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_UPDATE,
  PRODUCT_UPDATE_ERROR,
  PRODUCT_UPDATE_SUCCESS,
  VENDORS_GET,
  VENDORS_GET_ERROR,
  VENDORS_GET_SUCCESS,
  VENDOR_CREATE,
  VENDOR_CREATE_ERROR,
  VENDOR_CREATE_SUCCESS,
} from './product.types';

const initialState: IProductState = {
  products: [],
  productsGetState: null,
  productsGetError: null,

  productUpdateState: null,
  productUpdateError: null,

  productDeleteState: null,
  productDeleteError: null,

  productCreateState: null,
  productCreateError: null,

  vendorCreateState: null,
  vendorCreateError: null,

  modelCreateState: null,
  modelCreateError: null,

  vendors: [],
  vendorsGetState: null,
  vendorsGetError: null,

  models: [],
  modelsGetState: null,
  modelsGetError: null,
};

const ProductReducer = (state = initialState, action: ProductActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case PRODUCTS_GET:
        draft.productsGetState = RequestState.Loading;
        break;
      case PRODUCTS_GET_SUCCESS:
        draft.productsGetState = RequestState.Success;
        draft.productsGetError = null;
        draft.products = action.payload;
        break;
      case PRODUCTS_GET_ERROR:
        draft.productsGetState = RequestState.Error;
        draft.productsGetError = action.payload;
        break;

      case PRODUCT_CREATE:
        draft.productCreateState = RequestState.Loading;
        break;
      case PRODUCT_CREATE_SUCCESS:
        draft.productCreateState = RequestState.Success;
        draft.productCreateError = null;
        draft.products = [...draft.products, action.payload];
        break;
      case PRODUCT_CREATE_ERROR:
        draft.productCreateState = RequestState.Error;
        draft.productCreateError = action.payload;
        break;
      case PRODUCT_UPDATE:
        draft.productUpdateState = RequestState.Loading;
        break;
      case PRODUCT_UPDATE_SUCCESS:
        draft.productUpdateState = RequestState.Success;
        draft.productUpdateError = null;
        draft.products = state.products.map((product) =>
          product.id === action.payload.id ? action.payload : product,
        );
        break;
      case PRODUCT_UPDATE_ERROR:
        draft.productUpdateState = RequestState.Error;
        draft.productUpdateError = action.payload;
        break;
      case PRODUCT_DELETE:
        draft.productDeleteState = RequestState.Loading;
        break;
      case PRODUCT_DELETE_SUCCESS:
        draft.productDeleteState = RequestState.Success;
        draft.productDeleteError = null;
        draft.products = state.products.filter((product) => product.id !== action.payload);
        break;
      case PRODUCT_DELETE_ERROR:
        draft.productDeleteState = RequestState.Error;
        draft.productDeleteError = action.payload;
        break;
      case VENDOR_CREATE:
        draft.vendorCreateState = RequestState.Loading;
        break;
      case VENDOR_CREATE_SUCCESS:
        draft.vendorCreateState = RequestState.Success;
        draft.vendorCreateError = null;
        draft.vendors = [...draft.vendors, action.payload];
        break;
      case VENDOR_CREATE_ERROR:
        draft.vendorCreateState = RequestState.Error;
        draft.vendorCreateError = action.payload;
        break;
      case MODEL_CREATE:
        draft.modelCreateState = RequestState.Loading;
        break;
      case MODEL_CREATE_SUCCESS:
        draft.modelCreateState = RequestState.Success;
        draft.modelCreateError = null;
        draft.models = [...draft.models, action.payload];
        break;
      case MODEL_CREATE_ERROR:
        draft.modelCreateState = RequestState.Error;
        draft.modelCreateError = action.payload;
        break;
      case VENDORS_GET:
        draft.vendorsGetState = RequestState.Loading;
        break;
      case VENDORS_GET_SUCCESS:
        draft.vendorsGetState = RequestState.Success;
        draft.vendorsGetError = null;
        draft.vendors = action.payload;
        break;
      case VENDORS_GET_ERROR:
        draft.vendorsGetState = RequestState.Error;
        draft.vendorsGetError = action.payload;
        break;
      case MODELS_GET:
        draft.modelsGetState = RequestState.Loading;
        break;
      case MODELS_GET_SUCCESS:
        draft.modelsGetState = RequestState.Success;
        draft.modelsGetError = null;
        draft.models = action.payload;
        break;
      case MODELS_GET_ERROR:
        draft.modelsGetState = RequestState.Error;
        draft.modelsGetError = action.payload;
        break;
    }
  });

export default ProductReducer;
