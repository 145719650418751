import React, { useContext, useMemo } from 'react';
import { Button, QRCode, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import DefaultCoverImage from 'assets/images/dashboard/DefaultCoverImage.png';
import { ReactComponent as DoneIcon } from 'assets/icons/check-white.svg';
import { CustomerDashboardContext } from 'context/customerDashboard.context';
import styles from './NextStep.module.sass';

type NextStepProps = {
  onContinue: () => void;
  isDisabled: boolean;
  isDone?: boolean;
};

const NextStep: React.FC<NextStepProps> = (props) => {
  const { requestState, offer } = useContext(CustomerDashboardContext);
  const { t } = useTranslation();

  const currentState = useMemo(
    () => (offer?.state ? offer.state : requestState),
    [requestState, offer],
  );

  const coverImage = useMemo(() => {
    switch (currentState) {
      default:
        return DefaultCoverImage;
    }
  }, [currentState]);

  const videoCallLink = `${window.location.origin}/home-check/customer/${offer?.meetingId}`;

  return (
    <div className={styles.container}>
      <div className={styles.imageSection}>
        <div className={styles.coverImage}>
          <img src={coverImage} alt="coverImage" />
        </div>
        {offer?.remoteHomeCheck && (
          <div className={styles.qr}>
            <QRCode value={videoCallLink} size={100} />
          </div>
        )}
      </div>
      <Typography.Title level={4}>
        {t(`customerFlow:dashboard:nextStep:${currentState}:title`)}
      </Typography.Title>
      <Typography.Paragraph className={styles.description}>
        {t(`customerFlow:dashboard:nextStep:${currentState}:description`)}
      </Typography.Paragraph>
      <Button
        type="primary"
        className={clsx(styles.continueButton, {
          [styles.done]: props.isDone,
        })}
        block
        onClick={props.onContinue}
        disabled={!props.isDone && props.isDisabled}
      >
        {props.isDone ? <DoneIcon /> : t('common:buttons:continue')}
      </Button>
    </div>
  );
};

export default NextStep;
