import {
  FEEDBACK_CREATE,
  FEEDBACK_CREATE_ERROR,
  FEEDBACK_CREATE_SUCCESS,
  FEEDBACK_GET,
  FEEDBACK_GET_ERROR,
  FEEDBACK_GET_SUCCESS,
  IFeedback,
} from './feedback.types';

// Get feedback
export const feedbackGet = (referenceId: string, referenceType: string) => ({
  type: FEEDBACK_GET,
  payload: {
    referenceId,
    referenceType,
  },
});

export const feedbackGetSuccess = (payload: IFeedback[]) => ({
  type: FEEDBACK_GET_SUCCESS,
  payload: payload,
});

export const feedbackGetError = (error: any) => ({
  type: FEEDBACK_GET_ERROR,
  payload: error,
});

// Create feedback
export const feedbackCreate = (payload: IFeedback[]) => ({
  type: FEEDBACK_CREATE,
  payload: payload,
});

export const feedbackCreateSuccess = (payload: IFeedback[]) => ({
  type: FEEDBACK_CREATE_SUCCESS,
  payload: payload,
});

export const feedbackCreateError = (error: any) => ({
  type: FEEDBACK_CREATE_ERROR,
  payload: error,
});
