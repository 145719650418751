import React, { useMemo } from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Form, Tooltip, Typography } from 'antd';

import { InfoCircleTwoTone } from '@ant-design/icons';
import { Rule } from 'antd/es/form';
import { ValidationFactory } from 'framework/validations';
import { customerQuestionnaireConfigsSelector } from 'store/intermediate/intermediate.selectors';
import { IQuestionnaireConfig } from 'store/intermediate/intermediate.types';

import MultipleInputControl from './MultipleInputControl';
import styles from './MultipleInputContainer.module.sass';

type MultipleInputType = {
  [field: string]: string;
};

type InputOption = {
  title: string;
  key: string;
  coverImage: {
    url: string;
  };
};

type MultipleInputContainerProps = {
  data: MultipleInputType;
  handleChange(path: string | string[], value: any): void;
  path: string;
  visible: boolean;
  uischema: any;
  schema: any;
};
const MultipleInputContainer: React.FC<MultipleInputContainerProps> = (props) => {
  const { visible, uischema, path, data, handleChange } = props;
  const { options: uiOptions, coverImage } = uischema;

  const { multipleInputFields, question, description } = uiOptions;
  const { checkVisibility, booleanOption, inputs } = multipleInputFields;

  const { t } = useTranslation();
  const customerQuestionnaireConfig = useSelector(customerQuestionnaireConfigsSelector);
  const questionConfig = customerQuestionnaireConfig?.find(
    (c: IQuestionnaireConfig) => c.questionKey === props.path,
  );

  const validationRules: Rule[] = [ValidationFactory.REQUIRED];
  if (booleanOption) validationRules.push(ValidationFactory.CAR_MODEL);

  const hideQuestion = useMemo(() => {
    if (checkVisibility) {
      return questionConfig ? questionConfig.isVisible : false;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, customerQuestionnaireConfig, checkVisibility]);

  if (!visible || hideQuestion) return null;
  return (
    <Form.Item noStyle className={styles.container}>
      <Typography.Title level={4}>
        {t(question)}
        {description && (
          <Tooltip title={t(description)} className={styles.description}>
            <InfoCircleTwoTone twoToneColor="#bfbfbf" />
          </Tooltip>
        )}
      </Typography.Title>
      {!!coverImage?.url && (
        <img alt="question cover" src={coverImage.url} className={styles.coverImage} />
      )}

      <div className={styles.optionsContainer}>
        {inputs.map((input: InputOption) => (
          <div key={input.key} className={styles.option}>
            <Form.Item
              name={[path, input.key]}
              initialValue={data?.[input.key]}
              rules={validationRules}
            >
              <MultipleInputControl
                placeholder={input.title}
                coverImage={input?.coverImage?.url}
                handleInputChange={(value: string) => handleChange([path, input.key], value)}
              />
            </Form.Item>
          </div>
        ))}
      </div>
    </Form.Item>
  );
};

export default withJsonFormsControlProps(MultipleInputContainer);
