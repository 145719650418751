import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Form } from 'antd';
import moment from 'moment';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import styles from 'components/questions/DateInputQuestion/DateInputQuestion.module.sass';
import DatePicker from 'components/ui/DatePicker/DatePicker';
import { ClientRequestFields } from 'store/client-request/client-request.types';

type InstallPeriodValueType = {
  [ClientRequestFields.SoonestPossible]: boolean;
  [ClientRequestFields.StartDate]: any;
};

interface IQuestionProps {
  value?: any;
  onChange?: Function;
}

const InstallPeriodQuestion: React.FC<IQuestionProps> = (props) => {
  const [value, setValue] = React.useState<InstallPeriodValueType>({
    [ClientRequestFields.SoonestPossible]: true,
    [ClientRequestFields.StartDate]: null,
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (props.value) {
      const startDate = props.value[ClientRequestFields.StartDate];
      setValue({
        [ClientRequestFields.SoonestPossible]: props.value[ClientRequestFields.SoonestPossible],
        [ClientRequestFields.StartDate]: startDate ? new Date(startDate) : null,
      });
    }
  }, [props.value]);

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const newValue = {
      [ClientRequestFields.StartDate]: null,
      [ClientRequestFields.SoonestPossible]: !value[ClientRequestFields.SoonestPossible],
    };

    setValue(newValue);
    props.onChange && props.onChange(newValue);
  };

  const handleDateChange = (date?: Date) => {
    const newValue = {
      [ClientRequestFields.SoonestPossible]: false,
      [ClientRequestFields.StartDate]: date,
    };

    setValue(newValue);
    props.onChange && props.onChange(newValue);
  };

  const renderCheckbox = () => {
    return (
      <div className={styles.option}>
        <Checkbox
          checked={value[ClientRequestFields.SoonestPossible]}
          onChange={handleCheckboxChange}
        >
          <div className={styles.checkboxText}>
            {t(`customerFlow:questions:${ClientRequestFields.PeriodOfTime}:asap`)}
          </div>
        </Checkbox>
      </div>
    );
  };

  const renderDatePicker = () => {
    const date = value[ClientRequestFields.StartDate];
    return (
      <div className={styles.option}>
        <span className={styles.dateDescription}>
          {t(`customerFlow:questions:${ClientRequestFields.PeriodOfTime}:orFrom`)}
        </span>
        <DatePicker
          disabledDate={(current) => !!(current && current < moment().startOf('day'))}
          placeholder={t(
            `customerFlow:questions:${ClientRequestFields.PeriodOfTime}:datePlaceholder`,
          )}
          value={date ? moment(date) : undefined}
          onChange={(day) => handleDateChange(day?.toDate())}
          inputReadOnly
        />
        <span className={styles.dateDescription}>
          {t(`customerFlow:questions:${ClientRequestFields.PeriodOfTime}:come`)}
        </span>
      </div>
    );
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.question}>
          {t(`customerFlow:questions:${ClientRequestFields.PeriodOfTime}:question`)}
        </div>
        <div className={styles.description}>
          {t(`customerFlow:questions:${ClientRequestFields.PeriodOfTime}:description`)}
        </div>

        <div className={styles.options}>
          <div className={styles.label}>
            {t(`customerFlow:questions:${ClientRequestFields.PeriodOfTime}:label`)}
          </div>

          <Form.Item
            name={ClientRequestFields.SoonestPossible}
            valuePropName={ClientRequestFields.SoonestPossible}
          >
            {renderCheckbox()}

            <div className={styles.either}>
              {t(`customerFlow:questions:${ClientRequestFields.PeriodOfTime}:either`)}
            </div>

            {renderDatePicker()}
          </Form.Item>
        </div>
      </div>
    </>
  );
};

export default InstallPeriodQuestion;
