import React, { JSX } from 'react';
import clsx from 'clsx';
import Typography from 'antd/lib/typography';

import styles from './BoxContentSection.module.sass';

interface IBoxContentSectionProps {
  title?: string;
  containerClassName?: string;
  contentClassName?: string;
  children: React.ReactNode;
}

const BoxContentSection: React.FC<IBoxContentSectionProps> = (props) => {
  const { title, containerClassName, contentClassName, children, ...rest } = props;

  const containerClass = clsx(styles.section, containerClassName);
  const contentClass = clsx(styles.content, contentClassName);

  const childrenWithProps = React.Children.map(children, (child, index) => {
    return React.cloneElement(child as JSX.Element, {
      ...rest,
    });
  });

  return (
    <div className={containerClass}>
      <Typography.Title className={styles.title}>{title}</Typography.Title>

      <div className={contentClass}>{childrenWithProps}</div>
    </div>
  );
};

export default BoxContentSection;
