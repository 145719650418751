import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Input } from 'antd';
import clsx from 'clsx';
import { DeleteOutlined, FunnelPlotOutlined, SearchOutlined } from '@ant-design/icons';
import styles from './FiltersPanel.module.sass';
interface IFiltersProps {
  query?: string;
  onSearchChange?: (newValue: string) => void;
  onClear?: () => void;
  children: React.ReactNode;
}

const FiltersPanel: React.FC<IFiltersProps> = (props) => {
  const [showFilters, setShowFilters] = useState(false);
  const { t } = useTranslation('installerFlow');

  return (
    <div className={styles.filters}>
      <div className={styles.header}>
        <Input
          value={props.query}
          prefix={<SearchOutlined />}
          onChange={(e) => props.onSearchChange?.(e.target.value)}
        />
        {props.children && (
          <Button
            type="text"
            onClick={() => setShowFilters(!showFilters)}
            className={styles.showButton}
            icon={<FunnelPlotOutlined />}
          >
            {t(`installerFlow:dashboard:filters:button:${showFilters ? 'hide' : 'show'}`)}
          </Button>
        )}
      </div>
      {props.children && (
        <div className={clsx(styles.filtersList, { [styles.hidden]: !showFilters })}>
          <div className={styles.filtersDiv}>{props.children}</div>
          <Button type="primary" onClick={props.onClear} icon={<DeleteOutlined />}>
            {t('intermediate:myProjects:filters:clearFilters')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default FiltersPanel;
