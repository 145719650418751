import { all, call, put, takeLatest } from 'redux-saga/effects';
import LeadAPI from 'services/lead.service';
import { leadCreateError, leadCreateSuccess } from './lead.actions';
import { LeadCreateAction, LEAD_CREATE } from './lead.types';

export function* createPool(action: LeadCreateAction) {
  try {
    const result: string = yield call(LeadAPI.createLead, action.payload);
    if (result) {
      yield put(leadCreateSuccess(result));
    } else {
      yield put(leadCreateError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(leadCreateError(message));
  }
}

export default function* root() {
  yield all([takeLatest(LEAD_CREATE, createPool)]);
}
