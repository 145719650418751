import React, { useMemo } from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import FloatingLabel, { LabelPositionType } from 'components/ui/FloatingLabel/FloatingLabel';
import { MathUtils } from 'utils/mathUtils';

type SingleInputControlProps = {
  value?: string;
  onChange?: (value: string) => void;
  handleInputChange?: (value: string) => void;
  placeholder?: string;
  textArea?: boolean;
};

const { TextArea } = Input;
const SingleInputControl: React.FC<SingleInputControlProps> = (props) => {
  const {
    value,
    placeholder = 'customerFlow:answers:parkingSpaceLocation:other',
    onChange,
    textArea,
    handleInputChange,
  } = props;
  const { t } = useTranslation();

  const onInputChange = (value: string) => {
    onChange?.(MathUtils.convertCommaToDecimal(value));
    handleInputChange?.(MathUtils.convertCommaToDecimal(value));
  };

  const InputComponent = useMemo(() => (textArea ? TextArea : Input), [textArea]);

  return (
    <FloatingLabel label={t(placeholder)} labelPosition={LabelPositionType.CENTER} value={value}>
      <InputComponent value={value} onChange={(e) => onInputChange(e.target.value)} />
    </FloatingLabel>
  );
};

export default SingleInputControl;
