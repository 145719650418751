import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import Loader from 'components/ui/Loader/Loader';

import DocumentsTable from 'pages/MyProjects/ClientRequestPage/components/DocumentsTable/DocumentsTable';
import { invoicesGet } from 'store/client-request/client-request.actions';
import { invoicesSelector } from 'store/client-request/client-request.selectors';

const InvoicesPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { invoices } = useSelector(invoicesSelector);

  useEffect(() => {
    dispatch(invoicesGet());
  }, []); // eslint-disable-line

  if (!invoices) {
    return <Loader />;
  }

  return (
    <>
      <Typography.Title level={3}>{t('profile:invoices')}</Typography.Title>
      <DocumentsTable documents={invoices} />
    </>
  );
};

export default InvoicesPage;
