import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MediaCategoryType } from 'store/client-request/client-request.types';
import { DocumentType } from './TenderDocumentationPage';
import SectionFileUpload from '../InvoicePage/SectionFileUpload';

import styles from '../TenderParticipatePage/TenderParticipate.module.sass';

interface IInstallationDocumentationProps {
  offerId?: string;
  handleDocumentUploaded: (status: boolean, type: DocumentType) => void;
  allowedDocuments: string[];
}

const InstallationDocumentationPage: React.FC<IInstallationDocumentationProps> = (props) => {
  const { handleDocumentUploaded, offerId, allowedDocuments } = props;

  const { t } = useTranslation();

  const isDocumentationAllowed = useMemo(
    () => allowedDocuments.includes(MediaCategoryType.InstallationFinalDocumentation),
    [allowedDocuments],
  );

  const isOfferNetworkRegistrationAllowed = useMemo(
    () => allowedDocuments.includes(MediaCategoryType.OfferNetworkRegistration),
    [allowedDocuments],
  );

  const uploaders = [
    {
      key: 'invoiceUpload',
      category: MediaCategoryType.OfferInvoiceInstallationInstaller,
      type: DocumentType.INVOICE,
      hide: false,
    },
    {
      key: 'installationReportUpload',
      category: MediaCategoryType.InstallationFinalDocumentation,
      type: DocumentType.DOCUMENTATION,
      hide: !isDocumentationAllowed,
    },
    {
      key: 'registrationFormUpload',
      category: MediaCategoryType.OfferNetworkRegistration,
      type: DocumentType.UTILITY,
      hide: !isOfferNetworkRegistrationAllowed,
    },
  ];

  return (
    <div className={styles.container}>
      {uploaders.map(
        (item) =>
          !item.hide && (
            <SectionFileUpload
              key={item.type}
              offerId={offerId}
              title={t(`installerFlow:tenderParticipate:invoices:${item.key}`)}
              fileCategory={item.category}
              setFileUploaded={(status) => handleDocumentUploaded(status, item.type)}
            />
          ),
      )}
    </div>
  );
};

export default InstallationDocumentationPage;
