import React, { useState } from 'react';
import Typography from 'antd/lib/typography';
import { default as ANTModal, ModalProps } from 'antd/lib/modal';
import Checkbox from 'antd/lib/checkbox';
import Row from 'antd/lib/row';
import { Col } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IUserObject } from 'store/auth/auth.types';
import styles from './CustomInstallationDateModal.module.sass';
import OneValueDateTimePicker from '../ui/OneValueDateTimePicker/OneValueDateTimePicker';
import Button from '../ui/Button/Button';

import 'react-day-picker/dist/style.css';

interface ICustomInstallationDateModal {
  onSubmit: (date: Date) => void;
  onCancel?: () => void;
  installerUser?: IUserObject;
}

interface IAppointment {
  appointment?: Date;
  checked?: boolean;
}

const CustomInstallationDateModal: React.FC<ICustomInstallationDateModal & ModalProps> = (
  props,
) => {
  const { footer, onSubmit, onCancel, installerUser, ...rest } = props;
  const { t } = useTranslation();

  const currentDate = new Date();

  const [time, setTime] = useState(moment(currentDate.toString()).format('HH:mm'));
  const [date, setDate] = useState(new Date());
  const [value, setValue] = useState({
    checked: false,
  } as IAppointment);

  const onSelectTime = (time: string) => {
    setTime(time);

    let [hours, minutes] = time.split(':');
    let tempDate = new Date(date);

    tempDate.setHours(+hours, +minutes);
    setDate(tempDate);

    setValue({
      appointment: tempDate,
      checked: false,
    });
  };

  const onSelectDay = (day: Date) => {
    setDate(day);
    setValue({
      appointment: day,
      checked: false,
    });
  };

  const renderCheckbox = () => {
    const text = t('customerFlow:offer:selectingDate:installerHasAgreedFor', {
      chosenDate: moment(value.appointment).format('LLLL'),
    });
    const checkboxProps = {
      style: { marginTop: '10px' },
      checked: value.checked,
      onChange: (event: any) =>
        setValue({
          ...value,
          checked: event.target.checked,
        }),
    };

    return <Checkbox {...checkboxProps}>{text}</Checkbox>;
  };

  const modalProps = {
    ...rest,
    onCancel,
    centered: true,
    closable: false,
    footer: null,
    width: 680,
  };

  const dateTimeProps = {
    date,
    time,
    onTimeClick: (time: string) => onSelectTime(time),
    onDayClick: (day: Date) => onSelectDay(day),
  };

  const buttonProps = {
    className: styles.button,
    onClick: () => value.appointment && onSubmit(moment(value.appointment).toDate()),
    disabled: !value.checked,
  };

  const rowStyles = {
    marginTop: '10px',
    width: '100%',
  };

  return (
    <ANTModal {...modalProps} wrapClassName={styles.container}>
      <Typography.Title level={4} className={styles.title}>
        {t('customerFlow:offer:selectingDate:title', {
          firstName: installerUser?.firstName,
          lastName: installerUser?.lastName,
          phone: installerUser?.installer?.mobile,
        })}
      </Typography.Title>

      <div className={styles.pickerWrapper}>
        <OneValueDateTimePicker {...dateTimeProps} />
      </div>

      {value.appointment && renderCheckbox()}

      <Row style={rowStyles} justify="space-between">
        <Col>
          <Button type="primary" className={styles.button} onClick={onCancel} danger>
            {t('common:buttons:cancel')}
          </Button>
        </Col>

        <Col>
          <Button {...buttonProps} type="primary">
            {t('common:buttons:okay')}
          </Button>
        </Col>
      </Row>
    </ANTModal>
  );
};

CustomInstallationDateModal.defaultProps = {
  onOk: () => {},
  onSubmit: () => {},
};

export default CustomInstallationDateModal;
