import React, { useCallback, useEffect, useState } from 'react';
import Typography from 'antd/lib/typography';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, notification } from 'antd';
import { userDocumentsSelector } from 'store/user/user.selectors';
import { userGetDocuments } from 'store/user/user.actions';
import { installerDataGet } from 'store/installer/installer.actions';
import { installerDataSelector } from 'store/installer/installer.selectors';
import { EntityApprovalState } from 'store/common.types';
import { userDataSelector } from 'store/auth/auth.selectors';
import { submitLicenses } from 'store/installer/installer.actions';
import styles from './UnderReviewPage.module.sass';
import { MediaCategoryType } from '../../../store/client-request/client-request.types';
import { uploadFile, deleteFile } from '../../../store/client-request/client-request.actions';
import { calculateProgress, calculateTimeLeft } from '../../../utils/fileUploadUtils';
import FilesList from '../../../components/ui/FilesList/FilesList';
import DropZone from '../../../components/ui/DropZone/DropZone';

const category = MediaCategoryType.InstallerConfirmation;
const MAX_UPLOAD_DOCS_ALLOWED = 5;

const UnderReviewPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isUploadInProgress, setUploadInProgress] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const uploadedFiles = useSelector(userDocumentsSelector);
  const installerData = useSelector(installerDataSelector);

  useEffect(() => {
    dispatch(userGetDocuments(category));
    !installerData && dispatch(installerDataGet());
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    if (installerData && installerData.state === EntityApprovalState.Rejected) {
      notification.error({
        message: t('installerFlow:dashboard:subPage:underReview:installerRejected'),
        description: `${t('installerFlow:dashboard:subPage:underReview:installerRejected')}: ${
          installerData.rejectReason
        }`,
      });
    }
  }, [installerData]); // eslint-disable-line

  useEffect(() => {
    uploadedFiles.length && setUploadInProgress(false);
  }, [uploadedFiles]);

  const userData = useSelector(userDataSelector);
  const userName = `${_get(userData, 'firstName', '')} ${_get(userData, 'lastName', '')}`;
  let timeStartTemp: any = null;

  const onFileUploadProgress = useCallback((progressEvent: any) => {
    const percentCompleted = calculateProgress(progressEvent.loaded, progressEvent.total);

    if (!timeStartTemp) {
      timeStartTemp = progressEvent.timeStamp; // eslint-disable-line
    } else {
      const timeLeft = calculateTimeLeft(
        timeStartTemp,
        progressEvent.timeStamp,
        progressEvent.loaded,
        progressEvent.total,
      );

      setTimeLeft(timeLeft);
    }

    if (percentCompleted === 100) {
      setProgress(0);
    } else {
      setProgress(percentCompleted);
    }
  }, []);

  const onFileDropAccepted = useCallback((files: File[]) => {
    if (files[0]) {
      setUploadInProgress(true);
      dispatch(
        uploadFile(
          files[0],
          '',
          category,
          {},
          onFileUploadProgress,
          () => dispatch(userGetDocuments(category)),
          () => setUploadInProgress(false),
        ),
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFileDeleteClick = useCallback(
    (fileName: string, fileId: string) => {
      dispatch(deleteFile(fileName, fileId, category));
      dispatch(userGetDocuments(category));
    },
    [dispatch],
  );

  const acceptTypes = {
    'application/pdf': ['.pdf'],
    'image/*': ['.jpeg', '.png', '.jpg', '.svg'],
  };

  const handleSubmit = () => {
    setSubmitting(true);
    dispatch(submitLicenses());
  };

  if (installerData === null) return;

  return (
    <div className={styles.container}>
      <Typography.Title className={styles.title} level={2}>
        <strong className={styles.strong}>
          {t('installerFlow:dashboard:subPage:underReview:title')}
        </strong>
      </Typography.Title>

      <Typography.Title className={styles.title} level={4}>
        {!installerData.hasSubmittedLicenses
          ? t('installerFlow:dashboard:subPage:underReview:message', { name: userName })
          : t('installerFlow:dashboard:subPage:underReview:status')}
      </Typography.Title>
      <FilesList
        files={uploadedFiles}
        {...(!installerData.hasSubmittedLicenses && { onFileDelete: onFileDeleteClick })}
        className={styles.files}
      />
      {!installerData.hasSubmittedLicenses && uploadedFiles.length < MAX_UPLOAD_DOCS_ALLOWED && (
        <DropZone
          accept={acceptTypes}
          className={styles.dropzone}
          loading={isUploadInProgress}
          progress={progress}
          timeLeft={timeLeft}
          onFileDropAccepted={onFileDropAccepted}
          {...(uploadedFiles.length > 0 && { label: t('common:uploadMore') })}
        />
      )}
      {!installerData.hasSubmittedLicenses && (
        <Button
          type="primary"
          onClick={handleSubmit}
          className={styles.submit}
          loading={isSubmitting}
          disabled={uploadedFiles.length === 0}
        >
          {t('common:submit')}
        </Button>
      )}
    </div>
  );
};

export default UnderReviewPage;
