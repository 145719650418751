export class MathUtils {
  static convertCommaToDecimal = (value: string): string => {
    return (value || '').replace(/,/g, '.');
  };

  /**
   * Returns true if the given value is a valid decimal number
   * using a maximum of 1 comma or decimal point
   *
   * For example:
   * 1.11   : true
   * 1,11   : true
   * 1      : true
   *
   * 1,1,1  : false
   * 11.    : false
   *
   * @param value Value to be checked
   */
  static isValidNumber = (value: string): boolean => {
    return !!(value || '').match(/^\d+([.,]\d*)?$/);
  };

  static isInteger = (value: string): boolean => {
    return !!(value || '').match(/^\d+$/);
  };
}
