import React, { useState } from 'react';
import { Checkbox, Flex, Input, message, Modal } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons/lib/icons';
import Button from 'components/ui/Button/Button';
import Loader from 'components/ui/Loader/Loader';
import { POOL_TAG_CONFIG } from 'framework/constants';
import { IPoolTag } from 'store/intermediate/intermediate.types';
import { poolTagCreate, poolTagDelete, poolTagEdit } from 'store/intermediate/intermediate.actions';
import { clientRequestUpdate } from 'store/client-request/client-request.actions';
import styles from './ClientRequestTagsWidget.module.scss';
import PoolTagCircle from './PoolTagCircle';
import DeleteTagModal from './DeleteTagModal';

const WIDGET_TITLE_LOCIZE_KEY = `intermediate:tenderDetails:clientRequestTags`;

type PoolTagWithAssignedStatus = IPoolTag & {
  isAssignedToClientRequest: boolean;
  isAssignmentLoading: boolean;
  isLoading: boolean;
};
type PoolTagsWithAssignedStatus = Array<PoolTagWithAssignedStatus>;
type ManagePoolTagsProps = {
  clientRequestId: string;
  handleClose: () => void;
  isOpen: boolean;
  poolId: string;
  poolTagsRaw: PoolTagsWithAssignedStatus;
  title: string;
};

const ManagePoolTags = (props: ManagePoolTagsProps) => {
  const [tagToDelete, setTagToDelete] = useState<IPoolTag | null>(null);

  const [poolTags, setPoolTags] = useState<PoolTagsWithAssignedStatus>(() => {
    return POOL_TAG_CONFIG.map((poolTag) => {
      const alreadyExisting = props.poolTagsRaw.find(
        (item) => item.title.length > 0 && item.colorCode.includes(poolTag.colorCode),
      );
      if (alreadyExisting)
        return {
          ...poolTag,
          ...alreadyExisting,
        };
      return {
        ...poolTag,
        isAssignedToClientRequest: false,
        isAssignmentLoading: false,
        isLoading: false,
      };
    });
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChangeTagTitle = (
    e: React.ChangeEvent<HTMLInputElement>,
    poolTagColorCode: string,
  ) => {
    setPoolTags((prev) =>
      prev.map((poolTag) => {
        if (poolTag.colorCode === poolTagColorCode) {
          return { ...poolTag, title: e.target.value };
        }
        return poolTag;
      }),
    );
  };

  const handleKeyDownOnTitle = (e: React.KeyboardEvent<HTMLInputElement>, poolTag: IPoolTag) => {
    if (e.key === 'Enter') {
      handleTagCreatOrEdit(poolTag);
    }
  };

  const handleTagCreatOrEdit = (poolTag: IPoolTag) => {
    const { id: poolTagId, colorCode, colorName, title } = poolTag;
    setPoolTags((prev) =>
      prev.map((item) => {
        if (item.colorCode === poolTag.colorCode) {
          return { ...item, isLoading: true };
        }
        return item;
      }),
    );
    if (poolTag.id) {
      dispatch(
        poolTagEdit({
          onFinish: (isSuccuss: boolean) => {
            setPoolTags((prev) =>
              prev.map((item) => {
                if (item.colorCode === poolTag.colorCode) {
                  return { ...item, isLoading: false };
                }
                return item;
              }),
            );
            if (isSuccuss)
              message.success(
                t(`${WIDGET_TITLE_LOCIZE_KEY}:tagUpdated`, { tagTitle: poolTag.title }),
              );
          },
          poolTagId,
          title,
        }),
      );
    } else {
      dispatch(
        poolTagCreate({
          colorCode,
          colorName,
          onFinish: (isSuccuss: boolean, poolTagId: string) => {
            setPoolTags((prev) =>
              prev.map((item) => {
                if (item.colorCode === poolTag.colorCode) {
                  return { ...item, id: poolTagId, isLoading: false };
                }
                return item;
              }),
            );
            if (isSuccuss)
              message.success(
                t(`${WIDGET_TITLE_LOCIZE_KEY}:tagCreated`, { tagTitle: poolTag.title }),
              );
          },
          poolId: props.poolId,
          title,
        }),
      );
    }
  };

  const handleTagDeleteModal = (poolTag: IPoolTag) => {
    setTagToDelete(poolTag);
  };

  const handleTagDelete = () => {
    if (!tagToDelete) return;
    const { id: poolTagId } = tagToDelete;
    setPoolTags((prev) =>
      prev.map((item) => {
        if (item.id === tagToDelete.id) {
          return { ...item, isLoading: true };
        }
        return item;
      }),
    );
    dispatch(
      poolTagDelete({
        onFinish: (isSuccuss: boolean) => {
          setPoolTags((prev) =>
            prev.map((item) => {
              if (item.colorCode === tagToDelete.colorCode) {
                return {
                  ...item,
                  id: '',
                  title: '',
                  isAssignedToClientRequest: false,
                  isAssignmentLoading: false,
                  isLoading: false,
                };
              }
              return item;
            }),
          );
          if (isSuccuss)
            message.success(
              t(`${WIDGET_TITLE_LOCIZE_KEY}:tagDeleted`, { tagTitle: tagToDelete.title }),
            );
        },
        poolTagId,
      }),
    );
  };

  const handleAssignTag = (e: CheckboxChangeEvent, poolTag: IPoolTag) => {
    const alreadyAssignedTags = poolTags.filter((item) => item.isAssignedToClientRequest);
    if (e.target.checked) {
      const currentTagToAdd = poolTags.find((item) => item.id === poolTag.id);
      if (currentTagToAdd) {
        alreadyAssignedTags.push(currentTagToAdd);
      } else return;
    } else {
      const currentTagToRemoveIndex = alreadyAssignedTags.findIndex(
        (item) => item.id === poolTag.id,
      );
      if (currentTagToRemoveIndex !== -1) {
        alreadyAssignedTags.splice(currentTagToRemoveIndex, 1);
      } else return;
    }
    setPoolTags((prev) =>
      prev.map((item) => {
        if (item.colorCode === poolTag.colorCode) {
          return { ...item, isAssignmentLoading: true };
        }
        return item;
      }),
    );
    const clientRequestPoolTags = alreadyAssignedTags.map((item) => ({ id: item.id }));
    dispatch(
      clientRequestUpdate(
        props.clientRequestId,
        { poolTags: clientRequestPoolTags },
        () => {
          setPoolTags((prev) =>
            prev.map((item) => {
              if (item.colorCode === poolTag.colorCode) {
                return {
                  ...item,
                  isAssignedToClientRequest: e.target.checked,
                  isAssignmentLoading: false,
                };
              }
              return item;
            }),
          );
          message.success(
            t(`${WIDGET_TITLE_LOCIZE_KEY}:${e.target.checked ? 'tagAssigned' : 'tagUnassigned'}`, {
              tagTitle: poolTag.title,
            }),
          );
        },
        () => {
          setPoolTags((prev) =>
            prev.map((item) => {
              if (item.colorCode === poolTag.colorCode) {
                return {
                  ...item,
                  isAssignedToClientRequest: e.target.checked,
                  isAssignmentLoading: false,
                };
              }
              return item;
            }),
          );
        },
      ),
    );
  };

  if (!props.isOpen) return;

  return (
    <Modal
      open={props.isOpen}
      title={props.title}
      onCancel={props.handleClose}
      className={styles.modal}
      footer={[
        <Button
          key="submit"
          type="primary"
          danger
          onClick={props.handleClose}
          className={styles.closeBtn}
        >
          {t('customerFlow:clientRequest:close')}
        </Button>,
      ]}
    >
      {poolTags.map((poolTag) => (
        <div key={poolTag.colorCode} className={styles.tagRow}>
          <Flex align="center" justify="space-between" gap="middle">
            <Flex align="center" gap="middle">
              <PoolTagCircle size="24" radius="12" fillColor={poolTag.colorCode} />
              {poolTag.isAssignmentLoading ? (
                <Loader />
              ) : (
                <Checkbox
                  checked={poolTag.isAssignedToClientRequest}
                  onChange={(e) => handleAssignTag(e, poolTag)}
                  className={styles.tagChecked}
                  disabled={poolTag.id.length === 0}
                />
              )}
              <Input
                size="large"
                value={poolTag.title}
                placeholder={t(`${WIDGET_TITLE_LOCIZE_KEY}:tagName`)}
                onChange={(e) => handleChangeTagTitle(e, poolTag.colorCode)}
                onKeyDown={(e) => handleKeyDownOnTitle(e, poolTag)}
                className={styles.tagInput}
              />
            </Flex>
            <Flex align="center" justify="center">
              {poolTag.isLoading ? (
                <Loader />
              ) : (
                <>
                  <Button
                    className={clsx(styles.modalActionBtn, styles.createOrEditTagBtn)}
                    disabled={poolTag.title.length === 0}
                    onClick={() => handleTagCreatOrEdit(poolTag)}
                  >
                    <CheckOutlined />
                  </Button>
                  <Button
                    className={styles.modalActionBtn}
                    danger
                    disabled={poolTag.id.length === 0}
                    onClick={() => handleTagDeleteModal(poolTag)}
                  >
                    <CloseOutlined />
                  </Button>
                </>
              )}
            </Flex>
          </Flex>
        </div>
      ))}
      <DeleteTagModal
        isOpen={!!tagToDelete}
        locizeKey={WIDGET_TITLE_LOCIZE_KEY}
        handleClose={() => setTagToDelete(null)}
        handleTagDelete={handleTagDelete}
      />
    </Modal>
  );
};

export default ManagePoolTags;
