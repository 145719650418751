import React, { ReactNode, useMemo, useState } from 'react';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Card, Flex, Form, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { SupportedLanguages } from 'framework/constants';
import useStateBasedLanguageHandler from 'hooks/useStateBasedLanguageHandler';
import { TLanguageBasedContent, TSupportedLanguagesKey } from 'types/language.types';
import { getLanguageKey } from 'utils/supprotedLanguagesUtils';

interface IMultiLangEntrySectionProps {
  defaultLanguage: SupportedLanguages;
  children: (language: TSupportedLanguagesKey) => ReactNode;
  sectionSupportedLanguages: TSupportedLanguagesKey[];
}

const MultiLangEntrySection = (props: IMultiLangEntrySectionProps) => {
  const defaultLanguageContent = useMemo(
    () => ({
      language: getLanguageKey(props.defaultLanguage),
      content: null,
    }),
    [props.defaultLanguage],
  );
  const mappedSupportedLanguages = useMemo(
    () =>
      props.sectionSupportedLanguages.map((_sectionSupportedLang) => ({
        language: _sectionSupportedLang,
        content: null,
      })),
    [props.sectionSupportedLanguages],
  );
  const [languages, setLanguages] = useState<TLanguageBasedContent<null>[]>(
    props.sectionSupportedLanguages.length > 0
      ? props.sectionSupportedLanguages.includes(getLanguageKey(props.defaultLanguage))
        ? mappedSupportedLanguages
        : [defaultLanguageContent, ...mappedSupportedLanguages]
      : [defaultLanguageContent],
  );

  const {
    addTranslation,
    defaultLanguageKey,
    getLanguageChanger,
    possibleOptions,
    removeTranslation,
  } = useStateBasedLanguageHandler(props.defaultLanguage, null, languages, setLanguages);
  const { t } = useTranslation();

  return (
    <Flex vertical gap={8}>
      {languages.map((_language, index) => (
        <Card key={_language.language}>
          <Flex vertical>
            <Flex justify="end">
              <Space align="baseline">
                <Form.Item>
                  <Select
                    value={_language.language}
                    onChange={getLanguageChanger(index)}
                    placeholder={t('intermediate:myProjects:editProjectPools:language')}
                    options={possibleOptions}
                    disabled={
                      possibleOptions.length === 0 || defaultLanguageKey === _language.language
                    }
                  />
                </Form.Item>

                {defaultLanguageKey !== _language.language && (
                  <Button
                    onClick={() => {
                      removeTranslation(_language.language);
                    }}
                    type="text"
                    size="small"
                    shape="circle"
                    icon={<MinusCircleOutlined />}
                  />
                )}
              </Space>
            </Flex>
            <Flex vertical gap={8}>
              {props.children(_language.language)}
            </Flex>
          </Flex>
        </Card>
      ))}
      {possibleOptions.length > 0 && (
        <Button type="link" onClick={addTranslation}>
          {t('intermediate:myProjects:editProjectPools:addMoreTranslations')}
        </Button>
      )}
    </Flex>
  );
};

export default MultiLangEntrySection;
