import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import Typography from 'antd/lib/typography';
import Tooltip from 'antd/lib/tooltip';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import BackButton from 'components/ui/BackButton/BackButton';
import Button from 'components/ui/Button/Button';
import DateFormat from 'components/ui/DateFormat/DateFormat';

import { IClientRequest } from 'store/client-request/client-request.types';
import { IOffer, OfferState } from 'store/offer/offer.types';

import styles from './TenderDetailsPage.module.sass';

interface IPageHeaderProps {
  clientRequest: IClientRequest;
  offer?: IOffer | null;
}

const PageHeader: React.FC<IPageHeaderProps> = ({ clientRequest, offer }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const showEditOfferButton =
    offer?.state === OfferState.TenderCreated ||
    offer?.state === OfferState.TenderSubmitted ||
    offer?.state === OfferState.TenderAccepted ||
    offer?.state === OfferState.TenderSubmittedHomeCheck ||
    offer?.state === OfferState.TenderSubmittedRemoteHomeCheck ||
    offer?.state === OfferState.TenderAcceptedHomeCheck ||
    offer?.state === OfferState.TenderAcceptedRemoteHomeCheck;

  const onEditClick = () => {
    navigate(`/installer/tender/${clientRequest.id}/offer-type`);
  };

  return (
    <div className={styles.header}>
      <BackButton className={styles.back} onClick={() => navigate('/installer/dashboard')}>
        {t('common:buttons:back')}
      </BackButton>

      <div className={styles.flexContainer}>
        <div>
          <Typography.Title level={3} className={styles.title}>
            {t('installerFlow:tenderDetails:title')} {clientRequest.displayId}
          </Typography.Title>

          <p className={styles.text}>
            {t('installerFlow:tenderDetails:published')}{' '}
            <DateFormat date={clientRequest?.lastStateChangedAt as Date} />
          </p>
        </div>

        {showEditOfferButton ? (
          <Tooltip
            title={
              'You have already given an offer for this but you can edit it if required, if you edit the offer then it will require approval from the pool owner again'
            }
          >
            <Button className={styles.editButton} type="link" onClick={onEditClick}>
              <EditOutlined />
              <span>{t('Edit Offer')}</span>
              <InfoCircleOutlined className={styles.infoIcon} />
            </Button>
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};

export default PageHeader;
