import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import ArrowRight from 'assets/icons/arrow-right-white.svg';
import Table from 'components/ui/Table/Table';
import Button from 'components/ui/Button/Button';
import Icon from 'components/ui/Icon/Icon';
import { CommentType, QuestionType } from 'store/client-request/client-request.types';
import { formatDateFromIso } from 'utils/dateUtils';
import styles from './OffersListStep.module.sass';

interface IQuestionsListProps {
  questions: QuestionType[];
}

const QuestionsList: React.FC<IQuestionsListProps> = ({ questions }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  // TODO: later this logic will moved to BE
  // TODO: Also we need to not send private installer comments to customer. For now we get all comments / answers
  const filterQuestions = questions.filter(
    (question: QuestionType) =>
      !question.parentCommentId && question.type !== CommentType.PrivateInstaller,
  );
  const columns = [
    {
      title: '',
      key: 'logo',
      dataIndex: 'author',
      width: '35%',
      // eslint-disable-next-line react/display-name
      render: (author: any) =>
        author?.organization?.logo ? (
          <img
            className={styles.organizationLogo}
            src={author?.organization?.logo}
            alt="logo"
            width="60"
            height="60"
          />
        ) : (
          <Avatar
            shape="square"
            className={styles.organizationLogo}
            size={64}
            icon={<UserOutlined />}
          />
        ),
    },
    {
      title: '',
      dataIndex: 'text',
      key: 'text',
      width: '23%',
    },
    {
      title: '',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '17%',
      render: (createdAt: Date) => formatDateFromIso(createdAt, 'dddd, Do MMMM, YYYY'),
    },
    {
      title: '',
      dateIndex: 'answer',
      key: 'answer',
      width: '26%',
      align: 'left',
      render: renderAction,
    },
  ];

  function renderAction(question: any) {
    const hasAnswer = questions.filter((q) => question.id === q.parentCommentId).length;

    const buttonProps = {
      size: 'middle',
      type: hasAnswer ? 'secondary' : 'primary',
      icon: hasAnswer ? null : <Icon icon={ArrowRight} />,
      onClick: () =>
        navigate({
          pathname: `/customer/client-request/${id}/offer-question/${question.id}`,
        }),
    };

    const buttonTitle = hasAnswer
      ? t(`customerFlow:offerReview:offerListPage:viewAnswer`)
      : t(`customerFlow:offerReview:offerListPage:answer`);

    return (
      <div className={styles.questionTableAction}>
        {/* @ts-ignore */}
        <Button className={styles.buttonAction} {...buttonProps}>
          {buttonTitle}
        </Button>
      </div>
    );
  }

  const tableProps = {
    columns,
    className: styles.table,
    dataSource: filterQuestions,
  };

  return (
    <div className={styles.questions}>
      <div className={styles.questionsHead}>
        <div className={styles.questionsTitle}></div>
        <div className={styles.questionsTitle}>
          <h4>
            {t(`customerFlow:offerReview:offerListPage:questions`)} ({filterQuestions.length})
          </h4>
        </div>
      </div>
      {/* @ts-ignore */}
      <Table {...tableProps} />
    </div>
  );
};

export default QuestionsList;
