import React from 'react';

import { ReactComponent as EmailIcon } from 'assets/icons/dashboard/email.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/dashboard/phone.svg';

import styles from './ContactSection.module.sass';

interface IContactProps {
  email?: string;
  phone?: string;
}

const ContactSection: React.FC<IContactProps> = (props) => {
  const { phone, email } = props;
  return (
    <>
      {phone && (
        <div className={styles.contactData}>
          <PhoneIcon />
          {phone}
        </div>
      )}
      {email && (
        <div className={styles.contactData}>
          <EmailIcon />
          {email}
        </div>
      )}
    </>
  );
};

export default ContactSection;
