import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/ui/Icon/Icon';
import FeedbackForm, { FormValues } from 'components/ui/FeedbackForm/FeedbackForm';
import styles from './FeedbackStep.module.sass';
import chargeIcon from '../../../assets/icons/charge.svg';
import userIcon from '../../../assets/icons/user.svg';

export interface IFeedbackStepProps {
  setInstallerFeedback?: (value: FormValues) => void;
  setTendergyFeedback?: (value: FormValues) => void;
}

const FeedbackStep: React.FC<IFeedbackStepProps> = (props) => {
  const { setInstallerFeedback, setTendergyFeedback } = props;
  const { t } = useTranslation(['feedback']);

  const tendergyFeedbackFormProps = {
    description: t('feedback:tendergyDescription'),
    onChange: setTendergyFeedback,
  };

  const installerFeedbackFormProps = {
    description: t('feedback:installerDescription'),
    onChange: setInstallerFeedback,
  };

  return (
    <div className={styles.feedback}>
      <div className={styles.feedbackBox}>
        <div className={styles.title}>
          <Icon icon={chargeIcon} className={styles.icon} />
          {t('feedback:rateTendergy')}
        </div>
        <FeedbackForm {...tendergyFeedbackFormProps} />
      </div>
      <div className={styles.feedbackBox}>
        <div className={styles.title}>
          <Icon icon={userIcon} className={styles.icon} />
          {t('feedback:rateInstaller')}
        </div>
        <FeedbackForm {...installerFeedbackFormProps} />
      </div>
    </div>
  );
};

export default FeedbackStep;
