import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TablePaginationConfig } from 'antd';
import { CrudView } from 'components/layout/CrudView/CrudView';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'framework/constants';
import { IPool } from 'store/intermediate/intermediate.types';
import { IPDFTemplate } from 'store/pdf-templates/pdf-templates.types';
import {
  PDFTemplateGet,
  PDFTemplateCreate,
  PDFTemplateUpdate,
  PDFTemplateDelete,
} from 'store/pdf-templates/pdf-templates.actions';
import { currentPDFTemplatesSelector } from 'store/pdf-templates/pdf-templates.selectors';
import { EntityAction, RequestResult } from 'types/crud-view.types';
import { FormBody } from './FormBody';

interface TemplateProps {
  pool: IPool;
}

const defaultMetaValue = {
  limit: DEFAULT_PAGINATION_PAGE_SIZE,
  offset: 0,
};

export const PDFTemplate: React.FC<TemplateProps> = ({ pool }) => {
  const dispatch = useDispatch();
  const [meta, setMeta] = useState(defaultMetaValue);
  const { t } = useTranslation();

  const templates = useSelector(currentPDFTemplatesSelector);

  useEffect(() => {
    dispatch(PDFTemplateGet(pool.id));
    // eslint-disable-next-line
  }, [pool.id]);

  const onPageChange = (page: number, pageSize?: number) => {
    if (pageSize) {
      const newMeta = {
        offset: pageSize * (page - 1),
        limit: pageSize,
      };
      setMeta(newMeta);
    }
  };

  const paginationProps: TablePaginationConfig = {
    total: templates.length,
    pageSize: meta.limit,
    current: meta.offset ? meta.offset / meta.limit + 1 : 1,
    onChange: onPageChange,
    position: ['bottomCenter'],
  };

  const columns = [
    {
      title: t('intermediate:myProjects:pdfTemplates:table:name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('intermediate:myProjects:pdfTemplates:table:type'),
      dataIndex: 'type',
      key: 'type',
    },
  ];

  const formatBoxes = (boxes: any) => {
    return Object.keys(boxes).map((item) =>
      typeof boxes[item] === 'string' ? boxes[item] : JSON.stringify(boxes[item]),
    );
  };

  const formatPayload = (data: any) => {
    return {
      ...data,
      textBoxes: formatBoxes(data.textBoxes),
      file: data.files[0]['originFileObj'],
    };
  };

  const onActionClick = (
    action: EntityAction,
    data: IPDFTemplate,
    onRequestDone?: (status: RequestResult) => void,
  ) => {
    switch (action) {
      case EntityAction.Create:
        dispatch(PDFTemplateCreate(pool.id, formatPayload(data), onRequestDone));
        break;
      case EntityAction.Edit:
        dispatch(PDFTemplateUpdate(data.id, formatPayload(data), onRequestDone));
        break;
      case EntityAction.Delete:
        dispatch(PDFTemplateDelete(data.id, onRequestDone));
    }
  };

  const isOrderTemplatesType = templates.filter((item) => item.type === 'order-template').length
    ? true
    : false;

  const modalProps = {
    onSubmit: onActionClick,
    formBody: <FormBody />,
    width: '60%',
  };

  return (
    <CrudView<IPDFTemplate>
      entries={templates}
      columns={columns}
      locizeKey="intermediate:myProjects:pdfTemplates"
      isMaxEntries={isOrderTemplatesType}
      maxKey={'pdfTemplate'}
      paginationProps={paginationProps}
      modalProps={modalProps}
    />
  );
};
