import React, { useState } from 'react';
import { ModalProps } from 'antd/lib/modal';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import Button from 'components/ui/Button/Button';
import Checkbox from 'components/ui/Checkbox/Checkbox';
import { IOffer } from 'store/offer/offer.types';
import styles from './AnnotationModal.module.sass';

interface Props extends ModalProps {
  offer: IOffer;
  useAsDefault: boolean;
  visible: boolean;
  onSubmit: (annotation: string, useAsDefault: boolean) => void;
  onCancel: () => void;
}

const blockTypeOptions = ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'];
const inlineOptions = ['bold', 'italic', 'underline'];
const toolbarOptions = [
  'inline',
  'blockType',
  'fontSize',
  'fontFamily',
  'colorPicker',
  'link',
  'emoji',
  'list',
  'textAlign',
];

const editorProps = {
  toolbar: {
    options: toolbarOptions,
    inline: {
      options: inlineOptions,
    },
    blockType: {
      options: blockTypeOptions,
    },
  },
  wrapperClassName: styles.editorWrapper,
  editorClassName: styles.textAreaWrapper,
};

const AnnotationModal: React.FC<Props> = ({ offer, useAsDefault, onSubmit, onCancel, visible }) => {
  const { t } = useTranslation();

  const [isDefault, setIsDefault] = useState(useAsDefault);
  const [offerAnnotation, setOfferAnnotation] = useState(
    offer.offerAnnotation
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(offer.offerAnnotation)))
      : EditorState.createEmpty(),
  );

  const modalProps = {
    visible,
    title: t('intermediate:myProjects:annotationModal:title'),
    footer: null,
    width: 680,
    className: styles.container,
    onCancel,
  };

  const handleSaveClick = () => {
    const content = JSON.stringify(convertToRaw(offerAnnotation.getCurrentContent()));
    onSubmit(content, isDefault);
  };

  return (
    <Modal {...modalProps}>
      <div className={styles.caption}>{t('intermediate:myProjects:annotationModal:caption')}</div>
      <div>
        <Editor
          {...editorProps}
          initialContentState={convertToRaw(offerAnnotation.getCurrentContent())}
          onEditorStateChange={(offerAnnotation: any) => setOfferAnnotation(offerAnnotation)}
        />
      </div>
      <div className={styles.footer}>
        <div>
          <Checkbox
            checked={isDefault}
            onChange={() => setIsDefault(!isDefault)}
            className={styles.modeCheckbox}
          >
            {t('intermediate:myProjects:annotationModal:setAsDefault')}
          </Checkbox>
        </div>
        <div className={styles.actions}>
          {/*@ts-ignore*/}
          <Button type="secondary" className={styles.button} onClick={onCancel}>
            {t('common:buttons:cancel')}
          </Button>
          <Button type="primary" className={styles.button} onClick={handleSaveClick}>
            {t('common:buttons:save')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AnnotationModal;
