import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Tooltip, Typography } from 'antd';
import NavLink from 'components/ui/NavLink/NavLink';
import { offerstInProgressCount, offerTransitionReset } from 'store/offer/offer.actions';
import ActiveOffersTab from './ActiveOffersTab';
import styles from './OffersTab.module.sass';
import editStyles from '../EditPool.module.sass';

enum offerTabs {
  ACTIVE = 'offers',
  EXPIRED = 'expiredOffers',
}
export interface IRequestsProps {
  poolId: string;
  activeTabParam: string;
}
const OffersTab: React.FC<IRequestsProps> = ({ poolId, activeTabParam }) => {
  const { t } = useTranslation();

  // const navigate = useNavigate();
  const dispatch = useDispatch();
  // const offersInProgressCount = useSelector(offersInProgressCountSelector);

  useEffect(() => {
    dispatch(offerstInProgressCount());
  }, []); // eslint-disable-line

  useEffect(() => {
    return function cleanup() {
      dispatch(offerTransitionReset());
    };
  }, [dispatch]);

  return (
    <div style={{ paddingTop: '10px' }}>
      <Typography className={editStyles.boxTitle}>
        {t('intermediate:myProjects:editProjectPools:requests')}
        <Tooltip
          title={t('intermediate:myProjects:projectPoolsTab:notificationContent')}
          placement="bottom"
          className={editStyles.tooltip}
        >
          <InfoCircleOutlined />
        </Tooltip>
      </Typography>
      <Row>
        <Col span={19}>
          <div
            className={styles.navLinks}
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <NavLink
              to={`/my-projects/${poolId}/${offerTabs.ACTIVE}`}
              title={t('common:request:active')}
            />
            <NavLink
              to={`/my-projects/${poolId}/${offerTabs.EXPIRED}`}
              title={t('common:request:expired')}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} className={styles.table}>
          {activeTabParam === offerTabs.ACTIVE ? (
            <ActiveOffersTab poolId={poolId} activeTabParam={activeTabParam} />
          ) : null}
          {activeTabParam === offerTabs.EXPIRED ? (
            <ActiveOffersTab poolId={poolId} activeTabParam={activeTabParam} />
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default OffersTab;
