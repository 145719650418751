import pdfMake from 'pdfmake/build/pdfmake';
import hb from 'handlebars';
import i18next from 'i18next';
import dayjs from 'dayjs';
import { COMPANY_NAME, COMPANY_WEBSITE, SupportedLanguages } from 'framework/constants';
import { IOfferDocumentTotalCosts } from 'pages/MyProjects/ClientRequestPage/OfferDocumentGenerator/useCalculateCosts';
import { IOfferDocumentForm } from 'pages/MyProjects/ClientRequestPage/OfferDocumentGenerator/types';
import { formatPriceWithCurrency } from './currencyUtils';
import { flatten } from './array';

const fontFiles = {
  Roboto: {
    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
};
pdfMake.fonts = fontFiles;

interface ITable {
  name: string;
  value: any;
}

const getLayout = (userLanguage: SupportedLanguages): ILayout => {
  return {
    name: 'classic',
    headerHandler: function (currentPage: number, pageCount: number, pageSize: any) {
      return [
        {
          stack: [
            {
              image: 'schneiderLogo',
              width: 145,
              height: 40,
              margin: [pageSize.width - 170, 20, 0, 0],
            },
            {
              text: `${i18next.t('intermediate:pdfGen:layout:page', {
                lng: userLanguage,
              })} ${currentPage} ${i18next.t('intermediate:pdfGen:layout:of', {
                lng: userLanguage,
              })} ${pageCount}`,
              fontSize: 7,
              margin: [60, -25, 0, 0],
            },
          ],
        },
        {
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 40,
              w: pageSize.width,
              h: 0,
              r: 0,
              lineWidth: 5,
              lineColor: 'green',
            },
          ],
        },
      ];
    },
    footerHandler: function () {
      return [
        {
          text: COMPANY_NAME,
          fontSize: 7,
          margin: [60, 40, 0, 0],
        },
        { text: COMPANY_WEBSITE, fontSize: 7, margin: [60, 0, 0, 0] },
      ];
    },
    tableLayout: {
      hLineWidth: function (i: number, node: any) {
        // This function returns the line width for horizontal lines.
        // We return 0 for lines after the last row with content.
        // We also return 0 for the first and last row of the table.
        return i === 0 ||
          i === node.table.body.length ||
          (i < node.table.body.length && !node.table.body[i][0].text.length)
          ? 0
          : 0.5;
      },
      vLineWidth: function () {
        return 0;
      },
      hLineColor: function () {
        return 'black';
      },
      vLineColor: function () {
        return 'black';
      },
      paddingLeft: function () {
        return 8;
      },
      paddingRight: function () {
        return 8;
      },
      paddingTop: function () {
        return 2;
      },
      paddingBottom: function () {
        return 2;
      },
    },
  };
};

interface ILayout {
  name: string;
  headerHandler: any;
  footerHandler: any;
  tableLayout: any;
}

function createTable(
  tableDTO: TableDTO,
  vatRate: number,
  currency: string,
  userLanguage: SupportedLanguages,
) {
  const table = {
    headerRows: 2,
    widths: ['4%', '11%', '50%', '15%', '15%'],
    body: [
      [
        {
          text: i18next.t('intermediate:pdfGen:table:no', { lng: userLanguage }),
          style: 'tableHeader',
        },
        {
          text: i18next.t('intermediate:pdfGen:table:description', {
            lng: userLanguage,
          }),
          style: 'tableHeader',
        },
        {
          text: i18next.t('intermediate:pdfGen:table:quantity', {
            lng: userLanguage,
          }),
          style: 'tableHeader',
        },
        {
          text: i18next.t('intermediate:pdfGen:table:unitPrice', {
            lng: userLanguage,
          }),
          style: 'tableHeader',
        },
        {
          text: i18next.t('intermediate:pdfGen:table:totalPrice', {
            lng: userLanguage,
          }),
          style: 'tableHeader',
        },
      ],
    ],
  };
  let rowNumber = 1;
  let totalNetPrice = 0;
  tableDTO.groups.forEach((category) => {
    table.body.push([
      // eslint-disable-next-line
      { text: category.name, style: 'table_invoice_amount', bold: true, colSpan: 5 } as any,
      {},
      {},
      {},
      {},
    ]);
    category.lineItems.forEach((lineItem) => {
      totalNetPrice += lineItem.netPrice;
      table.body.push([
        // eslint-disable-next-line
        { text: rowNumber.toString(), style: 'table_invoice_amount' } as any,
        { text: `${lineItem.quantity} ${lineItem.unit}`, style: 'table_invoice_amount' },
        { text: lineItem.description, style: 'table_invoice_amount' },
        {
          text: formatPriceWithCurrency(lineItem.unitPrice, currency),
          style: 'table_invoice_amount',
          alignment: 'right',
        },
        {
          text: formatPriceWithCurrency(lineItem.netPrice, currency),
          style: 'table_invoice_amount',
          alignment: 'right',
        },
      ]);
      rowNumber += 1;
    });
  });
  const vat = totalNetPrice * (vatRate / 100);
  const totalGrossPrice = totalNetPrice + vat;
  table.body.push(
    // eslint-disable-next-line
    [{} as any, {}, {}, {}, {}],
    // eslint-disable-next-line
    [
      {} as any,
      {},
      {
        text: i18next.t('intermediate:pdfGen:table:totalPriceNet', {
          lng: userLanguage,
        }),
        bold: true,
        style: 'table_invoice_amount',
        alignment: 'right',
        border: [false, true, false, false],
      },
      {},
      {
        text: formatPriceWithCurrency(totalNetPrice, currency),
        bold: true,
        style: 'table_invoice_amount',
        alignment: 'right',
      },
    ],
    // eslint-disable-next-line
    [
      {} as any,
      {},
      {
        text: `${vatRate} % ${i18next.t('intermediate:pdfGen:table:vat', {
          lng: userLanguage,
        })}`,
        style: 'table_invoice_amount',
        alignment: 'right',
        bold: false,
        border: [false, true, false, false],
      },
      {},
      {
        text: formatPriceWithCurrency(vat, currency),
        style: 'table_invoice_amount',
        alignment: 'right',
      },
    ],
    // eslint-disable-next-line
    [
      {} as any,
      {},
      {
        text: i18next.t('intermediate:pdfGen:table:totalPriceGross', {
          lng: userLanguage,
        }),
        style: 'table_invoice_amount',
        alignment: 'right',
        bold: true,
        border: [false, true, false, false],
      },
      {},
      {
        text: formatPriceWithCurrency(totalGrossPrice, currency),
        bold: true,
        style: 'table_invoice_amount',
        alignment: 'right',
      },
    ],
  );
  return table;
}

export interface TableDTO {
  groups: IGroup[];
}

export interface IGroup {
  name: string;
  lineItems: ILineItem[];
}

interface ILineItem {
  description: string;
  unit: string;
  quantity: number;
  netPrice: number;
  unitPrice: number;
  grossPrice: number;
}
const genderOptions = [
  'intermediate:pdfGen:gender:ms',
  'intermediate:pdfGen:gender:mr',
  'intermediate:pdfGen:gender:mx',
];
export function generatePDF(
  templateJSON: any,
  values: any,
  tables: ITable[],
  layout: ILayout,
  fonts: any,
): pdfMake.TCreatedPdf {
  tables?.map((table) => (values[table.name] = `{{ ${table.name} }}`));
  const jsonDefinition = JSON.parse(hb.compile(templateJSON)(values));
  tables?.forEach((table) => {
    const tablePart = jsonDefinition.content.filter(
      (c: any) => Object.entries(c)[0][1] == `{{ ${table.name} }}`,
    );
    if (tablePart?.length > 0) {
      tablePart[0].table = table.value;
      tablePart[0].layout = layout.tableLayout;
    }
  });

  jsonDefinition.header = layout.headerHandler;
  jsonDefinition.footer = layout.footerHandler;
  pdfMake.vfs = fonts;
  return pdfMake.createPdf(jsonDefinition as any);
}

const PDFGenerator = () => {
  const createPDF = async (
    template: string,
    data: IOfferDocumentForm,
    vatRate: number,
    currency: string,
    totalCosts: IOfferDocumentTotalCosts,
    userLanguage: SupportedLanguages,
  ): Promise<pdfMake.TCreatedPdf> => {
    const vatMultiplier = 1 + vatRate / 100;
    const bundlesTable = flatten(
      data.bundles.map(
        (_bundle) =>
          _bundle?.components.map((component) => ({
            name: component?.category || '',
            lineItems: component?.items.map<ILineItem>((item) => ({
              netPrice:
                (_bundle.discount ? -1 : 1) *
                +(item.price || 0) *
                +(item.quantity || 0) *
                (_bundle.discount ? 1 : 1 + _bundle.margin / 100),
              unitPrice: +(item.price || 0) * (_bundle.discount ? 1 : 1 + _bundle.margin / 100),
              grossPrice:
                (_bundle.discount ? -1 : 1) *
                +(item.price || 0) *
                +(item.quantity || 0) *
                (_bundle.discount ? 1 : vatMultiplier) *
                (_bundle.discount ? 1 : 1 + _bundle.margin / 100),
              unit: item.unit || '',
              quantity: +(item.quantity || 0),
              description: item.description || '',
            })),
          })),
      ),
    );
    const productsTable: IGroup = {
      name: i18next.t('intermediate:pdfGen:products:title', { lng: userLanguage }),
      lineItems: data.products.map<ILineItem>((product) => ({
        netPrice: +(product.netPrice || 0),
        unitPrice: +(product.netPrice || 0),
        grossPrice: +(product.netPrice || 0) * vatMultiplier,
        unit: i18next.t('intermediate:pdfGen:products:piece', { lng: userLanguage }),
        description: product.title || '',
        quantity: 1,
      })),
    };

    data.owner.gender = i18next.t(genderOptions[data.owner.gender || 0], {
      lng: userLanguage,
    }) as any;
    data.offer.date = dayjs(data.offer.date).format('YYYY-MM-DD') as any;

    try {
      return generatePDF(
        template,
        {
          ...data,
          costs: {
            total: {
              gross: formatPriceWithCurrency(totalCosts.total.gross, currency),
            },
          },
        },
        [
          {
            name: 'price_table',
            value: createTable(
              {
                groups: [productsTable, ...bundlesTable],
              },
              vatRate,
              currency,
              userLanguage,
            ),
          },
        ],
        getLayout(userLanguage),
        fontFiles,
      );
    } catch (e) {
      throw 'JSON is not valid';
    }
  };
  const jsonToPDF = async (
    template: string,
    data: IOfferDocumentForm,
    vatRate: number,
    currency: string,
    totalCosts: IOfferDocumentTotalCosts,
    userLanguage: SupportedLanguages,
  ) => {
    const createdPDF = await createPDF(template, data, vatRate, currency, totalCosts, userLanguage);
    return new Promise<Blob>((resolve) => {
      createdPDF.getBlob((blob: Blob) => {
        return resolve(blob);
      });
    });
  };
  const downloadPDF = async (
    fileName: string,
    template: string,
    data: IOfferDocumentForm,
    vatRate: number,
    currency: string,
    totalCosts: IOfferDocumentTotalCosts,
    userLanguage: SupportedLanguages,
  ) => {
    const createdPDF = await createPDF(template, data, vatRate, currency, totalCosts, userLanguage);
    createdPDF.download(fileName);
  };
  return { jsonToPDF, downloadPDF };
};
export default PDFGenerator;
