import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { enableMapSet } from 'immer';
import { MatomoProvider, createInstance } from '@mitresthen/matomo-tracker-react';
import * as Sentry from '@sentry/react';
import config from 'config';
import * as serviceWorker from './serviceWorker';
import i18n from './i18n';
import AppRouter from './AppRouter';

import './App.sass';

// @ts-ignore
import store from './store/configureStore';

enableMapSet();

// Initialize Matomo
const matomoInstance = createInstance({
  urlBase: process.env.REACT_APP_MATOMO_BASE_URL || '',
  siteId: parseInt(process.env.REACT_APP_MATOMO_SITE_ID || '0', 10),
  srcUrl: `${process.env.REACT_APP_MATOMO_BASE_URL}js/${process.env.REACT_APP_MATOMO_FILE}`,
  configurations: {
    disableCookies: false,
  },
});

// Initialize Sentry
Sentry.init({
  dsn: config.SENTRY_DSN_URL,
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: [config.CRAFT_SERVICE_URL],
    }),
  ],
  environment: config.SENTRY_ENVIRONMENT,
  tracesSampleRate: 1.0,
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <MatomoProvider value={matomoInstance} />
    <I18nextProvider i18n={i18n}>
      <AppRouter />
    </I18nextProvider>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
