import React from 'react';
import Typography from 'antd/lib/typography';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { globalErrorSelector } from 'store/app/app.selectors';

const ErrorPage = () => {
  const { t } = useTranslation();
  const isGlobalError = useSelector(globalErrorSelector);

  return (
    <div style={{ textAlign: 'center' }}>
      <Typography.Title level={4}>{t(`errors:${isGlobalError}`)}</Typography.Title>
    </div>
  );
};

export default ErrorPage;
