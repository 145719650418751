import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropZone from 'components/ui/DropZone/DropZone';
import FilesList from 'components/ui/FilesList/FilesList';
import { isQuestionsSelector } from 'store/client-request/client-request.selectors';
import { MediaCategoryType } from 'store/client-request/client-request.types';
import { deleteFile } from 'store/client-request/client-request.actions';
import { clearCurrentFile, uploadGenericFile } from 'store/document/document.actions';
import { calculateProgress, calculateTimeLeft } from 'utils/fileUploadUtils';

import styles from './OfferQuestionPage.module.sass';

interface IUploadDocumentStepProps {
  category: MediaCategoryType;
  questionId?: string;
  uploadedFiles: any[];
  onDeleteSuccess?: any;
}

const UploadDocument: React.FC<IUploadDocumentStepProps> = ({
  category,
  questionId,
  uploadedFiles,
  onDeleteSuccess,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);

  const questionsAnswersList = useSelector(isQuestionsSelector);
  let timeStartTemp: any = null;

  useEffect(() => {
    setLoading(false);
  }, [questionsAnswersList]);

  const onFileUploadProgress = useCallback((progressEvent: any) => {
    const percentCompleted = calculateProgress(progressEvent.loaded, progressEvent.total);

    if (!timeStartTemp) {
      timeStartTemp = progressEvent.timeStamp; // eslint-disable-line
    } else {
      const timeLeft = calculateTimeLeft(
        timeStartTemp,
        progressEvent.timeStamp,
        progressEvent.loaded,
        progressEvent.total,
      );

      setTimeLeft(timeLeft);
    }

    if (percentCompleted === 100) {
      setProgress(0);
    } else {
      setProgress(percentCompleted);
    }
  }, []);

  const startFileUpload = useCallback(
    (file: File) => {
      if (file) {
        setLoading(true);
        dispatch(
          uploadGenericFile({
            document: file,
            category,
            config: {
              subCategory: 'commentId',
              subCategoryId: questionId,
            },
            uploadProgress: onFileUploadProgress,
            uploadSuccess: () => setLoading(false),
            uploadError: () => setLoading(false),
          }),
        );
      }
    },
    [category, questionId, dispatch, onFileUploadProgress],
  );

  const onFileDeleteClick = useCallback(
    (fileName: string, fileId: string) => {
      dispatch(deleteFile(fileName, fileId, category, onDeleteSuccess));
      dispatch(clearCurrentFile());
    },
    [category, onDeleteSuccess, dispatch],
  );

  const id = category.replace(/:/i, '-');

  return (
    <div className={styles.container} id={id}>
      <FilesList files={uploadedFiles} onFileDelete={onFileDeleteClick} />

      <DropZone
        acceptedFiles="JPG, PDF, PNG. "
        loading={loading}
        progress={progress}
        timeLeft={timeLeft}
        onFileDropAccepted={([files]) => startFileUpload(files)}
      />
    </div>
  );
};

export default UploadDocument;
