import React, { useCallback, useEffect, useState } from 'react';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import Loader from 'components/ui/Loader/Loader';
import useLanguageChangeCallback from 'hooks/useLaguageChangeCallback';
import {
  currentClientRequestSelector,
  clientRequestStateSelector,
} from 'store/client-request/client-request.selectors';
import { isRegistrationInviteTokenSelector } from 'store/register/register.selectors';
import { isAuthorizedSelector, customerRegistrationSelector } from 'store/auth/auth.selectors';

import { register } from 'store/auth/auth.actions';
import {
  ClientRequestTransitionTypes,
  IClientRequest,
} from 'store/client-request/client-request.types';
import { ValidateInviteType } from 'store/register/register.types';
import { SlugCategoryType } from 'store/intermediate/intermediate.types';
import { landingPageGet } from 'store/intermediate/intermediate.actions';
import { RequestState } from 'store/common.types';
import RegistrationStep from './components/RegistrationForm/RegistrationStep';
import PoolSelector from './components/PoolSelector/PoolSelector';

const RegistrationPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [step, setStep] = useState<number>();

  const clientRequest = useSelector(currentClientRequestSelector);
  const isClientRequestCreating = useSelector(clientRequestStateSelector);
  const isAuthorized = useSelector(isAuthorizedSelector);
  const { registrationState, registrationError } = useSelector(customerRegistrationSelector);
  const inviteTokenData = useSelector(isRegistrationInviteTokenSelector);
  const token = _get(inviteTokenData, 'token', '');
  const [form] = useForm();
  const { i18n } = useTranslation();
  const reloadLandingPage = useCallback(() => {
    if (!inviteTokenData?.clientRequestPool?.id) return;
    dispatch(
      landingPageGet(
        inviteTokenData?.clientRequestPool?.id,
        step === 2 ? SlugCategoryType.UserLandingPage : SlugCategoryType.ParentLandingPage,
      ),
    );
  }, [inviteTokenData, dispatch, step]);
  useLanguageChangeCallback(reloadLandingPage);
  useEffect(() => {
    form.setFieldValue('defaultLanguage', i18n.language);
  }, [form, i18n.language]);
  const languageWatcher = Form.useWatch('defaultLanguage', form);
  useEffect(() => {
    if (languageWatcher) i18n.changeLanguage(languageWatcher);
  }, [languageWatcher, i18n]);
  const handlePoolSelected = useCallback(
    (poolId?: string) => {
      if (!poolId) return;
      dispatch(landingPageGet(poolId, SlugCategoryType.UserLandingPage, () => setStep(2)));
      form.setFieldValue('country', inviteTokenData?.clientRequestPool?.country.iso31661Alpha3);
    },
    [dispatch, inviteTokenData, form],
  );
  useEffect(() => {
    if (
      [registrationState, isClientRequestCreating].includes(RequestState.Loading) ||
      registrationError !== null
    ) {
      return;
    }
    if (isAuthorized && clientRequest) {
      navigate(`/customer`);
      return;
    }
    if (!token || !inviteTokenData || isAuthorized) {
      navigate('/');
      return;
    }

    // If the pool is not a parent or if it is a parent with no children, set the poolId and move to the next step
    if (
      inviteTokenData &&
      (!inviteTokenData.clientRequestPool?.isParent ||
        (inviteTokenData.clientRequestPool?.isParent &&
          inviteTokenData.clientRequestPool?.childClientRequestPool?.length === 0))
    ) {
      handlePoolSelected(inviteTokenData?.clientRequestPool?.id);
      form.setFieldValue('poolId', inviteTokenData?.clientRequestPool?.id);
      return;
    }

    setStep(1);
  }, [
    token,
    form,
    handlePoolSelected,
    isAuthorized,
    registrationError,
    clientRequest,
    isClientRequestCreating,
    registrationState,
    navigate,
    inviteTokenData,
  ]);

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      const values = await form.getFieldsValue(true);
      const { poolId } = values;
      if (step === 1) handlePoolSelected(poolId);
      if (step === 2) {
        const inviteToken =
          poolId === inviteTokenData?.clientRequestPool?.id
            ? token
            : inviteTokenData?.clientRequestPool?.childClientRequestPool
                ?.find((pool) => pool.id === poolId)
                ?.inviteTokens?.find((token) => token.type === ValidateInviteType.EndUser)?.token;

        dispatch(
          register({ ...values, inviteToken }, {
            type: ClientRequestTransitionTypes.created,
            clientRequest: {
              address: { country: { iso31661Alpha3: values.country! } },
            },
          } as IClientRequest),
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderContent = () => {
    switch (step) {
      case 1:
        return <PoolSelector onSubmit={handleSubmit} inviteToken={inviteTokenData!} />;
      case 2:
        return <RegistrationStep onSubmit={handleSubmit} />;
      default:
        return <Loader />;
    }
  };

  return (
    <>
      <Helmet>
        <title>Tendergy - Registration</title>
      </Helmet>
      <Form form={form} preserve>
        {renderContent()}
      </Form>
    </>
  );
};

export default RegistrationPage;
