import React, { useMemo } from 'react';

import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as videoCall } from 'assets/icons/videoCall.svg';
import Button, { ButtonColor, IconPosition } from 'components/ui/Button/Button';
import Icon from 'components/ui/Icon/Icon';
import { OfferState } from 'store/offer/offer.types';

import { ScheduleOffButtons } from './ScheduleOffButtons';
import styles from '../../OrderDetailsPage.module.sass';

interface IActionButtonsProps {
  onConfirm: () => void;
  suggestAppointmentsOff: () => void;
  isRemoteHomeCheck: boolean;
  isScheduleOffButtons: boolean;
  meetingId?: string;
  offerState?: string;
  onConfirmAppointmentClick?: () => void;
}

export const ActionButtons: React.FC<IActionButtonsProps> = (props) => {
  const {
    onConfirm,
    isRemoteHomeCheck,
    meetingId,
    isScheduleOffButtons,
    offerState,
    suggestAppointmentsOff,
    onConfirmAppointmentClick,
  } = props;
  const { t } = useTranslation();

  const buttonKey = useMemo(() => {
    switch (offerState) {
      case OfferState.TenderSubmittedHomeCheck:
      case OfferState.TenderSubmittedRemoteHomeCheck:
        return 'tenderSubmittedHomeCheck';
      case OfferState.OfferHomeCheckPending:
      case OfferState.OfferRemoteHomeCheckPending:
        return 'homecheckPending';
      case OfferState.TenderAcceptedHomeCheck:
      case OfferState.TenderAcceptedRemoteHomeCheck:
        return 'tenderAcceptedHomeCheck';
      default:
        return offerState;
    }
  }, [offerState]);

  const isAcceptAppointmentButton = useMemo(
    () =>
      offerState === OfferState.TenderAcceptedHomeCheck ||
      offerState === OfferState.TenderAcceptedRemoteHomeCheck ||
      offerState === OfferState.OfferSubmitted,
    [offerState],
  );

  return (
    <Row gutter={[16, 16]}>
      {isRemoteHomeCheck && (
        <Col span={10}>
          <Button
            type="primary"
            size="large"
            block={true}
            onClick={() => {
              window.open(`/home-check/installer/${meetingId}`, '_blank');
            }}
            iconPosition={IconPosition.LEFT}
            icon={
              <Icon icon={videoCall as any} width="30px" height="30px" className={styles.icon} />
            }
          >
            {t(`customerFlow:installation:waitingStep:videoCall:startButton`)}
          </Button>
        </Col>
      )}
      {isAcceptAppointmentButton && (
        <Col>
          <Button
            color={ButtonColor.BLUE}
            type="primary"
            size="large"
            onClick={onConfirmAppointmentClick}
          >
            {t(`installerFlow:orderDetails:confirmButton:acceptCustomerAppointment`)}
          </Button>
        </Col>
      )}
      {isScheduleOffButtons ? (
        <ScheduleOffButtons
          state={offerState}
          handleDirect={onConfirm}
          handleOff={suggestAppointmentsOff}
        />
      ) : (
        <Col>
          <Button
            color={ButtonColor.GREEN}
            type="primary"
            size="large"
            block={true}
            onClick={onConfirm}
          >
            {t(`installerFlow:orderDetails:confirmButton:${buttonKey}`)}
          </Button>
        </Col>
      )}
    </Row>
  );
};
