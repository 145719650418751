import React from 'react';
import Typography from 'antd/lib/typography';
import { useTranslation } from 'react-i18next';
import manWithWoman from 'assets/images/man-with-woman.svg';
import styles from './SurveyResultPage.module.sass';

const SurveyResultPage: React.FC = () => {
  const { t } = useTranslation(['survey']);
  return (
    <div className={styles.successBox}>
      <h3 className={styles.title}>{t('customerFlow:lead:successTitle')}</h3>

      <Typography.Paragraph className={styles.text}>
        {t('customerFlow:lead:successDescription')}
      </Typography.Paragraph>

      <img className={styles.manImage} src={manWithWoman} alt="Man with woman" />
    </div>
  );
};

export default SurveyResultPage;
