import { createSelector } from 'reselect';
import draftToHtml from 'draftjs-to-html';
import {
  CUSTOM_POOL_AVATAR_KEY,
  CUSTOM_POOL_DATA_PRIVACY_KEY,
  CUSTOM_POOL_IMPRINT_KEY,
} from 'framework/constants';
import { isMembershipDateNotOverdue } from 'framework/dateUtils';
import { IRegisterState } from './register.types';

export const registerSelector = (state: any) => state.register;

export const isRegistrationFetchingSelector = createSelector(
  registerSelector,
  (register: IRegisterState) => register.loading,
);
export const isRegistrationErrorSelector = createSelector(
  registerSelector,
  (register: IRegisterState) => register.error,
);
export const isRegistrationInviteTokenSelector = createSelector(
  registerSelector,
  (register: IRegisterState) => register.tokenData,
);

export const isTokenValidationLoadingSelector = createSelector(
  registerSelector,
  (register: IRegisterState) => register.loading,
);

export const invitePoolAvatarSelector = createSelector(
  registerSelector,
  (register: IRegisterState) => {
    const allowAvatar = register.tokenData?.clientRequestPool?.membership?.optionsIncluded?.some(
      (option: any) =>
        option.name === CUSTOM_POOL_AVATAR_KEY &&
        isMembershipDateNotOverdue(register.tokenData?.clientRequestPool?.membership),
    );

    return allowAvatar ? register.tokenData?.clientRequestPool?.poolAvatar : null;
  },
);

export const invitePoolDataPrivacySelector = createSelector(
  registerSelector,
  (register: IRegisterState) => {
    const allowDataPrivacy =
      register.tokenData?.clientRequestPool?.membership?.optionsIncluded?.some(
        (option: any) => option.name === CUSTOM_POOL_DATA_PRIVACY_KEY,
      ) && isMembershipDateNotOverdue(register.tokenData?.clientRequestPool?.membership);
    const value =
      register.tokenData?.clientRequestPool?.dataPrivacy &&
      draftToHtml(JSON.parse(register.tokenData.clientRequestPool.dataPrivacy));
    return allowDataPrivacy && value && value.trim() !== '<p></p>' ? value : null;
  },
);

export const invitePoolImprintSelector = createSelector(
  registerSelector,
  (register: IRegisterState) => {
    const allowImprint =
      register.tokenData?.clientRequestPool?.membership?.optionsIncluded?.some(
        (option: any) => option.name === CUSTOM_POOL_IMPRINT_KEY,
      ) && isMembershipDateNotOverdue(register.tokenData?.clientRequestPool?.membership);
    const value =
      register.tokenData?.clientRequestPool?.imprint &&
      draftToHtml(JSON.parse(register.tokenData.clientRequestPool.imprint));
    return allowImprint && value && value.trim() !== '<p></p>' ? value : null;
  },
);
