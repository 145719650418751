import React from 'react';
import { useTranslation } from 'react-i18next';

import task from 'assets/images/task.svg';
import styles from './EmptyData.module.sass';

export const EmptyData: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.waitingOffers}>
      <img src={task} alt="Man with task" className={styles.waitingImg} />
      <p>{t('intermediate:myProjects:offer:noOffers')}</p>
    </div>
  );
};
