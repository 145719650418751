import React, { FC } from 'react';
import { Switch } from 'antd';
import { PoolType } from 'types/pool-settings.types';

interface ISurveyToggleProps {
  value?: PoolType;
  onChange?: (type: PoolType) => void;
  disabled?: boolean;
}

const SurveyToggle: FC<ISurveyToggleProps> = ({ value, onChange, ...rest }) => {
  const handleSwitchChange = (checked: boolean) => {
    onChange?.(checked ? PoolType.SurveyPool : PoolType.RequestPool);
  };

  return <Switch value={value === PoolType.SurveyPool} onChange={handleSwitchChange} {...rest} />;
};

export default SurveyToggle;
