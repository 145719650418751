import React from 'react';
import { useSelector } from 'react-redux';
import { Form, FormInstance, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ValidationFactory } from 'framework/validations';
import EmailVerificationStep from 'pages/auth/EmailVerificationStep/EmailVerificationStep';
import { userSelector } from 'store/auth/auth.selectors';
import { userEmailStatusSelector } from 'store/user/user.selectors';

import styles from './JourneySelection.module.sass';
import JourneyOptions from '../../components/JourneyOptions/JourneyOptions';

interface IJourneySelectionProps {
  form: FormInstance;
}

const JourneySelection: React.FC<IJourneySelectionProps> = ({ form }) => {
  const emailVerified = useSelector(userEmailStatusSelector);
  const user = useSelector(userSelector);
  const { t } = useTranslation();

  if (!(emailVerified || user?.emailVerified)) {
    return <EmailVerificationStep email={user.email} />;
  }

  return (
    <div>
      <div className={styles.header}>
        <Typography.Title level={3}>
          {t('customerFlow:productWizard:selectionStep:title')}
        </Typography.Title>
        <Typography.Paragraph>
          {t('customerFlow:productWizard:selectionStep:description')}
        </Typography.Paragraph>
        <Typography.Paragraph>
          {t('customerFlow:productWizard:selectionStep:description2')}
        </Typography.Paragraph>
      </div>
      <Form form={form}>
        <Form.Item
          name="journeyAnswer"
          id="journeyAnswer"
          noStyle
          rules={[ValidationFactory.REQUIRED_OPTION]}
        >
          <JourneyOptions />
        </Form.Item>
      </Form>
    </div>
  );
};

export default JourneySelection;
