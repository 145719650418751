import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Tabs } from 'antd';
import BackButton from 'components/ui/BackButton/BackButton';
import { userSelector } from 'store/user/user.selectors';
import { userGet } from 'store/user/user.actions';
import { isCustomerSelector } from 'store/auth/auth.selectors';
import { myClientRequestGet } from 'store/client-request/client-request.actions';

import PersonalInfoTab from './Tabs/PersonalInfoTab';
import ChangePasswordTab from './Tabs/ChangePasswordTab';
import ChangeEmailTab from './Tabs/ChangeEmailTab';
import DeleteAccountTab from './Tabs/DeleteAccountTab';

const EditProfile: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(userSelector);
  const isCustomer = useSelector(isCustomerSelector);

  useEffect(() => {
    if (isCustomer) {
      dispatch(myClientRequestGet());
    }
  }, [dispatch, isCustomer]);

  useEffect(() => {
    dispatch(userGet());
  }, [dispatch]);

  const onCancel = () => {
    navigate(`/profile`);
  };

  const onBackButtonClick = useCallback(() => {
    navigate(`/profile`);
  }, []); // eslint-disable-line

  const commonTabProps = {
    user,
    onCancel,
  };

  const tabs = [
    {
      key: '1',
      label: t('profile:personalInfo'),
      children: <PersonalInfoTab {...commonTabProps} isCustomer={isCustomer} />,
    },
    {
      key: '2',
      label: t('profile:changeEmail'),
      children: <ChangeEmailTab {...commonTabProps} />,
    },
    {
      key: '3',
      label: t('profile:changePassword'),
      children: <ChangePasswordTab {...commonTabProps} />,
    },
    {
      key: '4',
      label: t('profile:DeleteAccount'),
      children: <DeleteAccountTab {...commonTabProps} />,
      disabled: !isCustomer,
    },
  ];

  return (
    <>
      <BackButton onClick={onBackButtonClick}>{t('common:buttons:back')}</BackButton>
      <Tabs tabPosition="left" items={tabs} />
    </>
  );
};

export default EditProfile;
