import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ImgCrop from 'antd-img-crop';

import { Button, ColorPicker, Select, Skeleton, Space, Typography, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { DEFAULT_COLOR } from 'framework/constants';
import {
  poolFilesSelector,
  allowPoolAvatarSelector,
  allowPoolAccentColorSelector,
  allowPoolFontSelector,
} from 'store/intermediate/intermediate.selectors';
import {
  poolAvatarSelector,
  poolAvatarStateSelector,
} from 'store/client-request/client-request.selectors';
import { IDocument, MediaCategoryType } from 'store/client-request/client-request.types';
import { poolFileUpload, poolFilesGet, poolUpdate } from 'store/intermediate/intermediate.actions';
import { IPool } from 'store/intermediate/intermediate.types';
import { getFileLink } from 'store/client-request/client-request.actions';

import { RequestState } from 'store/common.types';
import styles from './CustomPoolSettings.module.sass';

interface ICustomPoolSettingsProps {
  pool: IPool;
}

const CustomPoolSettings: React.FC<ICustomPoolSettingsProps> = ({ pool }) => {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState(pool?.customAccentColor || DEFAULT_COLOR);
  const [font, setFont] = useState(pool?.customFont || '');
  const [fontsList, setFontsList] = useState([]);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const poolFiles = useSelector(poolFilesSelector);
  const poolAvatar = useSelector(poolAvatarSelector);
  const poolAvatarState = useSelector(poolAvatarStateSelector);
  const allowPoolAvatar = useSelector(allowPoolAvatarSelector);
  const allowPoolAccentColor = useSelector(allowPoolAccentColorSelector);
  const allowPoolFont = useSelector(allowPoolFontSelector);

  useEffect(() => {
    if (poolFiles.length) {
      const avatarFile = poolFiles.find(
        (file: IDocument) => file.category === MediaCategoryType.PoolAvatar,
      );
      if (avatarFile) dispatch(getFileLink(avatarFile.name));
    }
  }, [dispatch, poolFiles]);

  useEffect(() => {
    // eslint-disable-next-line
    const googleApiToken = process.env.REACT_APP_GOOGLE_API_KEY;

    // It's third-party service so we need to avoid Redux flow here
    axios
      .get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${googleApiToken}`)
      .then((response: any) => {
        const fonts = response.data?.items;
        if (fonts) {
          setFontsList(fonts);
        }
      });
  }, []);

  const handleAvatarUpload = ({ file, onSuccess, onError }: UploadRequestOption) => {
    setLoading(true);
    dispatch(
      poolFileUpload(
        file as File,
        pool.id,
        MediaCategoryType.PoolAvatar,
        null,
        onSuccess,
        onError,
        () => dispatch(poolFilesGet(pool.id, MediaCategoryType.PoolAvatar)),
      ),
    );
    setLoading(false);
  };

  const updatePoolColor = () => {
    dispatch(poolUpdate(pool.id, { customAccentColor: color }));
    message.info(t('intermediate:myProjects:editProjectPools:poolSaved'));
  };

  const updatePoolFont = () => {
    setLoading(true);
    dispatch(poolUpdate(pool.id, { customFont: font }));
    setLoading(false);
    message.info(t('intermediate:myProjects:editProjectPools:poolSaved'));
  };

  const setDefaultPoolColor = () => {
    setColor(() => DEFAULT_COLOR);
  };

  const renderAvatar = () => {
    const isShowAvatar =
      !!poolFiles.length && poolAvatar && poolAvatarState === RequestState.Success;

    return (
      <div className={styles.poolAvatar}>
        <Typography.Paragraph className={styles.smallTitle}>
          {t('intermediate:myProjects:editProjectPools:poolAvatar')}
        </Typography.Paragraph>

        <div className={styles.imageContainer}>
          {isShowAvatar ? (
            <img className={styles.avatar} src={poolAvatar} alt="avatar" />
          ) : (
            <Skeleton.Image className={styles.avatar} />
          )}
        </div>
        <ImgCrop aspect={16 / 9}>
          <Upload
            maxCount={1}
            showUploadList={false}
            customRequest={handleAvatarUpload}
            accept=".png, .jpg, .jpeg"
          >
            <Button size="small" type="primary" loading={loading} icon={<UploadOutlined />}>
              {t('intermediate:myProjects:editProjectPools:uploadAvatar')}
            </Button>
          </Upload>
        </ImgCrop>
      </div>
    );
  };

  const renderPicker = () => {
    return (
      <div className={styles.poolColor}>
        <Space>
          <Typography.Paragraph className={styles.smallTitle}>
            {t('intermediate:myProjects:editProjectPools:poolColor')}
          </Typography.Paragraph>
          <div className={styles.pickerContainer}>
            <ColorPicker showText value={color} onChange={(_value, hex) => setColor(hex)} />
          </div>
        </Space>
        <div className={styles.buttonsColor}>
          <Button size="small" type="primary" onClick={updatePoolColor}>
            {t('intermediate:myProjects:editProjectPools:buttonSetupColor')}
          </Button>
          <Button size="small" type="primary" onClick={setDefaultPoolColor} danger>
            {t('intermediate:myProjects:editProjectPools:buttonResetColor')}
          </Button>
        </div>
      </div>
    );
  };

  const renderFontSelect = () => {
    return (
      <div style={{ marginBottom: 20 }}>
        <span style={{ display: 'inline-block', marginBottom: 6 }}>
          <span>{t('intermediate:myProjects:editProjectPools:poolFont')}</span>
        </span>
        <div>
          <Select
            style={{ width: '100%' }}
            defaultValue={font}
            onChange={(value) => setFont(value)}
          >
            <Select.Option value="">
              <b>{t('intermediate:myProjects:editProjectPools:defaultFont')}</b>
            </Select.Option>
            {fontsList.map((f: any) => (
              <Select.Option value={f.family} key={f.family}>
                {f.family}
              </Select.Option>
            ))}
          </Select>
        </div>

        <Button
          size="small"
          type="primary"
          style={{ marginTop: 10 }}
          onClick={updatePoolFont}
          block
        >
          {t('intermediate:myProjects:editProjectPools:updateFont')}
        </Button>
      </div>
    );
  };

  return (
    <div>
      {allowPoolFont ? renderFontSelect() : null}
      {allowPoolAvatar ? renderAvatar() : null}
      {allowPoolAccentColor ? renderPicker() : null}
    </div>
  );
};

export default CustomPoolSettings;
