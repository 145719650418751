import React, { useEffect } from 'react';
import { Checkbox, Form, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NumericInput from 'components/ui/NumberInput/NumericInput';
import MultiLangInput from 'components/ui/MultiLangEntries/MultiLangInput';
import MultiLangEditor from 'components/ui/MultiLangEntries/MultiLangEditor';
import { ValidationFactory } from 'framework/validations';
import SettingTitle from 'pages/MyProjects/CreatePool/SettingTitle';
import { validateActivePosibility } from 'store/product-component/product-components.actions';
import { isActivableProductComponentSelector } from 'store/product-component/product-component.selector';

interface IFormBodyProps {
  form?: FormInstance;
}

export const FormBody: React.FC<IFormBodyProps> = ({ form }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const key = 'intermediate:myProjects:productWizard:formFields:';
  const isActive = useSelector(isActivableProductComponentSelector);
  useEffect(() => {
    dispatch(validateActivePosibility(form?.getFieldValue('id')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <Form.Item
        label={<SettingTitle title={t(`${key}name:label`)} tooltip={t(`${key}name:tooltip`)} />}
      >
        {form && (
          <MultiLangInput fieldName="localizedNames" form={form} label={t(`${key}name:label`)} />
        )}
      </Form.Item>

      <Form.Item
        name="position"
        label={
          <SettingTitle title={t(`${key}position:label`)} tooltip={t(`${key}position:tooltip`)} />
        }
        rules={[ValidationFactory.REQUIRED]}
      >
        <NumericInput label={t(`${key}position:label`)} decimalScale={0} />
      </Form.Item>
      <Form.Item
        label={
          <SettingTitle
            title={t(`${key}description:label`)}
            tooltip={t(`${key}description:tooltip`)}
          />
        }
      >
        {form && (
          <MultiLangEditor
            fieldName="localizedDescriptions"
            form={form}
            rules={[ValidationFactory.EDITOR_REQUIRED]}
          />
        )}
      </Form.Item>

      <Form.Item initialValue={false} name="active" valuePropName="checked">
        <Checkbox disabled={!isActive}>{t('intermediate:myProjects:bundle:table:active')}</Checkbox>
      </Form.Item>
    </>
  );
};
