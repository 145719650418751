import React from 'react';
import { Checkbox, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import TextField from 'components/ui/TextField/TextField';
import { ValidationFactory } from 'framework/validations';

export const FormBody: React.FC = () => {
  const { t } = useTranslation();

  const FIELDS = [
    {
      name: 'roleDescription',
      label: t('profile:roleDescription'),
      rules: [ValidationFactory.REQUIRED],
    },
    {
      name: 'name',
      label: t('profile:contactPerson'),
      rules: [ValidationFactory.REQUIRED],
    },
    {
      name: 'email',
      label: t('profile:emailOptional'),
      rules: [ValidationFactory.EMAIL, ValidationFactory.ONE_FILLED('phone')],
    },
    {
      name: 'phone',
      label: t('profile:phoneOptional'),
      rules: [ValidationFactory.PHONE, ValidationFactory.ONE_FILLED('email')],
    },
  ];
  return (
    <>
      {FIELDS.map((field) => (
        <Form.Item name={field.name} key={field.name} rules={field.rules}>
          <TextField label={field.label} />
        </Form.Item>
      ))}
      <Form.Item name="active" valuePropName="checked" initialValue={false}>
        <Checkbox>{t('intermediate:myProjects:bundle:table:active')}</Checkbox>
      </Form.Item>
    </>
  );
};
