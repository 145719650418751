import { IOrganization, IPool } from 'store/intermediate/intermediate.types';

// Customer registration action types
export const REGISTER_CUSTOMER = 'REGISTER_CUSTOMER';
export const REGISTER_CUSTOMER_SUCCESS = 'REGISTER_CUSTOMER_SUCCESS';
export const REGISTER_CUSTOMER_ERROR = 'REGISTER_CUSTOMER_ERROR';

//Installer registration action types
export const REGISTER_INSTALLER = 'REGISTER_INSTALLER';
export const REGISTER_INSTALLER_SUCCESS = 'REGISTER_INSTALLER_SUCCESS';
export const REGISTER_INSTALLER_ERROR = 'REGISTER_INSTALLER_ERROR';

// Email verification action types
export const REGISTER_VERIFY_EMAIL = 'REGISTER_VERIFY_EMAIL';
export const REGISTER_VERIFY_EMAIL_SUCCESS = 'REGISTER_VERIFY_EMAIL_SUCCESS';
export const REGISTER_VERIFY_EMAIL_ERROR = 'REGISTER_VERIFY_EMAIL_ERROR';

// Validate invite token
export const VALIDATE_INVITE_TOKEN = 'VALIDATE_INVITE_TOKEN';
export const VALIDATE_INVITE_TOKEN_SUCCESS = 'VALIDATE_INVITE_TOKEN_SUCCESS';
export const VALIDATE_INVITE_TOKEN_ERROR = 'VALIDATE_INVITE_TOKEN_ERROR';

export interface IInstallerRegister {
  id?: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  gender: number;
  mobile: string;
  title: string;
  company: string;
  organization: Organization;
  fingerprint?: string;
}

export interface Organization {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedDate: Date | null;
  authId: string;
  name: string;
  evuConfirmed: boolean;
  address: {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    deletedDate: Date | null;
    street: string;
    houseNumber: string;
    address2: string | null;
    postalCode: string;
    city: string;
  };
}

export interface IInviteTokenData {
  expires: string | Date | null;
  id: string;
  token: string;
  type: string;
  clientRequestPool?: IPool;
  organization?: IOrganization;
  isEnabled?: boolean;
  inviteeData: {
    email: string;
    lastName: string;
    firstName: string;
  } | null;
}

export interface IRegisterState {
  loading: boolean;
  tokenData: null | IInviteTokenData;
  error?: string | null;
}
export interface RegisterInstallerAction {
  type: typeof REGISTER_INSTALLER;
  payload: {
    user: IInstallerRegister;
    token: string;
  };
}

export interface RegisterInstallerSuccessAction {
  type: typeof REGISTER_INSTALLER_SUCCESS;
  payload: any;
}

export interface RegisterInstallerErrorAction {
  type: typeof REGISTER_INSTALLER_ERROR;
  payload: any;
}

export interface ValidateInviteTokenAction {
  type: typeof VALIDATE_INVITE_TOKEN;
  payload: {
    token: string;
  };
}

export interface ValidateInviteTokenSuccessAction {
  type: typeof VALIDATE_INVITE_TOKEN_SUCCESS;
  payload: any;
}

export interface ValidateInviteTokenErrorAction {
  type: typeof VALIDATE_INVITE_TOKEN_ERROR;
  payload: any;
}

export enum ValidateInviteType {
  Installer = 'installer',
  EndUser = 'enduser',
  InstallerManager = 'installerManager',
  PublicInstallerManager = 'publicInstallerManager',
}

export const installerInviteTypes = [
  ValidateInviteType.Installer,
  ValidateInviteType.InstallerManager,
  ValidateInviteType.PublicInstallerManager,
];

export type RegisterInstallerActionTypes =
  | RegisterInstallerAction
  | RegisterInstallerErrorAction
  | RegisterInstallerSuccessAction
  | ValidateInviteTokenAction
  | ValidateInviteTokenSuccessAction
  | ValidateInviteTokenErrorAction;

export type RegisterActionTypes = RegisterInstallerActionTypes;
