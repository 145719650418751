import React, { useEffect } from 'react';
import Form from 'antd/lib/form';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';
import TextField from 'components/ui/TextField/TextField';
import BackButton from 'components/ui/BackButton/BackButton';
import { ValidationFactory, ValidationMessagesFactory } from 'framework/validations';
import { login } from 'store/auth/auth.actions';
import {
  isAuthorizedSelector,
  isUserFetchingSelector,
  loginStateSelector,
} from 'store/auth/auth.selectors';
import { RequestState } from 'store/common.types';
import styles from './LoginPage.module.sass';

type TFormValues = {
  email: string;
  password: string;
};

const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isUserFetching = useSelector(isUserFetchingSelector);
  const isAuthorized = useSelector(isAuthorizedSelector);
  const loginState = useSelector(loginStateSelector);

  useEffect(() => {
    if (!isUserFetching && isAuthorized) {
      navigate('/home', { replace: true });
    }
  }, [isUserFetching, isAuthorized, navigate]);

  const handleFormSubmit = async (values: TFormValues) => {
    dispatch(login(values));
  };

  const formProps = {
    validateMessages: ValidationMessagesFactory,
  };

  return (
    <div className={styles.container}>
      <div>
        <BackButton onClick={() => navigate(-1)}>{t('common:buttons:back')}</BackButton>
        <Typography.Title level={3}>{t('common:forms:login')}</Typography.Title>
        <Form {...formProps} onFinish={handleFormSubmit}>
          <Form.Item name="email" rules={[ValidationFactory.REQUIRED, ValidationFactory.EMAIL]}>
            <TextField label={t('common:forms:email')} />
          </Form.Item>

          <Form.Item name="password" rules={[ValidationFactory.REQUIRED]}>
            <TextField
              label={t('common:forms:password')}
              className={styles.password}
              type="password"
              visibilityToggle={true}
            />
          </Form.Item>
          <Link to="/customer/password-reset" className={styles.forgot}>
            {t('common:forms:forgotPassword')}
          </Link>
          <Form.Item>
            <Button
              type="primary"
              className={styles.submit}
              htmlType={'submit'}
              loading={loginState === RequestState.Loading}
            >
              <span className={styles.bold}>{t('common:buttons:login')}</span>
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginPage;
