import config from 'config';
import { IBundle } from 'store/bundle/bundle.types';
import HttpService from './http.service';
import { ISearchDto } from '../store/common.types';

class BundleAPI extends HttpService {
  createBundle = (poolId: string, data: IBundle) => {
    return this.post(`bundles/pool/${poolId}`, { ...data });
  };

  getBundles = (poolId: string, params?: ISearchDto) => {
    return this.get(`bundles/pool/${poolId}`, params);
  };

  updateBundle = (bundleId: string, data: IBundle) => {
    return this.put(`bundles/${bundleId}`, { ...data });
  };

  deleteBundle = (bundleId: string) => {
    return this.delete(`bundles/${bundleId}`);
  };
}

export default new BundleAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
