import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import ClientRequestKeyValue from 'components/ui/ClientRequestKeyValue/ClientRequestKeyValue';
import ELTOClientRequestKeyValue from 'components/ui/ClientRequestKeyValue/ELTOClientRequestKeyValue';
import { userSelector } from 'store/user/user.selectors';
import { isIntermediateManagerSelector } from 'store/auth/auth.selectors';
import { userGet } from 'store/user/user.actions';
import { OfferState, IOffer } from 'store/offer/offer.types';
import { IClientRequest } from 'store/client-request/client-request.types';
import { IStep } from 'store/intermediate/intermediate.types';

import styles from './SpecificationWidget.module.sass';

interface IAnswersViewProps {
  clientRequest: IClientRequest;
}

enum hideQuestionsType {
  SHOWAFTERACCEPT = 'showAfterOfferAccept',
  NO = 'no',
}

const AnswersView: React.FC<IAnswersViewProps> = ({ clientRequest }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const isIntermediateManager: boolean = useSelector(isIntermediateManagerSelector);

  useEffect(() => {
    dispatch(userGet());
  }, [dispatch]);

  const steps = clientRequest.questionnaire?.steps || [];

  if (!steps.length) {
    return <span>Answers not available</span>;
  }

  const checkELTOParams = () => {
    const isELTO = clientRequest?.questionnaire?.name === 'ELTO';
    const shouldCheckHideness = !isIntermediateManager;
    let offerAccepted = false;
    if (shouldCheckHideness) {
      const installerOffers = clientRequest.offers?.filter((offer: IOffer) => {
        return offer.installerUser?.displayId === user?.displayId;
      });
      installerOffers?.forEach((offer: IOffer) => {
        if (shouldCheckHideness)
          switch (offer?.state) {
            case OfferState.OfferAcceptedHomeCheck:
            case OfferState.OfferAcceptedRemoteHomeCheck:
            case OfferState.OfferAcceptedHomeCheckAppointmentNA:
            case OfferState.OfferAcceptedRemoteHomeCheckAppointmentNA:
            case OfferState.OfferAcceptedHomeCheckAppointmentTBD:
            case OfferState.OfferAcceptedRemoteHomeCheckAppointmentTBD:
            case OfferState.OfferAccepted:
            case OfferState.OfferSubmitted:
            case OfferState.InstallationPending:
            case OfferState.InvoiceSubmittedInstallation:
            case OfferState.InvoiceAcceptedInstallation:
            case OfferState.InstallationDone:
              offerAccepted = true;
              break;
          }
      });
    }
    return { isELTO, shouldCheckHideness, offerAccepted };
  };

  const { isELTO, shouldCheckHideness, offerAccepted } = checkELTOParams();

  const renderAnswers = (questionKey: string, index: number, subStep: IStep) => {
    const uiElement = subStep.uiSchema.elements.find(
      (item: any) => item.scope === `#/properties/${questionKey}`,
    );
    const hide = uiElement.showQuestionToInstaller;
    const showAfterOfferAccept = hide?.criteria === hideQuestionsType.SHOWAFTERACCEPT;
    const dontShow = hide?.criteria === hideQuestionsType.NO;
    const hideForInstaller =
      !isIntermediateManager && (dontShow || (showAfterOfferAccept && !offerAccepted));
    if (isIntermediateManager || !hideForInstaller) {
      return !isELTO ? (
        <ClientRequestKeyValue
          key={`spec-question-${questionKey}`}
          questionKey={questionKey}
          clientRequest={clientRequest}
          uiSchema={uiElement}
        />
      ) : (
        <ELTOClientRequestKeyValue
          key={`spec-question-${questionKey}`}
          questionKey={questionKey}
          shouldCheckHideness={shouldCheckHideness}
          clientRequest={clientRequest}
          schema={subStep.schema.properties[questionKey]}
          uiSchema={uiElement}
        />
      );
    }
    return null;
  };

  const renderSubSteps = (subStep: any, step: any) => {
    const subStepTitle = `${step.order}-${subStep.order} : ${t(subStep.title)}`;
    const questionsKeys = Object.keys(subStep.schema.properties);

    return (
      <div key={subStepTitle}>
        <span className={styles.blockTitle}>{subStepTitle}</span>
        <div className={styles.list}>
          {questionsKeys.map((questionKey, index) => renderAnswers(questionKey, index, subStep))}
        </div>
      </div>
    );
  };

  const renderSteps = () =>
    steps.map((step) => {
      const title = `${step.order} : ${t(step.title)}`;

      return (
        <div className={styles.block} key={step.id}>
          <span className={styles.blockTitle}>{title}</span>
          {step.children.map((subStep) => renderSubSteps(subStep, step))}
        </div>
      );
    });

  return <div>{renderSteps()}</div>;
};

export default AnswersView;
