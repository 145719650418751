import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useNavigate } from 'react-router-dom';
import OfferTag from 'components/ui/OfferTag/OfferTag';
import Tag from 'components/ui/Tag/Tag';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import TableArrow from 'components/ui/TableArrow/TableArrow';
import { IOffer, OfferState } from 'store/offer/offer.types';
import { IClientRequest } from 'store/client-request/client-request.types';
import { getUserFullName } from 'utils/helpers';
import { NOT_APPLICABLE_ABBR } from '../../../framework/constants';
interface IOffersWidgetProps {
  clientRequest: IClientRequest;
}
const OffersWidget = ({ clientRequest }: IOffersWidgetProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleRowClick = (offer: IOffer) => navigate(`/installer/order/${offer.id}/details`);
  const OfferTableColumns: ColumnsType<IOffer> = [
    {
      title: t('installerFlow:dashboard:orderNr'),
      dataIndex: 'displayId',
      key: 'displayId',
      render: (_: string, row: IOffer) => `${clientRequest.displayId} / ${row.displayId}`,
    },
    {
      title: t('installerFlow:dashboard:pool'),
      key: 'poolName',
      render: (_: string, row: IOffer) => clientRequest.pool?.name,
    },
    {
      title: t('installerFlow:dashboard:name'),
      key: 'createdBy',
      render: (_: string, row: IOffer) => getUserFullName(clientRequest?.createdBy),
    },
    {
      title: t('installerFlow:dashboard:installerName'),
      key: 'installerUser',
      render: (_: string, row: IOffer) => getUserFullName(row.installerUser),
    },
    {
      title: t('installerFlow:dashboard:progress'),
      dataIndex: 'state',
      key: 'state',
      render: (status: OfferState) =>
        status ? <OfferTag state={status}>{status}</OfferTag> : <Tag>{NOT_APPLICABLE_ABBR}</Tag>,
    },
    {
      title: t('installerFlow:dashboard:zip'),
      dataIndex: 'zip',
      render: (_: string, row: IOffer) => {
        return clientRequest?.address?.postalCode ?? NOT_APPLICABLE_ABBR;
      },
    },
    {
      title: t('installerFlow:dashboard:dueDate'),
      dataIndex: 'date',
      key: 'date',
      render: (_: string, row: IOffer) => {
        // if finalInstallationTimestamp is present, it means home-check's already done or was not needed.
        const date = row.finalInstallationTimestamp || row.finalHomeCheckTimestamp || '';
        return date ? <DateFormat date={date} /> : '';
      },
    },
    {
      title: '',
      key: 'action',
      dataIndex: 'id',
      render: (_: string, row: IOffer) => <TableArrow offer={row} />,
    },
  ];
  return (
    <Table
      onRow={(item: IOffer) => ({
        onClick: () => handleRowClick(item),
      })}
      columns={OfferTableColumns}
      dataSource={clientRequest.offers}
      pagination={false}
    ></Table>
  );
};
export default OffersWidget;
