import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'antd/lib/modal';
import DropZone from 'components/ui/DropZone/DropZone';
import { MediaCategoryType } from 'store/client-request/client-request.types';
import { offerFileUpload } from 'store/offer/offer.actions';
import styles from './UploadDocumentModal.module.sass';
import ModalContainer from '../ModalContainer';

interface Props extends ModalProps {
  offerId?: string;
  visible: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  onSuccess: () => void;
}

const category = MediaCategoryType.OfferIntermediateOther;
const UploadDocumentModal: React.FC<Props> = ({
  offerId,
  onSubmit,
  onCancel,
  onSuccess,
  visible,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onFileAddedSuccess = () => {
    setLoading(false);
    onSuccess();
  };

  const onFileAddedError = () => {
    setLoading(false);
  };

  const onFileAdded = ([file]: File[]) => {
    if (file && offerId) {
      setLoading(true);

      dispatch(
        offerFileUpload(offerId, category, file, null, onFileAddedSuccess, onFileAddedError),
      );
    }
  };

  const modalProps = {
    visible,
    onCancel,
    onSubmit,
  };

  const dropZoneProps = {
    loading,
    multiple: true,
    acceptedFiles: 'PDF ',
    onFileDropAccepted: onFileAdded,
  };

  return (
    <ModalContainer {...modalProps}>
      <div className={styles.container}>
        <h1 className={styles.title}>{t('intermediate:myProjects:uploadDocumentModal:title')}</h1>
        <DropZone {...dropZoneProps} />
      </div>
    </ModalContainer>
  );
};

export default UploadDocumentModal;
