import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Checkbox, Row, Col, Avatar, Typography, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import ReCAPTCHA from 'react-google-recaptcha';
import TextField from 'components/ui/TextField/TextField';
import { popoverValidations, ValidationFactory } from 'framework/validations';
import {
  GOOGLE_CAPTCHA_SITE_KEY,
  MODAL_DATA_PROTECTION_KEY,
  MODAL_TERMS_KEY,
} from 'framework/constants';
import { isRegistrationInviteTokenSelector } from 'store/register/register.selectors';
import {
  CustomDocumentsCategoryType,
  CustomDocumentTextType,
  ICustomDocument,
} from 'store/intermediate/intermediate.types';

import { customerRegistrationSelector } from 'store/auth/auth.selectors';
import { RequestState } from 'store/common.types';
import styles from '../../RegistrationPage.module.sass';

enum FieldType {
  INPUT = 'input',
  DROPDOWN = 'dropDown',
}

interface IFormProps {
  openModal(modalType: string): void;
  legalDocuments: ICustomDocument[];
  onSubmit: () => void;
}

const RegistrationForm: React.FC<IFormProps> = ({ openModal, legalDocuments, onSubmit }) => {
  const { t, i18n } = useTranslation();
  const [validations, setValidations] = useState(popoverValidations);
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const inviteTokenData = useSelector(isRegistrationInviteTokenSelector);
  const { registrationState, registrationError } = useSelector(customerRegistrationSelector);
  const organization = inviteTokenData?.clientRequestPool?.intermediateOrganization;

  const dataPrivacy = legalDocuments.find(
    (item) =>
      item.category === CustomDocumentsCategoryType.PoolPrivacyPolicy &&
      item.textType === CustomDocumentTextType.LINK,
  );

  const terms = legalDocuments.find(
    (item) =>
      item.category === CustomDocumentsCategoryType.PoolTermsConditions &&
      item.textType === CustomDocumentTextType.LINK,
  );

  useEffect(() => {
    if (
      registrationState !== RequestState.Loading &&
      registrationError &&
      !registrationError.response?.data?.code.includes('TENDERGY_CAPCHA_INVALID')
    ) {
      recaptchaRef?.current?.reset();
    }
  }, [registrationState, registrationError, recaptchaRef]);

  const passwordRepeatValidator = useCallback(
    ({ getFieldValue }: any) => ({
      validator(rule: any, value: string) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject(t('common:errors:passwordsNotSame'));
      },
    }),
    [t],
  );

  const checkPassword = useCallback(
    (event: any) => {
      const { value } = event.target;

      const checkedValidations = validations.map((item) => ({
        ...item,
        checked: item.validator(value),
      }));

      setValidations(checkedValidations);
    },
    [validations],
  );

  const FIELDS = useMemo(
    () => [
      {
        type: FieldType.INPUT,
        width: 12,
        name: 'firstName',
        rules: [
          ValidationFactory.REQUIRED,
          ValidationFactory.MAX_LENGTH,
          ValidationFactory.NOT_ONLY_WHITE_SPACE,
        ],
        trigger: 'onInput',
        label: t('customerFlow:registration:firstName'),
      },
      {
        type: FieldType.INPUT,
        width: 12,
        name: 'lastName',
        rules: [
          ValidationFactory.REQUIRED,
          ValidationFactory.MAX_LENGTH,
          ValidationFactory.NOT_ONLY_WHITE_SPACE,
        ],
        trigger: 'onInput',
        label: t('customerFlow:registration:lastName'),
      },
      {
        type: FieldType.INPUT,
        name: 'email',
        rules: [ValidationFactory.REQUIRED, ValidationFactory.EMAIL],
        trigger: 'onInput',
        label: t('common:forms:email'),
      },
      {
        type: FieldType.INPUT,
        name: 'password',
        inputType: 'password',
        rules: [
          ValidationFactory.REQUIRED,
          ValidationFactory.PASSWORD,
          ValidationFactory.PASSWORD_MIN_LENGTH,
        ],
        trigger: 'onInput',
        label: t('customerFlow:registration:setPassword'),
        onInput: checkPassword,
      },
      {
        type: FieldType.INPUT,
        name: 'repeat-password',
        inputType: 'password',
        dependencies: ['password'],
        rules: [ValidationFactory.REQUIRED, passwordRepeatValidator],
        trigger: 'onInput',
        label: t('customerFlow:registration:repeatPassword'),
      },
    ],
    [t, checkPassword, passwordRepeatValidator],
  );

  const handleModal = (type: string) => (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    openModal(type);
  };

  return (
    <>
      <div className={styles.container}>
        <Typography.Title level={4}>{t('customerFlow:registration:title')}</Typography.Title>
        <Typography.Paragraph className={styles.subtitle}>
          {t('customerFlow:registration:description')}
        </Typography.Paragraph>
        <Row gutter={[16, 0]}>
          {FIELDS.map((field) => (
            <Col span={field.width ?? 24} key={`customer-registration-field-${field.name}`}>
              <Form.Item
                name={field.name}
                rules={field.rules}
                trigger={field.trigger}
                dependencies={field.dependencies}
              >
                <TextField
                  label={field.label}
                  type={field.inputType || 'text'}
                  onInput={field.onInput}
                />
              </Form.Item>
            </Col>
          ))}
          <Col span={24}>
            <Form.Item name="recaptchaToken" rules={[ValidationFactory.REQUIRED]}>
              <ReCAPTCHA
                key={i18n.language}
                ref={recaptchaRef}
                sitekey={GOOGLE_CAPTCHA_SITE_KEY!}
                hl={i18n.language}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="terms"
              rules={[ValidationFactory.CHECKBOX_REQUIRED]}
              valuePropName="checked"
            >
              <Checkbox className={styles.checkbox}>
                <span>{t('customerFlow:registration:termsText1')} </span>
                {terms ? (
                  <a
                    href={terms.text}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.bold}
                  >
                    {t('common:texts:termsAndConditions')}
                  </a>
                ) : (
                  <span
                    className={clsx(styles.openModalButton, styles.bold)}
                    onClick={handleModal(MODAL_TERMS_KEY)}
                  >
                    {t('common:texts:termsAndConditions')}
                  </span>
                )}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="dataPrivacy"
              rules={[ValidationFactory.CHECKBOX_REQUIRED]}
              valuePropName="checked"
            >
              <Checkbox className={styles.checkbox}>
                <span> {t('customerFlow:registration:termsText1')} </span>
                {dataPrivacy ? (
                  <a
                    href={dataPrivacy.text}
                    className={styles.bold}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('common:texts:dataPrivacy')}
                  </a>
                ) : (
                  <span
                    className={clsx(styles.openModalButton, styles.bold)}
                    onClick={handleModal(MODAL_DATA_PROTECTION_KEY)}
                  >
                    {t('common:texts:dataPrivacy')}
                  </span>
                )}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Button
          type="primary"
          onClick={onSubmit}
          className={styles.submit}
          loading={registrationState === RequestState.Loading}
        >
          {t('customerFlow:registration:submit')}
        </Button>
      </div>
      {organization && (
        <div className={styles.organizationContainer}>
          <div>
            <span className={styles.header}>{t('customerFlow:registration:bannerText1')}</span>
            <Typography.Title level={5}>{organization.name}</Typography.Title>
          </div>
          <div>
            {organization.logo ? (
              <img
                className={styles.organizationLogo}
                src={organization.logo}
                alt="logo"
                width="64"
                height="64"
              />
            ) : (
              <Avatar
                shape="square"
                className={styles.organizationLogo}
                size={64}
                icon={<UserOutlined />}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default RegistrationForm;
