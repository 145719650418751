import WallboxIcon from 'assets/icons/wallbox.svg';
import ReqeuestIcon from 'assets/icons/request.svg';
import OfferIcon from 'assets/icons/offer.svg';
import InstallationIcon from 'assets/icons/install.svg';

import AmountIcon from 'assets/icons/product-attributes/amount.svg';
import CableLengthIcon from 'assets/icons/product-attributes/cable-length.svg';
import CableIcon from 'assets/icons/product-attributes/cable.svg';
import EnergyMeterIcon from 'assets/icons/product-attributes/energy-meter.svg';
import InternetConnectionIcon from 'assets/icons/product-attributes/internet-connection.svg';
import PlugTypeIcon from 'assets/icons/product-attributes/plug-type.svg';
import PowerIcon from 'assets/icons/product-attributes/power.svg';
import ProtectionIcon from 'assets/icons/product-attributes/protection.svg';
import ProtocolIcon from 'assets/icons/product-attributes/protocol.svg';
import SubsidyIcon from 'assets/icons/product-attributes/subsidy.svg';

export const journeyOptions = [
  {
    icon: WallboxIcon,
    title: 'wallbox',
  },
  {
    icon: ReqeuestIcon,
    title: 'request',
  },
  {
    icon: OfferIcon,
    title: 'offer',
  },
  {
    icon: InstallationIcon,
    title: 'installation',
  },
];

export const productAttributes = [
  {
    icon: PowerIcon,
    title: 'chargingPower',
  },
  {
    icon: AmountIcon,
    title: 'amountOfChargingPoints',
  },
  {
    icon: CableIcon,
    title: 'cableIntegrated',
  },
  {
    icon: CableLengthIcon,
    title: 'cableLength',
  },
  {
    icon: PlugTypeIcon,
    title: 'plugType',
  },
  {
    icon: ProtectionIcon,
    title: 'accessProtection',
  },
  {
    icon: EnergyMeterIcon,
    title: 'energyMeter',
  },
  {
    icon: InternetConnectionIcon,
    title: 'internetConnection',
  },
  {
    icon: ProtocolIcon,
    title: 'protocols',
  },
  {
    icon: SubsidyIcon,
    title: 'subsidy',
  },
];
