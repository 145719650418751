import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnsType } from 'antd/lib/table';
import { FormInstance } from 'antd';
import { CrudView } from 'components/layout/CrudView/CrudView';
import { bundleCreate, bundleDelete, bundlesGet, bundleUpdate } from 'store/bundle/bundle.actions';
import { currentBundlesSelector } from 'store/bundle/bundle.selectors';
import { IPool } from 'store/intermediate/intermediate.types';
import { IBundle, IBundleComponent } from 'store/bundle/bundle.types';
import { EntityAction, RequestResult } from 'types/crud-view.types';
import { formatFormData, formatPayload, renderComponentsCol } from 'utils/bundle';
import { FormBody } from './FormBody';

interface IProductBundlesSettingProps {
  pool: IPool;
}

const ProductBundlesSetting: React.FC<IProductBundlesSettingProps> = ({ pool }) => {
  const { t } = useTranslation();
  const [form, setForm] = useState<FormInstance>();
  const bundles = useSelector(currentBundlesSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(bundlesGet(pool.id));
  }, [pool.id, dispatch]);

  const onActionClick = (
    action: EntityAction,
    data: IBundle,
    onRequestDone?: (status: RequestResult) => void,
  ) => {
    switch (action) {
      case EntityAction.Delete:
        dispatch(bundleDelete(data.id, onRequestDone));
        break;
      case EntityAction.Edit:
        dispatch(bundleUpdate(data.id, formatPayload(data), onRequestDone));
        break;
      case EntityAction.Create:
        dispatch(bundleCreate(pool.id, formatPayload(data), onRequestDone));
    }
  };

  const Columns: ColumnsType<IBundle> = [
    {
      title: t('intermediate:myProjects:bundle:table:name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('intermediate:myProjects:bundle:table:components'),
      dataIndex: 'components',
      key: 'components',
      ellipsis: {
        showTitle: false,
      },
      render: (components: IBundleComponent[]) => renderComponentsCol(components),
    },
    {
      title: t('intermediate:myProjects:bundle:table:active'),
      dataIndex: 'active',
      key: 'active',
      render: (active) =>
        t(`intermediate:myProjects:bundle:table:${active ? 'active' : 'inactive'}`),
    },
  ];
  const modalProps = {
    onSubmit: onActionClick,
    setForm: (value: FormInstance) => setForm(value),
    width: 1000,
    formBody: <FormBody pool={pool} form={form} />,
  };

  return (
    <CrudView<IBundle>
      columns={Columns}
      entries={bundles}
      updateEntryFormat={formatFormData}
      locizeKey="intermediate:myProjects:bundle"
      modalProps={modalProps}
      actionDisabled={(row: IBundle) => (row.clientRequests?.length ? true : false)}
    />
  );
};

export default ProductBundlesSetting;
