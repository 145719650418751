import { useEffect, useState } from 'react';
import { flatten } from 'lodash';
import { IClientRequestAnswer, QuestionsType } from 'store/client-request/client-request.types';
import { IQuestionnaire } from 'store/intermediate/intermediate.types';
import { QuestionnaireStepType } from 'types/questionnaire.types';
import {
  excludeQuestionnaireSteps,
  extractInputValue,
  extractRadioLabel,
} from 'utils/questionnaire';

type Result = {
  questionLabel: string;
  answerValueLabel: string | { label: string; value: string }[];
  questionKey: string;
};

const useAnswersLabels = (
  answers?: IClientRequestAnswer[],
  questionnaire?: IQuestionnaire,
): Result[] => {
  const [value, setValue] = useState<Result[]>([]);

  useEffect(() => {
    if (!answers?.length || !questionnaire) return;
    const substeps = flatten(
      questionnaire.steps
        .filter(
          (step) => !excludeQuestionnaireSteps.includes(step.stepType as QuestionnaireStepType),
        )
        .map((step) => flatten(step.children.map((substep) => substep.uiSchema.elements))),
    );
    let values: Result[] = [];
    substeps.forEach((item) => {
      const answer = answers.find((answer) => `#/properties/${answer.questionKey}` === item.scope);
      if (!answer) return;
      switch (item.options.format!) {
        case QuestionsType.RadioQuestion:
        case QuestionsType.RadioWithCustomInputQuestion:
          values.push({
            questionKey: answer.questionKey,
            ...extractRadioLabel(answer.answerValue.value, item.options),
          });
          break;
        case QuestionsType.MultipleInputQuestion:
        case QuestionsType.SingleInputQuestion:
          values.push({
            questionKey: answer.questionKey,
            ...extractInputValue(answer.answerValue.value, item.options),
          });
      }
    });
    setValue(values);
  }, [answers, questionnaire]);

  return value;
};

export default useAnswersLabels;
