import React, { useEffect } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { CrudView } from 'components/layout/CrudView/CrudView';
import { IContactPerson } from 'store/contact-person/contact-person.types';
import {
  contactPersonCreate,
  contactPersonDelete,
  contactPersonsGet,
  contactPersonUpdate,
} from 'store/contact-person/contact-person.actions';
import { currentContactPersonsSelector } from 'store/contact-person/contact-person.selectors';
import { EntityAction } from 'types/crud-view.types';
import { FormBody } from './FormBody';

interface IContactPersonsProps {
  poolId: string;
}

const ContactPersons: React.FC<IContactPersonsProps> = ({ poolId }) => {
  const { t } = useTranslation();
  const persons = useSelector(currentContactPersonsSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    getPersons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolId]);

  const Columns: ColumnsType<IContactPerson> = [
    {
      title: t('profile:roleDescription'),
      dataIndex: 'roleDescription',
      key: 'roleDescription',
    },
    {
      title: t('profile:name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('profile:phone'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: t('profile:email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('intermediate:myProjects:bundle:table:active'),
      dataIndex: 'active',
      key: 'active',
      render: (_, { active }) => <>{active && <CheckOutlined />}</>,
      align: 'center',
    },
  ];

  const getPersons = () => {
    dispatch(contactPersonsGet(poolId));
  };

  const onActionClick = (action: EntityAction, data: IContactPerson, onSuccess?: Function) => {
    const payload = {
      ...data,
      email: data.email?.trim() || undefined,
      phone: data.phone?.trim() || undefined,
    };
    try {
      switch (action) {
        case EntityAction.Create:
          dispatch(contactPersonCreate(poolId, payload, getPersons));
          break;
        case EntityAction.Edit:
          dispatch(contactPersonUpdate(data.id, payload, getPersons));
          break;
        case EntityAction.Delete:
          dispatch(contactPersonDelete(data.id));
      }
    } catch (e) {
      notification.error({ message: t('common:error:errors.somethingWrong') });
    }
    onSuccess && onSuccess();
  };

  const modalProps = {
    onSubmit: onActionClick,
    formBody: <FormBody />,
  };

  return (
    <CrudView<IContactPerson>
      columns={Columns}
      entries={persons}
      locizeKey="intermediate:myProjects:contactPersons"
      modalProps={modalProps}
      isMaxEntries={persons.length > 4}
      maxKey={'contactPersons'}
    />
  );
};

export default ContactPersons;
