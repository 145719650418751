import React, { useMemo } from 'react';
import { Checkbox, Col, Descriptions, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd/lib';
import NumericInput from 'components/ui/NumberInput/NumericInput';
import MultiLangInput from 'components/ui/MultiLangEntries/MultiLangInput';
import { ValidationFactory } from 'framework/validations';
import { IPool } from 'store/intermediate/intermediate.types';

import styles from '../../Products.module.sass';
import { getTotalPrice } from '../../../../../../../../utils/product';

interface IPricingProps {
  pool: IPool;
  form?: FormInstance;
}

const key = 'intermediate:myProjects:product:form:pricing:';

const Pricing: React.FC<IPricingProps> = ({ pool, form }) => {
  const { t } = useTranslation();
  const netPriceWatcher = Form.useWatch('netPrice', form);
  const grossPrice = useMemo(() => {
    return getTotalPrice(pool.country.vatRate.toString(), netPriceWatcher);
  }, [netPriceWatcher, pool]);

  return (
    <>
      <Form.Item initialValue={false} name="onRequestPrice" valuePropName="checked">
        <Checkbox>{t('intermediate:myProjects:product:form:pricing:onRequest:label')}</Checkbox>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) =>
          getFieldValue('onRequestPrice') ? (
            <Form.Item label={t(`${key}priceDesctiption:label`)}>
              {form && (
                <MultiLangInput
                  fieldName="localizedOnRequestPriceTitles"
                  label={t(`${key}priceDesctiption:label`)}
                  rules={[ValidationFactory.REQUIRED]}
                  form={form}
                />
              )}
            </Form.Item>
          ) : (
            <>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item
                    name={'netPrice'}
                    rules={[ValidationFactory.REQUIRED]}
                    label={t(`${key}netPrice:label`)}
                  >
                    <NumericInput
                      placeholder={t(`${key}netPrice:placeholder`)}
                      className={styles.input}
                      min={0}
                      regularHeight
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Descriptions>
                  <Descriptions.Item label={t(`intermediate:myProjects:bundle.bundleCurrency`)}>
                    {pool.country.currency}
                  </Descriptions.Item>
                  <Descriptions.Item label={t(`${key}tax:label`)}>
                    {pool.country.vatRate}
                  </Descriptions.Item>
                  <Descriptions.Item label={t(`${key}totalPrice:label`)}>
                    {grossPrice}
                  </Descriptions.Item>
                </Descriptions>
              </Row>
            </>
          )
        }
      </Form.Item>
    </>
  );
};

export default Pricing;
