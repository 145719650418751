import React from 'react';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import Rectangle from 'components/layout/Rectangle/Rectangle';
import { IClientRequest } from 'store/client-request/client-request.types';
import styles from './TenderParticipate.module.sass';

interface IProps {
  clientRequest: IClientRequest | null;
}

const IntermediateDetailsWidget: React.FC<IProps> = ({ clientRequest }) => {
  const { t } = useTranslation();

  const organizationData = {
    name: clientRequest?.pool?.intermediateOrganization?.name || 'N/A',
    address: clientRequest?.pool?.intermediateOrganization?.address || null,
  };

  return (
    <Rectangle
      title={t('installerFlow:tenderParticipate:intermediateDetailsTitle')}
      className={styles.poolData}
    >
      <p>
        <b>Name: </b> <span>{organizationData.name}</span>
      </p>
      <p>
        <b>{t('installerFlow:tenderParticipate:address')}: </b>{' '}
        <span>
          {organizationData.address?.city}, {organizationData.address?.street}{' '}
          {organizationData.address?.houseNumber}, {organizationData.address?.postalCode}
        </span>
      </p>
    </Rectangle>
  );
};

export default IntermediateDetailsWidget;
