import React, { FC } from 'react';

interface ITooltipProps {
  t: any;
}

export const InstallationSelectionModeTooltip: FC<ITooltipProps> = ({ t }) => (
  <div>
    <p>{t('intermediate:myProjects:poolSettings:installationSelectionMode:annotation:title')}</p>
    <strong>{t('intermediate:myProjects:poolSettings:installationSelectionMode:manual')}</strong>
    <p>{t('intermediate:myProjects:poolSettings:installationSelectionMode:annotation:manual')}</p>
    <strong>
      {t('intermediate:myProjects:poolSettings:installationSelectionMode:annotation:rangeTitle2')}
    </strong>
    <p>{t('intermediate:myProjects:poolSettings:installationSelectionMode:annotation:range')}</p>
    <strong>
      {t('intermediate:myProjects:poolSettings:installationSelectionMode:unrestricted')}
    </strong>
    <p>
      {t('intermediate:myProjects:poolSettings:installationSelectionMode:annotation:unrestricted')}
    </p>
  </div>
);

export const RequestValidityTooltip: FC<ITooltipProps> = ({ t }) => (
  <div>
    <p>{t('intermediate:myProjects:poolSettings:installationSelectionMode:annotation:title')}</p>
    <strong>{t('intermediate:myProjects:poolSettings:installationSelectionMode:manual')}</strong>
    <p>{t('intermediate:myProjects:poolSettings:installationSelectionMode:annotation:manual')}</p>
    <strong>
      {t('intermediate:myProjects:poolSettings:installationSelectionMode:annotation:rangeTitle2')}
    </strong>
    <p>{t('intermediate:myProjects:poolSettings:installationSelectionMode:annotation:range')}</p>
    <strong>
      {t('intermediate:myProjects:poolSettings:installationSelectionMode:unrestricted')}
    </strong>
    <p>
      {t('intermediate:myProjects:poolSettings:installationSelectionMode:annotation:unrestricted')}
    </p>
  </div>
);

export const RequestSettingTooltip: React.FC<ITooltipProps> = ({ t }) => (
  <div>
    <strong>{t('intermediate:myProjects:poolSettings:requireRequestConfirmation:yes')}</strong>
    <p>{t('intermediate:myProjects:poolSettings:requireRequestConfirmation:annotation:yes')}</p>
    <strong>{t('intermediate:myProjects:poolSettings:requireRequestConfirmation:no')}</strong>
    <p>{t('intermediate:myProjects:poolSettings:requireRequestConfirmation:annotation:no')}</p>
  </div>
);

export const HomeCheckOfferApprovalTooltip: React.FC<ITooltipProps> = ({ t }) => (
  <div>
    <p>{t('intermediate:myProjects:poolSettings:homeCheckOfferApproval:annotation:title')}</p>
    <strong>
      {t('intermediate:myProjects:poolSettings:homeCheckOfferApproval:replaceByIntermediate')}
    </strong>
    <p>
      {t(
        'intermediate:myProjects:poolSettings:homeCheckOfferApproval:annotation:replaceByIntermediate',
      )}
    </p>
    <strong>
      {t('intermediate:myProjects:poolSettings:homeCheckOfferApproval:payByIntermediate')}
    </strong>
    <p>
      {t(
        'intermediate:myProjects:poolSettings:homeCheckOfferApproval:annotation:payByIntermediate',
      )}
    </p>
  </div>
);

export const InstallationOfferApprovalTooltip: React.FC<ITooltipProps> = ({ t }) => (
  <div>
    <p>{t('intermediate:myProjects:poolSettings:installationOfferApproval:annotation:title')}</p>
    <strong>
      {t('intermediate:myProjects:poolSettings:installationOfferApproval:replaceByIntermediate')}
    </strong>
    <p>
      {t(
        'intermediate:myProjects:poolSettings:installationOfferApproval:annotation:replaceByIntermediate',
      )}
    </p>
    <strong>
      {t('intermediate:myProjects:poolSettings:installationOfferApproval:payByIntermediate')}
    </strong>
    <p>
      {t(
        'intermediate:myProjects:poolSettings:installationOfferApproval:annotation:payByIntermediate',
      )}
    </p>
  </div>
);

export const InstallationOfferInvoiceTooltip: React.FC<ITooltipProps> = ({ t }) => (
  <div>
    <p>{t('intermediate:myProjects:poolSettings:installationInvoiceApproval:annotation:title')}</p>
    <strong>
      {t('intermediate:myProjects:poolSettings:installationInvoiceApproval:replaceByIntermediate')}
    </strong>
    <p>
      {t(
        'intermediate:myProjects:poolSettings:installationInvoiceApproval:annotation:replaceByIntermediate',
      )}
    </p>
    <strong>
      {t('intermediate:myProjects:poolSettings:installationInvoiceApproval:payByIntermediate')}
    </strong>
    <p>
      {t(
        'intermediate:myProjects:poolSettings:installationInvoiceApproval:annotation:payByIntermediate',
      )}
    </p>
  </div>
);
