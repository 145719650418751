import { IFindWithRelations, ISearchDto } from 'store/common.types';

import { IDocument, IFile, MediaCategoryType } from 'store/client-request/client-request.types';
import {
  OFFER_CREATE,
  OFFER_CREATE_ERROR,
  OFFER_CREATE_SUCCESS,
  OFFER_GET,
  OFFER_GET_ERROR,
  OFFER_GET_SUCCESS,
  IOffer,
  OFFER_TRANSITION,
  OFFER_TRANSITION_SUCCESS,
  OFFER_TRANSITION_ERROR,
  OfferEventType,
  OFFER_TRANSITION_RESET,
  OFFERS_FIND_ERROR,
  MY_ORDERS_FIND,
  OFFER_GET_FOR_CLIENT_REQUEST,
  OFFER_GET_BY_INSTALLER,
  OFFERS_GET_BY_ORGANIZATION,
  MY_ORDERS_FIND_SUCCESS,
  OFFERS_GET_BY_ORGANIZATION_SUCCESS,
  GET_OFFER_FILES,
  GET_OFFER_FILES_SUCCESS,
  GET_OFFER_FILES_ERROR,
  GET_OFFERS_BY_CR,
  GET_OFFERS_BY_CR_ERROR,
  GET_OFFERS_BY_CR_SUCCESS,
  UPLOAD_OFFER_FILE,
  UPLOAD_OFFER_FILE_SUCCESS,
  UPLOAD_OFFER_FILE_ERROR,
  OFFERS_IN_PROGRESS_COUNT,
  OFFERS_IN_PROGRESS_COUNT_SUCCESS,
  OFFERS_IN_PROGRESS_COUNT_ERROR,
  OFFER_UPDATE,
  OFFER_UPDATE_SUCCESS,
  OFFER_UPDATE_ERROR,
  DOWNLOAD_CALENDAR_EVENT,
  DOWNLOAD_CALENDAR_EVENT_SUCCESS,
  DOWNLOAD_CALENDAR_EVENT_ERROR,
  DOWNLOAD_ALL_OFFER_DOCUMENTS,
  DOWNLOAD_ALL_OFFER_DOCUMENTS_ERROR,
  OFFER_REASSIGN_INSTALLER,
  OFFER_REASSIGN_INSTALLER_SUCCESS,
  OFFER_REASSIGN_INSTALLER_ERROR,
  OFFER_GET_DOCS_FOR_INSTALLER,
  OFFER_GET_DOCS_FOR_INSTALLER_SUCCESS,
  OFFER_GET_DOCS_FOR_INSTALLER_ERROR,
} from './offer.types';

//Download Calendar Event
export const downloadCalendarEvent = (offerId: string) => ({
  type: DOWNLOAD_CALENDAR_EVENT,
  payload: offerId,
});

export const downloadCalendarEventSuccess = () => ({
  type: DOWNLOAD_CALENDAR_EVENT_SUCCESS,
});

export const downloadCalendarEventError = (error: any) => ({
  type: DOWNLOAD_CALENDAR_EVENT_ERROR,
  payload: error,
});

//Count In Progress Offers
export const offerstInProgressCount = () => ({
  type: OFFERS_IN_PROGRESS_COUNT,
});

export const offerstInProgressCountSuccess = (payload: any) => ({
  type: OFFERS_IN_PROGRESS_COUNT_SUCCESS,
  payload: payload,
});

export const offerstInProgressCountError = (error: any) => ({
  type: OFFERS_IN_PROGRESS_COUNT_ERROR,
  payload: error,
});

//Get Offer
export const offerGet = (payload: IFindWithRelations) => ({
  type: OFFER_GET,
  payload,
});

//Get available offer for
export const offerForClientRequestGet = (clientRequestId: string) => ({
  type: OFFER_GET_FOR_CLIENT_REQUEST,
  payload: clientRequestId,
});

export const offerByInstallerGetAction = (clientRequestId: string) => ({
  type: OFFER_GET_BY_INSTALLER,
  payload: clientRequestId,
});

export const offerGetSuccess = (payload: IOffer) => ({
  type: OFFER_GET_SUCCESS,
  payload: payload,
});

export const offerGetError = (error: any) => ({
  type: OFFER_GET_ERROR,
  payload: error,
});

//Create Offer
export const offerCreate = (
  offer: IOffer,
  onSuccess?: Function,
  onError?: Function,
  eventType: OfferEventType = OfferEventType.INITIATED,
) => ({
  type: OFFER_CREATE,
  payload: { offer, onSuccess, onError, eventType },
});

export const offerCreateSuccess = (offer: IOffer) => ({
  type: OFFER_CREATE_SUCCESS,
  payload: { offer },
});

export const offerCreateError = (error: any) => ({
  type: OFFER_CREATE_ERROR,
  payload: error,
});

//Upload file
export const offerFileUpload = (
  id: string,
  mediaCategory: MediaCategoryType,
  document: File,
  onProgress?: any,
  onSuccess?: any,
  onError?: any,
) => ({
  type: UPLOAD_OFFER_FILE,
  payload: { id, mediaCategory, document, onProgress, onSuccess, onError },
});

export const offerFileUploadSuccess = (document: IDocument) => ({
  type: UPLOAD_OFFER_FILE_SUCCESS,
  payload: { document },
});

export const offerFileUploadError = (error: any) => ({
  type: UPLOAD_OFFER_FILE_ERROR,
  payload: error,
});

//Transition Offer
export const offerTransition = (
  event: OfferEventType,
  offer: IOffer,
  onSuccess?: any,
  onError?: any,
) => ({
  type: OFFER_TRANSITION,
  payload: { event, offer, onSuccess, onError },
});

export const offerTransitionSuccess = (payload: IOffer) => ({
  type: OFFER_TRANSITION_SUCCESS,
  payload: payload,
});

export const offerTransitionError = (error: any) => ({
  type: OFFER_TRANSITION_ERROR,
  payload: error,
});

export const offerTransitionReset = () => ({
  type: OFFER_TRANSITION_RESET,
});

//Find offers available for an installer
export const myOrdersFind = (payload: any) => ({
  type: MY_ORDERS_FIND,
  payload,
});

export const myOrdersFindSuccess = (payload: IOffer[]) => ({
  type: MY_ORDERS_FIND_SUCCESS,
  payload: payload,
});

export const offerGetByOrganization = (payload: ISearchDto) => ({
  type: OFFERS_GET_BY_ORGANIZATION,
  payload: payload,
});

export const offerGetByOrganizationSuccess = (payload: IOffer[]) => ({
  type: OFFERS_GET_BY_ORGANIZATION_SUCCESS,
  payload: payload,
});

export const offersFindError = (error: any) => ({
  type: OFFERS_FIND_ERROR,
  payload: error,
});

// Get offer files actions
export const getOfferFiles = (offerId: string) => ({
  type: GET_OFFER_FILES,
  payload: {
    offerId,
  },
});

export const getOfferFilesSuccess = (files: IFile[]) => ({
  type: GET_OFFER_FILES_SUCCESS,
  payload: {
    files,
  },
});

export const getOfferFilesError = (error: any) => ({
  type: GET_OFFER_FILES_ERROR,
  payload: error,
});

export const getOffersList = (id: string) => ({
  type: GET_OFFERS_BY_CR,
  payload: id,
});

export const getOffersListSuccess = (offers: any[]) => ({
  type: GET_OFFERS_BY_CR_SUCCESS,
  payload: offers,
});

export const getOffersListError = (error: any) => ({
  type: GET_OFFERS_BY_CR_ERROR,
  payload: error,
});

export const updateOffer = (id: string, offer: IOffer, onSuccess?: any) => ({
  type: OFFER_UPDATE,
  payload: {
    id,
    offer,
    onSuccess,
  },
});

export const updateOfferSuccess = (offers: IOffer) => ({
  type: OFFER_UPDATE_SUCCESS,
  payload: offers,
});

export const updateOfferError = (error: any) => ({
  type: OFFER_UPDATE_ERROR,
  payload: error,
});

//Download All Documents
export const downloadAllDocuments = (offerId: string) => ({
  type: DOWNLOAD_ALL_OFFER_DOCUMENTS,
  payload: {
    id: offerId,
  },
});

export const downloadAllDocumentsError = (error: any) => ({
  type: DOWNLOAD_ALL_OFFER_DOCUMENTS_ERROR,
  payload: { error },
});

export const reassignOfferInstaller = (offerId: string, installerId: string) => ({
  type: OFFER_REASSIGN_INSTALLER,
  payload: { offerId, installerId },
});

export const reassignOfferInstallerSuccess = (offer: IOffer) => ({
  type: OFFER_REASSIGN_INSTALLER_SUCCESS,
  payload: offer,
});

export const reassignOfferInstallerError = (error: any) => ({
  type: OFFER_REASSIGN_INSTALLER_ERROR,
  payload: { error },
});

export const offerGetDocsForInstaller = (offerId: string) => ({
  type: OFFER_GET_DOCS_FOR_INSTALLER,
  payload: offerId,
});

export const offerGetDocsForInstallerSuccess = (offerDocsForInstaller: IFile[]) => ({
  type: OFFER_GET_DOCS_FOR_INSTALLER_SUCCESS,
  payload: offerDocsForInstaller,
});

export const offerGetDocsForInstallerError = (errorCode: string) => ({
  type: OFFER_GET_DOCS_FOR_INSTALLER_ERROR,
  payload: errorCode,
});
