import React, { useRef, useState } from 'react';
import { Flex, Modal, Steps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IProduct } from 'store/product/product.types';
import { bundlesGet } from 'store/bundle/bundle.actions';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import { productWizardGet } from 'store/product-component/product-components.actions';
import SelectBundleStep, { TBundleFormData } from './SelectBundleStep';
import SelectProductsStep from './SelectProductsStep';
import ChangesReviewStep from './ChangesReviewStep';

type TChangePoolModalProps = {
  destPoolId?: string;
  isOpen: boolean;
  closeModalHandler: () => void;
};
enum ChangeSteps {
  Bundle,
  Products,
  Review,
}
const ChangePoolModal = (props: TChangePoolModalProps) => {
  const [currentStep, setCurrentStep] = useState<ChangeSteps>(0);
  const [lastVisitedStep, setLastVisitedStep] = useState<ChangeSteps>(0);
  const newBundleIdRef = useRef<string>();
  const newProductsRef = useRef<IProduct[]>();
  const dispatch = useDispatch();
  const clientRequest = useSelector(currentClientRequestSelector);
  const locizeKey = `intermediate:tenderDetails:changePoolModal`;
  const { t } = useTranslation();

  const canGoToStep = (step: ChangeSteps): boolean => {
    if (currentStep >= step) return true;
    return lastVisitedStep >= step;
  };
  const goToStep = (step: ChangeSteps, ignoreChecking = false) => {
    if (!ignoreChecking && !canGoToStep(step)) return;
    setCurrentStep(step);
    if (step > lastVisitedStep) {
      setLastVisitedStep(step);
    }
  };
  const bundleSubmitHandler = (formData: TBundleFormData) => {
    newBundleIdRef.current = formData.bundle;
    goToStep(ChangeSteps.Products, true);
  };
  const productSubmitHandler = (products?: IProduct[]) => {
    newProductsRef.current = products;
    goToStep(ChangeSteps.Review, true);
  };
  const closeModalHandler = () => {
    if (!clientRequest?.poolId) return;
    // Clean-ups.
    setCurrentStep(ChangeSteps.Bundle);
    dispatch(bundlesGet(clientRequest?.poolId));
    dispatch(productWizardGet(clientRequest.poolId));

    props.closeModalHandler();
  };
  return (
    <Modal
      title={t(`${locizeKey}:modalTitle`)}
      open={props.isOpen}
      onCancel={closeModalHandler}
      width={'80%'}
      footer={null}
    >
      <Flex vertical={true} gap={16}>
        <Steps
          current={currentStep}
          items={[
            {
              title: t(`${locizeKey}:selectBundle`),
              onClick: () => goToStep(ChangeSteps.Bundle),
              disabled: !canGoToStep(ChangeSteps.Bundle),
            },
            {
              title: t(`${locizeKey}:selectProduct`),
              onClick: () => goToStep(ChangeSteps.Products),
              disabled: !canGoToStep(ChangeSteps.Products),
            },
            {
              title: t(`${locizeKey}:reviewChanges`),
              onClick: () => goToStep(ChangeSteps.Review),
              disabled: !canGoToStep(ChangeSteps.Review),
            },
          ]}
        ></Steps>
        {currentStep === ChangeSteps.Bundle && (
          <SelectBundleStep
            bundleSubmitHandler={bundleSubmitHandler}
            destPoolId={props.destPoolId}
            initialFormData={{
              bundle: newBundleIdRef.current || '',
            }}
          />
        )}
        {currentStep === ChangeSteps.Products && (
          <SelectProductsStep
            backClick={() => {
              goToStep(ChangeSteps.Bundle);
            }}
            submitHandler={productSubmitHandler}
            destPoolId={props.destPoolId}
            initialProducts={newProductsRef.current}
          />
        )}
        {currentStep === ChangeSteps.Review && (
          <ChangesReviewStep
            products={newProductsRef.current}
            newBundleId={newBundleIdRef.current}
            destPoolId={props.destPoolId}
            backHandler={() => {
              goToStep(ChangeSteps.Products);
            }}
            closeModal={closeModalHandler}
          />
        )}
      </Flex>
    </Modal>
  );
};
export default ChangePoolModal;
