import React from 'react';
import clsx from 'clsx';
import { default as ANTTag } from 'antd/lib/tag';

import { useTranslation } from 'react-i18next';
import { ClientRequestState } from 'store/client-request/client-request.types';
import styles from './ClientRequestTag.module.sass';

interface ITagProps {
  state: ClientRequestState;
}

/***
 * When to use this Tag:
 *  - you want to show a tag of a client request (e.g. in tenderview)
 */
const ClientRequestTag: React.FC<ITagProps> = ({ state, ...rest }) => {
  const { t } = useTranslation(['clientRequest']);

  const tagClassName = clsx(styles.tag, state);

  return (
    <ANTTag {...rest} className={tagClassName}>
      {t(`clientRequest:state:${state}`)}
    </ANTTag>
  );
};

export default ClientRequestTag;
