// Calculates progress of file upload in percentage
export const calculateProgress = (loaded: number, total: number): number => {
  return Math.round((loaded * 100) / total);
};

// Calculates file upload time left in seconds
export const calculateTimeLeft = (
  timeStart: number,
  timeNow: number,
  loaded: number,
  total: number,
): number => {
  // time difference between upload start and now in seconds
  const timeSpent = (timeNow - timeStart) / 1000;

  // approximate upload speed in bytes/sec
  const speed = loaded / timeSpent;
  const dataLeft = total - loaded;

  // approximate time left to upload file
  return Math.round(dataLeft / speed);
};
