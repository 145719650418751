import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Col, Row, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import Loader from 'components/ui/Loader/Loader';
import BackButton from 'components/ui/BackButton/BackButton';
import QuestionnaireSkippedIcon from 'components/ui/QuestionnaireSkippedIcon/QuestionnaireSkippedIcon';
import LocationWidget from 'pages/installer/TenderDetailsPage/LocationWidget';
import InstallationPeriodWidget from 'pages/installer/TenderDetailsPage/InstallationPeriodWidget';
import PriceWidget from 'pages/installer/TenderDetailsPage/PriceWidget';
import SpecificationWidget from 'pages/installer/TenderDetailsPage/SpecificationWidget/SpecificationWidget';
import DocumentsWidget from 'pages/installer/TenderDetailsPage/DocumentsWidget';

import styles from 'pages/installer/TenderDetailsPage/TenderDetailsPage.module.sass';
import {
  clientRequestGet,
  getClientRequestFiles,
} from 'store/client-request/client-request.actions';
import {
  currentClientRequestSelector,
  clientRequestStateSelector,
} from 'store/client-request/client-request.selectors';
import { RequestState } from 'store/common.types';

const ClientRequestPage = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;
    dispatch(clientRequestGet(id, false));
    dispatch(getClientRequestFiles(id));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const clientRequestState = useSelector(clientRequestStateSelector);
  const clientRequest = useSelector(currentClientRequestSelector);
  const isQuestionnaireSkipped = clientRequest?.questionnaireSkipped;

  const renderContent = () => {
    if (!clientRequest || !clientRequest.contactType) {
      return <div>Loading...</div>;
    }

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <BackButton className={styles.back} onClick={() => navigate('/profile')}>
            {t('common:buttons:back')}
          </BackButton>
          <Row justify="space-between" align="middle" gutter={[0, 32]}>
            <Typography.Title level={3} className={styles.title}>
              {t('intermediate:myProjects:clientRequest')} {clientRequest.displayId}
            </Typography.Title>
          </Row>
        </div>
        <Row gutter={[16, 16]} key="tender-section-contact">
          <Col span={8} key="tender-section-location">
            <LocationWidget clientRequest={clientRequest} distance={null} />
          </Col>
          <Col span={8} key="tender-section-period">
            <InstallationPeriodWidget clientRequest={clientRequest} />
          </Col>
          <Col span={8} key="tender-section-price-range">
            <PriceWidget clientRequest={clientRequest} />
          </Col>
          <Col span={16} key="tender-section-specification">
            {isQuestionnaireSkipped ? (
              <QuestionnaireSkippedIcon />
            ) : (
              <SpecificationWidget clientRequest={clientRequest} />
            )}
          </Col>
          <Col span={8} key="tender-section-documents">
            <DocumentsWidget />
          </Col>
        </Row>
      </div>
    );
  };

  return <>{clientRequestState === RequestState.Success ? renderContent() : <Loader />}</>;
};

export default ClientRequestPage;
