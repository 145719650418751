import React from 'react';
import { Button, Col, Flex, Form, FormInstance, Row, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Rule } from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import useFormBasedLanguageHandler from 'hooks/useFormBasedLanguageHandler';
import TextField from '../TextField/TextField';

interface IMultiLangInputProps {
  form: FormInstance;
  fieldName: string;
  label: string;
  rules?: Rule[];
}

const MultiLangInput = (props: IMultiLangInputProps) => {
  const { fieldName, form, label, rules } = props;
  const fieldWatcher = Form.useWatch(fieldName, { form, preserve: true });
  const fieldLanguageHandler = useFormBasedLanguageHandler(fieldName, fieldWatcher, form);
  const { t } = useTranslation();

  return (
    <Form.List name={fieldName}>
      {(fields, { add, remove }) => (
        <Flex vertical gap={16}>
          {fields.map((field) => (
            <Row key={field.key} gutter={6} align={'middle'}>
              <Col span={19}>
                <Form.Item rules={rules} noStyle name={[field.name, 'value']}>
                  <TextField label={label} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item noStyle name={[field.name, 'lang']}>
                  <Select
                    placeholder={t('intermediate:myProjects:editProjectPools:language')}
                    options={fieldLanguageHandler.possibleOptions}
                    disabled={!fieldLanguageHandler.canAddMoreOptions}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <CloseOutlined
                  onClick={() => {
                    remove(field.name);
                  }}
                />
              </Col>
            </Row>
          ))}
          {fieldLanguageHandler.canAddMoreOptions && (
            <Button type="dashed" onClick={() => add()} block>
              {t('intermediate:myProjects:editProjectPools:addMoreTranslations')}
            </Button>
          )}
        </Flex>
      )}
    </Form.List>
  );
};

export default MultiLangInput;
