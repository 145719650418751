import { all, call, put, takeLatest } from 'redux-saga/effects';
import DocumentsAPI from 'services/documents.service';
import UserAPI from 'services/user.service';
import AuthAPI from 'services/auth.service';
import { getErrorText } from 'utils/helpers';
import {
  UserGetAction,
  UserGetDocumentsAction,
  UpdateUserProfileAction,
  USER_GET,
  USER_GET_DOCUMENTS,
  UPDATE_USER_PROFILE,
  UserDeleteAccountAction,
  USER_DELETE_ACCOUNT,
  USER_RESEND_VERIFICATION_EMAIL,
  UserResendVerificationEmailAction,
  USER_GET_EMAIL_STATUS,
} from './user.types';

import {
  userGetDocumentsSuccess,
  userGetDocumentsError,
  userGetSuccess,
  userGetError,
  updateUserProfileSuccess,
  updateUserProfileError,
  userResendVerificationEmailError,
  getEmailStatusSuccess,
  getEmailStatusError,
} from './user.actions';
import { logout } from '../auth/auth.actions';

export function* getUserDocuments(action: UserGetDocumentsAction): Generator<any, void, any> {
  try {
    const documents = yield call(DocumentsAPI.getFiles, action.payload);

    if (documents) {
      yield put(userGetDocumentsSuccess(documents));
    } else {
      yield put(userGetDocumentsError('Empty Response'));
    }
  } catch (err) {
    const message = getErrorText(err);
    yield put(userGetDocumentsError(message));
  }
}

export function* getUser(action: UserGetAction): Generator<any, void, any> {
  try {
    const user = yield call(UserAPI.getUser);

    if (user) {
      yield put(userGetSuccess(user));
    } else {
      yield put(userGetError('Empty Response'));
    }
  } catch (err) {
    const message = getErrorText(err);
    yield put(userGetError(message));
  }
}

export function* updateUserProfile(action: UpdateUserProfileAction): Generator<any, void, any> {
  const { onSuccess, onError, ...data } = action.payload;

  try {
    const userData = yield call(UserAPI.updateUserProfile, data);

    if (typeof onSuccess === 'function') {
      onSuccess();
    }

    if (userData) {
      yield put(updateUserProfileSuccess(userData));
      yield put(userGetSuccess(userData));
    } else {
      yield put(updateUserProfileError('Empty Response'));
    }
  } catch (err) {
    const message = getErrorText(err);
    yield put(updateUserProfileError(message));

    if (typeof onError === 'function') {
      onError();
    }
  }
}

export function* userDeleteAccount(action: UserDeleteAccountAction): Generator<any, void, any> {
  try {
    const result = yield call(UserAPI.deleteAccount);
    if (result) {
      yield put(logout());
    }
  } catch (err) {
    const message = getErrorText(err);
    yield put(userGetError(message));
  }
}
export function* resendVerificationEmail(action: UserResendVerificationEmailAction) {
  // on Success callback needed for change isLoading status
  const { onSuccess } = action.payload;
  try {
    yield call(AuthAPI.resendVerificationEmail);

    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    if (onSuccess) {
      onSuccess();
    }

    const message = getErrorText(err);
    yield put(userResendVerificationEmailError(message));
  }
}

export function* getEmailStatusSaga() {
  try {
    const { emailVerified } = yield call(UserAPI.getUserEmailStatus);
    yield put(getEmailStatusSuccess(emailVerified));
  } catch (err) {
    yield put(getEmailStatusError(err));
  }
}
export default function* root() {
  yield all([
    takeLatest(USER_GET_DOCUMENTS, getUserDocuments),
    takeLatest(USER_GET, getUser),
    takeLatest(UPDATE_USER_PROFILE, updateUserProfile),
    takeLatest(USER_DELETE_ACCOUNT, userDeleteAccount),
    takeLatest(USER_RESEND_VERIFICATION_EMAIL, resendVerificationEmail),
    takeLatest(USER_GET_EMAIL_STATUS, getEmailStatusSaga),
  ]);
}
