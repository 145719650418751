import { createSelector } from 'reselect';
import { ISurveysState } from './surveys.types';

export const surveysSelector = (state: any) => state.surveys;

//Surveys
export const surveysListSelector = createSelector(surveysSelector, (surveys: ISurveysState) => {
  return surveys.surveys;
});

export const surveySelector = createSelector(surveysSelector, (surveys: ISurveysState) => {
  return surveys.survey;
});

export const surveyCreateStateSelector = createSelector(
  surveysSelector,
  (surveys: ISurveysState) => {
    return surveys.createState;
  },
);
