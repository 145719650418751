import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Form, FormInstance, Row, Select } from 'antd';
import { Rule } from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import useFormBasedLanguageHandler from 'hooks/useFormBasedLanguageHandler';
import EditorContainer from '../EditorContainer/EditorContainer';

interface IMultiLangEditorProps {
  form: FormInstance;
  fieldName: string;
  rules?: Rule[];
}

const MultiLangEditor = (props: IMultiLangEditorProps) => {
  const { fieldName, form, rules } = props;
  const { t } = useTranslation();
  const fieldWatcher = Form.useWatch(fieldName, { form, preserve: true });
  const fieldLanguageHandler = useFormBasedLanguageHandler(fieldName, fieldWatcher, form);

  return (
    <Form.List name={fieldName}>
      {(fields, { add, remove }) => (
        <Flex vertical gap={16}>
          {fields.map((field) => (
            <Flex vertical gap={8} key={field.key}>
              <Row align={'middle'} justify={'end'} gutter={8}>
                <Col span={4}>
                  <Form.Item noStyle name={[field.name, 'lang']}>
                    <Select
                      placeholder={t('intermediate:myProjects:editProjectPools:language')}
                      options={fieldLanguageHandler.possibleOptions}
                      disabled={!fieldLanguageHandler.canAddMoreOptions}
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <CloseOutlined
                    onClick={() => {
                      remove(field.name);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item rules={rules} noStyle name={[field.name, 'value']}>
                    <EditorContainer />
                  </Form.Item>
                </Col>
              </Row>
            </Flex>
          ))}
          {fieldLanguageHandler.canAddMoreOptions && (
            <Button type="dashed" onClick={() => add()} block>
              {t('intermediate:myProjects:editProjectPools:addMoreTranslations')}
            </Button>
          )}
        </Flex>
      )}
    </Form.List>
  );
};

export default MultiLangEditor;
