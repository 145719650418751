import React, { useCallback, useEffect } from 'react';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Flex, message } from 'antd';
import { RequestState } from 'store/common.types';
import { productWizardStateSelector } from '../../../store/product-component/product-component.selector';
import { clientRequestUpdate } from '../../../store/client-request/client-request.actions';
import { productWizardGet } from '../../../store/product-component/product-components.actions';
import { IClientRequest } from '../../../store/client-request/client-request.types';
import ProductSelection from '../../../pages/client-request/Questionnaire/QuestionnaireSteps/ProductWizard/steps/Product/ProductSelection';

interface IEditRequestProductsComponentProps {
  clientRequest: IClientRequest | null;
  clientRequestState: RequestState | null;
  successUpdateHandler: () => void;
  cancelEditHandler: () => void;
}

const EditRequestProductsComponent = (props: IEditRequestProductsComponentProps) => {
  const { clientRequest, clientRequestState } = props;
  const [form] = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getProductWizardState = useSelector(productWizardStateSelector);

  useEffect(() => {
    if (!clientRequest?.pool?.id) return;
    dispatch(productWizardGet(clientRequest.pool.id));
  }, [clientRequest?.pool?.id, dispatch]);

  // Initialize form values
  useEffect(() => {
    form.setFieldValue('products', clientRequest?.products);
  }, [clientRequest, form]);

  const resetFormHandler = useCallback(() => {
    form.resetFields();
  }, [form]);
  const submitFormHandler = useCallback(() => {
    if (!clientRequest?.id) return;
    dispatch(
      clientRequestUpdate(
        clientRequest.id,
        {
          ...clientRequest,
          products: form.getFieldValue('products'),
        },
        () => {
          message.success(t('common:texts:success'));
          props.successUpdateHandler();
        },
        () => {
          message.error(t('common:errorCodes:title '));
        },
      ),
    );
  }, [clientRequest, dispatch, form, t, props]);

  return (
    <Card loading={[clientRequestState, getProductWizardState].includes(RequestState.Loading)}>
      <Flex vertical gap={16}>
        <ProductSelection form={form} />
        <Flex gap={8} justify="end">
          <Button size="large" onClick={props.cancelEditHandler} danger>
            {t('common:buttons:cancel')}
          </Button>
          <Button size="large" onClick={resetFormHandler}>
            {t('common:buttons:reset')}
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={submitFormHandler}
            loading={clientRequestState === RequestState.Saving}
          >
            {t('common:buttons:save')}
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};

export default EditRequestProductsComponent;
