import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ColumnsType } from 'antd/lib/table';
import { Button, Flex, Form, Input, Tooltip, Tag as AntTag } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import OfferTag from 'components/ui/OfferTag/OfferTag';
import Table from 'components/ui/Table/Table';
import Tag from 'components/ui/Tag/Tag';

import { DEFAULT_PAGINATION_PAGE_SIZE } from 'framework/constants';
import PoolTagCircle from 'pages/installer/TenderDetailsPage/ClientRequestTags/PoolTagCircle';
import { ClientRequestState, IClientRequest } from 'store/client-request/client-request.types';
import { IPool } from 'store/intermediate/intermediate.types';
import { OfferState } from 'store/offer/offer.types';
import { clientRequestsGet } from 'store/intermediate/intermediate.actions';
import { RequestState } from 'store/common.types';
import { clientRequests as clientRequestsSelector } from 'store/intermediate/intermediate.selectors';
import { normalizeSearchParam } from 'utils/commonUtils';
import styles from './ClientRequestsOverview.module.scss';

interface IClientRequestsSearchForm {
  page: number;
  query?: string;
}

const EXCLUDED_STATES: Array<ClientRequestState> = [
  ClientRequestState.Initiated,
  ClientRequestState.Submitted,
];

const ClientRequestsOverview: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGINATION_PAGE_SIZE);
  const [searchParams, setSearchParams] = useSearchParams();
  const clientRequests = useSelector(clientRequestsSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const query = searchParams.get('query') || '';
  const page = +(searchParams.get('page') || '1');

  const searchClientRequests = useCallback(
    (page = 1, query?: string) => {
      const offset = (page - 1) * pageSize;
      dispatch(clientRequestsGet(offset, pageSize, query, [], EXCLUDED_STATES));
    },
    [dispatch, pageSize],
  );

  useEffect(() => {
    searchClientRequests(page, query);
  }, [page, query, searchClientRequests, pageSize]);

  const UCRTableColumns: ColumnsType<IClientRequest> = [
    {
      title: 'installerFlow:dashboard:id',
      dataIndex: 'displayId',
      key: 'displayId',
    },
    {
      title: 'Pool',
      dataIndex: 'pool',
      render: (pool: IPool) => {
        return pool.name ? pool.name : '';
      },
    },
    {
      title: 'profile:firstName',
      dataIndex: 'firstName',
      render: (_: string, row: IClientRequest) => {
        return row.owner?.firstName ?? '';
      },
    },
    {
      title: 'profile:lastName',
      dataIndex: 'lastName',
      render: (_: string, row: IClientRequest) => {
        return row.owner?.lastName ?? '';
      },
    },
    {
      title: 'installerFlow:dashboard:period',
      dataIndex: 'period',
      key: 'period',
      render: (text: string, row: IClientRequest, index: Number) =>
        row?.soonestPossible || !row?.startDate ? (
          t('installerFlow:dashboard:soonest')
        ) : (
          <span>
            {t('installerFlow:dashboard:from')}
            <DateFormat date={row.startDate} />
          </span>
        ),
    },
    {
      title: 'installerFlow:dashboard:statusAndTags',
      dataIndex: 'state',
      key: 'state',
      render: (state: OfferState, row: IClientRequest) => (
        <Flex vertical>
          <div>
            {state && state !== OfferState.None ? (
              <OfferTag state={state} />
            ) : (
              <Tag>Unprocessed</Tag>
            )}
          </div>
          <Flex align="center" gap="5px" className={styles.crTagLine}>
            {row.poolTags?.map((poolTag) => (
              <Tooltip
                key={poolTag.id}
                placement="bottom"
                title={<AntTag color={poolTag.colorCode}>{poolTag.title}</AntTag>}
              >
                <span>
                  <PoolTagCircle size="14" radius="7" fillColor={poolTag.colorCode} />
                </span>
              </Tooltip>
            ))}
          </Flex>
        </Flex>
      ),
    },
  ];

  const handleSearch = (searchFormsValues: IClientRequestsSearchForm) => {
    setSearchParams({
      query: normalizeSearchParam(searchFormsValues.query),
      page: '1',
    });
  };

  const handlePageChange = (page: number, pageSize: number) => {
    setPageSize(pageSize);
    setSearchParams((_) => ({
      query: normalizeSearchParam(query),
      page: page.toString(),
    }));
  };

  return (
    <div>
      <div className={styles.formContainer}>
        <Form
          layout={'inline'}
          onFinish={handleSearch}
          initialValues={{
            query,
          }}
          className={styles.form}
        >
          <Form.Item name={'query'} className={styles.formItem}>
            <Input
              size={'large'}
              placeholder={t('intermediate:myProjects:clientRequestOverviewTab:searchPlaceholder')}
              className={styles.searchInput}
              allowClear={{
                clearIcon: <CloseOutlined onClick={() => handleSearch({ query: '', page: 1 })} />,
              }}
            />
          </Form.Item>
          <Form.Item className={styles.formItem}>
            <Button type="primary" htmlType={'submit'} size={'small'}>
              {t('common:forms:searchInput')}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Table
        loading={clientRequests.state === RequestState.Loading}
        columns={UCRTableColumns.map((tender: any) => ({ ...tender, title: t(`${tender.title}`) }))}
        dataSource={clientRequests.data.items}
        rowKey="id"
        emptyText={t('intermediate:myProjects:dashboardTab:noEntries')}
        onRowClick={(record) => `/my-projects/client-request/${record.id}`}
        pagination={{
          total: clientRequests.data?.total || 0,
          position: ['bottomCenter'],
          pageSize,
          current: page,
          onChange: handlePageChange,
        }}
      />
    </div>
  );
};

export default ClientRequestsOverview;
