import config from 'config';
import { IContactPerson } from 'store/contact-person/contact-person.types';
import HttpService from './http.service';

class ContactPersonAPI extends HttpService {
  createContactPerson = (poolId: string, data: IContactPerson) => {
    return this.post(`contact-person/pool/${poolId}`, { ...data });
  };

  getContactPersons = (poolId: string) => {
    return this.get(`contact-person/pool/${poolId}`);
  };

  updateContactPerson = (personId: string, data: IContactPerson) => {
    return this.put(`contact-person/${personId}`, { ...data });
  };

  deleteContactPerson = (personId: string) => {
    return this.delete(`contact-person/${personId}`);
  };
}

export default new ContactPersonAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
