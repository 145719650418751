import React from 'react';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  FormInstance,
  FormListFieldData,
  Input,
  InputNumber,
  Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { antdPriceFormatter, antdPriceParser, formatPrice } from 'utils/currencyUtils';
import { DescriptionModal } from './DesciptionModal';

interface IBundleRowFormProps {
  component: FormListFieldData;
  remove: (index: number) => void;
  form: FormInstance;
  formPath: (string | number)[];
}
export const BundleRowForm = ({ component, remove, form, formPath }: IBundleRowFormProps) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const closeModal = () => setIsModalVisible(false);
  const openModal = () => setIsModalVisible(true);
  const submitDescriptionForm = (description: string) => {
    form.setFieldValue(['bundles', ...formPath, component.name, 'description'], description);
    closeModal();
  };
  const descriptionWatcher = Form.useWatch(
    ['bundles', ...formPath, component.name, 'description'],
    form,
  );
  const priceWatcher = Form.useWatch(['bundles', ...formPath, component.name, 'price'], form);
  const quantityWatcher = Form.useWatch(['bundles', ...formPath, component.name, 'quantity'], form);
  const marginWatcher = Form.useWatch(['bundles', formPath[0], 'margin'], form);
  const isDiscountWatcher = Form.useWatch(['bundles', formPath[0], 'discount'], form);
  const totalPrice =
    priceWatcher * quantityWatcher * (isDiscountWatcher ? 1 : 1 + marginWatcher / 100);
  return (
    <Card>
      <DescriptionModal
        isModalVisible={isModalVisible}
        onSubmit={submitDescriptionForm}
        closeModal={closeModal}
        initialDescription={descriptionWatcher}
      />
      <Row gutter={4} align={'middle'}>
        <Col span={12}>
          <Form.Item
            name={[component.name, 'description']}
            label={t(`intermediate:myProjects:bundle.discription`)}
          >
            <Input.Search onClick={openModal} onSearch={openModal} enterButton={<EditOutlined />} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={[component.name, 'quantity']}
            label={t(`intermediate:myProjects:bundle.quantity`)}
          >
            <InputNumber min={0} controls={false} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={[component.name, 'unit']}
            label={t(`intermediate:myProjects:bundle.unit`)}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={[component.name, 'price']}
            label={t(`intermediate:myProjects:bundle.priceNet`)}
          >
            <InputNumber
              min={0}
              controls={false}
              formatter={antdPriceFormatter}
              parser={antdPriceParser}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Descriptions layout={'vertical'}>
            <Descriptions.Item label={t(`intermediate:myProjects:bundle.totalPriceNet`)}>
              {formatPrice(totalPrice)}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={2}>
          <Button
            type={'primary'}
            danger={true}
            onClick={() => remove(component.name)}
            icon={<DeleteOutlined />}
          />
        </Col>
      </Row>
    </Card>
  );
};
