import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ExportDate from 'assets/icons/export.svg';
import ClientRequestKeyValue from 'components/ui/ClientRequestKeyValue/ClientRequestKeyValue';
import Rectangle from 'components/layout/Rectangle/Rectangle';

import { ButtonColor } from 'components/ui/Button/Button';
import Icon from 'components/ui/Icon/Icon';
import Button from 'components/ui/Button/Button';
import { ISurvey } from 'store/surveys/surveys.types';
import { getWallboxes } from 'store/client-request/client-request.actions';

import styles from './SurveyPage.module.sass';

interface ISpecificationWidgetProps {
  survey: ISurvey;
}

const SpecificationWidget: React.FC<ISpecificationWidgetProps> = ({ survey }) => {
  const { t } = useTranslation('survey');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWallboxes());
  }, []); // eslint-disable-line

  const handleExportClick = () => {
    console.log('export click');
  };

  const renderExportButton = () => (
    <Button
      type="primary"
      color={ButtonColor.WHITE}
      className={styles.exportDateButton}
      onClick={handleExportClick}
    >
      <Icon icon={ExportDate} />
      <span>{t('Export Data')}</span>
    </Button>
  );

  return (
    <Rectangle title={t('installerFlow:tenderDetails:specification')}>
      {renderExportButton()}
      <div className={styles.specifications}>
        {survey?.questionnaire?.steps.map((step: any) => {
          return (
            <div className={styles.specificationsBlock} key={step.id}>
              <span className={styles.specificationsBlockTitle}>{`${step.order} : ${t(
                step.title,
              )}`}</span>
              {step.children.map((subStep: any) => {
                return (
                  <div key={step.id}>
                    <span className={styles.specificationsBlockTitle}>{`${step.order}-${
                      subStep.order
                    } : ${t(subStep.title)}`}</span>
                    <div className={styles.specificationsList}>
                      {Object.keys(subStep.schema.properties).map(
                        (questionKey: string, index: number) => {
                          return (
                            <ClientRequestKeyValue
                              key={`spec-question-${index}`}
                              questionKey={questionKey}
                              clientRequest={survey}
                              uiSchema={subStep.uiSchema.elements.find(
                                (item: any) => item.scope === `#/properties/${questionKey}`,
                              )}
                            />
                          );
                        },
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </Rectangle>
  );
};

export default SpecificationWidget;
