import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { Col, Row } from 'antd';
import Form from 'antd/lib/form';
import AnswerSelection, { AnswerType } from 'components/ui/AnswerSelection/AnswerSelection';
import { LabelPositionType } from 'components/ui/FloatingLabel/FloatingLabel';
import { PHONE_REGEX, REQUIRED_ADDRESS_FIELDS, ValidationFactory } from 'framework/validations';
import VerificationAPI from 'services/verification.service';

import { IOption } from 'store/common.types';
import { ICountry } from 'store/country/country.types';
import { HOOK_MARK } from 'utils/commonUtils';
import styles from './AddressQuestion.module.sass';
import { ClientRequestFields } from '../../../store/client-request/client-request.types';

type InputNameType = keyof AddressValue;

type AddressValue = {
  companyName: string;
  street: string;
  houseNumber: string;
  address2: string;
  city: string;
  postalCode: string;
  country: string;
  phone: string;
};

type InputType = {
  label: string;
  name: InputNameType;
  width: number;
  value: string | undefined;
  options?: IOption[];
  dependencies?: string[];
  xs?: number;
  disabled?: boolean;
};

interface Props {
  onChange?: Function;
  value?: AddressValue | {};
  name?: 'address' | 'billingAddress' | undefined;
  question?: string;
  form?: any;
  filteredCountries?: ICountry[];
  isCountryDisabled: boolean;
}

// eslint-disable-next-line
const AddressQuestion = React.forwardRef<HTMLDivElement, Props>((props: Props, forwardRef) => {
  const { t } = useTranslation();
  const countriesOptions = (props.filteredCountries || []).map((country) => ({
    value: country.iso31661Alpha3,
    label: t(`common:country:${country.iso31661Alpha3}`),
  }));

  useEffect(() => {
    const formFields = (props.form as any).getInternalHooks(HOOK_MARK).getFields();
    const postalCodeField = formFields.find((item: any) =>
      item.name.includes(`${props.name}postalCode`),
    );
    if ((props?.value as AddressValue)?.postalCode && postalCodeField) {
      props.form.setFields([
        ...formFields.filter((item: any) => !item.name.includes(`${props.name}postalCode`)),
        {
          ...postalCodeField,
          touched: true,
        },
      ]);
    }
  }, []); // eslint-disable-line

  const onInputChange = (name: string, value: string) => {
    const updatedValue = {
      ...props.value,
      [name]: value,
    };
    props.onChange && props.onChange(updatedValue);
  };

  const phoneValidator = ({ getFieldValue }: any) => ({
    async validator(rule: any, value: string) {
      let isTouched = props.form.isFieldTouched(rule.field);
      if (!isTouched) return Promise.resolve();

      const phone = value || getFieldValue(props.name).phone;

      if (!phone || (phone && !PHONE_REGEX.test(phone))) {
        return Promise.reject(t('common:errors:validPhone'));
      }

      return Promise.resolve();
    },
  });

  const postalCodeValidator = ({ getFieldValue }: any) => ({
    async validator(rule: any, value: string) {
      let isTouched = props.form.isFieldTouched(rule.field);
      if (!isTouched) return Promise.resolve();
      const postalCode = value || getFieldValue(props.name).postalCode;

      if (!postalCode || postalCode.length < 3) {
        // postal code is 5 digits
        return Promise.reject(t('common:errors:validPostalCode'));
      }
      await VerificationAPI.verifyPostalCode(postalCode, getFieldValue(props.name)?.country)
        .then((response) => {
          return Promise.resolve();
        })
        .catch((error: any) => {
          return Promise.reject(t('common:errors:validPostalCode'));
        });
      return Promise.resolve();
    },
  });

  const addressFieldsValidator = ({ getFieldValue }: any) => ({
    async validator(rule: any, value: string) {
      let isTouched = props.form.isFieldTouched(rule.field);
      if (!isTouched) return Promise.resolve();

      const isRequired = REQUIRED_ADDRESS_FIELDS.find((field) => props.name + field === rule.field);

      if (isRequired && !value) {
        return Promise.reject(t('common:errors:fieldRequired'));
      }

      return Promise.resolve();
    },
  });

  const ADDRESS_INPUTS: InputType[] = [
    {
      label: t(`customerFlow:questions:${ClientRequestFields.Address}:answers:company`),
      name: 'companyName',
      width: 18,
      xs: 24,
      value: _get(props.value, 'companyName', ''),
    },
    {
      label: t(`customerFlow:questions:${ClientRequestFields.Address}:answers:street`),
      name: 'street',
      width: 18,
      xs: 24,
      value: _get(props.value, 'street', ''),
    },
    {
      label: t(`customerFlow:questions:${ClientRequestFields.Address}:answers:houseNumber`),
      name: 'houseNumber',
      width: 6,
      xs: 24,
      value: _get(props.value, 'houseNumber', ''),
    },
    {
      label: t(`customerFlow:questions:${ClientRequestFields.Address}:answers:address2`),
      name: 'address2',
      width: 24,
      value: _get(props.value, 'address2', ''),
    },
    {
      label: t('common:forms:phone'),
      name: 'phone',
      width: 6,
      xs: 24,
      value: _get(props.value, 'phone', ''),
    },
    {
      label: t(`customerFlow:questions:${ClientRequestFields.Address}:answers:postalCode`),
      name: 'postalCode',
      width: 6,
      xs: 24,
      value: _get(props.value, 'postalCode', ''),
      dependencies: [props.name || 'country'],
    },
    {
      label: t(`customerFlow:questions:${ClientRequestFields.Address}:answers:city`),
      name: 'city',
      width: 12,
      xs: 24,
      value: _get(props.value, 'city', ''),
    },
    {
      label: t(`customerFlow:questions:${ClientRequestFields.Address}:answers:country`),
      name: 'country',
      width: 6,
      xs: 24,
      options: countriesOptions,
      value: _get(props.value, 'country', ''),
      dependencies: [props.name || 'postalCode'],
      disabled: props.isCountryDisabled,
    },
  ];

  return (
    <div className={styles.container} ref={forwardRef}>
      <div className={styles.question}>{props.question}</div>

      <Row className={styles.inputs} gutter={[16, 16]}>
        {ADDRESS_INPUTS.map((input: InputType) => (
          <Col key={`address-input-${input.name}`} span={input.width} xs={input.xs}>
            <Form.Item
              className={styles.questionShort}
              name={props.name + input.name}
              rules={
                input.name === 'phone'
                  ? [phoneValidator]
                  : input.name === 'postalCode'
                    ? [postalCodeValidator]
                    : [ValidationFactory.NOT_ONLY_WHITE_SPACE, addressFieldsValidator]
              }
              validateFirst={true}
              dependencies={input.dependencies}
            >
              <div>
                <AnswerSelection
                  value={input.value}
                  title={input.label}
                  titlePosition={LabelPositionType.LEFT}
                  type={input.name === 'country' ? AnswerType.SELECT : AnswerType.INPUT}
                  className={styles.input}
                  options={input?.options}
                  onInput={(e, val) => {
                    onInputChange(input.name, val);
                  }}
                  disabled={input.disabled}
                />
              </div>
            </Form.Item>
          </Col>
        ))}
      </Row>
    </div>
  );
});

export default AddressQuestion;
