import React from 'react';
import { useTranslation } from 'react-i18next';
import { default as ANTTag } from 'antd/lib/tag';
import clsx from 'clsx';
import { OfferState } from 'store/offer/offer.types';
import styles from './OfferTag.module.sass';

export enum TagType {
  default = 'default',
  pool = 'pool',
}

interface ITagProps {
  state: OfferState;
  type?: TagType;
  children?: React.ReactNode;
}

/***
 * When to use this Tag:
 *  - you want to show a tag of a offer (e.g. in tenderview)
 */
const OfferTag: React.FC<ITagProps> = ({ children, state, type = TagType.default, ...rest }) => {
  const { t } = useTranslation();

  const getTranslation = () => {
    // This switch case will return different translations for pool operator
    if (type === TagType.pool) {
      switch (state) {
        case OfferState.InvoiceSubmittedInstallation:
        case OfferState.InvoiceSubmittedHomeCheck:
        case OfferState.InvoiceSubmittedRemoteHomeCheck:
        case OfferState.InvoiceAcceptedInstallation:
        case OfferState.InstallationDone:
          return t(`common:allStates:${state}Pool`);

        default:
          return t(`common:allStates:${state}`);
      }
    }

    return t(`common:allStates:${state}`);
  };

  const tagClassName = clsx(styles.tag, state);

  return (
    <ANTTag {...rest} className={tagClassName}>
      {getTranslation()}
    </ANTTag>
  );
};

export default OfferTag;
