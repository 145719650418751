import React, { useState, useEffect } from 'react';
import { preloadScript } from 'opentok-react';

import { useParams } from 'react-router-dom';
import VideoCallAPI from 'services/video-call.service';
import Precall from './Precall';
import Call from './Call';

const CustomerVideoCall = () => {
  const [tab, setTab] = useState('preCall');
  const [offer, setOffer] = useState<any>(null);
  const [host, setHost] = useState<any>(null);
  const [config, setConfig] = useState<any>(null);

  const { meetingId } = useParams();

  useEffect(() => {
    if (meetingId)
      VideoCallAPI.getCustomerVideoCall(meetingId)
        .then((response: any) => {
          const { token, apiKey, sessionId, offer, host } = response;
          setHost(host);
          setOffer(offer);
          setConfig({ token: token, apiKey: apiKey, sessionId: sessionId });
        })
        .catch((error: any) => {
          return Promise.reject(error);
        });
  }, [meetingId]);

  const precallProps = {
    offer,
    host,
    startCall: () => setTab('call'),
  };

  const callProps = {
    offer,
    config,
    endCall: () => setTab('preCall'),
  };

  return <>{tab === 'preCall' ? <Precall {...precallProps} /> : <Call {...callProps} />}</>;
};

export default preloadScript(CustomerVideoCall);
