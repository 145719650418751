import React from 'react';
import { DEFAULT_COLOR } from 'framework/constants';

type ThemeType = {
  color: string;
  fontFamily: string;
};

const PoolThemeContext = React.createContext([
  {
    color: DEFAULT_COLOR,
    fontFamily: '',
  },
  () => {},
] as [ThemeType, any]);

export default PoolThemeContext;
