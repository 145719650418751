import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col, message, Collapse } from 'antd';
import NumericInput from 'components/ui/NumberInput/NumericInput';
import Button from 'components/ui/Button/Button';
import { poolUpdate } from 'store/intermediate/intermediate.actions';

import { getFileLink } from 'store/client-request/client-request.actions';
import {
  CustomDocumentsCategoryType,
  ICustomDocument,
} from 'store/intermediate/intermediate.types';

import { genericFileSelector } from 'store/document/document.selectors';
import { clearCurrentFile } from 'store/document/document.actions';
import { currentPool, customDocumentSelector } from 'store/intermediate/intermediate.selectors';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { PoolType } from 'types/pool-settings.types';
import { formatPrice, removeThousandSeparator } from 'utils/currencyUtils';
import styles from './TextAdjustmentsTab.module.sass';
import CustomDocumentSection from './CustomDocumentSection';

const { Panel } = Collapse;

const TextAdjustmentsTab: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const pool = useSelector(currentPool);
  const isSurveyPool = pool.poolType === PoolType.SurveyPool;
  const uploadedFile = useSelector(genericFileSelector);
  const customDocuments = useSelector(customDocumentSelector) as ICustomDocument[];
  const descriptionRef = useRef();
  const landingRef = useRef();
  const surveyLandingRef = useRef();
  const parentLandingRef = useRef();
  const infoRef = useRef();

  const description = customDocuments.filter(
    (v) => v.category === CustomDocumentsCategoryType.UserCostCalculatorDescription,
  );

  const landing = customDocuments.filter(
    (v) => v.category === CustomDocumentsCategoryType.UserLandingPage,
  );

  const surveyLanding = customDocuments.filter(
    (v) => v.category === CustomDocumentsCategoryType.SurveyLandingPage,
  );

  const parentLanding = customDocuments.filter(
    (v) => v.category === CustomDocumentsCategoryType.ParentLandingPage,
  );

  const info = customDocuments.filter(
    (v) => v.category === CustomDocumentsCategoryType.UserInfoPage,
  );

  const [homeCheckEstimate, setHomeCheckEstimate] = useState(
    formatPrice(pool.homeCheckEstimate) || '0',
  );

  useEffect(() => {
    dispatch(clearCurrentFile());
  }, [dispatch]);

  useEffect(() => {
    if (landing && landing[0]?.media.length && !uploadedFile) {
      const [image] = landing[0].media;
      image.media && dispatch(getFileLink(image.media.name));
    }
  }, [dispatch, landing]); //eslint-disable-line

  useEffect(() => {
    if (uploadedFile) {
      dispatch(getFileLink(uploadedFile.name));
    }
  }, [dispatch, uploadedFile]);

  const onSaveClick = () => {
    if (removeThousandSeparator(homeCheckEstimate) !== pool.homeCheckEstimate) {
      dispatch(
        poolUpdate(pool.id, {
          homeCheckEstimate: removeThousandSeparator(homeCheckEstimate),
        }),
      );
    }

    (descriptionRef?.current as any)?.onSaveCustomDocument();
    (landingRef?.current as any)?.onSaveCustomDocument();
    (infoRef?.current as any)?.onSaveCustomDocument();
    (parentLandingRef.current as any)?.onSaveCustomDocument();
    if (isSurveyPool) {
      (surveyLandingRef?.current as any)?.onSaveCustomDocument();
    }
    message.info(t('intermediate:myProjects:editProjectPools:poolSaved'));
  };

  return (
    <Row>
      <Col span={24}>
        <h3>{t('intermediate:myProjects:editProjectPools:overwritePriceRange')}</h3>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <NumericInput
              label={t('intermediate:myProjects:editProjectPools:homeCheckEstimate')}
              value={homeCheckEstimate.toString()}
              // @ts-ignore
              onChange={(e) => setHomeCheckEstimate(e.target.value)}
            />
          </Col>
        </Row>
        <Collapse ghost accordion className={styles.collapse}>
          <Panel
            header={t('intermediate:myProjects:editProjectPools:installationComponents')}
            key="1"
          >
            <CustomDocumentSection
              pool={pool}
              category={CustomDocumentsCategoryType.UserCostCalculatorDescription}
              customDocuments={description}
              ref={descriptionRef}
            />
          </Panel>
          {pool.isParent && (
            <Panel header={t('intermediate:myProjects:editProjectPools:parentLandingPage')} key="2">
              <CustomDocumentSection
                pool={pool}
                category={CustomDocumentsCategoryType.ParentLandingPage}
                customDocuments={parentLanding}
                showMedia
                ref={parentLandingRef}
              />
            </Panel>
          )}

          <Panel header={t('intermediate:myProjects:editProjectPools:landingPage')} key="3">
            <CustomDocumentSection
              pool={pool}
              category={CustomDocumentsCategoryType.UserLandingPage}
              customDocuments={landing}
              showMedia
              ref={landingRef}
            />
          </Panel>

          {isSurveyPool && (
            <Panel header={t('intermediate:myProjects:editProjectPools:surveyLandingPage')} key="4">
              <CustomDocumentSection
                pool={pool}
                category={CustomDocumentsCategoryType.SurveyLandingPage}
                customDocuments={surveyLanding}
                showMedia
                ref={surveyLandingRef}
              />
            </Panel>
          )}

          <Panel header={t('intermediate:myProjects:editProjectPools:faqPage')} key="5">
            <CustomDocumentSection
              pool={pool}
              category={CustomDocumentsCategoryType.UserInfoPage}
              customDocuments={info}
              showHeading
              ref={infoRef}
            />
          </Panel>
        </Collapse>
        <Button type="primary" className={styles.button} onClick={onSaveClick}>
          {t('intermediate:myProjects:editProjectPools:saveChanges')}
        </Button>
      </Col>
    </Row>
  );
};

export default TextAdjustmentsTab;
