import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, message, Tooltip, Typography } from 'antd';
import moment from 'moment';
import CopyLinkIcon from 'assets/icons/copy-link.svg';
import TextField from 'components/ui/TextField/TextField';

import Checkbox from 'components/ui/Checkbox/Checkbox';
import Button from 'components/ui/Button/Button';
import Notification from 'components/ui/Notification/Notification';

import Loader from 'components/ui/Loader/Loader';
import Icon from 'components/ui/Icon/Icon';
import DatePicker from 'components/ui/DatePicker/DatePicker';
import { IInviteTokenData, ValidateInviteType } from 'store/register/register.types';
import { RequestState } from 'store/common.types';
import { IPool } from 'store/intermediate/intermediate.types';
import {
  inviteTokenUpdateStateSelector,
  poolInviteTokensSelector,
  poolInviteTokensStateSelector,
} from 'store/intermediate/intermediate.selectors';
import { inviteTokenUpdate, poolInviteTokensGet } from 'store/intermediate/intermediate.actions';
import styles from './CustomerLinkTab.module.sass';
import editStyles from '../EditPool.module.sass';

export interface ICustomerLinkProps {
  pool: IPool;
}

const CustomerLinkTab: React.FC<ICustomerLinkProps> = ({ pool }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inviteTokenUpdateState = useSelector(inviteTokenUpdateStateSelector);
  const inviteTokens = useSelector(poolInviteTokensSelector);
  const [tokenData, setTokenData] = useState<IInviteTokenData | null>(inviteTokens[0]);
  const inviteTokensState = useSelector(poolInviteTokensStateSelector);
  const link = tokenData?.token
    ? `${document.location.origin}/registration/${tokenData.token}`
    : '';

  useEffect(() => {
    if (pool) {
      dispatch(poolInviteTokensGet(pool.id));
    }
  }, [pool, dispatch]);

  useEffect(() => {
    if (inviteTokens) {
      const endUserToken = inviteTokens.find(
        (item: IInviteTokenData) => item.type === ValidateInviteType.EndUser,
      );
      if (endUserToken) {
        setTokenData(endUserToken);
      } else {
        setTokenData(null);
      }
    }
  }, [inviteTokens]);

  useEffect(() => {
    if (inviteTokenUpdateState === RequestState.Success) {
      message.info(t('intermediate:myProjects:editProjectPools:poolSaved'));
    }
  }, [inviteTokenUpdateState]); // eslint-disable-line

  const copyToClipboard = (e: any, copyValue: string) => {
    let textField = document.createElement('textarea');
    textField.innerText = copyValue;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    message.info(t('intermediate:myProjects:editProjectPools:linkCopied'));
    e.target.focus();
  };

  const enableToggle = () => {
    tokenData &&
      setTokenData({
        ...tokenData,
        isEnabled: !tokenData.isEnabled,
      });
  };

  const changeDate = (value: Date) => {
    tokenData &&
      setTokenData({
        ...tokenData,
        expires: value,
      });
  };

  const expireToggle = () => {
    tokenData &&
      setTokenData({
        ...tokenData,
        expires: tokenData.expires ? null : new Date(),
      });
  };

  const onSaveClick = () => {
    if (tokenData) {
      dispatch(
        inviteTokenUpdate(tokenData.id, {
          ...tokenData,
          organization: undefined,
        }),
      );
    }
  };

  if (inviteTokensState === RequestState.Loading || !inviteTokens) {
    return <Loader />;
  }

  return (
    <Row>
      <Col span={24}>
        <Typography className={editStyles.boxTitle}>
          {t('intermediate:myProjects:editProjectPools:offerSettings')}
        </Typography>
        {!tokenData && (
          <Notification
            className={styles.notification}
            title={t('intermediate:myProjects:editProjectPools:customerLinkErrorTitle')}
            content={t('intermediate:myProjects:editProjectPools:customerLinkErrorContent')}
            type="error"
            fluid
          />
        )}
        <TextField
          label={t('intermediate:myProjects:editProjectPools:link')}
          value={link}
          className={styles.linkInput}
          readOnly
          suffix={
            <Icon
              className={styles.copyButton}
              onClick={(e) => copyToClipboard(e, link)}
              icon={CopyLinkIcon}
            />
          }
        />
        <TextField
          label={t('intermediate:myProjects:editProjectPools:inviteCode')}
          value={tokenData?.token || ''}
          className={styles.codeInput}
          readOnly
          suffix={
            <Icon
              className={styles.copyButton}
              onClick={(e) => copyToClipboard(e, tokenData?.token || '')}
              icon={CopyLinkIcon}
            />
          }
        />
        <Checkbox checked={!tokenData?.expires} onChange={expireToggle} className={styles.checkbox}>
          {t('intermediate:myProjects:editProjectPools:doNotExpire')}
        </Checkbox>
        <Tooltip
          placement="topLeft"
          title={
            !tokenData?.expires
              ? t('intermediate:myProjects:editProjectPools:customerLinkTooltip')
              : ''
          }
        >
          <div>
            <DatePicker
              onChange={(value) => value && changeDate(value?.toDate())}
              disabled={!tokenData?.expires}
              placeholder="DD.MM.YYYY"
              value={tokenData?.expires ? moment(tokenData.expires) : undefined}
            />
          </div>
        </Tooltip>
        <Checkbox
          checked={!!tokenData?.isEnabled}
          onChange={enableToggle}
          className={styles.checkbox}
        >
          {t('intermediate:myProjects:editProjectPools:isEnabled')}
        </Checkbox>
        <Button
          type="primary"
          onClick={onSaveClick}
          className={styles.button}
          disabled={!tokenData}
        >
          {t('intermediate:myProjects:editProjectPools:saveChanges')}
        </Button>
      </Col>
    </Row>
  );
};

export default CustomerLinkTab;
