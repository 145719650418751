import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';

import clsx from 'clsx';
import FloatingLabel, { LabelPositionType } from 'components/ui/FloatingLabel/FloatingLabel';
import styles from './MultipleInputContainer.module.sass';

type MultipleInputControlProps = {
  value?: string;
  onChange?: (value: string) => void;
  handleInputChange?: (value: string) => void;
  placeholder?: string;
  coverImage?: string;
};
const MultipleInputControl: React.FC<MultipleInputControlProps> = (props) => {
  const { value, onChange, coverImage, placeholder = 'other', handleInputChange } = props;
  const { t } = useTranslation();

  const onInputChange = (value: string) => {
    onChange?.(value);
    handleInputChange?.(value);
  };

  return (
    <div className={clsx({ [styles.option]: coverImage })}>
      {coverImage && <img src={coverImage} className={styles.coverImage} alt="answer cover" />}
      <FloatingLabel label={t(placeholder)} value={value} labelPosition={LabelPositionType.CENTER}>
        <Input value={value} onChange={(e) => onInputChange(e.target.value)} />
      </FloatingLabel>
    </div>
  );
};

export default MultipleInputControl;
