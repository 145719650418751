import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Select, notification } from 'antd';

import TextField from 'components/ui/TextField/TextField';
import { ValidationFactory } from 'framework/validations';
import { GENDER_OPTIONS } from 'framework/constants';
import { updateUserProfile } from 'store/user/user.actions';
import EditTabContainer from './EditTabContainer';

import styles from './EditTabContainer.module.sass';

enum FieldType {
  INPUT = 'input',
  DROPDOWN = 'dropDown',
}

type ProfileValue = {
  companyName: string;
  gender: string;
  title: string;
  firstName: string;
  lastName: string;
};

export interface IPersonalInfoTabProps {
  user: any;
  isCustomer: boolean;
  onChange?: Function;
  value?: ProfileValue;
  token?: string;
  onCancel(): void;
  onLinkClick?(): void;
}

const PersonalInfoTab: React.FC<IPersonalInfoTabProps> = ({ user, isCustomer, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState({
    firstName: '',
    lastName: '',
    title: '',
    gender: '',
    email: '',
    phone: '',
    fax: '',
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      const { firstName, lastName, title, gender, email, phone, fax } = user;

      setValue({
        firstName,
        lastName,
        title,
        gender,
        email,
        phone,
        fax,
      });
    }
  }, [user]);

  const onInputChange = (event: any, field: string) => {
    const newVal = { ...value, [field]: event.target.value };
    setValue(newVal);
  };

  const onDropDownChange = (selected: any, field: string) => {
    const newVal = { ...value, [field]: selected };
    setValue(newVal);
  };

  const onSuccess = () => {
    setIsLoading(false);

    notification.success({
      message: t('profile:successMessage'),
      duration: 3,
    });
  };

  const onError = () => {
    setIsLoading(false);
  };

  const onSaveClick = () => {
    const { email, ...rest } = value;
    setIsLoading(true);
    dispatch(
      updateUserProfile({
        ...rest,
        onSuccess,
        onError,
      }),
    );
  };

  const FIELDS = [
    {
      type: FieldType.INPUT,
      name: 'firstName',
      label: t('profile:firstName'),
      rules: [ValidationFactory.REQUIRED],
      value: value.firstName,
    },
    {
      type: FieldType.INPUT,
      name: 'lastName',
      label: t('profile:lastName'),
      rules: [ValidationFactory.REQUIRED],
      value: value.lastName,
    },
    {
      type: FieldType.INPUT,
      name: 'title',
      label: t('profile:titleField'),
      rules: [ValidationFactory.REQUIRED],
      value: value.title,
    },
    {
      type: FieldType.DROPDOWN,
      name: 'gender',
      label: t('profile:gender'),
      value: value.gender,
      rules: [ValidationFactory.REQUIRED],
      options: GENDER_OPTIONS.map((option) => {
        return { value: option.value, label: t(option.label) };
      }),
    },
    {
      type: FieldType.INPUT,
      name: 'email',
      label: t('profile:email'),
      rules: [ValidationFactory.REQUIRED],
      value: value.email,
    },
    {
      type: FieldType.INPUT,
      name: 'phone',
      label: t('profile:phone'),
      rules: [ValidationFactory.REQUIRED],
      value: value.phone,
    },
  ];

  if (!isCustomer) {
    FIELDS.push({
      type: FieldType.INPUT,
      name: 'fax',
      label: t('profile:faxNumber'),
      rules: [ValidationFactory.REQUIRED],
      value: value.fax,
    });
  }

  const renderFields = () =>
    FIELDS.map((field) => (
      <Form.Item key={field.name}>
        {field.type === FieldType.INPUT ? (
          <TextField
            className={styles.textField}
            label={field.label}
            name={field.name}
            value={field.value}
            type={'text'}
            disabled={field.name === 'email'}
            onInput={(event) => onInputChange(event, field.name)}
          />
        ) : (
          <Select
            className={styles.dropDown}
            placeholder={field.label}
            options={field.options}
            value={field.value}
            onChange={(e) => onDropDownChange(e, field.name)}
          />
        )}
      </Form.Item>
    ));

  const containerProps = {
    onCancel,
    title: t('profile:personalInfo'),
    saveDisabled: isLoading,
    onSave: onSaveClick,
  };

  return <EditTabContainer {...containerProps}>{renderFields()}</EditTabContainer>;
};

export default PersonalInfoTab;
