import React, { FC, useMemo } from 'react';
import clsx from 'clsx';
import draftToHtml from 'draftjs-to-html';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Col, Form, Row, Select } from 'antd';
import { ValidationFactory } from 'framework/validations';
import { landingPageSelector } from 'store/intermediate/intermediate.selectors';

import { IInviteTokenData } from 'store/register/register.types';
import styles from './PoolSelector.module.sass';

interface IPoolSelectiorProps {
  onSubmit: () => void;
  inviteToken?: IInviteTokenData;
}

const PoolSelector: FC<IPoolSelectiorProps> = ({ inviteToken, onSubmit }) => {
  const landingPage = useSelector(landingPageSelector);
  const { t } = useTranslation();

  const landing = useMemo(() => {
    if (!landingPage || (!landingPage.media.length && landingPage.text === 'null')) return null;
    return {
      coverImage: landingPage.media[0]?.mediaUrl,
      content: draftToHtml(JSON.parse(landingPage.text)),
    };
  }, [landingPage]);

  const poolsOptions = useMemo(() => {
    if (inviteToken?.clientRequestPool) {
      const pools = [
        { value: inviteToken?.clientRequestPool.id, label: inviteToken?.clientRequestPool.name },
      ];
      inviteToken?.clientRequestPool.childClientRequestPool?.map((pool) =>
        pools.push({ value: pool.id, label: pool.name }),
      );
      return pools;
    }
    return [];
  }, [inviteToken?.clientRequestPool]);

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div className={styles.container}>
      {landing?.coverImage && <img src={landing.coverImage} alt="landing" />}
      {landing?.content && (
        <div className={styles.landingDescription}>
          <p dangerouslySetInnerHTML={{ __html: landing.content }} />
        </div>
      )}
      <Row gutter={[16, 16]} justify="center" align={landing ? 'top' : 'middle'}>
        <Col lg={8} md={10} sm={22} xs={22}>
          <Form.Item
            name="poolId"
            className={clsx({ [styles.poolSelector]: !landing })}
            rules={[ValidationFactory.REQUIRED]}
          >
            <Select
              placeholder={t('common:company')}
              filterOption={filterOption}
              showSearch
              options={poolsOptions}
              listItemHeight={48}
              listHeight={250}
            />
          </Form.Item>
        </Col>
        <Col lg={4} md={6} sm={22} xs={22}>
          <Button type="primary" block onClick={onSubmit}>
            {t('common:buttons:select')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default PoolSelector;
