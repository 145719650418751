import React, { useState } from 'react';
import { default as ANTModal, ModalProps } from 'antd/lib/modal';
import { useTranslation } from 'react-i18next';
import Form from 'antd/lib/form';
import Button, { ButtonColor } from 'components/ui/Button/Button';
import TextField from 'components/ui/TextField/TextField';
import styles from './ConfirmationModal.module.sass';

interface Props extends ModalProps {
  visible: boolean;
  onSubmit: (reason: string) => void;
  onCancel: () => void;
}

const ConfirmationModal: React.FC<Props> = ({ onSubmit, onCancel, visible, title }) => {
  const [value, setValue] = useState('');

  const { t } = useTranslation();

  const onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  const modalProps = {
    title,
    visible,
    onCancel,
    centered: true,
    closable: true,
    footer: null,
    width: 680,
    wrapClassName: styles.container,
  };

  return (
    <ANTModal {...modalProps}>
      <Form.Item>
        <TextField
          label={t('intermediate:myProjects:questionModal:reason')}
          value={value}
          onInput={onInputChange}
        />
      </Form.Item>
      <div className={styles.buttonActions}>
        <Button type="primary" onClick={onCancel} color={ButtonColor.BLUE}>
          {t('common:buttons:cancel')}
        </Button>
        <Button
          type="primary"
          color={ButtonColor.GREEN}
          disabled={!value}
          onClick={() => {
            onSubmit(value);
          }}
        >
          {t('common:buttons:okay')}
        </Button>
      </div>
    </ANTModal>
  );
};

export default ConfirmationModal;
