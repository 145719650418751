import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ClientRequestState } from '../store/client-request/client-request.types';
import { currentClientRequestSelector } from '../store/client-request/client-request.selectors';

const useIsCustomerAllowedToEditRequestProducts = () => {
  const request = useSelector(currentClientRequestSelector);

  return useMemo(() => {
    if (!request || !request.products || !request.state) return false;
    if (request.products.length === 0) return false;
    return ![
      ClientRequestState.HomeCheck,
      ClientRequestState.Approved,
      ClientRequestState.OfferSelectionHomeCheck,
      ClientRequestState.OfferSelectionInstallation,
      ClientRequestState.OrderSubmitted,
      ClientRequestState.InstallationPending,
      ClientRequestState.PaymentPending,
      ClientRequestState.Done,
    ].includes(request.state);
  }, [request]);
};
export default useIsCustomerAllowedToEditRequestProducts;
