import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Popover, Progress } from 'antd';

import { useSelector } from 'react-redux';
import { ReactComponent as SuccessIcon } from 'assets/icons/check-green.svg';
import { IStep } from '../Stepper';
import styles from '../Stepper.module.sass';
import useClientRequestStepsHandler from '../../../../hooks/useClientRequestStepsHandler';
import { lastVisitedStepSelector } from '../../../../store/client-request/client-request.selectors';

interface IStepFormProps {
  title: string;
  isActive?: boolean;
  isDone?: boolean;
  index: number;
  substeps?: IStep[];
  currentSubstep?: number;
}

const Step: React.FC<IStepFormProps> = (props) => {
  const { title, isActive, isDone, index, substeps, currentSubstep = 1 } = props;
  const lastVisitedStep = useSelector(lastVisitedStepSelector);
  const [popover, setPopover] = useState(false);

  const { t } = useTranslation();

  const classNames = clsx(styles.step, {
    [styles.active]: isActive,
    [styles.done]: isDone,
  });
  const { canGoToStep, changeStep } = useClientRequestStepsHandler();
  const step = index + 1;

  const renderSupsteps = () => {
    if (!substeps) return null;
    return substeps.map((substep, substepIndex) => (
      <button
        onClick={() => changeStep(step, substepIndex + 1)}
        key={`step-${step}-substep-${substep.key}`}
        className={clsx(styles.substep, {
          [styles.clickable]: canGoToStep(step, substepIndex + 1),
          [styles.done]: isDone || substepIndex + 1 < lastVisitedStep.subStep,
          [styles.active]: isActive && substepIndex + 1 === currentSubstep,
        })}
      >
        {(substepIndex + 1 < currentSubstep && isActive) || isDone ? (
          <SuccessIcon />
        ) : (
          <div className={styles.substepNumber}>{substep.key}</div>
        )}
        {t(substep.title)}
      </button>
    ));
  };

  const handlePopoverClick = () => (substeps ? setPopover(true) : null);

  const handleOpenChange = (visible: boolean) => {
    if (!visible) setPopover(visible);
  };

  const progress = useMemo(() => {
    if (!substeps?.length) return 0;
    return +((currentSubstep * 100) / substeps.length).toFixed(1);
  }, [substeps, currentSubstep]);

  return (
    <div className={styles.stepContainer} onClick={handlePopoverClick}>
      <Popover
        content={renderSupsteps()}
        title="Progress"
        placement="bottom"
        trigger="click"
        open={popover}
        onOpenChange={handleOpenChange}
      >
        <div className={classNames}>
          <div className={styles.stepInformation}>
            {isDone ? <SuccessIcon /> : <div className={styles.circle}>{step}</div>}
            <div className={styles.stepCounter}>{`Step ${step}`}</div>
            <div className={styles.title}>{t(title)}</div>
          </div>
          {isActive && (
            <Progress
              type="circle"
              percent={progress}
              strokeColor="#131630"
              trailColor="#fff"
              strokeWidth={4}
              className={styles.progressBar}
              strokeLinecap="square"
              size={54}
            />
          )}
        </div>
      </Popover>
    </div>
  );
};

export default Step;
