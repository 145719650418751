import React, { useMemo } from 'react';
import { Button, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getFileLink } from 'store/client-request/client-request.actions';
import { IDocument, IFile } from 'store/client-request/client-request.types';
import { RequestState } from 'store/common.types';
import styles from './DownloadableDocumentList.module.sass';

export interface DownloadableDocumentListProps {
  docs?: IDocument[];
  docsState: RequestState;
}

const DownloadableDocumentList: React.FC<DownloadableDocumentListProps> = ({
  docs: documents,
  docsState: state,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleDocumentDownload = (documentName: string) => {
    dispatch(getFileLink(documentName));
  };

  const noDocsAvailable = useMemo(() => <span>{t('common:noDocsAvailable')}</span>, [t]);

  switch (state) {
    case RequestState.Loading:
      return <span>{t('common:loadingYourDocs')}...</span>;

    case RequestState.Error:
      return noDocsAvailable;

    case RequestState.Success:
      if (!documents || documents.length === 0) return noDocsAvailable;
      return documents.map((item: IFile) => (
        <div key={item.id} className={styles.fileItem}>
          <div className={styles.title}>
            <Typography.Title className={styles.name} level={5}>
              {item.originalName}
            </Typography.Title>
            <Typography.Text type="secondary">
              {t(`intermediate:myProjects:${item.category}`)}
            </Typography.Text>
          </div>
          <Button
            type="primary"
            onClick={() => handleDocumentDownload(item.name)}
            icon={<DownloadOutlined />}
          />
        </div>
      ));

    default:
      return noDocsAvailable;
  }
};

export default DownloadableDocumentList;
