import { IDocument } from 'store/client-request/client-request.types';
import {
  CLEAR_CURRENT_FILE,
  GenericFile,
  UPLOAD_GENERIC_FILE,
  UPLOAD_GENERIC_FILE_ERROR,
  UPLOAD_GENERIC_FILE_SUCCESS,
} from './document.types';

export const uploadGenericFile = (payload: GenericFile) => ({
  type: UPLOAD_GENERIC_FILE,
  payload: payload,
});

export const uploadGenericFileSuccess = (payload: IDocument) => ({
  type: UPLOAD_GENERIC_FILE_SUCCESS,
  payload: payload,
});

export const uploadGenericFileError = (error: any) => ({
  type: UPLOAD_GENERIC_FILE_ERROR,
  payload: error,
});

export const clearCurrentFile = () => ({
  type: CLEAR_CURRENT_FILE,
  payload: null,
});
