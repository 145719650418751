import {
  IContactPerson,
  CONTACT_PERSON_CREATE,
  CONTACT_PERSON_CREATE_ERROR,
  CONTACT_PERSON_CREATE_SUCCESS,
  CONTACT_PERSONS_GET,
  CONTACT_PERSONS_GET_SUCCESS,
  CONTACT_PERSONS_GET_ERROR,
  CONTACT_PERSON_UPDATE,
  CONTACT_PERSON_UPDATE_SUCCESS,
  CONTACT_PERSON_UPDATE_ERROR,
  CONTACT_PERSON_DELETE,
  CONTACT_PERSON_DELETE_SUCCESS,
  CONTACT_PERSON_DELETE_ERROR,
} from './contact-person.types';

//Create ContactPerson
export const contactPersonCreate = (
  poolId: string,
  contactPerson: IContactPerson,
  onSuccess: () => void,
) => ({
  type: CONTACT_PERSON_CREATE,
  payload: {
    poolId,
    contactPerson,
    onSuccess,
  },
});

export const contactPersonCreateSuccess = (payload: IContactPerson) => ({
  type: CONTACT_PERSON_CREATE_SUCCESS,
  payload: payload,
});

export const contactPersonCreateError = (error: any) => ({
  type: CONTACT_PERSON_CREATE_ERROR,
  payload: error,
});

//Get ContactPersons
export const contactPersonsGet = (payload: string) => ({
  type: CONTACT_PERSONS_GET,
  payload: payload,
});

export const contactPersonsGetSuccess = (payload: IContactPerson[]) => ({
  type: CONTACT_PERSONS_GET_SUCCESS,
  payload: payload,
});

export const contactPersonsGetError = (error: any) => ({
  type: CONTACT_PERSONS_GET_ERROR,
  payload: error,
});

//Update ContactPerson
export const contactPersonUpdate = (
  contactPersonId: string,
  contactPerson: IContactPerson,
  onSuccess: () => void,
) => ({
  type: CONTACT_PERSON_UPDATE,
  payload: {
    contactPersonId,
    contactPerson,
    onSuccess,
  },
});

export const contactPersonUpdateSuccess = (payload: IContactPerson) => ({
  type: CONTACT_PERSON_UPDATE_SUCCESS,
  payload: payload,
});

export const contactPersonUpdateError = (error: any) => ({
  type: CONTACT_PERSON_UPDATE_ERROR,
  payload: error,
});

//Delete ContactPerson
export const contactPersonDelete = (contactPersonId: string) => ({
  type: CONTACT_PERSON_DELETE,
  payload: contactPersonId,
});

export const contactPersonDeleteSuccess = (contactPersonId: string) => ({
  type: CONTACT_PERSON_DELETE_SUCCESS,
  payload: contactPersonId,
});

export const contactPersonDeleteError = (error: any) => ({
  type: CONTACT_PERSON_DELETE_ERROR,
  payload: error,
});
