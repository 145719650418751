import React from 'react';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from 'antd';
import moment from 'moment';
import { CalendarOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { isURL } from 'class-validator';
import i18n from 'i18next';
import clsx from 'clsx';
import Button, { IconPosition } from 'components/ui/Button/Button';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import Rectangle from 'components/layout/Rectangle/Rectangle';
import { showModal } from 'store/delivery-date/delivery-date.module';
import { WallboxDeliveryStatus, IClientRequest } from 'store/client-request/client-request.types';
import styles from './TenderDetailsPage.module.sass';

interface IInstallationPeriodWidgetProps {
  clientRequest: IClientRequest;
  isOperatorSide?: boolean;
  shouldShowWeekdays?: boolean;
}

const InstallationPeriodWidget: React.FC<IInstallationPeriodWidgetProps> = ({
  clientRequest,
  isOperatorSide,
  shouldShowWeekdays = true,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getPossibleDays = () => {
    const weekdaysArr = _get(clientRequest, 'weekdays') || [];
    return weekdaysArr.map((day: string) => t(`common:weekdays:${day}`)).join(', ');
  };

  const getDate = () =>
    clientRequest.soonestPossible ? (
      t('installerFlow:dashboard:soonest')
    ) : (
      <>
        {t('installerFlow:dashboard:from')}
        {clientRequest.startDate ? <DateFormat date={clientRequest.startDate} /> : ''}
      </>
    );

  const formatDate = (date: Date) => moment(date).format('DD.MM.YYYY');

  const getDeliveredTag = (date?: Date) => {
    const title = t('installerFlow:tenderDetails:deliveryDate:delivered');
    return date ? (
      <Tooltip title={formatDate(date)}>
        <Typography.Title level={5}>{title}</Typography.Title>
      </Tooltip>
    ) : (
      <Typography.Title level={5}>{title}</Typography.Title>
    );
  };

  const DHLTrackingAPI: string =
    'https://www.dhl.de/{lang}/privatkunden/dhl-sendungsverfolgung.html?piececode={trackingCode}';

  const getDeliveryDate = () => {
    const date = clientRequest.wallboxDeliveryDate;
    switch (clientRequest?.wallboxDeliveryStatus) {
      case WallboxDeliveryStatus.DeliveredManually:
        return getDeliveredTag(date);
      case WallboxDeliveryStatus.ToBeDefined:
        return <Typography.Title level={5}>TBD</Typography.Title>;
      case WallboxDeliveryStatus.Defined: {
        if (clientRequest.trackingValue) {
          const { trackingValue } = clientRequest;
          return isURL(trackingValue) ? (
            <div>
              <Button type="link" href={trackingValue}>
                {t('installerFlow:tenderDetails:trackingLabel:linkButton')}
              </Button>
            </div>
          ) : (
            <Typography.Title level={5}>{trackingValue}</Typography.Title>
          );
        }
        return <Typography.Title level={5}>{formatDate(date!)}</Typography.Title>;
      }
      case WallboxDeliveryStatus.Delivered:
      case WallboxDeliveryStatus.PreTransit:
      case WallboxDeliveryStatus.Transit:
      case WallboxDeliveryStatus.Failure:
      case WallboxDeliveryStatus.Unknown: {
        // tracking code is not url if state is one of above
        const { trackingValue, wallboxDeliveryStatus } = clientRequest;
        return (
          <div>
            <Typography.Title level={5}>
              <div>{t(`installerFlow:tenderDetails:deliveryDate:${wallboxDeliveryStatus}`)}</div>
              <Button
                type="link"
                target="_blank"
                href={DHLTrackingAPI.replace('{lang}', i18n.language).replace(
                  '{trackingCode}',
                  trackingValue as string,
                )}
              >
                {trackingValue}
              </Button>
            </Typography.Title>
          </div>
        );
      }
      default:
        return;
    }
  };

  const getDeliveryDateButton = () => {
    const isDefined = clientRequest?.wallboxDeliveryStatus === WallboxDeliveryStatus.Defined;
    const title = t(
      `installerFlow:tenderDetails:deliveryDate:button:${isDefined ? 'update' : 'setup'}`,
    );
    return (
      <Button
        //@ts-ignore
        type={isDefined ? 'secondary' : 'primary'}
        iconPosition={IconPosition.LEFT}
        icon={<CalendarOutlined />}
        onClick={() => dispatch(showModal(true))}
      >
        {title}
      </Button>
    );
  };

  return (
    <Rectangle title={t('installerFlow:tenderDetails:installationPeriod')}>
      <div className={styles.centerBlock}>
        <h5 className={styles.titleSmallRange}>{getDate()}</h5>
        {shouldShowWeekdays && (
          <div className={styles.possibleDays}>
            <p className={styles.label}>{t(`installerFlow:tenderDetails:possibleDays`)}</p>
            {getPossibleDays()}
          </div>
        )}

        <p className={clsx(styles.label, { [styles.mt2]: !shouldShowWeekdays })}>
          {t(
            `installerFlow:tenderDetails:${
              clientRequest.trackingValue ? 'trackingLabel' : 'deliveryDate'
            }:title`,
          )}
        </p>
        {getDeliveryDate()}
        {isOperatorSide && getDeliveryDateButton()}
      </div>
    </Rectangle>
  );
};

export default InstallationPeriodWidget;
