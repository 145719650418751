import { produce } from 'immer';
import { IParentPoolsFilterState, ParentPoolsFilterActionTypes } from './parentPoolsFilter.types';
import { RequestState } from '../common.types';

const initialState: IParentPoolsFilterState = {
  data: null,
  requestState: null,
  requestError: null,
};
const parentPoolsFilterReducer = (state = initialState, action: ParentPoolsFilterActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'PARENT_POOLS_FILTER':
        draft.requestState = RequestState.Loading;
        break;
      case 'PARENT_POOLS_FILTER_SUCCESS':
        draft.requestState = RequestState.Success;
        draft.requestError = null;
        draft.data = action.payload;
        break;
      case 'PARENT_POOLS_FILTER_ERROR':
        draft.requestState = RequestState.Error;
        draft.requestError = action.payload;
        break;
    }
  });
export default parentPoolsFilterReducer;
