import React, { useMemo } from 'react';
import { Progress, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import ArrowRight from 'assets/icons/dashboard/ArrowRight.svg';
import Icon from 'components/ui/Icon/Icon';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import { ClientRequestState } from 'store/client-request/client-request.types';
import { QuestionnaireStepType } from 'types/questionnaire.types';
import styles from './Progress.module.sass';

const WIZARD_STEPS_COUNT = 2;
const ADDRESS_STEPS_COUNT = 5;

type ProgressProps = {
  onContinue: () => void;
};

const ProgressWidget: React.FC<ProgressProps> = (props) => {
  const { t } = useTranslation();
  const request = useSelector(currentClientRequestSelector);

  const stepConfig = useMemo(() => {
    if (!request) return null;
    const questionnaireSteps = request.questionnaire?.steps?.filter(
      (step) => step.stepType !== QuestionnaireStepType.RemoteHomeCheck,
    ).length;

    let step = 1;
    if (request.step) {
      for (let index = 0; index < request.step; index++) {
        const increment = {
          1: WIZARD_STEPS_COUNT,
          2: questionnaireSteps ?? 0,
          3: ADDRESS_STEPS_COUNT,
        }[index];
        step += increment ?? 0;
      }
    }
    const steps = WIZARD_STEPS_COUNT + ADDRESS_STEPS_COUNT + (questionnaireSteps || 0);

    return {
      currentStep: step,
      steps,
      progress: +(((step - 1) * 100) / steps!).toFixed(1),
    };
  }, [request]);

  const isQuestionnaire = useMemo(
    () =>
      request?.state === ClientRequestState.Initiated ||
      request?.state === ClientRequestState.Submitted,
    [request],
  );
  return (
    <div onClick={props.onContinue} className={styles.progress}>
      {isQuestionnaire && (
        <Progress
          type="circle"
          percent={stepConfig?.progress!}
          strokeColor="#131630"
          trailColor="#fff"
          strokeWidth={4}
          className={styles.progressBar}
          strokeLinecap="square"
          size={54}
        />
      )}
      <div>
        <Typography.Text>{t('customerFlow:dashboard:progress:title')}</Typography.Text>
        <br />
        <Typography.Text className={styles.progressText}>
          {isQuestionnaire
            ? t('customerFlow:dashboard:progress:subtitle', {
                currentStep: stepConfig?.currentStep,
                steps: stepConfig?.steps,
              })
            : t(`customerFlow:dashboard:progress:${request?.state}:subtitle`)}
        </Typography.Text>
      </div>
      <Icon icon={ArrowRight} />
    </div>
  );
};

export default ProgressWidget;
