import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Col, Row, Typography } from 'antd';
import mailSent from 'assets/images/mail_sent.svg';
import BoxContent from 'components/layout/BoxContent/BoxContent';
import { userResendVerificationEmail } from 'store/user/user.actions';

import styles from './EmailVerificationStep.module.sass';

interface EmailVerificationStepProps {
  email: string;
}

const EmailVerificationStep: React.FC<EmailVerificationStepProps> = ({ email }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleResendClick = () => {
    setIsLoading(true);

    dispatch(
      userResendVerificationEmail({
        onSuccess: () => {
          setIsLoading(false);
        },
      }),
    );
  };

  const boxContentProps = {
    showNextButton: true,
    boxContentClassName: styles.container,
    showBackButton: false,
    nextButtonLoading: isLoading,
    nextButtonText: t('common:buttons:resend'),
    onNextClick: handleResendClick,
  };

  return (
    <BoxContent {...boxContentProps}>
      <Helmet>
        <title>Email verification required - Registration</title>
      </Helmet>
      <Row gutter={[16, 16]} align="middle" justify="center">
        <Col xs={21} md={14} className={styles.content}>
          <div>
            <Typography.Title level={3}>
              {t('customerFlow:costCalculations:emailVerification:title')}
            </Typography.Title>
            <Typography.Paragraph>
              {t('customerFlow:costCalculations:emailVerification:description1', { email })}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t('customerFlow:costCalculations:emailVerification:description2')}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t('customerFlow:costCalculations:emailVerification:description3')}
            </Typography.Paragraph>
          </div>
        </Col>
        <Col xs={12} offset={4} md={6}>
          <div>
            <img className={styles.manImage} src={mailSent} alt="Check your Inbox" />
          </div>
        </Col>
      </Row>
    </BoxContent>
  );
};

export default EmailVerificationStep;
