import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Form from 'antd/lib/form';
import TextField from 'components/ui/TextField/TextField';
import { ValidationFactory } from 'framework/validations';

type ContactValue = {
  email: string;
  phone: string;
  fax: string;
  mobile: string;
};

interface Props {
  onChange?: Function;
  value?: ContactValue;
}

const ContactStep: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState({
    email: '',
    phone: '',
    fax: '',
    mobile: '',
  } as ContactValue);

  const CONTACT_FIELDS = [
    {
      label: t('installerFlow:registration:emailAddress'),
      name: 'email',
      width: 24,
      value: props.value?.email || value.email,
      rules: [
        ValidationFactory.REQUIRED,
        ValidationFactory.NOT_ONLY_WHITE_SPACE,
        ValidationFactory.EMAIL,
      ],
    },
    {
      label: t('installerFlow:registration:mobileNumber'),
      name: 'mobile',
      width: 24,
      value: props.value?.mobile || value.mobile,
      rules: [
        ValidationFactory.REQUIRED,
        ValidationFactory.PHONE,
        ValidationFactory.NOT_ONLY_WHITE_SPACE,
        ValidationFactory.INCLUDES_URL,
      ],
    },
  ];

  const onInputChange = (value: ContactValue) => {
    setValue(value);
    props.onChange && props.onChange(value);
  };

  return (
    <Row gutter={[16, 16]}>
      {CONTACT_FIELDS.map((field) => (
        <Col span={24} key={`installer-register-field-${field.name}`}>
          <Form.Item name={field.name} rules={field.rules}>
            <TextField
              value={field.value}
              label={field.label}
              onInput={(e) => {
                const newVal = { ...value, [field.name]: e.currentTarget.value };
                onInputChange(newVal);
              }}
            />
          </Form.Item>
        </Col>
      ))}
    </Row>
  );
};

export default ContactStep;
