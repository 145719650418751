import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useNavigate } from 'react-router';
import { JsonForms } from '@jsonforms/react';
import { Button, Form, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import { useDispatch, useSelector } from 'react-redux';
import { customRenderer, unifySchemas, unifyUiSchemas } from 'framework/jsonForm';
import { transformAnswersToFormData } from 'framework/dataTransformations';
import ClientRequesAPI from 'services/client-request.service';
import { clientRequestAnswersSave } from 'store/client-request/client-request.actions';
import { currentClientRequestAnswersSelector } from 'store/client-request/client-request.selectors';
import { QuestionKey } from 'store/client-request/client-request.types';
import { IQuestionnaire } from 'store/intermediate/intermediate.types';
import { updateOffer } from 'store/offer/offer.actions';
import styles from './VideoCall.module.sass';

interface IProps {
  questionnaire: IQuestionnaire;
  clientRequestId: string;
  offerId: string;
  visible: boolean;
  onClose: Function;
  onEndCall: Function;
}
const QuestionnaireSection = ({
  questionnaire,
  clientRequestId,
  offerId,
  visible,
  onClose,
  onEndCall,
}: IProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation(['remoteHomeCheck']);
  const navigate = useNavigate();
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false);
  const dispatch = useDispatch();
  const clientRequestAnswers = useSelector(currentClientRequestAnswersSelector);

  const getData = (subStepSchema: any) =>
    subStepSchema && clientRequestAnswers.length
      ? transformAnswersToFormData(subStepSchema, clientRequestAnswers)
      : {};

  const save = async () => {
    let values: Store;
    values = await form.getFieldsValue();
    if (values) {
      const answers = Object.keys(values).map((changeQuestionKey) => ({
        questionKey: changeQuestionKey as QuestionKey,
        answerValue: { value: values[changeQuestionKey] },
      }));

      dispatch(clientRequestAnswersSave(clientRequestId, answers));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    _.debounce(() => {
      save();
    }, 500),
    [],
  );

  const downloadPdf = () => ClientRequesAPI.downloadSummaryPdf(clientRequestId);

  const handleSaveButtonClick = () => {
    onEndCall();
    setIsConfirmModalVisible(false);
    dispatch(
      updateOffer(offerId, { actualHomeCheckTimestamp: new Date() }, () => {
        navigate(`/installer/order/${offerId}/documentation`);
      }),
    );
  };
  const questionnaireConfig = useMemo(() => {
    // here we have step 0, as we dont have substeps remote homecheck
    const step = 0;
    const schema = unifySchemas(questionnaire, step);
    const uiSchema = unifyUiSchemas(questionnaire, step).elements;
    const answers = schema ? transformAnswersToFormData(schema, clientRequestAnswers || []) : {};

    return { uiSchema: uiSchema[step], schema, answers };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaire]);

  return (
    <div className={styles.questionnaire} hidden={!visible}>
      <div className={styles.title}>
        <div>Questionnaire Window</div>
        <CloseOutlined onClick={() => onClose(false)} />
      </div>
      <Form className={styles.form} form={form}>
        {questionnaire?.steps?.map((step) => (
          <div key={step.id} className={styles.questionnaireScrollBar}>
            {step.children.map((substep) => (
              <JsonForms
                key={substep.id}
                schema={questionnaireConfig.schema}
                uischema={questionnaireConfig.uiSchema}
                data={questionnaireConfig.answers}
                renderers={customRenderer}
                onChange={({ errors, data }) => {
                  const isEqual = _.isEqual(data, getData(substep.schema));
                  if (!isEqual) {
                    Object.keys(data).forEach((item) => {
                      form?.setFieldsValue({
                        [item]: data[item],
                      });
                    });
                    debouncedSave();
                  }
                }}
              />
            ))}
          </div>
        ))}
      </Form>
      <Button
        type="primary"
        onClick={() => {
          setIsConfirmModalVisible(true);
        }}
        block
      >
        Save and Exit
      </Button>
      <Button block type="primary" style={{ marginTop: '10px' }} onClick={downloadPdf}>
        Summary PDF Download
      </Button>
      <Modal
        title={t('remoteHomeCheck:text:confirmTitle')}
        open={isConfirmModalVisible}
        onCancel={() => setIsConfirmModalVisible(false)}
        footer={[
          <Button onClick={() => setIsConfirmModalVisible(false)} key={'cancel'}>
            {t('remoteHomeCheck:text:cancel')}
          </Button>,
          <Button onClick={handleSaveButtonClick} key={'sace'} type="primary">
            {t('remoteHomeCheck:text:save')}
          </Button>,
        ]}
      >
        <p> {t('remoteHomeCheck:text:confirmText')}</p>
      </Modal>
    </div>
  );
};

export default QuestionnaireSection;
