import React, { ReactNode, useEffect } from 'react';
import List from 'antd/lib/list';
import { useDispatch, useSelector } from 'react-redux';
import Typography from 'antd/lib/typography';
import { useNavigate } from 'react-router-dom';
import BoxContent from 'components/layout/BoxContent/BoxContent';
import Button, { ButtonColor } from 'components/ui/Button/Button';
import { clientRequestAnswersGet } from 'store/client-request/client-request.actions';
import {
  currentClientRequestAnswersSelector,
  currentClientRequestSelector,
} from 'store/client-request/client-request.selectors';
import { IClientRequestAnswer, QuestionKey } from 'store/client-request/client-request.types';
import { OfferState, OfferEventType, IOffer } from 'store/offer/offer.types';
import { offerTransition, offerTransitionReset } from 'store/offer/offer.actions';
import { offerTransitionStateSelector } from 'store/offer/offer.selectors';
import { RequestState } from 'store/common.types';
import styles from './TenderViewPage.module.sass';

const TenderViewPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clientRequest = useSelector(currentClientRequestSelector);
  const clientRequestAnswers = useSelector(currentClientRequestAnswersSelector);
  const transitionState = useSelector(offerTransitionStateSelector);

  useEffect(() => {
    if (!transitionState) {
      return;
    }
    if (transitionState === RequestState.Success) {
      navigate('/installer/dashboard');
    }
  }, [transitionState, navigate]);

  useEffect(() => {
    return function cleanup() {
      dispatch(offerTransitionReset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!clientRequest || !clientRequest.id) {
      return;
    }
    dispatch(clientRequestAnswersGet(clientRequest.id));
    // eslint-disable-next-line
  }, [clientRequest]);

  const listItem = (answer: IClientRequestAnswer) => {
    switch (answer.questionKey) {
      case QuestionKey.ParkingSpaceLocation:
        return (
          <List.Item key={answer.questionKey}>
            <List.Item.Meta
              title={answer.questionKey}
              description={answer.answerValue.value.selectedOption}
            />
          </List.Item>
        );
      default:
        return (
          <List.Item actions={[]} key={answer.questionKey}>
            <List.Item.Meta title={answer.questionKey} description={answer.answerValue.value} />
          </List.Item>
        );
    }
  };

  const questionItems = () => {
    let items: ReactNode[] = [];
    clientRequestAnswers.forEach((answer) => {
      items.push(listItem(answer));
    });
    return items;
  };

  const transitionOfferState = (event: OfferEventType) => {
    if (!clientRequest) {
      return; //TODO: Handle error
    }
    const offer: IOffer = {
      state: OfferState.None,
      clientRequest,
    };
    dispatch(offerTransition(event, offer));
  };

  return (
    <BoxContent title="Requirements summary" showBackButton={false} showNextButton={false}>
      <div className={styles.container}>
        <Typography.Text>Request Id: {clientRequest?.id}</Typography.Text>
        <Typography.Text>{`Created At: ${clientRequest?.createdAt}`}</Typography.Text>
        <Typography.Text>Annotation: {clientRequest?.annotation}</Typography.Text>
        <List className={styles.questionList} itemLayout="horizontal">
          {questionItems()}
        </List>
        <div className={styles.participationButtons}>
          <Button
            className={styles.participateButton}
            type="primary"
            onClick={() => transitionOfferState(OfferEventType.INSTALLER_REJECTED)}
            danger
          >
            Decline
          </Button>
          <Button
            className={styles.participateButton}
            type="primary"
            onClick={() =>
              clientRequest && navigate(`/tender/${clientRequest.id}/participate-reservation`)
            }
            color={ButtonColor.BLUE}
          >
            Participate with Reservation
          </Button>
          <Button
            className={styles.participateButton}
            type="primary"
            onClick={() => clientRequest && navigate(`/tender/${clientRequest.id}/participate`)}
            color={ButtonColor.GREEN}
          >
            Participate on these Terms
          </Button>
        </div>
      </div>
    </BoxContent>
  );
};

export default TenderViewPage;
