import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Table, { ColumnsType } from 'antd/lib/table';
import { Col, Row } from 'antd';
import DropDown from 'components/ui/DropDown/DropDown';
import Button from 'components/ui/Button/Button';
import { QuestionKey } from 'store/client-request/client-request.types';
import { getWallboxes } from 'store/client-request/client-request.actions';
import { wallboxesModels } from 'store/client-request/client-request.selectors';
import styles from './QuestionnaireTab.module.sass';

export type WalboxValue = {
  id: string;
  name: string;
  vendorName: string;
};

interface IChargingStationLimitQuestionProps {
  onChange?: Function;
  value?: WalboxValue[];
}

const ChargingStationLimitQuestion: React.FC<IChargingStationLimitQuestionProps> = ({
  onChange,
  value,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedWallboxes, setSelectedWallboxes] = useState<WalboxValue[]>(
    Array.isArray(value) ? value : [],
  );
  const [dropdownValue, setDropdownValue] = useState();

  useEffect(() => {
    onChange && onChange(selectedWallboxes);
  }, [selectedWallboxes]); // eslint-disable-line

  const transformWallboxes = (items: any) => {
    const wallboxes: any[] = [];
    items &&
      items.forEach((vendor: any) => {
        vendor?.products?.forEach((wallbox: any) => {
          wallboxes.push({
            id: wallbox.id,
            name: wallbox.name,
            vendorName: vendor.name,
          });
        });
      });
    return wallboxes;
  };

  const wallboxesRaw = useSelector(wallboxesModels);
  const wallboxes = transformWallboxes(wallboxesRaw);

  useEffect(() => {
    dispatch(getWallboxes());
  }, []); // eslint-disable-line

  const removeItem = (itemId: string) => {
    setSelectedWallboxes(selectedWallboxes.filter((item: WalboxValue) => item.id !== itemId));
  };

  const addWallbox = () => {
    if (dropdownValue && !selectedWallboxes.find((item) => item.id === dropdownValue)) {
      const wallbox = wallboxes.find((item) => item.id === dropdownValue);
      setSelectedWallboxes([...selectedWallboxes, wallbox]);
    }
  };

  const tableColumns: ColumnsType<WalboxValue> = [
    {
      title: t('intermediate:myProjects:editProjectPools:vendor'),
      dataIndex: 'vendorName',
      key: 'vendorName',
    },
    {
      title: t('intermediate:myProjects:editProjectPools:wallbox'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      // eslint-disable-next-line react/display-name
      render: (id: string) => (
        <Button danger={true} onClick={() => removeItem(id)} type="primary" size="small">
          {t('intermediate:myProjects:editProjectPools:delete')}
        </Button>
      ),
    },
  ];

  return (
    <div className={styles.limitContainer}>
      <div className={styles.limitQuestionTitle}>
        {t(`customerFlow:questions:${QuestionKey.ChargingStation}:question`)}
      </div>
      <div className={styles.description}>
        {t(`customerFlow:questions:${QuestionKey.ChargingStation}:description`)}
      </div>

      <div className={styles.limitAnswers}>
        <Row className={styles.dropdownRow}>
          <Col span={18}>
            <DropDown
              placeholder={t('intermediate:myProjects:editProjectPools:wallboxes')}
              value={dropdownValue}
              onChange={(v: any) => {
                setDropdownValue(v);
              }}
              className={styles.selectRow}
              options={wallboxes.map((item: any) => ({ value: item.id, label: item.name }))}
            />
          </Col>
          <Col span={6} className={styles.addWallbox}>
            <Button onClick={addWallbox} type="primary">
              {t('intermediate:myProjects:editProjectPools:addWallbox')}
            </Button>
          </Col>
        </Row>
        <Table
          columns={tableColumns}
          dataSource={selectedWallboxes}
          rowKey="id"
          pagination={false}
        />
      </div>
    </div>
  );
};

export default ChargingStationLimitQuestion;
