import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import clsx from 'clsx';
import { Typography } from 'antd';
import styles from './NavLink.module.sass';
import { IconPosition } from '../Button/Button';

interface INavLinkProps {
  to: string;
  title: string;
  className?: any;
  activeClassName?: any;
  icon?: any;
  iconPosition?: IconPosition;
}

const NavLink: React.FC<INavLinkProps> = ({
  to,
  title,
  className,
  icon,
  iconPosition = IconPosition.LEFT,
}) => {
  return (
    <RouterNavLink
      to={to}
      className={({ isActive }) =>
        clsx(className, styles.navLink, {
          [styles.activeNavLink]: isActive,
        })
      }
    >
      {iconPosition === IconPosition.LEFT && <div className={styles.icon}>{icon}</div>}
      <Typography.Text>{title}</Typography.Text>
      {iconPosition === IconPosition.RIGHT && <div className={styles.icon}>{icon}</div>}
      <div className={styles.navLine} />
    </RouterNavLink>
  );
};

export default NavLink;
