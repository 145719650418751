import { createSelector } from 'reselect';
import { IAppState } from './app.types';

export const appSelector = (state: any) => state.app;

// Active step and sub step selectors
export const activeStepSelector = createSelector(appSelector, (app: IAppState) => app.activeStep);

export const activeSubStepSelector = createSelector(
  appSelector,
  (app: IAppState) => app.activeSubStep,
);

// SubSteps visibility
export const subStepsHiddenSelector = createSelector(
  appSelector,
  (app: IAppState) => app.subStepsHidden,
);

export const globalErrorSelector = createSelector(appSelector, (app: IAppState) => app.globalError);
