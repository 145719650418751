import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Col, Row, Typography } from 'antd';

import Loader from 'components/ui/Loader/Loader';
import BackButton from 'components/ui/BackButton/BackButton';
import DateFormat from 'components/ui/DateFormat/DateFormat';

import styles from 'pages/SurveyPage/SurveyPage.module.sass';
import { surveySelector } from 'store/surveys/surveys.selectors';
import { surveyGet } from 'store/surveys/surveys.actions';

import SpecificationWidget from './SpecificationWidget';

const SurveyPage = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const survey = useSelector(surveySelector);

  useEffect(() => {
    if (id) dispatch(surveyGet(id));
  }, []); // eslint-disable-line

  const goBack = () => {
    navigate(-1);
  };

  const renderContent = () => {
    if (!survey) {
      return <Loader />;
    }

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <BackButton className={styles.back} onClick={goBack}>
            {t('common:buttons:back')}
          </BackButton>
          <div className={styles.titleContainer}>
            <Typography.Title level={3} className={styles.title}>
              {t('intermediate:myProjects:survey')} {survey.displayId}
            </Typography.Title>
            <div className={styles.publishDate}>
              <span className={styles.label}>Survey</span>
              <span className={styles.text}>
                {t('installerFlow:tenderDetails:published')}{' '}
                <DateFormat date={survey.createdAt as Date} />
              </span>
            </div>
          </div>
        </div>

        <Row gutter={[24, 24]} key="tender-section-contact">
          <Col span={24} key="tender-section-specification">
            <SpecificationWidget survey={survey} />
          </Col>
        </Row>
      </div>
    );
  };

  return renderContent();
};

export default SurveyPage;
