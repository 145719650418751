import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal, Form, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import Button, { ButtonColor } from 'components/ui/Button/Button';
import {
  getClientRequestFiles,
  getClientRequestHouseType,
} from 'store/client-request/client-request.actions';
import {
  currentClientRequestFilesSelector,
  currentClientRequestHouseTypeSelector,
} from 'store/client-request/client-request.selectors';
import { MediaCategoryType } from 'store/client-request/client-request.types';

import styles from './FotoWizardPage.module.sass';
import UploadDocumentBody from '../UploadDocumentsStep/UploadDocumentBody';

const FotoWizardWebView = () => {
  const [isModal, setModal] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { t } = useTranslation();

  const requestFiles = useSelector(currentClientRequestFilesSelector);
  const houseType = useSelector(currentClientRequestHouseTypeSelector);

  const { id, token } = useParams();

  useEffect(() => {
    if (!id || !token) return;
    dispatch(getClientRequestFiles(id, undefined, token));
    dispatch(getClientRequestHouseType(id, token));
  }, [id, dispatch, token]);

  useEffect(() => {
    form.setFieldsValue(requestFiles);
  }, [form, requestFiles]);

  const isContinue = useMemo(
    () => !requestFiles[MediaCategoryType.RequestFuseBox]?.length,
    [requestFiles],
  );
  const isAppartments = useMemo(() => houseType === 'apartment', [houseType]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography.Title level={4}>
          {t('customerFlow:clientRequest:fileUpload:headline')}
        </Typography.Title>
        <Trans i18nKey="customerFlow:clientRequest:fileUpload:text" />
      </div>
      <Form form={form}>
        <UploadDocumentBody token={token} requestId={id} fotoWizard appartments={isAppartments} />
      </Form>
      <div className={styles.button}>
        <Button
          color={ButtonColor.BLUE}
          disabled={isContinue}
          type={isContinue ? 'default' : 'primary'}
          onClick={() => setModal(true)}
        >
          {t('common:buttons:further')}
        </Button>
      </div>
      <Modal
        open={isModal}
        onCancel={() => setModal(false)}
        footer={[
          <Button
            key="confirmButton"
            color={ButtonColor.GREEN}
            onClick={() => setModal(false)}
            type="primary"
          >
            Ok
          </Button>,
        ]}
        title={t('customerFlow:clientRequest:fotoWizard:modal:title')}
      >
        {t('customerFlow:clientRequest:fotoWizard:modal:body')}
      </Modal>
    </div>
  );
};

export default FotoWizardWebView;
