import { RequestResult } from 'types/crud-view.types';
import {
  IPDFTemplate,
  PDF_TEMPLATES_GET,
  PDF_TEMPLATES_GET_ERROR,
  PDF_TEMPLATES_GET_SUCCESS,
  PDF_TEMPLATE_CREATE,
  PDF_TEMPLATE_CREATE_ERROR,
  PDF_TEMPLATE_CREATE_SUCCESS,
  PDF_TEMPLATE_DELETE,
  PDF_TEMPLATE_DELETE_ERROR,
  PDF_TEMPLATE_DELETE_SUCCESS,
  PDF_TEMPLATE_UPDATE,
  PDF_TEMPLATE_UPDATE_ERROR,
  PDF_TEMPLATE_UPDATE_SUCCESS,
} from './pdf-templates.types';

export const PDFTemplateCreate = (
  poolId: string,
  pdfTemplate: IPDFTemplate,
  onRequestDone?: (status: RequestResult) => void,
) => ({
  type: PDF_TEMPLATE_CREATE,
  payload: {
    poolId,
    pdfTemplate,
    onRequestDone,
  },
});

export const PDFTemplateCreateSuccess = (payload: IPDFTemplate) => ({
  type: PDF_TEMPLATE_CREATE_SUCCESS,
  payload: payload,
});

export const PDFTemplateCreateError = (error: any) => ({
  type: PDF_TEMPLATE_CREATE_ERROR,
  payload: error,
});

export const PDFTemplateUpdate = (
  id: string,
  pdfTemplate: IPDFTemplate,
  onRequestDone?: (status: RequestResult) => void,
) => ({
  type: PDF_TEMPLATE_UPDATE,
  payload: {
    id,
    body: pdfTemplate,
    onRequestDone,
  },
});

export const PDFTemplateUpdateSuccess = (payload: IPDFTemplate) => ({
  type: PDF_TEMPLATE_UPDATE_SUCCESS,
  payload: payload,
});

export const PDFTemplateUpdateError = (error: any) => ({
  type: PDF_TEMPLATE_UPDATE_ERROR,
  payload: error,
});

export const PDFTemplateDelete = (id: string, onRequestDone?: (status: RequestResult) => void) => ({
  type: PDF_TEMPLATE_DELETE,
  payload: { id: id, onRequestDone },
});

export const PDFTemplateDeleteSuccess = (id: string) => ({
  type: PDF_TEMPLATE_DELETE_SUCCESS,
  payload: id,
});

export const PDFTemplateDeleteError = (error: any) => ({
  type: PDF_TEMPLATE_DELETE_ERROR,
  payload: error,
});

export const PDFTemplateGet = (payload: string) => ({
  type: PDF_TEMPLATES_GET,
  payload: payload,
});

export const PDFTemplateGetSuccess = (payload: IPDFTemplate[]) => ({
  type: PDF_TEMPLATES_GET_SUCCESS,
  payload: payload,
});

export const PDFTemplateGetError = (error: any) => ({
  type: PDF_TEMPLATES_GET_ERROR,
  payload: error,
});
