import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import Form from 'antd/lib/form';
import TextField from 'components/ui/TextField/TextField';
import { ValidationFactory } from 'framework/validations';
import { changePassword } from 'store/auth/auth.actions';
import EditTabContainer from './EditTabContainer';
import styles from './EditTabContainer.module.sass';

export interface IChangePasswordProps {
  user: any;
  onCancel(): void;
}

const ChangePasswordTab: React.FC<IChangePasswordProps> = ({ user, onCancel }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const passwordRepeatValidator = ({ getFieldValue }: any) => ({
    validator(rule: any, value: string) {
      if (!value || getFieldValue('newPassword') === value) {
        return Promise.resolve();
      }
      return Promise.reject(t('common:errors:passwordsNotSame'));
    },
  });

  const [form] = Form.useForm();

  const FIELDS = [
    {
      name: 'currentPassword',
      label: t('profile:editPassword:currentPassword'),
      rules: [ValidationFactory.REQUIRED],
    },
    {
      name: 'newPassword',
      label: t('profile:editPassword:newPassword'),
      rules: [
        ValidationFactory.REQUIRED,
        ValidationFactory.PASSWORD,
        ValidationFactory.PASSWORD_MIN_LENGTH,
      ],
    },
    {
      name: 'newPasswordConfirmation',
      label: t('profile:editPassword:repeatPassword'),
      rules: [ValidationFactory.REQUIRED, passwordRepeatValidator],
      dependencies: ['newPassword'],
    },
  ];

  const onSuccess = () => {
    setIsLoading(false);
    form.resetFields();

    notification.success({
      message: t('profile:successMessage'),
      duration: 3,
    });
  };

  const onError = () => {
    setIsLoading(false);
  };

  const onFormSubmit = (values: any) => {
    dispatch(
      changePassword({
        ...values,
        onSuccess,
        onError,
      }),
    );
  };

  const renderFields = () =>
    FIELDS.map((field) => (
      <Form.Item
        key={field.name}
        name={field.name}
        rules={field.rules}
        trigger="onInput"
        dependencies={field.dependencies}
      >
        <TextField
          className={styles.textField}
          label={field.label}
          name={field.name}
          type="password"
        />
      </Form.Item>
    ));

  const formProps = {
    form,
    onFinish: onFormSubmit,
    name: 'change-password',
  };

  const containerProps = {
    onCancel,
    title: t('profile:fixPassword'),
    saveDisabled: isLoading,
    onSave: () => form.submit(),
    onFinish: onFormSubmit,
  };

  return (
    <EditTabContainer {...containerProps}>
      <Form {...formProps}>{renderFields()}</Form>
    </EditTabContainer>
  );
};

export default ChangePasswordTab;
