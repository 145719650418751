import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/lib/typography';
import InfoBox, { InfoBoxColor } from 'components/ui/InfoBox/InfoBox';
import DeleteAccountModal from 'components/modals/DeleteAccountModal';
import { userDeleteAccount } from 'store/user/user.actions';
import { isCustomerSelector } from 'store/auth/auth.selectors';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import { clientRequestsAllGet } from 'store/client-request/client-request.actions';
import { ClientRequestState } from 'store/client-request/client-request.types';
import EditTabContainer from './EditTabContainer';

import styles from './EditTabContainer.module.sass';

export interface IDeleteAccountTabProps {
  user: any;
  onCancel(): void;
}

const DeleteAccountTab: React.FC<IDeleteAccountTabProps> = ({ onCancel }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const clientRequest = useSelector(currentClientRequestSelector);
  const isCustomer = useSelector(isCustomerSelector);

  useEffect(() => {
    if (isCustomer && !clientRequest) {
      dispatch(clientRequestsAllGet());
    }
  }, []); // eslint-disable-line

  const handleOk = () => {
    dispatch(userDeleteAccount());
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const checkTheDeals = () => {
    let allow = true;

    // in the future we will have conditions not only for customer
    if (isCustomer) {
      // For customer we allow to delete account just when CR is done
      allow = clientRequest?.state === ClientRequestState.Done;
    }

    return allow;
  };

  const allowToDelete = checkTheDeals();

  const containerProps = {
    onCancel,
    title: t('profile:deleteAccount'),
    saveButtonText: t('profile:deleteAccount'),
    saveButtonType: 'danger',
    saveDisabled: !allowToDelete,
    onSave: () => {
      setIsModalVisible(true);
    },
  };

  return (
    <>
      <EditTabContainer {...containerProps}>
        <InfoBox color={InfoBoxColor.RED} className={styles.alertBox}>
          <Typography.Paragraph>
            {allowToDelete ? t('profile:warningDeleteAccount') : t('profile:banDeleteAccount')}
          </Typography.Paragraph>
        </InfoBox>
      </EditTabContainer>
      <DeleteAccountModal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        modalText={t('profile:confirmDeleteAccount')}
      />
    </>
  );
};

export default DeleteAccountTab;
