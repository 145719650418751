import React from 'react';
import { Form, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import TextField from 'components/ui/TextField/TextField';
import MultiLangInput from 'components/ui/MultiLangEntries/MultiLangInput';
import MultiLangEditor from 'components/ui/MultiLangEntries/MultiLangEditor';
import { ValidationFactory } from 'framework/validations';

import styles from '../../Products.module.sass';
interface IProductProps {
  form?: FormInstance;
}
const Product = (props: IProductProps) => {
  const { t } = useTranslation();
  const key = 'intermediate:myProjects:product:form:product:';
  const { form } = props;
  return (
    <>
      <Form.Item label={t(`${key}title:label`)}>
        {form && (
          <MultiLangInput
            fieldName="localizedTitles"
            label={t(`${key}title:placeholder`)}
            rules={[ValidationFactory.REQUIRED]}
            form={form}
          />
        )}
      </Form.Item>
      <Form.Item
        label={t(`${key}reference:label`)}
        name="referenceId"
        rules={[ValidationFactory.REQUIRED]}
        className={styles.input}
      >
        <TextField placeholder={t(`${key}reference:placeholder`)} className={styles.input} />
      </Form.Item>
      <Form.Item label={t(`${key}description:label`)}>
        {form && (
          <MultiLangEditor
            fieldName="localizedDescriptions"
            form={form}
            rules={[ValidationFactory.EDITOR_REQUIRED]}
          />
        )}
      </Form.Item>
    </>
  );
};

export default Product;
