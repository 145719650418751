import React from 'react';
import { Button, Card, Descriptions, Flex, message, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  clientRequestStateSelector,
  currentClientRequestSelector,
} from 'store/client-request/client-request.selectors';
import { currentBundlesSelector } from 'store/bundle/bundle.selectors';
import { IProduct } from 'store/product/product.types';
import { poolsSelector } from 'store/intermediate/intermediate.selectors';
import { IPool } from 'store/intermediate/intermediate.types';
import { clientRequestUpdate } from 'store/client-request/client-request.actions';
import { RequestState } from 'store/common.types';
import ProductComponentWidget from '../ProductComponentWidget/ProductComponentWidget';
import { BundleView } from '../SpecificationWidget/BundleView/BundleView';

type TChangeReviewProps = {
  newBundleId?: string;
  products?: IProduct[];
  destPoolId?: string;
  closeModal: () => void;
  backHandler: () => void;
};
const ChangesReviewComponent = (props: TChangeReviewProps) => {
  const clientRequest = useSelector(currentClientRequestSelector);
  const clientRequestState = useSelector(clientRequestStateSelector);
  const destPoolBundles = useSelector(currentBundlesSelector);
  const pools = useSelector(poolsSelector) as IPool[];
  const dispatch = useDispatch();
  const locizeKey = `intermediate:tenderDetails:changePoolModal`;
  const { t } = useTranslation();
  const successChangeHandler = () => {
    message.success(t(`${locizeKey}:poolChangedSuccessfully`));
  };
  const submitChangeHandler = () => {
    if (!clientRequest?.id) return;
    dispatch(
      clientRequestUpdate(
        clientRequest.id,
        {
          products: props.products ? props.products : null,
          bundle: props.newBundleId ? { id: props.newBundleId } : null,
          pool: { id: props.destPoolId },
        },
        successChangeHandler,
      ),
    );
  };
  const newBundle = destPoolBundles.find((_bundle) => _bundle.id === props.newBundleId);
  return (
    <Flex gap={8} vertical={true}>
      <Card title={t(`${locizeKey}:currentBundle`)}>
        {clientRequest?.bundle ? (
          <BundleView bundle={clientRequest.bundle} />
        ) : (
          <Typography.Text>{t(`${locizeKey}:noBundle`)}</Typography.Text>
        )}
      </Card>
      <Card title={t(`${locizeKey}:newBundle`)}>
        {newBundle ? (
          <BundleView bundle={newBundle} />
        ) : (
          <Typography.Text>{t(`${locizeKey}:noBundle`)}</Typography.Text>
        )}
      </Card>
      <Card title={t(`${locizeKey}:currentProducts`)}>
        {clientRequest?.products?.length && clientRequest.products.length > 0 ? (
          <ProductComponentWidget poolOperator={true} products={clientRequest?.products || []} />
        ) : (
          <Typography.Text>{t(`${locizeKey}:noProducts`)}</Typography.Text>
        )}
      </Card>
      <Card title={t(`${locizeKey}:newProducts`)}>
        {props.products?.length && props.products?.length > 0 ? (
          <ProductComponentWidget poolOperator={true} products={props.products} />
        ) : (
          <Typography.Text>{t(`${locizeKey}:noProducts`)}</Typography.Text>
        )}
      </Card>
      <Card title={t(`${locizeKey}:pool`)}>
        <Descriptions>
          <Descriptions.Item label={t(`${locizeKey}:current`)}>
            {clientRequest?.pool?.name}
          </Descriptions.Item>
          <Descriptions.Item label={t(`${locizeKey}:new`)}>
            {pools.find((_pool) => _pool.id === props.destPoolId)?.name}
          </Descriptions.Item>
        </Descriptions>
        <Typography.Title level={5}>{t(`${locizeKey}:areYouSure`)}</Typography.Title>
      </Card>

      <Flex justify={'space-between'}>
        <Button onClick={props.backHandler}>{t(`${locizeKey}:back`)}</Button>

        <Flex gap={4}>
          <Button onClick={props.closeModal}>{t(`${locizeKey}:no`)}</Button>
          <Button
            loading={clientRequestState === RequestState.Saving}
            onClick={submitChangeHandler}
            type={'primary'}
          >
            {t(`${locizeKey}:yes`)}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default ChangesReviewComponent;
