import { put, all, select, takeLatest, call } from 'redux-saga/effects';
import { AUTH_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'framework/constants';
import { decodeToken } from 'framework/jwt';
import { transformInstallerBeforeRequest } from 'framework/dataTransformations';
import AuthAPI from 'services/auth.service';
import InviteAPI from 'services/invite.service';
import { IState } from 'store/common.types';
import {
  REGISTER_INSTALLER,
  VALIDATE_INVITE_TOKEN,
  RegisterInstallerAction,
  ValidateInviteTokenAction,
} from './register.types';
import {
  registerInstallerError,
  registerInstallerSuccess,
  validateInviteTokenError,
  validateInviteTokenSuccess,
} from './register.actions';
import { loginSuccess } from '../auth/auth.actions';

export function* registerInstaller(action: RegisterInstallerAction) {
  try {
    const { user, token } = action.payload;
    const data: any = transformInstallerBeforeRequest(user, token);
    const state: IState = yield select();

    // REMOVE IT BEFORE COMMIT
    data.organization.isTest = false;

    const { accessToken, refreshToken } = yield call(AuthAPI.registerInstaller, {
      ...data,
      fingerprint: state?.auth?.fingerprint,
    });
    const createdUser = decodeToken(accessToken);

    localStorage.setItem(AUTH_TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

    yield put(registerInstallerSuccess());
    yield put(loginSuccess(createdUser, accessToken));
  } catch (err) {
    yield put(registerInstallerError(err));
  }
}

export function* validateToken(action: ValidateInviteTokenAction) {
  try {
    const data: string = yield call(InviteAPI.validate, action.payload.token);
    yield put(validateInviteTokenSuccess(data));
  } catch (error) {
    yield put(validateInviteTokenError(error));
  }
}

export default function* root() {
  yield all([
    takeLatest(REGISTER_INSTALLER, registerInstaller),
    takeLatest(VALIDATE_INVITE_TOKEN, validateToken),
  ]);
}
