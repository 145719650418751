import {
  SURVEYS_GET,
  SURVEYS_GET_ERROR,
  SURVEYS_GET_SUCCESS,
  SURVEY_GET,
  SURVEY_GET_SUCCESS,
  SURVEY_GET_ERROR,
  SurveysListType,
  ISurvey,
  SURVEY_CREATE,
  SURVEY_CREATE_SUCCESS,
  SURVEY_CREATE_ERROR,
} from './surveys.types';

//Get Surveys
export const surveysGet = (poolId: string) => ({
  type: SURVEYS_GET,
  payload: poolId,
});

export const surveysGetSuccess = (payload: SurveysListType) => ({
  type: SURVEYS_GET_SUCCESS,
  payload: payload,
});

export const surveysGetError = (error: any) => ({
  type: SURVEYS_GET_ERROR,
  payload: error,
});

// Get Survey
export const surveyGet = (payload: string) => ({
  type: SURVEY_GET,
  payload,
});

export const surveyGetSuccess = (payload: ISurvey) => ({
  type: SURVEY_GET_SUCCESS,
  payload: payload,
});

export const surveyGetError = (error: any) => ({
  type: SURVEY_GET_ERROR,
  payload: error,
});

// Create Survey
export const surveyCreate = (payload: ISurvey) => ({
  type: SURVEY_CREATE,
  payload,
});

export const surveyCreateSuccess = (payload: ISurvey) => ({
  type: SURVEY_CREATE_SUCCESS,
  payload: payload,
});

export const surveyCreateError = (error: any) => ({
  type: SURVEY_CREATE_ERROR,
  payload: error,
});
