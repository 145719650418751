/* eslint-disable react/display-name */
import React from 'react';
import { default as AntTable, TableProps, TablePaginationConfig } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './Table.module.sass';

interface ITableProps {
  className?: string;
  emptyText?: string;
  pagination?: false | TablePaginationConfig;
  onRowClick?: (row: any) => string | void;
}

const Table: React.FC<ITableProps & TableProps<any>> = ({
  emptyText = 'common:table:empty',
  pagination = false,
  columns,
  onRowClick,
  ...rest
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const customLocale = {
    emptyText: <span className={styles.empty}>{t(emptyText)}</span>,
  };
  const handleRowClick = (item: any) => {
    const path = onRowClick?.(item);
    if (path) navigate(path);
  };

  const tableProps = {
    ...rest,
    pagination,
    onRow: (item: any) => ({
      onClick: () => handleRowClick(item),
    }),
    columns: columns,
    locale: {
      ...customLocale,
      ...rest.locale,
    },
  };

  return <AntTable {...tableProps} />;
};

export default Table;
