import React, { useMemo, useState } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { IOffer } from 'store/offer/offer.types';
import { IClientRequest } from 'store/client-request/client-request.types';

import { checkIsHomeCheck, checkIsInstallation, checkIsRejected } from 'utils/offerHelpers';
import OfferItem from './OfferItem';
import styles from '../../ClientRequestPage.module.sass';
import { EmptyData } from '../../components/EmptyData/EmptyData';

const { TabPane } = Tabs;
export interface ICreatePoolModalProps {
  clientRequest: IClientRequest;
}

const OffersTab: React.FC<ICreatePoolModalProps> = ({ clientRequest }) => {
  const [tab, setTab] = useState('0');
  const { t } = useTranslation();
  const { offers, pool } = clientRequest;

  const homecheckOffers = useMemo(
    () => offers?.filter((offer) => checkIsHomeCheck(offer.state!)),
    [offers],
  );
  const installationOffers = useMemo(
    () => offers?.filter((offer) => checkIsInstallation(offer.state!)),
    [offers],
  );

  const rejectedOffers = useMemo(
    () => offers?.filter((offer) => checkIsRejected(offer.state!)),
    [offers],
  );

  const tabs = [
    { key: 0, offers: homecheckOffers, header: 'homecheckOffer' },
    { key: 1, offers: installationOffers, header: 'installationOffer' },
    { key: 2, offers: rejectedOffers, header: 'rejectedOffer' },
  ];

  return (
    <div className={styles.container}>
      <Tabs activeKey={tab} onTabClick={(tab) => setTab(tab)} className={styles.tabs}>
        {tabs.map((item) => (
          <TabPane key={item.key} tab={t(`intermediate:myProjects:${item.header}`)}>
            {item.offers?.length ? (
              item.offers.map((offer: IOffer) => (
                <OfferItem
                  key={item.key}
                  clientRequest={clientRequest}
                  offer={offer}
                  pool={pool || null}
                />
              ))
            ) : (
              <EmptyData />
            )}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default OffersTab;
