import {
  IClientRequest,
  IClientRequestAnswer,
  ClientRequestTransitionTypes,
  IClientRequests,
  IClientRequestSearchResults,
  IDocument,
  IGroupedFiles,
  MediaCategoryType,
  ANSWERS_SAVE,
  ANSWERS_SAVE_ERROR,
  ANSWERS_SAVE_SUCCESS,
  SINGLE_ANSWER_SAVE,
  SINGLE_ANSWER_SAVE_ERROR,
  SINGLE_ANSWER_SAVE_SUCCESS,
  CLIENT_REQUEST_CREATE,
  CLIENT_REQUEST_CREATE_ERROR,
  CLIENT_REQUEST_CREATE_SUCCESS,
  CLIENT_REQUEST_GET,
  CLIENT_REQUEST_GET_ERROR,
  CLIENT_REQUEST_GET_SUCCESS,
  CLIENT_REQUESTS_ALL_GET,
  CLIENT_REQUESTS_ALL_GET_ERROR,
  CLIENT_REQUESTS_ALL_GET_SUCCESS,
  CLIENT_REQUESTS_FIND,
  CLIENT_REQUESTS_FIND_SUCCESS,
  CLIENT_REQUESTS_FIND_ERROR,
  CLIENT_REQUEST_UPDATE,
  CLIENT_REQUEST_UPDATE_ERROR,
  CLIENT_REQUEST_UPDATE_SUCCESS,
  CLIENT_REQUESTS_COST_GET,
  CLIENT_REQUESTS_COST_GET_ERROR,
  CLIENT_REQUESTS_COST_GET_SUCCESS,
  CLIENT_REQUEST_DISTANCE_GET,
  CLIENT_REQUEST_DISTANCE_GET_ERROR,
  CLIENT_REQUEST_DISTANCE_GET_SUCCESS,
  UPDATE_TRANSITION_STATE,
  UPDATE_TRANSITION_STATE_ERROR,
  UPDATE_TRANSITION_STATE_SUCCESS,
  ANSWERS_GET,
  ANSWERS_GET_SUCCESS,
  ANSWERS_GET_ERROR,
  SINGLE_ANSWER_SET,
  ANSWERS_SET,
  UPLOAD_CLIENT_REQUEST_FILE,
  UPLOAD_CLIENT_REQUEST_FILE_PROGRESS,
  UPLOAD_CLIENT_REQUEST_FILE_SUCCESS,
  UPLOAD_CLIENT_REQUEST_FILE_ERROR,
  GET_CLIENT_REQUEST_FILES,
  GET_CLIENT_REQUEST_FILES_SUCCESS,
  GET_CLIENT_REQUEST_FILES_ERROR,
  DELETE_FILE,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_ERROR,
  GET_FILE_LINK,
  GET_FILE_LINK_SUCCESS,
  GET_FILE_LINK_ERROR,
  DOWNLOAD_FILE_BY_LINK,
  GET_WALLBOXES,
  GET_WALLBOXES_ERROR,
  GET_WALLBOXES_SUCCESS,
  CREATE_VENDOR,
  CREATE_VENDOR_ERROR,
  MY_CLIENT_REQUESTS_GET,
  MY_CLIENT_REQUESTS_GET_SUCCESS,
  MY_CLIENT_REQUESTS_GET_ERROR,
  ADD_COMMENT,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_ERROR,
  GET_COMMENTS_ERROR,
  GET_COMMENTS,
  GET_COMMENTS_SUCCESS,
  QuestionType,
  AddCommentPayloadType,
  UploadFileConfig,
  UPDATE_COMMENT,
  UPDATE_COMMENT_SUCCESS,
  UPDATE_COMMENT_ERROR,
  UpdateCommentPayloadType,
  GET_INVOICES_SUCCESS,
  GET_INVOICES,
  GET_INVOICES_ERROR,
  OfferDocument,
  CREATE_PRODUCT,
  CREATE_PRODUCT_ERROR,
  DOWNLOAD_ALL_CR_DOCUMENTS,
  DOWNLOAD_ALL_CR_DOCUMENTS_ERROR,
  CLIENT_REQUEST_ARCHIVE,
  CLIENT_REQUEST_ARCHIVE_SUCCESS,
  CLIENT_REQUEST_ARCHIVE_ERROR,
  GET_CUSTOMER_DOCUMENT_UPLOAD_TOKEN,
  FOTO_WIZARD_UPLOAD_TOKEN_GET_SUCCESS,
  FOTO_WIZARD_UPLOAD_TOKEN_GET_ERROR,
  ASSIGN_BUNDLE_TO_REQUEST,
  ASSIGN_BUNDLE_TO_REQUEST_ERROR,
  ASSIGN_BUNDLE_TO_REQUEST_SUCCESS,
  GET_CLIENT_REQUEST_HOUSE_TYPE,
  GET_CLIENT_REQUEST_HOUSE_TYPE_SUCCESS,
  GET_CLIENT_REQUEST_HOUSE_TYPE_ERROR,
  DELETE_LANDING_COVER_IMAGE,
  DELETE_LANDING_COVER_IMAGE_SUCCESS,
  DELETE_LANDING_COVER_IMAGE_ERROR,
} from './client-request.types';

//Get Invoices
export const invoicesGet = () => ({
  type: GET_INVOICES,
});

export const invoicesGetSuccess = (payload: OfferDocument[]) => ({
  type: GET_INVOICES_SUCCESS,
  payload: payload,
});

export const invoicesGetError = (error: any) => ({
  type: GET_INVOICES_ERROR,
  payload: error,
});

//Get Client Request
export const clientRequestGet = (
  clientRequestId: string,
  isIntermediate: boolean,
  shouldFetchOffers = true,
) => ({
  type: CLIENT_REQUEST_GET,
  payload: {
    clientRequestId,
    isIntermediate,
    shouldFetchOffers,
  },
});

export const clientRequestGetSuccess = (payload: IClientRequest) => ({
  type: CLIENT_REQUEST_GET_SUCCESS,
  payload: payload,
});

export const clientRequestGetError = (error: any) => ({
  type: CLIENT_REQUEST_GET_ERROR,
  payload: error,
});

export const myClientRequestGet = () => ({
  type: MY_CLIENT_REQUESTS_GET,
});

export const myClientRequestGetSuccess = (payload: IClientRequest[]) => ({
  type: MY_CLIENT_REQUESTS_GET_SUCCESS,
  payload: payload,
});

export const myClientRequestGetError = (error: any) => ({
  type: MY_CLIENT_REQUESTS_GET_ERROR,
  payload: error,
});

export const clientRequestsAllGet = () => ({
  type: CLIENT_REQUESTS_ALL_GET,
});

export const clientRequestsAllGetSuccess = (payload: IClientRequest) => ({
  type: CLIENT_REQUESTS_ALL_GET_SUCCESS,
  payload: payload,
});

export const clientRequestsAllGetError = (error: any) => ({
  type: CLIENT_REQUESTS_ALL_GET_ERROR,
  payload: error,
});

//Find Client Requests
export const clientRequestsFind = (payload?: IClientRequests) => ({
  type: CLIENT_REQUESTS_FIND,
  payload: payload,
});

export const clientRequestsFindSuccess = (payload: IClientRequestSearchResults) => ({
  type: CLIENT_REQUESTS_FIND_SUCCESS,
  payload: payload,
});

export const clientRequestsFindError = (error: any) => ({
  type: CLIENT_REQUESTS_FIND_ERROR,
  payload: error,
});

export const clientRequestArchive = (clientRequestId: string, archived: boolean) => ({
  type: CLIENT_REQUEST_ARCHIVE,
  payload: { clientRequestId, archived },
});

export const clientRequestArchiveSuccess = (payload: any) => ({
  type: CLIENT_REQUEST_ARCHIVE_SUCCESS,
  payload: payload,
});

export const clientRequestArchiveError = (error: any) => ({
  type: CLIENT_REQUEST_ARCHIVE_ERROR,
  payload: error,
});

// Create Client Request
export const clientRequestCreate = (
  clientRequest: IClientRequest,
  answers?: IClientRequestAnswer[],
) => ({
  type: CLIENT_REQUEST_CREATE,
  payload: { clientRequest, answers },
});

export const clientRequestCreateSuccess = (payload: IClientRequest) => ({
  type: CLIENT_REQUEST_CREATE_SUCCESS,
  payload: payload,
});

export const clientRequestCreateError = (error: any) => ({
  type: CLIENT_REQUEST_CREATE_ERROR,
  payload: error,
});

// Update Client Request data
export const clientRequestUpdate = (
  clientRequestId: string,
  fields: any,
  onSuccess?: Function,
  onError?: Function,
) => ({
  type: CLIENT_REQUEST_UPDATE,
  payload: { clientRequestId, fields, onSuccess, onError },
});

export const clientRequestUpdateSuccess = (payload: any) => ({
  type: CLIENT_REQUEST_UPDATE_SUCCESS,
  payload,
});

export const clientRequestUpdateError = (error: any) => ({
  type: CLIENT_REQUEST_UPDATE_ERROR,
  payload: error,
});

// Get client request cost
export const clientRequestCostGet = (clientRequestId: string) => ({
  type: CLIENT_REQUESTS_COST_GET,
  payload: { clientRequestId },
});

export const clientRequestCostGetSuccess = (payload: any) => ({
  type: CLIENT_REQUESTS_COST_GET_SUCCESS,
  payload,
});

export const clientRequestCostGetError = (error: any) => ({
  type: CLIENT_REQUESTS_COST_GET_ERROR,
  payload: error,
});

// Get client request distance
export const clientRequestDistanceGetAction = (clientRequestId: string) => ({
  type: CLIENT_REQUEST_DISTANCE_GET,
  payload: { clientRequestId },
});

export const clientRequestDistanceGetSuccessAction = (payload: any) => ({
  type: CLIENT_REQUEST_DISTANCE_GET_SUCCESS,
  payload,
});

export const clientRequestDistanceGetErrorAction = (error: any) => ({
  type: CLIENT_REQUEST_DISTANCE_GET_ERROR,
  payload: error,
});

//Get Bulk Answers
export const clientRequestAnswersGet = (clientRequestId: string) => ({
  type: ANSWERS_GET,
  payload: clientRequestId,
});

export const clientRequestAnswersGetSuccess = (answers: IClientRequestAnswer[]) => ({
  type: ANSWERS_GET_SUCCESS,
  payload: answers,
});

export const clientRequestAnswersGetError = (error: any) => ({
  type: ANSWERS_GET_ERROR,
  payload: error,
});

//Set Bulk Answers only, does not save
export const clientRequestAnswersSet = (answers: IClientRequestAnswer[]) => ({
  type: ANSWERS_SET,
  payload: answers,
});

//Save Bulk Answers
export const clientRequestAnswersSave = (
  clientRequestId: string,
  answers: IClientRequestAnswer[],
  onSuccess?: Function,
  onError?: Function,
) => ({
  type: ANSWERS_SAVE,
  payload: { clientRequestId, answers, onSuccess, onError },
});

export const clientRequestAnswersSaveSuccess = (answers: IClientRequestAnswer[]) => ({
  type: ANSWERS_SAVE_SUCCESS,
  payload: answers,
});

export const clientRequestAnswersSaveError = (error: any) => ({
  type: ANSWERS_SAVE_ERROR,
  payload: error,
});

//Set Single Answer
export const clientRequestAnswerSet = (answer: IClientRequestAnswer) => ({
  type: SINGLE_ANSWER_SET,
  payload: answer,
});

//Save Single Answer
export const clientRequestAnswerSave = (clientRequestId: string, answer: IClientRequestAnswer) => ({
  type: SINGLE_ANSWER_SAVE,
  payload: { clientRequestId, answer },
});

export const clientRequestAnswerSaveSuccess = (answers: IClientRequestAnswer) => ({
  type: SINGLE_ANSWER_SAVE_SUCCESS,
  payload: answers,
});

export const clientRequestAnswerSaveError = (error: any) => ({
  type: SINGLE_ANSWER_SAVE_ERROR,
  payload: error,
});

// Accept transition
export const updateTransitionState = (
  id: string,
  type: ClientRequestTransitionTypes,
  rejectReason?: string,
  onSuccess?: () => void,
  data?: Partial<IClientRequest>,
) => ({
  type: UPDATE_TRANSITION_STATE,
  payload: {
    id,
    type,
    rejectReason,
    onSuccess,
    data,
  },
});

export const updateTransitionStateSuccess = (payload: any) => ({
  type: UPDATE_TRANSITION_STATE_SUCCESS,
  payload,
});

export const updateTransitionStateError = (error: any) => ({
  type: UPDATE_TRANSITION_STATE_ERROR,
  payload: error,
});

// Upload file actions
export const uploadFile = (
  document: File,
  requestId: string,
  category: MediaCategoryType,
  config: UploadFileConfig,
  uploadProgress?: any,
  uploadSuccess?: any,
  uploadError?: any,
  isFotoWizard?: boolean,
  token?: string,
) => ({
  type: UPLOAD_CLIENT_REQUEST_FILE,
  payload: {
    document,
    requestId,
    category,
    config,
    uploadProgress,
    uploadSuccess,
    uploadError,
    isFotoWizard,
    token,
  },
});

export const uploadFileProgress = (percentCompleted: number) => ({
  type: UPLOAD_CLIENT_REQUEST_FILE_PROGRESS,
  payload: percentCompleted,
});

export const uploadFileSuccess = (document: IDocument) => ({
  type: UPLOAD_CLIENT_REQUEST_FILE_SUCCESS,
  payload: document,
});

export const uploadFileError = (error: any) => ({
  type: UPLOAD_CLIENT_REQUEST_FILE_ERROR,
  payload: error,
});

// Get client request files actions
export const getClientRequestFiles = (
  requestId: string,
  category?: MediaCategoryType,
  token?: string,
) => ({
  type: GET_CLIENT_REQUEST_FILES,
  payload: {
    requestId,
    category,
    token,
  },
});

export const getClientRequestHouseType = (id: string, token: string) => ({
  type: GET_CLIENT_REQUEST_HOUSE_TYPE,
  payload: { id, token },
});

export const getClientRequestHouseTypeSuccess = (houseType: string) => ({
  type: GET_CLIENT_REQUEST_HOUSE_TYPE_SUCCESS,
  payload: houseType,
});
export const getClientRequestHouseTypeError = (error: any) => ({
  type: GET_CLIENT_REQUEST_HOUSE_TYPE_ERROR,
  payload: error,
});
// Get client request files actions
export const getCustomerDocumentUploadToken = (requestId: string) => ({
  type: GET_CUSTOMER_DOCUMENT_UPLOAD_TOKEN,
  payload: {
    requestId,
  },
});
export const getCustomerDocumentUploadTokenSuccess = (token: any) => ({
  type: FOTO_WIZARD_UPLOAD_TOKEN_GET_SUCCESS,
  payload: {
    token,
  },
});

export const getCustomerDocumentUploadTokenError = (error: any) => ({
  type: FOTO_WIZARD_UPLOAD_TOKEN_GET_ERROR,
  payload: error,
});
export const getClientRequestFilesSuccess = (
  files: IGroupedFiles,
  category?: MediaCategoryType,
) => ({
  type: GET_CLIENT_REQUEST_FILES_SUCCESS,
  payload: {
    files,
    category,
  },
});

export const getClientRequestFilesError = (error: any) => ({
  type: GET_CLIENT_REQUEST_FILES_ERROR,
  payload: error,
});

// Delete file actions
export const deleteFile = (
  fileName: string,
  fileId: string,
  category: MediaCategoryType,
  onSuccess?: any,
) => ({
  type: DELETE_FILE,
  payload: { fileName, fileId, category, onSuccess },
});

export const deleteFileSuccess = (fileId: string, category: MediaCategoryType) => ({
  type: DELETE_FILE_SUCCESS,
  payload: { fileId, category },
});

export const deleteFileError = (error: any) => ({
  type: DELETE_FILE_ERROR,
  payload: error,
});

export const deleteLandingCoverImage = (
  fileName: string,
  fileId: string,
  category: MediaCategoryType,
) => ({
  type: DELETE_LANDING_COVER_IMAGE,
  payload: { fileId, fileName, category },
});

export const deleteLandingCoverImageSuccess = (fileId: string, category: MediaCategoryType) => ({
  type: DELETE_LANDING_COVER_IMAGE_SUCCESS,
  payload: { fileId, category },
});

export const deleteLandingCoverImageError = (error: any) => ({
  type: DELETE_LANDING_COVER_IMAGE_ERROR,
  payload: error,
});

export const getFileLink = (fileName: string) => ({
  type: GET_FILE_LINK,
  payload: { fileName },
});

export const getFileLinkSuccess = (fileLink: string, fileName?: string) => ({
  type: GET_FILE_LINK_SUCCESS,
  payload: { fileLink, fileName },
});

export const getFileLinkError = (error: any) => ({
  type: GET_FILE_LINK_ERROR,
  payload: error,
});

export const downloadFileByLink = (fileLink: string) => ({
  type: DOWNLOAD_FILE_BY_LINK,
  payload: { fileLink },
});

// Get Wallboxes
export const getWallboxes = () => ({
  type: GET_WALLBOXES,
  payload: {},
});

export const getWallboxesSuccess = (wallboxes: any) => ({
  type: GET_WALLBOXES_SUCCESS,
  payload: wallboxes,
});

export const getWallboxesError = (error: any) => ({
  type: GET_WALLBOXES_ERROR,
  payload: { error },
});

// Create Vendor
export const createVendor = (name: string) => ({
  type: CREATE_VENDOR,
  payload: name,
});

export const createVendorError = (error: any) => ({
  type: CREATE_VENDOR_ERROR,
  payload: { error },
});

// Create Product
export const createProduct = (id: string, name: string) => ({
  type: CREATE_PRODUCT,
  payload: { id, name },
});

export const createProductError = (error: any) => ({
  type: CREATE_PRODUCT_ERROR,
  payload: { error },
});

export const getComments = (id: string) => ({
  type: GET_COMMENTS,
  payload: { id },
});

export const getCommentsSuccess = (questions: QuestionType[]) => ({
  type: GET_COMMENTS_SUCCESS,
  payload: questions,
});

export const getCommentsError = (error: any) => ({
  type: GET_COMMENTS_ERROR,
  payload: { error },
});

export const addComment = (payload: AddCommentPayloadType) => ({
  type: ADD_COMMENT,
  payload,
});

export const addCommentSuccess = (question: any) => ({
  type: ADD_COMMENT_SUCCESS,
  payload: question,
});

export const addCommentError = (error: any) => ({
  type: ADD_COMMENT_ERROR,
  payload: { error },
});

export const updateComment = (payload: UpdateCommentPayloadType) => ({
  type: UPDATE_COMMENT,
  payload,
});

export const updateCommentSuccess = (question: any) => ({
  type: UPDATE_COMMENT_SUCCESS,
  payload: question,
});

export const updateCommentError = (error: any) => ({
  type: UPDATE_COMMENT_ERROR,
  payload: { error },
});

//Download All Documents
export const downloadAllDocuments = (clientRequestId: string) => ({
  type: DOWNLOAD_ALL_CR_DOCUMENTS,
  payload: {
    id: clientRequestId,
  },
});

export const downloadAllDocumentsError = (error: any) => ({
  type: DOWNLOAD_ALL_CR_DOCUMENTS_ERROR,
  payload: { error },
});

export const assignBundleToRequest = (id: string, bundleId: string) => ({
  type: ASSIGN_BUNDLE_TO_REQUEST,
  payload: {
    id,
    bundleId,
  },
});

export const assignBundleToRequestSuccess = (bundleId: string) => ({
  type: ASSIGN_BUNDLE_TO_REQUEST_SUCCESS,
  payload: bundleId,
});

export const assignBundleToRequestError = (error: any) => ({
  type: ASSIGN_BUNDLE_TO_REQUEST_ERROR,
  payload: { error },
});
