import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { CheckOutlined } from '@ant-design/icons';
import Button from 'components/ui/Button/Button';
import UploadDocumentModal from 'components/modals/UploadDocumentModal/UploadDocumentModal';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import Loader from 'components/ui/Loader/Loader';
import { currentOrderSelector, getOrderByIdStateSelector } from 'store/orders/orders.selectors';
import { acceptOrder, getOrderById, rejectOrder } from 'store/orders/orders.actions';
import { RequestState } from 'store/common.types';
import { IOrder, OrderState } from 'store/orders/orders.types';

import styles from '../../ClientRequestPage.module.sass';
interface Props {
  order: IOrder;
  onRejectSuccess: () => void;
}

enum Actions {
  Accept = 'accept',
  Reject = 'reject',
}

export const OrderItem: React.FC<Props> = ({ order, onRejectSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentOrder = useSelector(currentOrderSelector);
  const getOrderState = useSelector(getOrderByIdStateSelector);
  // const [details, setDetails] = useState(false);
  const [showUploadDoc, setShowUploadDoc] = useState(false);

  useEffect(() => {
    dispatch(getOrderById(order.id!));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, order]);

  const onActionClick = (action: Actions) => {
    switch (action) {
      case Actions.Accept:
        dispatch(acceptOrder(order.id!));
        break;
      case Actions.Reject:
        dispatch(rejectOrder(order.id!));
        onRejectSuccess();
        break;
    }
  };

  const uploadDocumentModalProps = {
    orderId: order.id,
    visible: showUploadDoc,
    onSubmit: () => setShowUploadDoc(false),
    onCancel: () => setShowUploadDoc(false),
    onSuccess: () => setShowUploadDoc(false),
  };

  // const showMoreProps = {
  //   details: details,
  //   setDetails: setDetails,
  //   headerChild: <InfoRow order={currentOrder!} />,
  // };

  return (
    <>
      {getOrderState === RequestState.Success ? (
        <>
          <Row align="middle" className={clsx([styles.offerHeader, styles.block])}>
            <Col span={16}>
              <div className={styles.idTitle}>
                {`${t('intermediate:myProjects:offer:displayId')}: `}
                <span className={styles.bold}>{currentOrder?.displayId}</span>
              </div>
            </Col>
            <Col span={8} className={styles.updatedLastDate}>
              <div className={styles.lastDate}>
                {`${t('intermediate:myProjects:offer:lastUpdateDate')}: `}
                <span className={styles.bold}>
                  <DateFormat date={currentOrder?.updatedAt} />
                </span>
              </div>
            </Col>
          </Row>
          <Row className={styles.block}>
            <Col span={5}>
              <div className={clsx([styles.name, styles.bold])}>
                {`${currentOrder?.installerUser?.firstName} ${currentOrder?.installerUser?.lastName}`}
              </div>
              <div className={styles.priceTitle}>
                {t('intermediate:myProjects:offer:installerName')}
              </div>
            </Col>
            <Col span={14} offset={5}>
              {order.state === OrderState.Created ? (
                <Row className={styles.buttons}>
                  <Button
                    className={styles.button}
                    type="primary"
                    danger
                    size="small"
                    onClick={() => onActionClick(Actions.Reject)}
                  >
                    {t('intermediate:myProjects:order:cancel')}
                  </Button>
                  <Button
                    className={styles.button}
                    type="primary"
                    size="small"
                    onClick={() => onActionClick(Actions.Accept)}
                  >
                    {t('intermediate:myProjects:order:confirm')}
                  </Button>
                </Row>
              ) : (
                <Row justify="end" align="middle" className={clsx([styles.done, styles.bold])}>
                  <CheckOutlined />
                  {` ${t('intermediate:myProjects:offer:requestDone')}`}
                </Row>
              )}
            </Col>
          </Row>
        </>
      ) : (
        <Loader />
      )}
      {/* <Col span={24}>{currentOrder && <ShowMore {...showMoreProps} />}</Col> */}
      <UploadDocumentModal {...uploadDocumentModalProps} />
    </>
  );
};
