import { createSelector } from 'reselect';
import { IFeedbackState } from './feedback.types';

export const feedbackSelector = (state: any) => state.feedback;

// Feedback list
export const feedbackListSelector = createSelector(
  feedbackSelector,
  (feedback: IFeedbackState) => feedback.feedback,
);

// Feedback State
export const feedbackStateSelector = createSelector(
  feedbackSelector,
  (feedback: IFeedbackState) => feedback.feedbackState,
);

// Feedback Create State
export const feedbackCreateStateSelector = createSelector(
  feedbackSelector,
  (feedback: IFeedbackState) => feedback.createFeedbackState,
);
