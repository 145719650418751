import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import DropZoneWrapper, { DropEvent, DropzoneProps, FileRejection } from 'react-dropzone';
import clsx from 'clsx';
import styles from './DropZone.module.sass';
import Loader from '../Loader/Loader';

interface Props extends IDropZoneProps, WithTranslation {}

export const MB_IN_BYTES = 1048576;
export const DEFAULT_MAX_FILE_SIZE = MB_IN_BYTES * 50; // 10 MB in bytes

interface IDropZoneProps extends DropzoneProps {
  className?: string;

  // Maximum accepted file
  // size in bytes
  maxSize?: number;

  // Accepted files
  acceptedFiles?: string;

  // State flags
  error?: boolean;
  loading?: boolean;

  // Progress bar and time left
  progress?: number;
  timeLeft?: number;

  multiple?: boolean;
  //for custom placholder
  placeholder?: string;

  onTryAgainClick?: () => void;

  onFileDropAccepted?: (files: File[]) => void;
  onFileDropRejected?: (files: File[]) => void;

  label?: string;
}

interface IDropZoneState {
  dragOver: boolean;
  error: string | null;
}

class DropZone extends React.PureComponent<Props, IDropZoneState> {
  static defaultProps = {
    // Default max size = 10MB
    maxSize: DEFAULT_MAX_FILE_SIZE,
    acceptedFiles: '',
    error: false,
    loading: false,
    multiple: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      dragOver: false,
      error: null,
    };
  }

  renderContent = () => {
    const { acceptedFiles, maxSize, t, placeholder, label = '' } = this.props;
    const maxSizeInMB = maxSize! / MB_IN_BYTES;
    const maxSizeStr = `${maxSizeInMB}MB`;

    return (
      <div className={styles.content}>
        {placeholder !== 'fotowizard' ? (
          <div className={styles.text}>
            {label ? (
              label
            ) : (
              <>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#">{t('dropZone:chooseFile')}</a>
                {t('dropZone:orDragHere')}
              </>
            )}
          </div>
        ) : (
          <div className={styles.text}>{t('dropZone:fotoWizard:dropZone')}</div>
        )}

        {placeholder !== 'fotowizard' && (
          <div className={styles.size}>
            {acceptedFiles}
            {t('dropZone:maxSize')}
            {maxSizeStr}
          </div>
        )}
      </div>
    );
  };

  renderError = () => {
    const { error } = this.state;
    const { t } = this.props;
    const message = error ? error : t('common:errors:oopsError');

    return (
      <div className={styles.content}>
        <div className={styles.text}>{message}</div>
        <div className={styles.again} onClick={this.onTryAgain}>
          {t('dropZone:tryAgain')}
        </div>
      </div>
    );
  };

  renderLoading = () => {
    const { timeLeft, progress, t } = this.props;
    const isDone = progress === 100;

    const innerClassName = clsx(styles.inner, {
      [styles.done]: isDone,
    });

    return (
      <div className={styles.content}>
        <Loader className={styles.loader} />

        <div className={styles.progress}>
          <span className={styles.text}>Loading</span>
          {timeLeft ? (
            <span className={styles.time}>
              {timeLeft}
              {t('dropZone:left')}
            </span>
          ) : null}
        </div>

        <div className={styles.bar}>
          <div className={innerClassName} style={{ width: `${progress}%` }} />
        </div>
      </div>
    );
  };

  toggleDragOver = (isDragOver: boolean) => {
    this.setState(() => ({
      dragOver: isDragOver,
    }));
  };

  onFileDropAccepted = (files: File[], event: DropEvent) => {
    const { onFileDropAccepted } = this.props;

    if (onFileDropAccepted && typeof onFileDropAccepted === 'function') {
      onFileDropAccepted(files);
    }
  };

  onFileDropRejected = (rejections: FileRejection[]) => {
    const { t, maxSize } = this.props;
    const error: string =
      rejections[0].errors[0].code === 'file-too-large'
        ? t('dropZone:largeFile', { size: maxSize! / MB_IN_BYTES })
        : rejections[0].errors[0].message;

    this.setState(() => ({
      error,
    }));
  };

  onTryAgain = () => {
    const { onTryAgainClick } = this.props;

    this.setState(() => ({
      error: null,
    }));

    if (onTryAgainClick && typeof onTryAgainClick === 'function') {
      onTryAgainClick();
    }
  };

  render() {
    const { error: propsError, loading, className, maxSize, multiple, accept } = this.props;
    const { error: stateError, dragOver } = this.state;
    const showError = propsError || stateError;

    const containerClassName = clsx(styles.container, className, {
      [styles.loading]: loading,
      [styles.error]: showError,
      [styles.dragover]: dragOver,
    });

    const dropZoneProps = {
      accept,
      multiple,
      maxSize: maxSize,
      onDropAccepted: this.onFileDropAccepted,
      onDropRejected: this.onFileDropRejected,
      onDragOver: () => this.toggleDragOver(true),
      onDragLeave: () => this.toggleDragOver(false),
    };

    return (
      <DropZoneWrapper {...dropZoneProps}>
        {({ getRootProps, getInputProps }) => (
          <div className={containerClassName} {...getRootProps()}>
            <input {...getInputProps()} />
            {!showError && !loading ? this.renderContent() : null}
            {!showError && loading ? this.renderLoading() : null}
            {showError && !loading ? this.renderError() : null}
          </div>
        )}
      </DropZoneWrapper>
    );
  }
}

export default withTranslation(['common', 'dropZone'])(DropZone);
