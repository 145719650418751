import React, { useCallback, useEffect } from 'react';
import {
  Button,
  Col,
  Form,
  FormInstance,
  FormListFieldData,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { IProduct } from 'store/product/product.types';
import { IProductComponent } from 'store/product-component/product-components.types';
import { flatten } from 'utils/array';
import { antdPriceFormatter, antdPriceParser } from 'utils/currencyUtils';
interface IProductFormProps {
  field: FormListFieldData;
  productComponents?: IProductComponent[];
  remove: (index: number) => void;
  form: FormInstance;
  vatRate: number;
}

const ProductFormItem = ({
  field: { name, ...restField },
  productComponents,
  form,
  remove,
  vatRate,
}: IProductFormProps) => {
  const selectedProducts = Form.useWatch('products', form) as IProduct[];
  const products = flatten(
    productComponents?.map((component) => component.products) as IProduct[][],
  );
  const productId = Form.useWatch(['products', name, 'id'], form);

  const availableProducts = products.filter(
    (_product) =>
      !selectedProducts?.some(
        (_selectedProduct) =>
          _selectedProduct?.id === _product?.id && _selectedProduct?.id !== productId,
      ),
  );
  const locizeKey = 'intermediate:pdfGen:product';
  const { t } = useTranslation();
  const setFormValues = useCallback(() => {
    const selectedProduct = products.find((product) => product?.id === productId);
    if (!selectedProduct) return;
    form.setFieldValue(['products', name, 'title'], selectedProduct.title);
    form.setFieldValue(['products', name, 'netPrice'], selectedProduct.netPrice);
    form.setFieldValue(['products', name, 'grossPrice'], selectedProduct.grossPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);
  useEffect(() => {
    setFormValues();
  }, [setFormValues]);
  const productNetPrice = Form.useWatch(['products', name, 'netPrice'], form);
  const setGross = useCallback(() => {
    form.setFieldValue(
      ['products', name, 'grossPrice'],
      (productNetPrice || 0) * (1 + vatRate / 100),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productNetPrice]);

  useEffect(() => {
    setGross();
  }, [setGross]);
  return (
    <Row gutter={4} align={'middle'}>
      <Col span={23}>
        <Form.Item
          name={[name, 'id']}
          rules={[{ required: true, message: t('common:errors:fieldRequired') }]}
          {...restField}
          label={t(``)}
        >
          <Select
            options={availableProducts.map((product) => ({
              label: product?.title,
              value: product?.id,
            }))}
            showSearch={false}
          />
        </Form.Item>
      </Col>
      <Col span={1}>
        <Button
          onClick={() => remove(name)}
          icon={<DeleteOutlined />}
          type={'primary'}
          danger={true}
        />
      </Col>
      <Col span={24}>
        <Form.Item
          name={[name, 'title']}
          rules={[{ required: true, message: t('common:errors:fieldRequired') }]}
          {...restField}
          label={t(`${locizeKey}:selectedHardware`)}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={[name, 'netPrice']}
          rules={[{ required: true, message: t('common:errors:fieldRequired') }]}
          {...restField}
          label={t(`${locizeKey}:priceNet`)}
        >
          <InputNumber
            formatter={antdPriceFormatter}
            parser={antdPriceParser}
            style={{ width: '100%' }}
            controls={false}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name={[name, 'grossPrice']} {...restField} label={t(`${locizeKey}:priceGross`)}>
          <InputNumber
            formatter={antdPriceFormatter}
            parser={antdPriceParser}
            style={{ width: '100%' }}
            controls={false}
            disabled={true}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
export default ProductFormItem;
