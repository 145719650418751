import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';
import { IPool } from 'store/intermediate/intermediate.types';
import LegalTab from './LegalTab/LegalTab';
import ContactPersons from './ContactPersons/ContactPersons';
import editStyles from '../EditPool.module.sass';

const { TabPane } = Tabs;

export interface IContactInformationProps {
  pool: IPool;
}

const ContactInformationTab: React.FC<IContactInformationProps> = ({ pool }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('0');

  return (
    <Tabs activeKey={activeTab} onTabClick={(activeKey) => setActiveTab(activeKey)}>
      <TabPane
        key="0"
        className={editStyles.tabContent}
        tab={t('intermediate:myProjects:editProjectPools:tabs:customData')}
      >
        <LegalTab pool={pool} />
      </TabPane>
      <TabPane
        key="1"
        className={editStyles.tabContent}
        tab={t('intermediate:myProjects:editProjectPools:tabs:contactPersons')}
      >
        <ContactPersons poolId={pool.id} />
      </TabPane>
    </Tabs>
  );
};

export default ContactInformationTab;
