import React from 'react';
import { Form, Radio, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { PoolSettingsFieldsType } from 'types/pool-settings.types';

const QuickCheckSetting: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name={PoolSettingsFieldsType.DeactivateQuickCheck}
      label={t('intermediate:myProjects:poolSettings:deactivateQuickCheck:title')}
      labelAlign="left"
    >
      <Radio.Group>
        <Space direction="vertical">
          <Radio value={false}>
            {t('intermediate:myProjects:poolSettings:deactivateQuickCheck:no')}
          </Radio>
          <Radio value={true}>
            {t('intermediate:myProjects:poolSettings:deactivateQuickCheck:yes')}
          </Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
  );
};

export default QuickCheckSetting;
