import { produce } from 'immer';
import { RequestState } from 'store/common.types';
import {
  IUserState,
  UserActionTypes,
  USER_GET,
  USER_GET_DOCUMENTS,
  USER_GET_DOCUMENTS_ERROR,
  USER_GET_DOCUMENTS_SUCCESS,
  USER_GET_EMAIL_STATUS_ERROR,
  USER_GET_EMAIL_STATUS_SUCCESS,
  USER_GET_ERROR,
  USER_GET_SUCCESS,
} from './user.types';

const initialState: IUserState = {
  userGetDocumentsState: null,
  getDocumentsError: null,
  files: [],
  user: null,
  userGetState: null,
  userGetError: null,
  emailVerified: false,
};

const UserReducer = (state = initialState, action: UserActionTypes) =>
  produce(state, (draft: any) => {
    switch (action.type) {
      case USER_GET_DOCUMENTS:
        draft.userGetDocumentsState = RequestState.Loading;
        break;
      case USER_GET_DOCUMENTS_ERROR:
        draft.userGetDocumentsState = RequestState.Error;
        draft.getDocumentsError = action.payload;
        break;
      case USER_GET_DOCUMENTS_SUCCESS:
        draft.userGetDocumentsState = RequestState.Success;
        draft.getDocumentsError = null;
        draft.files = action.payload;
        break;

      case USER_GET:
        draft.userGetState = RequestState.Loading;
        break;
      case USER_GET_ERROR:
        draft.userGetState = RequestState.Error;
        draft.userGetError = action.payload;
        break;
      case USER_GET_SUCCESS:
        draft.userGetState = RequestState.Success;
        draft.userGetError = null;
        draft.user = action.payload;
        break;
      case USER_GET_EMAIL_STATUS_SUCCESS:
        draft.emailVerified = action.payload;
        break;
      case USER_GET_EMAIL_STATUS_ERROR:
        draft.emailVerified = false;
        draft.error = action.payload;
        break;
    }
  });

export default UserReducer;
