import React from 'react';
import Typography from 'antd/lib/typography';
import { default as ANTModal, ModalProps } from 'antd/lib/modal';

import { useTranslation } from 'react-i18next';
import styles from './PasswordResetSuccess.module.sass';
import Button from '../ui/Button/Button';
import Icon from '../ui/Icon/Icon';

import CorrectGreen from '../../assets/icons/correct-green.svg';

interface IPasswordResetSuccessModal extends ModalProps {
  text?: string;
  onOkayClick?: () => void;
}

const PasswordResetSuccessModal: React.FC<IPasswordResetSuccessModal> = (props) => {
  const { onOkayClick, text = '', ...rest } = props;
  const { t } = useTranslation();

  const modalProps = {
    ...rest,
    centered: true,
    closable: false,
    footer: null,
    width: 316,
  };

  return (
    <ANTModal {...modalProps} wrapClassName={styles.container}>
      <Icon className={styles.icon} icon={CorrectGreen} width="60px" height="60px" />

      <Typography.Title className={styles.title}>{t('common:texts:success')}</Typography.Title>

      <Typography.Paragraph className={styles.text}>{text}</Typography.Paragraph>

      <Button type="primary" className={styles.button} onClick={onOkayClick}>
        {t('common:buttons:okay')}
      </Button>
    </ANTModal>
  );
};

export default PasswordResetSuccessModal;
