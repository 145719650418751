import { ISearchDto, RequestState } from 'store/common.types';
import { IPool } from 'store/intermediate/intermediate.types';
import { IProductComponent } from 'store/product-component/product-components.types';
import { RequestResult } from 'types/crud-view.types';
import { ILocalizedValue } from 'types/language.types';
export interface IProduct {
  id?: string;
  status?: ProductStatus;
  category?: string;
  title?: string;
  referenceId?: string;
  description?: string;
  onRequestPrice?: boolean;
  onRequestPriceTitle?: string;
  localizedTitles?: ILocalizedValue[];
  localizedDescriptions?: ILocalizedValue[];
  localizedOnRequestPriceTitles?: ILocalizedValue[];
  model?: IProductModel;
  mediaUrl?: string;
  pool?: IPool;
  documents?: File[];
  vendor?: IProductVendor;
  component?: IProductComponent;
  netPrice?: number;
  grossPrice?: number;
}

export enum ProductStatus {
  LISTED = 'listed',
  UNLISTED = 'unlisted',
}

export interface IProductModel extends IProductComponents {
  vendor?: IProductVendor;
  category?: ProductModelCategoryType;
  attributeOptions?: IAttributeOption[];
}

export interface IAttributeOption {
  name?: string;
  value?: string;
  attribute?: IAttribute;
}

interface IAttribute {
  attributeCode?: string;
  title?: string;
}
export interface IProductVendor extends IProductComponents {
  productModels?: IProductModel[];
}

interface IProductComponents {
  id?: string;
  name: string;
  pool?: IPool | { id: string };
}

enum ProductModelCategoryType {
  WALLBOX = 'wallbox',
}

export interface IProductState {
  products: IProduct[];
  productsGetState: RequestState | null;
  productsGetError: string | null;

  productUpdateState: RequestState | null;
  productUpdateError: string | null;

  productDeleteState: RequestState | null;
  productDeleteError: string | null;

  productCreateState: RequestState | null;
  productCreateError: string | null;

  vendorCreateState: RequestState | null;
  vendorCreateError: string | null;

  modelCreateState: RequestState | null;
  modelCreateError: string | null;

  vendors: IProductVendor[];
  vendorsGetState: RequestState | null;
  vendorsGetError: string | null;

  models: IProductModel[];
  modelsGetState: RequestState | null;
  modelsGetError: string | null;
}

export const PRODUCT_CREATE = 'PRODUCT_CREATE';
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS';
export const PRODUCT_CREATE_ERROR = 'PRODUCT_CREATE_ERROR';

export const PRODUCT_UPDATE = 'PRODUCT_UPDATE';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_ERROR = 'PRODUCT_UPDATE_ERROR';

export const PRODUCT_DELETE = 'PRODUCT_DELETE';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_ERROR = 'PRODUCT_DELETE_ERROR';

export const PRODUCTS_GET = 'PRODUCT_GET';
export const PRODUCTS_GET_SUCCESS = 'PRODUCT_GET_SUCCESS';
export const PRODUCTS_GET_ERROR = 'PRODUCT_GET_ERROR';

export const VENDOR_CREATE = 'VENDOR_CREATE';
export const VENDOR_CREATE_SUCCESS = 'VENDOR_CREATE_SUCCESS';
export const VENDOR_CREATE_ERROR = 'VENDOR_CREATE_ERROR';

export const MODEL_CREATE = 'MODEL_CREATE';
export const MODEL_CREATE_SUCCESS = 'MODEL_CREATE_SUCCESS';
export const MODEL_CREATE_ERROR = 'MODEL_CREATE_ERROR';

export const VENDORS_GET = 'VENDORS_GET';
export const VENDORS_GET_SUCCESS = 'VENDORS_GET_SUCCESS';
export const VENDORS_GET_ERROR = 'VENDORS_GET_ERROR';

export const MODELS_GET = 'MODELS_GET';
export const MODELS_GET_SUCCESS = 'MODELS_GET_SUCCESS';
export const MODELS_GET_ERROR = 'MODELS_GET_ERROR';

export interface ProductCreateAction {
  type: typeof PRODUCT_CREATE;
  payload: {
    product: IProduct;
    componentId: string;
    onRequestDone?: (status: RequestResult) => void;
  };
}

export interface ProductCreateSuccessAction {
  type: typeof PRODUCT_CREATE_SUCCESS;
  payload: IProduct;
}

export interface ProductCreateErrorAction {
  type: typeof PRODUCT_CREATE_ERROR;
  payload: string;
}

export interface ProductUpdateAction {
  type: typeof PRODUCT_UPDATE;
  payload: {
    id: string;
    body: IProduct;
    onRequestDone?: (status: RequestResult) => void;
  };
}

export interface ProductUpdateSuccessAction {
  type: typeof PRODUCT_UPDATE_SUCCESS;
  payload: IProduct;
}

export interface ProductUpdateErrorAction {
  type: typeof PRODUCT_UPDATE_ERROR;
  payload: string;
}

export interface ProductDeleteAction {
  type: typeof PRODUCT_DELETE;
  payload: {
    id: string;
    onRequestDone?: (status: RequestResult) => void;
  };
}

export interface ProductDeleteSuccessAction {
  type: typeof PRODUCT_DELETE_SUCCESS;
  payload: string;
}

export interface ProductDeleteErrorAction {
  type: typeof PRODUCT_DELETE_ERROR;
  payload: string;
}

export interface ProductsGetAction {
  type: typeof PRODUCTS_GET;
  payload: { componentId: string; filtersPayload?: ISearchDto };
}

export interface ProductsGetSuccessAction {
  type: typeof PRODUCTS_GET_SUCCESS;
  payload: IProduct[];
}

export interface ProductsGetErrorAction {
  type: typeof PRODUCTS_GET_ERROR;
  payload: string;
}

export interface VendorCreateAction {
  type: typeof VENDOR_CREATE;
  payload: { name: string; poolId: string };
}

export interface VendorCreateSuccessAction {
  type: typeof VENDOR_CREATE_SUCCESS;
  payload: IProductVendor;
}

export interface VendorCreateErrorAction {
  type: typeof VENDOR_CREATE_ERROR;
  payload: string;
}

export interface ModelCreateAction {
  type: typeof MODEL_CREATE;
  payload: {
    name: string;
    vendorId: string;
    poolId: string;
  };
}

export interface ModelCreateSuccessAction {
  type: typeof MODEL_CREATE_SUCCESS;
  payload: IProductModel;
}

export interface ModelCreateErrorAction {
  type: typeof MODEL_CREATE_ERROR;
  payload: string;
}

export interface VendorsGetAction {
  type: typeof VENDORS_GET;
  payload: {
    poolId: string;
  };
}

export interface VendorsGetSuccessAction {
  type: typeof VENDORS_GET_SUCCESS;
  payload: IProductVendor[];
}

export interface VendorsGetErrorAction {
  type: typeof VENDORS_GET_ERROR;
  payload: string;
}

export interface ModelsGetAction {
  type: typeof MODELS_GET;
  payload: {
    vendorId: string;
    poolId: string;
    category: string;
  };
}

export interface ModelsGetSuccessAction {
  type: typeof MODELS_GET_SUCCESS;
  payload: IProductModel[];
}

export interface ModelsGetErrorAction {
  type: typeof MODELS_GET_ERROR;
  payload: string;
}

export type ProductActionTypes =
  | ProductCreateAction
  | ProductCreateSuccessAction
  | ProductCreateErrorAction
  | ProductUpdateAction
  | ProductUpdateSuccessAction
  | ProductUpdateErrorAction
  | ProductDeleteAction
  | ProductDeleteSuccessAction
  | ProductDeleteErrorAction
  | ProductsGetAction
  | ProductsGetSuccessAction
  | ProductsGetErrorAction
  | VendorCreateAction
  | VendorCreateSuccessAction
  | VendorCreateErrorAction
  | ModelCreateAction
  | ModelCreateSuccessAction
  | ModelCreateErrorAction
  | VendorsGetAction
  | VendorsGetSuccessAction
  | VendorsGetErrorAction
  | ModelsGetAction
  | ModelsGetSuccessAction
  | ModelsGetErrorAction;
