import { createSelector } from 'reselect';
import { IProductComponentState } from './product-components.types';

export const productComponentSelector = (state: any) => state.productComponents;

export const productComponentCreateStateSelector = createSelector(
  productComponentSelector,
  (productComponent: IProductComponentState) => productComponent.productComponentCreateState,
);

export const productComponentsGetStateSelector = createSelector(
  productComponentSelector,
  (productComponent: IProductComponentState) => productComponent.productComponentsGetState,
);

export const productComponentsSelector = createSelector(
  productComponentSelector,
  (productComponent: IProductComponentState) => productComponent.productComponents,
);

export const productComponentUpdateStateSelector = createSelector(
  productComponentSelector,
  (productComponent: IProductComponentState) => productComponent.productComponentUpdateState,
);

export const productComponentDeleteStateSelector = createSelector(
  productComponentSelector,
  (productComponent: IProductComponentState) => productComponent.productComponentDeleteState,
);

export const productWizardSelector = createSelector(
  productComponentSelector,
  (productComponent: IProductComponentState) => productComponent.productWizard,
);

export const productWizardStateSelector = createSelector(
  productComponentSelector,
  (productComponent: IProductComponentState) => productComponent.productWizardComponentsGetState,
);

export const isActivableProductComponentSelector = createSelector(
  productComponentSelector,
  (productComponent: IProductComponentState) => productComponent.isActivable,
);
