import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Listen and invoke a callback function on language change event
 * @param callback Callback function to be called when language changes.
 */
const useLanguageChangeCallback = (callback: () => void) => {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.on('languageChanged', callback);

    return () => {
      i18n.off('languageChanged', callback);
    };
  }, [i18n, callback]);
};

export default useLanguageChangeCallback;
