import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loader from 'components/ui/Loader/Loader';
import OrdersAPI from 'services/orders.services';

export const DownloadOrder = () => {
  const { id, token } = useParams();
  useEffect(() => {
    getFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFile = async () => {
    if (id && token) await OrdersAPI.downloadAllDocuments(id, token);
    window.close();
  };

  return <Loader />;
};
