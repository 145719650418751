import moment from 'moment';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from 'framework/constants';

export const waitingFormatDate = (date?: Date) => {
  const time = date ? moment(date) : '';
  return time ? `${time.format(DEFAULT_DATE_FORMAT)} ${time.format(DEFAULT_TIME_FORMAT)}` : '';
};

export const formatDateFromIso = (isoDate: Date | string, outputDateFormat: string) => {
  return moment(isoDate).format(outputDateFormat);
};

//from & to should be in 'HH:mm" format (ex.: "15:30")
export const timeSlotsGenerator = (from: string, to: string, stepInMinutes: number): string[] => {
  const timeArray = [];
  let current = from;

  while (!moment(current, 'HH:mm').isAfter(moment(to, 'HH:mm'))) {
    timeArray.push(moment(current, 'HH:mm').format('HH:mm'));
    current = moment(current, 'HH:mm').add(stepInMinutes, 'minutes').format('HH:mm');
  }

  return timeArray;
};
