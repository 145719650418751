import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Modal, Select, Tabs, TabsProps, Typography, message } from 'antd';
import { debounce } from 'lodash';
import CopyLinkIcon from 'assets/icons/copy-link.svg';
import Loader from 'components/ui/Loader/Loader';
import Icon from 'components/ui/Icon/Icon';
import {
  findOrganizations,
  organizationsInvitesSelector,
  poolInviteTokensSelector,
  poolInviteTokensStateSelector,
} from 'store/intermediate/intermediate.selectors';
import { organizationsFind } from 'store/intermediate/intermediate.actions';
import { RequestState } from 'store/common.types';
import { IInviteTokenData, ValidateInviteType } from 'store/register/register.types';
import { IPool, Organization } from 'store/intermediate/intermediate.types';

import styles from './OrganizationsModal.module.sass';

export interface IOrganizationsModalProps {
  onClose?: () => void;
  visible: boolean;
  pool: IPool;
  onAdd: (v: Organization) => void;
  onSendLink: (email: string) => void;
}

const OrganizationsModal: React.FC<IOrganizationsModalProps> = ({
  onClose,
  visible,
  pool,
  onAdd,
  onSendLink,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('selectOrganizations');
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const organizationsInvites = useSelector(organizationsInvitesSelector);
  const organizations = useSelector(findOrganizations);
  const inviteTokens = useSelector(poolInviteTokensSelector);
  const [inviteLink, setInviteLink] = useState<string>('');
  const inviteTokensState = useSelector(poolInviteTokensStateSelector);

  useEffect(() => {
    dispatch(organizationsFind('', pool.intermediateOrganizationId));
  }, [dispatch, pool.intermediateOrganizationId]);

  useEffect(() => {
    if (inviteTokens) {
      const publicInstallerManager = inviteTokens.find(
        (item: IInviteTokenData) =>
          item.type === ValidateInviteType.PublicInstallerManager && !item.organization,
      );
      if (publicInstallerManager) {
        const link = `${document.location.origin}/registration/${publicInstallerManager.token}`;
        setInviteLink(link);
      } else {
        setInviteLink('');
      }
    }
  }, [inviteTokens]);

  const organizationsOptions = useMemo(() => {
    const filteredArray = organizations.filter(
      (item: Organization) =>
        !organizationsInvites.find((i: any) => i.organization && i.organization.id === item.id),
    );
    return filteredArray.map((i: Organization) => ({
      value: i.id!,
      label: i.organization.name!,
    }));
  }, [organizations, organizationsInvites]);

  if (inviteTokensState === RequestState.Loading || !inviteTokens) {
    return <Loader />;
  }

  const copyToClipboard = (e: any, copyValue: string) => {
    let textField = document.createElement('textarea');
    textField.innerText = copyValue;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    message.info(t('intermediate:myProjects:editProjectPools:linkCopied'));
    e.target.focus();
  };

  const handleOkClick = () => {
    if (selectedTab === 'selectOrganizations' && selectedOrganization) {
      onAdd(selectedOrganization);
      setSelectedOrganization(null);
    } else if (email) {
      onSendLink(email);
    }
  };

  const emailTextInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const searchOrganization = (value: string) =>
    dispatch(organizationsFind(value, pool.intermediateOrganizationId));

  const debounceOrganizationSearch = debounce(searchOrganization, 500);

  const onSelectOrganization = (selected: string) => {
    const organization = organizations.find((i: Organization) => i.id === selected);
    setSelectedOrganization(organization);
  };

  const ddProps = {
    options: organizationsOptions,
    placeholder: t('intermediate:myProjects:companyModal:selectCompany'),
    optionFilterProp: 'children',
    onSearch: debounceOrganizationSearch,
    onSelect: onSelectOrganization,
  };

  const renderInviteOrganization = () => (
    <>
      <Typography.Text>Invite via Link</Typography.Text>
      <Input
        placeholder={t('intermediate:myProjects:editProjectPools:link')}
        value={inviteLink}
        className={styles.linkInput}
        readOnly
        suffix={
          <Icon
            className={styles.copyButton}
            onClick={(e) => copyToClipboard(e, inviteLink)}
            icon={CopyLinkIcon}
          />
        }
      />
      <Typography.Text>Invite via Email</Typography.Text>
      <Input placeholder="Email" value={email || ''} onInput={emailTextInputChange} />
    </>
  );

  const items: TabsProps['items'] = [
    {
      key: 'selectOrganizations',
      label: t('intermediate:myProjects:organizationsModal:selectOrganizations'),
      children: <Select {...ddProps} showSearch filterOption={false} className={styles.select} />,
    },
    {
      key: 'inviteOrganization',
      label: t('intermediate:myProjects:organizationsModal:inviteOrganization'),
      children: renderInviteOrganization(),
    },
  ];

  return (
    <Modal
      onCancel={onClose}
      onOk={handleOkClick}
      open={visible}
      width={800}
      title={t('intermediate:myProjects:organizationsModal:title')}
      okText={t(
        `common:buttons:${
          selectedTab === 'selectOrganizations' ? 'addOrganizations' : 'sendInvitation'
        }`,
      )}
    >
      <Tabs items={items} onChange={(key) => setSelectedTab(key)} activeKey={selectedTab} />
    </Modal>
  );
};

export default OrganizationsModal;
