import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Tooltip, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import NavLink from 'components/ui/NavLink/NavLink';
import { offerstInProgressCount, offerTransitionReset } from 'store/offer/offer.actions';
import ActiveRequestsTab from './ActiveRequestsTab';
import styles from './RequstsTab.module.sass';
import editStyles from '../EditPool.module.sass';

enum RequestsTabs {
  ACTIVE = 'requests',
  ARCHIVED = 'archivedRequests',
}

const RequestsTab = () => {
  const { t } = useTranslation();
  const { activeTab, poolId } = useParams();
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(offerstInProgressCount());
  }, []); // eslint-disable-line

  useEffect(() => {
    return function cleanup() {
      dispatch(offerTransitionReset());
    };
  }, [dispatch]);

  return (
    <div style={{ paddingTop: '10px' }}>
      <Typography className={editStyles.boxTitle}>
        {t('intermediate:myProjects:editProjectPools:requests')}
        <Tooltip
          title={t('intermediate:myProjects:projectPoolsTab:notificationContent')}
          placement="bottom"
          className={editStyles.tooltip}
        >
          <InfoCircleOutlined />
        </Tooltip>
      </Typography>
      <Row>
        <Col span={19}>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <NavLink
              to={`/my-projects/${poolId}/${RequestsTabs.ACTIVE}`}
              title={t('common:request:active')}
            />
            <NavLink
              to={`/my-projects/${poolId}/${RequestsTabs.ARCHIVED}`}
              title={t('common:request:archived')}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} className={styles.table}>
          <ActiveRequestsTab poolId={poolId!} activeTabParam={activeTab!} />
        </Col>
      </Row>
    </div>
  );
};

export default RequestsTab;
