import config from 'config';
import { ILead } from 'store/lead/lead.types';
import HttpService from './http.service';

class LeadAPI extends HttpService {
  createLead = (data: ILead) => {
    return this.post(`public/leads/`, { ...data });
  };
}

export default new LeadAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
