import {
  DELETE_POOL_ORGANIZATIONS,
  DELETE_POOL_ORGANIZATIONS_ERROR,
  DELETE_POOL_ORGANIZATIONS_SUCCESS,
  GET_POOL_ORGANIZATIONS,
  GET_POOL_ORGANIZATIONS_ERROR,
  GET_POOL_ORGANIZATIONS_SUCCESS,
  UPDATE_POOL_ORGANIZATIONS,
  UPDATE_POOL_ORGANIZATIONS_ERROR,
  UPDATE_POOL_ORGANIZATIONS_SUCCESS,
} from './pool-tender-organization.types';
import { Organization } from '../intermediate/intermediate.types';

//Get Pool Organizations
export const poolOrganizationsGet = (payload: string) => ({
  type: GET_POOL_ORGANIZATIONS,
  payload: payload,
});

export const poolOrganizationsGetSuccess = (payload: Organization[]) => ({
  type: GET_POOL_ORGANIZATIONS_SUCCESS,
  payload: payload,
});

export const poolOrganizationsGetError = (error: any) => ({
  type: GET_POOL_ORGANIZATIONS_ERROR,
  payload: error,
});

//Update Pool Organizations
export const poolOrganizationsUpdate = (id: string, organization: Organization) => ({
  type: UPDATE_POOL_ORGANIZATIONS,
  payload: {
    id,
    organization,
  },
});

export const poolOrganizationsUpdateSuccess = (payload: Organization) => ({
  type: UPDATE_POOL_ORGANIZATIONS_SUCCESS,
  payload: payload,
});

export const poolOrganizationsUpdateError = (error: any) => ({
  type: UPDATE_POOL_ORGANIZATIONS_ERROR,
  payload: error,
});

//Delete Pool Organizations
export const poolOrganizationsDelete = (payload: string) => ({
  type: DELETE_POOL_ORGANIZATIONS,
  payload: payload,
});

export const poolOrganizationsDeleteSuccess = (payload: string) => ({
  type: DELETE_POOL_ORGANIZATIONS_SUCCESS,
  payload: payload,
});

export const poolOrganizationsDeleteError = (error: any) => ({
  type: DELETE_POOL_ORGANIZATIONS_ERROR,
  payload: error,
});
