import { all, call, put, takeLatest } from 'redux-saga/effects';
import OrdersAPI from 'services/orders.services';
import { IClientRequest } from 'store/client-request/client-request.types';
import {
  acceptOrderError,
  acceptOrderSuccess,
  getOrderByIdError,
  getOrderByIdSuccess,
  linkOrganizationToRequestError,
  linkOrganizationToRequestSuccess,
  rejectOrderError,
  rejectOrderSuccess,
} from './orders.actions';
import {
  AcceptOrderAction,
  ACCEPT_ORDER,
  GetOrderByIdAction,
  GET_ORDER_BY_ID,
  IOrder,
  LinkOrganizationToRequestAction,
  LINK_ORGANIZATION_TO_REQUEST,
  RejectOrderAction,
  REJECT_ORDER,
} from './orders.types';

export function* linkOrganizationToRequest(action: LinkOrganizationToRequestAction) {
  try {
    const { clientRequestId, organizationId } = action.payload;
    const response: IClientRequest = yield call(
      OrdersAPI.linkOrganizationToRequest,
      clientRequestId,
      organizationId,
    );
    if (response) {
      yield put(linkOrganizationToRequestSuccess(response));
    } else {
      yield put(linkOrganizationToRequestError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(linkOrganizationToRequestError(message));
  }
}

export function* acceptOrder(action: AcceptOrderAction) {
  try {
    const { id, token, type, onSuccess } = action.payload;
    const response: IOrder = yield call(OrdersAPI.acceptOrder, id, token, type);
    if (response) {
      if (response.offer?.id) onSuccess?.(response.offer.id);
      yield put(acceptOrderSuccess(response));
    } else {
      yield put(acceptOrderError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(acceptOrderError(message));
  }
}

export function* rejectOrder(action: RejectOrderAction) {
  try {
    const { id, token } = action.payload;
    const response: IOrder = yield call(OrdersAPI.rejectOrder, id, token);
    if (response) {
      yield put(rejectOrderSuccess(response));
    } else {
      yield put(rejectOrderError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(rejectOrderError(message));
  }
}

export function* getOrderById(action: GetOrderByIdAction) {
  try {
    const response: IOrder = yield call(OrdersAPI.getOrderById, action.payload);
    if (response) {
      yield put(getOrderByIdSuccess(response));
    } else {
      yield put(getOrderByIdError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(getOrderByIdError(message));
  }
}

export default function* root() {
  yield all([
    takeLatest(LINK_ORGANIZATION_TO_REQUEST, linkOrganizationToRequest),
    takeLatest(ACCEPT_ORDER, acceptOrder),
    takeLatest(REJECT_ORDER, rejectOrder),
    takeLatest(GET_ORDER_BY_ID, getOrderById),
  ]);
}
