// @ts-ignore
import classNames from 'classnames';

const configBuilder = (apiKey: string, sessionId: string, token: string) => {
  return {
    credentials: {
      apiKey: apiKey,
      sessionId: sessionId,
      token: token,
    },
    // A container can either be a query selector or an HTML Element
    streamContainers(pubSub: any, type: any, data: any, stream: any) {
      // @ts-ignore
      return {
        publisher: {
          camera: '#cameraPublisherContainer',
          screen: '#screenPublisherContainer',
        },
        subscriber: {
          camera: '#cameraSubscriberContainer',
          screen: '#screenSubscriberContainer',
        },
      }[pubSub][type];
    },
    controlsContainer: '#controls',
    packages: ['annotation'],
    communication: {
      callProperties: null, // Using default
      connectionLimit: 2,
    },
    annotation: {
      absoluteParent: {
        publisher: '.App-video-container',
        subscriber: '.App-video-container',
      },
    },
  };
};

export const containerClasses = (
  active: boolean,
  meta: any,
  localAudioEnabled: any,
  localVideoEnabled: any,
  isMobile: boolean,
) => {
  const sharingScreen = meta ? !!meta.publisher.screen : false;
  const viewingSharedScreen = meta ? meta.subscriber.screen : false;
  const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;
  const activeCameraSubscribersGt2 = activeCameraSubscribers > 2;
  const activeCameraSubscribersOdd = activeCameraSubscribers % 2;
  const screenshareActive = viewingSharedScreen || sharingScreen;
  return {
    controlClass: classNames('App-control-container', { hidden: !active }),
    localAudioClass: classNames('ots-video-control circle audio', {
      hidden: !active,
      muted: !localAudioEnabled,
    }),
    localVideoClass: classNames('ots-video-control circle video', {
      hidden: !active,
      muted: !localVideoEnabled,
    }),
    localCallClass: classNames('ots-video-control circle end-call', {
      hidden: !active,
    }),
    cameraPublisherClass: classNames('video-container', {
      hidden: !active,
      small: !!activeCameraSubscribers || screenshareActive,
      mobile: isMobile,
      left: screenshareActive,
    }),
    screenPublisherClass: classNames('video-container', {
      hidden: !active || !sharingScreen,
    }),
    cameraSubscriberClass: classNames(
      'video-container',
      { hidden: !active || !activeCameraSubscribers },
      { 'active-gt2': activeCameraSubscribersGt2 && !screenshareActive },
      { 'active-odd': activeCameraSubscribersOdd && !screenshareActive },
      { small: screenshareActive },
    ),
    screenSubscriberClass: classNames('video-container', {
      hidden: !viewingSharedScreen || !active,
    }),
  };
};

export const listDevices = (OT: any) => {
  return new Promise((resolve, reject) => {
    OT.getDevices((error: any, devices: Array<any>) => {
      if (error) {
        reject(error);
      } else {
        resolve(devices);
      }
    });
  });
};

export const listAudioInputs = async (OT: any) => {
  try {
    const devices = await listDevices(OT);
    // @ts-ignore
    const filteredDevices = devices.filter((device) => device.kind === 'audioInput');
    return Promise.resolve(filteredDevices);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const listVideoInputs = async (OT: any) => {
  try {
    const devices = await listDevices(OT);
    // @ts-ignore
    const filteredDevices = devices.filter((device: any) => device.kind === 'videoInput');
    return Promise.resolve(filteredDevices);
  } catch (error) {
    return Promise.reject(error);
  }
};

export default configBuilder;
