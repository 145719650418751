import React, { FC } from 'react';
import { Checkbox, Radio, RadioChangeEvent, Space } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CheckboxOptionType } from 'antd/lib';
import { useTranslation } from 'react-i18next';

export enum ControlType {
  Radio = 'radio',
  Checkbox = 'Checkbox',
}

interface IFormRadioControlProps {
  value?: CheckboxValueType[];
  onChange?: (e: RadioChangeEvent | CheckboxValueType[]) => void;
  options: CheckboxOptionType[];
  type?: ControlType;
  disabled?: boolean;
}

const FormRadioControl: FC<IFormRadioControlProps> = ({
  options,
  type = ControlType.Radio,
  ...rest
}) => {
  const { t } = useTranslation();
  const Control = type === ControlType.Radio ? Radio : Checkbox;
  return (
    <Control.Group {...rest}>
      <Space direction="vertical">
        {options.map((option) => (
          <Control disabled={option.disabled} key={option.value.toString()} value={option.value}>
            {typeof option.label === 'string' ? t(option.label) : option.label}
          </Control>
        ))}
      </Space>
    </Control.Group>
  );
};

export default FormRadioControl;
