import React, { useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { preloadScript } from 'opentok-react';
import { useDispatch, useSelector } from 'react-redux';

import Call from 'pages/VideoCall/InstallerVideoCall/Call';
import VideoCallAPI from 'services/video-call.service';
import { setGlobalError } from 'store/app/app.actions';
import {
  clientRequestAnswersGet,
  getClientRequestFiles,
} from 'store/client-request/client-request.actions';
import {
  isAuthorizedSelector,
  isCustomerSelector,
  isInstallerManagerSelector,
  isInstallerSelector,
  isIntermediateManagerSelector,
} from 'store/auth/auth.selectors';

import '../videoCall.css';
import Precall from './Precall';

const InstallerVideoCall = () => {
  const [tab, setTab] = useState('preCall');
  const [offer, setOffer] = useState<any>(null);
  const [guest, setGuest] = useState<any>(null);
  const [config, setConfig] = useState<any>(null);

  const dispatch = useDispatch();
  const isAuthorized = useSelector(isAuthorizedSelector);
  const isInstaller = useSelector(isInstallerSelector);
  const isCustomer = useSelector(isCustomerSelector);
  const isIntermediateManager = useSelector(isIntermediateManagerSelector);
  const isInstallerManager = useSelector(isInstallerManagerSelector);

  const { meetingId } = useParams();

  useEffect(() => {
    if (meetingId)
      VideoCallAPI.getInstallerVideoCall(meetingId)
        .then(async (response: any) => {
          const { token, apiKey, sessionId, offer, guest, questionnaire } = response;
          setGuest(guest);
          setOffer(offer);
          dispatch(getClientRequestFiles(offer.clientRequest.id));
          dispatch(clientRequestAnswersGet(offer.clientRequest.id));
          setConfig({ token, apiKey, sessionId, questionnaire });
        })
        .catch((error: any) => {
          return Promise.reject(error);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingId]);

  if (!isAuthorized) {
    return <Navigate to="/" />;
  }

  if (
    isAuthorized &&
    !isInstaller &&
    !isCustomer &&
    !isIntermediateManager &&
    !isInstallerManager
  ) {
    dispatch(setGlobalError('userRoleUndefined'));
    return <Navigate to="/error" />;
  }
  const precallProps = {
    offer,
    guest,
    startCall: () => setTab('call'),
  };

  const callProps = {
    offer,
    config,
    endCall: (shouldChangeTab: boolean) => {
      if (shouldChangeTab) setTab('preCall');
    },
  };

  return <>{tab === 'preCall' ? <Precall {...precallProps} /> : <Call {...callProps} />}</>;
};

export default preloadScript(InstallerVideoCall);
