import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Pencil from 'assets/icons/pencil.svg';
import BoxContent from 'components/layout/BoxContent/BoxContent';
import Icon from 'components/ui/Icon/Icon';
import Button from 'components/ui/Button/Button';
import { formatAnswersToArray } from 'framework/dataTransformations';
import useAnswersLabels from 'hooks/useAnswersLabels';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import { IClientRequestAnswer } from 'store/client-request/client-request.types';
import { questionnaireConfigForCustomerGet } from 'store/intermediate/intermediate.actions';
import { clientRequestAnswersSave } from 'store/client-request/client-request.actions';

import { mergeArraysWithoutDuplicates } from 'utils/commonUtils';
import styles from './AnswersReviewPage.module.sass';
import EditAnswerValue from './EditAnswerValue';

const AnswersReviewPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [answers, setAnswers] = useState<IClientRequestAnswer[]>([]);
  const [updatedAnswers, setUpdatedAnswers] = useState<IClientRequestAnswer[]>([]);

  const [editAnswer, setEditAnswer] = useState<IClientRequestAnswer | null>(null);

  const clientRequest = useSelector(currentClientRequestSelector);

  const answersLabels = useAnswersLabels(answers, clientRequest?.questionnaire);

  useEffect(() => {
    if (!clientRequest || !clientRequest.id) {
      return;
    }
    if (clientRequest.answers?.length) {
      setAnswers(clientRequest.answers);
    }
    dispatch(questionnaireConfigForCustomerGet());
  }, [dispatch, clientRequest]);

  const updateAnswer = (value: IClientRequestAnswer) => {
    const newAnswers = formatAnswersToArray(value);
    setUpdatedAnswers(newAnswers);
    setAnswers(mergeArraysWithoutDuplicates(answers, newAnswers, 'questionKey'));
    setEditAnswer(null);
  };

  const handleBackClick = () => navigate(`/customer/client-request/${clientRequest?.id}/offer`);

  const handleSaveClick = () =>
    dispatch(clientRequestAnswersSave(clientRequest?.id!, updatedAnswers));

  const boxProps = {
    title: t(`customerFlow:common:requirementsSummary`),
    onBackClick: handleBackClick,
    onNextClick: handleSaveClick,
    nextButtonText: t('common:buttons:saveChanges'),
  };

  const modalProps = {
    footer: null,
    centered: true,
    onCancel: () => setEditAnswer(null),
    onConfirm: updateAnswer,
    visible: !!editAnswer,
    answer: editAnswer,
  };

  const handleEditClick = (questionKey: string) => {
    const answer = answers?.find((item) => item.questionKey === questionKey);
    setEditAnswer(answer ?? null);
  };

  const renderAnswerLabel = (answerValueLabel: string | { value: string; label: string }[]) => {
    if (Array.isArray(answerValueLabel)) {
      return answerValueLabel.map((value) => `${t(value.label)}: ${value.value}`).join(', ');
    }
    return t(answerValueLabel);
  };

  const renderAnswers = useCallback(() => {
    if (!answersLabels.length) return <div>No Answers</div>;
    return answersLabels.map((answer, index) => {
      const { questionLabel, answerValueLabel, questionKey } = answer;
      return (
        <div key={`${questionLabel}-${index}`} className={styles.questionContainer}>
          <div className={styles.answer}>
            <span className={styles.questionLabel}>{t(questionLabel)}</span>
            <span className={styles.answerLabel}>{renderAnswerLabel(answerValueLabel)}</span>
          </div>
          <Button className={styles.editButton} onClick={() => handleEditClick(questionKey)}>
            <Icon className={styles.iconEdit} icon={Pencil} />
          </Button>
        </div>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersLabels]);

  return (
    <BoxContent {...boxProps} showBackButton showNextButton>
      <div className={styles.answersList}>{renderAnswers()}</div>
      <EditAnswerValue {...modalProps} title={t('customerFlow:questions:editModal:title')} />
    </BoxContent>
  );
};

export default AnswersReviewPage;
