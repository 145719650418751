import { RequestState } from 'store/common.types';

export interface IContactPerson {
  id: string;
  createdAt?: Date;
  updatedAt?: Date;
  name?: string;
  roleDescription?: string;
  phone?: string;
  email?: string;
  active?: boolean;
}

export interface IContactPersonState {
  contactPersonCreateState: RequestState | null;
  contactPersonCreateError: string | null;

  contactPersonUpdateState: RequestState | null;
  contactPersonUpdateError: string | null;

  contactPersonDeleteState: RequestState | null;
  contactPersonDeleteError: string | null;

  currentContactPersons: IContactPerson[];
  contactPersonsState: RequestState | null;
  contactPersonsError: string | null;
}

export const CONTACT_PERSON_CREATE = 'CONTACT_PERSON_CREATE';
export const CONTACT_PERSON_CREATE_SUCCESS = 'CONTACT_PERSON_CREATE_SUCCESS';
export const CONTACT_PERSON_CREATE_ERROR = 'CONTACT_PERSON_CREATE_ERROR';

export const CONTACT_PERSONS_GET = 'CONTACT_PERSONS_GET';
export const CONTACT_PERSONS_GET_SUCCESS = 'CONTACT_PERSONS_GET_SUCCESS';
export const CONTACT_PERSONS_GET_ERROR = 'CONTACT_PERSONS_GET_ERROR';

export const CONTACT_PERSON_UPDATE = 'CONTACT_PERSON_UPDATE';
export const CONTACT_PERSON_UPDATE_SUCCESS = 'CONTACT_PERSON_UPDATE_SUCCESS';
export const CONTACT_PERSON_UPDATE_ERROR = 'CONTACT_PERSON_UPDATE_ERROR';

export const CONTACT_PERSON_DELETE = 'CONTACT_PERSON_DELETE';
export const CONTACT_PERSON_DELETE_SUCCESS = 'CONTACT_PERSON_DELETE_SUCCESS';
export const CONTACT_PERSON_DELETE_ERROR = 'CONTACT_PERSON_DELETE_ERROR';

export interface ContactPersonCreateAction {
  type: typeof CONTACT_PERSON_CREATE;
  payload: {
    poolId: string;
    contactPerson: IContactPerson;
    onSuccess: () => void;
  };
}

export interface ContactPersonCreateSuccessAction {
  type: typeof CONTACT_PERSON_CREATE_SUCCESS;
  payload: IContactPerson;
}

export interface ContactPersonCreateErrorAction {
  type: typeof CONTACT_PERSON_CREATE_ERROR;
  payload: any;
}

// Get ContactPersons
export interface ContactPersonGetAction {
  type: typeof CONTACT_PERSONS_GET;
  payload: string;
}

export interface ContactPersonGetSuccessAction {
  type: typeof CONTACT_PERSONS_GET_SUCCESS;
  payload: IContactPerson[];
}

export interface ContactPersonGetErrorAction {
  type: typeof CONTACT_PERSONS_GET_ERROR;
  payload: any;
}

// Update ContactPersons
export interface ContactPersonUpdateAction {
  type: typeof CONTACT_PERSON_UPDATE;
  payload: {
    contactPersonId: string;
    contactPerson: IContactPerson;
    onSuccess: () => void;
  };
}

export interface ContactPersonUpdateSuccessAction {
  type: typeof CONTACT_PERSON_UPDATE_SUCCESS;
  payload: IContactPerson;
}

export interface ContactPersonUpdateErrorAction {
  type: typeof CONTACT_PERSON_UPDATE_ERROR;
  payload: any;
}

// Delete ContactPerson
export interface ContactPersonDeleteAction {
  type: typeof CONTACT_PERSON_DELETE;
  payload: string;
}

export interface ContactPersonDeleteSuccessAction {
  type: typeof CONTACT_PERSON_DELETE_SUCCESS;
  payload: string;
}

export interface ContactPersonDeleteErrorAction {
  type: typeof CONTACT_PERSON_DELETE_ERROR;
  payload: any;
}

export type ContactPersonActionTypes =
  | ContactPersonCreateAction
  | ContactPersonCreateSuccessAction
  | ContactPersonCreateErrorAction
  | ContactPersonGetAction
  | ContactPersonGetSuccessAction
  | ContactPersonGetErrorAction
  | ContactPersonUpdateAction
  | ContactPersonUpdateSuccessAction
  | ContactPersonUpdateErrorAction
  | ContactPersonDeleteAction
  | ContactPersonDeleteSuccessAction
  | ContactPersonDeleteErrorAction;
