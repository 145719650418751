import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Modal as ANTModal, Typography, QRCode } from 'antd';
import _get from 'lodash/get';
import videoCall from 'assets/icons/videoCall.svg';
import Link from 'assets/icons/link.svg';
import Button, { IconPosition } from 'components/ui/Button/Button';
import InstallerCard from 'components/ui/InstallerCard/InstallerCard';
import Icon from 'components/ui/Icon/Icon';
import { getDataForInstallerCard, getSecondUserForInstallerCard } from 'framework/helpUtils';
import { downloadCalendarEvent, offerForClientRequestGet } from 'store/offer/offer.actions';
import { IOffer } from 'store/offer/offer.types';

import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import { waitingFormatDate } from 'utils/dateUtils';
import { checkIsHomeCheck, checkIsRemoteHomeCheck } from 'utils/offerHelpers';
import styles from './WaitingStep.module.sass';

export enum WaitingStepType {
  DEFAULT = 'default',
  WAITING_DATES = 'waiting_dates',
  WAITING_HOME_CHECK = 'waiting_home_check',
}

interface Props {
  offer: IOffer;
  type?: WaitingStepType;
  isDirect?: boolean;
}

const WaitingStep: React.FC<Props> = ({ offer, type = WaitingStepType.DEFAULT, isDirect }) => {
  const isHomeCheck = checkIsHomeCheck(offer.state!);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const installationDate = waitingFormatDate(
    isHomeCheck ? offer.finalHomeCheckTimestamp : offer.finalInstallationTimestamp,
  );
  const clientRequest = useSelector(currentClientRequestSelector);

  const users = useMemo(() => {
    const installer = getSecondUserForInstallerCard(offer);
    const activeContactPerson = clientRequest?.pool?.contactPersons?.find((item) => item.active);
    const contactor = activeContactPerson ? activeContactPerson : getDataForInstallerCard(offer);
    return [
      {
        user: contactor,
        operator: true,
        organizationLogo: offer?.homeCheckOfferReplacedBy?.organization?.logo,
      },
      { user: installer, installer: true },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer, clientRequest]);

  const isRemoteHomeCheck = offer.remoteHomeCheck && checkIsRemoteHomeCheck(offer.state!);
  const meetingId = offer.meetingId;
  const videoCallPageLink = `${window.location.origin}/home-check/customer/${meetingId}`;

  const clientRequestId = _get(clientRequest, 'id', '');

  useEffect(() => {
    if (isRemoteHomeCheck && !meetingId) {
      dispatch(offerForClientRequestGet(clientRequestId));
    }
  }, []); // eslint-disable-line

  const modalProps = {
    onCancel: () => {
      setOpenModal(false);
    },
    visible: openModal,
    centered: true,
    mask: false,
    closable: true,
    footer: null,
    width: 500,
  };

  const handleExportAppointment = () => {
    dispatch(downloadCalendarEvent(offer.id!));
  };

  const currentPart = isHomeCheck ? 'homeCheck' : 'installation';

  const isHasAppointments =
    offer.appointments &&
    offer.appointments.length > 0 &&
    offer.appointments?.some(
      (item) => item.purpose === (currentPart === 'homeCheck' ? 'home_check' : 'installation'),
    );

  const directKey = isDirect ? 'orderDirectly' : currentPart;
  const typeKey = isHasAppointments ? type : WaitingStepType.WAITING_DATES;

  const isExportButton =
    (isHomeCheck && offer.finalHomeCheckTimestamp) ||
    (!isHomeCheck && offer.finalInstallationTimestamp);

  const getTitle = () =>
    currentPart === 'homeCheck'
      ? t('customerFlow:homeCheck:waitingStep:title:waiting_dates')
      : t(`customerFlow:${directKey}:waitingStep:title:${typeKey}`);

  return (
    <div className={styles.container}>
      <Row>
        <Col span={16}>
          <h4 className={styles.title}>{getTitle()}</h4>
          <Typography.Paragraph className={styles.text}>
            {t(`customerFlow:${directKey}:waitingStep:description:${typeKey}`, {
              installationDate,
            })}
          </Typography.Paragraph>
          <Typography.Paragraph className={styles.text}>
            {t(`customerFlow:installation:waitingStep:additionalText:${typeKey}`)}
          </Typography.Paragraph>
          {isExportButton && (
            <Button size="small" onClick={handleExportAppointment} className={styles.export}>
              {t('installerFlow:orderDetails:exportAppointment')}
            </Button>
          )}
          {isRemoteHomeCheck && meetingId && (
            <div>
              <div>
                <Typography.Title level={5}>{installationDate}</Typography.Title>
              </div>
              <Button
                onClick={() => {
                  setOpenModal(true);
                }}
                iconPosition={IconPosition.LEFT}
                type="primary"
                size="large"
                icon={<Icon icon={videoCall} width="30px" height="30px" />}
              >
                {t(`customerFlow:installation:waitingStep:videoCall:startButton`)}
              </Button>
              <ANTModal {...modalProps}>
                <Typography.Title level={3} className={styles.centerText}>
                  {t(`customerFlow:installation:waitingStep:videoCall:useQRCode`)}
                </Typography.Title>
                <Typography.Paragraph className={styles.centerText}>
                  {t(`customerFlow:installation:waitingStep:videoCall:email`)}
                </Typography.Paragraph>
                <div className={styles.centerText}>
                  <a href={videoCallPageLink} target="_blank" rel="noopener noreferrer">
                    <QRCode value={videoCallPageLink} size={200} />
                  </a>
                </div>
                <div className={styles.videoCallLink}>
                  <a href={videoCallPageLink} target="_blank" rel="noopener noreferrer">
                    <Icon icon={Link} className={styles.iconLink} />
                    {t('customerFlow:installation:waitingStep:videoCall:linkDesktop')}
                  </a>
                </div>
              </ANTModal>
            </div>
          )}
        </Col>
        <Col span={8} className={styles.cardContainer}>
          {users?.map((item, index) => <InstallerCard key={index} {...item} />)}
        </Col>
      </Row>
    </div>
  );
};

export default WaitingStep;
