import React, { useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Col, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import TextField from 'components/ui/TextField/TextField';

import { IOption } from 'store/common.types';
import styles from './Filters.module.sass';

const { Option } = Select;

interface IFiltersProps {
  onFilterChange: any;
  filters: any;
  locizeKey: string;
  categoryFilter?: boolean;
  options: IOption[];
}

export const Filters: React.FC<IFiltersProps> = ({ onFilterChange, categoryFilter, options }) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState('');

  const onSearchInput = (value: string) => {
    setSearch(value);
    onFilterChange('search', value);
  };

  return (
    <div className={styles.searchRow}>
      <Row gutter={[16, 16]} justify="space-between" align="middle">
        <Col span={10}>
          <TextField
            className={styles.input}
            placeholder={t('intermediate:myProjects:product:searchbar')}
            prefix={<SearchOutlined />}
            value={search}
            onChange={(e) => onSearchInput(e.target.value)}
          />
        </Col>
        <Col span={12}>
          <div className={styles.selectors}>
            <div>
              {categoryFilter && (
                <Select
                  placeholder="Category"
                  bordered={false}
                  className={styles.category}
                  onChange={(value) => onFilterChange('category', value)}
                  allowClear
                >
                  {['wallbox', 'chargingStations'].map((item) => (
                    <Option value={item} key={item}>
                      {t(`intermediate:myProjects:category:${item}`)}
                    </Option>
                  ))}
                </Select>
              )}
              <Select
                placeholder="Status"
                bordered={false}
                className={styles.status}
                onChange={(value) => onFilterChange(categoryFilter ? 'status' : 'active', value)}
                allowClear
              >
                {options.map((item) => (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
