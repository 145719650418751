import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { IClientRequest } from 'store/client-request/client-request.types';
import { IOrder } from 'store/orders/orders.types';
import { OrderItem } from './OrderItem';
import { EmptyData } from '../../components/EmptyData/EmptyData';

import styles from '../../ClientRequestPage.module.sass';

interface OrderProps {
  clientRequest: IClientRequest;
  onRejectSuccess: () => void;
}

export const Orders: React.FC<OrderProps> = ({ clientRequest, onRejectSuccess }) => {
  const { t } = useTranslation();
  const { orders } = clientRequest;

  return (
    <Row>
      <Col span={24} className={styles.container}>
        <div className={styles.title}>{t('intermediate:myProjects:orders')}</div>
        {Array.isArray(orders) && orders.length ? (
          orders.map((item: IOrder) => (
            <div key={item.id}>
              <OrderItem key={item.id} order={item} onRejectSuccess={onRejectSuccess} />
            </div>
          ))
        ) : (
          <EmptyData />
        )}
      </Col>
    </Row>
  );
};
