import React from 'react';
import { useTranslation } from 'react-i18next';
import { default as ANTModal, ModalProps } from 'antd/lib/modal';
import Button from 'components/ui/Button/Button';
import styles from './ModalContainer.module.sass';

interface IModalContainerProps extends ModalProps {
  children: any;
  modalTitle?: string;
  onCancel(): void;
  onSubmit(): void;
}

const ModalContainer: React.FC<IModalContainerProps> = ({
  children,
  modalTitle = '',
  onCancel,
  onSubmit,
  closable,
  ...rest
}) => {
  const { t } = useTranslation();

  const modalProps = {
    ...rest,
    onCancel,
    title: modalTitle,
    centered: true,
    closable,
    footer: null,
    width: 680,
    className: styles.container,
  };

  return (
    <ANTModal {...modalProps}>
      <div>{children}</div>
      <div className={styles.actions}>
        {/*@ts-ignore*/}
        <Button type="secondary" size="large" className={styles.button} onClick={onCancel}>
          {t('common:buttons:cancel')}
        </Button>
        {/*@ts-ignore*/}
        <Button type="primary" size="large" className={styles.button} onClick={onSubmit}>
          {t('common:buttons:okay')}
        </Button>
      </div>
    </ANTModal>
  );
};

export default ModalContainer;
