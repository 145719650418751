import { createSelector } from 'reselect';
import { ICountryState } from './country.types';

export const countrySelector = (state: any) => state.country;

// Countries List
export const countriesListSelector = createSelector(
  countrySelector,
  (lead: ICountryState) => lead.countries,
);
