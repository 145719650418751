import React from 'react';
import Typography from 'antd/lib/typography';

import { useTranslation } from 'react-i18next';
import manWithWoman from 'assets/images/man-with-woman.svg';
import Button from 'components/ui/Button/Button';
import styles from './OfferWaitingForOffersStep.module.sass';

interface IProps {
  showButton?: boolean;
  onEditClick?: () => void;
  isElto?: boolean;
  isDeliveryButton?: boolean;
  onDeliveryDateClick?: () => void;
}

const OfferWaitingForOffersStep: React.FC<IProps> = ({
  showButton = false,
  onEditClick,
  isElto = false,
  isDeliveryButton = false,
  onDeliveryDateClick,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        {t(`customerFlow:clientRequest:${isElto ? 'elto:' : ''}waitingForOffer:headline`)}
      </h3>
      {isElto && (
        <h3 className={styles.title}>
          {t(`customerFlow:clientRequest:${isElto ? 'elto:' : ''}waitingForOffer:headline2`)}
        </h3>
      )}

      <Typography.Paragraph className={styles.text}>
        {t(`customerFlow:clientRequest:${isElto ? 'elto:' : ''}waitingForOffer:description`)}
      </Typography.Paragraph>
      <div className={styles.buttonContainer}>
        {showButton && (
          <Button type="primary" onClick={onEditClick} style={{ marginRight: '10px' }}>
            {t('customerFlow:clientRequest:editButton')}
          </Button>
        )}
        {isDeliveryButton && (
          <>
            {/*@ts-ignore*/}
            <Button type="secondary" onClick={onDeliveryDateClick}>
              {t('customerFlow:clientRequest:deliveryDateButton')}
            </Button>
          </>
        )}
      </div>

      <img className={styles.manImage} src={manWithWoman} alt="Man with woman" />
    </div>
  );
};

export default OfferWaitingForOffersStep;
