import { useState, useCallback } from 'react';

export function useFilters<T>(initialFilters: T) {
  const [filters, setFilters] = useState(initialFilters);

  const handleFilterChange = useCallback(
    (updatedFilters: Partial<T>) => setFilters({ ...filters, ...updatedFilters }),
    [filters],
  );

  return { filters, handleFilterChange };
}
