import React, { useState, useEffect, useCallback } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormInstance } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CrudView } from 'components/layout/CrudView/CrudView';
import useLanguageChangeCallback from 'hooks/useLaguageChangeCallback';
import { IPool } from 'store/intermediate/intermediate.types';
import { IProductComponent } from 'store/product-component/product-components.types';

import {
  productComponentsGetStateSelector,
  productComponentsSelector,
} from 'store/product-component/product-component.selector';
import {
  productComponentCreate,
  productComponentDelete,
  productComponentsGet,
  productComponentUpdate,
} from 'store/product-component/product-components.actions';
import { RequestState } from 'store/common.types';
import { EntityAction, FormLayout, ICrudViewProps, RequestResult } from 'types/crud-view.types';
import { formatEditorField } from 'utils/product';
import { Filters } from './components/Filters/Filters';
import { FormBody } from './components/FormBody';
import { useFilters } from './hooks/useFilter';
import styles from './ProductWizard.module.sass';
interface IProductWizardProps {
  pool: IPool;
}

export const ProductWizard: React.FC<IProductWizardProps> = ({ pool }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form, setForm] = useState<FormInstance>();

  const [filtersPayload, onFilterChange, onSortChange] = useFilters({
    sortDirection: 1,
    sortField: 'position',
  });

  const components = useSelector(productComponentsSelector);
  const componentsGetState = useSelector(productComponentsGetStateSelector);
  const fetchProductWizardComponents = useCallback(() => {
    dispatch(productComponentsGet(pool.id, filtersPayload));
  }, [dispatch, pool.id, filtersPayload]);
  useEffect(() => {
    fetchProductWizardComponents();
  }, [fetchProductWizardComponents]);
  useLanguageChangeCallback(fetchProductWizardComponents);

  const [selectedProducts, setSelectedProducts] = useState<string[]>();
  const locizeKey = 'intermediate:myProjects:productWizard';

  const rowSelection = {
    selectedProducts,
    onChange: (newKeys: string[]) => setSelectedProducts(newKeys),
  };

  const onActionClick = (
    action: EntityAction,
    data: IProductComponent,
    onRequestDone?: (status: RequestResult) => void,
  ) => {
    const payload: IProductComponent = {
      ...data,
      description: formatEditorField(data.description),
      localizedDescriptions: data.localizedDescriptions.map((_localizedDescription) => ({
        ..._localizedDescription,
        value: formatEditorField(_localizedDescription.value),
      })),
    };

    switch (action) {
      case EntityAction.Create:
        dispatch(productComponentCreate(payload, pool.id, onRequestDone));
        break;
      case EntityAction.Edit:
        dispatch(productComponentUpdate(data.id, payload, onRequestDone));
        break;
      case EntityAction.Delete:
        dispatch(productComponentDelete(data.id, onRequestDone));
    }
  };

  const ProductWizardColumns: ColumnsType<IProductComponent> = [
    {
      title: 'Position',
      dataIndex: 'position',
      defaultSortOrder: 'ascend',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'],
      className: styles.positionCol,
    },
    { title: t(`${locizeKey}:table:name`), dataIndex: 'name' },
    {
      title: 'Status',
      dataIndex: 'active',
      render: (active) =>
        t(`intermediate:myProjects:bundle:table:${active ? 'active' : 'inactive'}`),
    },
  ];

  const modalProps = {
    formBody: <FormBody form={form} />,
    onSubmit: onActionClick,
    rowSelection: rowSelection,
    layout: FormLayout.VERTICAL,
    setForm: (value: FormInstance) => setForm(value),
    width: '60vw',
  };

  const onRowClick = (record: IProductComponent) => navigate(`product/${record.id}`);

  const statusOptions = [
    {
      value: '',
      label: t('intermediate:myProjects:bundle:table:inactive'),
    },
    { value: 'true', label: t('intermediate:myProjects:bundle:table:active') },
  ];

  const crudProps: ICrudViewProps<IProductComponent> = {
    tableLoading: componentsGetState === RequestState.Loading,
    columns: ProductWizardColumns,
    entries: components,
    filterRow: (
      <Filters
        options={statusOptions}
        filters={filtersPayload.filters}
        onFilterChange={onFilterChange}
        locizeKey={locizeKey}
      />
    ),
    modalProps: modalProps,
    onTableSort: onSortChange,
    locizeKey,
    onRowClick,
    createInitialValues: {
      localizedDescriptions: [
        {
          lang: pool.country.defaultLanguage,
          value: null,
        },
      ],
      localizedNames: [
        {
          lang: pool.country.defaultLanguage,
          value: '',
        },
      ],
    } as IProductComponent,
  };

  return <CrudView<IProductComponent> {...crudProps} rowsSelectable />;
};
