import { produce } from 'immer';
import {
  IBundleState,
  BundleActionTypes,
  BUNDLE_CREATE,
  BUNDLE_CREATE_ERROR,
  BUNDLE_CREATE_SUCCESS,
  BUNDLES_GET,
  BUNDLES_GET_ERROR,
  BUNDLES_GET_SUCCESS,
  BUNDLE_UPDATE,
  BUNDLE_UPDATE_ERROR,
  BUNDLE_UPDATE_SUCCESS,
  BUNDLE_DELETE,
  BUNDLE_DELETE_ERROR,
  BUNDLE_DELETE_SUCCESS,
} from './bundle.types';
import { RequestState } from '../common.types';

const initialState: IBundleState = {
  bundleCreateState: null,
  bundleCreateError: null,

  bundleUpdateState: null,
  bundleUpdateError: null,

  bundleDeleteState: null,
  bundleDeleteError: null,

  currentBundles: [],
  bundlesState: null,
  bundlesError: null,
};

const BundleReducer = (state = initialState, action: BundleActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case BUNDLE_CREATE:
        draft.bundleCreateState = RequestState.Loading;
        break;

      case BUNDLE_CREATE_ERROR:
        draft.bundleCreateState = RequestState.Error;
        draft.bundleCreateError = action.payload;
        break;

      case BUNDLE_CREATE_SUCCESS:
        draft.bundleCreateState = RequestState.Success;
        draft.bundleCreateError = null;
        draft.currentBundles = [...state.currentBundles, action.payload];
        break;

      case BUNDLES_GET:
        draft.bundlesState = RequestState.Loading;
        break;

      case BUNDLES_GET_ERROR:
        draft.bundlesState = RequestState.Error;
        draft.bundlesError = action.payload;
        break;

      case BUNDLES_GET_SUCCESS:
        draft.bundlesState = RequestState.Success;
        draft.bundlesError = null;
        draft.currentBundles = action.payload;
        break;

      case BUNDLE_UPDATE:
        draft.bundleUpdateState = RequestState.Loading;
        break;

      case BUNDLE_UPDATE_ERROR:
        draft.bundleUpdateState = RequestState.Error;
        draft.bundleUpdateError = action.payload;
        break;

      case BUNDLE_UPDATE_SUCCESS:
        draft.bundleUpdateState = RequestState.Success;
        draft.bundleUpdateError = null;
        draft.currentBundles = state.currentBundles.map((bundle) =>
          bundle.id === action.payload.id ? { ...action.payload } : bundle,
        );
        break;

      case BUNDLE_DELETE:
        draft.bundleDeleteState = RequestState.Loading;
        break;

      case BUNDLE_DELETE_ERROR:
        draft.bundleDeleteState = RequestState.Error;
        draft.bundleUpdateError = action.payload;
        break;

      case BUNDLE_DELETE_SUCCESS:
        draft.bundleDeleteState = RequestState.Success;
        draft.bundleUpdateError = null;
        draft.currentBundles = state.currentBundles.filter(
          (bundle) => bundle.id !== action.payload,
        );
        break;
    }
  });

export default BundleReducer;
