import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/ui/Button/Button';
import { SupportedLanguages } from 'framework/constants';
import MultiLanguageEntries, {
  TRichContent,
} from 'pages/MyProjects/EditPool/TextAdjustmentsTab/MultiLanguageEntries';
import {
  CustomDocumentsCategoryType,
  ICustomDocument,
  IPool,
} from 'store/intermediate/intermediate.types';

import { uploadGenericFile } from 'store/document/document.actions';
import { IDocument, MediaCategoryType } from 'store/client-request/client-request.types';
import { customDocumentImageUrlSelector } from 'store/client-request/client-request.selectors';
import {
  customDocumentCreate,
  customDocumentDelete,
  customDocumentUpdate,
} from 'store/intermediate/intermediate.actions';
import { deleteLandingCoverImage, getFileLink } from 'store/client-request/client-request.actions';

import { TLanguageBasedContent } from 'types/language.types';
import { getLanguageKey } from 'utils/supprotedLanguagesUtils';
import { formatEditorField, getEditorValue } from 'utils/product';
import styles from './TextAdjustmentsTab.module.sass';

export interface IEditPoolProps {
  pool: IPool;
  category: CustomDocumentsCategoryType;
  customDocuments?: ICustomDocument[];
  showHeading?: boolean;
  showMedia?: boolean;
}

const CustomDocumentSection = forwardRef((props: IEditPoolProps, ref: any) => {
  useImperativeHandle(ref, () => ({
    onSaveCustomDocument() {
      saveCustomDocument();
    },
  }));

  const { pool, customDocuments, showHeading = false, showMedia = false, category } = props;
  const [languageBasedContents, setLanguageBasedContents] = useState<
    TLanguageBasedContent<TRichContent>[]
  >([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileInput = useRef<HTMLInputElement>(null);

  const [fileUploading, setFileUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<IDocument | null>(null);

  const customDocumentImageUrl = useSelector(customDocumentImageUrlSelector);
  const firstDocument = useMemo(() => {
    return customDocuments?.[0];
  }, [customDocuments]);
  const imageUrl =
    uploadedFile || firstDocument?.media?.length
      ? customDocumentImageUrl[uploadedFile?.name || firstDocument?.media[0].media.name]
      : '';
  useEffect(() => {
    if (languageBasedContents.length > 0) return;
    const defaultLangContent = {
      language: getLanguageKey(pool.country.defaultLanguage),
      content: {
        text: null,
      },
    };

    if (customDocuments && customDocuments.length > 0) {
      const mappedDocuments = customDocuments.map((_customDocument) => ({
        content: {
          text: getEditorValue(_customDocument.text),
          title: _customDocument.heading,
        },
        language: getLanguageKey(_customDocument.lang),
      }));
      if (customDocuments.some((doc) => doc.lang === pool.country.defaultLanguage)) {
        setLanguageBasedContents(mappedDocuments);
        return;
      }
      setLanguageBasedContents([defaultLangContent, ...mappedDocuments]);
      return;
    }
    setLanguageBasedContents([defaultLangContent]);
  }, [customDocuments, setLanguageBasedContents, languageBasedContents, pool]);

  useEffect(() => {
    if (uploadedFile) {
      dispatch(getFileLink(uploadedFile.name));
    }
  }, [uploadedFile]); // eslint-disable-line

  useEffect(() => {
    if (customDocuments && firstDocument?.media.length && !uploadedFile) {
      const [image] = firstDocument.media;
      image.media && dispatch(getFileLink(image.media.name));
    }
  }, [dispatch, customDocuments]); //eslint-disable-line

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setFileUploading(true);
      dispatch(
        uploadGenericFile({
          document: file,
          category: MediaCategoryType.CustomDocumentImage,
          uploadSuccess: (document: any) => {
            setFileUploading(false);
            setUploadedFile(document);
          },
          uploadError: (error: any) => {
            setFileUploading(false);
            if (fileInput.current) {
              fileInput.current.value = '';
            }
          },
        }),
      );
    }
  };

  const saveCustomDocument = () => {
    for (const languageBasedContent of languageBasedContents) {
      const newCustomDocumentText = formatEditorField(languageBasedContent.content.text);
      const heading = languageBasedContent.content.title;
      const selectedLanguage = SupportedLanguages[languageBasedContent.language];
      const customDocument = customDocuments?.find(
        (_customDocument) => _customDocument.lang === selectedLanguage,
      );
      const newUploadedMedia = showMedia && uploadedFile ? [uploadedFile] : [];

      if (customDocument) {
        const isHeadingChanged = showHeading && heading !== customDocument.heading;
        const isMediaUpdated = showMedia && uploadedFile;

        if (
          uploadedFile &&
          customDocument.media.length &&
          uploadedFile.id !== customDocument.media[0].media.id
        ) {
          const [{ media }] = customDocument.media;
          dispatch(
            deleteLandingCoverImage(media.name, media.id, MediaCategoryType.CustomDocumentImage),
          );
        }

        if (newCustomDocumentText !== customDocument.text || isHeadingChanged || isMediaUpdated) {
          dispatch(
            customDocumentUpdate(pool.id, customDocument.id, {
              ...customDocument,
              text: newCustomDocumentText,
              lang: selectedLanguage,
              heading: showHeading ? heading : undefined,
              media: newUploadedMedia,
            }),
          );
        }
      } else {
        const currentMedia =
          customDocuments?.[0]?.media?.map((_media) => ({ id: _media.media?.id })) || [];
        const media = newUploadedMedia.length > 0 ? newUploadedMedia : currentMedia;

        dispatch(
          customDocumentCreate(pool.id, {
            category,
            text: newCustomDocumentText,
            lang: selectedLanguage,
            media,
            heading: showHeading ? heading : undefined,
          }),
        );
      }
    }
    if (!customDocuments) return;
    const deletingDocumentIDs = customDocuments
      .filter((_customDocument) =>
        languageBasedContents.every(
          (_languageBasedContent) =>
            SupportedLanguages[_languageBasedContent.language] !== _customDocument.lang,
        ),
      )
      .map((_customDocument) => _customDocument.id);
    for (const deletingDocumentID of deletingDocumentIDs) {
      dispatch(customDocumentDelete(deletingDocumentID));
    }
  };

  return (
    <>
      {showMedia && (
        <div className={styles.landingMedia}>
          {(!!firstDocument?.media.length || uploadedFile) && imageUrl && (
            <img src={imageUrl} alt="CustomDocumentImage" />
          )}
          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            ref={fileInput}
            onChange={onFileChange}
          />
          <Button // @ts-ignore
            type="secondary"
            onClick={() => fileInput?.current?.click()}
            loading={fileUploading}
          >
            {t('intermediate:myProjects:editProjectPools:uploadImage')}
          </Button>
        </div>
      )}

      <MultiLanguageEntries
        languageBasedContents={languageBasedContents}
        setLanguageBasedContents={setLanguageBasedContents}
        hasTitle={showHeading}
        defaultLanguage={pool.country.defaultLanguage}
      />
    </>
  );
});
CustomDocumentSection.displayName = 'CustomDocumentSection';

export default CustomDocumentSection;
