import React, { useContext } from 'react';

import { CustomerDashboardContext } from 'context/customerDashboard.context';
import styles from './ContactPerson.module.sass';
import { ContactSection } from '../../components';

const ContactPerson = () => {
  const { contactPerson } = useContext(CustomerDashboardContext);

  return (
    <>
      <div className={styles.name}>{contactPerson?.name}</div>
      <ContactSection email={contactPerson?.email} phone={contactPerson?.phone} />
    </>
  );
};

export default ContactPerson;
