import { IPool } from 'store/intermediate/intermediate.types';
import { RequestState } from '../common.types';

export interface IInstallerData {
  searchZipCode: string;
  searchCountryCode: string;
  searchRadius?: number;
  minChargingPoints?: number;
  maxChargingPoints?: number;
  allowRequestsNotifications: boolean;
  hasSubmittedLicenses: boolean;
  state?: string;
  rejectReason?: string;
}
export interface IInstallerState {
  installerData: IInstallerData | null;
  installerDataState: RequestState | null;
  installerDataError: string | null;

  installerDataUpdateState: RequestState | null;
  installerDataUpdateError: string | null;

  joiningPoolConfirmState: RequestState | null;
  joiningPoolConfirmError: string | null;

  installerInvitations: any[];

  installerOrganization: any;

  accessiblePools: {
    items: Array<IPool>;
    state: RequestState;
  };
}

export const INSTALLER_DATA_GET = 'INSTALLER_DATA_GET';
export const INSTALLER_DATA_GET_SUCCESS = 'INSTALLER_DATA_GET_SUCCESS';
export const INSTALLER_DATA_GET_ERROR = 'INSTALLER_DATA_GET_ERROR';

export const INSTALLER_DATA_UPDATE = 'INSTALLER_DATA_UPDATE';
export const INSTALLER_DATA_UPDATE_SUCCESS = 'INSTALLER_DATA_UPDATE_SUCCESS';
export const INSTALLER_DATA_UPDATE_ERROR = 'INSTALLER_DATA_UPDATE_ERROR';

export const JOINING_POOL_CONFIRM = 'JOINING_POOL_CONFIRM';
export const JOINING_POOL_CONFIRM_SUCCESS = 'JOINING_POOL_CONFIRM_SUCCESS';
export const JOINING_POOL_CONFIRM_ERROR = 'JOINING_POOL_CONFIRM_ERROR';

export const INSTALLER_INVITATIONS_GET = 'INSTALLER_INVITATIONS_GET';
export const INSTALLER_INVITATIONS_GET_SUCCESS = 'INSTALLER_INVITATIONS_GET_SUCCESS';
export const INSTALLER_INVITATIONS_GET_ERROR = 'INSTALLER_INVITATIONS_GET_ERROR';

export const INSTALLER_ORGANIZATION_GET = 'INSTALLER_ORGANIZATION_GET';
export const INSTALLER_ORGANIZATION_GET_SUCCESS = 'INSTALLER_ORGANIZATION_GET_SUCCESS';
export const INSTALLER_ORGANIZATION_GET_ERROR = 'INSTALLER_ORGANIZATION_GET_ERROR';

export const INSTALLER_SUBMIT_LICENSES = 'INSTALLER_SUBMIT_LICENSES';
export const INSTALLER_SUBMIT_LICENSES_SUCCESS = 'INSTALLER_SUBMIT_LICENSES_SUCCESS';
export const INSTALLER_SUBMIT_LICENSES_ERROR = 'INSTALLER_SUBMIT_LICENSES_ERROR';

export const INSTALLER_GET_POOLS = 'INSTALLER_GET_POOLS';
export const INSTALLER_GET_POOLS_SUCCESS = 'INSTALLER_GET_POOLS_SUCCESS';
export const INSTALLER_GET_POOLS_ERROR = 'INSTALLER_GET_POOLS_ERROR';

// Get Installer Organization
export interface InstallerOrganizationGetAction {
  type: typeof INSTALLER_ORGANIZATION_GET;
  payload: string;
}

export interface InstallerOrganizationGetSuccessAction {
  type: typeof INSTALLER_ORGANIZATION_GET_SUCCESS;
  payload: any;
}

export interface InstallerOrganizationGetErrorAction {
  type: typeof INSTALLER_ORGANIZATION_GET_ERROR;
  payload: any;
}

//Confirm Joining Pool
export interface JoiningPoolConfirmAction {
  type: typeof JOINING_POOL_CONFIRM;
  payload: string;
}

export interface JoiningPoolConfirmSuccessAction {
  type: typeof JOINING_POOL_CONFIRM_SUCCESS;
  payload: any;
}

export interface JoiningPoolConfirmErrorAction {
  type: typeof JOINING_POOL_CONFIRM_ERROR;
  payload: any;
}

//Get Installer Data
export interface InstallerDataGetAction {
  type: typeof INSTALLER_DATA_GET;
}

export interface InstallerDataGetSuccessAction {
  type: typeof INSTALLER_DATA_GET_SUCCESS;
  payload: IInstallerData;
}

export interface InstallerDataGetErrorAction {
  type: typeof INSTALLER_DATA_GET_ERROR;
  payload: any;
}

//Update Installer Data
export interface InstallerDataUpdateAction {
  type: typeof INSTALLER_DATA_UPDATE;
  payload: IInstallerData;
}

export interface InstallerDataUpdateSuccessAction {
  type: typeof INSTALLER_DATA_UPDATE_SUCCESS;
  payload: any;
}

export interface InstallerDataUpdateErrorAction {
  type: typeof INSTALLER_DATA_UPDATE_ERROR;
  payload: any;
}

export interface InstallerInvitationsGetAction {
  type: typeof INSTALLER_INVITATIONS_GET_SUCCESS;
  payload: {
    onSuccess: Function;
  };
}

export interface InstallerInvitationsGetSuccessAction {
  type: typeof INSTALLER_INVITATIONS_GET_SUCCESS;
  payload: any;
}

// Submit Licenses
export interface InstallerSubmitLicensesAction {
  type: typeof INSTALLER_SUBMIT_LICENSES;
}

export interface InstallerSubmitLicensesSuccessAction {
  type: typeof INSTALLER_SUBMIT_LICENSES_SUCCESS;
  payload: IInstallerData;
}

export interface InstallerSubmitLicensesErrorAction {
  type: typeof INSTALLER_SUBMIT_LICENSES_ERROR;
  payload: any;
}

// Actions to get pools an installer has access to
export interface InstallerPoolsGetAction {
  type: typeof INSTALLER_GET_POOLS;
}

export interface InstallerPoolsGetSuccessAction {
  type: typeof INSTALLER_GET_POOLS_SUCCESS;
  payload: Array<IPool>;
}

export interface InstallerPoolsGetErrorAction {
  type: typeof INSTALLER_GET_POOLS_ERROR;
  payload: any;
}

export type InstallerActionTypes =
  | InstallerDataGetAction
  | InstallerDataGetSuccessAction
  | InstallerDataGetErrorAction
  | InstallerDataUpdateAction
  | InstallerDataUpdateSuccessAction
  | InstallerDataUpdateErrorAction
  | JoiningPoolConfirmAction
  | JoiningPoolConfirmErrorAction
  | JoiningPoolConfirmSuccessAction
  | InstallerInvitationsGetSuccessAction
  | InstallerOrganizationGetAction
  | InstallerOrganizationGetSuccessAction
  | InstallerOrganizationGetErrorAction
  | InstallerSubmitLicensesAction
  | InstallerSubmitLicensesSuccessAction
  | InstallerSubmitLicensesErrorAction
  | InstallerPoolsGetAction
  | InstallerPoolsGetSuccessAction
  | InstallerPoolsGetErrorAction;
