import { combineReducers } from 'redux';
import AppReducer from './app/app.reducer';
import AuthReducer from './auth/auth.reducer';
import ClientRequestReducer from './client-request/client-request.reducer';
import OfferReducer from './offer/offer.reducer';
import RegisterReducer from './register/register.reducer';
import InstallerReducer from './installer/installer.reducer';
import LeadReducer from './lead/lead.reducer';
import UserReducer from './user/user.reducer';
import IntermediateReducer from './intermediate/intermediate.reducer';
import DocumentReducer from './document/document.reducer';
import PoolOrganizationReducer from './pool-tender-organization/pool-tender-organization.reducer';
import FeedbackReducer from './feedback/feedback.reducer';
import BundleReducer from './bundle/bundle.reducer';
import ContactPersonReducer from './contact-person/contact-person.reducer';
import CountryReducer from './country/country.reducer';
import SurveysReducer from './surveys/surveys.reducer';
import PDFTemplateReducer from './pdf-templates/pdf-templates.reducer';
import OrdersReducer from './orders/orders.reducer';
import OrganizationReducer from './organization/organization.reducer';
import { AUTH_LOGOUT_SUCCESS } from './auth/auth.types';
import ProductComponentsReducer from './product-component/product-components.reducer';
import ProductReducer from './product/product.reducer';
import { DeliveryDateModalReducer } from './delivery-date/delivery-date.module';
import parentPoolsFilterReducer from './parentPoolsFilter/parentPoolsFilter.reducer';

const appReducer = combineReducers({
  app: AppReducer,
  auth: AuthReducer,
  clientRequest: ClientRequestReducer,
  offer: OfferReducer,
  register: RegisterReducer,
  intermediate: IntermediateReducer,
  user: UserReducer,
  installer: InstallerReducer,
  lead: LeadReducer,
  parentPoolsFilter: parentPoolsFilterReducer,
  document: DocumentReducer,
  poolTenderOrganization: PoolOrganizationReducer,
  feedback: FeedbackReducer,
  bundle: BundleReducer,
  contactPerson: ContactPersonReducer,
  pdfTemplate: PDFTemplateReducer,
  country: CountryReducer,
  surveys: SurveysReducer,
  orders: OrdersReducer,
  organization: OrganizationReducer,
  productComponents: ProductComponentsReducer,
  products: ProductReducer,
  deliveryDateModal: DeliveryDateModalReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === AUTH_LOGOUT_SUCCESS) {
    //The state won't actually be undefined, because reducers return initialState when they receive an undefined state
    state = undefined;
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;

export default rootReducer;
