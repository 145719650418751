import config from 'config';
import { IInstallerData } from 'store/installer/installer.types';
import HttpService from './http.service';

class InstallerAPI extends HttpService {
  static ORGANIZATIONS_API = 'organizations';
  static INSTALLERS_API = 'installers';

  getOrganizationData = (id: string) => {
    return this.get(`${InstallerAPI.ORGANIZATIONS_API}/${id}`);
  };

  verifyConfirmation = () => {
    return this.get(`${InstallerAPI.INSTALLERS_API}/confirmation-status`);
  };

  getInstallerData = () => {
    return this.get(`${InstallerAPI.INSTALLERS_API}/me`);
  };

  updateInstallerData = (params: IInstallerData) => {
    return this.put(`${InstallerAPI.INSTALLERS_API}/me`, { ...params });
  };

  confirmJoiningPool = (tokenId: string) => {
    return this.put(`${InstallerAPI.INSTALLERS_API}/join/${tokenId}`);
  };

  getInstallerInvitations = () => {
    return this.get(`${InstallerAPI.INSTALLERS_API}/invites`);
  };

  submitLicenses = () => {
    return this.post(`${InstallerAPI.INSTALLERS_API}/license/submit`);
  };

  getAccessiblePools = () => {
    return this.get(`${InstallerAPI.INSTALLERS_API}/pools`);
  };
}

export default new InstallerAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
