import React, { useContext } from 'react';

import { CustomerDashboardContext } from 'context/customerDashboard.context';

import styles from './Installer.module.sass';
import { ContactSection } from '../../components';

const Installer = () => {
  const { installerUser } = useContext(CustomerDashboardContext);
  return (
    <>
      <div className={styles.nameSection}>
        {installerUser?.organization?.name}
        <span className={styles.name}>{installerUser?.firstName}</span>
      </div>
      <ContactSection email={installerUser?.email} phone={installerUser?.phone} />
    </>
  );
};

export default Installer;
