import React, { useCallback, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import Stepper, { IStep } from 'components/ui/Stepper/Stepper';
import { CUSTOMER_REQUEST_STEPS } from 'framework/stepper.config';
import {
  currentClientRequestSelector,
  lastVisitedStepSelector,
} from 'store/client-request/client-request.selectors';
import styles from './CustomerLayout.module.sass';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { productWizardGet } from '../../../store/product-component/product-components.actions';
import useLanguageChangeCallback from '../../../hooks/useLaguageChangeCallback';

export enum LayoutTypes {
  INSTALLER = 'installer',
  CUSTOMER = 'customer',
  LEAD = 'lead',
  SURVEY = 'survey',
}

interface ICustomerLayoutProps {
  children?: React.ReactNode;
}

const CustomerLayout: React.FC<ICustomerLayoutProps> = ({ children }) => {
  const request = useSelector(currentClientRequestSelector);
  const dispatch = useDispatch();
  const fetchProductWizard = useCallback(() => {
    if (request?.poolId) dispatch(productWizardGet(request?.poolId!));
  }, [request, dispatch]);
  useEffect(() => {
    fetchProductWizard();
  }, [fetchProductWizard]);
  useLanguageChangeCallback(fetchProductWizard);
  const steps = useMemo(() => {
    const initialSteps: IStep[] = CUSTOMER_REQUEST_STEPS;
    if (!request?.questionnaire?.steps) return initialSteps;

    const questionnaireStep = {
      key: 'questionnaire',
      title: 'stepper:customer:questionnaire:title',
      steps: request.questionnaire.steps.map((step) => ({
        title: step.title,
        key: step.order,
      })),
    };

    return initialSteps.reduce((prev, curr) => {
      if (curr.key === 'wallbox') return [...prev, curr, questionnaireStep];
      if (curr.key === 'questionnaire') return prev;
      return [...prev, curr];
    }, [] as IStep[]);
  }, [request]);
  const lastVisitedStep = useSelector(lastVisitedStepSelector);
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        {steps.length && (
          <Stepper
            steps={steps}
            activeStep={request?.step}
            lastVisitedStep={lastVisitedStep}
            activeSubStep={request?.subStep}
          />
        )}
        {children ? children : <Outlet />}
      </div>
      <Footer />
    </div>
  );
};

export default CustomerLayout;
