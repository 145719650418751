import { createSelector } from 'reselect';
import draftToHtml from 'draftjs-to-html';
import {
  CUSTOM_POOL_ACCENT_COLOR_KEY,
  CUSTOM_POOL_AVATAR_KEY,
  CUSTOM_POOL_FONT_KEY,
} from 'framework/constants';
import { isMembershipDateNotOverdue } from 'framework/dateUtils';
import { IClientRequestState } from './client-request.types';

export const clientRequestSelector = (state: any) => state.clientRequest;

// Request Cost
export const isClientRequestCost = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => clientRequest.cost,
);

// Request distance
export const isClientRequestDistance = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => clientRequest.distance,
);

//Client Request
export const clientRequestStateSelector = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => clientRequest.clientRequestState,
);

//My Client Requests
export const myClientRequestsSelector = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => clientRequest.myClientRequests,
);
export const lastVisitedStepSelector = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => clientRequest.lastVisitedStep,
);

export const isClientRequestErrorSelector = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => clientRequest.createError,
);

export const currentClientRequestSelector = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => clientRequest.clientRequest,
);
export const currentClientRequestVatRateSelector = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => clientRequest.clientRequest?.pool?.country.vatRate,
);
export const currentClientRequestCurrencySymbolSelector = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => clientRequest.clientRequest?.pool?.country.currencySymbol,
);
export const currentClientRequestFilesSelector = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => clientRequest.files,
);

export const currentClientRequestHouseTypeSelector = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => clientRequest.houseType,
);

// Available Client Requests
export const clientRequestsFindStateSelector = createSelector(
  clientRequestSelector,
  (clientRequests: IClientRequestState) => clientRequests.clientRequestsState,
);

export const foundClientRequestsSelector = createSelector(
  clientRequestSelector,
  (clientRequests: IClientRequestState) => clientRequests.clientRequests.items,
);

export const foundClientRequestsTotalSelector = createSelector(
  clientRequestSelector,
  (clientRequests: IClientRequestState) => clientRequests.clientRequests.total,
);

// CR Answers

export const clientRequestAnswersStateSelector = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => ({
    answersState: clientRequest.answersState,
    answersError: clientRequest.answersError,
  }),
);

export const currentClientRequestAnswersSelector = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => clientRequest.clientRequestAnswers,
);

// Transition
export const transitionStateSelector = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => clientRequest.transitionState,
);

export const wallboxesModels = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => clientRequest.wallboxes,
);

export const isQuestionsSelector = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => clientRequest.questions,
);

export const poolAvatarSelector = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => clientRequest.poolAvatar,
);

export const clientRequestPoolAvatar = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => clientRequest.clientRequest?.pool?.poolAvatar,
);

export const clientRequestPoolImprint = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => {
    return clientRequest.clientRequest?.pool?.imprint
      ? draftToHtml(JSON.parse(clientRequest.clientRequest?.pool?.imprint))
      : null;
  },
);

export const clientRequestPoolDataPrivacy = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) =>
    clientRequest.clientRequest?.pool?.dataPrivacy
      ? draftToHtml(JSON.parse(clientRequest.clientRequest?.pool?.dataPrivacy))
      : null,
);

export const poolAvatarStateSelector = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => clientRequest.poolAvatarState,
);

export const customDocumentImageUrlSelector = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => clientRequest.customDocumentImage,
);

export const invoicesSelector = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => ({
    invoicesState: clientRequest.invoicesState,
    invoices: clientRequest.invoices,
  }),
);

export const allowPoolAvatarSelector = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => {
    const allowPoolAvatar = clientRequest?.clientRequest?.pool?.membership?.optionsIncluded?.some(
      (option: any) => option.name === CUSTOM_POOL_AVATAR_KEY,
    );

    return (
      isMembershipDateNotOverdue(clientRequest.clientRequest?.pool?.membership) && allowPoolAvatar
    );
  },
);

export const allowPoolAccentColorSelector = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => {
    const allowAccentColor = clientRequest?.clientRequest?.pool?.membership?.optionsIncluded?.some(
      (option: any) => option.name === CUSTOM_POOL_ACCENT_COLOR_KEY,
    );

    return (
      isMembershipDateNotOverdue(clientRequest.clientRequest?.pool?.membership) && allowAccentColor
    );
  },
);

export const allowPoolFontSelector = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => {
    const allowFont = clientRequest?.clientRequest?.pool?.membership?.optionsIncluded?.some(
      (option: any) => option.name === CUSTOM_POOL_FONT_KEY,
    );

    return isMembershipDateNotOverdue(clientRequest.clientRequest?.pool?.membership) && allowFont;
  },
);

export const fotoWizardUploadToken = createSelector(
  clientRequestSelector,
  (clientRequest: IClientRequestState) => clientRequest.fotowizardUploadToken,
);

export const clientRequestPoolOfferMinDays = createSelector(
  clientRequestSelector,
  (cr: IClientRequestState) => cr.clientRequest?.pool?.offerMinimumValidity || '14',
);
