export type RadioOption = {
  label: string;
  message?: string;
  value: string;
  width?: number;
  imageUrl?: string;
};

export enum QuestionType {
  RadioWithCustomInput = 'radioWithCustomInput',
}

export const DefaultConfig = {
  answers: [],
};
