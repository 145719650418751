import React from 'react';
import styles from './Imprint.module.sass';

const ImprintDe: React.FC = () => {
  return (
    <div className={styles.container}>
      <p>Angaben gemäß § 5 TMG:</p>
      <p>
        Schneider Electric GmbH <br />
        Gothaer Str. 29 <br />
        40880 Ratingen
      </p>
      <p>E-Mail: info@inno2fleet.com</p>
      <p>
        Geschäftsführer: Christophe de Maistre <br />
        Stellv. Vorsitzender des Aufsichtsrats: Thierry Tricot <br />
        Sitz der Gesellschaft: Ratingen <br />
      </p>
      <p>
        Amtsgericht Düsseldorf | HRB 47852 <br />
        USt-IdNr. DE225673854 <br />
      </p>
    </div>
  );
};

export default ImprintDe;
