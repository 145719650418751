import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SendOutlined } from '@ant-design/icons';
import ArrowLeft from 'assets/icons/arrow-left-gray.svg';
import PeopleIcon from 'assets/icons/people.svg';
import PersonIcon from 'assets/icons/person.svg';
import InstallerIcon from 'assets/icons/installer.svg';
import Rectangle from 'components/layout/Rectangle/Rectangle';
import Icon, { IconSize } from 'components/ui/Icon/Icon';

import { CommentType, IDocument } from 'store/client-request/client-request.types';
import { addComment, getComments } from 'store/client-request/client-request.actions';
import { clearCurrentFile } from 'store/document/document.actions';
import { isIntermediateManagerSelector } from 'store/auth/auth.selectors';

import Dialog from './Dialog/Dialog';
import ChatFileUpload from './ChatFileUpload';
import ChatGroup from './ChatGroup/ChatGroup';

import styles from './Chat.module.sass';

interface IChatProps {
  clientRequestId: string;
  intermediate?: boolean;
}

const Chat: React.FC<IChatProps> = ({ clientRequestId, intermediate }) => {
  const [selectedGroup, setSelectedGroup] = useState<CommentType | null>(null);
  const [uploadedFile, setUploadedFile] = useState<IDocument | null>(null);
  const [questionText, setQuestionText] = useState<string>('');
  const [isAddingComment, setIsAddingComment] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isIntermediateManager: boolean = useSelector(isIntermediateManagerSelector);

  const groupsList = [
    {
      title: t('installerFlow:tenderDetails:chat:publicChat'),
      icon: <Icon icon={PeopleIcon} />,
      type: CommentType.Public,
      allow: true,
    },
    {
      title: t('installerFlow:tenderDetails:chat:privateCustomerChat'),
      icon: <Icon icon={PersonIcon} />,
      type: CommentType.Private,
      allow: isIntermediateManager,
    },
    {
      title: t('installerFlow:tenderDetails:chat:privateInstallerChat'),
      icon: <Icon icon={InstallerIcon} />,
      type: CommentType.PrivateInstaller,
      allow: true,
    },
  ];

  useEffect(() => {
    dispatch(getComments(clientRequestId));
  }, []); // eslint-disable-line

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleCreateQuestionSuccess = () => {
    setIsAddingComment(false);
    setQuestionText('');
    dispatch(getComments(clientRequestId));
    dispatch(clearCurrentFile());
    setUploadedFile(null);
  };

  const handleCreateQuestion = () => {
    const commentSide = intermediate ? 'intermediate' : 'installer';
    if ((questionText || uploadedFile) && selectedGroup) {
      setIsAddingComment(true);
      dispatch(
        addComment({
          id: clientRequestId,
          params: {
            text: questionText || '',
            type: selectedGroup,
            attachments: uploadedFile ? [uploadedFile.id] : undefined,
            commentSide,
          },
          onSuccess: handleCreateQuestionSuccess,
          onError: () => {
            setIsAddingComment(false);
          },
        }),
      );
    }
  };

  const getTitle = () => {
    const activeGroup = groupsList.find((g) => g.type === selectedGroup);

    if (!activeGroup) {
      return t('installerFlow:tenderDetails:qaTitle');
    }

    return (
      <div className={styles.headerContent}>
        <button className={styles.backButton} onClick={() => setSelectedGroup(null)}>
          <Icon icon={ArrowLeft} className={styles.icon} size={IconSize.MEDIUM} />
        </button>
        <div className={styles.groupIcon}>{activeGroup.icon}</div>
        <span>{activeGroup.title}</span>
      </div>
    );
  };

  const renderGroupsView = () =>
    groupsList
      .filter((group) => group.allow)
      .map((group) => (
        <ChatGroup key={group.type} group={group} onGroupClick={(type) => setSelectedGroup(type)} />
      ));

  const renderMessageBox = () => {
    return (
      <div className={styles.message}>
        <ChatFileUpload setUploadedFile={setUploadedFile} />
        <textarea
          value={questionText}
          rows={1}
          ref={inputRef}
          onChange={(e) => setQuestionText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey && !isAddingComment) {
              handleCreateQuestion();
            }
          }}
          placeholder={t('installerFlow:tenderDetails:qaBox:askQuestion')}
        />
        <button className={styles.send} disabled={isAddingComment} onClick={handleCreateQuestion}>
          <SendOutlined />
        </button>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Rectangle contentClassName={styles.rectangle} title={getTitle()}>
        <div className={styles.viewContainer}>
          {selectedGroup ? (
            <Dialog
              clientRequestId={clientRequestId}
              type={selectedGroup}
              intermediate={intermediate}
            />
          ) : (
            renderGroupsView()
          )}
        </div>
        <div className={styles.bottom}>
          {selectedGroup ? (
            renderMessageBox()
          ) : (
            <div className={styles.selectChat}>
              {t('installerFlow:tenderDetails:chat:selectChat')}
            </div>
          )}
        </div>
      </Rectangle>
    </div>
  );
};

export default Chat;
