import React from 'react';
import Typography from 'antd/lib/typography';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import styles from './CreatePool.module.sass';

interface ISettingTitle {
  title: string;
  tooltip?: any;
  infoIcon?: boolean;
}

const SettingTitle = ({ tooltip, title, infoIcon = true }: ISettingTitle) => {
  const popoverProps = {
    arrowPointAtCenter: true,
    content: tooltip,
    className: styles.popover,
  };

  return (
    <div className={styles.settingTitle}>
      <Typography className={styles.settingTitleText}>{title}</Typography>
      {infoIcon && (
        <Popover {...popoverProps} trigger="click" placement="right" className={styles.popover}>
          <div>
            <InfoCircleOutlined className={styles.infoIcon} />
          </div>
        </Popover>
      )}
    </div>
  );
};

export default SettingTitle;
