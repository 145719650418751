import React, { useCallback, useEffect } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Flex,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ValidationFactory } from 'framework/validations';
import { DEFAULT_BUNDLE_MARGIN } from 'framework/constants';
import { currentBundlesSelector } from 'store/bundle/bundle.selectors';
import { IBundle } from 'store/bundle/bundle.types';
import { groupBy } from 'utils/array';
import { BundleComponentForm } from './BundleComponentForm';
import { IOfferDocumentBundle, IOfferDocumentForm, IOfferDocumentFormJsonData } from '../types';

const convertFormData = (bundle: IBundle) => {
  return {
    id: bundle.id,
    margin: DEFAULT_BUNDLE_MARGIN,
    name: bundle.name,
    components: Object.entries(groupBy(bundle.components, (_component) => _component.category)).map(
      ([category, items]) => ({
        category,
        items,
      }),
    ),
  };
};

interface IBundleSelectFormProps {
  form: FormInstance<IOfferDocumentForm>;
  removeField: (fieldIndex: number) => void;
  fieldIndex: number;
  pdfFormData?: IOfferDocumentFormJsonData;
}
const BundleSelectForm = ({
  form,
  fieldIndex,
  removeField,
  pdfFormData,
}: IBundleSelectFormProps) => {
  const bundles = useSelector(currentBundlesSelector);

  const { t } = useTranslation();
  const bundleIdWatcher = Form.useWatch(['bundles', fieldIndex, 'id'], form);
  const selectedBundlesIds = Form.useWatch<IOfferDocumentBundle[]>('bundles')?.map(
    (bundle) => bundle?.id,
  );
  const bundlesSelectOptions = bundles
    .filter(
      (item) =>
        item.active && (bundleIdWatcher === item?.id || !selectedBundlesIds?.includes(item?.id)),
    )
    .map((item) => ({ label: item.name, value: item.id }));
  const isDiscountWatcher = Form.useWatch(['bundles', fieldIndex, 'discount'], form);
  const fillInitialBundleData = useCallback(() => {
    const bundle = bundles.find((_bundle) => _bundle.id === bundleIdWatcher);
    if (!bundle || pdfFormData?.jsonField?.bundles) return;
    form.setFieldValue(['bundles', fieldIndex], convertFormData(bundle));
  }, [bundles, bundleIdWatcher, form, fieldIndex, pdfFormData]);
  useEffect(() => {
    fillInitialBundleData();
  }, [fillInitialBundleData]);
  const setSelectedBundleData = useCallback(
    (bundleId: string) => {
      const bundle = bundles.find((_bundle) => _bundle.id === bundleId);
      if (!bundle) return;
      form.setFieldValue(['bundles', fieldIndex], convertFormData(bundle));
    },
    [bundles, form, fieldIndex],
  );
  return (
    <Card>
      <Row align={'middle'} gutter={[8, 8]}>
        <Col span={22}>
          <Form.Item
            rules={[{ required: true, message: t('common:errors:fieldRequired') }]}
            name={[fieldIndex, 'id']}
            label={t('intermediate:pdfGen:bundle:title')}
          >
            <Select
              options={bundlesSelectOptions}
              placeholder={t('installerFlow:tenderDetails:selectBundle')}
              onChange={setSelectedBundleData}
            />
          </Form.Item>
        </Col>
        <Col>
          <Button
            onClick={() => removeField(fieldIndex)}
            icon={<DeleteOutlined />}
            type={'primary'}
            danger={true}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]} align={'middle'}>
        <Col span={10}>
          <Form.Item
            name={[fieldIndex, 'name']}
            label={t('intermediate:myProjects:bundle:name')}
            rules={[ValidationFactory.REQUIRED]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={[fieldIndex, 'margin']}
            label={t('intermediate:pdfGen:bundle:margin')}
            rules={[ValidationFactory.REQUIRED]}
            initialValue={DEFAULT_BUNDLE_MARGIN}
          >
            <InputNumber prefix={'%'} controls={false} disabled={isDiscountWatcher} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item valuePropName="checked" name={[fieldIndex, 'discount']} noStyle>
            <Checkbox>{t('intermediate:pdfGen:bundle:discount')}</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Form.List name={[fieldIndex, 'components']}>
        {(fields, { add, remove }) => (
          <Flex vertical={true} gap={4}>
            {fields.map((field) => (
              <BundleComponentForm
                formPath={[fieldIndex, 'components']}
                form={form}
                field={field}
                remove={remove}
                key={field.key}
              />
            ))}
            <Form.Item>
              <Button icon={<PlusOutlined />} type="dashed" onClick={() => add()}>
                {t(`intermediate:myProjects:bundle.newComponentCategory`)}
              </Button>
            </Form.Item>
          </Flex>
        )}
      </Form.List>
    </Card>
  );
};
export default BundleSelectForm;
