import { RequestState } from '../common.types';
import { IPool } from '../intermediate/intermediate.types';

export interface IParentPoolsFilterPayload {
  items: IPool[];
  total: number;
}
export interface IParentPoolsFilterState {
  data: IParentPoolsFilterPayload | null;
  requestState: RequestState | null;
  requestError: string | null;
}
export const PARENT_POOLS_FILTER = 'PARENT_POOLS_FILTER';
export const PARENT_POOLS_FILTER_SUCCESS = 'PARENT_POOLS_FILTER_SUCCESS';
export const PARENT_POOLS_FILTER_ERROR = 'PARENT_POOLS_FILTER_ERROR';

export interface IParentPoolsFilterAction {
  type: typeof PARENT_POOLS_FILTER;
  payload: string;
}
export interface IParentPoolsFilterSuccessAction {
  type: typeof PARENT_POOLS_FILTER_SUCCESS;
  payload: IParentPoolsFilterPayload;
}
export interface IParentPoolsFilterErrorAction {
  type: typeof PARENT_POOLS_FILTER_ERROR;
  payload: string;
}
export type ParentPoolsFilterActionTypes =
  | IParentPoolsFilterAction
  | IParentPoolsFilterSuccessAction
  | IParentPoolsFilterErrorAction;
