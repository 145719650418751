import { RequestResult } from 'types/crud-view.types';
import {
  IBundle,
  BUNDLE_CREATE,
  BUNDLE_CREATE_ERROR,
  BUNDLE_CREATE_SUCCESS,
  BUNDLES_GET,
  BUNDLES_GET_SUCCESS,
  BUNDLES_GET_ERROR,
  BUNDLE_UPDATE,
  BUNDLE_UPDATE_SUCCESS,
  BUNDLE_UPDATE_ERROR,
  BUNDLE_DELETE,
  BUNDLE_DELETE_SUCCESS,
  BUNDLE_DELETE_ERROR,
} from './bundle.types';
import { ISearchDto } from '../common.types';

//Create Bundle
export const bundleCreate = (
  poolId: string,
  bundle: IBundle,
  onRequestDone?: (status: RequestResult) => void,
) => ({
  type: BUNDLE_CREATE,
  payload: {
    poolId,
    bundle,
    onRequestDone,
  },
});

export const bundleCreateSuccess = (payload: IBundle) => ({
  type: BUNDLE_CREATE_SUCCESS,
  payload: payload,
});

export const bundleCreateError = (error: any) => ({
  type: BUNDLE_CREATE_ERROR,
  payload: error,
});

//Get Bundles
export const bundlesGet = (poolId: string, params?: ISearchDto) => ({
  type: BUNDLES_GET,
  poolId,
  params,
});

export const bundlesGetSuccess = (payload: IBundle[]) => ({
  type: BUNDLES_GET_SUCCESS,
  payload: payload,
});

export const bundlesGetError = (error: any) => ({
  type: BUNDLES_GET_ERROR,
  payload: error,
});

//Update Bundle
export const bundleUpdate = (
  bundleId: string,
  bundle: IBundle,
  onRequestDone?: (status: RequestResult) => void,
) => ({
  type: BUNDLE_UPDATE,
  payload: {
    bundleId,
    bundle,
    onRequestDone,
  },
});

export const bundleUpdateSuccess = (payload: IBundle) => ({
  type: BUNDLE_UPDATE_SUCCESS,
  payload: payload,
});

export const bundleUpdateError = (error: any) => ({
  type: BUNDLE_UPDATE_ERROR,
  payload: error,
});

//Delete Bundle
export const bundleDelete = (
  bundleId: string,
  onRequestDone?: (status: RequestResult) => void,
) => ({
  type: BUNDLE_DELETE,
  payload: { id: bundleId, onRequestDone },
});

export const bundleDeleteSuccess = (bundleId: string) => ({
  type: BUNDLE_DELETE_SUCCESS,
  payload: bundleId,
});

export const bundleDeleteError = (error: any) => ({
  type: BUNDLE_DELETE_ERROR,
  payload: error,
});
