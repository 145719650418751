import { vanillaRenderers } from '@jsonforms/vanilla-renderers';
import CustomSurfaceQuestion from 'components/questions/CustomSurfaceQuestion/CustomSurfaceQuestion';
import surfaceQuestionControlTester from 'components/questions/CustomSurfaceQuestion/CustomSurfaceQuestionControlTester';
import RadioContainer from 'components/controls/RadioContainer/RadioContainer';
import {
  multipleInputControlTester,
  radioControlTester,
  singleInputControlTester,
} from 'components/controls/testers';
import SingleInputContainer from 'components/controls/SingleInputContainer/SingleInputContainer';
import MultipleInputContainer from 'components/controls/MultipleInputContainer/MultipleInputContainer';
import { IQuestionnaire } from 'store/intermediate/intermediate.types';

export const customRenderer = [
  ...vanillaRenderers,
  //register custom renderers
  { tester: multipleInputControlTester, renderer: MultipleInputContainer },
  { tester: radioControlTester, renderer: RadioContainer },
  { tester: singleInputControlTester, renderer: SingleInputContainer },
  { tester: surfaceQuestionControlTester, renderer: CustomSurfaceQuestion },
];

export const checkRedirectEvents = (activeSubStepElements: any[], fields: any[]) => {
  /*retrieve redirect events from uiSchema*/
  let redirectEvents: any[] = [];
  activeSubStepElements.forEach((element: any) => {
    if (element.redirectEvent) {
      redirectEvents.push({
        questionKey: element.scope.replace('#/properties/', ''),
        redirectEvent: element.redirectEvent,
      });
    }
  });
  /*check if redirect events should fire or not , if yes return the redirect URL*/
  let redirectURL = null;
  redirectEvents.forEach((item) => {
    if (fields[item.questionKey] === item.redirectEvent.ifIsEqual) {
      redirectURL = item.redirectEvent.url;
    }
  });
  return redirectURL;
};

export const checkHideNextEvents = (activeSubStepElements: any[], fields: any[]) => {
  /*retrieve hideNext events from uiSchema*/
  let hideNextEvents: any[] = [];
  activeSubStepElements.forEach((element: any) => {
    if (element.hideNextEvent) {
      hideNextEvents.push({
        questionKey: element.scope.replace('#/properties/', ''),
        hideNextEvent: element.hideNextEvent,
      });
    }
  });
  /*check if hide next button events should fire or not, if yes return true*/
  let hideNextButton = null;
  hideNextEvents.forEach((item) => {
    hideNextButton = fields[item.questionKey] === item.hideNextEvent.ifIsEqual;
  });
  return hideNextButton;
};

export const unifyUiSchemas = (questionnaire: IQuestionnaire, activeStepNumber: number) => {
  /* if active step has uiSchema, ignore uiSchema of sub steps
   else integrate sub steps uiSchemas */

  if (questionnaire.steps[activeStepNumber].uiSchema)
    return questionnaire.steps[activeStepNumber].uiSchema;
  else {
    const elements: any = [];
    const activeStep = questionnaire.steps[activeStepNumber];
    activeStep.children.map((subStep: any) => elements.push(subStep.uiSchema));

    return {
      type: 'Categorization',
      elements,
    };
  }
};

export const unifySchemas = (questionnaire: IQuestionnaire, activeStepNumber: number) => {
  /* if active step has schema, ignore schema of sub steps
   if not, integrate sub steps schemas */

  if (questionnaire.steps[activeStepNumber].schema)
    return questionnaire.steps[activeStepNumber].schema;
  else {
    let properties: any = {};
    let activeStep = questionnaire.steps[activeStepNumber];
    activeStep.children.forEach((subStep: any) => {
      Object.keys(subStep.schema.properties).forEach((key) => {
        properties[key] = subStep.schema.properties[key];
      });
    });
    let unifiedSchema = {
      type: 'object',
      properties: properties,
    };
    return unifiedSchema;
  }
};
