import React from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { ValidationFactory } from 'framework/validations';
import { ProductStatus } from 'store/product/product.types';

const Status = () => {
  const { t } = useTranslation();
  const statusOptions = Object.keys(ProductStatus).map((key) => ({
    value: key.toLowerCase(),
    label: t(`intermediate:myProjects:product:status:${key.toLowerCase()}`),
  }));

  return (
    <>
      <Form.Item
        name="status"
        label="Status"
        initialValue="unlisted"
        rules={[ValidationFactory.REQUIRED]}
      >
        <Select options={statusOptions} placeholder="Status" />
      </Form.Item>
      <p>{t('intermediate:myProjects:product:form:status:description')}</p>
    </>
  );
};

export default Status;
