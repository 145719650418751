import config from 'config';
import HttpService from './http.service';

class InviteAPI extends HttpService {
  validate = (token: string) => {
    return this.post(`invite-tokens/validate/${token}`);
  };
}

export default new InviteAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
