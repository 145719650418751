/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Col, message, Row, Typography, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { InfoCircleOutlined } from '@ant-design/icons';
import Table from 'components/ui/Table/Table';
import Notification from 'components/ui/Notification/Notification';
import Checkbox from 'components/ui/Checkbox/Checkbox';
import Button from 'components/ui/Button/Button';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import OrganizationsModal from 'pages/MyProjects/OrganizationsModal/OrganizationsModal';

import { InviteTokenType, IPool, Organization } from 'store/intermediate/intermediate.types';
import { organizationsInvitesSelector } from 'store/intermediate/intermediate.selectors';
import { poolOrganizationsSelector } from 'store/pool-tender-organization/pool-tender-organization.selectors';
import {
  existingOrganizationInvite,
  organizationInviteDelete,
  organizationsInvitesGet,
  organizationInvite,
  poolInviteTokensGet,
} from 'store/intermediate/intermediate.actions';
import {
  poolOrganizationsDelete,
  poolOrganizationsGet,
  poolOrganizationsUpdate,
} from 'store/pool-tender-organization/pool-tender-organization.actions';

import styles from './InstallerOrganizationsTab.module.sass';
import editStyles from '../EditPool.module.sass';

export interface IInstallerOrganizationsProps {
  pool: IPool;
}

const InstallerOrganizationsTab: React.FC<IInstallerOrganizationsProps> = ({ pool }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isVisibleModal, setVisibleModal] = useState(false);

  const organizationsInvites = useSelector(organizationsInvitesSelector);
  const poolOrganizations = useSelector(poolOrganizationsSelector);

  const showNotification = pool.intermediateOffer || pool.intermediateHomeCheckOffer;
  const notificationContent = t('intermediate:myProjects:editProjectPools:approvalNotification', {
    value:
      pool.intermediateOffer && pool.intermediateHomeCheckOffer
        ? t('intermediate:myProjects:editProjectPools:approvalAny')
        : pool.intermediateOffer
          ? t('intermediate:myProjects:editProjectPools:approvalInstallation')
          : t('intermediate:myProjects:editProjectPools:approvalHomeCheck'),
  });

  useEffect(() => {
    dispatch(organizationsInvitesGet(pool.id));
    dispatch(poolOrganizationsGet(pool.id));
    dispatch(poolInviteTokensGet(pool.id));
  }, [pool]); // eslint-disable-line

  const onChangeOrganizationAllow = (id: string) => {
    const selectedOrganization = poolOrganizations.find((o) => o.id === id)!;

    dispatch(
      poolOrganizationsUpdate(id, { automaticApproval: !selectedOrganization.automaticApproval }),
    );

    const messageTextOne = selectedOrganization.automaticApproval
      ? t('intermediate:myProjects:editProjectPools:approvalEnabled')
      : t('intermediate:myProjects:editProjectPools:approvalDisabled');

    const messageTextTwo = selectedOrganization.organization
      ? selectedOrganization.organization.name
      : selectedOrganization.id;

    message.info(`${messageTextOne} ${messageTextTwo}`);
  };

  const onDeleteOrganizationClick = (item: Organization) => {
    const organizationName = item.organization ? item.organization.name : item.id;
    dispatch(poolOrganizationsDelete(item.id!));
    message.info(
      t('intermediate:myProjects:editProjectPools:organizationRemoved', {
        organization: organizationName,
        pool: pool.name,
      }),
    );
  };

  const onAddOrganization = (organization: Organization) => {
    dispatch(existingOrganizationInvite(pool.id, organization.id!));
    setVisibleModal(false);
    message.info(t('intermediate:myProjects:editProjectPools:invitationSent'));
  };

  const onAddNewOrganization = (email: string) => {
    dispatch(
      organizationInvite({
        email,
        firstName: email,
        lastName: email,
        poolId: pool.id,
        organization: {
          name: email,
        },
      }),
    );
    message.info(t('intermediate:myProjects:editProjectPools:invitationSent'));
  };

  const onDeleteOrganizationInviteClick = (item: any) => {
    const organizationName = item.organization ? item.organization.name : item.id;
    dispatch(organizationInviteDelete(item.id));
    message.info(
      t('intermediate:myProjects:editProjectPools:organizationInviteRemoved', {
        organization: organizationName,
        pool: pool.name,
      }),
    );
  };

  const invitesTableColumns: ColumnsType<any> = [
    {
      title: t('intermediate:myProjects:offer:organizationName'),
      dataIndex: 'organization',
      key: 'organization',
      // eslint-disable-next-line react/display-name
      render: (organization: any, row: Organization) => {
        const className = organization ? '' : styles.deletedOrganizationText;
        return (
          <span className={className}>
            {!organization ? t('Organization deleted') : organization.name}
          </span>
        );
      },
    },
    {
      title: t('intermediate:myProjects:editProjectPools:type'),
      dataIndex: 'type',
      key: 'type',
      // eslint-disable-next-line react/display-name
      render: (type: any) => (
        <div>
          {type === InviteTokenType.Installer
            ? t('intermediate:myProjects:editProjectPools:toNewOrganization')
            : t('intermediate:myProjects:editProjectPools:toExistingOrganization')}
        </div>
      ),
    },
    {
      title: t('intermediate:myProjects:editProjectPools:invitedAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: Date) => {
        return <DateFormat date={createdAt} />;
      },
    },
    {
      title: t('intermediate:myProjects:editProjectPools:actions'),
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      render: (_id: string, row: any) => {
        return (
          <Button
            /* @ts-ignore */
            type="secondary"
            size="small"
            onClick={() => onDeleteOrganizationInviteClick(row)}
          >
            {t('intermediate:myProjects:editProjectPools:delete')}
          </Button>
        );
      },
    },
  ];

  const organizationsTableColumns: ColumnsType<Organization> = [
    {
      title: t('intermediate:myProjects:offer:organizationName'),
      dataIndex: 'organization',
      key: 'organization',

      render: (organization: any) => {
        const className = organization ? '' : styles.deletedOrganizationText;
        return (
          <span className={className}>
            {organization ? organization.name : t('Organization deleted')}
          </span>
        );
      },
    },
    {
      title: t('intermediate:myProjects:editProjectPools:allowAutomaticApproval'),
      dataIndex: 'automaticApproval',
      key: 'automaticApproval',
      align: 'right',
      render: (automaticApproval: boolean, row: Organization) => {
        return (
          <Checkbox
            className={styles.checkbox}
            checked={automaticApproval}
            onChange={() => onChangeOrganizationAllow(row.id!)}
          />
        );
      },
    },
    {
      title: t('intermediate:myProjects:editProjectPools:actions'),
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      render: (_id: string, row: Organization) => {
        return (
          /* @ts-ignore */
          <Button type="secondary" onClick={() => onDeleteOrganizationClick(row)} size="small">
            {t('intermediate:myProjects:editProjectPools:delete')}
          </Button>
        );
      },
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Typography className={editStyles.boxTitle}>
          {t('intermediate:myProjects:editProjectPools:organizations')}
          <Tooltip
            title={t('intermediate:myProjects:editProjectPools:organizationsNotification')}
            placement="bottom"
            className={editStyles.tooltip}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </Typography>
        <Typography.Paragraph>
          {t('intermediate:myProjects:editProjectPools:attentionText')}
        </Typography.Paragraph>
        {showNotification && (
          <Notification
            className={styles.notification}
            content={notificationContent}
            type="info"
            fluid
          />
        )}

        <Table
          columns={organizationsTableColumns}
          dataSource={poolOrganizations}
          rowKey="id"
          pagination={false}
        />

        <div className={styles.title}>
          {t('intermediate:myProjects:editProjectPools:pendingRequests')}
        </div>
        <Table
          columns={invitesTableColumns}
          dataSource={organizationsInvites.filter((item: any) => item.isEnabled)}
          rowKey="id"
          pagination={false}
          emptyText={t('intermediate:myProjects:editProjectPools:invitesEmptyText')}
        />

        {/* @ts-ignore */}
        <Button type="secondary" className={styles.addButton} onClick={() => setVisibleModal(true)}>
          {t('intermediate:myProjects:editProjectPools:addOrganization')}
        </Button>
        <OrganizationsModal
          visible={isVisibleModal}
          onClose={() => setVisibleModal(false)}
          pool={pool}
          onAdd={onAddOrganization}
          onSendLink={onAddNewOrganization}
        />
      </Col>
    </Row>
  );
};

export default InstallerOrganizationsTab;
