import React from 'react';
import { useTranslation } from 'react-i18next';
const importantLinks = [
  {
    name: 'customerFlow:dashboard:importantLinks:faq',
    path: '/faq',
  },
];
const ImportantLinks = () => {
  const { t } = useTranslation();
  return (
    <div>
      {importantLinks.map((link) => (
        <div key={link.path}>{t(link.name)}</div>
      ))}
    </div>
  );
};

export default ImportantLinks;
