import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { uniq } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import RadioQuestion from 'components/questions/RadioQuestion/RadioQuestion';

import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from 'framework/constants';
import { formatDate } from 'framework/dateUtils';
import { offerTransition } from 'store/offer/offer.actions';
import { AppointmentPurpose, IOffer, OfferEventType, OfferState } from 'store/offer/offer.types';

import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import { offerTransitionStateSelector } from 'store/offer/offer.selectors';
import { RequestState } from 'store/common.types';
import { clientRequestUpdate } from 'store/client-request/client-request.actions';
import { formatCurrency } from 'utils/currencyUtils';
import { checkIsPayByIntermediate } from 'utils/offerHelpers';
import styles from './OfferSubmitStep.module.sass';
import TermsPolicy from './TermsPolicy';

export type CheckboxesType = {
  terms: boolean;
  protection: boolean;
};

export enum OfferSubmitStepType {
  SELECTING = 'selecting',
  SUBMITTING = 'submitting',
  CUSTOMER_ACCEPTED = 'CUSTOMER_ACCEPTED',
}

const DefaultCheckboxes: CheckboxesType = {
  terms: false,
  protection: false,
};

interface IOfferSubmitStepProps {
  offer: IOffer;
  type?: OfferSubmitStepType;
  handleCloseModal?: () => void;
}

const OfferSubmitStep: React.FC<IOfferSubmitStepProps> = ({
  offer,
  type = OfferSubmitStepType.SELECTING,
  handleCloseModal,
}) => {
  const [modal, setModal] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [checkboxes, setCheckboxes] = useState<CheckboxesType>(DefaultCheckboxes);
  const [errors, setErrors] = useState<string[]>([]);
  const clientRequest = useSelector(currentClientRequestSelector);
  const offerTransitonState = useSelector(offerTransitionStateSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const purpose =
    offer.state === OfferState.OfferSubmitted || offer.state === OfferState.TenderAccepted
      ? 'installation'
      : 'home_check';

  const isSelecting = type === OfferSubmitStepType.SELECTING;

  const price = useMemo(
    () => (isSelecting ? offer.costEstimateHomeCheck : offer.costEstimate),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offer],
  );

  const isHasAppointments = Boolean(
    offer.appointments?.filter((appointment) => appointment.purpose === purpose).length,
  );

  useEffect(() => {
    let newErrors = errors;
    if (
      type !== OfferSubmitStepType.CUSTOMER_ACCEPTED &&
      isHasAppointments &&
      selectedDate &&
      errors.includes('selectedDate')
    ) {
      newErrors = newErrors.filter((e) => e !== 'selectedDate');
    }
    setErrors(newErrors);
  }, [selectedDate]); // eslint-disable-line

  const handleHideModal = () => setModal('');

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const {
      target: { name, checked },
    } = e;
    setCheckboxes({ ...checkboxes, [name as string]: checked });
    if (checked) {
      setErrors(errors.filter((e) => e !== name));
    } else {
      setErrors(uniq([...errors, name as string]));
    }
  };

  const handleNextClick = () => {
    const newErrors = [];
    !checkboxes['terms'] && price && newErrors.push('terms');
    !checkboxes['protection'] && price && newErrors.push('protection');
    type !== OfferSubmitStepType.CUSTOMER_ACCEPTED &&
      isHasAppointments &&
      !selectedDate &&
      newErrors.push('selectedDate');
    if (newErrors.length) {
      setErrors(newErrors);
      return;
    }

    const dateField = isSelecting ? 'finalHomeCheckTimestamp' : 'finalInstallationTimestamp';
    const data =
      type === OfferSubmitStepType.CUSTOMER_ACCEPTED || !isHasAppointments
        ? offer
        : {
            id: offer.id,
            [dateField]: selectedDate,
          };

    const transitionType =
      !isHasAppointments && type !== OfferSubmitStepType.CUSTOMER_ACCEPTED
        ? OfferEventType.CUSTOMER_ACCEPTED_WITHOUT_APPOINTMENT
        : {
            [OfferSubmitStepType.SELECTING]: OfferEventType.CUSTOMER_SELECTED_DATE,
            [OfferSubmitStepType.SUBMITTING]: OfferEventType.CUSTOMER_APPOINTMENT_CONFIRMED,
            [OfferSubmitStepType.CUSTOMER_ACCEPTED]: OfferEventType.CUSTOMER_ACCEPTED,
          }[type];

    dispatch(offerTransition(transitionType, data, offerAcceptSuccess));
  };

  const offerAcceptSuccess = () => {
    if (clientRequest?.id) {
      dispatch(clientRequestUpdate(clientRequest?.id, { step: clientRequest?.step! + 1 }));
    }
    handleCloseModal?.();
  };

  const getOptions = () => {
    const currentType = isSelecting
      ? AppointmentPurpose.HomeCheck
      : AppointmentPurpose.Installation;

    return (offer.appointments || [])
      .filter((a) => a.purpose === currentType)
      .map((appointment) => {
        const time = moment(appointment.timestamp);
        return {
          label: `${time.format(DEFAULT_DATE_FORMAT)} ${time.format(DEFAULT_TIME_FORMAT)}`,
          value: time.toISOString(),
        };
      });
  };

  const renderTop = () => {
    if (!isHasAppointments && purpose === 'home_check') {
      return <span>{t('customerFlow:offerReview:offerSubmitPage:withoutAppointment')}</span>;
    }
    if (type === OfferSubmitStepType.CUSTOMER_ACCEPTED) {
      return (
        <div className={styles.boldText}>
          <Typography.Paragraph>
            {t('customerFlow:offerReview:offerSubmitPage:customerAccepted:text1')}
          </Typography.Paragraph>
          <Typography.Paragraph>
            {t('customerFlow:offerReview:offerSubmitPage:customerAccepted:text2')}
          </Typography.Paragraph>
        </div>
      );
    }

    return (
      <>
        <RadioQuestion
          question={t(`customerFlow:offerReview:offerSubmitPage:title:${type}`)}
          description={t('customerFlow:offerReview:offerSubmitPage:description')}
          options={getOptions()}
          optionClassName={styles.option}
          onChange={(value: string) => setSelectedDate(value)}
          value={selectedDate}
        />
        {errors.includes('selectedDate') && (
          <span className={styles.error}>{t('common:errors:fieldRequired')}</span>
        )}
      </>
    );
  };

  const renderOfferData = () => {
    const timestamp = isSelecting
      ? offer.tenderAcceptedHomeCheckTimestamp
      : offer.tenderAcceptedTimestamp;
    const vatAmount = ((offer?.vatRate || 0) / 100) * price!;
    const isPayByIntermediate = checkIsPayByIntermediate(offer, clientRequest);
    if (!price) return;
    return (
      <div className={styles.summary}>
        <div className={styles.topText}>
          {[
            {
              level: 3,
              text: t('customerFlow:offerReview:offerSubmitPage:summary'),
            },
            {
              level: 5,
              text: !isPayByIntermediate ? (
                <>
                  {t('customerFlow:offerReview:offerSubmitPage:priceTitle', {
                    price: formatCurrency(+price! + vatAmount, offer.currencySymbol),
                  })}
                  <div className={styles.price}>
                    <span>{t('common:net')}:</span> {formatCurrency(price, offer.currencySymbol)}
                    <span>
                      {' '}
                      {t('common:vat')}:({offer?.vatRate || 0}%):
                    </span>{' '}
                    {formatCurrency(vatAmount, offer.currencySymbol)}
                  </div>
                </>
              ) : (
                ''
              ),
            },
            {
              level: 5,
              text: t('customerFlow:offerReview:offerSubmitPage:dateTitle', {
                date: timestamp ? formatDate(timestamp, DEFAULT_DATE_FORMAT) : 'n/a',
              }),
            },
          ].map((title, index) => (
            <Typography.Title
              level={title.level as 1 | 2 | 3 | 4 | undefined}
              key={`summary-title-${index}`}
            >
              {title.text}
            </Typography.Title>
          ))}
        </div>
      </div>
    );
  };
  const renderErrors = () =>
    (errors.includes('terms') || errors.includes('protection')) && (
      <span className={styles.error}>{t('customerFlow:offerReview:checkbox:error')}</span>
    );

  const nextButtonText = () => {
    const isPayByIntermediate = checkIsPayByIntermediate(offer, clientRequest);
    if (
      offer.state === OfferState.OfferAcceptedHomeCheckAppointmentTBD ||
      offer.state === OfferState.OfferAcceptedRemoteHomeCheckAppointmentTBD
    ) {
      return t('customerFlow:offerReview:offerSubmitPage:confirm:comfirmHomeCheck');
    }
    return t(
      `customerFlow:offerReview:offerSubmitPage:confirm:${
        isPayByIntermediate && type !== OfferSubmitStepType.SUBMITTING ? 'pay:' : ''
      }${type}`,
    );
  };

  const termPolicyProps = {
    modal: modal,
    installerData: offer.installerUser,
    values: checkboxes,
    onHideModal: handleHideModal,
    onNextClick: handleNextClick,
    onLinkClick: (modal: string) => setModal(modal),
    onChange: handleCheckboxChange,
  };

  return (
    <div className={styles.container}>
      {renderTop()}
      {renderOfferData()}
      {price && <TermsPolicy {...termPolicyProps} />}
      {renderErrors()}
      <div className={styles.buttonsBlock}>
        <Button
          onClick={handleNextClick}
          type="primary"
          loading={offerTransitonState === RequestState.Saving}
        >
          {nextButtonText()}
        </Button>
      </div>
    </div>
  );
};

export default OfferSubmitStep;
