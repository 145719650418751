import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Col, Flex, Row, Skeleton } from 'antd';
import Typography from 'antd/lib/typography';
import DocumentsIcon from 'assets/icons/dashboard/Offer.svg';
import BackButton from 'components/ui/BackButton/BackButton';
import Rectangle from 'components/layout/Rectangle/Rectangle';
import Stepper from 'components/ui/Stepper/Stepper';
import DownloadableDocumentList from 'components/ui/DownloadableDocumentList';
import { ORDER_STEPS } from 'framework/stepper.config';
import { getActiveStep } from 'framework/helpUtils';
import { Container } from 'pages/customer/Dashboard/components';
import { IOffer } from 'store/offer/offer.types';
import { offerGet, offerGetDocsForInstaller } from 'store/offer/offer.actions';
import {
  currentOfferSelector,
  offerDocsForInstallerSelector,
  offerGetStateSelector,
} from 'store/offer/offer.selectors';
import {
  clientRequestDistanceGetAction,
  clientRequestGet,
} from 'store/client-request/client-request.actions';
import {
  currentClientRequestSelector,
  clientRequestStateSelector,
  isClientRequestDistance,
} from 'store/client-request/client-request.selectors';
import { RequestState } from 'store/common.types';

import { checkIsHomeCheck } from 'utils/offerHelpers';
import styles from './OrderDetailsPage.module.sass';
import SectionContact from './SectionContact';
import SectionNextSteps from './SectionNextSteps/SectionNextSteps';
import { feedbackListSelector } from '../../../store/feedback/feedback.selectors';
import { feedbackGet } from '../../../store/feedback/feedback.actions';
import { FeedbackReferenceType } from '../../../store/feedback/feedback.types';

const OrderDetailsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: offerId } = useParams();

  const offer = useSelector(currentOfferSelector);
  const clientRequest = useSelector(currentClientRequestSelector);
  const clientRequestDistance = useSelector(isClientRequestDistance);
  const offerState = useSelector(offerGetStateSelector);
  const clientRequestState = useSelector(clientRequestStateSelector);
  const { docsForInstallerState, docsForInstaller } = useSelector(offerDocsForInstallerSelector);

  const isHomeCheck = checkIsHomeCheck(offer?.state!);

  // Distance API returns array of distances(multiple address support), Using 1st distance
  const distance = clientRequestDistance?.length ? clientRequestDistance[0].distance : '';
  const installationDate = isHomeCheck
    ? offer?.finalHomeCheckTimestamp
    : offer?.finalInstallationTimestamp;
  const currentFeedback = useSelector(feedbackListSelector);
  useEffect(() => {
    if (clientRequest && clientRequest.id) {
      dispatch(feedbackGet(clientRequest.id, FeedbackReferenceType.ClientRequests));
    }
  }, [dispatch, clientRequest]);
  const activeStep = getActiveStep(offer, !!currentFeedback?.length);

  useEffect(() => {
    dispatch(offerGet({ id: offerId!, relations: ['installerUser', 'appointments'] }));
    dispatch(offerGetDocsForInstaller(offerId!));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (offer?.clientRequestId) {
      dispatch(clientRequestGet(offer.clientRequestId, false, false));
      dispatch(clientRequestDistanceGetAction(offer.clientRequestId));
    }
  }, [offer?.clientRequestId]); // eslint-disable-line react-hooks/exhaustive-deps

  const steps = useMemo(
    () =>
      ORDER_STEPS.map((step) => ({
        ...step,
        title: t(step.title),
        subtitle: isHomeCheck ? 'Home-Check' : 'Installation',
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offer],
  );

  if (clientRequestState === RequestState.Loading || offerState === RequestState.Loading) {
    return (
      <Skeleton
        active={true}
        paragraph={{
          rows: 3,
        }}
        round={true}
      />
    );
  }

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <BackButton onClick={goBack}>{t('common:buttons:back')}</BackButton>

        <Stepper activeStep={activeStep} steps={steps} />

        <Typography.Title level={3} className={styles.title}>
          {t('installerFlow:orderDetails:offer')} {clientRequest?.displayId}
        </Typography.Title>
      </div>

      <Flex vertical gap="middle">
        <Row gutter={[16, 16]}>
          <Col span={8}>
            {clientRequest && offer ? (
              <SectionContact
                name={`${clientRequest?.createdBy?.firstName} ${clientRequest?.createdBy?.lastName}`.trim()}
                installationAddress={`${clientRequest?.address?.street}, ${clientRequest?.address?.houseNumber}, ${clientRequest?.address?.postalCode}, ${clientRequest?.address?.city}`.trim()}
                billingAddress={
                  clientRequest?.billingAddress &&
                  `${clientRequest.billingAddress?.street}, ${clientRequest.billingAddress?.houseNumber}, ${clientRequest?.address?.postalCode}, ${clientRequest.billingAddress?.city}`.trim()
                }
                companyName={clientRequest.address?.companyName}
                billingCompanyName={clientRequest.billingAddress?.companyName}
                distance={`${distance} km`}
                email={clientRequest?.createdBy?.email}
                phone=""
                mobile={clientRequest?.createdBy?.phone}
                assignedTo={`${offer?.installerUser?.firstName} ${offer?.installerUser?.lastName}`}
                clientRequestId={`${clientRequest?.id}`}
              />
            ) : (
              <Rectangle title={t('installerFlow:orderDetails:contact')}>
                <Skeleton paragraph={{ rows: 7 }} active />
              </Rectangle>
            )}
          </Col>

          <Col span={16}>
            <SectionNextSteps
              id={offerId!}
              date={installationDate}
              offer={offer as IOffer}
              clientRequest={clientRequest}
            />
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Container title={t('common:documents')} icon={DocumentsIcon} md={24}>
              <DownloadableDocumentList docs={docsForInstaller} docsState={docsForInstallerState} />
            </Container>
          </Col>
        </Row>
      </Flex>
    </div>
  );
};

export default OrderDetailsPage;
