import React, { useEffect, useState } from 'react';
import Form from 'antd/lib/form';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { JsonForms } from '@jsonforms/react';
import { useNavigate, useParams } from 'react-router-dom';
import BoxContent from 'components/layout/BoxContent/BoxContent';
import CustomerLayout from 'components/layout/CustomerLayout/CustomerLayout';
import { scrollToFormField } from 'framework/scrollerUtils';
import { customRenderer, unifyUiSchemas, unifySchemas } from 'framework/jsonForm';
import { PATH } from 'framework/path';
import SurveyResultPage from 'pages/survey/SurveyResultPage/SurveyResultPage';
import { changeStep } from 'store/app/app.actions';
import { questionnaireSelector } from 'store/intermediate/intermediate.selectors';
import { activeStepSelector, activeSubStepSelector } from 'store/app/app.selectors';
import { questionnaireGet } from 'store/intermediate/intermediate.actions';
import { surveyCreateStateSelector } from 'store/surveys/surveys.selectors';
import { surveyCreate } from 'store/surveys/surveys.actions';
import { IPool } from 'store/intermediate/intermediate.types';
import { RequestState } from 'store/common.types';
import { QuestionnaireStepType } from 'types/questionnaire.types';
import styles from './SurveyPage.module.sass';

export interface ISurveyAnswer {
  questionKey: string;
  answerValue: {
    value: string | any;
  };
}

const SurveyPage: React.FC = () => {
  const [jsonformsData, setJsonformsData] = useState<any>({});
  const [answers, setAnswers] = useState<Map<string, ISurveyAnswer>>(
    new Map<string, ISurveyAnswer>(),
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isNotLastStep, setIsNotLastStep] = useState<boolean>(true);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { poolId } = useParams();

  const { t } = useTranslation(['survey']);
  const navigate = useNavigate();
  const activeStep = useSelector(activeStepSelector);
  const activeSubStep = useSelector(activeSubStepSelector);
  const questionnaire = useSelector(questionnaireSelector);
  const surveyCreateState = useSelector(surveyCreateStateSelector);

  let uiSchema: any;
  let schema: any;
  let activeStepData: undefined | { title: string; children: any[] };
  let stepTitle: string = '';
  let subStepTitle: string = '';
  let maxSubSteps: number = 1;

  if (questionnaire) {
    activeStepData = questionnaire.steps[activeStep - 1];
    if (activeStepData) {
      uiSchema = unifyUiSchemas(questionnaire, activeStep - 1);
      schema = unifySchemas(questionnaire, activeStep - 1);
      stepTitle = activeStepData?.title;
      maxSubSteps = activeStepData?.children.length + 1;
      subStepTitle = activeStepData.children[activeSubStep - 1]?.title;
    }
  }

  useEffect(() => {
    if (questionnaire) {
      const subStepCount = questionnaire.steps[activeStep - 1].children.length;
      const activeStepType = questionnaire.steps[activeStep - 1].stepType;
      const isNotLastStep =
        activeStepType === QuestionnaireStepType.User
          ? activeSubStep <= subStepCount
          : activeSubStep < subStepCount + 1;
      setIsNotLastStep(isNotLastStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubStep]);

  useEffect(() => {
    if (isLoading && surveyCreateState === RequestState.Success) {
      dispatch(changeStep(activeStep, activeSubStep + 1));
      setLoading(false);
    }
  }, [surveyCreateState, isLoading]); // eslint-disable-line

  useEffect(() => {
    if (poolId) {
      dispatch(changeStep(1, 1));
      dispatch(questionnaireGet(poolId));
    } else {
      navigate(PATH.HOME);
    }
  }, [poolId]); // eslint-disable-line

  const onValuesChange = (values: any) => {
    const changedAnswers = Object.keys(values).map((changeQuestionKey) => ({
      questionKey: changeQuestionKey,
      answerValue: { value: values[changeQuestionKey] },
    }));
    const newAnswers = new Map<string, ISurveyAnswer>(answers);
    (changedAnswers || []).forEach((answer) => {
      newAnswers.set(answer.questionKey, answer);
    });
    setAnswers(newAnswers);
    return newAnswers;
  };

  const nextStep = async () => {
    const newAnswers = onValuesChange(jsonformsData);

    try {
      await form.validateFields();
    } catch (error) {
      const e = error as any;
      if (e.errorFields) {
        const errorField = e.errorFields[0];
        if (errorField && errorField.name[0]) {
          scrollToFormField(errorField.name[0]);
          return;
        }
      }
      throw e;
    }
    const subStepCount = questionnaire.steps[activeStep - 1].children.length;
    const activeStepType = questionnaire.steps[activeStep - 1].stepType;
    const isNotLastStep =
      activeStepType === QuestionnaireStepType.User
        ? activeSubStep < subStepCount
        : activeSubStep < subStepCount + 1;

    const shouldSaveSurvey =
      activeStepType === QuestionnaireStepType.QuickCheck && activeSubStep === subStepCount;

    if (shouldSaveSurvey) {
      setLoading(true);
      dispatch(
        surveyCreate({
          step: 1,
          pool: {
            id: poolId,
          } as IPool,
          questionnaire: {
            id: questionnaire.id,
            version: questionnaire.version,
          },
          answers: Array.from(newAnswers, (v) => v[1]),
        }),
      );
      return;
    }

    if (isNotLastStep) {
      dispatch(changeStep(activeStep, activeSubStep + 1));
    } else {
      dispatch(changeStep(activeStep + 1, 1));
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const prevStep = () => {
    dispatch(changeStep(activeStep, activeSubStep - 1));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onJsonFormsChange = (data: any) => {
    if (data) {
      Object.keys(data).forEach((item) => {
        form.setFieldsValue({
          [item]: data[item],
        });
      });
      setJsonformsData(data);
    }
  };

  const isFirstStep = activeSubStep === 1;
  const isLastStep = activeSubStep === maxSubSteps;

  const boxProps = {
    title: t(stepTitle),
    showNextButton: !isLastStep,
    showBackButton: !isFirstStep && !isLastStep && !isLoading,
    onNextClick: nextStep,
    onBackClick: prevStep,
    nextButtonLoading: isLoading,
  };

  const renderQuestionnaire = () => {
    if (isNotLastStep)
      return (
        <Form form={form} className={styles.questionForm}>
          <JsonForms
            schema={schema}
            uischema={uiSchema.elements[activeSubStep - 1]}
            data={jsonformsData}
            renderers={customRenderer}
            onChange={({ data }) => {
              onJsonFormsChange(data);
            }}
          />
        </Form>
      );
    else return <SurveyResultPage />;
  };

  return (
    <CustomerLayout>
      <BoxContent {...boxProps}>
        <Helmet>
          <title>{`${t(stepTitle)} - ${t(subStepTitle)} - Tendergy`}</title>
        </Helmet>
        {questionnaire && questionnaire.steps && renderQuestionnaire()}
      </BoxContent>
    </CustomerLayout>
  );
};

export default SurveyPage;
