import { produce } from 'immer';
import { RequestState } from 'store/common.types';
import {
  CLEAR_CURRENT_FILE,
  DocumentActionTypes,
  IDocumentState,
  UPLOAD_GENERIC_FILE,
  UPLOAD_GENERIC_FILE_ERROR,
  UPLOAD_GENERIC_FILE_SUCCESS,
} from './document.types';

const initialState: IDocumentState = {
  file: null,
  fileState: null,
  fileError: null,
};

const DocumentReducer = (state = initialState, action: DocumentActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPLOAD_GENERIC_FILE:
        draft.fileState = RequestState.Loading;
        break;

      case UPLOAD_GENERIC_FILE_ERROR:
        draft.fileState = RequestState.Error;
        draft.fileError = null;
        break;

      case UPLOAD_GENERIC_FILE_SUCCESS:
        draft.fileState = RequestState.Success;
        draft.fileError = null;
        draft.file = action.payload;
        break;

      case CLEAR_CURRENT_FILE:
        draft.file = action.payload;
        break;
    }
  });

export default DocumentReducer;
