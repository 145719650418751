import React from 'react';
import { useTranslation } from 'react-i18next';
import Rectangle from 'components/layout/Rectangle/Rectangle';
import { IClientRequest } from 'store/client-request/client-request.types';
import { CostCalculationModeOptionsType } from 'types/pool-settings.types';
import { formatCurrency, formatPrice, getPriceRange } from 'utils/currencyUtils';
import styles from './TenderDetailsPage.module.sass';

const PriceWidget: React.FC<{ clientRequest: IClientRequest }> = ({ clientRequest }) => {
  const { t } = useTranslation();

  const label = t('installerFlow:tenderDetails:priceRangeSubtitle');
  const isQuestionnaireSkipped = clientRequest?.questionnaireSkipped;
  const isDeactivated =
    clientRequest?.pool?.costCalculationMode === CostCalculationModeOptionsType.Deactivated;
  const priceRange = clientRequest ? getPriceRange(clientRequest) : null;
  const country = clientRequest?.address?.country;

  const renderPrice = () => {
    if (isDeactivated) {
      return (
        <h5 className={styles.titleSmallRange}>{t('installerFlow:tenderDetails:notAvailable')}</h5>
      );
    }

    if (isQuestionnaireSkipped) {
      return (
        <div className={styles.centerBlock}>
          <h5 className={styles.titleSmallRange}>
            {formatPrice(clientRequest?.pool?.homeCheckEstimate)}€
          </h5>
        </div>
      );
    }

    return (
      <div className={styles.centerBlock}>
        {clientRequest && priceRange?.min && priceRange?.max && country ? (
          <div className={styles.priceRange}>
            <div>
              <div className={styles.price}>
                {formatCurrency(+priceRange.min, country.currencySymbol)} -{' '}
              </div>
              <div>
                <span>{t('common:net')}:</span>
                {formatCurrency(
                  (1 - country.vatRate / 100) * +priceRange.min,
                  country.currencySymbol,
                )}
              </div>
              <div>
                <span>
                  {t('common:vat')}:({country.vatRate}%):
                </span>{' '}
                {formatCurrency((country.vatRate / 100) * +priceRange.min, country.currencySymbol)}
              </div>
            </div>
            <div>
              <div className={styles.price}>
                {formatCurrency(+priceRange.max, country.currencySymbol)}
              </div>
              <div>
                <span>{t('common:net')}:</span>
                {formatCurrency(
                  (1 - country.vatRate / 100) * +priceRange.max,
                  country.currencySymbol,
                )}
              </div>
              <div>
                <span>
                  {t('common:vat')}:({country.vatRate}%):
                </span>{' '}
                {formatCurrency((country.vatRate / 100) * +priceRange.max, country.currencySymbol)}
              </div>
            </div>
          </div>
        ) : (
          <span>n/a</span>
        )}
        <p className={styles.label}>{label}</p>
      </div>
    );
  };

  return <Rectangle title={t('installerFlow:tenderDetails:priceRange')}>{renderPrice()}</Rectangle>;
};

export default PriceWidget;
