import React, { FC, useContext, useEffect, useState, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Skeleton } from 'antd';
import { useDispatch } from 'react-redux';
import BackButton from 'components/ui/BackButton/BackButton';
import OfferReviewStep from 'components/steps/OfferReviewStep/OfferReviewStep';
import OffersListStep from 'components/steps/OffersListStep/OffersListStep';
import OfferSubmitStep, {
  OfferSubmitStepType,
} from 'components/steps/OfferSubmitStep/OfferSubmitStep';
import Stepper from 'components/ui/Stepper/Stepper';
import { CustomerDashboardContext } from 'context/customerDashboard.context';
import { offerTransition } from 'store/offer/offer.actions';
import { IOffer, OfferEventType, OfferState } from 'store/offer/offer.types';
import { ClientRequestState } from 'store/client-request/client-request.types';
import { clientRequestGet } from 'store/client-request/client-request.actions';
import { OfferSelectionSteps } from 'types/customer-flow.types';
import styles from './OfferSelection.module.sass';

type OfferSelectionProps = {
  onClose: () => void;
};

const OfferSelection: FC<OfferSelectionProps> = ({ onClose }) => {
  const [step, setStep] = useState(1);
  const [selectedOffer, setSelectedOffer] = useState<IOffer>();
  const { offer, offers, request } = useContext(CustomerDashboardContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOfferClick = (index: number) => {
    setSelectedOffer(offers?.[index]);
    setStep(step + 1);
  };
  const handleOfferConfirm = () => setStep(step + 1);

  const handleRejectOffer = () => {
    dispatch(
      offerTransition(OfferEventType.CUSTOMER_REJECTED, selectedOffer!, () => {
        if (request?.id) dispatch(clientRequestGet(request.id, false));
      }),
    );
    onClose();
  };

  useEffect(() => {
    if (!offer) return;
    if (
      offer?.state === OfferState.OfferAcceptedRemoteHomeCheckAppointmentTBD ||
      offer?.state === OfferState.OfferAcceptedHomeCheckAppointmentTBD
    ) {
      setStep(3);
      setSelectedOffer(offer);
    }
    if (request?.state === ClientRequestState.InstallationPending) {
      const isSubmitted = offer?.state === OfferState.OfferSubmitted;
      setSelectedOffer(offer);
      setStep(isSubmitted ? 3 : 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offers]);

  const handleCloseModal = () => {
    setSelectedOffer(undefined);
    setStep(1);
    onClose();
  };

  const isDecline = useMemo(() => {
    if (!selectedOffer) return false;
    return [
      OfferState.TenderAccepted,
      OfferState.TenderAcceptedHomeCheck,
      OfferState.TenderAcceptedRemoteHomeCheck,
    ].includes(selectedOffer?.state!);
  }, [selectedOffer]);

  if (!offers?.length) return <Skeleton />;

  const renderContent = () => {
    const state = selectedOffer?.state;
    const isHomeCheck =
      state === OfferState.TenderAcceptedHomeCheck ||
      state === OfferState.TenderAcceptedRemoteHomeCheck ||
      state === OfferState.OfferAcceptedHomeCheckAppointmentTBD ||
      state === OfferState.OfferAcceptedRemoteHomeCheckAppointmentTBD;

    const submitScreenType = isHomeCheck
      ? OfferSubmitStepType.SELECTING
      : OfferSubmitStepType.CUSTOMER_ACCEPTED;

    switch (step) {
      case 1:
        return (
          <OffersListStep
            list={offers || []}
            onOfferClick={handleOfferClick}
            showQuestions={false}
          />
        );
      case 2:
        return <OfferReviewStep offer={selectedOffer!} onConfirm={handleOfferConfirm} />;
      case 3:
        return (
          <OfferSubmitStep
            offer={selectedOffer!}
            handleCloseModal={handleCloseModal}
            type={
              selectedOffer?.state === OfferState.OfferSubmitted
                ? OfferSubmitStepType.SUBMITTING
                : submitScreenType
            }
          />
        );
      default:
        return null;
    }
  };

  const renderActionButtons = () => (
    <div>
      {isDecline && (
        <Button type="primary" danger style={{ marginRight: '8px' }} onClick={handleRejectOffer}>
          {t('common:buttons:decline')}
        </Button>
      )}
      <Button type="primary" onClick={handleOfferConfirm}>
        {t('common:buttons:further')}
      </Button>
    </div>
  );

  return (
    <>
      <Stepper steps={OfferSelectionSteps} activeStep={step} />
      {renderContent()}
      <div className={styles.footer}>
        {step > 1 && (
          <BackButton
            key="back-button"
            disabled={
              offer?.state === OfferState.OfferAcceptedHomeCheckAppointmentTBD ||
              offer?.state === OfferState.OfferAcceptedRemoteHomeCheckAppointmentTBD ||
              offer?.state === OfferState.OfferSubmitted
            }
            onClick={() => setStep(step - 1)}
          >
            {t('common:buttons:back')}
          </BackButton>
        )}
        {step === 2 && renderActionButtons()}
      </div>
    </>
  );
};

export default OfferSelection;
