import { IRadionOption, QuestionnaireStepType } from 'types/questionnaire.types';

export const excludeQuestionnaireSteps = [
  QuestionnaireStepType.RemoteHomeCheck,
  QuestionnaireStepType.User,
];

export const getQuestionnaireLength = (questionnaire: any) => questionnaire?.steps?.length;

export type RadioValueType = { [key: string]: string } | string;
export type InputValueType = { [key: string]: string | boolean } | string;

export const extractRadioLabel = (answerValue: RadioValueType, options: any) => {
  const { question, radioFields } = options;
  const questionLabel: string = question;
  let answerValueLabel: string = '';

  if (typeof answerValue === 'string') {
    answerValueLabel = getRadioLabel(answerValue, radioFields.options) || '';
    return { questionLabel, answerValueLabel };
  }

  if (answerValue.text) {
    answerValueLabel = answerValue.text as string;
  } else if (answerValue.selectedOption) {
    answerValueLabel = getRadioLabel(answerValue.selectedOption, radioFields.options) || '';
  }

  return { questionLabel, answerValueLabel };
};

const getRadioLabel = (value: string, options: IRadionOption[]) => {
  const option = options.find((item: IRadionOption) => item.value === value);
  return option?.label;
};

export const extractInputValue = (answerValue: InputValueType, options: any) => {
  const { question: questionLabel } = options;

  let answerValueLabel: string = '';

  if (typeof answerValue === 'string') {
    return { questionLabel, answerValueLabel: answerValue };
  }

  if (options.multipleInputFields) {
    const hasTrue = Object.keys(answerValue).find((key) => answerValue[key] === true);
    if (hasTrue) {
      answerValueLabel = options.multipleInputFields.booleanOption.title;
    } else {
      answerValueLabel = options.multipleInputFields.inputs.map(
        (input: { [key: string]: string }) => ({
          label: input.title,
          value: answerValue[input.key],
        }),
      );
    }
  }

  return { questionLabel, answerValueLabel };
};
