import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';

import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Typography from 'antd/lib/typography';
import { Input } from 'antd';
import BoxContent from 'components/layout/BoxContent/BoxContent';
import InstallerCard from 'components/ui/InstallerCard/InstallerCard';

import { MediaCategoryType, QuestionType } from 'store/client-request/client-request.types';
import { isQuestionsSelector } from 'store/client-request/client-request.selectors';
import {
  addComment,
  deleteFile,
  getComments,
  updateComment,
} from 'store/client-request/client-request.actions';

import { CommentType } from 'store/comment/comment.types';
import { genericFileSelector } from 'store/document/document.selectors';
import { clearCurrentFile } from 'store/document/document.actions';
import styles from './OfferQuestionPage.module.sass';
import UploadDocument from './UploadDocument';

const OfferQuestionPage = () => {
  const [text, setText] = useState<string>('');
  const [answer, setAnswer] = useState<CommentType>();

  const { id, questionId } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const questionsAnswersList = useSelector(isQuestionsSelector);
  const onlyQuestions = questionsAnswersList.filter(
    (question: QuestionType) => !question.parentCommentId,
  );
  const currentQuestion = onlyQuestions.find((q) => q.id === questionId);
  const parentComment = _get(currentQuestion, 'id', '');

  const uploadedFile = useSelector(genericFileSelector);
  const uploadedFiles = uploadedFile ? [uploadedFile] : answer?.attachments || [];

  useEffect(() => {
    dispatch(clearCurrentFile());
  }, [dispatch]);

  useEffect(() => {
    const answers = questionsAnswersList.filter((q) => currentQuestion?.id === q.parentCommentId);
    if (answers.length) {
      setText(_get(answers, '0.text', ''));
      setAnswer(answers[0] as CommentType);
    }
  }, [questionsAnswersList, currentQuestion]);

  const handleAddAnswerSuccess = () => {
    navigate(`/customer/client-request/${id}/offer`);
  };

  const handleAddAnswer = () => {
    if (answer) {
      if (
        uploadedFile &&
        answer?.attachments?.length &&
        uploadedFile.id !== answer.attachments[0].id
      ) {
        const [media] = answer.attachments;
        dispatch(deleteFile(media.name, media.id, MediaCategoryType.CommentAttachment));
      }
      dispatch(
        updateComment({
          clientRequestId: id!,
          commentId: answer.id,
          params: {
            text,
            attachments: uploadedFiles.length ? uploadedFiles.map((f) => f?.id) : undefined,
          },
          onSuccess: handleAddAnswerSuccess,
        }),
      );
    } else {
      dispatch(
        addComment({
          id: id!,
          params: {
            type: currentQuestion?.type,
            text,
            parentComment,
            attachments: uploadedFiles.length ? uploadedFiles.map((f) => f?.id) : [],
          },
          onSuccess: handleAddAnswerSuccess,
          onError: () => {}, //TODO Handle upload error
        }),
      );
    }
  };

  const updateQuestions = () => {
    dispatch(getComments(id!));
  };

  const textAreaProps = {
    className: styles.textArea,
    value: text,
    onChange: (event: any) => {
      setText(event.target.value);
    },
  };

  const usedObj = {
    firstName: _get(currentQuestion, 'author.firstName', ''),
    lastName: _get(currentQuestion, 'author.lastName', ''),
    phone: _get(currentQuestion, 'author.phone', ''),
    email: _get(currentQuestion, 'author.email', ''),
    organization: _get(currentQuestion, 'author.organization', ''),
  };

  const boxProps = {
    title: t('customerFlow:offerReview:offerListPage:questions'),
    showNextButton: true,
    showBackButton: true,
    nextButtonText: answer
      ? t('customerFlow:offerReview:done')
      : t('customerFlow:offerReview:publishAnswer'),
    onNextClick: handleAddAnswer,
    onBackClick: () => navigate(-1),
  };

  const uploadDocumentProps = {
    category: MediaCategoryType.CommentAttachment,
    questionId: answer?.id,
    onDeleteSuccess: updateQuestions,
    uploadedFiles,
  };

  return (
    <BoxContent {...boxProps}>
      <div className={styles.content}>
        <div className={styles.question}>
          <InstallerCard
            small
            // @ts-ignore
            user={usedObj}
          />
          <Typography.Title className={styles.questionText} level={4}>
            {currentQuestion?.text}
          </Typography.Title>
        </div>
        <div className={styles.answer}>
          <Typography.Title level={4}>{t('customerFlow:offerReview:yourAnswer')}</Typography.Title>
          <Input.TextArea {...textAreaProps} />

          <UploadDocument {...uploadDocumentProps} />
        </div>
      </div>
    </BoxContent>
  );
};

export default OfferQuestionPage;
