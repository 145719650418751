import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/lib/modal';
import { Col, Collapse, Row } from 'antd';
import { Link } from 'react-router-dom';
import { CloseOutlined, EditFilled } from '@ant-design/icons';
import Cookies from 'universal-cookie';
import moment from 'moment';
import cookiesIcon from 'assets/icons/cookies.svg';
import Icon from 'components/ui/Icon/Icon';
import Button from 'components/ui/Button/Button';
import styles from './CookieConsentModal.module.sass';
import CookieTable from './CookieTable';

const { Panel } = Collapse;
const cookies = new Cookies();

const deleteAllCookies = () => {
  Object.keys(cookies.getAll()).forEach((key) => {
    key !== 'CookieConsent' && cookies.remove(key);
  });
};

const CookieConsentModal: React.FC = () => {
  const { t } = useTranslation(['welcomePage']);
  const [isCookieModalVisible, setCookieModalVisible] = useState(!cookies.get('CookieConsent'));
  const [selectedCookies, setSelectedCookies] = useState<React.Key[]>(
    Array.isArray(cookies.get('CookieConsent')) ? cookies.get('CookieConsent') : ['refreshToken'],
  );

  useEffect(() => {
    const cookieConsent = cookies.get('CookieConsent');
    if (!cookieConsent || cookieConsent === 'rejected') {
      deleteAllCookies();
    }
    if (cookieConsent === 'all') {
      cookies.set('CookieConsent', JSON.stringify(['refreshToken']), {
        expires: moment().add(1, 'year').toDate(),
      });
    }
  }, []);

  const onCancel = () => {
    setCookieModalVisible(false);
  };

  const onAccept = () => {
    deleteAllCookies();
    cookies.remove('CookieConsent');
    cookies.set('CookieConsent', JSON.stringify(selectedCookies), {
      expires: moment().add(1, 'year').toDate(),
    });
    onCancel();
  };

  const onReject = () => {
    deleteAllCookies();
    cookies.set('CookieConsent', 'rejected', { expires: moment().add(1, 'year').toDate() });
    onCancel();
  };

  const modalProps = {
    centered: true,
    closable: false,
    footer: null,
    width: 620,
    open: isCookieModalVisible,
    onCancel,
  };

  return (
    <>
      <div
        onClick={() => setCookieModalVisible(!isCookieModalVisible)}
        className={styles.cookiesButton}
      >
        <Icon icon={cookiesIcon} className={styles.icon} />
      </div>
      <Modal {...modalProps} className={styles.container}>
        <div>
          <h1 className={styles.title}>{t('welcomePage:cookies:title')}</h1>
          <p className={styles.text}>
            {t('welcomePage:cookies:description')}
            <Link to="/legal/privacy-policy" className={styles.link}>
              {t('welcomePage:cookies:privacyPolicy')}
            </Link>
            {t('welcomePage:cookies:description2')}
            <Link to="/legal/legal" className={styles.link}>
              {t('welcomePage:cookies:legal')}
            </Link>
            .
          </p>
          <Collapse ghost className={styles.collapse} expandIcon={() => <EditFilled />}>
            <Panel header={t('welcomePage:cookies:customize')} key="1">
              <CookieTable
                selectedRowKeys={selectedCookies}
                setSelectedRowKeys={setSelectedCookies}
              />
            </Panel>
          </Collapse>
          <Row gutter={[24, 0]}>
            <Col span={12}>
              {/* @ts-ignore */}
              <Button onClick={onReject} type="secondary" block size="large">
                {t('welcomePage:cookies:reject')}
              </Button>
            </Col>
            <Col span={12}>
              <Button onClick={onAccept} type="primary" block size="large">
                {t('welcomePage:cookies:accept')}
              </Button>
            </Col>
          </Row>

          <div className={styles.close} onClick={onCancel}>
            <CloseOutlined />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CookieConsentModal;
