import React, { useEffect, useState } from 'react';
import _isEqual from 'lodash/isEqual';
import { Row, Col, Form, Button as AntButton, message, Typography, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Store } from 'antd/lib/form/interface';
import { CloseOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import Button from 'components/ui/Button/Button';
import CarModelQuestion from 'components/questions/CarModelQuestion/CarModelQuestion';
import Checkbox from 'components/ui/Checkbox/Checkbox';
import { scrollToFormField } from 'framework/scrollerUtils';
import { ValidationFactory } from 'framework/validations';
import SelectQuestionModal from 'pages/MyProjects/SelectQuestionModal/SelectQuestionModal';
import { IPool, IQuestionnaireConfig } from 'store/intermediate/intermediate.types';
import { QuestionKey } from 'store/client-request/client-request.types';
import {
  questionnaireConfigCreate,
  questionnaireConfigDelete,
  questionnaireConfigUpdate,
} from 'store/intermediate/intermediate.actions';
import ChargingStationLimitQuestion from './ChargingStationLimitQuestion';
import styles from './QuestionnaireTab.module.sass';
import editStyles from '../EditPool.module.sass';

export interface IQuestionnaireTabProps {
  pool: IPool;
  configs: IQuestionnaireConfig[];
}

const QuestionnaireTab: React.FC<IQuestionnaireTabProps> = ({ pool, configs }) => {
  const { t } = useTranslation();
  const [isVisibleModal, setVisibleModal] = useState(false);
  const [questions, setQuestions] = useState<IQuestionnaireConfig[]>([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    setQuestions(
      configs.map((c) => ({
        ...c,
        defaultAnswer: JSON.parse(c.defaultAnswer),
        possibleAnswers: JSON.parse(c.possibleAnswers),
      })),
    );
  }, [configs]);

  const onAddQuestion = (v: any) => {
    if (!questions.find((i) => i.questionKey === v)) {
      setQuestions([
        ...questions,
        {
          questionKey: v,
          isDisabled: true,
          isVisible: false,
          defaultAnswer: {},
          possibleAnswers: {},
        },
      ]);
    } else {
      message.error(t('intermediate:myProjects:editProjectPools:questionExist'));
    }
  };

  const onRemoveCLick = (name: string) => {
    const config = configs.find((c) => c.questionKey === name);
    if (config && config.id) {
      dispatch(questionnaireConfigDelete(pool.id, config.id));
    } else {
      setQuestions(questions.filter((q) => q.questionKey !== name));
    }
    message.info(t('intermediate:myProjects:editProjectPools:questionDeleted'));
  };

  const onSaveClick = async () => {
    let values: Store;
    try {
      values = await form.validateFields();
    } catch (e) {
      if ((e as any).errorFields) {
        const errorField = (e as any).errorFields[0];
        if (errorField && errorField.name[0]) {
          scrollToFormField(errorField.name[0]);
          return;
        }
      }
      throw e;
    }

    Object.keys(values).forEach((changeQuestionKey) => {
      const config = configs.find((c) => c.questionKey === changeQuestionKey);
      const isVisible = questions.find((q) => q.questionKey === changeQuestionKey)!.isVisible;
      if (config && config.id) {
        if (
          !_isEqual(
            JSON.parse(!isVisible ? config.defaultAnswer : config.possibleAnswers),
            values[changeQuestionKey],
          ) ||
          config.isVisible !== isVisible
        ) {
          dispatch(
            questionnaireConfigUpdate(pool.id, config.id, {
              ...config,
              isVisible,
              defaultAnswer: isVisible
                ? config.defaultAnswer
                : JSON.stringify(values[changeQuestionKey]),
              possibleAnswers: !isVisible
                ? config.possibleAnswers
                : JSON.stringify(values[changeQuestionKey]),
            }),
          );
        }
      } else {
        dispatch(
          questionnaireConfigCreate(pool.id, {
            questionKey: changeQuestionKey,
            isDisabled: true,
            isVisible,
            defaultAnswer: !isVisible ? JSON.stringify(values[changeQuestionKey]) : '{}',
            possibleAnswers: isVisible ? JSON.stringify(values[changeQuestionKey]) : '[]',
          }),
        );
      }
    });
    message.info(t('intermediate:myProjects:editProjectPools:poolSaved'));
  };

  const changeQuestionMode = (name: string) => {
    setQuestions(
      questions.map((item) =>
        item.questionKey !== name ? item : { ...item, isVisible: !item.isVisible },
      ),
    );
    const question = questions.find((item) => item.questionKey === name)!;
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [name]: question.isVisible ? question.defaultAnswer : question.possibleAnswers,
    });
  };

  const selectQuestionModalProps = {
    changeVisible: setVisibleModal,
    onClose: () => setVisibleModal(false),
    visible: isVisibleModal,
    onAdd: onAddQuestion,
  };

  const questionComponent = (name: string) =>
    (
      ({
        [QuestionKey.CarModel]: <CarModelQuestion />,
      }) as any
    )[name];

  const questionLimitComponent = (name: string) =>
    (
      ({
        [QuestionKey.ChargingStation]: <ChargingStationLimitQuestion />,
      }) as any
    )[name];

  const questionRules = (name: string) =>
    (
      ({
        [QuestionKey.CarModel]: [
          {
            validator: async (_rule: any, value: any) => {
              const hasManufacturerAndModel = Boolean(value.manufacturer && value.model);
              const hasNoVehicle = Boolean(value.noVehicle);

              if (!value || !(hasManufacturerAndModel || hasNoVehicle)) {
                throw new Error();
              }
            },
            message: t('customerFlow:questions:carModel:error'),
          },
        ],
        [QuestionKey.ChargingStation]: [ValidationFactory.CHARGING_STATION],
      }) as any
    )[name];

  return (
    <Row>
      <Col span={24} className={styles.container}>
        <Typography className={editStyles.boxTitle}>
          {t('intermediate:myProjects:editProjectPools:questionnaire')}
          <Tooltip
            title={t('intermediate:myProjects:editProjectPools:questionnaireNotification')}
            placement="bottom"
            className={editStyles.tooltip}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </Typography>
        <Form form={form} style={{ flex: 1 }}>
          {questions.map((item) => (
            <Form.Item key={item.questionKey} className={styles.question}>
              <AntButton
                className={styles.closeButton}
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => onRemoveCLick(item.questionKey)}
              />
              <Form.Item
                name={item.questionKey}
                rules={questionRules(item.questionKey)}
                noStyle
                initialValue={!item.isVisible ? item.defaultAnswer : item.possibleAnswers}
              >
                {!item.isVisible
                  ? questionComponent(item.questionKey)
                  : questionLimitComponent(item.questionKey)}
              </Form.Item>
              {questionLimitComponent(item.questionKey) && (
                <>
                  <Checkbox
                    checked={!item.isVisible}
                    onChange={() => changeQuestionMode(item.questionKey)}
                    className={styles.modeCheckbox}
                  >
                    {t('intermediate:myProjects:editProjectPools:defaultAnswer')}
                  </Checkbox>
                  <Checkbox
                    checked={item.isVisible}
                    onChange={() => changeQuestionMode(item.questionKey)}
                    className={styles.modeCheckbox}
                  >
                    {t('intermediate:myProjects:editProjectPools:possibleAnswers')}
                  </Checkbox>
                </>
              )}
            </Form.Item>
          ))}
        </Form>
        <div className={styles.buttons}>
          {/* @ts-ignore */}
          <Button type="secondary" onClick={() => setVisibleModal(true)}>
            {t('intermediate:myProjects:editProjectPools:addQuestion')}
          </Button>
          <Button type="primary" onClick={onSaveClick}>
            {t('intermediate:myProjects:editProjectPools:saveChanges')}
          </Button>
        </div>
        <SelectQuestionModal {...selectQuestionModalProps} />
      </Col>
    </Row>
  );
};

export default QuestionnaireTab;
