import React, { useEffect, useRef } from 'react';

import { Carousel } from 'antd';
import clsx from 'clsx';
import { CarouselRef } from 'antd/es/carousel';
import { ReactComponent as ArrowIcon } from 'assets/icons/dashboard/ArrowRight.svg';
import styles from './Stepper.module.sass';
import Step from './Step/Step';
import { IClientRequestLastStep } from '../../../store/client-request/client-request.types';

export interface IStep {
  key: number | string;
  title: string;
  steps?: IStep[];
}

export interface IStepperProps {
  steps: IStep[];
  activeStep?: number;
  activeSubStep?: number;
  subStepsHidden?: boolean;
  color?: string;
  fontFamily?: string;
  onStepChange?: (activeStep: number, activeSubStep?: number) => void;
  lastVisitedStep?: IClientRequestLastStep;
}

const Stepper: React.FC<IStepperProps> = ({
  steps = [],
  activeStep = 1,
  activeSubStep = 1,
  lastVisitedStep,
}) => {
  const sliderRef = useRef<CarouselRef>(null);
  useEffect(() => {
    sliderRef.current?.goTo(activeStep - 1);
  }, [activeStep, activeSubStep]);

  const Arrow = (props: any) => {
    const { className, style, onClick, children, left } = props;
    return (
      <div
        className={clsx(className, styles.carouselArrow, {
          [styles.left]: left,
        })}
        style={style}
        onClick={onClick}
      >
        {children}
      </div>
    );
  };

  const carouselSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: activeStep - 1,
    className: styles.carouselContainer,
    nextArrow: (
      <Arrow>
        <ArrowIcon />
      </Arrow>
    ),
    prevArrow: (
      <Arrow left>
        <ArrowIcon />
      </Arrow>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          initialSlide: activeStep - 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          initialSlide: activeStep - 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          initialSlide: activeStep - 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          initialSlide: activeStep - 1,
          slidesToShow: 1,
        },
      },
    ],
  };
  const isStepDone = (index: number) => {
    if (lastVisitedStep) {
      return index + 1 < lastVisitedStep.step;
    }
    return index + 1 < activeStep;
  };
  return (
    <Carousel ref={sliderRef} arrows {...carouselSettings} lazyLoad="ondemand">
      {steps.map((step, index) => (
        <Step
          key={step.key}
          index={index}
          title={step.title}
          isActive={index + 1 === activeStep}
          isDone={isStepDone(index)}
          substeps={step.steps}
          currentSubstep={activeSubStep}
        />
      ))}
    </Carousel>
  );
};

export default Stepper;
