import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from 'antd';
import PreviewDocument from 'components/ui/PreviewDocument/PreviewDocument';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import { getFileLink } from 'store/client-request/client-request.actions';
import { MediaCategoryType } from 'store/client-request/client-request.types';
import { getOfferFiles } from 'store/offer/offer.actions';
import { currentOfferFilesSelector, currentOfferSelector } from 'store/offer/offer.selectors';

import styles from './InvoiceAcceptedHomeCheck.module.sass';

const InvoiceAcceptedHomeCheck = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const offer = useSelector(currentOfferSelector);
  const offerFiles = useSelector(currentOfferFilesSelector);

  useEffect(() => {
    if (offer && offer.id) {
      dispatch(getOfferFiles(offer.id));
    }
  }, [dispatch, offer]);

  const onDocumentClick = (fileName: string) => {
    dispatch(getFileLink(fileName));
  };

  const filesInvoices = useCallback(() => {
    const invoices = [];
    const homeCheckInvoice = offerFiles?.find(
      (file) => file.document.category === MediaCategoryType.OfferInvoiceHomeCheck,
    );
    if (homeCheckInvoice) {
      invoices.push(homeCheckInvoice);
    } else {
      const homeCheckInstallerInvoice = offerFiles?.find(
        (file) => file.document.category === MediaCategoryType.OfferInvoiceHomeCheckInstaller,
      );
      homeCheckInstallerInvoice && invoices.push(homeCheckInstallerInvoice);
    }
    return invoices;
  }, [offerFiles]);

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>
        {t('customerFlow:installation:invoiceAcceptedHomeCheck:headline')}
      </h4>
      <Typography.Paragraph className={styles.text}>
        {t('customerFlow:installation:invoiceAcceptedHomeCheck:description')}
      </Typography.Paragraph>
      <Typography.Paragraph className={styles.text}>
        {t('customerFlow:installation:invoiceAcceptedHomeCheck:description2')}
      </Typography.Paragraph>

      {filesInvoices()?.map((item: any) => (
        <div key={item.id} className={styles.file}>
          <div className={styles.fileCategory}>
            {t(`intermediate:myProjects:${item.document.category}`)}
          </div>
          <PreviewDocument
            originalName={item.document.originalName} // @ts-ignore
            size={item.document.size} // @ts-ignore
            type="pdf"
            onDownloadClick={() => onDocumentClick(item.document.name)}
          />
          <div className={styles.date}>
            {t('customerFlow:installation:installationDone:uploadedOn')}
            <DateFormat date={new Date(item.document.createdAt)} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default InvoiceAcceptedHomeCheck;
