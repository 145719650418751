import React from 'react';
import 'moment/locale/de';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from 'framework/constants';

/**
 * Usage:
 * Use this component to display date/time in the UI
 *
 * Parameter:
 * date: the date obj
 * format: Moment JS time formatting. Please use something like "L" or "LL" and void
 *         DD/MM/YYYY, because L will be localized automatically.
 *         @docs: https://momentjs.com/docs/#/parsing/string-format/
 *
 *  Todo: Detect localization and localize automatically.
 */

interface IDateFormatProps {
  date?: Date;
  format?: string;
}
const DateFormat: React.FC<IDateFormatProps> = ({ date, format = DEFAULT_DATE_FORMAT }) => {
  if (!date) return <div>n/a</div>;
  return moment(date).format(format);
};

export default DateFormat;
