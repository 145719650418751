/* eslint-disable react/display-name */
import React, { useCallback } from 'react';
import { Row, Col, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { useDispatch } from 'react-redux';
import DOWNLOAD_ICON from 'assets/icons/direct-download.svg';
import { FileTypesFactory } from 'components/ui/FileCard/FileCard';
import Icon, { IconSize } from 'components/ui/Icon/Icon';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import {
  FileType,
  IDocument,
  MediaCategoryType,
  OfferDocument,
} from 'store/client-request/client-request.types';
import { getFileLink } from 'store/client-request/client-request.actions';
import styles from './DocumentsTable.module.sass';

export interface ICreatePoolModalProps {
  documents: OfferDocument[];
  withCategories?: boolean;
}

const DocumentsTable: React.FC<ICreatePoolModalProps> = ({ documents, withCategories = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const emptyColumn = {
    children: '',
    props: {
      colSpan: 0,
    },
  };

  const TableColumns: ColumnsType<OfferDocument> = [
    {
      dataIndex: 'document',
      key: 'mine',
      width: 50,
      render: (document: IDocument, row: any) => {
        if (document) {
          const fileTypeIcon = FileTypesFactory[document.mime!] || FileTypesFactory[FileType.PDF];
          return (
            <>
              <div className={styles.document}>
                <Icon icon={fileTypeIcon} className={styles.icon} size={IconSize.MEDIUM} />
              </div>
              <div className={styles.download}>
                <Icon icon={DOWNLOAD_ICON} className={styles.icon} size={IconSize.MEDIUM} />
              </div>
            </>
          );
        } else {
          return {
            children: <p>{row.title}</p>,
            props: {
              colSpan: 5,
              className: styles.tableTitle,
            },
          };
        }
      },
    },
    {
      title: t('intermediate:myProjects:offer:document:name'),
      dataIndex: 'document',
      key: 'name',
      render: (document: IDocument) => {
        if (document) {
          return document.originalName;
        } else {
          return emptyColumn;
        }
      },
    },
    {
      title: t('intermediate:myProjects:offer:document:category'),
      dataIndex: 'document',
      key: 'category',
      render: (document: IDocument) => {
        if (document) {
          return t(`intermediate:myProjects:${document.category}`);
        } else {
          return emptyColumn;
        }
      },
    },
    {
      title: t('intermediate:myProjects:offer:document:size'),
      dataIndex: 'document',
      key: 'size',
      render: (document: IDocument) => {
        if (document) {
          const fileSize =
            document.size && Number.isFinite(document.size)
              ? `${(document.size / 1024).toFixed(1)}kb`
              : '';
          return fileSize;
        } else {
          return emptyColumn;
        }
      },
    },
    {
      title: t('intermediate:myProjects:offer:document:date'),
      dataIndex: 'document',
      key: 'date',
      render: (document: IDocument) => {
        if (document) {
          return document.createdAt ? <DateFormat date={new Date(document.createdAt)} /> : 'n/a';
        } else {
          return emptyColumn;
        }
      },
    },
  ];

  const onDocumentClick = useCallback(
    (fileName: string) => {
      dispatch(getFileLink(fileName));
    },
    [dispatch],
  );

  const sortDocumentsByDate = (documents: OfferDocument[]) =>
    documents.sort(
      (a, b) => new Date(b.document.createdAt).getTime() - new Date(a.document.createdAt).getTime(),
    );

  const sortedDocuments = (documents: OfferDocument[]) => {
    const homeCheckDocuments = documents
      ? documents.filter((item) =>
          [
            MediaCategoryType.OfferHomeCheck,
            MediaCategoryType.OfferHomeCheckInstaller,
            MediaCategoryType.OfferInvoiceHomeCheck,
            MediaCategoryType.OfferInvoiceHomeCheckInstaller,
          ].includes(item.document.category),
        )
      : [];
    const installationDocuments = documents
      ? documents.filter((item) =>
          [
            MediaCategoryType.InstallationOrderConfirmation,
            MediaCategoryType.OfferInvoiceInstallation,
            MediaCategoryType.OfferInvoiceInstallationInstaller,
            MediaCategoryType.OfferBidInstaller,
          ].includes(item.document?.category),
        )
      : [];
    const otherDocuments = documents
      ? documents.filter(
          ({ document: { category } }) =>
            !homeCheckDocuments.find((item) => item.document.category === category) &&
            !installationDocuments.find((item) => item.document.category === category),
        )
      : [];

    let sortedDocuments: any[] = [];
    if (homeCheckDocuments.length) {
      sortedDocuments = [
        { title: t('intermediate:myProjects:offer:document:homeCheck'), id: '1' },
        ...sortDocumentsByDate(homeCheckDocuments),
      ];
    }
    if (installationDocuments.length) {
      sortedDocuments = [
        ...sortedDocuments,
        { title: t('intermediate:myProjects:offer:document:installation'), id: '2' },
        ...sortDocumentsByDate(installationDocuments),
      ];
    }
    if (otherDocuments.length) {
      sortedDocuments = [
        ...sortedDocuments,
        { title: t('intermediate:myProjects:offer:document:other'), id: '3' },
        ...sortDocumentsByDate(otherDocuments),
      ];
    }
    return sortedDocuments;
  };

  return (
    <Row className={styles.documents}>
      <Col span={24}>
        <Table
          rowClassName={styles.documentRow}
          columns={TableColumns}
          dataSource={withCategories ? sortedDocuments(documents) : documents}
          rowKey="id"
          onRow={(record) => ({
            onClick: record.document
              ? () => {
                  onDocumentClick(record.document.name);
                }
              : undefined,
          })}
          pagination={false}
          size="middle"
        />
      </Col>
    </Row>
  );
};

export default DocumentsTable;
