import React from 'react';
import { useTranslation } from 'react-i18next';
import { IOffer } from 'store/offer/offer.types';
import { formatCurrency } from 'utils/currencyUtils';
import styles from './OfferAdditionalInfoCard.module.sass';

interface IInstallerCardProps {
  offer?: IOffer;
}

const OfferAdditionalInfoCard: React.FC<IInstallerCardProps> = ({ offer }) => {
  const { t } = useTranslation();

  if (!offer) {
    return <div>Loading</div>;
  }

  const annotation = offer.poolOwnerAnnotation;

  const checkIfAdditionalInfo = () => {
    return !!(
      annotation ||
      offer.costEstimateAdditional ||
      offer.additionalConditions ||
      offer.additionalConditionsDescription
    );
  };

  const renderConditions = () => {
    const conditions = offer?.additionalConditions
      ? offer?.additionalConditions.map((item) => {
          return t(`installerFlow:tenderParticipate:withReservation:${item}Label`);
        })
      : [];

    return conditions.join(', ');
  };

  if (!checkIfAdditionalInfo()) {
    return null;
  }

  return (
    <div className={styles.card}>
      <h4 className={styles.title}>
        {t('installerFlow:tenderParticipate:withReservation:additionalInfoFromInstaller')}
      </h4>

      {offer.additionalConditions && (
        <>
          <p className={styles.label}>
            {t('installerFlow:tenderParticipate:withReservation:additionalConditions')}
          </p>
          <p className={styles.text}>{renderConditions()}</p>
        </>
      )}
      {offer.additionalConditionsDescription && (
        <>
          <p className={styles.label}>
            {t('installerFlow:tenderParticipate:withReservation:additionalDescription')}
          </p>
          <p className={styles.text}>{offer.additionalConditionsDescription}</p>
        </>
      )}
      {offer.costEstimateAdditional && (
        <>
          <p className={styles.label}>
            {t('installerFlow:tenderParticipate:withReservation:additionalCostEstimate')}
          </p>
          <p className={styles.text}>{formatCurrency(offer.costEstimateAdditional)}</p>
        </>
      )}
      {annotation ? (
        <>
          <p className={styles.label}>
            {t('installerFlow:tenderParticipate:withReservation:commentInstaller')}{' '}
          </p>
          <p className={styles.text}>{annotation}</p>
        </>
      ) : null}
    </div>
  );
};

export default OfferAdditionalInfoCard;
