import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import styles from './FeedbackForm.module.sass';
import Rating from '../Rating/Rating';

export interface FormValues {
  rate: number;
  comment: string;
}

export interface IFeedbackFormProps {
  className?: string;
  description?: string;
  onChange?: (value: FormValues) => void;
}

const FeedbackForm: React.FC<IFeedbackFormProps> = (props) => {
  const { className, onChange, description } = props;
  const { t } = useTranslation(['feedback']);
  const [rate, setRate] = useState<number>(0);
  const [comment, setComment] = useState<string>('');

  useEffect(() => {
    if (onChange) {
      onChange({ rate, comment });
    }
  }, [rate, comment, onChange]);

  return (
    <div className={clsx([styles.container, className])}>
      <div className={styles.description}>{description}</div>
      <Rating value={rate} onChange={setRate} className={styles.rating} />
      <Input.TextArea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder={t('feedback:additionalComments')}
      />
    </div>
  );
};

FeedbackForm.defaultProps = {
  className: '',
};

export default FeedbackForm;
