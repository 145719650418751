import { produce } from 'immer';
import { RequestState } from 'store/common.types';
import {
  ISurveysState,
  SURVEY_CREATE,
  SURVEY_CREATE_ERROR,
  SURVEY_CREATE_SUCCESS,
  SURVEY_GET,
  SURVEY_GET_ERROR,
  SURVEY_GET_SUCCESS,
  SURVEYS_GET,
  SURVEYS_GET_ERROR,
  SURVEYS_GET_SUCCESS,
  SurveysActionTypes,
} from './surveys.types';

const initialState: ISurveysState = {
  surveys: {
    items: [],
    total: 0,
  },

  survey: {},

  surveyState: null,
  createError: null,
  createState: null,
};

const SurveysReducer = (state = initialState, action: SurveysActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SURVEYS_GET:
        draft.surveyState = RequestState.Loading;
        break;

      case SURVEYS_GET_SUCCESS:
        draft.surveys = action.payload;
        draft.surveyState = RequestState.Success;
        draft.createError = null;
        break;

      case SURVEYS_GET_ERROR:
        draft.surveyState = RequestState.Error;
        draft.createError = action.payload;
        break;

      case SURVEY_GET:
        draft.surveyState = RequestState.Loading;
        break;

      case SURVEY_GET_SUCCESS:
        draft.survey = action.payload;
        draft.surveyState = RequestState.Success;
        draft.createError = null;
        break;

      case SURVEY_GET_ERROR:
        draft.surveyState = RequestState.Error;
        draft.createError = action.payload;
        break;

      case SURVEY_CREATE:
        draft.createState = RequestState.Loading;
        break;

      case SURVEY_CREATE_SUCCESS:
        draft.createState = RequestState.Success;
        draft.createError = null;
        break;

      case SURVEY_CREATE_ERROR:
        draft.createState = RequestState.Error;
        draft.createError = action.payload;
        break;
    }
  });

export default SurveysReducer;
