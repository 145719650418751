import { produce } from 'immer';
import { RequestState } from 'store/common.types';
import { mergeArraysWithoutDuplicates } from 'utils/commonUtils';
import {
  ANSWERS_GET,
  ANSWERS_GET_ERROR,
  ANSWERS_GET_SUCCESS,
  ANSWERS_SAVE,
  ANSWERS_SAVE_ERROR,
  ANSWERS_SAVE_SUCCESS,
  ANSWERS_SET,
  CLIENT_REQUEST_CREATE,
  CLIENT_REQUEST_CREATE_ERROR,
  CLIENT_REQUEST_CREATE_SUCCESS,
  CLIENT_REQUEST_DISTANCE_GET_ERROR,
  CLIENT_REQUEST_DISTANCE_GET_SUCCESS,
  CLIENT_REQUEST_GET,
  CLIENT_REQUEST_GET_ERROR,
  CLIENT_REQUEST_GET_SUCCESS,
  CLIENT_REQUESTS_ALL_GET,
  CLIENT_REQUESTS_ALL_GET_ERROR,
  CLIENT_REQUESTS_ALL_GET_SUCCESS,
  CLIENT_REQUESTS_COST_GET_ERROR,
  CLIENT_REQUESTS_COST_GET_SUCCESS,
  CLIENT_REQUESTS_FIND,
  CLIENT_REQUESTS_FIND_ERROR,
  CLIENT_REQUESTS_FIND_SUCCESS,
  ClientRequestActionTypes,
  DELETE_FILE_SUCCESS,
  GET_CLIENT_REQUEST_FILES_SUCCESS,
  GET_COMMENTS_SUCCESS,
  GET_WALLBOXES_SUCCESS,
  IClientRequestAnswer,
  IClientRequestState,
  IFile,
  MY_CLIENT_REQUESTS_GET,
  MY_CLIENT_REQUESTS_GET_ERROR,
  MY_CLIENT_REQUESTS_GET_SUCCESS,
  SINGLE_ANSWER_SAVE,
  SINGLE_ANSWER_SAVE_ERROR,
  SINGLE_ANSWER_SAVE_SUCCESS,
  SINGLE_ANSWER_SET,
  UPDATE_TRANSITION_STATE,
  UPDATE_TRANSITION_STATE_ERROR,
  UPDATE_TRANSITION_STATE_SUCCESS,
  GET_FILE_LINK_SUCCESS,
  GET_FILE_LINK,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_ERROR,
  GET_INVOICES,
  CLIENT_REQUEST_ARCHIVE,
  CLIENT_REQUEST_ARCHIVE_SUCCESS,
  CLIENT_REQUEST_ARCHIVE_ERROR,
  FOTO_WIZARD_UPLOAD_TOKEN_GET_SUCCESS,
  FOTO_WIZARD_UPLOAD_TOKEN_GET_ERROR,
  ASSIGN_BUNDLE_TO_REQUEST,
  ASSIGN_BUNDLE_TO_REQUEST_ERROR,
  ASSIGN_BUNDLE_TO_REQUEST_SUCCESS,
  CLIENT_REQUEST_UPDATE,
  CLIENT_REQUEST_UPDATE_SUCCESS,
  CLIENT_REQUEST_UPDATE_ERROR,
  GET_CLIENT_REQUEST_HOUSE_TYPE_SUCCESS,
  QuestionKey,
} from './client-request.types';

const initialState: IClientRequestState = {
  //Client Request
  clientRequestState: null,
  createError: null,
  updateError: null,
  clientRequest: null,

  clientRequestsState: null,
  clientRequests: {
    items: [],
    total: 0,
  },
  lastVisitedStep: {
    step: 1,
    subStep: 1,
  },

  myClientRequestsState: null,
  myClientRequests: [],
  myClientRequestsError: null,

  cost: {
    min: 0,
    max: 0,
  },

  distance: null,

  //Answers
  clientRequestAnswers: [],
  answersState: RequestState.None,
  answersError: null,
  // [TODO]: Refactor to remove error, too general. Replace with appropriate specific error vars
  error: null,

  // Transition
  transitionState: null,
  transitionError: null,
  houseType: null,
  files: {},

  wallboxes: [],

  questions: [],

  poolAvatar: '',
  poolAvatarState: null,

  customDocumentImage: {},

  invoices: null,
  invoicesState: RequestState.Loading,
  invoicesError: null,
  fotowizardUploadToken: null,

  assignBundleToRequestState: null,
  assignBundleToRequestError: null,
};

function updateAnswers(
  newAnswers: IClientRequestAnswer[] | IClientRequestAnswer,
  oldAnswers: IClientRequestAnswer[],
) {
  const newAnswersArray = Array.isArray(newAnswers) ? newAnswers : [newAnswers];

  return Object.values<IClientRequestAnswer>(
    [...oldAnswers, ...newAnswersArray].reduce(
      (result, obj) => {
        result[obj.questionKey] = obj;
        return result;
      },
      {} as Record<QuestionKey, IClientRequestAnswer>,
    ),
  );
}

const ClientRequestReducer = (state = initialState, action: ClientRequestActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_FILE_LINK:
        draft.poolAvatarState = RequestState.Loading;
        break;

      case GET_FILE_LINK_SUCCESS:
        draft.poolAvatarState = RequestState.Success;
        if (action.payload.fileLink.includes('pool')) {
          draft.poolAvatar = action.payload.fileLink;
        } else {
          draft.customDocumentImage[action.payload.fileName!] = action.payload.fileLink;
        }
        break;

      case GET_INVOICES:
        draft.invoicesState = RequestState.Loading;
        break;

      case GET_INVOICES_ERROR:
        draft.invoicesState = RequestState.Error;
        draft.invoicesError = action.payload;
        break;

      case GET_INVOICES_SUCCESS:
        draft.invoicesState = RequestState.Success;
        draft.invoicesError = null;
        draft.invoices = action.payload;
        break;

      case CLIENT_REQUEST_GET:
        draft.clientRequestState = RequestState.Loading;
        break;

      case CLIENT_REQUEST_GET_ERROR:
        draft.clientRequestState = RequestState.Error;
        draft.createError = action.payload;
        break;

      case CLIENT_REQUEST_GET_SUCCESS:
        draft.clientRequestState = RequestState.Success;
        draft.createError = null;
        draft.clientRequest = action.payload;
        draft.lastVisitedStep = {
          step: action.payload.step || 1,
          subStep: action.payload.subStep || 1,
        };
        break;

      case FOTO_WIZARD_UPLOAD_TOKEN_GET_SUCCESS:
        draft.fotowizardUploadToken = action.payload;
        break;

      case FOTO_WIZARD_UPLOAD_TOKEN_GET_ERROR:
        draft.error = action.payload;
        break;

      case CLIENT_REQUESTS_FIND:
        draft.clientRequestsState = RequestState.Loading;
        break;

      case CLIENT_REQUESTS_FIND_ERROR:
        draft.clientRequestsState = RequestState.Error;
        draft.error = action.payload;
        break;

      case CLIENT_REQUESTS_FIND_SUCCESS:
        draft.clientRequestsState = RequestState.Success;
        draft.error = null;
        draft.clientRequests = action.payload;
        break;

      case MY_CLIENT_REQUESTS_GET:
        draft.myClientRequestsState = RequestState.Loading;
        break;

      case MY_CLIENT_REQUESTS_GET_ERROR:
        draft.myClientRequestsState = RequestState.Error;
        draft.myClientRequestsError = action.payload;
        break;

      case MY_CLIENT_REQUESTS_GET_SUCCESS:
        draft.myClientRequestsState = RequestState.Success;
        draft.myClientRequestsError = null;
        draft.myClientRequests = action.payload;
        break;

      case CLIENT_REQUESTS_ALL_GET:
        draft.clientRequestState = RequestState.Loading;
        break;

      case CLIENT_REQUESTS_ALL_GET_ERROR:
        draft.clientRequestState = RequestState.Error;
        draft.createError = action.payload;
        draft.clientRequest = null;
        break;

      case CLIENT_REQUESTS_ALL_GET_SUCCESS:
        draft.clientRequestState = RequestState.Success;
        draft.createError = null;
        draft.clientRequest =
          state.clientRequest?.id === action.payload.id
            ? { ...state.clientRequest, ...action.payload }
            : action.payload;
        break;

      case CLIENT_REQUEST_ARCHIVE:
        draft.clientRequestState = RequestState.Saving;
        break;

      case CLIENT_REQUEST_ARCHIVE_SUCCESS:
        draft.clientRequestState = RequestState.Success;
        draft.createError = null;
        draft.myClientRequests = state.myClientRequests?.map((item) =>
          item.id === action.payload.id ? { ...item, archived: !item.archived } : item,
        );
        break;

      case GET_CLIENT_REQUEST_HOUSE_TYPE_SUCCESS:
        draft.houseType = action.payload;
        break;

      case CLIENT_REQUEST_ARCHIVE_ERROR:
        draft.clientRequestState = RequestState.Error;
        draft.createError = action.payload;
        draft.clientRequest = null;
        break;

      case CLIENT_REQUEST_CREATE:
        draft.clientRequestState = RequestState.Saving;
        break;

      case CLIENT_REQUEST_CREATE_ERROR:
        draft.clientRequestState = RequestState.Error;
        draft.createError = action.payload;
        break;

      case CLIENT_REQUEST_CREATE_SUCCESS:
        draft.clientRequestState = RequestState.Success;
        draft.createError = null;
        draft.clientRequest = action.payload;
        break;

      case CLIENT_REQUEST_UPDATE:
        draft.clientRequestState = RequestState.Saving;
        break;
      case CLIENT_REQUEST_UPDATE_SUCCESS:
        draft.clientRequest = { ...draft.clientRequest, ...action.payload };
        draft.clientRequestState = RequestState.Success;
        if (!action.payload.step || !action.payload.subStep) break;
        if (action.payload.step > state.lastVisitedStep.step) {
          draft.lastVisitedStep.step = action.payload.step;
          draft.lastVisitedStep.subStep = action.payload.subStep;
          break;
        }
        if (action.payload.subStep > state.lastVisitedStep.subStep) {
          draft.lastVisitedStep.subStep = action.payload.subStep;
        }
        break;
      case CLIENT_REQUEST_UPDATE_ERROR:
        draft.clientRequestState = RequestState.Error;
        draft.updateError = action.payload;
        break;
      case CLIENT_REQUESTS_COST_GET_SUCCESS:
        draft.cost = action.payload;
        draft.createError = null;
        break;

      case CLIENT_REQUESTS_COST_GET_ERROR:
        draft.createError = action.payload;
        break;

      case CLIENT_REQUEST_DISTANCE_GET_SUCCESS:
        draft.distance = action.payload;
        draft.createError = null;
        break;

      case CLIENT_REQUEST_DISTANCE_GET_ERROR:
        draft.createError = action.payload;
        break;

      case ANSWERS_GET:
        draft.answersError = null;
        draft.answersState = RequestState.Loading;
        break;

      case ANSWERS_GET_ERROR:
        draft.answersState = RequestState.Error;
        draft.answersError = action.payload;
        draft.error = action.payload;
        break;
      case ANSWERS_SET:
      case SINGLE_ANSWER_SET:
        draft.clientRequestAnswers = updateAnswers(action.payload, state.clientRequestAnswers);
        break;

      case ANSWERS_GET_SUCCESS:
      case ANSWERS_SAVE_SUCCESS:
      case SINGLE_ANSWER_SAVE_SUCCESS:
        draft.answersState = RequestState.Success;
        draft.answersError = null;
        draft.clientRequestAnswers = updateAnswers(action.payload, state.clientRequestAnswers);
        draft.clientRequest = {
          ...state.clientRequest,
          answers: mergeArraysWithoutDuplicates(
            state.clientRequest?.answers ?? [],
            action.payload,
            'questionKey',
          ),
        };
        break;

      case ANSWERS_SAVE:
        draft.answersState = RequestState.Saving;
        break;

      case ANSWERS_SAVE_ERROR:
        draft.answersState = RequestState.Error;
        draft.answersError = action.payload;
        draft.error = action.payload;
        break;

      case UPDATE_TRANSITION_STATE:
        draft.transitionState = RequestState.Loading;
        break;

      case UPDATE_TRANSITION_STATE_SUCCESS:
        draft.transitionState = RequestState.Success;
        draft.clientRequest = {
          ...state.clientRequest,
          ...action.payload,
          weekdays: action.payload.weekdays || state.clientRequest?.weekdays,
          costEstimateMin: action.payload.costEstimateMin || state.clientRequest?.costEstimateMin,
          costEstimateMax: action.payload.costEstimateMax || state.clientRequest?.costEstimateMax,
          startDate: action.payload.startDate || state.clientRequest?.startDate,
          soonestPossible: action.payload.soonestPossible || state.clientRequest?.soonestPossible,
          address: action.payload.address || state.clientRequest?.address,
          answers: action.payload.answers || state.clientRequest?.answers,
          billingAddress: action.payload.billingAddress || state.clientRequest?.billingAddress,
          pool: action.payload.pool || state.clientRequest?.pool,
          questionnaireSkipped:
            action.payload.questionnaireSkipped || state.clientRequest?.questionnaireSkipped,
        };
        break;

      case UPDATE_TRANSITION_STATE_ERROR:
        draft.transitionState = RequestState.Error;
        draft.transitionError = action.payload;
        break;

      case SINGLE_ANSWER_SAVE:
        draft.answersState = RequestState.Saving;
        break;

      case SINGLE_ANSWER_SAVE_ERROR:
        draft.answersState = RequestState.Error;
        draft.error = action.payload;
        break;

      case GET_CLIENT_REQUEST_FILES_SUCCESS: {
        const { category, files } = action.payload;

        if (category) {
          draft.files[category] = files[category];
        } else {
          draft.files = action.payload.files;
        }
        break;
      }

      case DELETE_FILE_SUCCESS: {
        const { category, fileId } = action.payload;
        const files = state.files[action.payload.category] || [];
        const updatedFiles = files.filter((file: IFile) => file.id !== fileId);

        draft.files[category] = updatedFiles;
        break;
      }

      case GET_WALLBOXES_SUCCESS:
        draft.wallboxes = action.payload;
        break;

      case GET_COMMENTS_SUCCESS: {
        draft.questions = action.payload;
        break;
      }
      case ASSIGN_BUNDLE_TO_REQUEST: {
        draft.assignBundleToRequestState = RequestState.Loading;
        break;
      }
      case ASSIGN_BUNDLE_TO_REQUEST_SUCCESS: {
        draft.assignBundleToRequestState = RequestState.Success;
        draft.assignBundleToRequestError = null;
        draft.clientRequest = { ...state.clientRequest, bundleId: action.payload };
        break;
      }
      case ASSIGN_BUNDLE_TO_REQUEST_ERROR: {
        draft.assignBundleToRequestState = RequestState.Error;
        draft.assignBundleToRequestError = action.payload;
        break;
      }
    }
  });

export default ClientRequestReducer;
