import React, { useCallback, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Loader from 'components/ui/Loader/Loader';
import useLanguageChangeCallback from 'hooks/useLaguageChangeCallback';
import EmailVerificationStep from 'pages/auth/EmailVerificationStep/EmailVerificationStep';
import {
  isUserFetchingSelector,
  isAuthorizedSelector,
  isInstallerSelector,
  isInstallerManagerSelector,
  isIntermediateManagerSelector,
  userSelector,
  isCustomerSelector,
} from 'store/auth/auth.selectors';
import { userEmailStatusSelector } from 'store/user/user.selectors';
import {
  clientRequestGet,
  clientRequestsAllGet,
} from 'store/client-request/client-request.actions';
import {
  currentClientRequestSelector,
  clientRequestStateSelector,
} from 'store/client-request/client-request.selectors';
import { getPoolLegalDocuments } from 'store/intermediate/intermediate.actions';
import { offerForClientRequestGet } from 'store/offer/offer.actions';
import { feedbackGet } from 'store/feedback/feedback.actions';
import { FeedbackReferenceType } from 'store/feedback/feedback.types';

const PrivateRoute = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isAuthorized: boolean = useSelector(isAuthorizedSelector);
  const request = useSelector(currentClientRequestSelector);
  const requestFetching = useSelector(clientRequestStateSelector);
  const isUserFetching: boolean = useSelector(isUserFetchingSelector);
  const isInstaller: boolean = useSelector(isInstallerSelector);
  const isCustomer: boolean = useSelector(isCustomerSelector);
  const isInstallerManager: boolean = useSelector(isInstallerManagerSelector);
  const isIntermediateManager: boolean = useSelector(isIntermediateManagerSelector);
  const isInstallerFlowRoles = isInstaller || isIntermediateManager || isInstallerManager;
  const isCustomerRoute = location.pathname.includes('/customer');
  const isInstallerRoute = location.pathname.includes('/installer');
  const isIntermediateRoute = location.pathname.includes('/my-projects');
  const user = useSelector(userSelector);
  const emailVerified = useSelector(userEmailStatusSelector);
  const isEmailNotVerified = !(emailVerified || user?.emailVerified);

  const fetchContents = useCallback(
    (refetch: boolean) => {
      if (!request && requestFetching === null && !isIntermediateManager && isCustomer)
        dispatch(clientRequestsAllGet());
      if (!isCustomer) return;

      if (request?.id && (!request.questionnaire || refetch))
        dispatch(clientRequestGet(request?.id, false));
      if (request?.id) {
        dispatch(getPoolLegalDocuments(request.poolId!, true));
        dispatch(offerForClientRequestGet(request.id));
        dispatch(feedbackGet(request.id, FeedbackReferenceType.ClientRequests));
      }
    },
    [isCustomer, dispatch, request, requestFetching, isIntermediateManager],
  );
  useEffect(() => {
    fetchContents(false);
  }, [fetchContents]);
  useLanguageChangeCallback(() => {
    fetchContents(true);
  });

  if (isUserFetching && !isAuthorized) {
    return (
      <div style={{ padding: '20px 0' }}>
        <Loader />
      </div>
    );
  }

  if (!isAuthorized && !isUserFetching) {
    return <Navigate to={'/login'} />;
  }

  if (isEmailNotVerified) {
    return <EmailVerificationStep email={user.email} />;
  }

  if (
    (isCustomerRoute && isInstallerFlowRoles) ||
    (isIntermediateRoute && !isIntermediateManager) ||
    (isInstallerRoute && !isInstallerFlowRoles)
  ) {
    return <Navigate to={'/home'} />;
  }
  return <Outlet />;
};

export default PrivateRoute;
