import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import attachmentIcon from 'assets/icons/attachment.svg';
import Icon, { IconSize } from 'components/ui/Icon/Icon';
import { FileTypesFactory } from 'components/ui/FileCard/FileCard';

import { clearCurrentFile, uploadGenericFile } from 'store/document/document.actions';
import { genericFileSelector } from 'store/document/document.selectors';
import { FileType, IDocument, MediaCategoryType } from 'store/client-request/client-request.types';
import { deleteFile } from 'store/client-request/client-request.actions';

import styles from './Chat.module.sass';

interface IProps {
  setUploadedFile: (file: IDocument | null) => void;
}

const ChatFileUpload: React.FC<IProps> = (props) => {
  const { setUploadedFile } = props;
  const dispatch = useDispatch();

  const [fileUploading, setFileUploading] = useState(false);

  const fileInput = useRef<HTMLInputElement>(null);
  const uploadedFile = useSelector(genericFileSelector);
  const allowedFileTypes = 'application/pdf, image/png, image/jpeg';

  useEffect(() => {
    dispatch(clearCurrentFile());
  }, [dispatch]);

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setFileUploading(true);
      dispatch(
        uploadGenericFile({
          document: file,
          category: MediaCategoryType.CommentAttachment,
          uploadSuccess: (document: any) => {
            setFileUploading(false);
            setUploadedFile(document);
          },
          uploadError: (error: any) => {
            setFileUploading(false);
            if (fileInput.current) {
              fileInput.current.value = '';
            }
          },
        }),
      );
    }
  };

  const onDeleteFile = () => {
    if (uploadedFile) {
      dispatch(deleteFile(uploadedFile.name, uploadedFile.id, MediaCategoryType.CommentAttachment));
      dispatch(clearCurrentFile());
      setUploadedFile(null);
    }
  };

  const renderButton = () => {
    if (fileUploading) {
      return <LoadingOutlined />;
    }
    if (uploadedFile) {
      return (
        <div className={styles.fileAction}>
          <Icon
            icon={FileTypesFactory[FileType.PDF]}
            className={styles.icon}
            size={IconSize.MEDIUM}
          />
          <DeleteOutlined onClick={onDeleteFile} />
        </div>
      );
    } else {
      return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <img src={attachmentIcon} alt="Attachment" onClick={() => fileInput?.current?.click()} />
      );
    }
  };

  return (
    <div className={styles.attachment}>
      <input
        type="file"
        style={{ display: 'none' }}
        accept={allowedFileTypes}
        ref={fileInput}
        onChange={onFileChange}
      />
      {renderButton()}
    </div>
  );
};

export default ChatFileUpload;
