import React from 'react';
import Form from 'antd/lib/form';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useTranslation } from 'react-i18next';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import DropDown from 'components/ui/DropDown/DropDown';
import Button from 'components/ui/Button/Button';
import NumericInput from 'components/ui/NumberInput/NumericInput';
import { ValidationFactory } from 'framework/validations';
import { QuestionKey } from 'store/client-request/client-request.types';

import styles from './CustomSurfaceQuestion.module.sass';

type InstallationAffectedSurfaceValue = {
  surfaceCode: string;
  distance: string;
};

interface IProps {
  data: InstallationAffectedSurfaceValue[];
  handleChange(path: string, value: any): void;
  path: string;
  visible: boolean;
  uischema: any;
  schema: any;
}

const EmptyValue = {
  surfaceCode: '',
  distance: '',
};

const CustomSurfaceQuestion: React.FC<IProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const uiOptions = props.uischema.options;
  const { question, description, surfaceFields } = uiOptions;
  const { options, buttonText, dropDownPlaceholder } = surfaceFields;

  const onSelectChange = (selectedSurfaceCode: string, index: number) => {
    if (data && data.length > index) {
      const row = {
        surfaceCode: selectedSurfaceCode,
        distance: data[index].distance || '',
      };
      const newData = Object.assign([], data, { [index]: row });
      props.handleChange && props.handleChange(props.path, newData);
    }
  };

  const onInputChange = (event: any, index: number) => {
    const inputText = event.target.value;

    if (data && data.length > index) {
      const row = {
        surfaceCode: data[index].surfaceCode || '',
        distance: inputText,
      };
      const newData = Object.assign([], data, { [index]: row });
      props.handleChange && props.handleChange(props.path, newData);
    }
  };

  //TODO: Add more values for surfaces
  const renderRow = (row: { surfaceCode: string; distance: string }, index: number) => {
    const dropDownOptions = options.map((option: any) => {
      return {
        value: option.value,
        label: t(option.label),
      };
    });

    const dropDownProps = {
      options: dropDownOptions,
      placeholder: t(dropDownPlaceholder),
      value: row.surfaceCode,
      onChange: (value: any) => onSelectChange(value, index),
    };

    const handleRemoveClick = () => removeRow(index);

    return (
      <div key={index} className={styles.answerRow}>
        <div className={styles.section}>
          <DropDown {...dropDownProps} />
        </div>
        <div className={styles.textSection}>
          {t(
            `customerFlow:questions:${QuestionKey.InstallationAffectedSurface}:texts:withLengthOf`,
          )}
        </div>
        <div className={styles.section}>
          <NumericInput
            className={styles.inputItem}
            label={t('common:texts:meters')}
            value={row.distance}
            onChange={(event) => {
              onInputChange(event, index);
            }}
          />
        </div>

        {index ? (
          <div className={styles.removeButton} onClick={handleRemoveClick}>
            <CloseCircleOutlined />
          </div>
        ) : null}
      </div>
    );
  };

  const addRow = () => {
    const newData = data && data.concat([EmptyValue]);
    props.handleChange && props.handleChange(props.path, newData);
  };

  const removeRow = (index: number) => {
    if (data && data.length > 1) {
      const newData = data.concat([]);
      newData.splice(index, 1);
      props.handleChange && props.handleChange(props.path, newData);
    }
  };

  return props.visible ? (
    <Form.Item
      className={styles.questionShort}
      rules={[ValidationFactory.EXCAVATION_SURFACE]}
      name={QuestionKey.InstallationAffectedSurface}
      initialValue={props.data}
    >
      <div className={styles.container} id={props.path}>
        <div className={styles.question}>{t(question)}</div>

        {description && <div className={styles.description}>{t(description)}</div>}

        {props.data?.map((row, index) => renderRow(row, index))}
        <Button type="link" onClick={() => addRow()}>
          {t(buttonText)}
        </Button>
      </div>
    </Form.Item>
  ) : (
    <></>
  );
};

CustomSurfaceQuestion.defaultProps = {
  data: [EmptyValue],
};

export default withJsonFormsControlProps(CustomSurfaceQuestion);
