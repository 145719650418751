import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import Loader from 'components/ui/Loader/Loader';
import ProjectsSidebar from 'components/ui/ProjectsSidebar/ProjectsSidebar';
import PoolThemeContext from 'context/poolTheme.context';
import ClientRequestAPI from 'services/client-request.service';
import { userSelector } from 'store/user/user.selectors';
import { userGet } from 'store/user/user.actions';
import { isCustomerSelector } from 'store/auth/auth.selectors';
import { myClientRequestGet } from 'store/client-request/client-request.actions';
import { myClientRequestsSelector } from 'store/client-request/client-request.selectors';
import ProfileInfoTab from './Tabs/ProfileInfoTab/ProfileInfoTab';
import RequestsTab from './RequestsTab/RequestsTab';
const Profile: React.FC = () => {
  const [selected, setSelected] = useState<string>('profile');
  const [archivedRequest, setArchivedRequest] = useState<any[]>([]);
  const [allRequest, setAllRequest] = useState<any[]>([]);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(userSelector);
  const clientRequests = useSelector(myClientRequestsSelector);
  const isCustomer = useSelector(isCustomerSelector);

  const [poolTheme] = useContext(PoolThemeContext);

  useEffect(() => {
    if (isCustomer) {
      dispatch(myClientRequestGet());
      ClientRequestAPI.getMyArchivedClientRequests().then((res) => setArchivedRequest(res.items));
    }
  }, [dispatch, isCustomer]);

  useEffect(() => {
    let archived = [...archivedRequest];
    let test = archived
      ? archived.map((val, i) => {
          val.archived = true;
          return val;
        })
      : [];
    let allRequest = [...clientRequests, ...test];
    setAllRequest(allRequest);
  }, [archivedRequest, clientRequests]);

  useEffect(() => {
    dispatch(userGet());
  }, [dispatch]);

  const refresh = () => {
    dispatch(userGet());
  };

  const profileSidebarProps = {
    color: poolTheme.color,
    selected,
    list: [
      {
        title: t('profile:profile'),
        value: 'profile',
        action: () => setSelected('profile'),
      },
      {
        title: t('profile:myRequests'),
        value: 'myRequests',
        action: () => setSelected('myRequests'),
      },
    ],
  };

  const renderContent = () =>
    user && user.firstName ? (
      <>
        {isCustomer && (
          <Col span={6}>
            <ProjectsSidebar {...profileSidebarProps} />
          </Col>
        )}
        <Col span={isCustomer ? 18 : 24}>
          {selected === 'profile' && (
            <ProfileInfoTab user={user} isCustomer={isCustomer} refresh={refresh} />
          )}
          {selected === 'myRequests' && <RequestsTab clientRequests={allRequest} />}
        </Col>
      </>
    ) : (
      <Loader />
    );

  return <Row style={{ paddingTop: '20px' }}>{renderContent()}</Row>;
};

export default Profile;
