import React from 'react';
import Typography from 'antd/lib/typography';
import styles from './OfferReviewStep.module.sass';

const { Paragraph } = Typography;

interface ISectionTextProps {
  title: string;
  text?: string;
  small?: boolean;
}

const SectionText: React.FC<ISectionTextProps> = ({ title, text = '', small = true }) => {
  const titleSize = small ? styles.titleSmall : styles.titleBig;

  return (
    <>
      <h4 className={titleSize}>{title}</h4>
      {text && <Paragraph className={styles.text}>{text}</Paragraph>}
    </>
  );
};

export default SectionText;
