import { ISearchDto } from 'store/common.types';
import { RequestResult } from 'types/crud-view.types';
import {
  IProductComponent,
  PRODUCT_COMPONENTS_GET,
  PRODUCT_COMPONENTS_GET_ERROR,
  PRODUCT_COMPONENTS_GET_SUCCESS,
  PRODUCT_COMPONENT_CREATE,
  PRODUCT_COMPONENT_CREATE_ERROR,
  PRODUCT_COMPONENT_CREATE_SUCCESS,
  PRODUCT_COMPONENT_DELETE,
  PRODUCT_COMPONENT_DELETE_ERROR,
  PRODUCT_COMPONENT_DELETE_SUCCESS,
  PRODUCT_COMPONENT_UPDATE,
  PRODUCT_COMPONENT_UPDATE_ERROR,
  PRODUCT_COMPONENT_UPDATE_SUCCESS,
  PRODUCT_WIZARD_ACTIVE_VALIDATE,
  PRODUCT_WIZARD_ACTIVE_VALIDATE_ERROR,
  PRODUCT_WIZARD_ACTIVE_VALIDATE_SUCCESS,
  PRODUCT_WIZARD_GET,
  PRODUCT_WIZARD_GET_ERROR,
  PRODUCT_WIZARD_GET_SUCCESS,
} from './product-components.types';

export const productComponentCreate = (
  product: IProductComponent,
  poolId: string,
  onRequestDone?: (status: RequestResult) => void,
) => ({
  type: PRODUCT_COMPONENT_CREATE,
  payload: {
    product,
    poolId,
    onRequestDone,
  },
});

export const productComponentCreateSuccess = (product: IProductComponent) => ({
  type: PRODUCT_COMPONENT_CREATE_SUCCESS,
  payload: product,
});

export const productComponentCreateError = (error: any) => ({
  type: PRODUCT_COMPONENT_CREATE_ERROR,
  payload: error,
});

export const productComponentUpdate = (
  id: string,
  body: IProductComponent,
  onRequestDone?: (status: RequestResult) => void,
) => ({
  type: PRODUCT_COMPONENT_UPDATE,
  payload: {
    id,
    body,
    onRequestDone,
  },
});

export const productComponentUpdateSuccess = (product: IProductComponent) => ({
  type: PRODUCT_COMPONENT_UPDATE_SUCCESS,
  payload: product,
});

export const productComponentUpdateError = (error: any) => ({
  type: PRODUCT_COMPONENT_UPDATE_ERROR,
  payload: error,
});

export const productComponentDelete = (
  id: string,
  onRequestDone?: (status: RequestResult) => void,
) => ({
  type: PRODUCT_COMPONENT_DELETE,
  payload: {
    id,
    onRequestDone,
  },
});

export const productComponentDeleteSuccess = (id: string) => ({
  type: PRODUCT_COMPONENT_DELETE_SUCCESS,
  payload: id,
});

export const productComponentDeleteError = (error: any) => ({
  type: PRODUCT_COMPONENT_DELETE_ERROR,
  payload: error,
});

export const productComponentsGet = (poolId: string, search?: ISearchDto) => ({
  type: PRODUCT_COMPONENTS_GET,
  payload: {
    poolId,
    search,
  },
});

export const productComponentsGetSuccess = (productComponents: {
  items: IProductComponent[];
  total: number;
}) => ({
  type: PRODUCT_COMPONENTS_GET_SUCCESS,
  payload: productComponents,
});

export const productComponentsGetError = (error: any) => ({
  type: PRODUCT_COMPONENTS_GET_ERROR,
  payload: error,
});

export const productWizardGet = (poolId: string) => ({
  type: PRODUCT_WIZARD_GET,
  payload: poolId,
});

export const productWizardGetSuccess = (products: IProductComponent[]) => ({
  type: PRODUCT_WIZARD_GET_SUCCESS,
  payload: products,
});

export const productWizardGetError = (error: any) => ({
  type: PRODUCT_WIZARD_GET_ERROR,
  payload: error,
});

export const validateActivePosibility = (componentId?: string) => ({
  type: PRODUCT_WIZARD_ACTIVE_VALIDATE,
  payload: componentId,
});

export const validateActivePosibilitySuccess = (isActive: boolean) => ({
  type: PRODUCT_WIZARD_ACTIVE_VALIDATE_SUCCESS,
  payload: isActive,
});

export const validateActivePosibilityError = (error: any) => ({
  type: PRODUCT_WIZARD_ACTIVE_VALIDATE_ERROR,
  payload: error,
});
