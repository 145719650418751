import React, { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Typography from 'antd/lib/typography';
import { useTranslation } from 'react-i18next';
import NextButton from 'components/ui/NextButton/NextButton';
import FeedbackForm, { FormValues } from 'components/ui/FeedbackForm/FeedbackForm';
import { feedbackCreate, feedbackGet } from 'store/feedback/feedback.actions';
import { FeedbackReceiverType, FeedbackReferenceType } from 'store/feedback/feedback.types';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import { feedbackListSelector, feedbackStateSelector } from 'store/feedback/feedback.selectors';
import { RequestState } from 'store/common.types';
import styles from './OrderDetailsPage.module.sass';

const SectionFeedback: React.FC = () => {
  const clientRequest = useSelector(currentClientRequestSelector);
  const [feedback, setFeedback] = useState<FormValues>({ rate: 0, comment: '' });
  const loadingFeedback = useSelector(feedbackStateSelector);
  const currentFeedback = useSelector(feedbackListSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation(['feedback']);

  useEffect(() => {
    if (clientRequest && clientRequest.id) {
      dispatch(feedbackGet(clientRequest.id, FeedbackReferenceType.ClientRequests));
    }
  }, [dispatch, clientRequest]);

  const onGiveFeedbackClick = () => {
    if (clientRequest && clientRequest.id) {
      dispatch(
        feedbackCreate([
          {
            referenceType: FeedbackReferenceType.ClientRequests,
            referenceId: clientRequest.id,
            receiverType: FeedbackReceiverType.Platform,
            rating: feedback.rate,
            text: feedback.comment,
          },
        ]),
      );
    }
  };

  const feedbackFormProps = {
    description: t('feedback:tendergyDescription'),
    onChange: setFeedback,
  };

  if (loadingFeedback === null && loadingFeedback === RequestState.Loading) {
    return (
      <Skeleton
        active={true}
        paragraph={{
          rows: 3,
        }}
        round={true}
      />
    );
  }

  if (!currentFeedback.length) {
    return (
      <>
        <FeedbackForm {...feedbackFormProps} />
        <NextButton
          onClick={onGiveFeedbackClick}
          disabled={!feedback.rate}
          className={styles.feedbackButton}
        >
          {t('feedback:giveFeedback')}
        </NextButton>
      </>
    );
  }

  return (
    <Typography.Paragraph className={styles.text}>
      {t('feedback:feedbackExist')}
    </Typography.Paragraph>
  );
};

export default SectionFeedback;
