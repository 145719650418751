import { ThemeConfig } from 'antd/lib';

export const theme: ThemeConfig = {
  token: {
    colorPrimary: '#131630',
    colorPrimaryHover: '#282C42',
    colorPrimaryBorderHover: '#5F6380',
    colorPrimaryBorder: '#5B667C',
    colorPrimaryBgHover: '#A1A7BB',
    colorPrimaryBg: '#FFF',
    fontFamily: 'Nunito Sans',
  },
  components: {
    Button: {
      controlHeight: 48,
      controlHeightSM: 36,
      borderRadius: 8,
      fontWeightStrong: 600,
    },
    Select: {
      controlHeight: 48,
    },
  },
};
