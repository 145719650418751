import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Avatar, Button, Table } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Tools from 'assets/icons/tools.svg';
import Home from 'assets/icons/home.svg';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import useIsMobile from 'hooks/useIsModible';
import { IOffer, OfferState } from 'store/offer/offer.types';
import {
  currentClientRequestSelector,
  isQuestionsSelector,
} from 'store/client-request/client-request.selectors';

import { formatCurrency } from 'utils/currencyUtils';
import { checkIsPayByIntermediate } from 'utils/offerHelpers';
import styles from './OffersListStep.module.sass';
import QuestionsList from './QuestionsList';
import OfferCard from './OfferCard/OfferCard';
const InstallationTypeConfig: { [key: string]: { icon: any; text: string } } = {
  tenderAcceptedHomeCheck: {
    icon: Home,
    text: 'Home-Check',
  },
  tenderAcceptedRemoteHomeCheck: {
    icon: Home,
    text: 'Remote Home-Check',
  },
  tenderAccepted: {
    icon: Tools,
    text: 'Installation',
  },
};

interface Props {
  list: IOffer[];
  onOfferClick: (index: number) => void;
  showQuestions?: boolean;
}

const OffersListStep: React.FC<Props> = ({ list, onOfferClick, showQuestions = true }) => {
  const { t } = useTranslation();
  const questionsList = useSelector(isQuestionsSelector);
  const clientRequest = useSelector(currentClientRequestSelector);
  const orgLogo = clientRequest?.pool?.intermediateOrganization?.logo;

  const isMobile = useIsMobile();
  const renderOfferPrice = (offer: IOffer) => {
    if (!checkIsPayByIntermediate(offer, clientRequest)) {
      const price =
        offer.state === OfferState.TenderAcceptedHomeCheck ||
        offer.state === OfferState.TenderAcceptedRemoteHomeCheck
          ? offer.costEstimateHomeCheck
          : offer.costEstimate;
      const vatAmount = ((offer?.vatRate || 0) / 100) * price!;
      return (
        <div className={styles.priceRange}>
          <div className={styles.price}>
            {formatCurrency(+price! + vatAmount, offer.currencySymbol)}
          </div>
          <div>
            <span>{t('common:net')}:</span> {formatCurrency(price, offer.currencySymbol)}
          </div>
          <div>
            <span>
              {t('common:vat')}:({offer?.vatRate || 0}%):
            </span>{' '}
            {formatCurrency(vatAmount, offer.currencySymbol)}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderType = (state: string) => {
    return (
      <div className={styles.type}>
        {orgLogo ? (
          <img
            className={styles.organizationLogo}
            src={orgLogo}
            alt="logo"
            width="70"
            height="70"
          />
        ) : (
          <Avatar
            shape="square"
            className={styles.organizationLogo}
            size={64}
            icon={<UserOutlined />}
          />
        )}
        <span>{InstallationTypeConfig[state]?.text}</span>
      </div>
    );
  };

  const renderAction = (offer: IOffer, record: IOffer, index: any) => {
    const buttonProps = {
      disabled: false,
      onClick: () => onOfferClick(index),
    };
    return (
      <Button block type="primary" size="middle" className={styles.buttonAction} {...buttonProps}>
        {t('customerFlow:offerReview:offerListPage:goToOffer')}
      </Button>
    );
  };

  const columns = [
    {
      title: t('customerFlow:offerReview:offerListPage:table:state'),
      dataIndex: 'state',
      key: 'state',
      width: '26%',
      render: renderType,
    },
    {
      title: t('customerFlow:offerReview:offerListPage:table:createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '16%',
      render: (value: Date) => <DateFormat date={value} />,
    },
    {
      title: t('customerFlow:offerReview:offerListPage:table:offerValidityDate'),
      dataIndex: 'offerValidityDate',
      key: 'offerValidityDate',
      width: '16%',
      render: (value: Date) => <DateFormat date={value} />,
    },
    {
      title: t('customerFlow:offerReview:offerListPage:table:price'),
      dataIndex: '',
      key: 'price',
      width: '18%',
      render: renderOfferPrice,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '30%',
      render: renderAction,
    },
  ];

  const renderCards = () =>
    list.map((offer, index) => {
      return (
        <OfferCard
          offer={offer}
          key={offer.id}
          coverImage={orgLogo}
          type={InstallationTypeConfig[offer.state!]?.text}
          price={renderOfferPrice(offer)}
          onClick={() => onOfferClick(index)}
        />
      );
    });
  return (
    <div className={styles.content}>
      {isMobile ? renderCards() : <Table dataSource={list} columns={columns} pagination={false} />}
      {questionsList && showQuestions ? <QuestionsList questions={questionsList} /> : null}
    </div>
  );
};

export default OffersListStep;
