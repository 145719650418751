import React from 'react';
import clsx from 'clsx';
import { Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { SupportedLanguages } from 'framework/constants';
import styles from './OfferDocumentGenerator.module.scss';

interface IActionButtonsProps {
  isOfferPublishable: boolean;
  downloadPDFClickHandler: () => void;
  cancelButtonClickHandler: () => void;
  sendOfferClickHandler: () => void;
  saveFormData: () => void;
  isOfferSubmitting: boolean;
  isFormDataSaving: boolean;
}
const ActionButtons = ({
  cancelButtonClickHandler,
  sendOfferClickHandler,
  downloadPDFClickHandler,
  isOfferPublishable,
  isOfferSubmitting,
  isFormDataSaving,
  saveFormData,
}: IActionButtonsProps) => {
  const { t, i18n } = useTranslation();
  const locizeKey = 'intermediate:pdfGen:buttons';

  return (
    <Flex
      justify={'space-between'}
      className={clsx(styles.actionButtons, {
        [styles.smallText]: i18n.language !== SupportedLanguages.EN,
      })}
    >
      <Flex>
        <Button className={styles.actionButton} onClick={cancelButtonClickHandler}>
          {t(`${locizeKey}:cancel`)}
        </Button>
      </Flex>
      <Flex gap={4}>
        <Button className={styles.actionButton} loading={isFormDataSaving} onClick={saveFormData}>
          {t(`${locizeKey}:save`)}
        </Button>

        <Button className={styles.actionButton} onClick={downloadPDFClickHandler}>
          {t(`${locizeKey}:download`)}
        </Button>
        <Button
          className={styles.actionButton}
          onClick={sendOfferClickHandler}
          loading={isOfferSubmitting}
          type={'primary'}
          disabled={!isOfferPublishable}
        >
          {t(`${locizeKey}:send`)}
        </Button>
      </Flex>
    </Flex>
  );
};
export default ActionButtons;
