import React, { FC, JSX } from 'react';
import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import { IOffer } from 'store/offer/offer.types';
import styles from './OfferCard.module.sass';

type OfferCardProps = {
  coverImage?: string;
  type?: string;
  offer: IOffer;
  price: JSX.Element | null;
  onClick?: () => void;
};

const OfferCard: FC<OfferCardProps> = ({ coverImage, type, offer, price, onClick }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.cardContainer}>
      <div className={styles.title}>
        {coverImage && <img src={coverImage} alt="org logo" />}
        <div>
          <Typography.Title level={4}>{type}</Typography.Title>
          <Typography.Title level={5} className={styles.priceTitle}>
            {t('customerFlow:offerReview:offerListPage:table:price')}
          </Typography.Title>
          {price}
        </div>
      </div>
      <div className={styles.dateLabel}>
        {t('customerFlow:offerReview:offerListPage:table:createdAt')}
        <Typography.Title level={5}>
          <DateFormat date={offer.createdAt} />
        </Typography.Title>
      </div>
      <div className={styles.dateLabel}>
        {t('customerFlow:offerReview:offerListPage:table:offerValidityDate')}
        <Typography.Title level={5}>
          <DateFormat date={offer.offerValidityDate} />
        </Typography.Title>
      </div>
      <Button type="primary" block style={{ marginTop: '16px' }} onClick={onClick}>
        {t('customerFlow:offerReview:offerListPage:goToOffer')}
      </Button>
    </div>
  );
};

export default OfferCard;
