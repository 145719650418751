import React, { useEffect, useState } from 'react';

import { Select, Tooltip, Modal, Button } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

import { listAudioInputs, listVideoInputs } from './helpers';

export default function SettingSection(props: any) {
  const [audioDevices, setAudioDevices] = useState<any>([]);
  const [videoDevices, setVideoDevices] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { OT, publisher } = props;
  const { Option } = Select;
  useEffect(() => {
    navigator.mediaDevices.ondevicechange = async () => {
      setDevices();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isModalVisible) setDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  const setDevices = async () => {
    const audioDevices = await listAudioInputs(OT);
    const videoDevices = await listVideoInputs(OT);
    setAudioDevices(audioDevices);
    setVideoDevices(videoDevices);
  };

  const onVideoSourceChanged = async (deviceId: any) => {
    if (deviceId && publisher) {
      publisher.setVideoSource(deviceId);
    }
  };

  const onAudioSourceChanged = async (deviceId: any) => {
    if (deviceId && publisher) {
      publisher.setAudioSource(deviceId);
    }
  };

  return (
    <>
      <Tooltip title="Setting">
        <Button
          shape="circle"
          style={{ backgroundColor: 'inherit' }}
          icon={<MoreOutlined style={{ color: 'white' }} />}
          size={'small'}
          onClick={() => setIsModalVisible(true)}
        />
      </Tooltip>

      <Modal
        title="Setting the input devices"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <div>
          {audioDevices.length > 0 && (
            <Select
              onChange={(value) => onAudioSourceChanged(value)}
              style={{ width: '70%', margin: '5px' }}
              defaultValue={
                publisher?.getAudioSource()
                  ? publisher.getAudioSource().label
                  : audioDevices[0].label
              }
            >
              {audioDevices.map((device: any) => (
                <Option value={device.deviceId} key={device.label}>
                  {device.label}
                </Option>
              ))}
            </Select>
          )}
          {videoDevices.length > 0 && (
            <Select
              onChange={(value) => onVideoSourceChanged(value)}
              style={{ width: '70%', margin: '5px' }}
              defaultValue={
                publisher?.getVideoSource()
                  ? publisher.getVideoSource().deviceId
                  : videoDevices[0].deviceId
              }
            >
              {videoDevices.map((device: any) => (
                <Option value={device.deviceId} key={device.label}>
                  {device.label}
                </Option>
              ))}
            </Select>
          )}
        </div>
      </Modal>
    </>
  );
}
