/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import { StarOutlined, StarFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Rate } from 'antd';
import styles from './Rating.module.sass';

export interface IRatingProps {
  className?: string;
  value?: number;
  onChange?: (value: number) => void;
}

const Rating: React.FC<IRatingProps> = (props) => {
  const { className, value, onChange } = props;
  const { t } = useTranslation(['feedback']);
  const rateProps = {
    character: ({ value, index }: any) => (value < index + 1 ? <StarOutlined /> : <StarFilled />),
    allowClear: false,
    className: styles.rate,
    value,
    onChange,
  };

  return (
    <div className={clsx([styles.container, className])}>
      <div className={styles.title}>{t('feedback:notSatisfied')}</div>
      <div className={styles.rate}>
        <Rate {...rateProps} />
      </div>
      <div className={styles.title}>{t('feedback:verySatisfied')}</div>
    </div>
  );
};

Rating.defaultProps = {
  className: '',
};

export default Rating;
