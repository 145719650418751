import { createSelector } from 'reselect';
import { IPDFTemplateState } from './pdf-templates.types';

export const pdfTemplateSelector = (state: any) => state.pdfTemplate;

export const pdfTemplateCreateStateSelector = createSelector(
  pdfTemplateSelector,
  (pdfTemplate: IPDFTemplateState) => pdfTemplate.PDFTemplateCreateState,
);

export const pdfTemplateGetStateSelector = createSelector(
  pdfTemplateSelector,
  (pdfTemplate: IPDFTemplateState) => pdfTemplate.PDFTemplatesState,
);

export const currentPDFTemplatesSelector = createSelector(
  pdfTemplateSelector,
  (pdfTemplate: IPDFTemplateState) => pdfTemplate.currentPDFTemplates,
);

export const pdfTemplateUpdateStateSelector = createSelector(
  pdfTemplateSelector,
  (pdfTemplate: IPDFTemplateState) => pdfTemplate.PDFTemplateUpdateState,
);

export const pdfTemplateDeleteStateSelector = createSelector(
  pdfTemplateSelector,
  (pdfTemplate: IPDFTemplateState) => pdfTemplate.PDFTemplateDeleteState,
);
