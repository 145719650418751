import { useMemo, useState } from 'react';
import _ from 'lodash';
import { ISearchDto } from 'store/common.types';

type FilterTuple = [
  ISearchDto,
  (key: string, value: string | boolean | number) => void,
  (sortField: string, sortDirection: 1 | -1) => void,
];

export const useFilters = (defaultValue: ISearchDto): FilterTuple => {
  const [meta, setMeta] = useState<ISearchDto>(defaultValue);
  const [filters, setFilters] = useState({});

  const setSearch = _.debounce((value: string) => {
    setFilters({ ...filters, search: value });
  }, 500);

  const onFilterChange = (key: string, value: string | boolean | number) => {
    if (key === 'search') {
      setSearch(value as string);
    } else {
      setFilters({ ...filters, [key]: value });
    }
  };

  const onSortChange = (sortField: string, sortDirection: 1 | -1) => {
    setMeta({ ...meta, sortDirection, sortField });
  };

  const result = useMemo(() => ({ ...meta, filters }), [meta, filters]);

  return [result, onFilterChange, onSortChange];
};
