import React from 'react';
import { DayPicker } from 'react-day-picker';
import moment from 'moment';
import { TimePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { SupportedDateFnsLocales, SupportedLanguages } from 'framework/constants';
import styles from './OneValueDateTimePicker.module.sass';
import { modifiersStyles } from '../DateTimePicker/config';

interface Props {
  time: string;
  date: Date;
  onTimeClick: Function;
  onDayClick: Function;
}

const OneValueDateTimePicker: React.FC<Props> = ({ onDayClick, onTimeClick, time, date }) => {
  const { i18n } = useTranslation();

  const modifiers = {
    today: new Date(),
    current: date,
  };

  const renderDay = (day: Date) => {
    const date = day.getDate();

    return (
      <div className={styles.cellStyle}>
        <div className={styles.dateStyle}>{date}</div>
      </div>
    );
  };

  const renderCalendar = () => {
    const props = {
      modifiers,
      modifiersStyles,
      renderDay,
      selected: date,
      locale: SupportedDateFnsLocales[i18n.language as SupportedLanguages],
      showOutsideDays: true,
      enableOutsideDaysClick: true,
      onDayClick: (day: Date) => onDayClick(day),
    };

    return (
      <div className={styles.calendar}>
        <DayPicker {...props} />
      </div>
    );
  };

  return (
    <>
      <div className={styles.container}>
        <div>{renderCalendar()}</div>
        <TimePicker
          size="large"
          format="HH:mm"
          onChange={(value) => onTimeClick(value?.format('HH:mm'))}
          className={styles.timePicker}
          inputReadOnly
        />
      </div>
      <div className={styles.selectedDate}>{moment(date).format('dddd, Do MMMM, YYYY HH:mm')}</div>
    </>
  );
};

export default OneValueDateTimePicker;
