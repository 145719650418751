import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Form, Select, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import axios from 'axios';
import Rectangle from 'components/layout/Rectangle/Rectangle';
import ClientRequestService from 'services/client-request.service';
import { poolsGet } from 'store/intermediate/intermediate.actions';
import {
  poolsRequestStateSelector,
  poolsSelector,
} from 'store/intermediate/intermediate.selectors';
import { IPool } from 'store/intermediate/intermediate.types';
import { RequestState } from 'store/common.types';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import styles from './ChangePoolWidget.module.sass';
import ChangePoolModal from './ChangePoolModal';
type TFormData = {
  poolId: string;
};
export const ChangePoolWidget = () => {
  const { t } = useTranslation();
  const locizeKey = `intermediate:tenderDetails:changePool`;
  const clientRequest = useSelector(currentClientRequestSelector);
  const pools = useSelector(poolsSelector) as IPool[];
  const poolsRequestState = useSelector(poolsRequestStateSelector);
  const dispatch = useDispatch();
  const [checkPoolLoading, setCheckPoolLoading] = useState(false);
  const [selectedDestPoolId, setSelectedDestPoolId] = useState<string>();
  const [isChangePoolModalOpen, setIsChangePoolModalOpen] = useState(false);
  const checkPoolChangeHandler = async (formData: TFormData) => {
    if (!clientRequest?.id) return;
    try {
      setCheckPoolLoading(true);
      await ClientRequestService.checkPoolChange(clientRequest?.id, formData.poolId);
      setSelectedDestPoolId(formData.poolId);
      setIsChangePoolModalOpen(true);
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        console.log(e.response);
      }
    } finally {
      setCheckPoolLoading(false);
    }
  };
  const poolsFilterHandler = useCallback(
    (_poolName?: string) => {
      const parentId = clientRequest?.pool?.parentId || clientRequest?.poolId;

      if (!parentId) return;
      debounce(() => {
        dispatch(
          poolsGet({
            query: _poolName,
            parentId,
            includeParent: true,
          }),
        );
      }, 100)();
    },
    [dispatch, clientRequest],
  );
  useEffect(() => {
    poolsFilterHandler('');
  }, [poolsFilterHandler]);
  const poolsOptions = pools?.map((pool) => ({
    value: pool.id,
    label: pool.name,
  }));
  return (
    <Rectangle title={t(`${locizeKey}:title`)} className={styles.rectangle}>
      <Flex vertical={true} gap={16}>
        <Typography.Text>{t(`${locizeKey}:description`)}</Typography.Text>
        <Form
          initialValues={{
            poolId: clientRequest?.poolId,
          }}
          onFinish={checkPoolChangeHandler}
        >
          <Form.Item label={t(`${locizeKey}:pool`)} name={'poolId'}>
            <Select
              size={'large'}
              showSearch
              onSearch={poolsFilterHandler}
              loading={poolsRequestState === RequestState.Loading}
              options={poolsOptions}
              filterOption={false}
              allowClear={true}
              onClear={() => poolsFilterHandler('')}
              onChange={() => poolsFilterHandler('')}
              virtual={false}
            />
          </Form.Item>
          <Form.Item>
            <Flex justify={'center'}>
              <Button type="primary" htmlType={'submit'} size={'small'} loading={checkPoolLoading}>
                {t(`${locizeKey}:submit`)}
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </Flex>
      <ChangePoolModal
        isOpen={isChangePoolModalOpen}
        closeModalHandler={() => setIsChangePoolModalOpen(false)}
        destPoolId={selectedDestPoolId}
      />
    </Rectangle>
  );
};

export default ChangePoolWidget;
