import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PreviewDocument from 'components/ui/PreviewDocument/PreviewDocument';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import { getFileLink } from 'store/client-request/client-request.actions';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import { MediaCategoryType } from 'store/client-request/client-request.types';
import { getOfferFiles } from 'store/offer/offer.actions';
import { currentOfferFilesSelector, currentOfferSelector } from 'store/offer/offer.selectors';
import { OfferApproval, OfferState } from 'store/offer/offer.types';
import styles from './CustomerInstallationDoneStep.module.sass';

const CustomerInstallationDoneStep = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const offer = useSelector(currentOfferSelector);
  const offerFiles = useSelector(currentOfferFilesSelector);
  const clientRequest = useSelector(currentClientRequestSelector);

  useEffect(() => {
    if (offer && offer.id) {
      dispatch(getOfferFiles(offer.id));
    }
  }, [dispatch, offer]);

  const onDocumentClick = useCallback(
    (fileName: string) => {
      dispatch(getFileLink(fileName));
    },
    [dispatch],
  );

  const ivoicesFiles = useCallback(() => {
    const invoices = [];
    const homeCheckInvoice = offerFiles?.find(
      (file) => file.document.category === MediaCategoryType.OfferInvoiceHomeCheck,
    );
    const installationInvoice = offerFiles?.find(
      (file) => file.document.category === MediaCategoryType.OfferInvoiceInstallation,
    );
    const homeCheckInstallerInvoice = offerFiles?.find(
      (file) => file.document.category === MediaCategoryType.OfferInvoiceHomeCheckInstaller,
    );
    const installationInstallerInvoice = offerFiles?.find(
      (file) => file.document.category === MediaCategoryType.OfferInvoiceInstallationInstaller,
    );

    if (homeCheckInvoice) {
      invoices.push(homeCheckInvoice);
    } else {
      homeCheckInstallerInvoice && invoices.push(homeCheckInstallerInvoice);
    }
    if (installationInvoice) {
      invoices.push(installationInvoice);
    } else {
      installationInstallerInvoice && invoices.push(installationInstallerInvoice);
    }
    return invoices;
  }, [offerFiles]);

  const renderInvoicesSection = () => {
    if (clientRequest?.installationInvoiceApproval === OfferApproval.PayByIntermediate) {
      return <></>;
    }
    if (offer?.state === OfferState.InvoiceSubmittedInstallation) {
      return (
        <div className={styles.text}>
          <br />
          {t('customerFlow:installation:installationDone:waitingForInvoice')}
        </div>
      );
    } else {
      return ivoicesFiles()?.map((item: any) => {
        return (
          <div key={item.id} className={styles.file}>
            <div className={styles.fileCategory}>
              {t(`intermediate:myProjects:${item.document.category}`)}
            </div>
            <PreviewDocument
              originalName={item.document.originalName} // @ts-ignore
              size={item.document.size} // @ts-ignore
              type="pdf"
              onDownloadClick={() => onDocumentClick(item.document.name)}
            />
            <div className={styles.date}>
              {t('customerFlow:installation:installationDone:uploadedOn')}
              <DateFormat date={new Date(item.document.createdAt)} />
            </div>
          </div>
        );
      });
    }
  };

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>{t('customerFlow:installation:installationDone:headline')}</h4>
      <div className={styles.text}>
        {t('customerFlow:installation:installationDone:description')}
      </div>
      <br />
      <div className={styles.text}>{t('customerFlow:installation:installationDone:subtitle')}</div>
      <br />
      {clientRequest?.installationInvoiceApproval !== OfferApproval.PayByIntermediate && (
        <div className={styles.subtitle}>
          {t('customerFlow:installation:installationDone:yourInvoices')}
        </div>
      )}
      {renderInvoicesSection()}
    </div>
  );
};

export default CustomerInstallationDoneStep;
