import { all, fork } from 'redux-saga/effects';

import auth from './auth/auth.sagas';
import clientRequest from './client-request/client-request.sagas';
import offer from './offer/offer.sagas';
import register from './register/register.sagas';
import intermediate from './intermediate/intermediate.sagas';
import user from './user/user.sagas';
import installer from './installer/installer.sagas';
import lead from './lead/lead.sagas';
import document from './document/document.sagas';
import poolOrganization from './pool-tender-organization/pool-tender-organization.sagas';
import feedback from './feedback/feedback.sagas';
import bundle from './bundle/bundle.sagas';
import country from './country/country.sagas';
import surveys from './surveys/surveys.sagas';
import contactPerson from './contact-person/contact-person.sagas';
import PDFTemplate from './pdf-templates/pdf-templates.sagas';
import orders from './orders/orders.sagas';
import organization from './organization/organization.sagas';
import productComponent from './product-component/product-component.sagas';
import product from './product/product.sagas';
import parentPoolsFilter from './parentPoolsFilter/parentPoolsFilter.sagas';
// this is main root saga
export default function* root() {
  yield all([
    fork(auth),
    fork(clientRequest),
    fork(offer),
    fork(register),
    fork(intermediate),
    fork(user),
    fork(installer),
    fork(lead),
    fork(document),
    fork(poolOrganization),
    fork(feedback),
    fork(bundle),
    fork(country),
    fork(surveys),
    fork(contactPerson),
    fork(PDFTemplate),
    fork(orders),
    fork(organization),
    fork(productComponent),
    fork(product),
    fork(parentPoolsFilter),

    // add all other imported sagas here
  ]);
}
