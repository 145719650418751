import React from 'react';
import clsx from 'clsx';
import { ExclamationCircleOutlined, CloseOutlined } from '@ant-design/icons';
import styles from './InfoBox.module.sass';

export enum InfoBoxColor {
  GREY = 'grey',
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
}

interface IBoxProps {
  className?: string;
  title?: string;
  color?: InfoBoxColor;
  onClose?(): void;
  children: React.ReactNode;
}

const InfoBox: React.FC<IBoxProps> = ({
  children,
  className,
  color = InfoBoxColor.GREY,
  title,
  onClose,
}) => {
  const renderTitle = () => (
    <h4 className={styles.title}>
      <span className={styles.titleText}>
        <ExclamationCircleOutlined /> {title}
      </span>
    </h4>
  );

  const renderCloseButton = () => (
    <button className={styles.buttonClose}>
      <CloseOutlined />
    </button>
  );

  const containerClassName = clsx(styles.container, styles[color], className);

  return (
    <div className={containerClassName}>
      {typeof onClose === 'function' ? renderCloseButton() : null}
      {title ? renderTitle() : null}
      <div>{children}</div>
    </div>
  );
};

export default InfoBox;
