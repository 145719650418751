import React, { useMemo, useState } from 'react';
import _get from 'lodash/get';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';

import { Avatar, Button, Drawer, Typography } from 'antd';

import OrganizationIcon from 'assets/icons/menu/organization.svg';
import ProfileIcon from 'assets/icons/menu/profile.svg';
import InvitationsIcon from 'assets/icons/menu/invitations.svg';
import InvoicesIcon from 'assets/icons/menu/invoices.svg';
import InfoIcon from 'assets/icons/menu/info.svg';
import LogoutIcon from 'assets/icons/menu/logout.svg';
import DeliveryDateIcon from 'assets/icons/menu/deliveryDate.svg';
import Icon from 'components/ui/Icon/Icon';
import { DeliveryDateModalPages, showModal } from 'store/delivery-date/delivery-date.module';
import { WallboxDeliveryStatus } from 'store/client-request/client-request.types';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import {
  isCustomerSelector,
  isInstallerManagerSelector,
  isIntermediateManagerSelector,
  userDataSelector,
} from 'store/auth/auth.selectors';
import { logout } from 'store/auth/auth.actions';
import styles from './UserMenu.module.sass';

const UserMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  const clientRequest = useSelector(currentClientRequestSelector);
  const userData = useSelector(userDataSelector);
  const isInstallerManager = useSelector(isInstallerManagerSelector);
  const isIntermadiateManager = useSelector(isIntermediateManagerSelector);
  const isCustomer = useSelector(isCustomerSelector);

  const userDisplayData = useMemo(
    () => ({
      fullName: `${_get(userData, 'firstName', '')} ${_get(userData, 'lastName', '')}`,
      email: _get(userData, 'email', ''),
      phone: _get(userData, 'phone', ''),
    }),
    [userData],
  );

  const deliveryStatus = _get(clientRequest, 'wallboxDeliveryStatus', null);

  const isDeliveryButton =
    isCustomer &&
    !_get(clientRequest, 'products.length', null) &&
    deliveryStatus &&
    deliveryStatus !== WallboxDeliveryStatus.DeliveredManually;

  const handleMenuClick = (path: string) => {
    navigate(path);
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    handleMenuClick('/');
  };

  const handleDeliveryDate = () => {
    dispatch(showModal(true, DeliveryDateModalPages.HeaderPopup));
    setOpen(false);
  };

  const menu = [
    {
      title: t('profile:organization'),
      path: '/organization',
      visible: isInstallerManager || isIntermadiateManager,
      icon: OrganizationIcon,
    },
    {
      title: t('profile:profile'),
      path: '/profile',
      visible: true,
      icon: ProfileIcon,
    },
    {
      title: t('profile:invitations'),
      path: '/invitations',
      visible: isInstallerManager,
      icon: InvitationsIcon,
    },
    {
      title: t('profile:invoices'),
      path: '/invoices',
      visible: isCustomer,
      icon: InvoicesIcon,
    },
    {
      title: t('profile:faqPage'),
      path: '/faq',
      visible: isCustomer,
      icon: InfoIcon,
    },
    {
      title: t('customerFlow:clientRequest:deliveryDateButton'),
      path: '/delivery-date',
      visible: isDeliveryButton,
      onClick: handleDeliveryDate,
      icon: DeliveryDateIcon,
    },
    {
      title: t('profile:logOut'),
      path: '/',
      onClick: handleLogout,
      visible: true,
      icon: LogoutIcon,
    },
  ];

  return (
    <>
      <div className={styles.container} onClick={() => setOpen(true)}>
        <Avatar icon={<UserOutlined />} />
        {userDisplayData && (
          <Typography.Text className={styles.name}>{userDisplayData.fullName}</Typography.Text>
        )}
      </div>
      <Drawer open={isOpen} onClose={() => setOpen(false)} rootClassName={styles.drawer}>
        <div className={styles.header}>
          <Typography.Title level={3}>{userDisplayData.fullName}</Typography.Title>
          <Typography.Title level={5}>{userDisplayData.email}</Typography.Title>
        </div>
        <div className={styles.menu}>
          {menu.map((item) => {
            if (!item.visible) return;
            return (
              <Button
                className={styles.menuButton}
                key={item.path}
                size="small"
                type="text"
                icon={<Icon icon={item.icon} className={styles.icon} />}
                onClick={() => (item.onClick ? item.onClick() : handleMenuClick(item.path))}
                block
              >
                {item.title}
              </Button>
            );
          })}
        </div>
      </Drawer>
    </>
  );
};

export default UserMenu;
