import { produce } from 'immer';
import { createSelector } from 'reselect';

export enum DeliveryDateModalPages {
  JourneySelection = 'JourneySelection',
  HeaderPopup = 'HeaderPopup',
  ClientRequest = 'ClientRequest',
}
export interface IDeliveryDateModalState {
  isModal: boolean;
  page: DeliveryDateModalPages | null;
}

const initialState: IDeliveryDateModalState = {
  isModal: false,
  page: null,
};

const SHOW_MODAL = 'SHOW_MODAL';

const DELIVERY_DATE_UPDATED = 'DELIVERY_DATE_UPDATED';

interface ShowModalAction {
  type: typeof SHOW_MODAL;
  payload: {
    status: boolean;
    page: DeliveryDateModalPages;
  };
}
interface DeliveryDateUpdatedAction {
  type: typeof DELIVERY_DATE_UPDATED;
}

type DeliveryDateModalActionTypes = ShowModalAction | DeliveryDateUpdatedAction;

export const showModal = (status: boolean, page?: DeliveryDateModalPages) => ({
  type: SHOW_MODAL,
  payload: { status, page },
});

export const deliveryDateUpdated = () => ({
  type: DELIVERY_DATE_UPDATED,
});

export const DeliveryDateModalReducer = (
  state = initialState,
  action: DeliveryDateModalActionTypes,
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SHOW_MODAL:
        draft.isModal = action.payload.status;
        draft.page = action.payload.page;
        break;
      case DELIVERY_DATE_UPDATED:
        draft.isModal = false;
        draft.page = null;
    }
  });

const deliveryDateSelector = (state: any) => state.deliveryDateModal;

export const selectDeliveryDateModalState = createSelector(
  deliveryDateSelector,
  (deliveryDateModal) => deliveryDateModal,
);
