import React, { FC, useMemo } from 'react';

import { Col, Form, FormInstance, Input, Row, Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import NumericInput from 'components/ui/NumberInput/NumericInput';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import { ValidationFactory } from 'framework/validations';
import { IOption } from 'store/common.types';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import { percentFilter } from 'utils/currencyUtils';
import OfferUploader from './OfferUploader';

interface IOfferReservationsProps {
  form?: FormInstance;
  offerId?: string;
}

const OfferReservations: FC<IOfferReservationsProps> = ({ offerId }) => {
  const { t } = useTranslation();
  const request = useSelector(currentClientRequestSelector);

  const currencyOptions = useMemo(
    () =>
      request?.pool?.countries?.reduce((options: IOption[], country) => {
        const { currencySymbol, currency } = country;

        if (!options.some((option) => option.value === currencySymbol)) {
          const label = `${currencySymbol} ${currency}`;
          options.push({ value: currencySymbol, label });
        }

        return options;
      }, []) ?? [],
    [request],
  );
  const minValidityDays = useMemo(() => request?.pool?.offerMinimumValidity ?? '14', [request]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Form.Item name="documents" rules={[ValidationFactory.REQUIRED]}>
          <OfferUploader offerId={offerId} />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Typography.Title level={5}>
          {t('installerFlow:tenderParticipate:sectionParticipate:totalPrice')}
        </Typography.Title>
      </Col>
      <Col span={18}>
        <Row>
          <Col span={9}>
            <Form.Item name="costEstimate" rules={[ValidationFactory.REQUIRED]}>
              <NumericInput
                width="100%"
                label={t('installerFlow:tenderParticipate:sectionParticipate:biddingPrice')}
              />
            </Form.Item>
          </Col>
          <Col span={15} />
          <Col span={5}>
            <Form.Item name="vatRate">
              <NumericInput
                disabled
                width="100%"
                isAllowed={percentFilter}
                suffix={'%'}
                label={'% VAT'}
              />
            </Form.Item>
          </Col>
          <Col span={3} offset={1}>
            <Form.Item name="currencySymbol" rules={[ValidationFactory.REQUIRED]}>
              <Select disabled options={currencyOptions} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col span={6}>
        <Typography.Title level={5}>
          {t('installerFlow:tenderParticipate:withReservation:offerValidation')}
        </Typography.Title>
      </Col>
      <Col span={7}>
        <Form.Item
          name="offerValidityDays"
          rules={[ValidationFactory.REQUIRED, ValidationFactory.MIN_DAYS(minValidityDays)]}
        >
          <NumericInput label={t('installerFlow:tenderParticipate:withReservation:days')} />
        </Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item dependencies={['offerValidityDays']} noStyle>
          {({ getFieldValue }) =>
            getFieldValue('offerValidityDate') && (
              <Typography.Paragraph>
                <span>
                  {t('installerFlow:tenderParticipate:withReservation:offerValidUntill')} :{' '}
                  <DateFormat date={getFieldValue('offerValidityDate')} />
                </span>
              </Typography.Paragraph>
            )
          }
        </Form.Item>
      </Col>
      <Col span={24}>
        <Typography.Title level={4}>
          {t('installerFlow:tenderParticipate:sectionAnnotation:title')}
        </Typography.Title>
        <Typography.Paragraph>
          {t('installerFlow:tenderParticipate:sectionAnnotation:description')}
        </Typography.Paragraph>
        <Form.Item name="annotation">
          <Input.TextArea />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default OfferReservations;
