import React from 'react';
import { useTranslation } from 'react-i18next';
import House from 'assets/icons/house.svg';
import Icon from 'components/ui/Icon/Icon';
import styles from './QuestionnaireSkippedIcon.module.sass';

const QuestionnaireSkippedIcon = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.homeCheckRequest}>
      <div className={styles.blankBlock}>
        <Icon icon={House} />
        <p>{t('common:questionnaireSkipped')}</p>
      </div>
    </div>
  );
};

export default QuestionnaireSkippedIcon;
