import { produce } from 'immer';
import {
  FEEDBACK_CREATE,
  FEEDBACK_CREATE_ERROR,
  FEEDBACK_CREATE_SUCCESS,
  FEEDBACK_GET,
  FEEDBACK_GET_ERROR,
  FEEDBACK_GET_SUCCESS,
  FeedbackActionTypes,
  IFeedbackState,
} from './feedback.types';
import { RequestState } from '../common.types';

const initialState: IFeedbackState = {
  feedback: [],
  feedbackState: null,
  feedbackError: null,

  createFeedbackState: null,
  createFeedbackError: null,
};

const FeedbackReducer = (state = initialState, action: FeedbackActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FEEDBACK_GET:
        draft.feedbackState = RequestState.Loading;
        break;

      case FEEDBACK_GET_ERROR:
        draft.feedbackState = RequestState.Error;
        draft.feedbackError = action.payload;
        break;

      case FEEDBACK_GET_SUCCESS:
        draft.feedbackState = RequestState.Success;
        draft.feedbackError = null;
        draft.feedback = action.payload;
        break;

      case FEEDBACK_CREATE:
        draft.createFeedbackState = RequestState.Loading;
        break;

      case FEEDBACK_CREATE_ERROR:
        draft.createFeedbackState = RequestState.Error;
        draft.createFeedbackError = action.payload;
        break;

      case FEEDBACK_CREATE_SUCCESS:
        draft.createFeedbackState = RequestState.Success;
        draft.createFeedbackError = null;
        draft.feedback = action.payload;
        break;
    }
  });

export default FeedbackReducer;
