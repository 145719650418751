import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { IOrganization } from 'store/intermediate/intermediate.types';
import styles from './OrganizationInfoLabel.module.sass';

interface IProps {
  organization: IOrganization | { logo: string; name: string };
}

export const OrganizationInfoLabel: React.FC<IProps> = (props) => {
  const { organization } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 16]} className={styles.logoStyle}>
      <Col span={12} className={styles.logoColum1}>
        <span> {t('customerFlow:registration:bannerText1')} </span>
      </Col>
      <Col span={12} className={styles.logoColum2}>
        <Row gutter={[16, 16]} style={{ placeItems: 'center' }}>
          <Col span={8} style={{ textAlign: 'center' }}>
            {organization.logo ? (
              <img
                className={styles.organizationLogo}
                src={organization.logo}
                alt="logo"
                width="70"
                height="70"
              />
            ) : (
              <Avatar
                shape="square"
                className={styles.organizationLogo}
                size={64}
                icon={<UserOutlined />}
              />
            )}
          </Col>
          <Col span={16}>{organization.name}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default OrganizationInfoLabel;
