import React, { useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/lib/typography';
import Loader from 'components/ui/Loader/Loader';
import { validateInviteToken } from 'store/register/register.actions';
import {
  isRegistrationErrorSelector,
  isRegistrationInviteTokenSelector,
} from 'store/register/register.selectors';
import { ValidateInviteType, installerInviteTypes } from 'store/register/register.types';
import { landingPageGet } from 'store/intermediate/intermediate.actions';
import { SlugCategoryType } from 'store/intermediate/intermediate.types';
import { PoolType } from 'types/pool-settings.types';
import { setAppLanguage } from 'utils/language';

const RegistrationLinkPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const inviteTokenData = useSelector(isRegistrationInviteTokenSelector);
  const registrationError = useSelector(isRegistrationErrorSelector);

  useEffect(() => {
    if (id) dispatch(validateInviteToken(id));
  }, []); // eslint-disable-line
  useEffect(() => {
    if (inviteTokenData?.clientRequestPool?.country?.defaultLanguage) {
      setAppLanguage(inviteTokenData?.clientRequestPool?.country?.defaultLanguage);
    }
  }, [inviteTokenData]);

  const renderContent = useCallback(() => {
    const inviteType = inviteTokenData?.type;

    if (!inviteType) return <Loader />;

    if (inviteTokenData?.clientRequestPool?.id) {
      const landingType =
        inviteTokenData?.clientRequestPool?.poolType === PoolType.SurveyPool
          ? SlugCategoryType.SurveyLandingPage
          : SlugCategoryType.ParentLandingPage;
      dispatch(landingPageGet(inviteTokenData.clientRequestPool.id, landingType));
    }

    if (inviteType === ValidateInviteType.EndUser) {
      inviteTokenData?.clientRequestPool?.poolType === PoolType.SurveyPool
        ? navigate(`/survey/${inviteTokenData?.clientRequestPool.id}`)
        : navigate(`/customer/register`);
    }

    if (installerInviteTypes.includes(inviteType as ValidateInviteType))
      navigate(`/installer/register/${inviteTokenData?.token}`);

    if (
      registrationError ||
      [
        ValidateInviteType.Installer,
        ValidateInviteType.EndUser,
        ValidateInviteType.InstallerManager,
      ].includes(inviteType as ValidateInviteType)
    ) {
      return (
        <Typography.Title level={5} style={{ textAlign: 'center', paddingTop: '50px' }}>
          {t(`common:errors:registrationLinkInvalid`)}
        </Typography.Title>
      );
    }

    return <Loader />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteTokenData]);

  return renderContent();
};

export default RegistrationLinkPage;
