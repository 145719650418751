import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import Typography from 'antd/lib/typography';
import Rectangle from 'components/layout/Rectangle/Rectangle';
import BackButton from 'components/ui/BackButton/BackButton';
import NextButton from 'components/ui/NextButton/NextButton';
import RadioQuestion from 'components/questions/RadioQuestion/RadioQuestion';
import Loader from 'components/ui/Loader/Loader';

import { clientRequestGet } from 'store/client-request/client-request.actions';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';

import styles from './OfferTypePage.module.sass';

const OfferTypePage = () => {
  const [needHomeCheck, setNeedHomeCheck] = React.useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const clientRequest = useSelector(currentClientRequestSelector);
  const isQuestionnaireSkipped = clientRequest?.questionnaireSkipped;

  useEffect(() => {
    if (!clientRequest && id) {
      dispatch(clientRequestGet(id, false));
    }
  }, []); // eslint-disable-line

  const onBackButtonClick = useCallback(() => {
    navigate(`/installer/tender/${id}`);
  }, []); // eslint-disable-line

  const onNextButtonClick = () => {
    const path = needHomeCheck === 'no' ? 'directly' : 'home-check';
    const remoteHomeCheck = needHomeCheck === 'remoteHomeCheck' ? '?remoteHomeCheck=yes' : '';
    navigate(`/installer/tender/${id}/participate/${path}${remoteHomeCheck}`);
  };

  const radioProps = {
    question: '',
    options: [
      {
        label: t('installerFlow:tenderDetails:offerTypePage:optionRemoteHC'),
        value: 'remoteHomeCheck',
      },
      {
        label: t('installerFlow:tenderDetails:offerTypePage:optionYes'),
        value: 'yes',
      },
      {
        label: t('installerFlow:tenderDetails:offerTypePage:optionNo'),
        value: 'no',
      },
    ],
    onChange: (value: string) => setNeedHomeCheck(value),
  };

  // For case when questionnaire skipped installer can't skip home check
  if (isQuestionnaireSkipped) {
    radioProps.options.splice(-1);
  }

  if (!clientRequest) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <BackButton className={styles.back} onClick={onBackButtonClick}>
          {t('common:buttons.back')}
        </BackButton>

        <Rectangle title={t('installerFlow:tenderDetails:offerTypePage:title')}>
          <Typography className={styles.text}>
            {t('installerFlow:tenderDetails:offerTypePage:description')}
          </Typography>

          <div className={styles.options}>
            <RadioQuestion {...radioProps} />
          </div>

          <NextButton disabled={!needHomeCheck} className={styles.next} onClick={onNextButtonClick}>
            {t('common:buttons:next')}
          </NextButton>
        </Rectangle>
      </div>
    </div>
  );
};

export default OfferTypePage;
