import { CHANGE_STEP, SET_SUB_STEPS_HIDDEN, SET_GLOBAL_ERROR } from './app.types';

// Change active step
export const changeStep = (activeStep: number, activeSubStep: number) => ({
  type: CHANGE_STEP,
  payload: { activeStep, activeSubStep },
});

// Change SubSteps visibility
export const setSubStepsHidden = (subStepsHidden: boolean) => ({
  type: SET_SUB_STEPS_HIDDEN,
  payload: { subStepsHidden },
});

export const setGlobalError = (error: string) => ({
  type: SET_GLOBAL_ERROR,
  payload: { error },
});
