import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Form, notification } from 'antd';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/ui/Button/Button';
import Loader from 'components/ui/Loader/Loader';
import { ICustomDocument, IPool } from 'store/intermediate/intermediate.types';

import {
  customDocumentDelete,
  getPoolLegalDocuments,
  savePoolLegalDocuments,
} from 'store/intermediate/intermediate.actions';
import {
  legalDocumentsSelector,
  legalDocumentsStateSelector,
} from 'store/intermediate/intermediate.selectors';
import { RequestState } from 'store/common.types';
import {
  formatLegalDocumentsFormData,
  getDeletedDocumentsIDs,
  saveLegalDocumentsPayload,
} from 'utils/poolUtils';
import LegalSectionEditor from './LegalSectionsEditor';
import styles from './LegalTab.module.sass';
import { TSupportedLanguagesKey } from '../../../../../types/language.types';

interface ILegalTabProps {
  pool: IPool;
}
enum sections {
  'termsConditions' = 'termsConditions',
  'dataPrivacy' = 'dataPrivacy',
  'cancelationPolicy' = 'cancelationPolicy',
  'imprint' = 'imprint',
}
type TLanguageBasedLegalValue = {
  [language in TSupportedLanguagesKey]?: {
    id?: string;
    text: string | null;
    link: string | null;
  };
};
export type TLegalFormValues = {
  [category in keyof typeof sections]: TLanguageBasedLegalValue;
};

const LegalTab: React.FC<ILegalTabProps> = ({ pool }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<TLegalFormValues>();
  const dispatch = useDispatch();

  const legalDocuments: ICustomDocument[] = useSelector(legalDocumentsSelector);
  const legalDocumentsState = useSelector(legalDocumentsStateSelector);

  useEffect(() => {
    dispatch(getPoolLegalDocuments(pool.id, false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pool.id, dispatch]);

  const showNotification = useCallback(
    (success: boolean, message: string) => {
      setLoading(false);
      const body = {
        duration: 3,
        message: message,
      };
      success ? notification.success(body) : notification.error(body);
    },
    [setLoading],
  );

  const save = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        const deletingDocumentIDs = getDeletedDocumentsIDs(values, legalDocuments);
        for (const deletingDocumentID of deletingDocumentIDs) {
          dispatch(customDocumentDelete(deletingDocumentID));
        }
        dispatch(
          savePoolLegalDocuments(pool.id, saveLegalDocumentsPayload(values), () =>
            showNotification(true, t('intermediate:myProjects:legalInformation:successMessage')),
          ),
        );
      })
      .catch(() =>
        showNotification(false, t('intermediate:myProjects:legalInformation:errorMessage')),
      );
  }, [form, setLoading, pool, dispatch, showNotification, legalDocuments, t]);
  const formValues = useMemo(() => {
    const formattedDocumentsData = formatLegalDocumentsFormData(legalDocuments);
    form.setFieldsValue(formattedDocumentsData);
    return formattedDocumentsData;
  }, [legalDocuments, form]);

  if (legalDocumentsState === RequestState.Loading) {
    return <Loader />;
  }
  return (
    <Form form={form} initialValues={formValues}>
      {Object.keys(sections).map((section) => (
        <LegalSectionEditor
          defaultLanguage={pool.country.defaultLanguage}
          key={section}
          sectionName={section}
        />
      ))}
      <div className={styles.buttonContainer}>
        <Button disabled={loading} type="primary" onClick={save} className={styles.button}>
          {t('common:buttons:saveChanges')}
        </Button>
      </div>
    </Form>
  );
};

export default LegalTab;
