import { all, call, put, takeLatest } from 'redux-saga/effects';
import CountryAPI from 'services/country.service';
import { countriesGetError, countriesGetSuccess } from './country.actions';
import { CountriesGetAction, COUNTRIES_GET, ICountry } from './country.types';

export function* getCountries(action: CountriesGetAction) {
  try {
    const result: { items: ICountry[] } = yield call(CountryAPI.getCountries);
    if (result) {
      yield put(countriesGetSuccess(result.items));
    } else {
      yield put(countriesGetError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(countriesGetError(message));
  }
}

export default function* root() {
  yield all([takeLatest(COUNTRIES_GET, getCountries)]);
}
