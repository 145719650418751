import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'antd/lib/form';
import { useDispatch, useSelector } from 'react-redux';
import AddressQuestion from 'components/questions/AddressQuestion/AddressQuestion';
import Checkbox from 'components/ui/Checkbox/Checkbox';
import { ValidationFactory } from 'framework/validations';

import styles from 'pages/client-request/ClientRequestPage/ClientRequestPage.module.sass';
import { ClientRequestFields } from 'store/client-request/client-request.types';
import { IPool } from 'store/intermediate/intermediate.types';
import { countriesListSelector } from 'store/country/country.selectors';
import { countriesGet } from 'store/country/country.actions';

interface IPersonalDetailsStepProps {
  form?: any;
  pool?: IPool | null;
}

const PersonalDetailsStep: React.FC<IPersonalDetailsStepProps> = (
  props: IPersonalDetailsStepProps,
) => {
  const { form, pool } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const questionBillingAddressRef = React.useRef<HTMLDivElement>(null);
  const questionAddressRef = React.useRef<HTMLDivElement>(null);
  const [showDifferentAddress, setShowDifferentAddress] = useState(
    form.getFieldValue(ClientRequestFields.BillingAddress)?.postalCode,
  );
  const addressValidationMessage = 'customerFlow:clientRequest:fillAddressDetails';
  const billingAddressValidationMessage = 'customerFlow:clientRequest:fillBillingAddressDetails';

  const allSupportedCountries = useSelector(countriesListSelector);

  useEffect(() => {
    dispatch(countriesGet());
  }, [dispatch]);

  return (
    <Form form={form} style={{ width: '600px' }} name="request-personal-details-form">
      <Form.Item
        name={ClientRequestFields.Address}
        rules={[ValidationFactory.ADDRESS_INPUTS(addressValidationMessage)]}
      >
        <AddressQuestion
          ref={questionAddressRef}
          name={'address'}
          question={t(`customerFlow:questions:${ClientRequestFields.Address}:installationAddress`)}
          form={form}
          filteredCountries={pool?.country ? [pool.country] : []}
          isCountryDisabled={true}
        />
      </Form.Item>
      <Form.Item initialValue={false} name="showDifferentAddress" valuePropName="checked">
        <Checkbox
          checked={showDifferentAddress}
          onChange={() => setShowDifferentAddress(!showDifferentAddress)}
        >
          {t('customerFlow:clientRequest:differentBillingAddress')}
        </Checkbox>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) =>
          getFieldValue('showDifferentAddress') && (
            <Form.Item
              className={styles.questionShort}
              name={ClientRequestFields.BillingAddress}
              rules={[ValidationFactory.ADDRESS_INPUTS(billingAddressValidationMessage)]}
            >
              <AddressQuestion
                ref={questionBillingAddressRef}
                name={'billingAddress'}
                question={t(`customerFlow:questions:${ClientRequestFields.Address}:billingAddress`)}
                form={form}
                filteredCountries={allSupportedCountries ?? []}
                isCountryDisabled={false}
              />
            </Form.Item>
          )
        }
      </Form.Item>
    </Form>
  );
};

export default PersonalDetailsStep;
