import { all, call, put, takeLatest } from 'redux-saga/effects';
import ContactPersonAPI from 'services/contact-person.service';
import {
  contactPersonCreateError,
  contactPersonCreateSuccess,
  contactPersonDeleteError,
  contactPersonDeleteSuccess,
  contactPersonsGetError,
  contactPersonsGetSuccess,
  contactPersonUpdateError,
  contactPersonUpdateSuccess,
} from './contact-person.actions';
import {
  ContactPersonCreateAction,
  ContactPersonGetAction,
  CONTACT_PERSON_CREATE,
  CONTACT_PERSONS_GET,
  IContactPerson,
  ContactPersonUpdateAction,
  CONTACT_PERSON_UPDATE,
  ContactPersonDeleteAction,
  CONTACT_PERSON_DELETE,
} from './contact-person.types';

export function* createContactPerson(action: ContactPersonCreateAction) {
  try {
    const { poolId, contactPerson, onSuccess } = action.payload;
    const result: IContactPerson = yield call(
      ContactPersonAPI.createContactPerson,
      poolId,
      contactPerson,
    );
    if (result) {
      onSuccess();
      yield put(contactPersonCreateSuccess(result));
    } else {
      yield put(contactPersonCreateError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(contactPersonCreateError(message));
  }
}

export function* getContactPersons(action: ContactPersonGetAction) {
  try {
    const result: IContactPerson[] = yield call(ContactPersonAPI.getContactPersons, action.payload);
    if (result) {
      yield put(contactPersonsGetSuccess(result));
    } else {
      yield put(contactPersonsGetError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(contactPersonsGetError(message));
  }
}

export function* updateContactPerson(action: ContactPersonUpdateAction) {
  try {
    const { contactPersonId, contactPerson, onSuccess } = action.payload;
    const result: IContactPerson = yield call(
      ContactPersonAPI.updateContactPerson,
      contactPersonId,
      contactPerson,
    );
    if (result) {
      onSuccess();
      yield put(contactPersonUpdateSuccess({ ...contactPerson, id: contactPersonId }));
    } else {
      yield put(contactPersonUpdateError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(contactPersonUpdateError(message));
  }
}

export function* deleteContactPerson(action: ContactPersonDeleteAction) {
  try {
    const result: IContactPerson = yield call(ContactPersonAPI.deleteContactPerson, action.payload);
    if (result) {
      yield put(contactPersonDeleteSuccess(action.payload));
    } else {
      yield put(contactPersonDeleteError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(contactPersonDeleteError(message));
  }
}

export default function* root() {
  yield all([
    takeLatest(CONTACT_PERSON_CREATE, createContactPerson),
    takeLatest(CONTACT_PERSONS_GET, getContactPersons),
    takeLatest(CONTACT_PERSON_UPDATE, updateContactPerson),
    takeLatest(CONTACT_PERSON_DELETE, deleteContactPerson),
  ]);
}
