import React from 'react';
import clsx from 'clsx';
import styles from './FilesList.module.sass';
import FileCard from '../FileCard/FileCard';
import { IFile } from '../../../store/client-request/client-request.types';

interface IFilesListProps {
  className?: string;
  files: IFile[];
  onFileDelete?: (fileName: string, fileId: string) => void;
}

const FilesList: React.FC<IFilesListProps> = (props) => {
  const { files, className, onFileDelete } = props;
  const containerClassName = clsx(
    className === 'mobileViewWidth' ? styles.mobileViewWidth : styles.container,
    className,
  );

  return (
    <div className={containerClassName}>
      {files.map((file: IFile) => (
        <FileCard {...file} key={file.id} className={styles.card} onDeleteClick={onFileDelete} />
      ))}
    </div>
  );
};

FilesList.defaultProps = {
  files: [],
};

export default FilesList;
