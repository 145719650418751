import React, { useContext } from 'react';
import { default as ANTSelect, SelectProps } from 'antd/lib/select';
import FiltersContext from 'context/filters.context';

const { Option: ANTOption } = ANTSelect;

export type SelectItem = {
  value: string;
  label: string;
};

export interface IFilterSelectProps {
  name: string;
  width: number;
  options: SelectItem[];
}

const FilterSelect: React.FC<IFilterSelectProps & SelectProps<any>> = ({
  name,
  options = [],
  width = 100,
  ...props
}) => {
  const { filters, setFilters } = useContext(FiltersContext);

  const handleChange = (value: any) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const renderOptions = () =>
    options.map((item: SelectItem, index: number) => (
      <ANTOption value={item.value} key={`${item.value}_${index}`}>
        {item.label}
      </ANTOption>
    ));

  const selectProps = {
    ...props,
    style: {
      width,
      marginRight: '10px',
    },
    onChange: handleChange,
  };

  return <ANTSelect {...selectProps}>{renderOptions()}</ANTSelect>;
};

export default FilterSelect;
