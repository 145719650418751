import React from 'react';

export type FiltersType = {
  [key: string]: string | string[] | number | null;
};

const FiltersContext = React.createContext({
  filters: {},
  setFilters: () => ({}),
} as {
  filters: FiltersType;
  setFilters: any;
});

export default FiltersContext;
