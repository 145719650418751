import { IAddress } from 'store/client-request/client-request.types';
import { RequestState } from '../common.types';

export enum LeadState {
  NEW = 'NEW',
  CONTACTED = 'CONTACTED',
}

export interface ILead {
  state: LeadState;
  leadType: string;
  companyName?: string;
  numberOfCars?: string;
  contactPersonName?: string;
  contactPersonEmail?: string;
  initiatorName?: string;
  phone?: string;
  address?: IAddress;
}

export interface ILeadState {
  leadCreateState: RequestState | null;
  leadCreateError: string | null;
}

export const LEAD_CREATE = 'LEAD_CREATE';
export const LEAD_CREATE_SUCCESS = 'LEAD_CREATE_SUCCESS';
export const LEAD_CREATE_ERROR = 'LEAD_CREATE_ERROR';

//Create Lead
export interface LeadCreateAction {
  type: typeof LEAD_CREATE;
  payload: ILead;
}

export interface LeadCreateSuccessAction {
  type: typeof LEAD_CREATE_SUCCESS;
  payload: any;
}

export interface LeadCreateErrorAction {
  type: typeof LEAD_CREATE_ERROR;
  payload: any;
}

export type LeadActionTypes = LeadCreateAction | LeadCreateSuccessAction | LeadCreateErrorAction;
