interface IConfigEnv {
  [key: string]: any;
}

interface IConfig {
  local: IConfigEnv;
  development: IConfigEnv;
  staging: IConfigEnv;
  production: IConfigEnv;
}

const configOptions: IConfig = {
  local: {
    AUTH_SERVICE_URL: 'http://localhost:4000/api/v1',
    CRAFT_SERVICE_URL: 'http://localhost:3000/api/v1',
    CRAFT_SERVICE_URL_V2: 'http://localhost:3000/api/v2',
    SENTRY_DSN_URL: 'https://c1b7b94669294b369021cc6c72bb023b@o486407.ingest.sentry.io/5543616',
    SENTRY_ENVIRONMENT: 'local',
  },
  development: {
    AUTH_SERVICE_URL: 'https://auth.development.tendergy.com/api/v1',
    CRAFT_SERVICE_URL: 'https://craft.development.tendergy.com/api/v1',
    CRAFT_SERVICE_URL_V2: 'https://craft.development.tendergy.com/api/v2',
    SENTRY_DSN_URL: 'https://c1b7b94669294b369021cc6c72bb023b@o486407.ingest.sentry.io/5543616',
    SENTRY_ENVIRONMENT: 'developement',
  },
  staging: {
    AUTH_SERVICE_URL: 'https://auth.staging.tendergy.com/api/v1',
    CRAFT_SERVICE_URL: 'https://craft.staging.tendergy.com/api/v1',
    CRAFT_SERVICE_URL_V2: 'https://craft.staging.tendergy.com/api/v2',
    SENTRY_DSN_URL: 'https://c1b7b94669294b369021cc6c72bb023b@o486407.ingest.sentry.io/5543616',
    SENTRY_ENVIRONMENT: 'staging',
  },
  production: {
    AUTH_SERVICE_URL: 'https://auth.services.tendergy.com/api/v1',
    CRAFT_SERVICE_URL: 'https://craft.services.tendergy.com/api/v1',
    CRAFT_SERVICE_URL_V2: 'https://craft.services.tendergy.com/api/v2',
    SENTRY_DSN_URL: 'https://c1b7b94669294b369021cc6c72bb023b@o486407.ingest.sentry.io/5543616',
    SENTRY_ENVIRONMENT: 'production',
  },
};

// eslint-disable-next-line
const env = process.env.REACT_APP_CUSTOM_NODE_ENV || 'development';

// @ts-ignore
const config: IConfigEnv = configOptions[env];

// eslint-disable-next-line
console.log('ENV', process.env.REACT_APP_CUSTOM_NODE_ENV, config);

export default config;
