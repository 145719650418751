import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Flex, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import ProductSelection from 'pages/client-request/Questionnaire/QuestionnaireSteps/ProductWizard/steps/Product/ProductSelection';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import { productWizardGet } from 'store/product-component/product-components.actions';
import { IProduct } from 'store/product/product.types';
import {
  productWizardSelector,
  productWizardStateSelector,
} from 'store/product-component/product-component.selector';
import { RequestState } from 'store/common.types';
import ProductComponentWidget from '../ProductComponentWidget/ProductComponentWidget';

type TSelectProductStepProps = {
  destPoolId?: string;
  submitHandler: (products?: IProduct[]) => void;
  initialProducts?: IProduct[];
  backClick: () => void;
};
const SelectProductsStepComponent = (props: TSelectProductStepProps) => {
  const clientRequest = useSelector(currentClientRequestSelector);
  const productWizardRequestState = useSelector(productWizardStateSelector);
  const destPoolProducts = useSelector(productWizardSelector);
  const [isProductsRequestSent, setIsProductsRequestSent] = useState(false);
  const locizeKey = `intermediate:tenderDetails:changePoolModal`;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm();
  const loadProductComponents = useCallback(() => {
    if (!props.destPoolId) return;
    dispatch(productWizardGet(props.destPoolId));
    setIsProductsRequestSent(true);
  }, [props.destPoolId, dispatch]);
  useEffect(() => {
    loadProductComponents();
  }, [loadProductComponents]);
  const submitHandler = async () => {
    try {
      const values = await form.validateFields();
      props.submitHandler(values.products);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (
      productWizardRequestState === RequestState.Success &&
      !destPoolProducts.length &&
      isProductsRequestSent
    )
      props.submitHandler();
  }, [productWizardRequestState, destPoolProducts, props, isProductsRequestSent]);
  return (
    <Flex vertical={true} gap={8}>
      <Card title={t(`${locizeKey}:currentProducts`)}>
        {clientRequest?.products?.length && clientRequest.products.length > 0 ? (
          <ProductComponentWidget poolOperator={true} products={clientRequest?.products || []} />
        ) : (
          <Typography.Text>{t(`${locizeKey}:noProducts`)}</Typography.Text>
        )}
      </Card>
      <Card
        title={t(`${locizeKey}:newProducts`)}
        loading={productWizardRequestState === RequestState.Loading}
      >
        <ProductSelection form={form} initialProducts={props.initialProducts} />
      </Card>
      <Flex justify={'space-between'} gap={4}>
        <Button onClick={props.backClick}>{t(`${locizeKey}:back`)}</Button>
        <Button onClick={submitHandler} type={'primary'}>
          {t(`${locizeKey}:next`)}
        </Button>
      </Flex>
    </Flex>
  );
};
export default SelectProductsStepComponent;
