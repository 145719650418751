import React from 'react';
import { Modal } from 'antd';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Button from 'components/ui/Button/Button';

import styles from './ClientRequestTagsWidget.module.scss';

type DeleteTagModalProps = {
  handleClose: () => void;
  handleTagDelete: () => void;
  isOpen: boolean;
  locizeKey: string;
};

const DeleteTagModal = (props: DeleteTagModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={props.isOpen}
      title={t(`${props.locizeKey}:deleteTagTitle`)}
      onCancel={props.handleClose}
      closable={false}
      footer={[
        <Button
          key="cancel"
          type="default"
          onClick={props.handleClose}
          className={clsx(styles.deleteTagBtn, styles.deleteTagCancelBtn)}
        >
          {t('common:buttons:cancel')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          danger
          onClick={() => {
            props.handleClose();
            props.handleTagDelete();
          }}
          className={styles.deleteTagBtn}
        >
          {t(`${props.locizeKey}:deleteTagConfirm`)}
        </Button>,
      ]}
    >
      <p>{t(`${props.locizeKey}:deleteTagContent`)}</p>
    </Modal>
  );
};

export default DeleteTagModal;
