import { IUserObject } from 'store/auth/auth.types';
import { ClientRequestState, IClientRequest } from 'store/client-request/client-request.types';
import { IContactPerson } from 'store/contact-person/contact-person.types';
import { IOffer } from 'store/offer/offer.types';

enum CustomerSteps {
  Wallbox = 'wallbox',
  Questionnaire = 'questionnaire',
  Request = 'request',
  HomecheckOfferSelection = 'homeCheckOfferSelection',
  HomecheckAppointmentSelection = 'homeCheckAppointmentSelection',
  HomecheckScheduled = 'homeCheckScheduled',
  HomecheckInvoicePayments = 'homecheckInvoicePayments',
  InstallationOfferSelection = 'installationOfferSelection',
  InstallationAppointmentSelection = 'installationAppointmentSelection',
  InstallationScheduled = 'installationScheduled',
  InstallationInvoicePayments = 'installationInvoicePayments',
  Feedback = 'feedback',
}

export const FlowSteps = [
  { step: 0, key: CustomerSteps.Wallbox },
  { step: 1, key: CustomerSteps.Questionnaire },
  { step: 2, key: CustomerSteps.Request },
  { step: 3, key: CustomerSteps.HomecheckOfferSelection },
  { step: 4, key: CustomerSteps.HomecheckAppointmentSelection },
  { step: 5, key: CustomerSteps.HomecheckScheduled },
  { step: 6, key: CustomerSteps.InstallationOfferSelection },
  { step: 7, key: CustomerSteps.InstallationAppointmentSelection },
  { step: 8, key: CustomerSteps.InstallationScheduled },
  { step: 9, key: CustomerSteps.InstallationInvoicePayments },
  { step: 10, key: CustomerSteps.Feedback },
];

type IPrice = {
  gross?: number;
  vat: Record<number, number>;
  net: number;
};

export type IHardwareProduct = {
  title: string;
  id?: string;
  mediaUrl?: string;
  totalPrice: number;
  netPrice: number;
};

export interface IHardwarePrices {
  gross: number;
  net: number;
  products: IHardwareProduct[];
}

interface ICostEstimationPrices extends IPrice {
  hardware?: IHardwarePrices;
  currencySymbol?: string;
  installation?: Pick<IPrice, 'gross' | 'net'>;
  homeCheck?: Pick<IPrice, 'gross' | 'net'>;
}

export interface IRequestDashboardConfig {
  step: number;
  request?: Partial<IClientRequest>;
  offers?: IOffer[];
  installerUser?: IUserObject;
  contactPerson?: IContactPerson;
  prices?: ICostEstimationPrices;
  requestState?: ClientRequestState;
  offer?: IOffer | null;
}

export const OfferSelectionSteps = [
  {
    key: 'offersList',
    title: 'stepper:customer:offersList:title',
  },
  {
    key: 'offerOverview',
    title: 'stepper:customer:offerOverview:title',
  },
  {
    key: 'offerAccept',
    title: 'stepper:customer:offerAccept:title',
  },
];
