import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/lib/typography';
import manOnStairs from 'assets/images/man-on-stairs.svg';
import NextButton from 'components/ui/NextButton/NextButton';
import { setSubStepsHidden } from 'store/app/app.actions';
import styles from './CostWelcomeStep.module.sass';

interface ICostWelcomePageProps {
  isLoading: boolean;
  onNextClick?(): void;
}

const CostWelcomeStep: React.FC<ICostWelcomePageProps> = ({ isLoading, onNextClick }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect((): any => {
    dispatch(setSubStepsHidden(true));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Typography.Title level={3} className={styles.title}>
          {t('customerFlow:costCalculations:welcomeTitle')}
        </Typography.Title>
        <Typography.Paragraph className={styles.text}>
          {t('customerFlow:costCalculations:welcomeText1')}
        </Typography.Paragraph>
        <NextButton className={styles.link} onClick={onNextClick} loading={isLoading}>
          {t('customerFlow:costCalculations:hereWeGo')}
        </NextButton>
      </div>
      <img className={styles.manImage} src={manOnStairs} alt="Man going up on the stairs" />
    </div>
  );
};

export default CostWelcomeStep;
