/* eslint-disable react/display-name */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import DeleteAccountModal from 'components/modals/DeleteAccountModal';
import Tag from 'components/ui/Tag/Tag';
import OfferTag from 'components/ui/OfferTag/OfferTag';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import Loader from 'components/ui/Loader/Loader';
import { OfferState } from 'store/offer/offer.types';
import { ClientRequestState, IClientRequest } from 'store/client-request/client-request.types';
import { clientRequestArchive } from 'store/client-request/client-request.actions';
import { formatPriceRange } from 'utils/currencyUtils';
import styles from '../Profile.module.sass';

export interface IProfileProps {
  clientRequests: any;
}

const RequestsTab: React.FC<IProfileProps> = ({ clientRequests }) => {
  const dispatch = useDispatch();
  const [isArchiveModal, setIsArchiveModal] = useState(false);
  const [clientRequest, setClientRequest] = useState<IClientRequest>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!clientRequests) {
    return <Loader />;
  }

  const CRTableColumns: ColumnsType<IClientRequest> = [
    {
      title: 'installerFlow:dashboard:id',
      dataIndex: 'displayId',
      key: 'displayId',
    },
    {
      title: 'installerFlow:dashboard:period',
      dataIndex: 'period',
      key: 'period',
      render: (text: string, row: IClientRequest, index: Number) =>
        row?.soonestPossible ? (
          t('installerFlow:dashboard:soonest')
        ) : (
          <span>
            {t('installerFlow:dashboard:from')}
            <DateFormat date={row.startDate} />
          </span>
        ),
    },
    {
      title: 'installerFlow:dashboard:priceRange',
      dataIndex: 'priceRange',
      key: 'priceRange',
      render: (text: string, row: IClientRequest, index: Number) => formatPriceRange(row),
    },
    {
      title: 'installerFlow:dashboard:status',
      dataIndex: 'state',
      key: 'state',
      render: renderStatus,
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      width: '5%',
      align: 'right',
      render: archiveAction,
    },
  ];

  function renderStatus(state: OfferState, row: IClientRequest) {
    const offerTag =
      state && state !== OfferState.None ? <OfferTag state={state} /> : <Tag>Unprocessed</Tag>;
    return (
      <div>
        {offerTag}
        {row.archived && <Tag>Archived</Tag>}
      </div>
    );
  }

  function archiveAction(_: string, row: IClientRequest) {
    return (
      <Button
        type="text"
        icon={<DeleteOutlined />}
        disabled={!row.archivable || row.archived}
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          setIsArchiveModal(true);
          setClientRequest(row);
        }}
        danger
      />
    );
  }

  const archiveRequest = () => {
    if (clientRequest?.id) {
      dispatch(clientRequestArchive(clientRequest.id, !clientRequest.archived));
    }
    setIsArchiveModal(false);
  };

  const onRowClick = (record: IClientRequest) => {
    switch (record.state) {
      case ClientRequestState.Done:
      case ClientRequestState.ApprovalPending:
      case ClientRequestState.Approved:
      case ClientRequestState.InstallationPending:
      case ClientRequestState.HomeCheck:
      case ClientRequestState.OfferSelectionHomeCheck:
      case ClientRequestState.OfferSelectionInstallation:
        return {
          onClick: (e: any) => {
            navigate(`/profile/client-request/${record.id}`);
          },
        };
      default:
        return {};
    }
  };

  const archiveModalProps = {
    visible: isArchiveModal,
    onOk: archiveRequest,
    onCancel: () => setIsArchiveModal(false),
    okText: t('intermediate:myProjects:archive:button:ok'),
    cancelText: t('intermediate:myProjects:archive:button:cancel'),
    modalText: t('intermediate:myProjects:archive:innerText'),
  };

  return (
    <>
      <h2 className={styles.title}>{t('profile:myRequests')}</h2>
      <div className={clsx([styles.container, styles.tablePadding])}>
        <Table
          columns={CRTableColumns.map((tender: any) => ({
            ...tender,
            title: t(`${tender.title}`),
          }))}
          dataSource={clientRequests}
          rowKey="id"
          pagination={false}
          onRow={onRowClick}
        />
      </div>
      <DeleteAccountModal {...archiveModalProps} />
    </>
  );
};

export default RequestsTab;
