import React from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'antd/lib/form';
import InstallPeriodQuestion from 'components/questions/InstallPeriodQuestion/InstallPeriodQuestion';
import { SCROLL_CONFIG } from 'framework/constants';
import styles from 'pages/client-request/ClientRequestPage/ClientRequestPage.module.sass';
import { ClientRequestFields } from 'store/client-request/client-request.types';

interface IStepProps {
  form?: any;
}

const AvailabilityStep: React.FC<IStepProps> = (props) => {
  const { t } = useTranslation();
  const questionWeekdaysRef = React.useRef<HTMLDivElement>(null);

  return (
    <Form className={styles.questionForm} form={props.form} name="request-availability-form">
      <Form.Item
        name={ClientRequestFields.Availability}
        rules={[
          {
            validator: async (rule, value) => {
              // One of value required
              if (
                !value ||
                !(
                  Boolean(value[ClientRequestFields.SoonestPossible]) ||
                  Boolean(value[ClientRequestFields.StartDate])
                )
              ) {
                throw new Error();
              }
            },
            message: t('customerFlow:questions:periodOfTime:error'),
          },
        ]}
      >
        <InstallPeriodQuestion
          onChange={(value: any) => {
            if (value && (value?.soonestPossible || value?.startDate)) {
              questionWeekdaysRef?.current?.scrollIntoView(SCROLL_CONFIG);
            }
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default AvailabilityStep;
