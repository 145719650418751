import React, { ReactNode, useContext, useMemo } from 'react';
import { Collapse } from 'antd';

import { useTranslation } from 'react-i18next';
import { CustomerDashboardContext } from 'context/customerDashboard.context';

import { formatPrice } from 'utils/currencyUtils';
import styles from './CostOverview.module.sass';
import { PricingTypes } from '../widget.types';

const CostOverview = () => {
  const { prices } = useContext(CustomerDashboardContext);
  const { t } = useTranslation();

  const totalPriceComponent = (type: PricingTypes) =>
    prices?.[type] ? (
      <div className={styles.totalPrice}>
        <span>{t(`customerFlow:dashboard:costOverview:${type}:title`)}</span>
        <span className={styles[type]}>
          {formatPrice(prices?.[type]?.net)}
          {prices?.currencySymbol}
        </span>
      </div>
    ) : null;

  const renderHardwareView = () => (
    <>
      {prices?.hardware?.products?.map((product: any) => (
        <div key={product.id} className={styles.hardwareItem}>
          {product.mediaUrl && (
            <img className={styles.coverImage} src={product.mediaUrl} alt={'hardware product'} />
          )}
          <span className={styles.title}>{product.title}</span>
          <span className={styles.price}>
            {formatPrice(product.netPrice)}
            {prices.currencySymbol}
          </span>
        </div>
      ))}
    </>
  );
  const collapseItems = useMemo(() => {
    const items: { key: number; label: ReactNode; showArrow?: boolean; children?: ReactNode }[] = [
      {
        key: 1,
        label: totalPriceComponent(PricingTypes.Hardware),
        children: renderHardwareView(),
      },
    ];
    // if (prices?.homeCheck?.gross) {
    //   items.push({
    //     key: 2,
    //     label: totalPriceComponent(PricingTypes.HomeCheck),
    //     showArrow: false,
    //   });
    // }
    // if (prices?.installation?.gross) {
    //   items.push({
    //     key: 3,
    //     label: totalPriceComponent(PricingTypes.Installation),
    //     showArrow: false,
    //   });
    // }
    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prices]);

  return (
    <div className={styles.container}>
      <div className={styles.totalSection}>
        <span className={styles.gross}>
          {t('customerFlow:dashboard:costOverview:gross', {
            gross: formatPrice(prices?.gross),
            currencySymbol: prices?.currencySymbol,
          })}
        </span>
        {prices?.vat &&
          Object.keys(prices?.vat).map((vat) => (
            <div key={vat} className={styles.description}>
              {t('customerFlow:dashboard:costOverview:vat', {
                vat,
                vatPrice: formatPrice(prices.vat[+vat].toFixed(2)),
                currencySymbol: prices?.currencySymbol,
              })}
            </div>
          ))}
        <span className={styles.net}>
          {t('customerFlow:dashboard:costOverview:net', {
            net: formatPrice(prices?.net),
            currencySymbol: prices?.currencySymbol,
          })}
        </span>
      </div>
      <div>
        <Collapse
          bordered={false}
          items={collapseItems}
          expandIconPosition="end"
          collapsible="icon"
        />
      </div>
    </div>
  );
};

export default CostOverview;
