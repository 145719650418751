import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Loader from 'components/ui/Loader/Loader';

import FotoWizardWebView from 'components/steps/FotoWizardWebView/FotoWizardWebView';
import CustomerLayout from 'components/layout/CustomerLayout/CustomerLayout';
import NotFound from 'pages/NotFound/NotFound';
import FAQPage from 'pages/FAQPage/FAQPage';
import InstallerVideoCall from 'pages/VideoCall/InstallerVideoCall/InstallerVideoCall';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import Home from 'pages/Home/Home';
import LeadPage from 'pages/LeadPage/LeadPage';
import { OrderAccepted } from 'pages/order/Accepted/Accepted';
import { DownloadOrder } from 'pages/order/OrderDownload/Download';
import LegalPage from 'pages/LegalPage/LegalPage';
import TermsPage from 'pages/TermsPage/TermsPage';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage/PrivacyPolicyPage';
import LoginPage from 'pages/auth/LoginPage/LoginPage';
import TokenLoginPage from 'pages/TokenLoginPage/TokenLoginPage';
import RegistrationLinkPage from 'pages/RegistrationLinkPage/RegistrationLinkPage';
import InstallerRegistrationPage from 'pages/installer/InstallerRegistrationPage/InstallerRegistrationPage';
import EmailVerifyPage from 'pages/EmailVerifyPage/EmailVerifyPage';
import EmailChangePage from 'pages/EmailChangePage/EmailChangePage';
import RegistrationPage from 'pages/auth/CustomerRegistration/RegistrationPage';
import PasswordResetPage from 'pages/auth/PasswordResetPage/PasswordResetPage';
import PasswordResetConfirmPage from 'pages/auth/PasswordResetConfirmPage/PasswordResetConfirmPage';
import CustomerDashboard from 'pages/customer/Dashboard/CustomerDashboard';
import AnswersReviewPage from 'pages/client-request/AnswersReview/AnswersReviewPage';
import DashboardPage from 'pages/installer/DashboardPage/DashboardPage';
import TenderDetailsPage from 'pages/installer/TenderDetailsPage/TenderDetailsPage';
import OfferTypePage from 'pages/installer/OfferTypePage/OfferTypePage';
import ParticipatePage from 'pages/installer/ParticipatePage/ParticipatePage';
import TenderParticipateHomeCheckPage from 'pages/installer/TenderParticipatePage/TenderParticipateHomeCheckPage';
import TenderAppointmentPage from 'pages/installer/TenderAppointmentPage/TenderAppointmentPage';
import OrderDetailsPage from 'pages/installer/OrderDetailsPage/OrderDetailsPage';
import TenderDocumentationPage from 'pages/installer/TenderDocumentationPage/TenderDocumentationPage';
import JoinPoolPage from 'pages/installer/JoinPoolPage/JoinPoolPage';
import UnderReviewPage from 'pages/installer/UnderReviewPage/UnderReviewPage';
import Profile from 'pages/Profile/Profile';
import EditProfile from 'pages/Profile/EditProfile';
import { ProductWizard } from 'pages/client-request/Questionnaire/QuestionnaireSteps/ProductWizard/ProductWizard';
import ClientRequestPage from 'pages/client-request/ClientRequestPage/ClientRequestPage';
import OperatorClinetRequestPage from 'pages/MyProjects/ClientRequestPage/ClientRequestPage';
import OfferPage from 'pages/client-request/OfferPage/OfferPage';
import OfferQuestionPage from 'pages/OfferQuestionPage/OfferQuestionPage';
import InstallationPage from 'pages/client-request/InstallationPage/InstallationPage';
import SurveyPage from 'pages/survey/SurveyPage/SurveyPage';
import OperatorSurveyPage from 'pages/SurveyPage/SurveyPage';
import CustomerVideoCall from 'pages/VideoCall/CustomerVideoCall/CustomerVideoCall';
import MyProjects from 'pages/MyProjects/MyProjects';
import EditPool from 'pages/MyProjects/EditPool/EditPool';
import CreatePool from 'pages/MyProjects/CreatePool/CreatePool';
import TenderViewPage from 'pages/installer/TenderViewPage/TenderViewPage';
import TenderParticipationPage from 'pages/installer/TenderParticipationPage/TenderParticipationPage';
import Questionnaire from 'pages/client-request/Questionnaire/Questionnaire';
import EditProductsPage from 'pages/MyProjects/ClientRequestPage/EditProductsModal/EditProductsPage';
import ProfileClientRequestPage from './pages/Profile/ClientRequestPage/ClientRequestPage';
import OrganizationPage from './pages/installer/OrganizationPage/OrganizationPage';
import InvoicesPage from './pages/InvoicesPage/InvoicesPage';
import PoolInvitesPage from './pages/installer/PoolInvitesPage/PoolInvitesPage';
import WelcomePage from './pages/WelcomePage/WelcomePage';
import PrivateRoute from './components/containers/PrivateRoute';
import App from './App';
import VideoCallLayout from './components/layout/VideoCallLayout/VideoCallLayout';
import DefaultLayout from './components/layout/DefaultLayout/DefaultLayout';
import EditCustomerRequestProductsPage from './pages/customer/Dashboard/ProductEdit/EditRequestProducts';
import OfferDocumentGenerator from './pages/MyProjects/ClientRequestPage/OfferDocumentGenerator/OfferDocumentGenerator';

export default function AppRouter() {
  return (
    <Router>
      <Suspense
        fallback={
          <div style={{ textAlign: 'center', marginTop: '20vh' }}>
            <Loader />
          </div>
        }
      >
        <App>
          <Routes>
            <Route path="/" element={<DefaultLayout />}>
              <Route index element={<WelcomePage />} />
              <Route path="*" element={<NotFound />} />
              <Route path="error" element={<ErrorPage />} />
              <Route path="home" element={<Home />} />
              <Route path="order/:id/accept" element={<OrderAccepted />} />
              <Route
                path="client-request/:id/documents/download/:token"
                element={<DownloadOrder />}
              />
              <Route path="/home-check/customer/:meetingId" element={<CustomerVideoCall />} />
              <Route path="legal">
                <Route path="legal" element={<LegalPage />} />
                <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="terms-and-conditions" element={<TermsPage />} />
              </Route>
              <Route path="login">
                <Route index element={<LoginPage />} />
                <Route path="token/:token" element={<TokenLoginPage />} />
              </Route>
              <Route path="registration/:id" element={<RegistrationLinkPage />} />
              <Route path="installer/register/:token?" element={<InstallerRegistrationPage />} />
              <Route path="password-reset">
                <Route index element={<Navigate to="/customer/password-reset" />} />
                <Route path=":token/:userId?" element={<PasswordResetConfirmPage />} />
              </Route>
              <Route path="change-email" element={<EmailChangePage />} />
              <Route path="verify-email" element={<EmailVerifyPage />} />
              <Route path="customer">
                <Route index element={<Navigate to="/customer/dashboard" />} />
                <Route path="register" element={<RegistrationPage />} />
                <Route path="password-reset/*" element={<PasswordResetPage />} />
                <Route element={<PrivateRoute />}>
                  <Route path="dashboard" element={<CustomerDashboard />} />
                  <Route path="edit-products" element={<EditCustomerRequestProductsPage />} />

                  <Route path="tender/:id">
                    <Route path="view/*" element={<TenderViewPage />} />
                    <Route path="participate/*" element={<TenderParticipationPage />} />
                  </Route>
                  <Route
                    path="client-request/:id"
                    element={<Navigate to="/customer/dashboard" />}
                  />
                </Route>
              </Route>
              <Route path="/" element={<PrivateRoute />}>
                <Route path="installer">
                  <Route path="dashboard" element={<DashboardPage />} />
                  <Route path="tender/:id">
                    <Route index element={<TenderDetailsPage />} />
                    <Route path="offer-type" element={<OfferTypePage />} />
                    <Route path="participate/directly" element={<ParticipatePage />} />
                    <Route
                      path="participate/home-check"
                      element={<TenderParticipateHomeCheckPage />}
                    />
                  </Route>
                  <Route path="order/:id">
                    <Route path="appointment" element={<TenderAppointmentPage />} />
                    <Route path="details/:backPage?" element={<OrderDetailsPage />} />
                    <Route path="documentation" element={<TenderDocumentationPage />} />
                  </Route>
                  <Route
                    path="order/:offerId/make-offer"
                    element={<ParticipatePage isHomeCheckDone />}
                  />
                  <Route path="join/:token" element={<JoinPoolPage />} />
                  <Route path="under-review" element={<UnderReviewPage />} />
                </Route>
                <Route path="my-projects">
                  <Route index element={<MyProjects />} />
                  <Route path=":poolId/:activeTab/" element={<EditPool isNew={false} />} />
                  <Route
                    path=":id/:activeTab/product/:productId"
                    element={<EditPool isNew={false} />}
                  />
                  <Route path="create/*" element={<CreatePool />} />
                  <Route path="survey/:id" element={<OperatorSurveyPage />} />
                  <Route path="client-request/:id" element={<OperatorClinetRequestPage />} />
                  <Route
                    path="client-request/:requestId/offer/:offerId/document"
                    element={<OfferDocumentGenerator />}
                  />
                  <Route
                    path="client-request/:requestID/edit-products"
                    element={<EditProductsPage />}
                  />
                </Route>
                <Route path="invitations" element={<PoolInvitesPage />} />
                <Route path="invoices" element={<InvoicesPage />} />
                <Route path="info" element={<Navigate to="/faq" />} />
                <Route path="faq" element={<FAQPage />} />
                <Route path="organization" element={<OrganizationPage />} />
                <Route path="profile">
                  <Route index element={<Profile />} />
                  <Route path="edit" element={<EditProfile />} />
                  <Route path="client-request/:id" element={<ProfileClientRequestPage />} />
                </Route>
              </Route>
            </Route>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/customer/client-request/:id" element={<CustomerLayout />}>
                <Route index element={<Navigate to="/customer/dashboard" />} />
                <Route path="review" element={<AnswersReviewPage />} />
                <Route path="wallbox" element={<ProductWizard />} />
                <Route path="request" element={<ClientRequestPage />} />
                <Route path="questionnaire" element={<Questionnaire />} />
                <Route path="offer" element={<OfferPage />} />
                <Route path="offer-question/:questionId" element={<OfferQuestionPage />} />
                <Route path="installation" element={<InstallationPage />} />
              </Route>
              <Route path="/home-check/installer" element={<VideoCallLayout />}>
                <Route path=":meetingId" element={<InstallerVideoCall />} />
              </Route>
            </Route>
            <Route path="lead" element={<LeadPage />} />
            <Route path="survey/:poolId" element={<SurveyPage />} />
            <Route path="/fotoWizard/:id/:token" element={<FotoWizardWebView />} />
          </Routes>
        </App>
      </Suspense>
    </Router>
  );
}
