import { TSupportedLanguagesKey } from './language.types';

export enum PoolSettingsFieldsType {
  Name = 'name',
  PoolType = 'poolType',
  IsSurvey = 'isSurvey',
  IsParent = 'isParent',
  ParentId = 'parentId',
  RequireRequestConfirmation = 'requireRequestConfirmation',
  HomeCheckOfferApproval = 'homeCheckOfferApproval',
  InstallationOfferApproval = 'installationOfferApproval',
  InstallationInvoiceApproval = 'installationInvoiceApproval',
  // [TODO]: Remove countries
  Countries = 'countries',
  Country = 'country',
  RequestValidationSelectionMode = 'requestValidationSelectionMode',
  RequestValidationDays = 'requestValidationDays',
  RequestValidity = 'requestValidity',
  OfferValidity = 'offerValidity',
  OfferValidationDays = 'offerValidationDays',
  OfferMinimumValidity = 'offerMinimumValidity',
  InstallationSelectionMode = 'installationSelectionMode',
  AppointmentFrom = 'appointmentFrom',
  AppointmentUntil = 'appointmentUntil',
  DeactivateQuickCheck = 'deactivateQuickCheck',
  CostCalculationMode = 'costCalculationMode',
  CostEstimateMin = 'costEstimateMin',
  CostEstimateMax = 'costEstimateMax',
  WizardInstallationJourney = 'wizardInstallationJourney',
  WizardWallboxJourney = 'wizardWallboxJourney',
  DocumentationMode = 'documentationMode',
  DocumentsAllowed = 'documentsAllowed',
  ServiceFeeCost = 'serviceFeeCost',
}

export enum CostCalculationModeOptionsType {
  Manual = 'manual',
  Range = 'range',
  Unrestricted = 'unrestricted',
  Deactivated = 'deactivated',
}
export enum RequestValidationOptionsType {
  Manual = 'manual',
  Default = 'unlimited',
}
export enum InstallationSelectionModeOptionsType {
  Manual = 'manual',
  Range = 'range',
  Unrestricted = 'unrestricted',
}

export enum DocumentationModeOptionsType {
  Automatic = 'automatic',
  Manual = 'manual',
}

export enum PoolType {
  RequestPool = 'requestPool',
  SurveyPool = 'surveyPool',
}

export const CustomDocumentCategory = {
  termsConditions: 'pool_terms_conditions',
  dataPrivacy: 'pool_privacy_policy',
  cancelationPolicy: 'pool_cancelation_policy',
  imprint: 'pool_imprint',
};

interface ISection {
  id?: string | null;
  text?: string | null;
  link?: string | null;
}
type TLanguageBasedSection = {
  [language in TSupportedLanguagesKey]?: ISection;
};
export interface ILegalForm {
  termsConditions: TLanguageBasedSection;
  dataPrivacy: TLanguageBasedSection;
  cancelationPolicy: TLanguageBasedSection;
  imprint: TLanguageBasedSection;
}
