import config from 'config';
import HttpService from './http.service';

class CountryAPI extends HttpService {
  getCountries = () => {
    return this.get(`countries`);
  };
}

export default new CountryAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
