import { createSelector } from 'reselect';
import { IDocumentState } from './document.types';

export const documentSelector = (state: any) => state.document;

// Get Generic File
export const genericFileSelector = createSelector(
  documentSelector,
  (document: IDocumentState) => document.file,
);

// Generic File State
export const genericFileStateSelector = createSelector(
  documentSelector,
  (document: IDocumentState) => document.fileState,
);

// Generic File Erorr
export const genericFileErrorSelector = createSelector(
  documentSelector,
  (document: IDocumentState) => document.fileError,
);
