import React from 'react';
import { default as Modal, ModalProps } from 'antd/lib/modal';
import Typography from 'antd/lib/typography';
import Button, { ButtonColor } from 'components/ui/Button/Button';

export interface ITermsOfUseProps extends ModalProps {
  onOk: () => void;
  onCancel: () => void;
}

const TenderParticipationModal: React.FC<ITermsOfUseProps> = ({ onCancel, onOk, ...rest }) => {
  return (
    <Modal
      {...rest}
      footer={[
        <Button
          key="revokeParticipation"
          color={ButtonColor.WHITE}
          onClick={() => onCancel()}
          type="primary"
        >
          Revoke Participation
        </Button>,
        <Button
          key="agreeParticipation"
          color={ButtonColor.BLUE}
          onClick={() => onOk()}
          type="primary"
        >
          Agree
        </Button>,
      ]}
    >
      <Typography.Title>You take part in tender</Typography.Title>
      <p>We are pleased that you are participating in the tender.</p>
      <p>
        You hereby agree to make an offer in the specified price range without prior home check,
        provided that the information provided by the customer is correct.{' '}
      </p>
      <p>
        In the next few hours, all participants in this tender will be sighted by our team. If you
        get the promise, you will be asked to send your offer to the customer.
      </p>
      <p>You can revoke your participation as long as the advertisement is open.</p>
    </Modal>
  );
};

export default TenderParticipationModal;
