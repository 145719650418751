import React from 'react';
import { Col, FormInstance, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { currentPool } from 'store/intermediate/intermediate.selectors';
import { Status, Organization, Media, Product, Pricing } from './form-sections';
import styles from '../Products.module.sass';

interface IFormBodyProps {
  form?: FormInstance;
}

export const FormBody: React.FC<IFormBodyProps> = ({ form }) => {
  const { t } = useTranslation();
  const pool = useSelector(currentPool);

  const sections = [
    { title: 'productStatus', component: <Status /> },
    { title: 'productOrganization', component: <Organization pool={pool} form={form} /> },
    { title: 'media', component: <Media form={form} /> },
    { title: 'product', component: <Product form={form} /> },
    { title: 'pricing', component: <Pricing form={form} pool={pool} /> },
  ];

  return (
    <>
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={6}>
          {sections.slice(0, 2).map((section) => (
            <div className={styles.section} key={section.title}>
              <Typography.Title level={5}>
                {t(`intermediate:myProjects:product:form:title:${section.title}`)}
              </Typography.Title>
              {section.component}
            </div>
          ))}
        </Col>
        <Col span={17}>
          {sections.slice(2).map((section) => (
            <div className={styles.section} key={section.title}>
              <Typography.Title level={5}>
                {t(`intermediate:myProjects:product:form:title:${section.title}`)}
              </Typography.Title>
              {section.component}
            </div>
          ))}
        </Col>
      </Row>
    </>
  );
};
