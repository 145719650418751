import React, { useEffect, useState } from 'react';
import { Form, FormInstance } from 'antd';
import { useDispatch } from 'react-redux';
import DropZone from 'components/ui/DropZone/DropZone';
import FilesList from 'components/ui/FilesList/FilesList';
import { ValidationFactory } from 'framework/validations';
import { deleteFile } from 'store/client-request/client-request.actions';
import { IFile, MediaCategoryType } from 'store/client-request/client-request.types';
import { uploadGenericFile } from 'store/document/document.actions';

const fileCategory = MediaCategoryType.ProductWizard;

interface IMedia {
  form?: FormInstance;
}

const Media: React.FC<IMedia> = ({ form }) => {
  const dispatch = useDispatch();
  const [uploadedFile, setUploadedFile] = useState<any[]>([]);
  const [fileUploading, setFileUploading] = useState(false);

  useEffect(() => {
    const files = form?.getFieldValue('documents');
    if (files?.length) {
      setUploadedFile(files.map((file: any) => file.document));
    }
  }, [form]);

  const onFileAdd = (file: File) => {
    if (file) {
      setFileUploading(true);
      dispatch(
        uploadGenericFile({
          document: file,
          category: fileCategory,
          uploadSuccess: onUploadSuccess,
          uploadError: onUploadError,
        }),
      );
    }
  };

  const onUploadSuccess = (document: IFile) => {
    setFileUploading(false);
    setUploadedFile([document]);
    const values = form?.getFieldsValue();
    form?.setFieldsValue({
      ...values,
      documents: [{ document: { id: document.id } }],
    });
  };

  const onUploadError = (error: any) => {
    setFileUploading(false);
  };

  const onDeleteFile = (fileName: string, fileId: string) => {
    dispatch(deleteFile(fileName, fileId, fileCategory));
    setUploadedFile([]);
  };

  const acceptTypes = {
    'image/*': ['.jpeg', '.png', '.jpg', '.svg'],
    'application/pdf': ['.pdf'],
  };

  return (
    <Form.Item name="documents" rules={[ValidationFactory.REQUIRED]}>
      <div>
        <FilesList files={uploadedFile} onFileDelete={onDeleteFile} />
        <DropZone
          multiple={false}
          onFileDropAccepted={([file]: File[]) => onFileAdd(file)}
          loading={fileUploading}
          acceptedFiles="JPG, PDF, PNG, SVG. "
          accept={acceptTypes}
        />
      </div>
    </Form.Item>
  );
};

export default Media;
