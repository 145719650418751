import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import draftToHtml from 'draftjs-to-html';
import Loader from 'components/ui/Loader/Loader';
import useLanguageChangeCallback from 'hooks/useLaguageChangeCallback';
import { clientRequestsAllGet } from 'store/client-request/client-request.actions';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import { landingPageGet } from 'store/intermediate/intermediate.actions';
import { landingPageSelector } from 'store/intermediate/intermediate.selectors';
import { SlugCategoryType } from 'store/intermediate/intermediate.types';
import task from '../../assets/images/task.svg';
import styles from '../MyProjects/ClientRequestPage/ClientRequestPage.module.sass';

const FAQPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const clientRequest = useSelector(currentClientRequestSelector);
  const faqPage = useSelector(landingPageSelector);
  const faqPageContent = faqPage?.text && draftToHtml(JSON.parse(faqPage.text));
  const fetchFAQContent = useCallback(() => {
    if (clientRequest?.pool?.id) {
      dispatch(landingPageGet(clientRequest.pool.id, SlugCategoryType.UserFAQPage));
    }
  }, [clientRequest, dispatch]);
  useLanguageChangeCallback(fetchFAQContent);

  useEffect(() => {
    if (!clientRequest) {
      dispatch(clientRequestsAllGet());
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    fetchFAQContent();
  }, [fetchFAQContent]);

  const renderContent = () =>
    // by default empty content has empty tag <p> and length is 8
    faqPageContent && faqPageContent.length > 8 ? (
      <>
        {faqPage?.heading && <h2>{faqPage.heading}</h2>}
        {faqPageContent && <div dangerouslySetInnerHTML={{ __html: faqPageContent }} />}
      </>
    ) : (
      <div>
        <div className={styles.waitingOffers}>
          <img src={task} alt="Man with task" className={styles.waitingImg} />
          <p>{t('common:texts:noContent')}</p>
        </div>
      </div>
    );

  if (!clientRequest) {
    return <Loader />;
  }

  return <>{renderContent()}</>;
};

export default FAQPage;
