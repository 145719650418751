import { RequestState } from 'store/common.types';
import { RequestResult } from 'types/crud-view.types';

export interface IPDFTemplate {
  id: string;
  name: string;
  type: string;
  textBoxes: string[];
  file: File;
}

export interface IPDFTemplateState {
  PDFTemplateCreateState: RequestState | null;
  PDFTemplateCreateError: string | null;

  PDFTemplateUpdateState: RequestState | null;
  PDFTemplateUpdateError: string | null;

  PDFTemplateDeleteState: RequestState | null;
  PDFTemplateDeleteError: string | null;

  currentPDFTemplates: IPDFTemplate[];
  PDFTemplatesState: RequestState | null;
  PDFTemplatesError: string | null;
}

export const PDF_TEMPLATE_CREATE = 'PDF_TEMPLATE_CREATE';
export const PDF_TEMPLATE_CREATE_SUCCESS = 'PDF_TEMPLATE_CREATE_SUCCESS';
export const PDF_TEMPLATE_CREATE_ERROR = 'PDF_TEMPLATE_CREATE_ERROR';

export const PDF_TEMPLATE_UPDATE = 'PDF_TEMPLATE_UPDATE';
export const PDF_TEMPLATE_UPDATE_SUCCESS = 'PDF_TEMPLATE_UPDATE_SUCCESS';
export const PDF_TEMPLATE_UPDATE_ERROR = 'PDF_TEMPLATE_UPDATE_ERROR';

export const PDF_TEMPLATE_DELETE = 'PDF_TEMPLATE_DELETE';
export const PDF_TEMPLATE_DELETE_SUCCESS = 'PDF_TEMPLATE_DELETE_SUCCESS';
export const PDF_TEMPLATE_DELETE_ERROR = 'PDF_TEMPLATE_DELETE_ERROR';

export const PDF_TEMPLATES_GET = 'PDF_TEMPLATES_GET';
export const PDF_TEMPLATES_GET_SUCCESS = 'PDF_TEMPLATES_GET_SUCCESS';
export const PDF_TEMPLATES_GET_ERROR = 'PDF_TEMPLATES_GET_ERROR';

export interface PDFTemplateCreateAction {
  type: typeof PDF_TEMPLATE_CREATE;
  payload: {
    poolId: string;
    pdfTemplate: IPDFTemplate;
    onRequestDone?: (status: RequestResult) => void;
  };
}

export interface PDFTemplateCreateSuccessAction {
  type: typeof PDF_TEMPLATE_CREATE_SUCCESS;
  payload: IPDFTemplate;
}

export interface PDFTemplateCreateErrorAction {
  type: typeof PDF_TEMPLATE_CREATE_ERROR;
  payload: any;
}

export interface PDFTemplateUpdateAction {
  type: typeof PDF_TEMPLATE_UPDATE;
  payload: {
    id: string;
    body: IPDFTemplate;
    onRequestDone?: (status: RequestResult) => void;
  };
}

export interface PDFTemplateUpdateSuccessAction {
  type: typeof PDF_TEMPLATE_UPDATE_SUCCESS;
  payload: IPDFTemplate;
}

export interface PDFTemplateUpdateErrorAction {
  type: typeof PDF_TEMPLATE_UPDATE_ERROR;
  payload: any;
}

export interface PDFTemplateDeleteAction {
  type: typeof PDF_TEMPLATE_DELETE;
  payload: { id: string; onRequestDone?: (status: RequestResult) => void };
}

export interface PDFTemplateDeleteSuccessAction {
  type: typeof PDF_TEMPLATE_DELETE_SUCCESS;
  payload: string;
}

export interface PDFTemplateDeleteErrorAction {
  type: typeof PDF_TEMPLATE_DELETE_ERROR;
  payload: any;
}

export interface PDFTemplatesGetAction {
  type: typeof PDF_TEMPLATES_GET;
  payload: string;
}

export interface PDFTemplatesGetSuccessAction {
  type: typeof PDF_TEMPLATES_GET_SUCCESS;
  payload: IPDFTemplate[];
}

export interface PDFTemplatesGetErrorAction {
  type: typeof PDF_TEMPLATES_GET_ERROR;
  payload: any;
}

export type PDFTemplateActionTypes =
  | PDFTemplateCreateAction
  | PDFTemplateCreateSuccessAction
  | PDFTemplateCreateErrorAction
  | PDFTemplateUpdateAction
  | PDFTemplateUpdateSuccessAction
  | PDFTemplateUpdateErrorAction
  | PDFTemplateDeleteAction
  | PDFTemplateDeleteSuccessAction
  | PDFTemplateDeleteErrorAction
  | PDFTemplatesGetAction
  | PDFTemplatesGetSuccessAction
  | PDFTemplatesGetErrorAction;
