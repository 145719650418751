import React, { ReactNode, useEffect, useCallback, useState } from 'react';
import { Button, Col, Modal, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import ToDoIcon from 'assets/icons/dashboard/ToDo.svg';

import RequestIcon from 'assets/icons/dashboard/ClientRequest.svg';
import DocumentsIcon from 'assets/icons/dashboard/Offer.svg';
import LinksIcon from 'assets/icons/dashboard/Links.svg';
import CostOverviewIcon from 'assets/icons/dashboard/Money.svg';
import ContactPersonIcon from 'assets/icons/dashboard/Support.svg';
import InstallerIcon from 'assets/icons/dashboard/Installer.svg';
import DownloadableDocumentList from 'components/ui/DownloadableDocumentList';
import { CustomerDashboardContext } from 'context/customerDashboard.context';

import useIsMobile from 'hooks/useIsModible';
import useIsCustomerAllowedToEditRequestProducts from 'hooks/useIsCustomerAllowedToEditRequestProducts';
import { OfferState } from 'store/offer/offer.types';
import {
  currentClientRequestSelector,
  invoicesSelector,
} from 'store/client-request/client-request.selectors';
import { userSelector } from 'store/auth/auth.selectors';
import { ClientRequestState } from 'store/client-request/client-request.types';
import { currentOfferSelector } from 'store/offer/offer.selectors';
import { feedbackListSelector } from 'store/feedback/feedback.selectors';
import { invoicesGet } from 'store/client-request/client-request.actions';
import OfferSelection from './components/OfferSelection/OfferSelection';
import styles from './CustomerDashboard.module.sass';
import {
  ContactPerson,
  CostOverview,
  Installer,
  NextStep,
  Progress,
  ToDo,
  Request,
  ImportantLinks,
} from './Widgets';
import {
  DashboardWidgets,
  ModalType,
  OfferActionRequiredState,
  UntitledWidgets,
} from './Widgets/widget.types';
import { Container } from './components';
import { useDashboard } from './useDashboard';
import FeedbackPage from './components/FeedbackPage/FeedbackPage';

interface IArticle {
  span: { [key: string]: number | undefined };
  item: React.JSX.Element;
  key: DashboardWidgets;
  icon?: string;
  hidden?: boolean;
  className?: string;
  headingExtra?: ReactNode;
}

const CustomerDashboard = () => {
  const request = useSelector(currentClientRequestSelector);
  const isCustomerAllowedToEditProducts = useIsCustomerAllowedToEditRequestProducts();
  const offer = useSelector(currentOfferSelector);
  const feedbackList = useSelector(feedbackListSelector);
  const { invoicesState, invoices } = useSelector(invoicesSelector);
  const { t } = useTranslation();
  const user = useSelector(userSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modal, setModal] = useState<ModalType>(ModalType.None);

  const isMobile = useIsMobile();

  const { config } = useDashboard();

  useEffect(() => {
    dispatch(invoicesGet());
  }, [dispatch]);

  const handleContinueClick = useCallback(() => {
    switch (request?.state) {
      case ClientRequestState.Initiated:
      case ClientRequestState.Submitted:
      case ClientRequestState.PendingDocument:
        if (request.step && request.step < 3) {
          const nextPage = request?.step === 1 ? 'wallbox' : 'questionnaire';
          navigate(`/customer/client-request/${request?.id}/${nextPage}`);
          break;
        }
        navigate(`/customer/client-request/${request?.id}/request`);
        break;
      case ClientRequestState.OfferSelectionHomeCheck:
      case ClientRequestState.OfferSelectionInstallation:
        {
          if (request?.offers?.some((offer) => OfferActionRequiredState.includes(offer.state!))) {
            setModal(ModalType.Offer);
          }
        }
        break;
      case ClientRequestState.HomeCheck:
        {
          const meetingId = offer?.meetingId;
          if (meetingId) navigate(`/home-check/customer/${meetingId}`);
        }
        break;
      case ClientRequestState.InstallationPending:
        {
          if (request?.offers?.some((offer) => offer.state === OfferState.OfferSubmitted)) {
            setModal(ModalType.Offer);
          }
        }
        break;
      case ClientRequestState.Done:
        {
          if (!feedbackList.length) {
            setModal(ModalType.Feedback);
          }
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, offer, navigate]);

  const isNextStepDisabled =
    [
      ClientRequestState.Cancelled,
      ClientRequestState.ApprovalPending,
      ClientRequestState.Approved,
      ClientRequestState.OrderSubmitted,
      ClientRequestState.PaymentPending,
    ].includes(request?.state!) ||
    ([
      ClientRequestState.OfferSelectionHomeCheck,
      ClientRequestState.OfferSelectionInstallation,
    ].includes(request?.state!) &&
      !request?.offers?.some((offer) => OfferActionRequiredState.includes(offer.state!))) ||
    (request?.state === ClientRequestState.HomeCheck && !offer?.meetingId) ||
    (request?.state === ClientRequestState.InstallationPending &&
      !request?.offers?.some((offer) => offer.state === OfferState.OfferSubmitted));

  const onEditProductsClick = useCallback(() => {
    navigate('../edit-products');
  }, [navigate]);

  const articles: IArticle[] = [
    {
      span: { xl: 6, lg: 7, md: 24 },
      item: <Progress onContinue={handleContinueClick} />,
      key: DashboardWidgets.Progress,
      className: styles.progress,
      hidden: ![ClientRequestState.Initiated, ClientRequestState.Submitted].includes(
        request?.state!,
      ),
    },
    {
      span: { lg: 16, md: 24 },
      item: (
        <NextStep
          onContinue={handleContinueClick}
          isDisabled={isNextStepDisabled}
          isDone={request?.state === ClientRequestState.Done && feedbackList.length > 0}
        />
      ),
      key: DashboardWidgets.NextSteps,
    },
    {
      span: { lg: 8, md: 12 },
      item: <ToDo />,
      icon: ToDoIcon,
      key: DashboardWidgets.ToDo,
    },
    {
      span: { lg: 8, md: 12 },
      item: (
        <DownloadableDocumentList
          docs={invoices?.map((invoice) => invoice.document)}
          docsState={invoicesState}
        />
      ),
      icon: DocumentsIcon,
      key: DashboardWidgets.Documents,
    },
    {
      span: { lg: 8 },
      item: <Request />,
      icon: RequestIcon,
      key: DashboardWidgets.Request,
      headingExtra: isCustomerAllowedToEditProducts && (
        <Button onClick={onEditProductsClick} shape="circle" icon={<EditOutlined />} size="small" />
      ),
    },
    {
      span: { lg: 8 },
      item: <CostOverview />,
      icon: CostOverviewIcon,
      key: DashboardWidgets.CostOverview,
      hidden: !config?.prices?.gross,
    },
    {
      span: { lg: 8 },
      item: <ContactPerson />,
      icon: ContactPersonIcon,
      key: DashboardWidgets.ContactPerson,
      hidden: !config?.contactPerson,
    },
    {
      span: { lg: 8 },
      item: <Installer />,
      icon: InstallerIcon,
      key: DashboardWidgets.Installer,
      hidden: !config.installerUser,
    },
    {
      span: { lg: 8 },
      item: <ImportantLinks />,
      icon: LinksIcon,
      key: DashboardWidgets.ImportantLinks,
      //for now hide important links as we don't have any implemented sections to add them
      hidden: true,
    },
  ];

  const getArticle = (article: IArticle) => {
    const { key, headingExtra, span, icon, item, hidden, className } = article;

    if (hidden) return null;

    const title = !UntitledWidgets.includes(key) ? t(`customerFlow:dashboard:${key}:header`) : '';

    return (
      <Container
        className={className}
        headingExtra={headingExtra}
        key={key}
        title={title}
        icon={icon}
        {...span}
      >
        {item}
      </Container>
    );
  };

  return (
    <CustomerDashboardContext.Provider value={config}>
      {request && (
        <div style={{ padding: '0 16px' }}>
          <Row gutter={[16, 16]}>
            <Col xl={18} lg={17}>
              <Typography.Title level={3}>
                {t(`customerFlow:dashboard:header:title`, {
                  name: `${user.firstName} ${user.lastName}`,
                })}
              </Typography.Title>
              <Typography.Text style={{ color: '#8E8E8E' }}>
                {t('customerFlow:dashboard:header:subtitle')}
              </Typography.Text>
            </Col>
            {articles.map((article) => getArticle(article))}
          </Row>
        </div>
      )}

      <Modal
        title={t(`customerFlow:dashboard:modal:${modal}:title`)}
        footer={null}
        width={isMobile ? '100%' : '80vw'}
        open={modal !== ModalType.None}
        onCancel={() => setModal(ModalType.None)}
      >
        {modal === ModalType.Offer && <OfferSelection onClose={() => setModal(ModalType.None)} />}
        {modal === ModalType.Feedback && (
          <FeedbackPage handleCloseModal={() => setModal(ModalType.None)} />
        )}
      </Modal>
    </CustomerDashboardContext.Provider>
  );
};
export default CustomerDashboard;
