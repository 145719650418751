import React from 'react';
import clsx from 'clsx';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import styles from './NumberInput.module.sass';
import FloatingLabel, { LabelPositionType } from '../FloatingLabel/FloatingLabel';

interface INumericInputProps {
  containerClassName?: string;
  className?: string;
  value?: string;
  label?: string;
  error?: string;
  decimalScale?: number;
  regularHeight?: boolean;
}

const NumericInput: React.FC<INumericInputProps & NumericFormatProps> = ({
  className,
  containerClassName,
  value,
  label,
  error,
  regularHeight = false,
  ...rest
}) => {
  const containerClassNames = clsx(styles.container, containerClassName, {
    [styles.invalid]: !!error,
  });

  const inputClassName = clsx('ant-input', className, styles.input, styles.green, {
    [styles.invalid]: !!error,
    [styles.noLabel]: !label && !regularHeight,
  });

  const inputProps = {
    ...rest,
    value,
    className: inputClassName,
    decimalSeparator: ',',
  };

  return (
    <div className={containerClassNames}>
      <FloatingLabel label={label!} value={value!} labelPosition={LabelPositionType.LEFT}>
        <NumericFormat {...inputProps} />
      </FloatingLabel>
      {error ? <span className={styles.error}>{error}</span> : null}
    </div>
  );
};

export default NumericInput;
