import React, { useEffect, useState } from 'react';
import { Form, Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'components/ui/Button/Button';
import { ICrudModalProps, EntityAction, FormLayout, RequestResult } from 'types/crud-view.types';

import styles from './CrudModal.module.sass';

export function CrudModal<T>(props: ICrudModalProps<T>) {
  const {
    onClose,
    locizeKey,
    action,
    onSubmit,
    formBody,
    dataToUpdate,
    setForm,
    onFormValuesChange,
    requiredMark = true,
    layout = FormLayout.HORIZONTAL,
    ...rest
  } = props;

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (action !== undefined) {
      dataToUpdate ? form.setFieldsValue(dataToUpdate) : form.resetFields();
    }
  }, [action, form, dataToUpdate]);

  useEffect(() => {
    if (setForm) {
      setForm(form);
    }
  }, [form, setForm]);

  const onModalClose = () => {
    setLoading(false);
    onClose();
  };

  const onRequestDone = (requestStatus: RequestResult) => {
    onModalClose();
    if (requestStatus === RequestResult.SUCCESS) {
      notification.success({ message: t(`${locizeKey}:${action}:success`) });
    }
  };

  const modalProps = {
    footer: null,
    centered: true,
    onCancel: onModalClose,
    ...rest,
  };

  const submit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        const data = action === EntityAction.Create ? values : { ...dataToUpdate, ...values };
        if (action) {
          onSubmit(action, data, onRequestDone);
        }
      })
      // If we don't add a catch, the error will be thrown to the app, and the page will go blank.
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Modal {...modalProps} title={t(`${locizeKey}:${action}:title`)} destroyOnClose>
      {action === EntityAction.Delete && <p>{t(`${locizeKey}:${action}:body`)}</p>}
      {(action === EntityAction.Edit || action === EntityAction.Create) && (
        <Form
          layout={layout}
          requiredMark={requiredMark}
          form={form}
          onValuesChange={onFormValuesChange}
        >
          {formBody}
        </Form>
      )}
      <div className={styles.modalButtons}>
        <Button
          // @ts-ignore
          type="secondary"
          size="small"
          onClick={onClose}
        >
          {t('common:buttons:cancel')}
        </Button>
        <Button type="primary" size="small" onClick={submit} loading={loading}>
          {t(`common:buttons:confirm`)}
        </Button>
      </div>
    </Modal>
  );
}
