import {
  IDocument,
  MediaCategoryType,
  UploadFileConfig,
} from 'store/client-request/client-request.types';
import { RequestState } from '../common.types';

export interface GenericFile {
  document: File;
  category: MediaCategoryType;
  config?: UploadFileConfig;
  uploadProgress?: any;
  uploadSuccess?: any;
  uploadError?: any;
}

export interface IDocumentState {
  file: IDocument | null;
  fileState: RequestState | null;
  fileError: string | null;
}

export const UPLOAD_GENERIC_FILE = 'UPLOAD_GENERIC_FILE';
export const UPLOAD_GENERIC_FILE_SUCCESS = 'UPLOAD_GENERIC_FILE_SUCCESS';
export const UPLOAD_GENERIC_FILE_ERROR = 'UPLOAD_GENERIC_FILE_ERROR';

export const CLEAR_CURRENT_FILE = 'CLEAR_CURRENT_FILE';

//Upload Generic File
export interface UploadGenericFileAction {
  type: typeof UPLOAD_GENERIC_FILE;
  payload: GenericFile;
}

export interface UploadGenericFileSuccessAction {
  type: typeof UPLOAD_GENERIC_FILE_SUCCESS;
  payload: IDocument;
}

export interface UploadGenericFileErrorAction {
  type: typeof UPLOAD_GENERIC_FILE_ERROR;
  payload: any;
}

//Clear Current File
export interface ClearCurrentFileAction {
  type: typeof CLEAR_CURRENT_FILE;
  payload: any;
}

export type DocumentActionTypes =
  | UploadGenericFileAction
  | UploadGenericFileSuccessAction
  | UploadGenericFileErrorAction
  | ClearCurrentFileAction;
