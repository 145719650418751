import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, InputNumber } from 'antd';
import FormRadioControl from 'components/form/components/FormRadioControl';
import { ValidationFactory } from 'framework/validations';
import { PoolSettingsFieldsType, CostCalculationModeOptionsType } from 'types/pool-settings.types';

export type CostCalculatorInputs = {
  costEstimateMin: string;
  costEstimateMax: string;
};
const COST_CALCULATION_LOCIZE_KEY = 'intermediate:myProjects:poolSettings:costCalculationMode';

const CostCalculatorSetting = () => {
  const { t } = useTranslation();

  return (
    <>
      <Form.Item
        name={PoolSettingsFieldsType.CostCalculationMode}
        label={t(`${COST_CALCULATION_LOCIZE_KEY}:title`)}
        labelAlign="left"
        rules={[ValidationFactory.REQUIRED]}
      >
        <FormRadioControl
          options={[
            {
              value: CostCalculationModeOptionsType.Manual,
              label: `${COST_CALCULATION_LOCIZE_KEY}:manual`,
            },
            {
              value: CostCalculationModeOptionsType.Range,
              label: `${COST_CALCULATION_LOCIZE_KEY}:range`,
            },
            {
              value: CostCalculationModeOptionsType.Unrestricted,
              label: `${COST_CALCULATION_LOCIZE_KEY}:manualPrices`,
              disabled: true,
            },
            {
              value: CostCalculationModeOptionsType.Deactivated,
              label: `${COST_CALCULATION_LOCIZE_KEY}:deactivated`,
            },
          ]}
        />
      </Form.Item>
      <Form.Item dependencies={[PoolSettingsFieldsType.CostCalculationMode]} noStyle>
        {({ getFieldValue }) => {
          const isModeRange =
            getFieldValue(PoolSettingsFieldsType.CostCalculationMode) ===
            CostCalculationModeOptionsType.Range;

          const fields = [
            PoolSettingsFieldsType.CostEstimateMin,
            PoolSettingsFieldsType.CostEstimateMax,
          ];
          return fields.map((field) => (
            <Form.Item
              rules={isModeRange ? [ValidationFactory.REQUIRED] : []}
              key={field}
              name={field}
              hidden={!isModeRange}
              label={t(`${COST_CALCULATION_LOCIZE_KEY}:${field}:title`)}
              labelAlign="left"
            >
              <InputNumber min={0} controls={false} />
            </Form.Item>
          ));
        }}
      </Form.Item>
    </>
  );
};

export default CostCalculatorSetting;
