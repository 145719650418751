import { produce } from 'immer';
import { RequestState } from 'store/common.types';
import {
  IPDFTemplateState,
  PDFTemplateActionTypes,
  PDF_TEMPLATES_GET,
  PDF_TEMPLATES_GET_ERROR,
  PDF_TEMPLATES_GET_SUCCESS,
  PDF_TEMPLATE_CREATE,
  PDF_TEMPLATE_CREATE_ERROR,
  PDF_TEMPLATE_CREATE_SUCCESS,
  PDF_TEMPLATE_DELETE,
  PDF_TEMPLATE_DELETE_SUCCESS,
  PDF_TEMPLATE_UPDATE,
  PDF_TEMPLATE_UPDATE_ERROR,
  PDF_TEMPLATE_UPDATE_SUCCESS,
} from './pdf-templates.types';

const initialState: IPDFTemplateState = {
  PDFTemplateCreateState: null,
  PDFTemplateCreateError: null,

  PDFTemplateUpdateState: null,
  PDFTemplateUpdateError: null,

  PDFTemplateDeleteState: null,
  PDFTemplateDeleteError: null,

  currentPDFTemplates: [],
  PDFTemplatesState: null,
  PDFTemplatesError: null,
};

const PDFTemplateReducer = (state = initialState, action: PDFTemplateActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case PDF_TEMPLATES_GET:
        draft.PDFTemplatesState = RequestState.Loading;
        break;
      case PDF_TEMPLATES_GET_SUCCESS:
        draft.PDFTemplatesState = RequestState.Success;
        draft.PDFTemplatesError = null;
        draft.currentPDFTemplates = action.payload;
        break;
      case PDF_TEMPLATES_GET_ERROR:
        draft.PDFTemplatesError = action.payload;
        draft.PDFTemplatesState = RequestState.Error;
        break;
      case PDF_TEMPLATE_CREATE:
        draft.PDFTemplateCreateState = RequestState.Loading;
        break;
      case PDF_TEMPLATE_CREATE_SUCCESS:
        draft.PDFTemplateCreateState = RequestState.Success;
        draft.PDFTemplateCreateError = null;
        draft.currentPDFTemplates = [...state.currentPDFTemplates, action.payload];
        break;
      case PDF_TEMPLATE_CREATE_ERROR:
        draft.PDFTemplateCreateError = action.payload;
        draft.PDFTemplateCreateState = RequestState.Error;
        break;
      case PDF_TEMPLATE_UPDATE:
        draft.PDFTemplateUpdateState = RequestState.Loading;
        break;
      case PDF_TEMPLATE_UPDATE_SUCCESS:
        draft.PDFTemplateUpdateState = RequestState.Success;
        draft.PDFTemplateUpdateError = null;
        draft.currentPDFTemplates = state.currentPDFTemplates.map((el) =>
          el.id === action.payload.id
            ? {
                ...el,
                name: action.payload.name,
                type: action.payload.type,
                file: action.payload.file,
                textBoxes: action.payload.textBoxes,
              }
            : el,
        );
        break;
      case PDF_TEMPLATE_UPDATE_ERROR:
        draft.PDFTemplateUpdateError = action.payload;
        draft.PDFTemplateUpdateState = RequestState.Error;
        break;
      case PDF_TEMPLATE_DELETE:
        draft.PDFTemplateDeleteState = RequestState.Loading;
        break;
      case PDF_TEMPLATE_DELETE_SUCCESS:
        draft.PDFTemplateDeleteState = RequestState.Success;
        draft.PDFTemplateDeleteError = null;
        draft.currentPDFTemplates = state.currentPDFTemplates.filter(
          (el) => el.id !== action.payload,
        );
        break;
    }
  });

export default PDFTemplateReducer;
