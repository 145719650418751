import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { clientRequestGet } from 'store/client-request/client-request.actions';
import {
  currentClientRequestSelector,
  clientRequestStateSelector,
} from 'store/client-request/client-request.selectors';
import EditRequestProductsComponent from '../../../../components/form/EditRequestProducts/EditRequestProducts';

const EditProductsPage = () => {
  const { requestID } = useParams();
  const dispatch = useDispatch();
  const clientRequest = useSelector(currentClientRequestSelector);
  const clientRequestState = useSelector(clientRequestStateSelector);
  const navigate = useNavigate();
  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (!requestID) return;
    dispatch(clientRequestGet(requestID, true));
  }, [dispatch, requestID]);

  return (
    <EditRequestProductsComponent
      clientRequest={clientRequest}
      clientRequestState={clientRequestState}
      successUpdateHandler={goBack}
      cancelEditHandler={goBack}
    />
  );
};
export default EditProductsPage;
