export const scrollToFormField = (id: string) => {
  id = id.replace(/:/i, '-');
  let errorField = document.getElementById(id);
  if (!errorField) {
    console.error(`Could not find element ${id}, please check if the container has the id set;`);
    return;
  }

  // we know a few levels up is the headline etc. included. Currently we scroll to the field directly, which is not the expected behaviour.
  // I know there might be a better way, but for now it's working.
  const findParents = 10;

  for (let i = 0; i < findParents; i++) {
    if (errorField.parentElement) {
      errorField = errorField.parentElement;
      if (errorField.className) {
        if (
          errorField.className.indexOf('ant-row ') > -1 ||
          errorField.className.indexOf('ant-form-item ') > -1 ||
          errorField.className.indexOf('BoxContentSection') > -1
        )
          break;
      }
    }
  }

  errorField.scrollIntoView({ behavior: 'smooth' });
};
