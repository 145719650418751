import React, { useState } from 'react';
import clsx from 'clsx';
import styles from './FloatingLabel.module.sass';

export enum LabelPositionType {
  CENTER = 'center',
  LEFT = 'left',
}

interface IFloatingLabelProps {
  containerClassName?: string;
  labelClassName?: string;

  children: any;
  label: string;
  labelPosition?: LabelPositionType;
  value?: string;
}

const FloatingLabel: React.FC<IFloatingLabelProps> = (props) => {
  const { children, label, labelPosition, value, containerClassName, labelClassName } = props;
  const [focus, setFocus] = useState(false);
  const isLabelFloating = focus || (value && value.length !== 0);

  const containerClassNames = clsx(styles.container, containerClassName);
  const labelClassNames = clsx(styles.label, labelClassName, {
    [styles.floating]: isLabelFloating,
    [styles.left]: labelPosition === LabelPositionType.LEFT,

    // This one we need to override styles from outside
    // the component. Check TextField.module.sass for more info.
    floating: isLabelFloating,
  });

  const containerProps = {
    className: containerClassNames,
    onBlur: () => setFocus(false),
    onFocus: () => setFocus(true),
  };

  return (
    <div {...containerProps}>
      {children}
      <label className={labelClassNames}>{label}</label>
    </div>
  );
};

FloatingLabel.defaultProps = {
  containerClassName: '',
  labelClassName: '',
  labelPosition: LabelPositionType.CENTER,
};

export default FloatingLabel;
