import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { useDispatch } from 'react-redux';
import message from 'antd/lib/message';
import Typography from 'antd/lib/typography';
import { Avatar, Badge, Button, Upload } from 'antd';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import CopyLinkIcon from 'assets/icons/copy-link.svg';
import Icon from 'components/ui/Icon/Icon';
import TextField from 'components/ui/TextField/TextField';
import Loader from 'components/ui/Loader/Loader';
import IntermediateAPI from 'services/intermediate.service';
import { poolInviteTokensGet } from 'store/intermediate/intermediate.actions';
import { IOrganization, IPool } from 'store/intermediate/intermediate.types';
import { userGet } from 'store/user/user.actions';

import { IUserObject } from 'store/auth/auth.types';
import styles from './Organization.module.sass';

type OrganizationValue = {
  firstName: string;
  lastName: string;
};

export interface IOrganizationProps {
  value?: OrganizationValue;
  pool: IPool;
  organization: IOrganization;
  user: IUserObject;
}

export const Organization: React.FC<IOrganizationProps> = ({ pool, organization, user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (pool) {
      dispatch(poolInviteTokensGet(pool.id));
    }
  }, [pool, dispatch]);

  const copyToClipboard = (e: any, copyValue: string) => {
    const textField = document.createElement('textarea');
    textField.innerText = copyValue;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    message.info(t('intermediate:myProjects:editProjectPools:linkCopied'));
    e.target.focus();
  };

  const renderInviteLink = () => {
    const installerInvite = organization.inviteTokens.find((i: any) => i.type === 'installer');
    const isInviteEnabled = installerInvite?.isEnabled;

    if (!installerInvite && !isInviteEnabled) {
      return (
        <Typography className={styles.inviteErrorMessage}>
          {t("Invitation to invite installer's isn't available")}
        </Typography>
      );
    }

    const link = installerInvite?.token
      ? `${document.location.origin}/registration/${installerInvite.token}`
      : '';

    return (
      <div className={styles.inviteLink}>
        <span className={styles.inviteLinkText}>{t('profile:inviteLink')}</span>
        <TextField
          value={link}
          label={t('intermediate:myProjects:editProjectPools:link')}
          className={styles.linkInput}
          readOnly
          suffix={
            <Icon
              className={styles.copyButton}
              onClick={(e) => copyToClipboard(e, link)}
              icon={CopyLinkIcon}
            />
          }
        />
      </div>
    );
  };

  const renderMembers = () => (
    <div className={styles.members}>
      <span className={styles.membersTitle}>{t('profile:members')}</span>
      <Badge count={organization.members.length} color="green" />
      <ul className={styles.membersList}>
        {organization.members.map((member: any) => (
          <li key={member.id} className={styles.membersListEl}>
            <Avatar size={30} icon={<UserOutlined />} />
            <span className={styles.membersListName}>
              {member.firstName} {member.lastName}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );

  const deleteLogo = () => {
    setLoading(true);
    if (organization.id) {
      IntermediateAPI.deleteOrganizationLogo(organization.id).then((res: any) => {
        if (res) {
          dispatch(userGet());
          setLoading(false);
        }
      });
    }
  };

  // const uploadProfile = (file: File) => {
  //   if (organization.id) {
  //     setLoading(true);
  //     IntermediateAPI.uploadOrganizationLogo(organization.id, file).then((res: any) => {
  //       if (res) {
  //         dispatch(userGet());
  //         setLoading(false);
  //       }
  //     });
  //   }
  // };

  const renderOrganizationData = () => {
    return (
      <div className={styles.userInfo}>
        <div>
          {loading ? (
            <Loader />
          ) : (
            <div className={styles.uploadProfileBox}>
              <Avatar
                className={styles.organizationLogo}
                shape="square"
                size={100}
                src={organization.logo ?? ''}
                icon={<UserOutlined />}
              />
            </div>
          )}
        </div>

        <span className={styles.userInfoName}>{organization?.name}</span>
        <span className={styles.userDescription}>ID: {user.displayId}</span>
        <div style={{ marginTop: '20px' }}>
          <Upload>
            <Button className={styles.uploadBtn} loading={loading}>
              {t('intermediate:myProjects:editProjectPools:updateAvatar')}
            </Button>
          </Upload>
          {organization.logo && (
            <Button
              type="primary"
              loading={loading}
              onClick={deleteLogo}
              className={styles.deleteBtn}
              danger
            >
              {t('intermediate:myProjects:editProjectPools:deleteAvatar')}
            </Button>
          )}
        </div>
      </div>
    );
  };

  if (!user || !organization) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      {renderOrganizationData()}
      {renderMembers()}
      {renderInviteLink()}
    </div>
  );
};
