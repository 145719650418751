import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Button, Card, Flex, Form, Select, Space } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { RawDraftContentState } from 'draft-js';
import { useTranslation } from 'react-i18next';
import EditorContainer from 'components/ui/EditorContainer/EditorContainer';
import Input from 'components/ui/TextField/TextField';
import { SupportedLanguages } from 'framework/constants';
import useStateBasedLanguageHandler from 'hooks/useStateBasedLanguageHandler';
import { TLanguageBasedContent } from 'types/language.types';

export type TRichContent = {
  title?: string;
  text: RawDraftContentState | null;
};

interface IMultiLanguageEntriesProps {
  defaultLanguage: SupportedLanguages;
  hasTitle: boolean;
  languageBasedContents: TLanguageBasedContent<TRichContent>[];
  setLanguageBasedContents: Dispatch<SetStateAction<TLanguageBasedContent<TRichContent>[]>>;
}

const MultiLanguageEntries = (props: IMultiLanguageEntriesProps) => {
  const { languageBasedContents, setLanguageBasedContents } = props;
  const {
    addTranslation,
    defaultLanguageKey,
    getLanguageChanger,
    possibleOptions,
    removeTranslation,
  } = useStateBasedLanguageHandler(
    props.defaultLanguage,
    { text: null },
    languageBasedContents,
    setLanguageBasedContents,
  );
  const getEditorOnChangeEvent = useCallback(
    (languageIndex: number) => (text: RawDraftContentState) =>
      setLanguageBasedContents((_languageBasedContents) => {
        const languageBasedContentsClone = [..._languageBasedContents];
        languageBasedContentsClone[languageIndex].content.text = text;
        return languageBasedContentsClone;
      }),
    [setLanguageBasedContents],
  );
  const getInputOnChangeEvent = useCallback(
    (languageIndex: number) => (title: string) =>
      setLanguageBasedContents((_languageBasedContents) => {
        const languageBasedContentsClone = [..._languageBasedContents];
        languageBasedContentsClone[languageIndex].content.title = title;
        return languageBasedContentsClone;
      }),
    [setLanguageBasedContents],
  );
  const { t } = useTranslation();

  return (
    <Flex vertical gap={8}>
      <Flex vertical gap={4}>
        {languageBasedContents.map((languageBasedContent, index) => (
          <Card key={languageBasedContent.language}>
            <Flex vertical>
              <Flex justify="end">
                <Space align="baseline">
                  <Form.Item>
                    <Select
                      value={languageBasedContent.language}
                      onChange={getLanguageChanger(index)}
                      placeholder={t('intermediate:myProjects:editProjectPools:language')}
                      options={possibleOptions}
                      disabled={
                        possibleOptions.length === 0 ||
                        defaultLanguageKey === languageBasedContent.language
                      }
                    />
                  </Form.Item>

                  {defaultLanguageKey !== languageBasedContent.language && (
                    <Button
                      onClick={() => {
                        removeTranslation(languageBasedContent.language);
                      }}
                      type="text"
                      size="small"
                      shape="circle"
                      icon={<MinusCircleOutlined />}
                    />
                  )}
                </Space>
              </Flex>
              <Flex vertical gap={8}>
                {props.hasTitle && (
                  <Input
                    size="large"
                    label={t('intermediate:myProjects:editProjectPools:faqPageTitle')}
                    onChange={(e) => getInputOnChangeEvent(index)(e.target.value)}
                    value={languageBasedContents[index].content?.title}
                  />
                )}
                <EditorContainer
                  onChange={getEditorOnChangeEvent(index)}
                  value={languageBasedContents[index].content?.text}
                />
              </Flex>
            </Flex>
          </Card>
        ))}
      </Flex>
      {possibleOptions.length > 0 && (
        <Button type="link" onClick={addTranslation}>
          {t('intermediate:myProjects:editProjectPools:addMoreTranslations')}
        </Button>
      )}
    </Flex>
  );
};

export default MultiLanguageEntries;
