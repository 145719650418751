import i18n from './../i18n';

export const evalMessageArgument = (
  argumentName: string,
  argumentValue: string,
  locizeKey: string,
) => i18n.t(locizeKey).replace(new RegExp(`%${argumentName}%`, 'g'), argumentValue);

export const offerMINdaysMessage = (offerValidityDays: string) =>
  evalMessageArgument('OFFER_MIN_DAYS', offerValidityDays, 'common:errors:validMinDays');
