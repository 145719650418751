import { produce } from 'immer';
import { RequestState } from 'store/common.types';
import {
  LINK_ORGANIZATION_TO_REQUEST,
  LINK_ORGANIZATION_TO_REQUEST_ERROR,
  LINK_ORGANIZATION_TO_REQUEST_SUCCESS,
  OrdersActionTypes,
  IOrdersState,
  GET_ORDER_BY_ID_ERROR,
  GET_ORDER_BY_ID_SUCCESS,
  GET_ORDER_BY_ID,
  ACCEPT_ORDER,
  ACCEPT_ORDER_ERROR,
  ACCEPT_ORDER_SUCCESS,
  REJECT_ORDER,
  REJECT_ORDER_ERROR,
  REJECT_ORDER_SUCCESS,
} from './orders.types';

const initialState: IOrdersState = {
  linkOrganizationToRequestState: null,
  linkOrganizationToRequestError: null,

  order: null,
  getOrderByIdState: null,
  getOrderByIdError: null,

  acceptOrderError: null,
  acceptOrderState: null,

  rejectOrderState: null,
  rejectOrderError: null,
};

const OrdersReducer = (state = initialState, action: OrdersActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LINK_ORGANIZATION_TO_REQUEST:
        draft.linkOrganizationToRequestState = RequestState.Loading;
        break;
      case LINK_ORGANIZATION_TO_REQUEST_SUCCESS:
        draft.linkOrganizationToRequestState = RequestState.Success;
        draft.linkOrganizationToRequestError = null;
        break;
      case LINK_ORGANIZATION_TO_REQUEST_ERROR:
        draft.linkOrganizationToRequestError = action.payload;
        draft.linkOrganizationToRequestState = RequestState.Error;
        break;
      case GET_ORDER_BY_ID:
        draft.getOrderByIdState = RequestState.Loading;
        break;
      case GET_ORDER_BY_ID_SUCCESS:
        draft.getOrderByIdState = RequestState.Success;
        draft.getOrderByIdError = null;
        draft.order = action.payload;
        break;
      case GET_ORDER_BY_ID_ERROR:
        draft.getOrderByIdError = action.payload;
        draft.getOrderByIdState = RequestState.Error;
        break;
      case ACCEPT_ORDER:
        draft.acceptOrderState = RequestState.Loading;
        break;
      case ACCEPT_ORDER_SUCCESS:
        draft.acceptOrderState = RequestState.Success;
        draft.acceptOrderError = null;
        draft.order = action.payload;
        break;
      case ACCEPT_ORDER_ERROR:
        draft.acceptOrderError = action.payload;
        draft.acceptOrderState = RequestState.Error;
        break;
      case REJECT_ORDER:
        draft.rejectOrderState = RequestState.Loading;
        break;
      case REJECT_ORDER_SUCCESS:
        draft.rejectOrderState = RequestState.Success;
        draft.rejectOrderError = null;
        draft.order = action.payload;
        break;
      case REJECT_ORDER_ERROR:
        draft.rejectOrderError = action.payload;
        draft.rejectOrderState = RequestState.Error;
        break;
    }
  });

export default OrdersReducer;
