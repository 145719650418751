import { IClientRequest } from 'store/client-request/client-request.types';
import { IPool } from 'store/intermediate/intermediate.types';
import { RequestResult } from 'types/crud-view.types';
import { ISearchDto, RequestState } from '../common.types';

export interface IBundle {
  id: string;
  createdAt?: Date;
  updatedAt?: Date;
  vatRate: number | string;
  currencySymbol: string;
  active: boolean;
  vat: number;
  totalPriceNet: number;
  totalPriceGross: number;
  components: IBundleComponent[];
  name: string;
  description?: string;
  pool?: IPool;
  clientRequests?: IClientRequest[];
}

export interface IBundleComponent {
  id?: string;
  category?: string;
  description?: string;
  price?: string | number;
  quantity?: string;
  unit?: string;
  totalPrice?: string | number;
}

export interface IBundleState {
  bundleCreateState: RequestState | null;
  bundleCreateError: string | null;

  bundleUpdateState: RequestState | null;
  bundleUpdateError: string | null;

  bundleDeleteState: RequestState | null;
  bundleDeleteError: string | null;

  currentBundles: IBundle[];
  bundlesState: RequestState | null;
  bundlesError: string | null;
}

export const BUNDLE_CREATE = 'BUNDLE_CREATE';
export const BUNDLE_CREATE_SUCCESS = 'BUNDLE_CREATE_SUCCESS';
export const BUNDLE_CREATE_ERROR = 'BUNDLE_CREATE_ERROR';

export const BUNDLES_GET = 'BUNDLES_GET';
export const BUNDLES_GET_SUCCESS = 'BUNDLES_GET_SUCCESS';
export const BUNDLES_GET_ERROR = 'BUNDLES_GET_ERROR';

export const BUNDLE_UPDATE = 'BUNDLE_UPDATE';
export const BUNDLE_UPDATE_SUCCESS = 'BUNDLE_UPDATE_SUCCESS';
export const BUNDLE_UPDATE_ERROR = 'BUNDLE_UPDATE_ERROR';

export const BUNDLE_DELETE = 'BUNDLE_DELETE';
export const BUNDLE_DELETE_SUCCESS = 'BUNDLE_DELETE_SUCCESS';
export const BUNDLE_DELETE_ERROR = 'BUNDLE_DELETE_ERROR';

// Create Bundle
export interface BundleCreateAction {
  type: typeof BUNDLE_CREATE;
  payload: {
    poolId: string;
    bundle: IBundle;
    onRequestDone?: (status: RequestResult) => void;
  };
}

export interface BundleCreateSuccessAction {
  type: typeof BUNDLE_CREATE_SUCCESS;
  payload: IBundle;
}

export interface BundleCreateErrorAction {
  type: typeof BUNDLE_CREATE_ERROR;
  payload: any;
}

// Get Bundles
export interface BundleGetAction {
  type: typeof BUNDLES_GET;
  poolId: string;
  params?: ISearchDto;
}

export interface BundleGetSuccessAction {
  type: typeof BUNDLES_GET_SUCCESS;
  payload: IBundle[];
}

export interface BundleGetErrorAction {
  type: typeof BUNDLES_GET_ERROR;
  payload: any;
}

// Update Bundles
export interface BundleUpdateAction {
  type: typeof BUNDLE_UPDATE;
  payload: {
    bundleId: string;
    bundle: IBundle;
    onRequestDone?: (status: RequestResult) => void;
  };
}

export interface BundleUpdateSuccessAction {
  type: typeof BUNDLE_UPDATE_SUCCESS;
  payload: IBundle;
}

export interface BundleUpdateErrorAction {
  type: typeof BUNDLE_UPDATE_ERROR;
  payload: any;
}

// Delete Bundle
export interface BundleDeleteAction {
  type: typeof BUNDLE_DELETE;
  payload: {
    id: string;
    onRequestDone?: (status: RequestResult) => void;
  };
}

export interface BundleDeleteSuccessAction {
  type: typeof BUNDLE_DELETE_SUCCESS;
  payload: string;
}

export interface BundleDeleteErrorAction {
  type: typeof BUNDLE_DELETE_ERROR;
  payload: any;
}

export type BundleActionTypes =
  | BundleCreateAction
  | BundleCreateSuccessAction
  | BundleCreateErrorAction
  | BundleGetAction
  | BundleGetSuccessAction
  | BundleGetErrorAction
  | BundleUpdateAction
  | BundleUpdateSuccessAction
  | BundleUpdateErrorAction
  | BundleDeleteAction
  | BundleDeleteSuccessAction
  | BundleDeleteErrorAction;
