import moment from 'moment';
import { IOrganizationMembershipPlan } from 'store/intermediate/intermediate.types';
import { IClientRequest } from 'store/client-request/client-request.types';
import { IOffer } from 'store/offer/offer.types';
import { InstallationSelectionModeOptionsType } from 'types/pool-settings.types';
import { DEFAULT_TIME_FORMAT } from './constants';

const { Range } = InstallationSelectionModeOptionsType;

export const formatDate = (date?: Date, format?: any): string => {
  if (!date) {
    return 'n/a';
  }

  return moment(date).format(format || DEFAULT_TIME_FORMAT);
};

export const isMembershipDateNotOverdue = (membership?: IOrganizationMembershipPlan): boolean => {
  const isValidTo = membership?.validUntil
    ? moment(new Date()).isBefore(new Date(membership?.validUntil))
    : true;
  const isValidFrom = membership?.startFrom
    ? moment(new Date()).isAfter(new Date(membership?.startFrom))
    : false;
  return isValidTo && isValidFrom;
};

export const getAppointmentDisabledDays = (clientRequest: IClientRequest, offer: IOffer) => {
  const { pool, startDate, soonestPossible } = clientRequest;
  const { tenderAcceptedTimestamp } = offer;

  const mainDate = soonestPossible ? tenderAcceptedTimestamp : startDate;

  if (!mainDate) {
    return {};
  }

  const isDateRange = pool?.installationSelectionMode === Range;

  const getBefore = () => {
    if (isDateRange && pool?.appointmentFrom) {
      const { appointmentFrom } = pool;
      return moment(new Date(mainDate)).add(appointmentFrom, 'weeks');
    }

    return soonestPossible ? new Date() : new Date(mainDate);
  };

  const getAfter = () => {
    if (isDateRange && pool?.appointmentUntil) {
      const { appointmentUntil } = pool;
      return moment(new Date(mainDate)).add(appointmentUntil, 'weeks');
    }

    return '';
  };

  const after = getAfter();
  const before = getBefore();

  return {
    before,
    after,
  };
};
