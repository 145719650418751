import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Col, Row, Typography } from 'antd';
import clsx from 'clsx';
import { useMatomo } from '@mitresthen/matomo-tracker-react';
import { PATH } from 'framework/path';
import { isAuthorizedSelector } from 'store/auth/auth.selectors';
import styles from './WelcomePage.module.sass';

type ColType = {
  title: string;
  text: string;
  buttonText: string;
  path: string;
  className: string;
};

const WelcomePage: React.FC = () => {
  const { trackPageView } = useMatomo();
  const { t } = useTranslation(['welcomePage', 'common']);
  const navigate = useNavigate();

  const isAuthorized = useSelector(isAuthorizedSelector);

  useEffect(() => {
    setTimeout(() => trackPageView({}), 1);
  }, [trackPageView]);

  useEffect(() => {
    if (isAuthorized) {
      navigate(PATH.HOME);
    }
  }, [isAuthorized, navigate]);

  const COLS: ColType[] = [
    {
      title: t('welcomePage:customerTitle'),
      text: t('welcomePage:customerText'),
      className: styles.customer,
      buttonText: t('welcomePage:loginCustomer'),
      path: PATH.LOGIN,
    },
    {
      title: t('welcomePage:installerTitle'),
      text: t('welcomePage:installerText'),
      buttonText: t('welcomePage:loginInstaller'),
      className: styles.installer,
      path: PATH.LOGIN,
    },
  ];

  const renderCol = (col: ColType, index: number) => (
    <Col md={12} xs={24} key={`welcome-col-${index}`}>
      <div className={clsx(styles.col, col.className)}>
        <div className={styles.informationSection}>
          <div>
            <Typography.Title level={3}>{col.title}</Typography.Title>
            <Typography.Paragraph className={styles.descriptionText}>
              {col.text}
            </Typography.Paragraph>
          </div>
          <Link to="/login" className={styles.smallLink}>
            {col.buttonText}
          </Link>
        </div>
        {!!index && (
          <div className={styles.registerSection}>
            <Typography.Paragraph className={styles.smallText}>
              {t('welcomePage:dontHaveAccount')}{' '}
              <Link to="/installer/register" className={styles.smallLink}>
                {t('welcomePage:registerNow')}
              </Link>
            </Typography.Paragraph>
          </div>
        )}
      </div>
    </Col>
  );

  return (
    <div className={styles.container}>
      <Typography.Title level={2} className={styles.title}>
        {t('welcomePage:title')}
      </Typography.Title>
      <Row gutter={[16, 16]} className={styles.options}>
        {COLS.map(renderCol)}
      </Row>
    </div>
  );
};

export default WelcomePage;
