import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Spin from 'antd/lib/spin';
import Typography from 'antd/lib/typography';
import LoginOutlined from '@ant-design/icons/LoginOutlined';
import Button from 'components/ui/Button/Button';
import { tokenRefresh, verifyEmail, verifyEmailError } from 'store/auth/auth.actions';
import {
  isAuthorizedSelector,
  isCustomerSelector,
  isInstallerManagerSelector,
  isInstallerSelector,
  isIntermediateManagerSelector,
  isVerifyEmailFailedSelector,
  isVerifyEmailSucceededSelector,
  isVerifyInstallerSucceededSelector,
  isVerifyingEmailSelector,
} from 'store/auth/auth.selectors';
import styles from './EmailVerifyPage.module.sass';

const Paragraph = Typography.Paragraph;

const EmailVerifyPage: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const verifyEmailSuccess = useSelector(isVerifyEmailSucceededSelector);
  const verifyEmailFailure = useSelector(isVerifyEmailFailedSelector);
  const loading = useSelector(isVerifyingEmailSelector);
  const isAuthorized = useSelector(isAuthorizedSelector);
  const isCustomer = useSelector(isCustomerSelector);
  const isInstallerManager = useSelector(isInstallerManagerSelector);
  const isInstaller = useSelector(isInstallerSelector);
  const isInstallerVerified = useSelector(isVerifyInstallerSucceededSelector);
  const isIntermediateManager = useSelector(isIntermediateManagerSelector);

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    if (token) {
      dispatch(verifyEmail({ token }));
    } else {
      dispatch(verifyEmailError(t('common:errors:tokenNotFound')));
    }
  }, []); // eslint-disable-line
  // [TODO]: Make a hook to handle user navigation after login
  const handleRedirectUser = () => {
    /* [TODO]:  merge the handleContinueClick and handleRedirectUser or
    or just arrange them in more a sane way.
    Since this function has only been called by the handleContinue
    check and it has already checked if the user has been logged in,
    a simple check would be enough, for now. */
    if (!isAuthorized) return;
    if (isCustomer) {
      navigate('/customer');
      return;
    }
    if (isIntermediateManager) {
      return navigate('/my-projects');
    }

    if ((isInstaller || isInstallerManager) && isInstallerVerified) {
      navigate('/installer/dashboard');
      return;
    }
    navigate('/');
    return;
  };

  const handleContinueClick = () => {
    isAuthorized ? dispatch(tokenRefresh(handleRedirectUser)) : navigate('/login');
  };

  const renderMessage = () =>
    verifyEmailSuccess ? (
      <div className={styles.confirmation}>
        <Paragraph>{t('customerFlow:emailVerify:verified')}</Paragraph>
        <Button type="primary" icon={<LoginOutlined />} onClick={handleContinueClick}>
          {t('customerFlow:emailVerify:continueWithCR')}
        </Button>
      </div>
    ) : (
      <Paragraph className={styles.confirmation}>
        {t('customerFlow:emailVerify:failed')} <b>{verifyEmailFailure}</b>
      </Paragraph>
    );

  return <div className={styles.container}>{loading ? <Spin /> : renderMessage()}</div>;
};

export default EmailVerifyPage;
