import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Col, Row, Image, Skeleton, Progress, notification, Carousel } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import TrashRed from 'assets/icons/trash-red.svg';
import Icon from 'components/ui/Icon/Icon';

import DocumentsAPI from 'services/documents.service';
import { IFile, MediaCategoryType } from 'store/client-request/client-request.types';
import { currentClientRequestFilesSelector } from 'store/client-request/client-request.selectors';

import { deleteFile, uploadFile } from 'store/client-request/client-request.actions';
import { calculateProgress } from 'utils/fileUploadUtils';
import styles from './VideoCall.module.sass';

const SnapshotSection = forwardRef((props: any, ref: any) => {
  useImperativeHandle(ref, () => ({
    onTakeSnapShot() {
      takeSnapshot();
    },
  }));

  const { subscribers, offer } = props;
  const [localImage, setLocalImage] = useState<any>();
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [uploadedImages, setUploadedImages] = useState<any>([]);

  const dispatch = useDispatch();
  const requestFiles = useSelector(currentClientRequestFilesSelector);

  useEffect(() => {
    updateImageList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!localImage) setUploadPercentage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localImage]);

  const updateImageList = async () => {
    const uploadedFiles = requestFiles[MediaCategoryType.RequestVideoCall] || [];
    if (uploadedFiles.length)
      await DocumentsAPI.getFileLinks(uploadedFiles.map((file: IFile) => file.name)).then(
        (response) => {
          const { links } = response;
          if (links)
            setUploadedImages(
              uploadedFiles.map((file: IFile, index: number) => ({
                name: file.name,
                url: links[index],
              })),
            );
        },
      );
  };

  const takeSnapshot = () => {
    if (Object.keys(subscribers.camera).length !== 0 && !localImage) {
      Object.keys(subscribers.camera).forEach((key: any) => {
        const imgData = subscribers.camera[key].getImgData();
        uploadImage(imgData);
        setLocalImage(imgData);
      });
    } else
      notification.error({
        message: 'Can not take snapshot',
      });
  };

  const uploadImage = (imgData: any) => {
    fetch(`data:image/jpg;base64, ${imgData}`)
      .then((res) => res.blob())
      .then((blob) => {
        setUploadPercentage(0);
        const file = new File([blob], `snapshot.jpg`, { type: 'image/jpg' });
        dispatch(
          uploadFile(
            file,
            offer.clientRequest.id,
            MediaCategoryType.RequestVideoCall,
            {},
            onFileUploadProgress,
            onUploadSuccess,
          ),
        );
      });
  };

  const deleteImage = (image: any) => {
    dispatch(
      deleteFile(image.name, offer.clientRequest.id, MediaCategoryType.RequestVideoCall, () => {
        setUploadedImages(uploadedImages.filter((file: any) => image.name !== file.name));
      }),
    );
  };

  const onUploadSuccess = async (file: any) => {
    await DocumentsAPI.getFileLink(file.name).then((response) => {
      const url = response.link;
      setUploadedImages([{ name: file.name, url: url }, ...uploadedImages]);
      setLocalImage(null);
    });
  };

  const onFileUploadProgress = (progressEvent: any) => {
    const percentCompleted = calculateProgress(progressEvent.loaded, progressEvent.total);
    setUploadPercentage(percentCompleted);
  };

  const sliderSetting = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 4,
  };
  return (
    <Row gutter={[2, 2]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Carousel {...sliderSetting}>
          {localImage && (
            <div>
              <Image
                src={`data:image/png;base64, ${localImage}`}
                alt={'loading ...'}
                width={120}
                height={90}
                placeholder={<Skeleton.Image style={{ width: '120px', height: '90px' }} />}
              />
              <div style={{ width: '120px' }}>
                <Progress percent={uploadPercentage} status="active" showInfo={false} />
              </div>
            </div>
          )}
          {uploadedImages.length > 0 &&
            uploadedImages.map(
              (image: any, index: any) =>
                image.url && (
                  <div key={index}>
                    <div style={{ position: 'relative' }}>
                      <span className={styles.deleteIcon} onClick={() => deleteImage(image)}>
                        <Icon height="16px" width="13px" icon={TrashRed} />
                      </span>
                      <Image
                        src={image.url}
                        alt={'loading ...'}
                        height={90}
                        width={120}
                        placeholder={<Skeleton.Image style={{ width: '120px', height: '90px' }} />}
                      />
                    </div>
                  </div>
                ),
            )}
        </Carousel>
      </Col>
    </Row>
  );
});

export default SnapshotSection;
