import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { IPool } from 'store/intermediate/intermediate.types';
import { installerOrganizationSelector } from 'store/installer/installer.selectors';
import { userGet } from 'store/user/user.actions';
import { userSelector } from 'store/user/user.selectors';
import { getInstallerOrganization } from 'store/installer/installer.actions';
import { DefaultSettings } from './Tabs/DefaultSettings/DefaultSettings';
import { Organization } from './Tabs/Organization/Organization';

type OrganizationValue = {
  firstName: string;
  lastName: string;
};

export interface IOrganizationPageProps {
  value?: OrganizationValue;
  pool?: IPool;
}

const OrganizationPage: React.FC<IOrganizationPageProps> = ({ pool }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organization = useSelector(installerOrganizationSelector);
  const user = useSelector(userSelector);

  useEffect(() => {
    dispatch(userGet());
  }, [dispatch]);

  useEffect(() => {
    if (user?.organizationId) {
      dispatch(getInstallerOrganization(user.organizationId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const tabs = [
    {
      key: '1',
      label: t('organization:sidebar:organization'),
      children: <Organization organization={organization} pool={pool!} user={user} />,
    },
    {
      key: '2',
      label: t('organization:sidebar:defaultSettings'),
      children: <DefaultSettings organization={organization} />,
    },
  ];

  return <Tabs items={tabs} tabPosition="left" />;
};

export default OrganizationPage;
