import React, { FC, ReactNode } from 'react';

import clsx from 'clsx';
import { Col, Typography } from 'antd';
import { ColProps } from 'antd/lib';
import Icon from 'components/ui/Icon/Icon';
import styles from './Container.module.sass';

interface IContainerProps {
  children: React.ReactNode;
  space?: boolean;
  className?: string;
  grow?: number;
  title?: string;
  headingExtra?: ReactNode;
  icon?: string;
  onClick?: () => void;
}
const Container: FC<IContainerProps & ColProps> = ({
  children,
  space = true,
  className,
  grow,
  title,
  headingExtra,
  icon,
  ...rest
}) => {
  return (
    <Col style={{ flexGrow: grow ?? 0 }} md={12} xs={24} {...rest}>
      <div className={clsx(styles.container, className, { [styles.backgroundWhite]: space })}>
        {title && (
          <header className={styles.header}>
            <div className={styles.title}>
              {icon && (
                <div className={styles.iconContainer}>
                  <Icon width="20px" height="20px" className={styles.icon} icon={icon} />
                </div>
              )}
              <Typography.Title level={4}>{title}</Typography.Title>
            </div>
            {headingExtra}
          </header>
        )}
        {children}
      </div>
    </Col>
  );
};

export default Container;
