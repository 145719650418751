import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { decodeToken } from 'framework/jwt';
import {
  AUTH_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  USER_ROLE_INSTALLER,
  USER_ROLE_INSTALLER_MANAGER,
} from 'framework/constants';
import AuthAPI from 'services/auth.service';
import UserAPI from 'services/user.service';
import InstallerAPI from 'services/installer.service';
import { HttpServiceState } from 'services/http.service';
import { clientRequestCreate } from 'store/client-request/client-request.actions';
import { setAppLanguage } from 'utils/language';
import {
  changePasswordError,
  changePasswordSuccess,
  loginError,
  loginSuccess,
  logoutError,
  logoutSuccess,
  passwordResetConfirmError,
  passwordResetConfirmSuccess,
  passwordResetRequestError,
  passwordResetRequestSuccess,
  passwordResetUpdateError,
  passwordResetUpdateSuccess,
  registerError,
  registerSuccess,
  tokenRefreshError,
  tokenRefreshSuccess,
  verifyEmailError,
  verifyEmailSuccess,
  verifyChangeEmailSuccess,
  verifyChangeEmailError,
  changeEmailError,
  changeEmailSuccess,
  verifyInstallerError,
  verifyInstallerSuccess,
  verifyPasswordResetTokenError,
  verifyPasswordResetTokenSuccess,
  tokenRefresh,
} from './auth.actions';
import {
  AUTH_INIT,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_TOKEN_LOGIN,
  AUTH_PASSWORD_RESET_CONFIRM,
  AUTH_PASSWORD_RESET_REQUEST,
  AUTH_PASSWORD_RESET_UPDATE,
  AUTH_CHANGE_PASSWORD,
  AUTH_REGISTER,
  AUTH_TOKEN_REFRESH,
  AUTH_VERIFY_EMAIL,
  AUTH_VERIFY_CHANGE_EMAIL,
  AUTH_CHANGE_EMAIL,
  AUTH_VERIFY_INSTALLER,
  IUserObject,
  LoginAction,
  PasswordResetConfirmAction,
  PasswordResetRequestAction,
  PasswordResetUpdateAction,
  ChangePasswordAction,
  RegisterAction,
  VerifyEmailAction,
  VerifyChangeEmailAction,
  InitLoginAction,
  TokenLoginAction,
  TokenRefreshAction,
  VerifyResetPasswordTokenAction,
  VERIFY_PASSWORD_RESET_TOKEN,
  IPassResetTypes,
} from './auth.types';

export function* authInit(action: InitLoginAction) {
  const accessToken = localStorage.getItem(AUTH_TOKEN_KEY);

  // Check if token exist
  if (accessToken) {
    const user: IUserObject = decodeToken(accessToken);
    const { exp } = user;
    const isTokenValid = exp * 1000 - Date.now() > 0;

    // Check if token valid
    if (isTokenValid) {
      yield put(loginSuccess(user, accessToken));
      action?.payload?.onSuccess && action.payload.onSuccess();
    } else {
      HttpServiceState.getInstance().isRefreshing = true;
      yield put(tokenRefresh());
    }
  } else {
    yield put(loginError('NOT_AUTHORIZED'));
  }
}
export function* register(action: RegisterAction): Generator<any, void, any> {
  try {
    const state = yield select();

    const { accessToken, refreshToken, defaultLanguage } = yield call(AuthAPI.register, {
      ...action.payload.user,
      fingerprint: state?.auth?.fingerprint,
    });

    const user = decodeToken(accessToken);
    localStorage.setItem(AUTH_TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    if (defaultLanguage?.isoCode) setAppLanguage(defaultLanguage?.isoCode).then();
    if (isInstaller(user.roles)) {
      yield verifyInstaller();
    }

    const { clientRequest } = action.payload;
    if (clientRequest) {
      yield put(clientRequestCreate(clientRequest, action.payload.answers));
    }
    yield put(registerSuccess(user, accessToken));
  } catch (err) {
    yield put(registerError(err));
  }
}

export function* verifyEmail(action: VerifyEmailAction) {
  try {
    const { defaultLanguage } = yield call(AuthAPI.verifyEmail, action.payload);
    if (defaultLanguage?.isoCode) setAppLanguage(defaultLanguage?.isoCode).then();
    yield put(verifyEmailSuccess());
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(verifyEmailError(message));
  }
}

export function* verifyChangeEmail(action: VerifyChangeEmailAction) {
  try {
    yield call(AuthAPI.updateEmailVerify, action.payload);
    yield put(verifyChangeEmailSuccess());
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(verifyChangeEmailError(message));
  }
}

export function* verifyInstaller() {
  try {
    const { status } = yield call(InstallerAPI.verifyConfirmation);
    yield put(verifyInstallerSuccess(status));
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(verifyInstallerError(message));
  }
}

export function* changeEmail(action: any) {
  const { onSuccess, onError, email } = action.payload;

  try {
    yield call(AuthAPI.updateEmail, email);
    yield put(changeEmailSuccess());

    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    if (typeof onError === 'function') {
      onError();
    }

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(changeEmailError(message));
  }
}

export function* login(action: LoginAction): Generator<any, void, any> {
  try {
    const state = yield select();
    const response = yield call(AuthAPI.login, {
      ...action.payload,
      fingerprint: state?.auth?.fingerprint,
    });
    const { accessToken, refreshToken, defaultLanguage } = response;
    const user: any = decodeToken(accessToken);
    localStorage.setItem(AUTH_TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

    if (defaultLanguage?.isoCode) setAppLanguage(defaultLanguage?.isoCode).then();
    yield put(loginSuccess(user, accessToken));
  } catch (err) {
    yield put(loginError(err));
  }
}

export function* tokenLogin(action: TokenLoginAction): Generator<any, void, any> {
  try {
    const response = yield call(AuthAPI.tokenLogin, action.payload);
    const { accessToken } = response;
    const user: any = decodeToken(accessToken);
    localStorage.setItem(AUTH_TOKEN_KEY, accessToken);

    yield put(loginSuccess(user, accessToken));
  } catch (err) {
    yield put(loginError(err));
  }
}

export function* tokenRefreshSaga(action: TokenRefreshAction): Generator<any, void, any> {
  try {
    const response = yield call(AuthAPI.tokenRefresh);
    const { accessToken } = response;
    const { refreshToken } = response.refreshSession;
    const user = decodeToken(accessToken);
    localStorage.setItem(AUTH_TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

    yield put(tokenRefreshSuccess(user, accessToken));
    if (action?.payload?.onSuccess) {
      action.payload.onSuccess();
    }
  } catch (err) {
    yield put(tokenRefreshError(err));
    localStorage.setItem(AUTH_TOKEN_KEY, '');
    localStorage.setItem(REFRESH_TOKEN_KEY, '');
    window.location.href = '/login';
  } finally {
    HttpServiceState.getInstance().isRefreshing = false;
  }
}

export function* logout(): Generator<any, void, any> {
  try {
    const state = yield select();
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    if (refreshToken) yield call(AuthAPI.logout, state?.auth?.fingerprint, refreshToken);
    localStorage.removeItem(AUTH_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    yield put(logoutSuccess());
  } catch (err) {
    yield put(logoutError(err));
  }
}

export function* verifyPasswordResetToken(action: VerifyResetPasswordTokenAction) {
  try {
    const response: IPassResetTypes = yield call(UserAPI.verifyPasswordResetToken, action.payload);
    if (response) {
      yield put(verifyPasswordResetTokenSuccess(response));
    } else {
      yield put(verifyPasswordResetTokenError('Invalid token'));
    }
  } catch (err) {
    yield put(verifyPasswordResetTokenError(err));
  }
}

export function* passwordResetRequest(action: PasswordResetRequestAction) {
  try {
    const { email, onSuccess } = action.payload;
    yield call(AuthAPI.passwordResetRequest, email);
    yield put(passwordResetRequestSuccess());

    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  } catch (err) {
    yield put(passwordResetRequestError(err));
  }
}

export function* passwordResetConfirm(action: PasswordResetConfirmAction) {
  try {
    yield call(AuthAPI.passwordResetConfirm, action.payload);
    yield put(passwordResetConfirmSuccess());
  } catch (err) {
    yield put(passwordResetConfirmError(err));
  }
}

export function* passwordResetUpdate(action: PasswordResetUpdateAction) {
  try {
    const { token, password, userId } = action.payload;
    yield call(AuthAPI.passwordResetUpdate, token, password, userId);
    yield put(passwordResetUpdateSuccess());
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(passwordResetUpdateError(message));
  }
}

export function* changePassword(action: ChangePasswordAction): Generator<any, void, any> {
  const { onSuccess, onError, ...rest } = action.payload;

  try {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY) || '';
    const state = yield select();

    yield call(AuthAPI.changePassword, {
      ...rest,
      refreshToken,
      fingerprint: state?.auth?.fingerprint,
    });
    yield put(changePasswordSuccess());

    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    if (typeof onError === 'function') {
      onError();
    }

    const message = Array.isArray(data.message) ? data.message[0] : data.message;

    yield put(changePasswordError(message));
  }
}

export default function* root() {
  yield all([
    takeLatest(AUTH_INIT, authInit),
    takeLatest(AUTH_LOGIN, login),
    takeLatest(AUTH_TOKEN_LOGIN, tokenLogin),
    takeLatest(AUTH_TOKEN_REFRESH, tokenRefreshSaga),
    takeLatest(AUTH_LOGOUT, logout),
    takeLatest(AUTH_REGISTER, register),
    takeLatest(AUTH_VERIFY_EMAIL, verifyEmail),
    takeLatest(AUTH_VERIFY_CHANGE_EMAIL, verifyChangeEmail),
    takeLatest(AUTH_VERIFY_INSTALLER, verifyInstaller),
    takeLatest(AUTH_PASSWORD_RESET_REQUEST, passwordResetRequest),
    takeLatest(AUTH_PASSWORD_RESET_CONFIRM, passwordResetConfirm),
    takeLatest(AUTH_PASSWORD_RESET_UPDATE, passwordResetUpdate),
    takeLatest(AUTH_CHANGE_PASSWORD, changePassword),
    takeLatest(AUTH_CHANGE_EMAIL, changeEmail),
    takeLatest(VERIFY_PASSWORD_RESET_TOKEN, verifyPasswordResetToken),
  ]);
}

function isInstaller(roles: string[]): boolean {
  return (
    roles.indexOf(USER_ROLE_INSTALLER) !== -1 || roles.indexOf(USER_ROLE_INSTALLER_MANAGER) !== -1
  );
}
