import { OfferState } from 'store/offer/offer.types';

export const acceptedHomeCheckOfferStates = [
  OfferState.InvoiceAcceptedHomeCheck,
  OfferState.InvoiceAcceptedRemoteHomeCheck,
  OfferState.InvoicePaidHomeCheck,
  OfferState.InvoicePaidRemoteHomeCheck,
];

export const acceptedInstallationOfferStates = [
  OfferState.OfferAccepted,
  OfferState.OfferSubmitted,
  OfferState.InstallationPending,
  OfferState.InstallationDone,
  OfferState.InvoiceAcceptedInstallation,
  OfferState.InvoiceRejectedInstallation,
];
