import { RequestState } from 'store/common.types';
import { IPool } from '../intermediate/intermediate.types';

export type SurveysListType = {
  items: any[];
  total: number;
};

export interface ISurvey {
  createdAt?: Date;
  displayId?: string;
  step?: number;
  answers?: any;
  pool?: IPool;
  poolId?: string;
  questionnaire?: any;
  versionedQuestionnaireId?: any;
}

export interface ISurveysState {
  surveys: SurveysListType;
  survey: ISurvey;
  surveyState: RequestState | null;
  createError: string | null;
  createState: RequestState | null;
}

// Get list of surveys
export const SURVEYS_GET = 'SURVEYS_GET';
export const SURVEYS_GET_SUCCESS = 'SURVEYS_GET_SUCCESS';
export const SURVEYS_GET_ERROR = 'SURVEYS_GET_SUCCESS';

// Get survey by id
export const SURVEY_GET = 'SURVEY_GET';
export const SURVEY_GET_SUCCESS = 'SURVEY_GET_SUCCESS';
export const SURVEY_GET_ERROR = 'SURVEY_GET_SUCCESS';

// Create survey
export const SURVEY_CREATE = 'SURVEY_CREATE';
export const SURVEY_CREATE_SUCCESS = 'SURVEY_CREATE_SUCCESS';
export const SURVEY_CREATE_ERROR = 'SURVEY_CREATE_SUCCESS';

// Get surveys list
export interface SurveysGetAction {
  type: typeof SURVEYS_GET;
  payload: string;
}

export interface SurveysGetSuccessAction {
  type: typeof SURVEYS_GET_SUCCESS;
  payload: ISurvey;
}

export interface SurveysGetErrorAction {
  type: typeof SURVEYS_GET_ERROR;
  payload: any;
}

// Get survey by id
export interface SurveyGetAction {
  type: typeof SURVEY_GET;
  payload: string;
}

export interface SurveyGetSuccessAction {
  type: typeof SURVEY_GET_SUCCESS;
  payload: ISurvey;
}

export interface SurveyGetErrorAction {
  type: typeof SURVEY_GET_ERROR;
  payload: any;
}

// Create survey
export interface SurveyCreateAction {
  type: typeof SURVEY_CREATE;
  payload: ISurvey;
}

export interface SurveyCreateSuccessAction {
  type: typeof SURVEY_CREATE_SUCCESS;
  payload: ISurvey;
}

export interface SurveyCreateErrorAction {
  type: typeof SURVEY_CREATE_ERROR;
  payload: any;
}

export type SurveysActionTypes =
  | SurveyGetAction
  | SurveyGetSuccessAction
  | SurveyGetErrorAction
  | SurveysGetAction
  | SurveysGetSuccessAction
  | SurveysGetErrorAction
  | SurveyCreateAction
  | SurveyCreateSuccessAction
  | SurveyCreateErrorAction;
