import React from 'react';
import { QRCode, Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import Link from 'assets/icons/link.svg';
import Icon from 'components/ui/Icon/Icon';
import styles from './UploadDocumentsStep.module.sass';

interface IQRCodeSectionProps {
  requestId: string;
  uploadToken: string;
}

const QRCodeSection: React.FC<IQRCodeSectionProps> = ({ requestId, uploadToken }) => {
  const { t } = useTranslation();
  const PageLink = `${window.location.origin}/fotowizard/${requestId}/${uploadToken}`;
  return (
    <div className={styles.qrContainer}>
      <div>
        <Typography.Title level={5}>
          {t('customerFlow:costCalculations:uploadFlatDocuments:qrCode:title')}
        </Typography.Title>
        <Typography>
          {t('customerFlow:costCalculations:uploadFlatDocuments:qrCode:body')}
        </Typography>
      </div>
      <div>
        <QRCode value={PageLink} size={100} />
        <a href={PageLink} target="_blank" rel="noopener noreferrer" className={styles.link}>
          <Icon icon={Link} className={styles.icon} />
          {t('customerFlow:costCalculations:uploadFlatDocuments:qrCodeLink')}
        </a>
      </div>
    </div>
  );
};

export default QRCodeSection;
