import React from 'react';
import clsx from 'clsx';
import { Input as ANTDInput } from 'antd';
import { PasswordProps } from 'antd/es/input';
import AlertIconRed from 'assets/icons/alert-circle-red.svg';
import styles from './TextField.module.sass';
import FloatingLabel, { LabelPositionType } from '../FloatingLabel/FloatingLabel';
import Icon, { IconSize } from '../Icon/Icon';

interface ITextFieldProps extends PasswordProps {
  type?: string;
  className?: string;
  iconClassName?: string;
  label?: string;
  value?: string;
  error?: string | null;
}

const TextField: React.FC<ITextFieldProps> = (props) => {
  const { type, label, value, className, iconClassName, error, ...rest } = props;

  const inputClassName = clsx(styles.input, className, {
    [styles.invalid]: !!error,
    [styles.noLabel]: !label,
  });

  const containerClassName = clsx(styles.container, {
    [styles.invalid]: !!error,
  });

  const suffix = error ? (
    <Icon
      width="18px"
      height="18px"
      className={iconClassName}
      icon={AlertIconRed}
      size={IconSize.SMALL}
    />
  ) : null;

  const inputProps = {
    value,
    className,
    type,
    ...rest,
  };

  const Input = type === 'password' ? ANTDInput.Password : ANTDInput;

  return (
    <div className={containerClassName}>
      <FloatingLabel label={label!} value={value!} labelPosition={LabelPositionType.LEFT}>
        <Input suffix={suffix} {...inputProps} className={inputClassName} />
      </FloatingLabel>

      {error ? <span className={styles.error}>{error}</span> : null}
    </div>
  );
};

export default TextField;
