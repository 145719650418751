import React, { useMemo } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IOffer, OfferState } from 'store/offer/offer.types';
import { userSelector } from 'store/auth/auth.selectors';
import { reassignOfferInstaller } from 'store/offer/offer.actions';
import styles from './TableArrow.module.sass';
interface ITableArrowProps {
  offer?: IOffer;
}
const TableArrow: React.FC<ITableArrowProps> = ({ offer }) => {
  const { t } = useTranslation();
  const currentUser = useSelector(userSelector);
  const dispatch = useDispatch();

  const isAssignable = useMemo(
    () => currentUser.id !== offer?.installerUserId && offer?.state !== OfferState.InstallationDone,
    [currentUser, offer],
  );

  const handleAssignClick = (e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    e?.stopPropagation();
    e?.preventDefault();
    if (offer?.id) {
      dispatch(reassignOfferInstaller(offer.id, currentUser.id));
    }
  };

  return (
    <div className={styles.container}>
      {offer?.installerUserId && (
        <Popconfirm
          title={t('installerFlow:dashboard:table:popup:assign:title')}
          onConfirm={handleAssignClick}
          onCancel={(e) => {
            e?.preventDefault();
            e?.stopPropagation();
          }}
          okText={t('installerFlow:dashboard:table:popup:assign:button:confirm')}
          cancelText={t('installerFlow:dashboard:table:popup:assign:button:cancel')}
          disabled={!isAssignable}
        >
          <Button
            type="primary"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            disabled={!isAssignable}
          >
            {t(`installerFlow:dashboard:table:button:${isAssignable ? 'assign' : 'assigned'}`)}
          </Button>
        </Popconfirm>
      )}
      <ArrowRightOutlined className={styles.arrow} />
    </div>
  );
};

export default TableArrow;
