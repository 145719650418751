import config from 'config';
import HttpService from './http.service';

class VideoCallAPI extends HttpService {
  getInstallerVideoCall = (meetingId: string) => {
    return this.get(`video-meeting/installer/${meetingId}`);
  };

  getCustomerVideoCall = (meetingId: string) => {
    return this.get(`video-meeting/user/${meetingId}`);
  };
}

export default new VideoCallAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
