import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Skeleton } from 'antd';

import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useForm } from 'antd/es/form/Form';
import { JsonForms } from '@jsonforms/react';

import { useNavigate } from 'react-router-dom';
import BoxContent from 'components/layout/BoxContent/BoxContent';
import { scrollToFormField } from 'framework/scrollerUtils';
import { formatAnswersToArray, transformAnswersToFormData } from 'framework/dataTransformations';
import {
  checkRedirectEvents,
  customRenderer,
  unifySchemas,
  unifyUiSchemas,
} from 'framework/jsonForm';
import {
  ClientRequestTransitionTypes,
  IClientRequest,
} from 'store/client-request/client-request.types';
import {
  clientRequestAnswersSave,
  clientRequestUpdate,
  updateTransitionState,
} from 'store/client-request/client-request.actions';
import {
  clientRequestAnswersStateSelector,
  clientRequestStateSelector,
  currentClientRequestSelector,
} from 'store/client-request/client-request.selectors';
import { RequestState } from 'store/common.types';
import { QuestionnaireStepType } from 'types/questionnaire.types';
import { excludeQuestionnaireSteps } from 'utils/questionnaire';
import styles from './Questionnaire.module.sass';
import useClientRequestStepsHandler from '../../../hooks/useClientRequestStepsHandler';

export interface IQuestionnaireConfig {
  step: number;
  subStep: number;
  stepData: any;
  subStepData: any;
  stepsLength: number;
  subStepsLength: number;
  schema: any;
  uiSchema: any;
  answers: { [key: string]: any };
}

const Questionnaire = () => {
  const request = useSelector(currentClientRequestSelector);
  const requestState = useSelector(clientRequestStateSelector);
  const { answersState } = useSelector(clientRequestAnswersStateSelector);

  const questionnaire = useMemo(() => request?.questionnaire, [request]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = useForm();
  /// when refactor keep in mind add logic when click next on last questionnaire step
  const config = useMemo(() => {
    if (!questionnaire || request?.step! > questionnaire.steps?.length || request?.step === 1)
      return null;
    const updatedSteps = questionnaire.steps?.filter(
      (step: any) => !excludeQuestionnaireSteps.includes(step.stepType),
    );
    const step = (request?.step ?? 2) - 1;
    const subStep = request?.subStep ?? 1;
    const stepData = updatedSteps?.[step - 1];
    const subStepData = stepData?.children[subStep - 1];
    const updatedQuestionnaire = { ...questionnaire, steps: updatedSteps };
    const schema = unifySchemas(questionnaire, step - 1);
    const uiSchema = unifyUiSchemas(updatedQuestionnaire, step - 1).elements;
    const answers = schema ? transformAnswersToFormData(schema, request?.answers || []) : {};

    return {
      step,
      subStep,
      stepData,
      subStepData,
      stepsLength: updatedSteps?.length || 0,
      subStepsLength: stepData?.children?.length || 0,
      schema,
      uiSchema,
      answers,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaire, request]);

  const substepElements = useMemo(() => config && config.uiSchema[config.subStep - 1], [config]);
  const { canGoToStep, changeStep } = useClientRequestStepsHandler();
  const canGoBack = useMemo(
    () => !!(config?.subStep && config.subStep > 1) || canGoToStep(1),
    [config, canGoToStep],
  );
  const updateCR = (data: Partial<IClientRequest>) =>
    dispatch(clientRequestUpdate(request?.id!, data, () => onSuccessRequestUpdate(data)));
  const navigateToNextPage = useCallback(() => {
    navigate(`/customer/client-request/${request?.id}/request`);
  }, [navigate, request]);
  const onSuccessRequestUpdate = useCallback(
    (data: IClientRequest) => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      if (data?.step === 2) return;
      if (request?.state !== 'submitted') {
        dispatch(
          updateTransitionState(
            request?.id!,
            ClientRequestTransitionTypes.accepted,
            undefined,
            navigateToNextPage,
          ),
        );
        return;
      }
      navigateToNextPage();
    },
    [request, dispatch, navigateToNextPage],
  );
  const prevStep = useCallback(() => {
    if (!config) return;
    if (config.subStep === 1) {
      const previousSubStep = request?.products && request?.products?.length > 0 ? 2 : 1;
      changeStep(1, previousSubStep);
      return;
    }
    changeStep(2, config.subStep - 1);
  }, [config, changeStep, request]);
  if (!config) {
    return (
      <div style={{ padding: '70px 60px 0' }}>
        <Skeleton
          active={true}
          paragraph={{
            rows: 3,
          }}
          round={true}
        />
      </div>
    );
  }

  const handleNextClick = async () => {
    try {
      const values = await form.validateFields();
      const redirect = checkRedirectEvents(substepElements.elements, values);
      if (redirect) navigate(redirect);
      const isLast = config.subStep === config.subStepsLength;
      const steps = {
        step: isLast ? request?.step! + 1 : request?.step,
        subStep: isLast ? 1 : config.subStep + 1,
      };

      if (request?.id) {
        const answers = formatAnswersToArray(values);
        dispatch(clientRequestAnswersSave(request?.id, answers, () => updateCR({ ...steps })));
      }
    } catch (e: any) {
      if (e?.errorFields) {
        const errorField = e?.errorFields[0];
        if (errorField && errorField.name[0]) {
          scrollToFormField(errorField.name[0]);
          return;
        }
      }
      throw e;
    }
  };

  if (config?.stepData?.stepType === QuestionnaireStepType.CostCalculation) return null;

  return (
    <BoxContent
      boxClassName={styles.container}
      onNextClick={handleNextClick}
      onBackClick={prevStep}
      showNextButton={true}
      showBackButton={canGoBack}
      isContentLoading={
        (requestState && [RequestState.Loading, RequestState.Saving].includes(requestState)) ||
        [RequestState.Loading, RequestState.Saving].includes(answersState) ||
        request?.step !== 2
      }
    >
      <Helmet>
        <title>
          {t(config?.stepData?.title)} - {t(config?.subStepData.title)} - Tendergy
        </title>
      </Helmet>
      <Form form={form}>
        <JsonForms
          schema={config.schema}
          uischema={substepElements}
          renderers={customRenderer}
          data={config.answers}
        />
      </Form>
    </BoxContent>
  );
};

export default Questionnaire;
