import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Typography from 'antd/lib/typography';
import Form from 'antd/lib/form';
import manWithWoman from 'assets/images/man-with-woman.svg';
import BoxContent from 'components/layout/BoxContent/BoxContent';
import TextField from 'components/ui/TextField/TextField';
import AddressQuestion from 'components/questions/AddressQuestion/AddressQuestion';
import DropDown from 'components/ui/DropDown/DropDown';

import CustomerLayout from 'components/layout/CustomerLayout/CustomerLayout';
import { ValidationFactory, ValidationMessagesFactory } from 'framework/validations';
import { changeStep } from 'store/app/app.actions';
import { leadCreate } from 'store/lead/lead.actions';
import { LeadState } from 'store/lead/lead.types';
import { leadCreateStateSelector } from 'store/lead/lead.selectors';
import { RequestState } from 'store/common.types';

import { isRegistrationInviteTokenSelector } from 'store/register/register.selectors';
import { countriesListSelector } from 'store/country/country.selectors';
import { countriesGet } from 'store/country/country.actions';
import styles from './LeadPage.module.sass';

const LeadPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isSuccesPage, setSuccesPage] = useState(false);
  const leadCreateState = useSelector(leadCreateStateSelector);
  const inviteTokenData = useSelector(isRegistrationInviteTokenSelector);
  const countries = useSelector(countriesListSelector);

  const addressCountries = inviteTokenData?.clientRequestPool?.countries || countries;

  useEffect(() => {
    dispatch(countriesGet());
    dispatch(changeStep(1, 3));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (isLoading && leadCreateState === RequestState.Success) {
      setLoading(false);
      setSuccesPage(true);
    }
    if (isLoading && leadCreateState === RequestState.Error) {
      setLoading(false);
    }
  }, [leadCreateState]); // eslint-disable-line

  const onNextClick = async () => {
    const values = await form.validateFields();
    const poolId = inviteTokenData?.clientRequestPool?.id;
    dispatch(
      leadCreate({
        ...values,
        leadType: 'companyInstallation',
        state: LeadState.NEW,
        pool: poolId && { id: poolId },
      }),
    );
    setLoading(true);
  };

  const boxProps = {
    title: t('stepper:installer:step2'),
    showNextButton: !isSuccesPage,
    showBackButton: !isSuccesPage,
    onNextClick,
    onBackClick: () => navigate('/customer'),
    boxContentClassName: styles.container,
    nextButtonLoading: isLoading,
  };

  const formProps = {
    form,
    name: 'lead',
    validateMessages: ValidationMessagesFactory,
    className: styles.questionForm,
  };

  const companyNameProps = {
    name: 'companyName',
    rules: [ValidationFactory.REQUIRED],
  };

  const contactPersonNameProps = {
    name: 'contactPersonName',
    rules: [ValidationFactory.REQUIRED],
  };
  const numberOfCarsProps = {
    name: 'numberOfCars',
    rules: [ValidationFactory.REQUIRED],
  };

  const contactPersonEmailProps = {
    name: 'contactPersonEmail',
    rules: [ValidationFactory.REQUIRED, ValidationFactory.EMAIL],
  };

  const contactPersonPhoneProps = {
    name: 'contactPersonPhone',
  };
  const addressProps = {
    name: 'address',
    rules: [ValidationFactory.ADDRESS_INPUTS('customerFlow:clientRequest:fillAddressDetails')],
  };

  const initiatorNameProps = {
    name: 'initiatorName',
  };

  const ddProps = {
    options: ['<11', '11-50', '51-100', '>100'].map((i) => ({ value: i, label: i })),
    name: 'numberOfCompanyCars',
    placeholder: t('customerFlow:lead:numberOfCars'),
  };

  return (
    <CustomerLayout>
      <BoxContent {...boxProps}>
        {!isSuccesPage ? (
          <Form {...formProps}>
            <div className={styles.title}>{t('customerFlow:lead:title')}</div>
            <Form.Item {...companyNameProps}>
              <TextField label={t('customerFlow:lead:companyName')} />
            </Form.Item>
            <Form.Item {...numberOfCarsProps}>
              <DropDown {...ddProps} />
            </Form.Item>
            <Form.Item {...contactPersonNameProps}>
              <TextField label={t('customerFlow:lead:companyContactPersonName')} />
            </Form.Item>
            <Form.Item {...contactPersonPhoneProps}>
              <TextField label={t('customerFlow:lead:companyContactPersonPhone')} />
            </Form.Item>
            <Form.Item {...contactPersonEmailProps}>
              <TextField label={t('customerFlow:lead:companyContactPersonEmail')} />
            </Form.Item>
            <Form.Item {...initiatorNameProps}>
              <TextField label={t('customerFlow:lead:yourName')} />
            </Form.Item>
            <Form.Item {...addressProps}>
              <AddressQuestion
                form={formProps.form}
                name={'address'}
                filteredCountries={addressCountries}
                isCountryDisabled={true}
              />
            </Form.Item>
          </Form>
        ) : (
          <div className={styles.successBox}>
            <h3 className={styles.title}>{t('customerFlow:lead:successTitle')}</h3>

            <Typography.Paragraph className={styles.text}>
              {t('customerFlow:lead:successDescription')}
            </Typography.Paragraph>

            <img className={styles.manImage} src={manWithWoman} alt="Man with woman" />
          </div>
        )}
      </BoxContent>
    </CustomerLayout>
  );
};

export default LeadPage;
