import React, { useState, useMemo, useRef } from 'react';
import { useEffect } from 'react';
// @ts-ignore
import AccCore from 'opentok-accelerator-core';
import { Button, notification, Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { CameraFilled, QuestionOutlined } from '@ant-design/icons';
import { IOffer } from 'store/offer/offer.types';
import configBuilder, { containerClasses } from '../helpers';
import SettingSection from '../SettingSection';

import styles from '../VideoCall.module.sass';
import SnapShotSection from '../SnapShotSection';
import QuestionnaireSection from '../QuestionnaireSection';

interface ICallProps {
  offer: IOffer;
  config: any;
  endCall: (shouldChangeTab: boolean) => void;
}

const Call: React.FC<ICallProps> = ({ offer, config, endCall }) => {
  // eslint-disable-next-line no-undef
  const openTok = OT;

  const { t } = useTranslation();

  const snapShotRef = useRef();

  const [localAudioEnabled, setLocalAudioEnabled] = useState(true);
  const [localVideoEnabled, setLocalVideoEnabled] = useState(true);
  const [vonageInstance, setVonageInstance] = useState<any>(null);
  const [publisher, setPublisher] = useState(null);
  const [subscribers, setSubscribers] = useState<any>(null);
  const [meta, setMeta] = useState(null);
  const [questionnaireVisible, setQuestionnaireVisible] = useState(true);
  const [shouldShowLoading, setShowLoading] = useState(false);

  const callConfig = useMemo(
    () => ({
      insertMode: 'append',
      width: '100%',
      height: '100%',
      fitMode: 'cover',
      mirror: false,
      publishVideo: localVideoEnabled,
      publishAudio: localAudioEnabled,
      style: { buttonDisplayMode: 'off' },
    }),
    [localAudioEnabled, localVideoEnabled],
  );

  useEffect(() => {
    const { apiKey, sessionId, token } = config;
    const callPublisher = new AccCore(configBuilder(apiKey, sessionId, token));
    setVonageInstance(callPublisher);
    callPublisher
      .connect()
      .then(() => {
        callPublisher
          .startCall(callConfig)
          .then((res: any) => {
            const { meta, subscribers, publisher } = res;
            setPublisher(publisher);
            setSubscribers(subscribers);
            setMeta(meta);
          })
          .catch((error: any) => {
            notification.error({ message: t('remoteHomeCheck:errors:startCall') });
          });
      })
      .catch((error: any) => {
        notification.error({ message: t('remoteHomeCheck:errors:connectMeeting') });
      });

    const events = [
      'subscribeToCamera',
      'unsubscribeFromCamera',
      'subscribeToScreen',
      'unsubscribeFromScreen',
      'startScreenShare',
    ];

    events.forEach((event) =>
      // @ts-ignore
      callPublisher.on(event, ({ meta, subscribers, publisher }) => {
        setPublisher(publisher);
        setSubscribers(subscribers);
        setMeta(meta);
      }),
    );
    return () => {
      callPublisher.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleLocalAudio = () => {
    vonageInstance?.toggleLocalAudio(!localAudioEnabled);
    setLocalAudioEnabled(!localAudioEnabled);
  };

  const toggleLocalVideo = () => {
    vonageInstance?.toggleLocalVideo(!localVideoEnabled);
    setLocalVideoEnabled(!localVideoEnabled);
  };

  const takeSnapshot = () => {
    const isCustomerCameraOn =
      subscribers && subscribers.camera && Object.keys(subscribers.camera).length !== 0;

    if (isCustomerCameraOn)
      // @ts-ignore
      snapShotRef?.current?.onTakeSnapShot();
    else notification.warning({ message: t('remoteHomeCheck:errors:customerCameraNotAvailable') });
  };

  const {
    localAudioClass,
    localVideoClass,
    localCallClass,
    controlClass,
    cameraPublisherClass,
    screenPublisherClass,
    cameraSubscriberClass,
    screenSubscriberClass,
  } = containerClasses(true, meta, localAudioEnabled, localVideoEnabled, false);

  return (
    <>
      <div className={styles.container}>
        {shouldShowLoading && <Spin fullscreen />}
        <div className={styles.videoSection}>
          <div className="App-main">
            <div className="App-video-container" style={{ position: 'relative' }}>
              <div id="cameraPublisherContainer" className={cameraPublisherClass} />
              <div id="screenPublisherContainer" className={screenPublisherClass} />
              <div id="cameraSubscriberContainer" className={cameraSubscriberClass} />
              <div id="screenSubscriberContainer" className={screenSubscriberClass} />
              <div className={styles.topLeft}>
                <SettingSection OT={openTok} publisher={publisher} />
              </div>
              <div className={styles.topRight}>
                <Tooltip title="Questionnaire">
                  <Button
                    shape="circle"
                    style={{ backgroundColor: 'inherit' }}
                    icon={<QuestionOutlined style={{ color: 'white' }} />}
                    size={'small'}
                    onClick={() => setQuestionnaireVisible(true)}
                  />
                </Tooltip>
              </div>
            </div>
            <div id="controls" className={controlClass}>
              <div className={localAudioClass} onClick={toggleLocalAudio} />
              <div className={localVideoClass} onClick={toggleLocalVideo} />
              <div className={localCallClass} onClick={() => endCall(true)} />
              <div className={'ots-video-control circle take-snapshot'} onClick={takeSnapshot}>
                <Tooltip title={t('remoteHomeCheck:tooltip:takeSnapShot')}>
                  <CameraFilled className={styles.cameraIcon} />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        {subscribers && config?.questionnaire && (
          <QuestionnaireSection
            questionnaire={config.questionnaire}
            clientRequestId={offer?.clientRequest?.id!}
            offerId={offer.id!}
            visible={questionnaireVisible}
            onEndCall={() => {
              endCall(false);
              setShowLoading(true);
            }}
            onClose={(value: boolean) => setQuestionnaireVisible(value)}
          />
        )}
      </div>
      <div>
        {subscribers && (
          <SnapShotSection offer={offer} subscribers={subscribers} ref={snapShotRef} />
        )}
      </div>
    </>
  );
};

export default Call;
