import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from 'antd';
import FormRadioControl, { ControlType } from 'components/form/components/FormRadioControl';
import { ValidationFactory } from 'framework/validations';
import { MediaCategoryType } from 'store/client-request/client-request.types';
import { PoolSettingsFieldsType, DocumentationModeOptionsType } from 'types/pool-settings.types';

const DocumentationSetting = () => {
  const { t } = useTranslation();

  return (
    <>
      <Form.Item
        name={PoolSettingsFieldsType.DocumentationMode}
        label={t('intermediate:myProjects:poolSettings:documentationMode:title')}
        labelAlign="left"
      >
        <FormRadioControl
          type={ControlType.Radio}
          options={[
            {
              value: DocumentationModeOptionsType.Automatic,
              label: t('intermediate:myProjects:poolSettings:documentationMode:automatic'),
            },
            {
              value: DocumentationModeOptionsType.Manual,
              label: t('intermediate:myProjects:poolSettings:documentationMode:manual'),
            },
          ]}
        />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Form.Item
            name={PoolSettingsFieldsType.DocumentsAllowed}
            label={t('intermediate:myProjects:poolSettings:documentsAllowed:title')}
            labelAlign="left"
            rules={[ValidationFactory.REQUIRED]}
          >
            <FormRadioControl
              type={ControlType.Checkbox}
              disabled={
                getFieldValue(PoolSettingsFieldsType.DocumentationMode) ===
                DocumentationModeOptionsType.Automatic
              }
              options={[
                {
                  value: MediaCategoryType.OfferInvoiceInstallation,
                  label: t(
                    'intermediate:myProjects:poolSettings:documentsAllowed:invoiceInstallation',
                  ),
                  disabled: true,
                },
                {
                  value: MediaCategoryType.InstallationFinalDocumentation,
                  label: t(
                    'intermediate:myProjects:poolSettings:documentsAllowed:finalDocumentation',
                  ),
                },
                {
                  value: MediaCategoryType.OfferNetworkRegistration,
                  label: t('intermediate:myProjects:poolSettings:documentsAllowed:permitAgreement'),
                },
              ]}
            />
          </Form.Item>
        )}
      </Form.Item>
    </>
  );
};

export default DocumentationSetting;
