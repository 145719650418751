import config from 'config';
import { APP_NAME, FINGERPRINT_KEY, REFRESH_TOKEN_KEY } from 'framework/constants';
import HttpService from './http.service';
import { IUserCredentials, IVerifyEmail, IUserRegister } from '../store/auth/auth.types';

class AuthAPI extends HttpService {
  login = (params: IUserCredentials) => {
    return this.post('auth/login', { ...params, context: 'tendergy' });
  };

  tokenLogin = (token: string) => {
    return this.post(`auth/login/${token}`);
  };

  register = (params: IUserRegister) => {
    return this.post('auth/register', { ...params, context: 'tendergy' });
  };

  verifyEmail = (params: IVerifyEmail) => {
    return this.patch('auth/profile/email/verify', { ...params, context: 'tendergy' });
  };

  // Send new one
  updateEmail = (email: string) => {
    return this.post('auth/change-email', { email, context: 'tendergy' });
  };

  // Confirm new one by token
  updateEmailVerify = (params: IVerifyEmail) => {
    return this.patch('auth/profile/email', { ...params, context: 'tendergy' });
  };

  tokenRefresh = () => {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY) || '';
    const fingerprint = localStorage.getItem(FINGERPRINT_KEY) || '';

    return this.post(
      'auth/refresh',
      {
        refreshToken,
        context: APP_NAME,
        fingerprint,
      },
      true,
    );
  };

  logout = (fingerprint: string, refreshToken: string) => {
    return this.post('auth/logout', { fingerprint, refreshToken, context: 'tendergy' });
  };

  passwordResetRequest = (email: string) => {
    return this.post('auth/recover', { email, context: 'tendergy' });
  };

  passwordResetConfirm = (passResetToken: string) => {
    return this.get(`auth/reset/${passResetToken}`, { context: 'tendergy' });
  };

  passwordResetUpdate = (passResetToken: string, newPassword: string, userId?: string) => {
    return this.post(`auth/${userId ? 'integration/' : ''}reset`, {
      token: passResetToken,
      password: newPassword,
      userId,
      context: 'tendergy',
    });
  };

  changePassword = (data: {
    currentPassword: string;
    newPassword: string;
    newPasswordConfirmation: string;
    fingerprint: string;
    refreshToken: string;
  }) => {
    return this.patch('auth/profile/password', { ...data, context: 'tendergy' });
  };

  registerInstaller = (params: any) => {
    return this.post('auth/register/installer', { ...params, context: 'tendergy' });
  };

  resendVerificationEmail = () => {
    return this.post(`auth/email-verify-resend`, { context: 'tendergy' });
  };
}

export default new AuthAPI({
  apiURL: config.CRAFT_SERVICE_URL_V2,
});
