import React, { useEffect, useState } from 'react';
import { Col, notification, Row } from 'antd';
import { Publisher } from 'opentok-react/types/opentok';
import { useTranslation } from 'react-i18next';
import { IOffer } from 'store/offer/offer.types';
import SettingSection from '../SettingSection';

import styles from '../VideoCall.module.sass';
import { containerClasses } from '../helpers';
import StartCallSection from '../StartCallSection';

interface IPrecallProps {
  offer: IOffer;
  guest: any;
  startCall: () => void;
}

const Precall: React.FC<IPrecallProps> = ({ offer, guest, startCall }) => {
  // eslint-disable-next-line no-undef
  const openTok = OT;

  const { t } = useTranslation();

  const [publisher, setPublisher] = useState<Publisher>();
  const [localAudioEnabled, setLocalAudioEnabled] = useState<boolean>(true);
  const [localVideoEnabled, setLocalVideoEnabled] = useState<boolean>(true);

  const { localAudioClass, localVideoClass } = containerClasses(
    false,
    null,
    localAudioEnabled,
    localVideoEnabled,
    false,
  );

  useEffect(() => {
    const preCallPublisher = openTok.initPublisher(
      // @ts-ignore
      'Pre-call-window',
      {
        insertMode: 'append',
        width: '100%',
        height: '100%',
        fitMode: 'cover',
        mirror: false,
        publishVideo: localVideoEnabled,
        publishAudio: localAudioEnabled,
        style: { buttonDisplayMode: 'off', audioLevelDisplayMode: 'on' },
      },
      function (error) {
        if (error) {
          if (error.code === 1500) {
            notification.error({ message: t('remoteHomeCheck:errors:noAccessDevices') });
            setLocalAudioEnabled(false);
            setLocalVideoEnabled(false);
          }
        }
      },
    );
    preCallPublisher.on({
      mediaStopped: () => {
        notification.error({ message: t('remoteHomeCheck:errors:noAccessDevices') });
        setLocalAudioEnabled(false);
        setLocalVideoEnabled(false);
      },
    });
    setPublisher(preCallPublisher);
    return () => {
      preCallPublisher.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.preCallSection}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="center">
        <Col xs={24} sm={20} md={14} lg={14} xl={14}>
          <div
            className={styles.preCallWindow}
            id={'Pre-call-window'}
            style={{ position: 'relative' }}
          >
            <div className={styles.topLeft}>
              <SettingSection publisher={publisher} />
            </div>
          </div>
          <div className={'App-control-container'}>
            <div
              className={localAudioClass}
              onClick={() => {
                publisher?.publishAudio(!localAudioEnabled);
                setLocalAudioEnabled(!localAudioEnabled);
              }}
            />
            <div
              className={localVideoClass}
              onClick={() => {
                publisher?.publishVideo(!localVideoEnabled);
                setLocalVideoEnabled(!localVideoEnabled);
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={10} lg={10} xl={10} className={styles.centerContent}>
          <StartCallSection offer={offer} user={guest} startCall={() => startCall()} />
        </Col>
      </Row>
    </div>
  );
};

export default Precall;
