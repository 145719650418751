import { ILead, LEAD_CREATE, LEAD_CREATE_ERROR, LEAD_CREATE_SUCCESS } from './lead.types';

//Create Lead
export const leadCreate = (payload: ILead) => ({
  type: LEAD_CREATE,
  payload: payload,
});

export const leadCreateSuccess = (payload: any) => ({
  type: LEAD_CREATE_SUCCESS,
  payload: payload,
});

export const leadCreateError = (error: any) => ({
  type: LEAD_CREATE_ERROR,
  payload: error,
});
