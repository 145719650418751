/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import clsx from 'clsx';
import styles from './Tabs.module.sass';

export interface ListItem {
  title: string;
  value: string;
  number?: number;
  action: any;
}

export interface IProjectsSidebarProps {
  className?: string;
  selected?: string;
  list: ListItem[];
  ellipsis?: boolean;
}

const Tabs: React.FC<IProjectsSidebarProps> = (props) => {
  const { className, list, selected, ellipsis = false } = props;
  return (
    <div className={clsx([styles.container, className])}>
      {list.map((item) => {
        const itemClasses = clsx(styles.item, {
          [styles.selected]: item.value === selected,
          [styles.ellipsis]: ellipsis,
        });

        return (
          <div key={item.value} className={itemClasses} onClick={item.action}>
            <div className={styles.title}>{item.title}</div>
            {!!item.number && <div className={styles.number}>{item.number}</div>}
          </div>
        );
      })}
    </div>
  );
};

Tabs.defaultProps = {
  list: [],
};

export default Tabs;
