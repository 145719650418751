/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import Form from 'antd/lib/form';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import {
  getClientRequestFiles,
  getCustomerDocumentUploadToken,
} from 'store/client-request/client-request.actions';
import {
  currentClientRequestFilesSelector,
  currentClientRequestSelector,
  fotoWizardUploadToken,
} from 'store/client-request/client-request.selectors';
import { QuestionKey } from 'store/client-request/client-request.types';
import UploadDocumentBody from './UploadDocumentBody';
import QRCodeSection from './QRCodeSection';

import styles from './UploadDocumentsStep.module.sass';

interface IUploadDocumentsStepProps {
  form?: any;
  fotoWizard?: boolean;
}

const UploadDocumentsStep: React.FC<IUploadDocumentsStepProps> = (
  props: IUploadDocumentsStepProps,
) => {
  const { form } = props;
  const dispatch = useDispatch();
  const request = useSelector(currentClientRequestSelector);
  const requestFiles = useSelector(currentClientRequestFilesSelector);
  const uploadToken = useSelector(fotoWizardUploadToken);
  const { t } = useTranslation();

  // Get all uploaded documents at component mount
  useEffect(() => {
    if (request && request.id) {
      dispatch(getClientRequestFiles(request.id));
      dispatch(getCustomerDocumentUploadToken(request.id));
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    form.setFieldsValue(requestFiles);
  }, [requestFiles, form]);

  const houseType = request?.answers?.find((item) => item.questionKey === QuestionKey.HouseType);
  return (
    <div className={styles.container}>
      <Typography.Title level={4}>
        {t('customerFlow:clientRequest:fileUpload:headline')}
      </Typography.Title>
      <Trans i18nKey="customerFlow:clientRequest:fileUpload:text" />
      {!request?.questionnaireSkipped && (
        <Form form={form} name="request-upload-documents-form">
          <UploadDocumentBody appartments={houseType?.answerValue.value === 'apartment'} />
        </Form>
      )}
      <QRCodeSection requestId={request?.id!} uploadToken={uploadToken?.token} />
    </div>
  );
};

export default UploadDocumentsStep;
