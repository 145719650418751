import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Form from 'antd/lib/form';
import ReCAPTCHA from 'react-google-recaptcha';
import TextField from 'components/ui/TextField/TextField';
import { popoverValidations, ValidationFactory } from 'framework/validations';
import { GOOGLE_CAPTCHA_SITE_KEY } from 'framework/constants';

interface Props {
  termsChecked: boolean;
  onTermsChange(value: boolean): void;
  onOpenProtectionModal(): void;
  onOpenTermsModal(): void;
}

const PasswordStep: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const [validations, setValidations] = useState(popoverValidations);

  const passwordRepeatValidator = ({ getFieldValue }: any) => ({
    validator(rule: any, value: string) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }

      return Promise.reject(t('common:errors:passwordsNotSame'));
    },
  });

  const checkPassword = (event: any) => {
    const { value } = event.target;

    const checkedValidations = validations.map((item) => ({
      ...item,
      checked: item.validator(value),
    }));

    setValidations(checkedValidations);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Form.Item
          name="password"
          rules={[
            ValidationFactory.REQUIRED,
            ValidationFactory.PASSWORD,
            ValidationFactory.PASSWORD_MIN_LENGTH,
          ]}
        >
          <TextField
            label={t('installerFlow:registration:setPassword')}
            type="password"
            onInput={checkPassword}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="repeat-password"
          rules={[ValidationFactory.REQUIRED, passwordRepeatValidator]}
        >
          <TextField label={t('installerFlow:registration:repeatPassword')} type="password" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="captcha">
          <ReCAPTCHA key={i18n.language} hl={i18n.language} sitekey={GOOGLE_CAPTCHA_SITE_KEY!} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Checkbox
          onChange={(e) => {
            props.onTermsChange(e.target.checked);
          }}
          checked={props.termsChecked}
        >
          {t('common:texts:iAgreeToThe')}{' '}
          <Link to="#" onClick={props.onOpenTermsModal}>
            {t('common:texts:termsOfUse')}
          </Link>{' '}
          {t('common:texts:and')}{' '}
          <Link to="#" onClick={props.onOpenProtectionModal}>
            {t('common:texts:dataDeclaration')}
          </Link>
        </Checkbox>
      </Col>
    </Row>
  );
};

export default PasswordStep;
