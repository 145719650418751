import { produce } from 'immer';
import {
  DELETE_POOL_ORGANIZATIONS,
  DELETE_POOL_ORGANIZATIONS_ERROR,
  DELETE_POOL_ORGANIZATIONS_SUCCESS,
  GET_POOL_ORGANIZATIONS,
  GET_POOL_ORGANIZATIONS_ERROR,
  GET_POOL_ORGANIZATIONS_SUCCESS,
  IPoolTenderOrganizationState,
  PoolTenderOrganizationActionTypes,
  UPDATE_POOL_ORGANIZATIONS,
  UPDATE_POOL_ORGANIZATIONS_ERROR,
  UPDATE_POOL_ORGANIZATIONS_SUCCESS,
} from './pool-tender-organization.types';
import { RequestState } from '../common.types';

const initialState: IPoolTenderOrganizationState = {
  organizations: [],
  organizationsState: null,
  organizationsError: null,

  organizationsUpdateState: null,
  organizationsUpdateError: null,

  organizationsDeleteState: null,
  organizationsDeleteError: null,
};

const PoolOrganizationReducer = (state = initialState, action: PoolTenderOrganizationActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_POOL_ORGANIZATIONS:
        draft.organizationsState = RequestState.Loading;
        break;

      case GET_POOL_ORGANIZATIONS_ERROR:
        draft.organizationsState = RequestState.Error;
        draft.organizationsError = action.payload;
        break;

      case GET_POOL_ORGANIZATIONS_SUCCESS:
        draft.organizationsState = RequestState.Success;
        draft.organizationsError = null;
        draft.organizations = action.payload;
        break;
      case UPDATE_POOL_ORGANIZATIONS:
        draft.organizationsUpdateState = RequestState.Loading;
        break;

      case UPDATE_POOL_ORGANIZATIONS_ERROR:
        draft.organizationsUpdateState = RequestState.Error;
        draft.organizationsUpdateError = action.payload;
        break;

      case UPDATE_POOL_ORGANIZATIONS_SUCCESS:
        draft.organizationsUpdateState = RequestState.Success;
        draft.organizationsUpdateError = null;
        draft.organizations = state.organizations.map((o) =>
          o.id === action.payload.id ? { ...o, ...action.payload } : o,
        );
        break;
      case DELETE_POOL_ORGANIZATIONS:
        draft.organizationsDeleteState = RequestState.Loading;
        break;

      case DELETE_POOL_ORGANIZATIONS_ERROR:
        draft.organizationsDeleteState = RequestState.Error;
        draft.organizationsDeleteError = action.payload;
        break;

      case DELETE_POOL_ORGANIZATIONS_SUCCESS:
        draft.organizationsDeleteState = RequestState.Success;
        draft.organizationsDeleteError = null;
        draft.organizations = state.organizations.filter((o) => o.id !== action.payload);
        break;
    }
  });

export default PoolOrganizationReducer;
