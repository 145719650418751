import config from 'config';
import HttpService from './http.service';
import { IFeedback } from '../store/feedback/feedback.types';

class FeedbackAPI extends HttpService {
  getFeedback = (referenceId: string, referenceType: string, author: string) => {
    return this.get(`feedback/`, {
      referenceId,
      referenceType,
      author,
    });
  };

  createFeedback = (feedback: IFeedback) => {
    return this.post(`feedback/`, { ...feedback });
  };
}

export default new FeedbackAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
