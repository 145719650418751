import { useEffect, useMemo } from 'react';
import { FormInstance } from 'antd';
import { SupportedLanguages } from 'framework/constants';
import { languageSelectorOptions } from 'utils/supprotedLanguagesUtils';

const useFormBasedLanguageHandler = (
  fieldName: string,
  watcher?: { lang?: SupportedLanguages }[],
  form?: FormInstance,
) => {
  const possibleOptions = useMemo(
    () =>
      languageSelectorOptions.filter(
        (_languageSelectorOption) =>
          !watcher?.some((_field) => _field?.lang === _languageSelectorOption.value),
      ),
    [watcher],
  );

  useEffect(() => {
    if (possibleOptions.length === 0 || !form || !watcher) return;
    // Check if last item is empty
    const lastItemIndex = watcher.length - 1;
    // Set the first item in possible options
    if (!watcher[lastItemIndex]?.lang)
      form.setFieldValue([fieldName, lastItemIndex, 'lang'], possibleOptions[0].value);
  }, [watcher, form, possibleOptions, fieldName]);
  const canAddMoreOptions = useMemo(() => {
    if (!watcher) return true;
    return watcher?.length < languageSelectorOptions.length;
  }, [watcher]);
  return { possibleOptions, canAddMoreOptions };
};
export default useFormBasedLanguageHandler;
