import { ValidationFactory } from 'framework/validations';

export const componentColumns = [
  {
    heading: 'discription',
    name: 'description',
    span: 10,
    inputType: 'text',
    rules: [ValidationFactory.REQUIRED],
  },
  {
    heading: 'quantity',
    name: 'quantity',
    span: 2,
    inputType: 'number',
    decimalScale: 0,
  },
  {
    heading: 'unit',
    name: 'unit',
    span: 3,
    inputType: 'text',
  },
  {
    heading: 'priceNet',
    name: 'price',
    span: 4,
    inputType: 'number',
  },
];
