import React, { useState } from 'react';
import { ModalProps } from 'antd/lib/modal';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { useTranslation } from 'react-i18next';
import styles from './ReminderModal.module.sass';
import ModalContainer from '../ModalContainer';

interface Props extends ModalProps {
  visible: boolean;
  onSubmit: (value: any) => void;
  onCancel: () => void;
}

const ReminderModal: React.FC<Props> = ({ onSubmit, onCancel, visible }) => {
  const { t } = useTranslation();

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        // @ts-ignore
        convertFromHTML(`
          <p>${t('intermediate:myProjects:reminderModal:text:p1')}</p>
          <p>${t('intermediate:myProjects:reminderModal:text:p2')}</p>
      `),
      ),
    ),
  );

  const modalProps = {
    visible,
    onCancel,
    onSubmit: () => {
      const content = stateToHTML(editorState.getCurrentContent());
      onSubmit(content);
    },
    modalTitle: t('intermediate:myProjects:reminderModal:title'),
  };

  const editorProps = {
    toolbar: {
      options: ['inline', 'list', 'remove', 'history', 'emoji'],
      inline: {
        options: ['bold', 'italic', 'underline'],
      },
      list: {
        options: ['unordered', 'ordered'],
      },
    },
    wrapperClassName: styles.editorContainer,
    editorClassName: styles.editorTextArea,
    editorState,
    onEditorStateChange: (editorState: any) => {
      setEditorState(editorState);
    },
  };

  return (
    <ModalContainer {...modalProps}>
      <Editor {...editorProps} />
    </ModalContainer>
  );
};

export default ReminderModal;
