import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'framework/dateUtils';
import { CommentType } from 'store/client-request/client-request.types';
import { isQuestionsSelector } from 'store/client-request/client-request.selectors';
import styles from './ChatGroup.module.sass';

type GroupType = {
  title: string;
  icon: any;
  type: CommentType;
};

interface IChatGroupProps {
  group: GroupType;
  onGroupClick(type: CommentType): void;
}

const ChatGroup: React.FC<IChatGroupProps> = ({ group, onGroupClick }) => {
  const { t } = useTranslation();
  const questionsList = useSelector(isQuestionsSelector);

  const getLatestComment = () => {
    const filtered = questionsList.filter((q) => q.type === group.type);
    return filtered[filtered.length - 1];
  };

  const getAnswerAuthor = () => {
    switch (group.type) {
      case CommentType.Public:
      case CommentType.Private:
        return latestComment?.parentCommentId
          ? t('installerFlow:tenderDetails:qaBox:customersAnswer')
          : t('installerFlow:tenderDetails:qaBox:installer');

      case CommentType.PrivateInstaller:
      default:
        return null;
    }
  };

  const handleGroupClick = () => {
    onGroupClick(group.type);
  };

  const latestComment = getLatestComment();
  const commentAuthor = latestComment ? getAnswerAuthor() : '';
  const commentDate = latestComment
    ? formatDate(new Date(latestComment.createdAt), 'HH:mm • DD/MM/YY')
    : '';

  return (
    <div key={group.title} className={styles.group} onClick={handleGroupClick}>
      <div className={styles.groupIcon}>{group.icon}</div>
      <div className={styles.groupInfo}>
        <div className={styles.groupTitleContainer}>
          <span className={styles.groupTitle}>{group.title}</span>
          {commentDate && <span className={styles.groupDate}>{commentDate}</span>}
        </div>
        {latestComment && (
          <>
            <div className={styles.groupSender}>{commentAuthor && `${commentAuthor}:`}</div>
            <div className={styles.groupMessage}>{latestComment.text}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChatGroup;
