import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setGlobalError } from 'store/app/app.actions';
import { verifyInstaller } from 'store/auth/auth.actions';
import {
  isAuthorizedSelector,
  isCustomerSelector,
  isInstallerManagerSelector,
  isInstallerSelector,
  isIntermediateManagerSelector,
  isVerifyInstallerSucceededSelector,
} from 'store/auth/auth.selectors';

const Home = () => {
  const dispatch = useDispatch();
  const isAuthorized = useSelector(isAuthorizedSelector);
  const isInstaller = useSelector(isInstallerSelector);
  const isCustomer = useSelector(isCustomerSelector);
  const isIntermediateManager = useSelector(isIntermediateManagerSelector);
  const isInstallerManager = useSelector(isInstallerManagerSelector);
  const navigate = useNavigate();
  const installerVerified = useSelector(isVerifyInstallerSucceededSelector);

  useEffect(() => {
    if (isAuthorized != null && !isAuthorized) {
      return navigate('/');
    }

    if (isAuthorized && isIntermediateManager) {
      return navigate('/my-projects');
    }

    if (isAuthorized && isCustomer) {
      navigate('/customer');
    }

    if (
      isAuthorized &&
      !isInstaller &&
      !isCustomer &&
      !isIntermediateManager &&
      !isInstallerManager
    ) {
      dispatch(setGlobalError('userRoleUndefined'));
      return navigate('/error');
    }
  }, [
    dispatch,
    isAuthorized,
    isCustomer,
    isInstaller,
    isInstallerManager,
    isIntermediateManager,
    navigate,
  ]);

  useEffect(() => {
    const installerRelatedRoles = isInstaller || isInstallerManager;

    if (isAuthorized && installerRelatedRoles) {
      dispatch(verifyInstaller());
    }
  }, [dispatch, isAuthorized, isInstaller, isInstallerManager]);

  useEffect(() => {
    const installerRelatedRoles = isInstaller || isInstallerManager;
    if (isAuthorized && installerRelatedRoles && installerVerified) {
      navigate('/installer/dashboard');
    }

    if (isAuthorized && installerRelatedRoles && installerVerified != null && !installerVerified) {
      navigate('/installer/under-review');
    }
  }, [installerVerified, isAuthorized, isInstaller, isInstallerManager, navigate]);

  return null;
};

export default Home;
