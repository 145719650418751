import React from 'react';
import { useTranslation } from 'react-i18next';
import { MediaCategoryType } from 'store/client-request/client-request.types';
import { DocumentType } from './TenderDocumentationPage';
import SectionFileUpload from '../InvoicePage/SectionFileUpload';

interface IHomeCheckDocumentationProps {
  offerId?: string;
  handleDocumentUploaded: (status: boolean, type: DocumentType) => void;
}

const HomeCheckDocumentationPage: React.FC<IHomeCheckDocumentationProps> = (props) => {
  const { t } = useTranslation();
  const { offerId, handleDocumentUploaded } = props;

  const uploads = [
    {
      key: 'homecheckInvoiceUpload',
      category: MediaCategoryType.OfferInvoiceHomeCheckInstaller,
      type: DocumentType.HOMECHECK_INVOICE,
    },
    {
      key: 'homecheckDocumentationUpload',
      category: MediaCategoryType.RequestHomeCheckDocumentation,
      type: DocumentType.HOMECHECK_DOCUMENTATION,
    },
    {
      key: 'homecheckPhotosUpload',
      category: MediaCategoryType.CustomDocumentImage,
      type: DocumentType.HOMECHECK_PHOTOS,
    },
  ];

  const acceptTypes = {
    'image/*': ['.jpeg', '.png', '.jpg', '.svg'],
    'application/pdf': ['.pdf'],
  };
  return (
    <>
      {uploads.map((item) => (
        <SectionFileUpload
          acceptedFiles="JPG, PDF, PNG."
          accept={acceptTypes}
          key={item.type}
          offerId={offerId}
          title={t(`installerFlow:tenderParticipate:invoices:${item.key}`)}
          fileCategory={item.category}
          setFileUploaded={(status) => handleDocumentUploaded(status, item.type)}
          multiple
        />
      ))}
    </>
  );
};

export default HomeCheckDocumentationPage;
