import { produce } from 'immer';
import { RequestState } from 'store/common.types';
import {
  IProductComponentState,
  ProductComponentActionTypes,
  PRODUCT_COMPONENTS_GET,
  PRODUCT_COMPONENTS_GET_ERROR,
  PRODUCT_COMPONENTS_GET_SUCCESS,
  PRODUCT_COMPONENT_CREATE,
  PRODUCT_COMPONENT_CREATE_ERROR,
  PRODUCT_COMPONENT_CREATE_SUCCESS,
  PRODUCT_COMPONENT_DELETE,
  PRODUCT_COMPONENT_DELETE_ERROR,
  PRODUCT_COMPONENT_DELETE_SUCCESS,
  PRODUCT_COMPONENT_UPDATE,
  PRODUCT_COMPONENT_UPDATE_ERROR,
  PRODUCT_COMPONENT_UPDATE_SUCCESS,
  PRODUCT_WIZARD_ACTIVE_VALIDATE,
  PRODUCT_WIZARD_ACTIVE_VALIDATE_ERROR,
  PRODUCT_WIZARD_ACTIVE_VALIDATE_SUCCESS,
  PRODUCT_WIZARD_GET,
  PRODUCT_WIZARD_GET_ERROR,
  PRODUCT_WIZARD_GET_SUCCESS,
} from './product-components.types';

const initialState: IProductComponentState = {
  productComponents: [],
  productComponentsGetState: null,
  productComponentsGetError: null,

  productComponentCreateState: null,
  productComponentCreateError: null,

  productComponentUpdateState: null,
  productComponentUpdateError: null,

  productComponentDeleteState: null,
  productComponentDeleteError: null,

  productWizard: [],
  productWizardComponentsGetError: null,
  productWizardComponentsGetState: null,

  isActivable: false,
  validateActivePosibilityState: null,
  validateActivePosibilityError: null,
};

const ProductComponentsReducer = (state = initialState, action: ProductComponentActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case PRODUCT_COMPONENTS_GET:
        draft.productComponentsGetState = RequestState.Loading;
        break;
      case PRODUCT_COMPONENTS_GET_SUCCESS:
        draft.productComponentsGetState = RequestState.Success;
        draft.productComponentsGetError = null;
        draft.productComponents = action.payload.items;
        break;
      case PRODUCT_COMPONENTS_GET_ERROR:
        draft.productComponentsGetState = RequestState.Error;
        draft.productComponentsGetError = action.payload;
        break;
      case PRODUCT_COMPONENT_CREATE:
        draft.productComponentCreateState = RequestState.Loading;
        break;
      case PRODUCT_COMPONENT_CREATE_SUCCESS:
        draft.productComponentCreateState = RequestState.Success;
        draft.productComponentCreateError = null;
        draft.productComponents = [...state.productComponents, action.payload];
        break;
      case PRODUCT_COMPONENT_CREATE_ERROR:
        draft.productComponentCreateState = RequestState.Error;
        draft.productComponentCreateError = action.payload;
        break;

      case PRODUCT_COMPONENT_UPDATE:
        draft.productComponentUpdateState = RequestState.Loading;
        break;
      case PRODUCT_COMPONENT_UPDATE_SUCCESS:
        draft.productComponentUpdateState = RequestState.Success;
        draft.productComponentUpdateError = null;
        draft.productComponents = state.productComponents.map((productComponent) =>
          productComponent.id === action.payload.id ? action.payload : productComponent,
        );
        break;
      case PRODUCT_COMPONENT_UPDATE_ERROR:
        draft.productComponentUpdateState = RequestState.Error;
        draft.productComponentUpdateError = action.payload;
        break;
      case PRODUCT_COMPONENT_DELETE:
        draft.productComponentDeleteState = RequestState.Loading;
        break;
      case PRODUCT_COMPONENT_DELETE_SUCCESS:
        draft.productComponentDeleteState = RequestState.Success;
        draft.productComponentDeleteError = null;
        draft.productComponents = state.productComponents.filter(
          (productComponent) => productComponent.id !== action.payload,
        );
        break;
      case PRODUCT_COMPONENT_DELETE_ERROR:
        draft.productComponentDeleteState = RequestState.Error;
        draft.productComponentDeleteError = action.payload;
        break;
      case PRODUCT_WIZARD_GET:
        draft.productWizardComponentsGetState = RequestState.Loading;
        break;
      case PRODUCT_WIZARD_GET_SUCCESS:
        draft.productWizardComponentsGetState = RequestState.Success;
        draft.productWizardComponentsGetError = null;
        draft.productWizard = action.payload;
        break;
      case PRODUCT_WIZARD_GET_ERROR:
        draft.productWizardComponentsGetState = RequestState.Error;
        draft.productWizardComponentsGetError = action.payload;
        break;
      case PRODUCT_WIZARD_ACTIVE_VALIDATE:
        draft.validateActivePosibilityState = RequestState.Loading;
        break;
      case PRODUCT_WIZARD_ACTIVE_VALIDATE_SUCCESS:
        draft.validateActivePosibilityState = RequestState.Success;
        draft.validateActivePosibilityError = null;
        draft.isActivable = action.payload;
        break;
      case PRODUCT_WIZARD_ACTIVE_VALIDATE_ERROR:
        draft.validateActivePosibilityState = RequestState.Error;
        draft.validateActivePosibilityError = action.payload;
        break;
    }
  });

export default ProductComponentsReducer;
