import React from 'react';
import { Tooltip } from 'antd';
import { IBundle, IBundleComponent } from 'store/bundle/bundle.types';
import { formatPrice, removeThousandSeparator } from './currencyUtils';

export const formatPayload = (values: IBundle) => {
  let components: IBundleComponent[] = [];
  values.components?.map(
    (item: any) =>
      item.component?.map((component: IBundleComponent) => {
        return components.push({
          ...component,
          category: item.name,
          price: component.price ? removeThousandSeparator(component.price) : 0,
        });
      }),
  );
  return {
    ...values,
    currency: values.currencySymbol,
    components: components,
  };
};

const getComponent = (values: IBundleComponent) => ({
  id: values.id,
  totalPrice: values.totalPrice,
  description: values.description,
  quantity: values.quantity,
  price: formatPrice(values.price),
  unit: values.unit,
});

export const formatFormData = (values: IBundle) => {
  const components = values?.components?.reduce((prev: any, curr: any) => {
    const newPrev = [...prev];
    const exist = newPrev.find((item: any) => item.name === curr.category);
    if (exist) {
      exist.component.push(getComponent(curr));
    } else {
      newPrev.push({
        name: curr.category,
        component: [getComponent(curr)],
      });
    }
    return newPrev;
  }, []);
  return {
    ...values,
    components,
    vat: formatPrice(values.vat),
    totalPriceGross: formatPrice(values.totalPriceGross),
    totalPriceNet: formatPrice(values.totalPriceNet),
  };
};

export const renderComponentsCol = (components: IBundleComponent[]) => {
  const componentsNames = components.map((item) => item.category);
  const title = componentsNames.join(', ');
  return (
    <Tooltip placement="topLeft" title={title}>
      {title}
    </Tooltip>
  );
};
