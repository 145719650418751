import React, { useMemo, useState } from 'react';
import { Modal, ModalProps } from 'antd';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import RadioQuestion from 'components/questions/RadioQuestion/RadioQuestion';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from 'framework/constants';
import { IAppointment } from 'store/offer/offer.types';

type DateSelectionModalType = {
  appointments: IAppointment[];
  onSubmit(value: string): void;
};
const DateSelectionModal: React.FC<DateSelectionModalType & ModalProps> = ({
  appointments,
  onSubmit,
  ...rest
}) => {
  const [date, setDate] = useState<string>('');
  const { t } = useTranslation();

  const getDate = (date: Moment) =>
    `${date.format(DEFAULT_DATE_FORMAT)} ${date.format(DEFAULT_TIME_FORMAT)}`;

  const options = useMemo(
    () =>
      appointments.map((appointment) => {
        const time = moment(appointment.timestamp);
        return { label: getDate(time), value: time.toISOString() };
      }),
    [appointments],
  );

  const handleOkClick = () => {
    onSubmit(date);
  };

  return (
    <Modal {...rest} onOk={handleOkClick}>
      <RadioQuestion
        question={t('customerFlow:offerReview:offerSubmitPage:description')}
        options={options}
        onChange={(value: string) => setDate(value)}
        value={date}
      />
    </Modal>
  );
};

export default DateSelectionModal;
