import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Flex, Form, Select, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { DefaultOptionType } from 'antd/es/select';
import { useTranslation } from 'react-i18next';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import { bundlesGet } from 'store/bundle/bundle.actions';
import { bundleGetStateSelector, currentBundlesSelector } from 'store/bundle/bundle.selectors';
import { RequestState } from 'store/common.types';
import { BundleView } from '../SpecificationWidget/BundleView/BundleView';

export type TBundleFormData = {
  bundle?: string;
};
type TSelectBundleStepProps = {
  destPoolId?: string;
  bundleSubmitHandler: (formData: TBundleFormData) => void;
  initialFormData: TBundleFormData;
};
const SelectBundleStepComponent = (props: TSelectBundleStepProps) => {
  const locizeKey = `intermediate:tenderDetails:changePoolModal`;
  const { t } = useTranslation();
  const clientRequest = useSelector(currentClientRequestSelector);
  const destPoolBundles = useSelector(currentBundlesSelector);
  const bundlesGetState = useSelector(bundleGetStateSelector);
  const [isBundlesRequestSent, setIsBundlesRequestSent] = useState(false);
  const dispatch = useDispatch();
  const [form] = useForm();
  const loadDestPoolBundles = useCallback(async () => {
    if (!props.destPoolId) return;
    dispatch(bundlesGet(props.destPoolId));
    setIsBundlesRequestSent(true);
  }, [dispatch, props.destPoolId]);
  const selectedBundleWatcher = Form.useWatch('bundle', form);
  useEffect(() => {
    loadDestPoolBundles();
  }, [loadDestPoolBundles]);
  const destPoolBundleOptions = destPoolBundles.map<DefaultOptionType>((_bundles) => ({
    label: _bundles.name,
    value: _bundles.id,
  }));
  useEffect(() => {
    if (bundlesGetState === RequestState.Success && !destPoolBundles.length && isBundlesRequestSent)
      props.bundleSubmitHandler({});
  }, [destPoolBundles, bundlesGetState, props.bundleSubmitHandler, props, isBundlesRequestSent]);
  return (
    <Form form={form} onFinish={props.bundleSubmitHandler} initialValues={props.initialFormData}>
      <Flex vertical={true} gap={8}>
        <Card title={t(`${locizeKey}:currentBundle`)}>
          {clientRequest?.bundle ? (
            <BundleView bundle={clientRequest?.bundle} />
          ) : (
            <Typography.Text>{t(`${locizeKey}:noBundle`)}</Typography.Text>
          )}
        </Card>
        <Card title={t(`${locizeKey}:newBundle`)}>
          <Form.Item
            rules={[{ required: true }]}
            name={'bundle'}
            label={t(`${locizeKey}:selectBundle`)}
          >
            <Select
              loading={bundlesGetState === RequestState.Loading}
              options={destPoolBundleOptions}
              virtual={false}
            ></Select>
          </Form.Item>
          {selectedBundleWatcher && (
            <BundleView
              bundle={destPoolBundles.find((_bundle) => _bundle.id === selectedBundleWatcher)}
            />
          )}
        </Card>
        <Flex justify={'flex-end'}>
          <Button type={'primary'} htmlType={'submit'}>
            {t(`${locizeKey}:next`)}
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
};
export default SelectBundleStepComponent;
