import React, { useState, Key } from 'react';
import { Col, Divider, Dropdown, Menu, Row, TableProps, Tooltip, Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import { SorterResult } from 'antd/lib/table/interface';
import BackButton from 'components/ui/BackButton/BackButton';
import { CrudModal } from 'components/modals/CrudModal/CrudModal';
import Table from 'components/ui/Table/Table';
import { ICrudViewProps, EntityAction } from 'types/crud-view.types';
import { columnsFormatter } from './utils';

import styles from './CrudView.module.sass';

export function CrudView<T>(props: ICrudViewProps<T>) {
  const {
    locizeKey,
    columns,
    entries,
    isMaxEntries,
    maxKey,
    paginationProps,
    updateEntryFormat,
    actionDisabled,
    rowsSelectable,
    modalProps,
    filterRow,
    onRowClick,
    backButton,
    onBackClick,
    onTableSort,
    tableLoading,
    rowActions = [],
  } = props;

  const [isModal, setIsModal] = useState(false);
  const [updateData, setUpdateData] = useState<T | null>(null);
  const [action, setAction] = useState<EntityAction>();
  const [selectedRows, setSelectedRows] = useState<Key[]>([]);
  const { t } = useTranslation();

  const updatedModalProps = {
    open: isModal,
    onClose: () => onModalClose(),
    dataToUpdate: updateData,
    locizeKey,
    action,
    ...modalProps,
  };

  const rowSelection = {
    selectedRows,
    onChange: (newKeys: Key[]) => setSelectedRows(newKeys),
  };

  const onModalClose = () => {
    setIsModal(false);
    setAction(undefined);
    setUpdateData(null);
  };

  const handleActionClick = (action: EntityAction, entry?: T) => {
    setAction(action);
    if (entry) {
      const data = updateEntryFormat ? updateEntryFormat(entry) : entry;
      setUpdateData(data);
    }
    setIsModal(true);
  };

  const tableActions = {
    title: '',
    dataIndex: '',
    key: 'action',
    width: '10%',
    render: (_: string, row: T) => (
      <div className={styles.actions}>
        <EditOutlined
          disabled={actionDisabled ? actionDisabled(row) : false}
          className={styles.edit}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleActionClick(EntityAction.Edit, row);
          }}
        />
        <DeleteOutlined
          disabled={actionDisabled ? actionDisabled(row) : false}
          className={styles.delete}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleActionClick(EntityAction.Delete, row);
          }}
        />
      </div>
    ),
  };

  const menuItems = [{ label: 'Delete' }, ...rowActions];

  const menu = (
    <Menu>
      {menuItems.map((item, index) => (
        <Menu.Item key={index}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  const onTableChange: TableProps<T>['onChange'] = (_pagination, _filters, sorter) => {
    if (onTableSort) {
      const sort = sorter as SorterResult<T>;
      onTableSort(sort.field as string, sort.order === 'ascend' ? 1 : -1);
    }
  };

  const tableProps = rowsSelectable && {
    rowSelection,
  };

  return (
    <>
      <div className={styles.header}>
        {backButton && <BackButton onClick={onBackClick}>{t('common:buttons:back')}</BackButton>}
        <Typography className={styles.title}>{t(`${locizeKey}:title`)}</Typography>
        <Tooltip title={isMaxEntries ? t(`common:errors:${maxKey}`) : ''} placement="bottom">
          <div>
            <Button
              onClick={() => handleActionClick(EntityAction.Create, props.createInitialValues)}
              type={isMaxEntries ? 'default' : 'primary'}
              size="small"
              disabled={isMaxEntries}
            >
              {t(`${locizeKey}:button:create`)}
            </Button>
          </div>
        </Tooltip>
      </div>
      <div className={styles.tableBody}>
        <Row gutter={[16, 8]}>
          {filterRow && (
            <Col span={24} style={{ paddingLeft: '8px' }}>
              {filterRow}
            </Col>
          )}
          {rowsSelectable && selectedRows.length > 0 && (
            <Col span={24} className={styles.selectFilter}>
              <Typography>{selectedRows?.length} Selected</Typography>
              <Divider type="vertical" />
              <Dropdown overlay={menu}>
                <div>
                  More Actions <DownOutlined />
                </div>
              </Dropdown>
            </Col>
          )}
          <Col span={24}>
            <Table
              loading={tableLoading}
              rowKey="id"
              columns={columnsFormatter([...columns, tableActions])}
              dataSource={entries}
              pagination={paginationProps}
              onChange={onTableChange}
              onRowClick={onRowClick}
              {...tableProps}
            />
          </Col>
        </Row>
      </div>
      <CrudModal<T> {...updatedModalProps} />
    </>
  );
}
