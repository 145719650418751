import { RootState } from 'store/rootReducer';

export const installerDataSelector = (state: any) => state.installer.installerData;

export const joiningPoolConfirmStateSelector = (state: any) =>
  state.installer.joiningPoolConfirmState;

export const installerInvitationsSelector = (state: any) => state.installer.installerInvitations;

export const installerOrganizationSelector = (state: any) => state.installer.installerOrganization;

export const installerAccessiblePoolsSelector = (state: RootState) =>
  state.installer.accessiblePools;
