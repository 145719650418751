import React, { JSX } from 'react';
import clsx from 'clsx';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './BlockKeyValue.module.sass';

interface IBlockKeyValueProps {
  className?: string;
  label: string;
  value: string | JSX.Element;
  question?: string;
}

const BlockKeyValue: React.FC<IBlockKeyValueProps> = ({ className, label, value, question }) => {
  const renderTooltip = () => (
    <Tooltip title={question}>
      <InfoCircleOutlined style={{ marginLeft: '6px' }} />
    </Tooltip>
  );

  const isQuestion = question && question.length > 1;

  return (
    <div className={clsx(styles.term, className)}>
      <span className={styles.key}>
        {label}
        {isQuestion ? renderTooltip() : null}
      </span>
      <span className={styles.termValue}>{value}</span>
    </div>
  );
};

export default BlockKeyValue;
