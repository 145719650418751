export const CUSTOMER_REQUEST_STEPS = [
  {
    key: 'wallbox',
    title: 'stepper:customer:wallbox:title',
    steps: [
      { title: 'stepper:customer:wallbox:steps:1', key: 1 },
      { title: 'stepper:customer:wallbox:steps:2', key: 2 },
    ],
  },
  {
    key: 'request',
    title: 'stepper:customer:request:title',
    steps: [
      { title: 'stepper:customer:request:steps.1', key: 1 },
      { title: 'stepper:customer:request:steps.2', key: 2 },
      { title: 'stepper:customer:request:steps.3', key: 3 },
      { title: 'stepper:customer:request:steps.4', key: 4 },
      { title: 'stepper:customer:request:steps.5', key: 5 },
    ],
  },
  {
    key: 'offer',
    title: 'stepper:customer:offer:title',
    steps: [
      { title: 'stepper:customer:offer:steps.1', key: 1 },
      { title: 'stepper:customer:offer:steps.2', key: 2 },
    ],
  },
  {
    key: 'installation',
    title: 'stepper:customer:installation:title',
    steps: [{ title: 'stepper:customer:installation:steps.1', key: 1 }],
  },
];

export const WALLBOX_STEP = {
  key: 'wallbox',
  stepType: 'wallbox',
  title: 'stepper:customer:wallbox:title',
  steps: [
    { title: 'stepper:customer:wallbox:steps.1', key: '3.1' },
    { title: 'stepper:customer:wallbox:steps.2', key: '3.2' },
  ],
};

export const INSTALLER_REGISTRATION_STEPS = [
  {
    key: '1',
    title: 'stepper:installer:title',
    steps: [
      { title: 'stepper:installer:step1', key: '1.1' },
      { title: 'stepper:installer:step2', key: '1.2' },
      { title: 'stepper:installer:step3', key: '1.3' },
      { title: 'stepper:installer:step4', key: '1.4' },
    ],
  },
];

export const ORDER_STEPS = [
  {
    key: '1',
    title: 'installerFlow:orderDetails:steps:offerStep',
  },
  {
    key: '2',
    title: 'installerFlow:orderDetails:steps:execution',
  },
  {
    key: '3',
    title: 'installerFlow:orderDetails:steps:finalize',
  },
  {
    key: '4',
    title: 'installerFlow:orderDetails:steps:feedback',
  },
];

export const LEAD_STEPS = [
  {
    key: '1',
    title: 'stepper:lead:title',
    steps: [
      { title: 'stepper:lead:step1', key: '1.1' },
      { title: 'stepper:lead:step2', key: '1.2' },
      { title: 'stepper:lead:step3', key: '1.3' },
    ],
  },
];

export const CUSTOMER_FOTO_WIZARD_STEPS = [
  {
    key: '1',
    title: 'clientRequest:fotoWizard:step1:heading',
  },
  {
    key: '2',
    title: 'clientRequest:fotoWizard:step2:heading',
  },
  {
    key: '3',
    title: 'clientRequest:fotoWizard:step3:heading',
  },
];
