import React from 'react';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import { AppointmentPurpose, IAppointment, IOffer } from 'store/offer/offer.types';

import { formatDateFromIso } from 'utils/dateUtils';
import styles from '../../ClientRequestPage.module.sass';

interface IInfoRowProps {
  offer: IOffer;
}

export const InfoRow: React.FC<IInfoRowProps> = ({ offer }) => {
  const { t } = useTranslation();

  const renderDate = (key: keyof IOffer) => (
    <Col className={styles.infoColumn}>
      <div className={clsx([styles.detailsTitle, styles.bold])}>
        {t(`intermediate:myProjects:offer:${key.replace('Timestamp', 'Date')}`)}
      </div>
      <div className={styles.detailsInfo}>
        <DateFormat date={offer[key]} />
      </div>
    </Col>
  );

  const dates: Array<keyof IOffer> = [
    'actualHomeCheckTimestamp',
    'finalHomeCheckTimestamp',
    'actualInstallationTimestamp',
    'finalInstallationTimestamp',
  ];

  return (
    <Row gutter={[8, 8]}>
      {offer.appointments && !!offer.appointments.length && (
        <Col span={4}>
          <div className={clsx([styles.detailsTitle, styles.bold])}>
            {offer.appointments[0].purpose === AppointmentPurpose.Installation
              ? t('intermediate:myProjects:offer:installationAppointment')
              : t('intermediate:myProjects:offer:homeCheckAppointment')}
          </div>
          {offer.appointments.map((item: IAppointment) => (
            <div className={styles.detailsDate} key={item.id}>
              {formatDateFromIso(item.timestamp, 'dddd, Do MMM YYYY HH:mm')}
            </div>
          ))}
        </Col>
      )}
      {dates.map((item) => (
        <React.Fragment key={item}>{offer[item] && renderDate(item)}</React.Fragment>
      ))}
      <Col className={styles.infoColumn}>
        <div className={clsx([styles.detailsTitle, styles.bold])}>
          {t('intermediate:myProjects:offer:installerInfo')}
        </div>
        <div className={styles.detailsInfo}>
          {`${offer.installerUser?.firstName} ${offer.installerUser?.lastName}`} <br />
          {offer.installerUser?.phone}
        </div>
      </Col>
      <Col className={styles.infoColumn}>
        <div className={clsx([styles.detailsTitle, styles.bold])}>
          {t('intermediate:myProjects:offer:organizationName')}
        </div>
        <div className={styles.detailsInfo}>{offer?.installerUser?.organization?.name}</div>
      </Col>
    </Row>
  );
};
