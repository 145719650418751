import React from 'react';

import { Editor, RawDraftContentState } from 'react-draft-wysiwyg';
import { getEditorValue } from 'utils/product';
import editorStyle from './Editor.module.sass';

const blockTypeOptions = ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'];
const inlineOptions = ['bold', 'italic', 'underline'];
const toolbarOptions = [
  'inline',
  'blockType',
  'fontSize',
  'fontFamily',
  'colorPicker',
  'link',
  'emoji',
  'list',
  'textAlign',
];
const editorProps = {
  toolbar: {
    options: toolbarOptions,
    inline: {
      className: editorStyle.inline,
      options: inlineOptions,
    },
    blockType: {
      className: editorStyle.dropdown,
      options: blockTypeOptions,
      dropdownClassName: editorStyle.dropdownContainer,
    },
    fontSize: {
      className: editorStyle.dropdown,
      dropdownClassName: editorStyle.dropdownContainer,
    },
    fontFamily: {
      className: editorStyle.dropdown,
      dropdownClassName: editorStyle.dropdownContainer,
    },
  },
  wrapperClassName: editorStyle.wrapper,
  editorClassName: editorStyle.textAreaWrapper,
  toolbarClassName: editorStyle.toolbar,
};

interface EditorContainerProps {
  onChange?: any;
  value?: any;
}

const EditorContainer: React.FC<EditorContainerProps> = ({ value, onChange }) => {
  const onContentStateChange = (editorInstance: RawDraftContentState) => {
    if (editorInstance.blocks.every((item) => item.text === '')) {
      onChange(null);
    } else {
      onChange(editorInstance);
    }
  };
  return (
    <Editor
      {...editorProps}
      initialContentState={getEditorValue(value)}
      onContentStateChange={onContentStateChange}
    />
  );
};

export default EditorContainer;
