import React from 'react';
import clsx from 'clsx';
import { default as ANTButton, ButtonProps } from 'antd/lib/button';

import styles from './Button.module.sass';

export enum ButtonColor {
  GREEN = 'green',
  BLUE = 'blue',
  WHITE = 'white',
  GREY = 'grey',
  DARK_BLUE = 'dark-blue',
}

export enum IconPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface IButtonProps extends ButtonProps {
  className?: string;
  children: any;
  color?: ButtonColor;
  icon?: any;
  iconPosition?: IconPosition;
}

const Button: React.FC<IButtonProps> = (props) => {
  const { children, className, color, icon, iconPosition, ...rest } = props;

  const withLeftIcon = icon && iconPosition === IconPosition.LEFT;
  const withRightIcon = icon && iconPosition === IconPosition.RIGHT;

  const buttonClassNames = clsx(styles.button, className, {
    [styles[color!]]: !rest.danger,
    [styles['icon-left']]: withLeftIcon,
    [styles['icon-right']]: withRightIcon,
  });

  const content = [children];

  if (withLeftIcon) {
    content.unshift(icon);
  }

  if (withRightIcon) {
    content.push(icon);
  }

  return (
    <ANTButton className={buttonClassNames} {...rest}>
      {content}
    </ANTButton>
  );
};

Button.defaultProps = {
  color: ButtonColor.BLUE,
  iconPosition: IconPosition.RIGHT,
};

export default Button;
