import { produce } from 'immer';

import {
  AppActionTypes,
  CHANGE_STEP,
  IAppState,
  SET_GLOBAL_ERROR,
  SET_SUB_STEPS_HIDDEN,
} from './app.types';

const initialState: IAppState = {
  // Stepper
  activeStep: 1,
  activeSubStep: 1,
  subStepsHidden: false,
  globalError: '',
};

const ClientRequestReducer = (state = initialState, action: AppActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CHANGE_STEP:
        draft.activeStep = action.payload.activeStep;
        draft.activeSubStep = action.payload.activeSubStep;
        break;

      case SET_SUB_STEPS_HIDDEN:
        draft.subStepsHidden = action.payload.subStepsHidden;
        break;

      case SET_GLOBAL_ERROR:
        draft.globalError = action.payload.error;
        break;
    }
  });

export default ClientRequestReducer;
