import { createSelector } from 'reselect';
import { IPoolTenderOrganizationState } from './pool-tender-organization.types';

export const poolTenderOrganizationSelector = (state: any) => state.poolTenderOrganization;

// Get Pool Organizations
export const poolOrganizationsSelector = createSelector(
  poolTenderOrganizationSelector,
  (poolTenderOrganization: IPoolTenderOrganizationState) => poolTenderOrganization.organizations,
);

// Get Pool Organizations State
export const poolOrganizationsStateSelector = createSelector(
  poolTenderOrganizationSelector,
  (poolTenderOrganization: IPoolTenderOrganizationState) =>
    poolTenderOrganization.organizationsState,
);
