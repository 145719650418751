import React from 'react';
import { Row } from 'antd';
import clsx from 'clsx';
import { IOption } from 'store/common.types';

import styles from './RadioGroup.module.sass';

interface IRadioGroupProps {
  value?: string;
  onChange?: (value: string) => void;
  options: IOption[];
}

const RadioGroup: React.FC<IRadioGroupProps> = (props) => {
  const { options, onChange, value } = props;
  return (
    <Row className={styles.container}>
      {options.map((option) => (
        <div
          key={option.value}
          className={clsx(styles.option, { [styles.checked]: option.value === value })}
          onClick={() => onChange?.(option.value)}
          {...option}
        >
          {option.label}
        </div>
      ))}
    </Row>
  );
};

export default RadioGroup;
