import React from 'react';
import { Typography } from 'antd';
import { IProduct } from 'store/product/product.types';

import styles from '../Products.module.sass';

interface ProductNameProps {
  product: IProduct;
}

export const ProductName: React.FC<ProductNameProps> = ({ product }) => {
  return (
    <div className={styles.product}>
      <div>
        <img alt="Product" src={product.mediaUrl} className={styles.imageContainer} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography.Text strong>{product.title}</Typography.Text>
        <Typography.Text strong>{product.vendor?.name}</Typography.Text>
      </div>
    </div>
  );
};
