import React, { FC, useContext } from 'react';
import { Button, Form, Input, Rate, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ChargeIcon } from 'assets/icons/charge.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { CustomerDashboardContext } from 'context/customerDashboard.context';
import { feedbackCreate } from 'store/feedback/feedback.actions';
import { FeedbackReceiverType, FeedbackReferenceType } from 'store/feedback/feedback.types';

import styles from './FeedbackPage.module.sass';

type FeedbackValues = {
  [key: string]: {
    rate: number;
    comment: string;
  };
};

interface IFeedbackPageProps {
  handleCloseModal: () => void;
}
const FeedbackPage: FC<IFeedbackPageProps> = ({ handleCloseModal }) => {
  const { t } = useTranslation('feedback');
  const dispatch = useDispatch();
  const { request } = useContext(CustomerDashboardContext);

  const handleSaveFeedback = (values: FeedbackValues) => {
    dispatch(
      feedbackCreate(
        Object.keys(values).map((key) => ({
          referenceType: FeedbackReferenceType.ClientRequests,
          referenceId: request?.id!,
          receiverType:
            key === 'installation'
              ? FeedbackReceiverType.Installers
              : FeedbackReceiverType.Platform,
          rating: values[key].rate,
          text: values[key].comment,
        })),
      ),
    );
    handleCloseModal();
  };

  return (
    <Form onFinish={handleSaveFeedback}>
      <div className={styles.title}>
        <ChargeIcon />
        <Typography.Title level={4}>{t('feedback:rateTendergy')}</Typography.Title>
      </div>
      <Typography.Text type="secondary">{t('feedback:tendergyDescription')}</Typography.Text>
      <Form.Item name={['installation', 'rate']}>
        <Rate />
      </Form.Item>
      <Form.Item name={['installation', 'comment']}>
        <Input.TextArea placeholder={t('feedback:additionalComments')} />
      </Form.Item>
      <div className={styles.title}>
        <UserIcon />
        <Typography.Title level={4}>{t('feedback:rateInstaller')}</Typography.Title>
      </div>
      <Typography.Text type="secondary">{t('feedback:installerDescription')}</Typography.Text>
      <Form.Item name={['tendergy', 'rate']}>
        <Rate />
      </Form.Item>
      <Form.Item name={['tendergy', 'comment']}>
        <Input.TextArea placeholder={t('feedback:additionalComments')} />
      </Form.Item>
      <Button type="primary" htmlType="submit">
        {t(`common:buttons:confirm`)}
      </Button>
    </Form>
  );
};

export default FeedbackPage;
