import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  IParentPoolsFilterAction,
  IParentPoolsFilterPayload,
  PARENT_POOLS_FILTER,
} from './parentPoolsFilter.types';
import {
  parentPoolsFilterErrorAction,
  parentPoolsFilterSuccessAction,
} from './parentPoolsFilter.actions';
import IntermediateAPI from '../../services/intermediate.service';

export function* parentPoolsFilterSagas(action: IParentPoolsFilterAction) {
  try {
    const params = {
      sortFields: 'name',
      sortDirections: 1,
      limit: 10,
      offset: 0,
      isParent: true,
      query: action.payload,
    };

    const parentPoolsFilterResult: IParentPoolsFilterPayload = yield call(
      IntermediateAPI.getPools,
      params,
    );
    if (parentPoolsFilterResult) {
      yield put(parentPoolsFilterSuccessAction(parentPoolsFilterResult));
    } else {
      yield put(parentPoolsFilterErrorAction('Empty Response'));
    }
  } catch (err: any) {
    if (err?.response?.data) {
      const data = err?.response?.data;
      const message = Array.isArray(data.message) ? data.message[0] : data.message;
      yield put(parentPoolsFilterErrorAction(message));
    } else {
      put(parentPoolsFilterErrorAction('Unknown Error'));
    }
  }
}
export default function* root() {
  yield all([takeLatest(PARENT_POOLS_FILTER, parentPoolsFilterSagas)]);
}
