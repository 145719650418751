import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Spin, Typography, Modal, Skeleton } from 'antd';
import Button from 'components/ui/Button/Button';
import DateFormat from 'components/ui/DateFormat/DateFormat';

import styles from './VideoCall.module.sass';

interface Props {
  startCall: Function;
  offer: any;
  user: any;
  startCallLoading?: boolean;
  isCustomer?: boolean;
}

const StartCallSection = (props: Props) => {
  const { t } = useTranslation(['remoteHomeCheck']);
  const [isDisclaimerVisible, setIsDisclaimerVisible] = useState<boolean>(false);
  const { offer, user, startCallLoading, startCall, isCustomer } = props;

  const renderModalContent = () => {
    const translationKey = isCustomer ? 'customer' : 'installer';

    return (
      <>
        <Typography className={styles.text}>
          {t(`remoteHomeCheck:disclaimer:${translationKey}:title`)}
        </Typography>
        <Typography className={styles.text}>
          {t(`remoteHomeCheck:disclaimer:${translationKey}:text1`)}
        </Typography>
        <Typography className={styles.text}>
          {t(`remoteHomeCheck:disclaimer:${translationKey}:text2`)}
        </Typography>
        <Typography className={styles.text}>
          {t(`remoteHomeCheck:disclaimer:${translationKey}:text3`)}
        </Typography>
        <Typography className={styles.text}>
          {t(`remoteHomeCheck:disclaimer:${translationKey}:text4`)}
          <Link to="/legal/privacy-policy" className={styles.link}>
            {t('remoteHomeCheck:disclaimer:link')}
          </Link>
        </Typography>
        <Typography className={styles.text}>
          {t(`remoteHomeCheck:disclaimer:${translationKey}:text5`)}
        </Typography>
      </>
    );
  };

  return (
    <div>
      {user ? (
        <Typography.Title level={5}>
          {t('remoteHomeCheck:text:with')}: {`${user.firstName} ${user.lastName}`}
        </Typography.Title>
      ) : (
        <Skeleton active paragraph={{ rows: 1 }} />
      )}
      {offer ? (
        <Typography.Title level={5}>
          {t('remoteHomeCheck:text:at')}:&nbsp;
          <DateFormat date={offer.finalHomeCheckTimestamp} format={'MMMM Do YYYY, HH:mm'} />
        </Typography.Title>
      ) : (
        <Skeleton active paragraph={{ rows: 1 }} />
      )}
      <Typography.Title level={3}>{t('remoteHomeCheck:text:readyToJoin')}</Typography.Title>
      <Button
        type="primary"
        size="large"
        onClick={() => setIsDisclaimerVisible(true)}
        disabled={startCallLoading}
      >
        {startCallLoading && <Spin />}
        {t('remoteHomeCheck:text:joinCallButton')}
      </Button>
      <Modal
        title={t('remoteHomeCheck:text:disclaimer')}
        open={isDisclaimerVisible}
        onCancel={() => setIsDisclaimerVisible(false)}
        footer={[
          <Button onClick={() => setIsDisclaimerVisible(false)} key={'reject'}>
            {t('remoteHomeCheck:text:reject')}
          </Button>,
          <Button
            onClick={() => {
              setIsDisclaimerVisible(false);
              startCall();
            }}
            key={'accept'}
            type="primary"
          >
            {t('remoteHomeCheck:text:accept')}
          </Button>,
        ]}
      >
        {renderModalContent()}
      </Modal>
    </div>
  );
};

export default StartCallSection;
