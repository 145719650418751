import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import DropZone from 'components/ui/DropZone/DropZone';
import FilesList from 'components/ui/FilesList/FilesList';
import { deleteFile } from 'store/client-request/client-request.actions';
import { IFile, MediaCategoryType } from 'store/client-request/client-request.types';
import { uploadGenericFile } from 'store/document/document.actions';
import { offerFileUpload } from 'store/offer/offer.actions';

interface IOfferUploaderProps {
  offerId?: string;
  onChange?: (files: IFile[]) => void;
  value?: IFile[];
}

const OfferUploader: FC<IOfferUploaderProps> = ({ offerId, value, onChange }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onFileUpload = useCallback(
    (file: File) => {
      setLoading(true);
      if (offerId) {
        dispatch(
          offerFileUpload(
            offerId,
            MediaCategoryType.OfferBidInstaller,
            file,
            null,
            handleSuccessFileUpload,
            () => setLoading(false),
          ),
        );
      } else {
        dispatch(
          uploadGenericFile({
            document: file,
            category: MediaCategoryType.OfferBidInstaller,
            uploadSuccess: handleSuccessFileUpload,
          }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offerId],
  );

  const handleSuccessFileUpload = (file: IFile) => {
    setLoading(false);
    onChange?.([file]);
  };

  const handleDeleteFile = (fileName: string, fileId: string) => {
    dispatch(
      deleteFile(
        fileName,
        fileId,
        MediaCategoryType.OfferBidInstaller,
        () => onChange?.(value ? value?.filter((file) => file.id !== fileId) : []),
      ),
    );
  };

  return (
    <>
      {value?.length && <FilesList files={value} onFileDelete={handleDeleteFile} />}
      <DropZone
        maxFiles={1}
        onFileDropAccepted={([file]: File[]) => onFileUpload(file)}
        loading={loading}
      />
    </>
  );
};

export default OfferUploader;
