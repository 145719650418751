import { all, call, put, takeLatest } from 'redux-saga/effects';
import BundleAPI from 'services/bundle.service';
import { RequestResult } from 'types/crud-view.types';
import {
  bundleCreateError,
  bundleCreateSuccess,
  bundleDeleteError,
  bundleDeleteSuccess,
  bundlesGetError,
  bundlesGetSuccess,
  bundleUpdateError,
  bundleUpdateSuccess,
} from './bundle.actions';
import {
  BundleCreateAction,
  BundleGetAction,
  BUNDLE_CREATE,
  BUNDLES_GET,
  IBundle,
  BundleUpdateAction,
  BUNDLE_UPDATE,
  BundleDeleteAction,
  BUNDLE_DELETE,
} from './bundle.types';

export function* createBundle(action: BundleCreateAction) {
  try {
    const { poolId, bundle, onRequestDone } = action.payload;
    const result: IBundle = yield call(BundleAPI.createBundle, poolId, bundle);
    if (result) {
      yield put(bundleCreateSuccess(result));
      onRequestDone && onRequestDone(RequestResult.SUCCESS);
    } else {
      yield put(bundleCreateError('Empty Response'));
      onRequestDone && onRequestDone(RequestResult.ERROR);
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(bundleCreateError(message));
  }
}

export function* getBundles(action: BundleGetAction) {
  try {
    const result: IBundle[] = yield call(BundleAPI.getBundles, action.poolId, {
      sortField: 'name',
      sortDirection: 1,
    });
    if (result) {
      yield put(bundlesGetSuccess(result));
    } else {
      yield put(bundlesGetError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(bundlesGetError(message));
  }
}

export function* updateBundle(action: BundleUpdateAction) {
  try {
    const { bundleId, bundle, onRequestDone } = action.payload;
    const result: IBundle = yield call(BundleAPI.updateBundle, bundleId, bundle);
    if (result) {
      yield put(bundleUpdateSuccess({ ...bundle, id: bundleId }));
      onRequestDone && onRequestDone(RequestResult.SUCCESS);
    } else {
      yield put(bundleUpdateError('Empty Response'));
      onRequestDone && onRequestDone(RequestResult.ERROR);
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(bundleUpdateError(message));
  }
}

export function* deleteBundle(action: BundleDeleteAction) {
  try {
    const { id, onRequestDone } = action.payload;
    const result: IBundle = yield call(BundleAPI.deleteBundle, id);
    if (result) {
      yield put(bundleDeleteSuccess(id));
      onRequestDone && onRequestDone(RequestResult.SUCCESS);
    } else {
      yield put(bundleDeleteError('Empty Response'));
      onRequestDone && onRequestDone(RequestResult.ERROR);
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(bundleDeleteError(message));
  }
}

export default function* root() {
  yield all([
    takeLatest(BUNDLE_CREATE, createBundle),
    takeLatest(BUNDLES_GET, getBundles),
    takeLatest(BUNDLE_UPDATE, updateBundle),
    takeLatest(BUNDLE_DELETE, deleteBundle),
  ]);
}
