import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isCustomerSelector } from 'store/auth/auth.selectors';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import { getPoolLegalDocuments } from 'store/intermediate/intermediate.actions';
import useLanguageChangeCallback from './useLaguageChangeCallback';

/**
 * This hook will load legal documents only for customers
 */
const useFetchCustomerLegalDocuments = () => {
  const isCustomer: boolean = useSelector(isCustomerSelector);
  const dispatch = useDispatch();

  const request = useSelector(currentClientRequestSelector);
  const loadLegalDocuments = useCallback(() => {
    if (isCustomer && request) {
      dispatch(getPoolLegalDocuments(request.poolId!, true));
    }
  }, [request, dispatch, isCustomer]);
  useEffect(() => {
    loadLegalDocuments;
  }, [loadLegalDocuments]);
  useLanguageChangeCallback(loadLegalDocuments);
};

export default useFetchCustomerLegalDocuments;
