import config from 'config';
import { fileDownload } from 'framework/fileDownload';
import { OrderConfirmationType } from 'store/orders/orders.types';
import HttpService from './http.service';

class OrdersAPI extends HttpService {
  downloadAllDocuments = async (id: string, token: string) => {
    const headers = this.getHeaders({}, true);
    const res = await this.client.request({
      url: `${config.CRAFT_SERVICE_URL}/client-requests/${id}/documents/download/${token}`,
      responseType: 'blob',
      headers,
    });
    const fileName = `client-request-${id}-archive.zip`;
    fileDownload(res.data, fileName);
  };

  linkOrganizationToRequest = (clientRequestId: string, organizationId: string) => {
    return this.post(`mock/orders/request/${clientRequestId}/submit`, {
      installerOrganization: organizationId,
    });
  };

  acceptOrder = (id: string, token?: string, type?: OrderConfirmationType) => {
    const endpoint = token ? `orders/${id}/accept/${token}` : `orders/${id}/accept`;

    return this.post(endpoint, {
      type,
      state: type || 'confirmed',
    });
  };

  rejectOrder = (id: string, token?: string) => {
    const endpoint = token ? `orders/${id}/decline/${token}` : `orders/${id}/decline`;

    return this.post(endpoint, {
      declineReason: 'rejected',
    });
  };

  getOrderById = (id: string) => {
    return this.get(`orders/${id}`);
  };
}

export default new OrdersAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
