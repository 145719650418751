import React, { useState } from 'react';
import clsx from 'clsx';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Modal from 'antd/lib/modal';

import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { InfoCircleTwoTone } from '@ant-design/icons';
import AnswerSelection from 'components/ui/AnswerSelection/AnswerSelection';

import styles from './RadioQuestion.module.sass';

interface IOption {
  label: string;
  message?: string;
  value: string;
  width?: number;
}

interface IRadioQuestionProps {
  question: string;
  value?: string;
  description?: any;
  optionClassName?: string;
  grid?: boolean;
  options: IOption[];
  id?: string;
  onChange?: (value: string) => void;
  modalContent?: any;
  additionalInfo?: any;
  warning?: string;
  info?: boolean;
  questionCoverImage?: string;
}

const RadioQuestion = React.forwardRef<HTMLDivElement, IRadioQuestionProps>((props, forwardRef) => {
  const {
    description,
    optionClassName,
    options,
    onChange,
    grid,
    modalContent,
    additionalInfo,
    warning,
    info,
    questionCoverImage,
  } = props;
  const [value, setValue] = useState('');
  const [showModal, setShowModal] = useState(false);

  const isShowModal = !!(description && modalContent);

  const onRadioChange = (value: string) => {
    setValue(value);
    onChange!(value);
  };

  const getOption = (option: IOption) => (
    <AnswerSelection
      key={option.value}
      title={option.label}
      message={option.message}
      value={option.value}
      fluid={!!option.width}
      checked={(props.value || value) === option.value}
      onClick={() => onRadioChange(option.value)}
      className={clsx(styles.selection, optionClassName)}
    />
  );

  const getOptions = () => {
    const Wrapper = grid ? Col : React.Fragment;

    return options.map((option: IOption) => {
      const wrapperProps = grid ? { span: option.width || 24 } : {};

      return (
        <Wrapper {...wrapperProps} key={option.value}>
          {getOption(option)}
        </Wrapper>
      );
    });
  };

  const renderOptions = () => {
    if (grid) {
      return (
        <Row className={styles.row} id={props.id} gutter={[16, 16]}>
          {getOptions()}
        </Row>
      );
    }

    return (
      <div className={styles.answers} id={props.id}>
        {getOptions()}
      </div>
    );
  };

  const renderWithPopover = () => {
    const modalProps = {
      visible: showModal,
      className: styles.modal,
      footer: null,
      width: 'unset',
      onCancel: () => setShowModal(false),
    };

    return (
      <>
        <div
          className={clsx(styles.description, { [styles.hovered]: isShowModal })}
          onClick={() => setShowModal(true)}
        >
          <span className={styles.hoverIcon} />
          {additionalInfo}
        </div>

        {modalContent ? <Modal {...modalProps}>{modalContent}</Modal> : null}
      </>
    );
  };

  const renderAdditionalInfo = () => (isShowModal ? renderWithPopover() : additionalInfo);

  return (
    <div className={styles.container} ref={forwardRef}>
      <div>
        <span className={styles.question}>{props.question}</span>
        {questionCoverImage && (
          <img
            src={questionCoverImage}
            alt="Question Cover Imager Url"
            className={styles.coverImage}
          />
        )}
        {info && (
          <span className={styles.info}>
            {info}
            <InfoCircleTwoTone twoToneColor="#bfbfbf" />
          </span>
        )}
      </div>
      <div className={styles.description}>{description}</div>
      {warning && (
        <div className={styles.warning}>
          <ExclamationCircleTwoTone twoToneColor="#f5222d" />
          {warning}
        </div>
      )}
      {additionalInfo ? renderAdditionalInfo() : null}
      {renderOptions()}
    </div>
  );
});

RadioQuestion.defaultProps = {
  options: [],
  grid: false,
  onChange: (value: string) => {},
};

export default RadioQuestion;
