import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Typography from 'antd/lib/typography';
import Spin from 'antd/lib/spin';
import Button from 'components/ui/Button/Button';
import { verifyChangeEmail, verifyChangeEmailError } from 'store/auth/auth.actions';
import {
  isVerifyChangeEmailErrorSelector,
  isVerifyChangeEmailLoadingSelector,
  isVerifyChangeEmailSuccessSelector,
} from 'store/auth/auth.selectors';

import styles from './EmailChangePage.module.sass';

const Paragraph = Typography.Paragraph;

const EmailChangePage: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const changeEmailSuccess = useSelector(isVerifyChangeEmailSuccessSelector);
  const changeEmailFailure = useSelector(isVerifyChangeEmailErrorSelector);
  const loading = useSelector(isVerifyChangeEmailLoadingSelector);

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    if (token) {
      dispatch(verifyChangeEmail({ token }));
    } else {
      dispatch(verifyChangeEmailError(t('common:errors:tokenNotFound')));
    }
  }, [t]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderMessage = () =>
    changeEmailSuccess ? (
      <div className={styles.confirmation}>
        <Paragraph>{t('customerFlow:emailVerify:verified')}</Paragraph>
        <Link to="/">
          <Button>{t('customerFlow:emailVerify:loginToContinue')}</Button>
        </Link>
      </div>
    ) : (
      <Paragraph className={styles.confirmation}>
        {t('profile:changeEmailError')} <b>{changeEmailFailure}</b>
      </Paragraph>
    );

  return <div className={styles.container}>{loading ? <Spin /> : renderMessage()}</div>;
};

export default EmailChangePage;
