import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import Form from 'antd/lib/form';
import Typography from 'antd/lib/typography';
import TextField from 'components/ui/TextField/TextField';
import { ValidationFactory } from 'framework/validations';
import { changeEmail } from 'store/auth/auth.actions';
import EditTabContainer from './EditTabContainer';

import styles from './EditTabContainer.module.sass';

export interface IChangeEmailTabProps {
  user: any;
  onCancel(): void;
}

const ChangeEmailTab: React.FC<IChangeEmailTabProps> = ({ user, onCancel }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const FIELDS = [
    {
      name: 'email',
      label: t('profile:newEmailAddress'),
      rules: [ValidationFactory.REQUIRED, ValidationFactory.EMAIL],
    },
  ];

  const onSuccess = () => {
    setIsLoading(false);
    form.resetFields();

    notification.success({
      message: t('profile:changeEmailSuccess'),
      duration: 10,
    });
  };

  const onError = () => {
    setIsLoading(false);
  };

  const onFormSubmit = (values: any) => {
    dispatch(
      changeEmail({
        ...values,
        onSuccess,
        onError,
      }),
    );
  };

  const renderFields = () =>
    FIELDS.map((field) => (
      <Form.Item key={field.name} name={field.name} rules={field.rules} trigger="onInput">
        <TextField
          className={styles.textField}
          label={field.label}
          name={field.name}
          type="email"
        />
      </Form.Item>
    ));

  const formProps = {
    form,
    onFinish: onFormSubmit,
    name: 'change-email',
  };

  const containerProps = {
    onCancel,
    title: t('profile:fixChangeEmail'),
    saveDisabled: isLoading,
    onSave: () => form.submit(),
    onFinish: onFormSubmit,
  };

  return (
    <EditTabContainer {...containerProps}>
      <Typography.Text className={styles.currentEmail}>
        {t('profile:currentEmail')}
        <b> {user.email}</b>
      </Typography.Text>
      <Form {...formProps}>{renderFields()}</Form>
    </EditTabContainer>
  );
};

export default ChangeEmailTab;
