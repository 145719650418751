import {
  ICountry,
  COUNTRIES_GET,
  COUNTRIES_GET_SUCCESS,
  COUNTRIES_GET_ERROR,
} from './country.types';

// Get Countries
export const countriesGet = () => ({
  type: COUNTRIES_GET,
});

export const countriesGetSuccess = (payload: ICountry[]) => ({
  type: COUNTRIES_GET_SUCCESS,
  payload: payload,
});

export const countriesGetError = (error: any) => ({
  type: COUNTRIES_GET_ERROR,
  payload: error,
});
