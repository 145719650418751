import { ISearchDto, RequestState } from 'store/common.types';
import { IProduct } from 'store/product/product.types';
import { RequestResult } from 'types/crud-view.types';
import { ILocalizedValue } from 'types/language.types';

export interface IProductComponent {
  id: string;
  name: string;
  localizedNames: ILocalizedValue[];
  position: number;
  description: string;
  localizedDescriptions: ILocalizedValue[];
  active: boolean;
  products?: IProduct[];
}

export interface IProductComponentState {
  productComponents: IProductComponent[];
  productComponentsGetState: RequestState | null;
  productComponentsGetError: string | null;

  productComponentCreateState: RequestState | null;
  productComponentCreateError: string | null;

  productComponentUpdateState: RequestState | null;
  productComponentUpdateError: string | null;

  productComponentDeleteState: RequestState | null;
  productComponentDeleteError: string | null;

  productWizard: IProductComponent[];
  productWizardComponentsGetState: RequestState | null;
  productWizardComponentsGetError: string | null;

  isActivable: boolean;
  validateActivePosibilityState: RequestState | null;
  validateActivePosibilityError: string | null;
}

export const PRODUCT_COMPONENTS_GET = 'PRODUCT_COMPONENTS_GET';
export const PRODUCT_COMPONENTS_GET_SUCCESS = 'PRODUCT_COMPONENTS_GET_SUCCESS';
export const PRODUCT_COMPONENTS_GET_ERROR = 'PRODUCT_COMPONENTS_GET_ERROR';

export const PRODUCT_COMPONENT_CREATE = 'PRODUCT_COMPONENT_CREATE';
export const PRODUCT_COMPONENT_CREATE_SUCCESS = 'PRODUCT_COMPONENT_CREATE_SUCCESS';
export const PRODUCT_COMPONENT_CREATE_ERROR = 'PRODUCT_COMPONENT_CREATE_ERROR';

export const PRODUCT_COMPONENT_UPDATE = 'PRODUCT_COMPONENT_UPDATE';
export const PRODUCT_COMPONENT_UPDATE_SUCCESS = 'PRODUCT_COMPONENT_UPDATE_SUCCESS';
export const PRODUCT_COMPONENT_UPDATE_ERROR = 'PRODUCT_COMPONENT_UPDATE_ERROR';

export const PRODUCT_COMPONENT_DELETE = 'PRODUCT_COMPONENT_DELETE';
export const PRODUCT_COMPONENT_DELETE_SUCCESS = 'PRODUCT_COMPONENT_DELETE_SUCCESS';
export const PRODUCT_COMPONENT_DELETE_ERROR = 'PRODUCT_COMPONENT_DELETE_ERROR';

export const PRODUCT_WIZARD_GET = 'PRODUCT_WIZARD_GET';
export const PRODUCT_WIZARD_GET_SUCCESS = 'PRODUCT_WIZARD_GET_SUCCESS';
export const PRODUCT_WIZARD_GET_ERROR = 'PRODUCT_WIZARD_GET_ERROR';

export const PRODUCT_WIZARD_ACTIVE_VALIDATE = 'PRODUCT_WIZARD_ACTIVE_VALIDATE';
export const PRODUCT_WIZARD_ACTIVE_VALIDATE_SUCCESS = 'PRODUCT_WIZARD_ACTIVE_VALIDATE_SUCCESS';
export const PRODUCT_WIZARD_ACTIVE_VALIDATE_ERROR = 'PRODUCT_WIZARD_ACTIVE_VALIDATE_ERROR';

export interface ProductComponentsGetAction {
  type: typeof PRODUCT_COMPONENTS_GET;
  payload: {
    poolId: string;
    search?: ISearchDto;
  };
}

export interface ProductComponentsGetSuccessAction {
  type: typeof PRODUCT_COMPONENTS_GET_SUCCESS;
  payload: { items: IProductComponent[]; total: number };
}

export interface ProductComponentsGetErrorAction {
  type: typeof PRODUCT_COMPONENTS_GET_ERROR;
  payload: any;
}

export interface ProductComponentCreateAction {
  type: typeof PRODUCT_COMPONENT_CREATE;
  payload: {
    product: IProductComponent;
    poolId: string;
    onRequestDone?: (status: RequestResult) => void;
  };
}

export interface ProductComponentCreateSuccessAction {
  type: typeof PRODUCT_COMPONENT_CREATE_SUCCESS;
  payload: IProductComponent;
}

export interface ProductComponentCreateErrorAction {
  type: typeof PRODUCT_COMPONENT_CREATE_ERROR;
  payload: any;
}

export interface ProductComponentUpdateAction {
  type: typeof PRODUCT_COMPONENT_UPDATE;
  payload: {
    id: string;
    body: IProductComponent;
    onRequestDone?: (status: RequestResult) => void;
  };
}

export interface ProductComponentUpdateSuccessAction {
  type: typeof PRODUCT_COMPONENT_UPDATE_SUCCESS;
  payload: IProductComponent;
}

export interface ProductComponentUpdateErrorAction {
  type: typeof PRODUCT_COMPONENT_UPDATE_ERROR;
  payload: any;
}

export interface ProductComponentDeleteAction {
  type: typeof PRODUCT_COMPONENT_DELETE;
  payload: {
    id: string;
    onRequestDone?: (status: RequestResult) => void;
  };
}

export interface ProductComponentDeleteSuccessAction {
  type: typeof PRODUCT_COMPONENT_DELETE_SUCCESS;
  payload: string;
}

export interface ProductComponentDeleteErrorAction {
  type: typeof PRODUCT_COMPONENT_DELETE_ERROR;
  payload: any;
}

export interface ProductWizardGetAction {
  type: typeof PRODUCT_WIZARD_GET;
  payload: string;
}

export interface ProductWizardGetSuccessAction {
  type: typeof PRODUCT_WIZARD_GET_SUCCESS;
  payload: IProductComponent[];
}
export interface ProductWizardGetErrorAction {
  type: typeof PRODUCT_WIZARD_GET_ERROR;
  payload: any;
}

export interface ProductWizardActiveValidationAction {
  type: typeof PRODUCT_WIZARD_ACTIVE_VALIDATE;
  payload: string;
}
export interface ProductWizardActiveValidationSuccessAction {
  type: typeof PRODUCT_WIZARD_ACTIVE_VALIDATE_SUCCESS;
  payload: boolean;
}
export interface ProductWizardActiveValidationErrorAction {
  type: typeof PRODUCT_WIZARD_ACTIVE_VALIDATE_ERROR;
  payload: any;
}

export type ProductComponentActionTypes =
  | ProductComponentsGetAction
  | ProductComponentsGetSuccessAction
  | ProductComponentsGetErrorAction
  | ProductComponentCreateAction
  | ProductComponentCreateSuccessAction
  | ProductComponentCreateErrorAction
  | ProductComponentUpdateAction
  | ProductComponentUpdateSuccessAction
  | ProductComponentUpdateErrorAction
  | ProductComponentDeleteAction
  | ProductComponentDeleteSuccessAction
  | ProductComponentDeleteErrorAction
  | ProductWizardGetAction
  | ProductWizardGetSuccessAction
  | ProductWizardGetErrorAction
  | ProductWizardActiveValidationAction
  | ProductWizardActiveValidationSuccessAction
  | ProductWizardActiveValidationErrorAction;
