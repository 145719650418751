import React, { JSX } from 'react';
import _find from 'lodash/find';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  IClientRequest,
  QuestionCalculatedKey,
  QuestionKey,
  QuestionsType,
} from 'store/client-request/client-request.types';
import { isIntermediateManagerSelector } from 'store/auth/auth.selectors';
import ELTO from './ELTO.json';
import styles from './ClientRequestKeyValue.module.sass';
import BlockKeyValue from '../BlockKeyValue/BlockKeyValue';

export interface IELTOClientRequestKeyValue {
  questionKey: QuestionKey | QuestionCalculatedKey | string;
  clientRequest: IClientRequest;
  shouldCheckHideness: boolean;
  schema?: any;
  uiSchema?: any;
}

const ELTOClientRequestKeyValue: React.FC<IELTOClientRequestKeyValue> = ({
  questionKey,
  clientRequest,
  uiSchema,
  shouldCheckHideness,
}) => {
  const { t } = useTranslation();
  const isIntermediateManager = useSelector(isIntermediateManagerSelector);
  const questionType: QuestionsType = uiSchema.options.format;
  const ANSWER_NOT_AVAILABLE = t('installerFlow:tenderDetails:answerNotAvailable');

  const checkHidden = (): { shouldCheckHideness: boolean; hiddenKeys?: boolean | string[] } => {
    const key = questionKey as keyof typeof ELTO.alwaysHiddenQuestionKeys;
    const alwaysHiddenKeys = ELTO.alwaysHiddenQuestionKeys[key];
    if (alwaysHiddenKeys) {
      return { shouldCheckHideness: true, hiddenKeys: alwaysHiddenKeys };
    }
    if (shouldCheckHideness) {
      const key = questionKey as keyof typeof ELTO.conditionalHiddenQuestionKeys;
      const conditionalHiddenKeys = ELTO.conditionalHiddenQuestionKeys[key];
      if (conditionalHiddenKeys)
        return { shouldCheckHideness: true, hiddenKeys: conditionalHiddenKeys };
    }

    return { shouldCheckHideness: false };
  };

  const renderQuestion = () => {
    let answer: any = _find(clientRequest.answers, (answer) => answer.questionKey === questionKey);
    let answerTranslated: string | JSX.Element = ANSWER_NOT_AVAILABLE;

    const label = t(uiSchema.options.question);
    const question = t(uiSchema.options.question);
    const answerValue = answer?.answerValue.value;

    const { shouldCheckHideness, hiddenKeys } = checkHidden();
    // for a Multiple input questions that
    // some(not all) of its inputs should hide, hiddenKeys is an array
    const shouldHideNestedInputs: boolean = Array.isArray(hiddenKeys);
    if (shouldCheckHideness && shouldHideNestedInputs && !isIntermediateManager) {
      return null;
    }

    switch (questionType) {
      case QuestionsType.RadioQuestion: {
        const possibleOptions = uiSchema.options.radioFields.options;
        const selectedOption = possibleOptions.find((option: any) => option.value === answerValue);
        const customInput = answerValue?.text || ANSWER_NOT_AVAILABLE;
        answerTranslated =
          customInput || `${t(selectedOption?.label)}  ${t(selectedOption?.message)}`;
        break;
      }

      case QuestionsType.SingleInputQuestion: {
        const isAppointmentQuestion = Array.isArray(answerValue);
        if (isAppointmentQuestion)
          answerTranslated = (
            <div>
              {answerValue.map((appointment: any, index: any) => {
                return (
                  <div key={`row-${appointment.day}`}>
                    <p>
                      {appointment.day} : {appointment.periods.join(', ')}
                    </p>
                  </div>
                );
              })}
            </div>
          );
        else answerTranslated = answerValue;
        break;
      }

      case QuestionsType.MultipleInputQuestion:
        if (typeof answerValue === 'object') {
          let isLocationQuestion = Boolean(answerValue.lat && answerValue.lng);
          let inputs = Object.keys(answerValue);
          if (shouldCheckHideness && Array.isArray(hiddenKeys)) {
            // filter hidden inputs
            inputs = Object.keys(answerValue).filter(function (questionKey) {
              return !hiddenKeys.includes(questionKey);
            });
          }
          answerTranslated = (
            <div>
              {inputs.map((key: any, index: any) => {
                const inputInfo = uiSchema.options?.multipleInputFields?.inputs?.find(
                  (item: any) => item.key === key,
                );

                const booleanInput = uiSchema.options.multipleInputFields?.booleanOption;
                const inputTitle = inputInfo ? inputInfo.title : booleanInput.title;
                return (
                  answerValue[key] && (
                    <div key={`row-${key}`}>
                      <p>
                        {t(inputTitle)} : {t(answerValue[key])}
                      </p>
                    </div>
                  )
                );
              })}
              {isLocationQuestion && (
                <a
                  href={`http://maps.google.com/?q=${answerValue.lat},${answerValue.lng}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('common:texts:viewOnMap')}
                </a>
              )}
            </div>
          );
        }
        break;
    }

    return answer || answerTranslated ? (
      <BlockKeyValue
        className={styles.block}
        label={label}
        question={question}
        value={answerTranslated}
      />
    ) : null;
  };

  return renderQuestion();
};

export default ELTOClientRequestKeyValue;
