import React from 'react';
import { RowSelectionType } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';
import Table from 'components/ui/Table/Table';

interface DataType {
  key: React.Key;
  name: string;
  purpose: string;
  age: string;
}

interface IProps {
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: (v: React.Key[]) => void;
}

const CookieTable: React.FC<IProps> = ({ selectedRowKeys, setSelectedRowKeys }) => {
  const { t } = useTranslation(['welcomePage']);

  const data: DataType[] = [
    {
      key: 'refreshToken',
      name: 'refreshToken',
      purpose: t('welcomePage:cookies:refreshToken:purpose'),
      age: t('welcomePage:cookies:refreshToken:age'),
    },
  ];

  const columns = [
    {
      title: t('welcomePage:cookies:name'),
      dataIndex: 'name',
    },
    {
      title: t('welcomePage:cookies:purpose'),
      dataIndex: 'purpose',
    },
    {
      title: t('welcomePage:cookies:expirationDate'),
      dataIndex: 'age',
    },
  ];

  const rowSelection = {
    type: 'checkbox' as RowSelectionType,
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.name === 'refreshToken',
      name: record.name,
    }),
  };

  return (
    <>
      <Table rowSelection={rowSelection} columns={columns} dataSource={data} size="small" />
    </>
  );
};

export default CookieTable;
