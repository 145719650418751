import React, { ReactNode } from 'react';
import clsx from 'clsx';
import Typography from 'antd/lib/typography';
import styles from './Rectangle.module.sass';

interface IRectangleProps {
  className?: string;
  contentClassName?: string;
  headingExtra?: ReactNode;
  title: any;
  children: any;
}

const Rectangle: React.FC<IRectangleProps> = ({
  className,
  contentClassName,
  title,
  children,
  headingExtra,
}) => (
  <div className={clsx(styles.rectangle, className)}>
    <div className={styles.rectangleHeading}>
      <Typography className={styles.rectangleTitle}>{title}</Typography>
      {headingExtra}
    </div>
    <div className={clsx(styles.rectangleContent, contentClassName)}>{children}</div>
  </div>
);

export default Rectangle;
