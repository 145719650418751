import React from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import Rectangle from 'components/layout/Rectangle/Rectangle';
import { IProduct } from 'store/product/product.types';

import styles from './ProductComponentWidget.module.sass';

interface IProductComponentWidgetProps {
  products: IProduct[];
  poolOperator?: boolean;
  onEditButtonClicked?: () => void;
}

const ProductComponentWidget: React.FC<IProductComponentWidgetProps> = ({
  products,
  poolOperator,
  onEditButtonClicked,
}) => {
  const { t } = useTranslation();

  const productSections = [
    {
      title: t('intermediate:myProjects:productWizard:formFields:name:label'),
      hide: false,
    },
    {
      title: t('intermediate:myProjects:product:name:label'),
      hide: false,
    },
    {
      title: t('intermediate:myProjects:product:form:pricing:netPrice:label'),
      hide: !poolOperator,
    },
    {
      title: t('intermediate:myProjects:product:form:pricing:totalPrice:label'),
      hide: !poolOperator,
    },
  ];

  const getProduct = (product: IProduct) => {
    return (
      <>
        <Col span={6}>{product.component?.name}</Col>
        <Col span={6}>{product.title}</Col>
        {poolOperator && <Col span={6}>{product.netPrice || '-'}</Col>}
        {poolOperator && <Col span={6}>{product.grossPrice || '-'}</Col>}
      </>
    );
  };

  return (
    <Rectangle
      className={styles.container}
      title={t('installerFlow:tenderDetails:productWizard')}
      headingExtra={
        onEditButtonClicked && (
          <Button
            onClick={onEditButtonClicked}
            shape="circle"
            icon={<EditOutlined />}
            size="small"
          />
        )
      }
    >
      <Row gutter={[16, 16]}>
        {productSections.map((section, index) => (
          <React.Fragment key={index}>
            {!section.hide && (
              <Col span={6} key={index}>
                <Typography.Text strong>{section.title}</Typography.Text>
              </Col>
            )}
          </React.Fragment>
        ))}
      </Row>
      <Row gutter={[16, 16]}>
        {products.map((product) => (
          <React.Fragment key={product.id}>{getProduct(product)}</React.Fragment>
        ))}
      </Row>
    </Rectangle>
  );
};

export default ProductComponentWidget;
