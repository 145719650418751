import {
  IParentPoolsFilterAction,
  IParentPoolsFilterErrorAction,
  IParentPoolsFilterPayload,
  IParentPoolsFilterSuccessAction,
  PARENT_POOLS_FILTER,
  PARENT_POOLS_FILTER_ERROR,
  PARENT_POOLS_FILTER_SUCCESS,
} from './parentPoolsFilter.types';

export const parentPoolsFilterAction = (name: string): IParentPoolsFilterAction => ({
  type: PARENT_POOLS_FILTER,
  payload: name,
});
export const parentPoolsFilterSuccessAction = (
  payload: IParentPoolsFilterPayload,
): IParentPoolsFilterSuccessAction => ({
  type: PARENT_POOLS_FILTER_SUCCESS,
  payload,
});
export const parentPoolsFilterErrorAction = (error: string): IParentPoolsFilterErrorAction => ({
  type: PARENT_POOLS_FILTER_ERROR,
  payload: error,
});
