import { all, call, put, takeLatest } from 'redux-saga/effects';
import PDFTemplateAPI from 'services/pdf-template.service';
import { RequestResult } from 'types/crud-view.types';
import {
  PDFTemplateCreateError,
  PDFTemplateCreateSuccess,
  PDFTemplateGetSuccess,
  PDFTemplateGetError,
  PDFTemplateUpdateError,
  PDFTemplateUpdateSuccess,
  PDFTemplateDeleteSuccess,
  PDFTemplateDeleteError,
} from './pdf-templates.actions';
import {
  IPDFTemplate,
  PDFTemplateCreateAction,
  PDFTemplateDeleteAction,
  PDFTemplatesGetAction,
  PDFTemplateUpdateAction,
  PDF_TEMPLATES_GET,
  PDF_TEMPLATE_CREATE,
  PDF_TEMPLATE_DELETE,
  PDF_TEMPLATE_UPDATE,
} from './pdf-templates.types';

export function* createPDFTemplate(action: PDFTemplateCreateAction) {
  const { poolId, pdfTemplate, onRequestDone } = action.payload;
  try {
    const result: IPDFTemplate = yield call(PDFTemplateAPI.createPDFTemplate, poolId, pdfTemplate);
    if (result) {
      yield put(PDFTemplateCreateSuccess(result));
      onRequestDone && onRequestDone(RequestResult.SUCCESS);
    } else {
      yield put(PDFTemplateCreateError('Empty Response'));
      onRequestDone && onRequestDone(RequestResult.ERROR);
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(PDFTemplateCreateError(message));
  }
}

export function* updatePDFTemplate(action: PDFTemplateUpdateAction) {
  const { id, body, onRequestDone } = action.payload;
  try {
    const result: IPDFTemplate = yield call(PDFTemplateAPI.updatePDFTemplate, id, body);
    if (result) {
      yield put(PDFTemplateUpdateSuccess(result));
      onRequestDone && onRequestDone(RequestResult.SUCCESS);
    } else {
      yield put(PDFTemplateUpdateError('Empty Response'));
      onRequestDone && onRequestDone(RequestResult.ERROR);
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(PDFTemplateUpdateError(message));
  }
}

export function* deletePDFTemplate(action: PDFTemplateDeleteAction) {
  const { id, onRequestDone } = action.payload;
  try {
    const result: IPDFTemplate = yield call(PDFTemplateAPI.deletePDFTemplate, id);
    if (result) {
      yield put(PDFTemplateDeleteSuccess(id));
      onRequestDone && onRequestDone(RequestResult.SUCCESS);
    } else {
      yield put(PDFTemplateDeleteError('Empty Response'));
      onRequestDone && onRequestDone(RequestResult.ERROR);
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(PDFTemplateDeleteError(message));
  }
}

export function* getPDFTemplates(action: PDFTemplatesGetAction) {
  try {
    const result: IPDFTemplate[] = yield call(PDFTemplateAPI.getPDFTemplates, action.payload);
    if (result) {
      yield put(PDFTemplateGetSuccess(result));
    } else {
      yield put(PDFTemplateGetError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(PDFTemplateGetError(message));
  }
}

export default function* root() {
  yield all([
    takeLatest(PDF_TEMPLATE_CREATE, createPDFTemplate),
    takeLatest(PDF_TEMPLATE_UPDATE, updatePDFTemplate),
    takeLatest(PDF_TEMPLATE_DELETE, deletePDFTemplate),
    takeLatest(PDF_TEMPLATES_GET, getPDFTemplates),
  ]);
}
