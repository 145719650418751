import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Dropdown, Menu, message } from 'antd';

import MoreOutlined from '@ant-design/icons/MoreOutlined';
import attachmentBLueIcon from 'assets/icons/attachment-blue.svg';
import { formatDate } from 'framework/dateUtils';
import {
  getComments,
  getFileLink,
  updateComment,
} from 'store/client-request/client-request.actions';
import { isQuestionsSelector } from 'store/client-request/client-request.selectors';
import { userDataSelector } from 'store/auth/auth.selectors';
import { CommentType, QuestionType } from 'store/client-request/client-request.types';

import styles from './Dialog.module.sass';

interface QuestionWithAnswer extends QuestionType {
  answer: QuestionType | undefined;
}

interface IDialogProps {
  clientRequestId: string;
  type: CommentType;
  intermediate?: boolean;
}

const Dialog: React.FC<IDialogProps> = ({ clientRequestId, type, intermediate }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const questionsList = useSelector(isQuestionsSelector);
  const currentUser = useSelector(userDataSelector);

  const handleOpenFile = (name: string) => {
    dispatch(getFileLink(name));
  };

  const handleChangeType = (question: QuestionWithAnswer) => {
    dispatch(
      updateComment({
        clientRequestId,
        commentId: question.id,
        params: { type: CommentType.Public, text: question.text },
        onSuccess: () => {
          message.info(t('installerFlow:tenderDetails:qaBox:questionMoved'));
          dispatch(getComments(clientRequestId));
        },
      }),
    );
  };

  const questions = useCallback(() => {
    const filteredList = questionsList.filter((q) => !q.parentCommentId && q.type === type);
    return filteredList.map((question) => ({
      ...question,
      answer: questionsList.find((q) => q.parentCommentId === question.id),
    }));
  }, [questionsList, type]);

  const menu = (question: QuestionWithAnswer) => (
    <Menu>
      <Menu.Item onClick={() => handleChangeType(question)}>
        {t('installerFlow:tenderDetails:qaBox:moveQuestion')}
      </Menu.Item>
    </Menu>
  );

  const renderQuestions = () => {
    return questions().map((question) => {
      const { answer } = question;
      const questionDate = formatDate(new Date(question.createdAt), 'HH:mm • DD/MM/YY');
      const answerDate = answer && formatDate(new Date(answer.createdAt), 'HH:mm • DD/MM/YY');
      return (
        <div className={styles.item} key={question.id}>
          <div className={styles.header}>
            <div>
              {currentUser.id === question.authorId
                ? t('installerFlow:tenderDetails:qaBox:me')
                : t('installerFlow:tenderDetails:qaBox:installer')}
              :
            </div>
            <div>{questionDate}</div>
          </div>
          <div className={styles.content}>
            <div className={styles.text}>
              {question.text || <span>*{t('installerFlow:tenderDetails:qaBox:fileAttached')}</span>}
            </div>
            {!!question.attachments?.length && (
              <div
                className={styles.file}
                onClick={() => handleOpenFile(question.attachments[0].name)}
              >
                <img src={attachmentBLueIcon} alt="Attachment" />
              </div>
            )}
            {intermediate && type === CommentType.Private && (
              <Dropdown overlay={menu(question)} placement="bottomRight" arrow>
                <div className={styles.more}>
                  <MoreOutlined />
                </div>
              </Dropdown>
            )}
          </div>
          {answer && (
            <>
              <div className={clsx(styles.header, styles.answer)}>
                <div>{t('installerFlow:tenderDetails:qaBox:customersAnswer')}:</div>
                <div>{answerDate}</div>
              </div>
              <div className={styles.content}>
                <div className={styles.text}>
                  {answer.text || (
                    <span>*{t('installerFlow:tenderDetails:qaBox:fileAttached')}</span>
                  )}
                </div>
                {!!answer.attachments.length && (
                  <div
                    className={styles.file}
                    onClick={() => handleOpenFile(answer.attachments[0].name)}
                  >
                    <img src={attachmentBLueIcon} alt="Attachment" />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      );
    });
  };

  return <div className={styles.dialog}>{renderQuestions()}</div>;
};

export default Dialog;
