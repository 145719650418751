import React, { useMemo, useState } from 'react';
import { ControlElement, JsonSchema } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Alert, Col, Form, Row, Tooltip, Typography } from 'antd';

import Checkbox from 'antd/es/checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
import { InfoCircleTwoTone } from '@ant-design/icons';
import InfoBox, { InfoBoxColor } from 'components/ui/InfoBox/InfoBox';
import Notification from 'components/ui/Notification/Notification';
import { ValidationFactory } from 'framework/validations';
import RadioControl from './RadioControl';
import styles from './RadioContainer.module.sass';
import { formatOptionsToQuestions } from '../control.helper';
import { QuestionType } from '../control.types';

interface IRadioContainerProps {
  handleChange(path: string, value: any): void;
  path: string;
  data?: any;
  visible: boolean;
  uischema: ControlElement & { coverImage?: { url: string } };
  schema: JsonSchema;
}
const RadioContainer: React.FC<IRadioContainerProps> = (props) => {
  const { visible, uischema, path, data, handleChange } = props;

  const [checkValue, setCheckValue] = useState(false);

  const { t } = useTranslation();

  const coverImage = uischema.coverImage?.url;
  const uiOptions = uischema.options;
  const radioFields = uiOptions?.radioFields;

  const { customErrors, format, description, question }: any = uiOptions;
  const { checkbox, notification, infoBox, other, options, conditionalInfoBox } = radioFields;

  //check for additional controls
  const isInput = format === QuestionType.RadioWithCustomInput;
  const isNotification = notification && notification.ifIsEqual === data;
  const isConditionalInfoBox = conditionalInfoBox && conditionalInfoBox.ifIsEqual === data;
  const isCheckbox = useMemo(() => {
    if (!checkbox) return false;
    return isInput ? checkbox?.value === data?.selectedOption : checkbox?.value === data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkbox, data]);

  //---- validation declaration based on uiSchema and schema
  const ajvValidationError = customErrors?.ajvValidation
    ? customErrors.ajvValidation
    : 'common:errors:selectOption';

  const checkboxValidationError = customErrors?.checkboxValidation
    ? customErrors.checkboxValidation
    : 'common:errors:confirmCheckbox';

  const customInputValidationError = customErrors?.withOtherValidation
    ? customErrors.withOtherValidation
    : 'common:errors:fieldRequired';

  const ajvValidation = ValidationFactory.AJV_VALIDATION(props.schema, ajvValidationError);
  const validationRules = [ajvValidation];

  if (isInput) {
    const customInputValidation = ValidationFactory.REQUIRED_WITH_OTHER(customInputValidationError);
    validationRules.push(customInputValidation);
  }

  //handlers
  const radioControlProps = {
    options: formatOptionsToQuestions(options),
    hasInput: isInput,
    handleOptionSelection: (value: any) => handleChange(path, value),
    inputProps: {
      title: t(other),
    },
  };

  if (!visible) return;
  return (
    <Form.Item className={styles.radioContainer}>
      {infoBox && (
        <InfoBox className={styles.box} title={t(infoBox.welcome)}>
          <p>{t(infoBox.text1)}</p>
          <p>{t(infoBox.text2)}</p>
          <p className={styles.last}>{t(infoBox.text3)}</p>
        </InfoBox>
      )}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Typography.Title level={4}>
            {t(question)}
            {description && (
              <Tooltip title={t(description)} className={styles.description}>
                <InfoCircleTwoTone twoToneColor="#bfbfbf" />
              </Tooltip>
            )}
          </Typography.Title>
        </Col>
        {coverImage && (
          <Col span={24}>
            <img alt="question cover" src={coverImage} className={styles.coverImage} />
          </Col>
        )}
        <Col span={24}>
          <Form.Item name={path} initialValue={data} rules={validationRules}>
            <RadioControl {...radioControlProps} />
          </Form.Item>
        </Col>
      </Row>

      {isCheckbox && (
        <>
          <Notification
            className={styles.notification}
            content={t(checkbox?.content)}
            size="small"
            type="info"
          />
          <Form.Item
            rules={[ValidationFactory.CHECKBOX_VALIDATION(checkValue, checkboxValidationError)]}
            name="checkbox"
          >
            <Checkbox checked={checkValue} onChange={() => setCheckValue(!checkValue)}>
              {t(checkbox.title)}
            </Checkbox>
          </Form.Item>
        </>
      )}
      {isConditionalInfoBox && (
        <InfoBox
          color={InfoBoxColor.YELLOW}
          className={styles.alertBox}
          title={t('customerFlow:requirements:digging:title')}
        >
          <ul className={styles.requirementsList}>
            <li>
              {t('customerFlow:requirements:digging:el1')}
              <ul>
                <li>{t('customerFlow:requirements:digging:text1')}</li>
                <li>{t('customerFlow:requirements:digging:text2')}</li>
              </ul>
            </li>
            <li>
              {t('customerFlow:requirements:digging:el2')}
              <ul>
                <li>{t('customerFlow:requirements:digging:text3')}</li>
              </ul>
            </li>
          </ul>
        </InfoBox>
      )}
      {isNotification && (
        <Alert style={{ marginTop: '20px' }} message={t(notification.content)} type="error" />
      )}
    </Form.Item>
  );
};

export default withJsonFormsControlProps(RadioContainer);
