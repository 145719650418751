/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnsType } from 'antd/lib/table';
import Filters from 'components/ui/Filters/Filters';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import OfferTag from 'components/ui/OfferTag/OfferTag';
import Table from 'components/ui/Table/Table';
import Tag from 'components/ui/Tag/Tag';
import {
  DEFAULT_FILTERS_VALUE,
  DEFAULT_META_VALUE,
  DEFAULT_PAGINATION_PAGE_SIZE,
} from 'framework/constants';
import { poolOffersGet } from 'store/intermediate/intermediate.actions';
import { poolOffers, poolOffersState } from 'store/intermediate/intermediate.selectors';
import { currentPool as currentPoolSelector } from 'store/intermediate/intermediate.selectors';
import { IOffer, OfferState } from 'store/offer/offer.types';
import { IPool } from 'store/intermediate/intermediate.types';

import { RequestState } from 'store/common.types';
import { getUserFullName } from 'utils/helpers';
import styles from './OffersTab.module.sass';

export interface IActiveOffersTabProps {
  poolId: string;
  activeTabParam: string;
}
enum offerTabs {
  ACTIVE = 'offers',
  EXPIRED = 'expiredOffers',
}
type TFilters = typeof DEFAULT_FILTERS_VALUE;
const ActiveOffersTab: React.FC<IActiveOffersTabProps> = ({ poolId, activeTabParam }) => {
  const [params, setParams] = useState({ ...DEFAULT_FILTERS_VALUE, ...DEFAULT_META_VALUE });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentPool: IPool = useSelector(currentPoolSelector);
  const offers = useSelector(poolOffers);
  const offersState = useSelector(poolOffersState);

  useEffect(() => {
    activeTabParam === offerTabs.EXPIRED
      ? handleApplyFilter('expired', true)
      : handleApplyFilter('expired', '');
  }, [activeTabParam]); // eslint-disable-line

  useEffect(() => {
    dispatch(poolOffersGet({ ...params, poolId }));
  }, [params, dispatch, poolId]);

  const handlePaginationChange = (
    page: number,
    pageSize: number = DEFAULT_PAGINATION_PAGE_SIZE,
  ) => {
    setParams((_params) => ({
      ..._params,
      limit: pageSize,
      offset: pageSize !== _params.limit ? 0 : pageSize * (page - 1),
    }));
  };

  const handleApplyFilter = (name: string, value: any) => {
    setParams((_params) => ({
      ..._params,
      [name]: value,
      ...DEFAULT_META_VALUE,
    }));
  };
  const handleApplyAllFilters = (filters: TFilters) => {
    setParams((_params) => ({
      ..._params,
      ...filters,
      ...DEFAULT_META_VALUE,
    }));
  };
  const handleClearFilters = () => {
    setParams((_params) => ({ ..._params, ...DEFAULT_FILTERS_VALUE, ...DEFAULT_META_VALUE }));
  };

  const OfferTableColumns: ColumnsType<IOffer> = [
    {
      title: 'installerFlow:dashboard:orderNr',
      dataIndex: 'id',
      key: 'id',
      render: (_: string, row: IOffer) => row.clientRequest?.displayId,
    },
    {
      title: 'installerFlow:dashboard:name',
      dataIndex: 'name',
      key: 'name',
      render: (_: string, row: IOffer) => getUserFullName(row.installerUser),
    },
    {
      title: 'installerFlow:dashboard:progress',
      dataIndex: 'state',
      key: 'state',
      render: (state: OfferState) =>
        state ? <OfferTag state={state}>{state}</OfferTag> : <Tag>N/A</Tag>,
    },
    ...(currentPool.isParent
      ? [
          {
            title: 'installerFlow:dashboard:poolName',
            dataIndex: 'pool',
            key: 'pool',
            render: (_: string, row: IOffer) => {
              const { id: poolId, name: poolName } = row.clientRequest?.pool || {};
              return currentPool.id === poolId ? (
                poolName
              ) : (
                <Link to={`/my-projects/${poolId}/offers`} onClick={(e) => e.stopPropagation()}>
                  {poolName}
                </Link>
              );
            },
          },
        ]
      : []),
    {
      title: 'installerFlow:dashboard:zip',
      dataIndex: 'zip',
      render: (_: string, row: IOffer) => {
        return row.clientRequest?.address ? row.clientRequest?.address.postalCode : 'n/a';
      },
    },
    {
      title: 'installerFlow:dashboard:dueDate',
      dataIndex: 'date',
      key: 'date',
      render: (_: string, row: IOffer) => {
        return row.clientRequest ? <DateFormat date={row.clientRequest.endDate} /> : 'n/a';
      },
    },
  ];

  const isFiltersActive = Boolean(params.query || params.state.length);
  return (
    <div className={styles.offers}>
      <Filters
        filters={params}
        setFilters={handleApplyAllFilters}
        inputName="query"
        inputValue={params.query}
        inputPlaceholder={t('intermediate:myProjects:filters:searchNumber')}
        onFiltersChange={handleApplyFilter}
        onClearFilters={handleClearFilters}
        isActive={isFiltersActive}
      >
        <Filters.MultiSelect
          name="state"
          label="Status"
          options={Object.values(OfferState).map((state) => ({
            value: state,
            label: t(`common:allStates:${state}`),
          }))}
          // [TODO]: Use correct naming
          placeholder={t('intermediate:myProjects:filters:searchStatus')}
        />
      </Filters>

      <Table
        columns={OfferTableColumns.map((order: any) => ({ ...order, title: t(`${order.title}`) }))}
        rowKey="id"
        dataSource={offers?.items || []}
        loading={offersState === RequestState.Loading}
        onRowClick={(record) => `/my-projects/client-request/${record.clientRequest.id}`}
        pagination={{
          showSizeChanger: true,
          total: offers?.total || 0,
          position: ['bottomCenter'],
          pageSize: params.limit,
          current: params.offset ? params.offset / params.limit + 1 : 1,
          onChange: handlePaginationChange,
        }}
      />
    </div>
  );
};

export default ActiveOffersTab;
