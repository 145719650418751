import React from 'react';
import { Typography } from 'antd';
import styles from './FilterItemContainer.module.sass';

interface IFilterItemContainerProps {
  label?: string;
  children: any;
}
const FilterItemContainer: React.FC<IFilterItemContainerProps> = ({ label, children }) => {
  return (
    <div className={styles.filterItemContainer}>
      <Typography.Paragraph className={styles.title}>{label}</Typography.Paragraph>
      <div className={styles.element}>{children}</div>
    </div>
  );
};

export default FilterItemContainer;
