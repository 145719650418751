import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import styles from './VideoCallLayout.module.sass';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

interface IVideoCallLayoutProps {
  children?: React.ReactElement | ReactNode;
}

const VideoCallLayout: React.FC<IVideoCallLayoutProps> = (props) => {
  return (
    <div className={styles.defaultLayout}>
      <Header />
      <div className={styles.container}>{props.children ? props.children : <Outlet />}</div>
      <Footer />
    </div>
  );
};

export default VideoCallLayout;
