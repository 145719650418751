export enum QuestionnaireStepType {
  QuickCheck = 'quickCheck',
  User = 'user',
  CostCalculation = 'costCalculation',
  RemoteHomeCheck = 'remoteHomeCheck',
  Wallbox = 'wallbox',
}

export type IRadionOption = {
  value: string;
  label: string;
};
