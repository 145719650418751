import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Typography, notification } from 'antd';
import Button from 'components/ui/Button/Button';
import Rectangle from 'components/layout/Rectangle/Rectangle';
import OfferDatePickerModal from 'components/modals/OfferDatePickerModal';
import {
  AppointmentPurpose,
  IOffer,
  OfferEventType,
  OfferState,
  installationStates,
} from 'store/offer/offer.types';
import { IClientRequest } from 'store/client-request/client-request.types';
import { downloadCalendarEvent, offerCreate, offerTransition } from 'store/offer/offer.actions';

import { userDataSelector } from 'store/auth/auth.selectors';
import { waitingFormatDate } from 'utils/dateUtils';
import { checkIsHomeCheck } from 'utils/offerHelpers';
import DateSelectionModal from './components/DateSelectionModal';
import { ActionButtons } from './components/ActionButtons';
import styles from '../OrderDetailsPage.module.sass';
import SectionFeedback from '../SectionFeedback';

interface ISectionNextStepsProps {
  date: any;
  offer: IOffer;
  clientRequest: IClientRequest | null;
  id: string;
}

const SectionNextSteps: React.FC<ISectionNextStepsProps> = (props) => {
  const { offer, date, id, clientRequest } = props;

  const [isDateModalVisible, setDateModalVisible] = useState(false);
  const [isDateSelectionModal, setDateSelectionModal] = useState(false);

  const currentUser = useSelector(userDataSelector);

  const hasInstallationOffer =
    clientRequest?.offers?.find((item) => installationStates.includes(item.state!))
      ?.installerUserId === currentUser?.id;

  const appointments = useMemo(() => {
    if (offer?.state) {
      const purpose = checkIsHomeCheck(offer.state)
        ? AppointmentPurpose.HomeCheck
        : AppointmentPurpose.Installation;

      return offer.appointments?.filter((item) => item.purpose === purpose) || [];
    }
    return [];
  }, [offer]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!offer) {
    return <div>Loading offer</div>;
  }

  const isScheduleOffButtons = [
    OfferState.OfferAcceptedHomeCheckAppointmentNA,
    OfferState.OfferAcceptedRemoteHomeCheckAppointmentNA,
    OfferState.OfferAccepted,
  ].includes(offer.state!);

  const isRemoteHomeCheck = offer.state === OfferState.OfferRemoteHomeCheckPending;

  const handleExportAppointment = () => {
    dispatch(downloadCalendarEvent(offer.id!));
  };

  const suggestAppointmentsOff = () =>
    dispatch(offerTransition(OfferEventType.INSTALLER_OFFER_APPOINTMENT_OFF_TENDERGY, offer));

  const handleConfirmAppointmentClick = () => {
    if (!appointments.length) {
      handleAcceptOfferWithAppointment();
    } else {
      setDateSelectionModal(true);
    }
  };

  const handleAcceptOfferWithAppointment = (appointment?: string) => {
    let transitionType: OfferEventType;
    let dateField = 'finalInstallationTimestamp';

    if (!appointment && appointments.length) {
      notification.error({ message: t('installerFlow:orderDetails:error:appointmentNotSelected') });
      return;
    }

    if (checkIsHomeCheck(offer?.state!)) {
      transitionType = !appointment
        ? OfferEventType.CUSTOMER_ACCEPTED_WITHOUT_APPOINTMENT
        : OfferEventType.CUSTOMER_SELECTED_DATE;
      dateField = 'finalHomeCheckTimestamp';
    } else {
      transitionType = OfferEventType.CUSTOMER_APPOINTMENT_CONFIRMED;
    }

    const data = appointment ? { id: offer.id, [dateField]: appointment } : offer;
    dispatch(offerTransition(transitionType, data, handleSuccessTransition));
  };

  const handleSuccessTransition = () => {
    setDateSelectionModal(false);
    navigate('/installer/dashboard/homecheck-offers');
  };

  const renderButtons = (onConfirm: any) => (
    <ActionButtons
      meetingId={offer.meetingId}
      isScheduleOffButtons={isScheduleOffButtons}
      isRemoteHomeCheck={isRemoteHomeCheck}
      offerState={offer.state}
      suggestAppointmentsOff={suggestAppointmentsOff}
      onConfirm={onConfirm}
      onConfirmAppointmentClick={handleConfirmAppointmentClick}
    />
  );

  const renderText = () => {
    switch (offer.state) {
      case OfferState.InvoiceRejectedInstallation:
        return (
          <>
            <Typography.Paragraph className={styles.text}>
              {t('installerFlow:orderDetails:invoiceRejectedInstallation:text')}
              {offer.rejectReason}
            </Typography.Paragraph>
            {renderButtons(() => {
              offer.actualInstallationTimestamp
                ? navigate(`/installer/order/${offer.id}/documentation`)
                : setDateModalVisible(true);
            })}
          </>
        );
      case OfferState.OfferHomeCheckPending:
      case OfferState.OfferRemoteHomeCheckPending:
        return (
          <>
            <Typography.Paragraph className={styles.text}>
              {offer.appointments?.length
                ? t('installerFlow:orderDetails:homecheckPending:text')
                : t('installerFlow:orderDetails:homecheckPending:textNoAppointment')}
            </Typography.Paragraph>
            {date ? (
              <Typography.Paragraph className={styles.selectedTime}>
                {waitingFormatDate(date)}
                <Button size="small" onClick={handleExportAppointment}>
                  {t('installerFlow:orderDetails:exportAppointment')}
                </Button>
              </Typography.Paragraph>
            ) : null}
            <Typography.Paragraph className={styles.text}>
              {offer.appointments?.length
                ? t('installerFlow:orderDetails:homecheckPending:text2')
                : t('installerFlow:orderDetails:homecheckPending:textNoAppointment2')}
            </Typography.Paragraph>
            {/* We redirect the installer to the page from CRAFT-788 and make and offer with HOME_CHECK_DONE */}
            {renderButtons(() => {
              offer.actualHomeCheckTimestamp
                ? navigate(`/installer/order/${id}/documentation`)
                : setDateModalVisible(true);
            })}
          </>
        );

      case OfferState.TenderAccepted:
        return (
          <>
            <Typography.Paragraph className={styles.text}>
              {t('installerFlow:orderDetails:tenderAccepted:text')}
            </Typography.Paragraph>
            <Typography.Paragraph className={styles.text}>
              {t('installerFlow:orderDetails:tenderAccepted:text2')}
            </Typography.Paragraph>
          </>
        );

      case OfferState.OfferAccepted:
        return (
          <>
            <Typography.Paragraph className={styles.text}>
              {t('installerFlow:orderDetails:offerAccepted:text')}
            </Typography.Paragraph>
            <Typography.Paragraph className={styles.text}>
              {t('installerFlow:orderDetails:offerAccepted:text2')}
            </Typography.Paragraph>
            {/* for the OfferAccepted We redirect the installer to the date selection page and make an offer with INSTALLER_APPOINTMENT_CREATED */}
            {renderButtons(() => {
              navigate(`/installer/order/${id}/appointment`);
            })}
          </>
        );

      case OfferState.TenderSubmittedHomeCheck:
      case OfferState.TenderSubmittedRemoteHomeCheck:
        return (
          <>
            <Typography.Paragraph className={styles.text}>
              {t(`installerFlow:orderDetails:${OfferState.TenderSubmittedHomeCheck}:text`)}
            </Typography.Paragraph>
            {renderButtons(() => {
              navigate(`/installer/order/${id}/appointment`);
            })}
          </>
        );
      case OfferState.TenderAcceptedHomeCheck:
      case OfferState.TenderAcceptedRemoteHomeCheck:
        return (
          <>
            <Typography.Paragraph className={styles.text}>
              {t(`installerFlow:orderDetails:${OfferState.TenderAcceptedHomeCheck}:text`)}
            </Typography.Paragraph>
            {renderButtons(() => {
              navigate(`/installer/order/${id}/appointment`);
            })}
          </>
        );
      case OfferState.OfferSubmitted:
        return (
          <>
            <Typography.Paragraph className={styles.text}>
              {t(`installerFlow:orderDetails:${OfferState.TenderAcceptedHomeCheck}:text`)}
            </Typography.Paragraph>
            {renderButtons(() => {
              navigate(`/installer/order/${id}/appointment`);
            })}
          </>
        );

      case OfferState.InstallationPending: {
        const key = 'installerFlow:orderDetails:installationPendingState';
        return (
          <>
            <Typography.Paragraph className={styles.text}>
              {t(`${key}:${date ? '' : 'appointmentsOff:'}text`)}
            </Typography.Paragraph>
            {date && (
              <Typography.Paragraph className={styles.selectedTime}>
                {waitingFormatDate(date)}
                <Button size="small" onClick={handleExportAppointment}>
                  {t('installerFlow:orderDetails:exportAppointment')}
                </Button>
              </Typography.Paragraph>
            )}
            <Typography.Paragraph className={styles.text}>
              {t(`${key}:${date ? '' : 'appointmentsOff:'}text2`)}
            </Typography.Paragraph>
            {renderButtons(() => {
              offer.actualInstallationTimestamp
                ? navigate(`/installer/order/${offer.id}/documentation`)
                : setDateModalVisible(true);
            })}
          </>
        );
      }

      case OfferState.HomeCheckInvoiceRequested:
        return (
          <>
            <Typography.Paragraph className={styles.text}>
              {t('installerFlow:orderDetails:homeCheckInvoiceRequested:text')}
            </Typography.Paragraph>
            {renderButtons(() => {
              navigate(`/installer/order/${id}/cancel-invoice`);
            })}
          </>
        );

      case OfferState.InvoiceRejectedHomeCheck:
        return (
          <>
            <Typography.Paragraph className={styles.text}>
              {t('installerFlow:orderDetails:invoiceRejectedHomeCheck:text')} {offer.rejectReason}
            </Typography.Paragraph>
            {renderButtons(() => {
              offer.actualHomeCheckTimestamp
                ? navigate(`/installer/order/${id}/documentation`)
                : setDateModalVisible(true);
            })}
          </>
        );

      case OfferState.InvoicePaidHomeCheck:
      case OfferState.InvoicePaidRemoteHomeCheck:
        return (
          <>
            <Typography.Paragraph className={styles.text}>
              {t('installerFlow:orderDetails:invoicePaidHomeCheck:text')}
            </Typography.Paragraph>
            {renderButtons(() => {
              navigate(`/installer/order/${id}/make-offer`);
            })}
          </>
        );

      case OfferState.OfferAcceptedHomeCheckAppointmentNA:
      case OfferState.OfferAcceptedRemoteHomeCheckAppointmentNA:
        return (
          <>
            <Typography.Paragraph className={styles.text}>
              {t('installerFlow:orderDetails:offerAcceptedHomeCheckAppointmentNA:text')}
            </Typography.Paragraph>
            <Typography.Paragraph className={styles.text}>
              {t('installerFlow:orderDetails:offerAcceptedHomeCheckAppointmentNA:text2')}
            </Typography.Paragraph>
            {renderButtons(() => {
              navigate(`/installer/order/${id}/appointment`);
            })}
          </>
        );

      case OfferState.InvoiceSubmittedInstallation:
      case OfferState.InvoiceAcceptedInstallation:
      case OfferState.InstallationDone:
        return <SectionFeedback />;

      case OfferState.TenderSubmitted:
      case OfferState.TenderRejected:
      case OfferState.OfferRejected:
      case OfferState.OfferRejectedHomeCheck:
      case OfferState.InvoicePaymentPendingHomeCheck:
      case OfferState.InvoicePaymentPendingRemoteHomeCheck:
      case OfferState.Review:
        return (
          <Typography.Paragraph className={styles.text}>
            {t(`installerFlow:orderDetails:${offer.state}:text`)}
          </Typography.Paragraph>
        );
      case OfferState.InvoiceSubmittedHomeCheck:
      case OfferState.InvoiceSubmittedRemoteHomeCheck:
        return (
          <>
            <Typography.Paragraph className={styles.text}>
              {t(`installerFlow:orderDetails:${offer.state}:text`)}
            </Typography.Paragraph>
            {!hasInstallationOffer &&
              renderButtons(() =>
                dispatch(
                  offerCreate({ clientRequestId: clientRequest?.id }, (installationOffer: IOffer) =>
                    navigate(`/installer/order/${installationOffer.id}/make-offer`),
                  ),
                ),
              )}
          </>
        );
      case OfferState.OfferAcceptedHomeCheckAppointmentTBD:
      case OfferState.OfferAcceptedRemoteHomeCheckAppointmentTBD:
        return (
          <>
            <Typography.Paragraph className={styles.text}>
              {t(`installerFlow:orderDetails:${offer.state}:text`)}
            </Typography.Paragraph>
            {renderButtons(() => setDateSelectionModal(true))}
          </>
        );
      default:
        return <Typography.Paragraph>Unexpected state: {offer.state}</Typography.Paragraph>;
    }
  };

  const offerDatePickerModalProps = {
    open: isDateModalVisible,
    offer,
    onCancel: () => setDateModalVisible(false),
  };

  return (
    <Rectangle title={t(`installerFlow:orderDetails:title:${offer.state}`)}>
      {renderText()}
      <OfferDatePickerModal {...offerDatePickerModalProps} />
      <DateSelectionModal
        appointments={appointments}
        open={isDateSelectionModal}
        onSubmit={handleAcceptOfferWithAppointment}
        onCancel={() => setDateSelectionModal(false)}
      />
    </Rectangle>
  );
};

export default SectionNextSteps;
