import React from 'react';
import Typography from 'antd/lib/typography';
import { useLocation, Link } from 'react-router-dom';
import styles from './NotFound.module.sass';

const NotFound = () => {
  let location = useLocation();
  return (
    <div className={styles.container}>
      <Typography.Title className={styles.title} level={2}>
        404
      </Typography.Title>

      <Typography.Title className={styles.title} level={4}>
        Oops! Page <strong className={styles.strong}>{location.pathname}</strong> not found
      </Typography.Title>

      <Typography className={styles.text}>
        Sorry, we couldn&apos;t find this page. But don&apos;t worry, you can{' '}
        <Link className={styles.link} to="/">
          go back to homepage
        </Link>
        .
      </Typography>
    </div>
  );
};

export default NotFound;
