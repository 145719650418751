import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Typography from 'antd/lib/typography';
import { useDispatch } from 'react-redux';
import installerImage from 'assets/images/installer.jpg';
import Button from 'components/ui/Button/Button';
import { changeStep } from 'store/app/app.actions';
import styles from './InstallerRegistrationPage.module.sass';

interface ILandingStepProps {
  inviteTokenData: any;
}

const LandingStep: React.FC<ILandingStepProps> = ({ inviteTokenData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onButtonClick = () => {
    dispatch(changeStep(1, 1));
  };

  return (
    <Row gutter={[16, 16]}>
      <Col className={styles.container}>
        <img className={styles.img} src={installerImage} alt="Installer" />
        <Typography.Title level={2} className={styles.landingTitle}>
          {t('installerFlow:registration:inviteMessage', {
            poolName: inviteTokenData.clientRequestPool.name,
            organizationName: inviteTokenData.clientRequestPool?.intermediateOrganization?.name,
          })}
        </Typography.Title>
        <Typography.Paragraph>
          {t('installerFlow:registration:inviteSubtext', {
            poolName: inviteTokenData.clientRequestPool.name,
            organizationName: inviteTokenData.clientRequestPool?.intermediateOrganization?.name,
          })}
        </Typography.Paragraph>
        <Button type="primary" onClick={onButtonClick} className={styles.joinButton}>
          {t('installerFlow:registration:join')}
        </Button>
      </Col>
    </Row>
  );
};

export default LandingStep;
