import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import styles from './EditTabContainer.module.sass';

interface IEditTabContainerProps {
  children: any;
  title?: string;
  saveDisabled?: boolean;
  saveButtonText?: string;
  saveButtonType?: string;
  onCancel(): void;
  onSave(): void;
}

const EditTabContainer: React.FC<IEditTabContainerProps> = ({
  children,
  title,
  saveDisabled,
  saveButtonText,
  onCancel,
  onSave,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      {title ? <h2 className={styles.title}>{title}</h2> : null}
      <div>{children}</div>
      <div className={styles.actions}>
        <Button type="text" onClick={onCancel}>
          {t('common:buttons:cancel')}
        </Button>
        <Button type="primary" disabled={saveDisabled} onClick={onSave}>
          {saveButtonText || t('common:buttons:saveChanges')}
        </Button>
      </div>
    </div>
  );
};

export default EditTabContainer;
