import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import Form from 'antd/lib/form';
import Typography from 'antd/lib/typography';
import { Button, Flex, Space } from 'antd';
import BackButton from 'components/ui/BackButton/BackButton';

import Stepper from 'components/ui/Stepper/Stepper';
import DocumentationSetting from 'pages/MyProjects/CreatePool/DocumentationSetting';
import CostCalculatorSetting from 'pages/MyProjects/CreatePool/CostCalculatorSetting';
import QuickCheckSetting from 'pages/MyProjects/CreatePool/QuickCheckSetting';
import GeneralSettings from 'pages/MyProjects/CreatePool/GeneralSettings/GeneralSettings';

import { MediaCategoryType } from 'store/client-request/client-request.types';
import { countriesListSelector } from 'store/country/country.selectors';
import { countriesGet } from 'store/country/country.actions';

import { poolCreate } from 'store/intermediate/intermediate.actions';
import {
  CostCalculationModeOptionsType,
  DocumentationModeOptionsType,
  InstallationSelectionModeOptionsType,
  PoolSettingsFieldsType,
  PoolType,
  RequestValidationOptionsType,
} from 'types/pool-settings.types';

import { handleOfferInvoiceSettings } from 'utils/poolUtils';
import PoolNameStep from './steps/pool-name/PoolNameStep';
import styles from './CreatePool.module.sass';

const FormValues = {
  // Step 2
  [PoolSettingsFieldsType.PoolType]: PoolType.RequestPool,
  [PoolSettingsFieldsType.RequireRequestConfirmation]: true,
  [PoolSettingsFieldsType.RequestValidationSelectionMode]: RequestValidationOptionsType.Default,

  [PoolSettingsFieldsType.HomeCheckOfferApproval]: 'replaceByIntermediate',
  [PoolSettingsFieldsType.InstallationOfferApproval]: 'replaceByIntermediate',
  [PoolSettingsFieldsType.InstallationInvoiceApproval]: 'replaceByIntermediate',
  [PoolSettingsFieldsType.InstallationSelectionMode]:
    InstallationSelectionModeOptionsType.Unrestricted,
  [PoolSettingsFieldsType.RequestValidity]: null,
  // Step 3
  [PoolSettingsFieldsType.DeactivateQuickCheck]: false,

  // Step 4
  [PoolSettingsFieldsType.CostCalculationMode]: CostCalculationModeOptionsType.Manual,

  // Step 5
  [PoolSettingsFieldsType.DocumentationMode]: DocumentationModeOptionsType.Automatic,
  [PoolSettingsFieldsType.DocumentsAllowed]: [
    MediaCategoryType.OfferInvoiceInstallation,
    MediaCategoryType.InstallationFinalDocumentation,
    MediaCategoryType.OfferNetworkRegistration,
  ],
};

const CreatePool: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isSurvey, setIsSurvey] = useState(false);

  const countries = useSelector(countriesListSelector);

  const [form] = Form.useForm();

  const [step, setStep] = useState(1);

  const steps = [
    { key: 1, title: 'stepper:createNewPool:step1', content: <PoolNameStep /> },
    { key: 2, title: 'stepper:createNewPool:step2' },
    { key: 3, title: 'stepper:createNewPool:step3' },
    { key: 4, title: 'stepper:createNewPool:step4' },
    { key: 5, title: 'stepper:createNewPool:step5' },
  ];

  useEffect(() => {
    dispatch(countriesGet());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (countries.length) {
      const defaultCountry = countries.find((country) => country.isDefault);
      defaultCountry && form.setFieldsValue({ countries: [defaultCountry.iso31661Alpha3] });
    }
  }, [countries]); // eslint-disable-line

  const onCreateProjectPool = (values: any) =>
    dispatch(
      poolCreate(
        {
          ...values,
          isDefault: true,
          country: countries.find((country) => country.iso31661Alpha3 === values.country),
        },
        onCancelClick,
      ),
    );

  const createError = (name: string) => ({
    name: name,
    errors: [t('errors:checkBoxUnable')],
  });

  const onNextClick = async () => {
    try {
      await form.validateFields();
      const values = await form.getFieldsValue(true);
      if (values.parentId) {
        const { parentId, name, poolType, isParent } = values;
        onCreateProjectPool({ parentId, name, poolType, isParent });
        return;
      }
      if (step === 2) {
        const errors = [
          PoolSettingsFieldsType.HomeCheckOfferApproval,
          PoolSettingsFieldsType.InstallationInvoiceApproval,
          PoolSettingsFieldsType.InstallationOfferApproval,
        ].map((name) => createError(name));
        const errorField = handleOfferInvoiceSettings(values);
        if (errorField) {
          errorField === PoolSettingsFieldsType.HomeCheckOfferApproval
            ? form.setFields(errors.filter((err) => err.name !== errorField))
            : form.setFields(errors.filter((err) => err.name === errorField));
          new Error(t('errors:fillValues'));
        }
      }

      if (step === 5) onCreateProjectPool(values);
      setStep((prevStep) => prevStep + 1);
    } catch (e) {
      console.log(e);
    }
  };

  const onCancelClick = () => {
    navigate('/my-projects');
  };

  const onBackButtonClick = () => setStep((prevStep) => (prevStep > 1 ? prevStep - 1 : 0));

  const renderSteps = () => {
    switch (step) {
      case 1:
        return <PoolNameStep />;

      case 2:
        return <GeneralSettings isCreating isSurvey={isSurvey} />;

      case 3:
        return <QuickCheckSetting />;

      case 4:
        return <CostCalculatorSetting />;

      case 5:
        return <DocumentationSetting />;

      default:
        return null;
    }
  };

  const handleFieldsChange = (changedFields: any) => {
    const isPoolTypeChanged = Object.keys(changedFields).some((key) =>
      ['poolType', 'isParent', 'parentId'].includes(key),
    );

    if (isPoolTypeChanged) {
      const payload = changedFields.isParent
        ? {
            poolType: PoolType.RequestPool,
            parentId: null,
          }
        : {
            isParent: false,
          };
      form.setFieldsValue(payload);
      setIsSurvey(form.getFieldValue('poolType') === PoolType.SurveyPool);
    }
  };

  return (
    <>
      <Typography.Title level={3}>{t('intermediate:myProjects:createPool:title')}</Typography.Title>
      <Stepper steps={steps} activeStep={step} />
      <div className={styles.createPoolContainer}>
        <Form
          form={form}
          preserve
          initialValues={FormValues}
          layout="vertical"
          onValuesChange={handleFieldsChange}
        >
          {renderSteps()}
        </Form>

        <Flex justify="space-between">
          <BackButton
            size="middle"
            disabled={step === 1}
            className={clsx(styles.back, {
              [styles.hidden]: step === 1,
            })}
            onClick={onBackButtonClick}
          >
            {t('common:buttons.back')}
          </BackButton>
          <Space>
            <Button type="text" onClick={onCancelClick} danger>
              Cancel
            </Button>
            <Button className={styles.buttonNext} onClick={onNextClick} type="primary">
              {step === 5
                ? t('intermediate:myProjects:createPool:submitButton')
                : t('common:buttons:continue')}
            </Button>
          </Space>
        </Flex>
      </div>
    </>
  );
};

export default CreatePool;
