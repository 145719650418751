import React, { useContext } from 'react';
import clsx from 'clsx';
import ArrowRight from 'assets/icons/arrow-right-white.svg';
import PoolThemeContext from 'context/poolTheme.context';
import styles from './NextButton.module.sass';
import Button, { IconPosition, IButtonProps } from '../Button/Button';
import Icon, { IconSize } from '../Icon/Icon';

interface IBackButtonProps extends IButtonProps {
  className?: string;
  children: string;
  onClick?: () => void;
}

const NextButton: React.FC<IBackButtonProps> = ({ children, className, onClick, ...rest }) => {
  const [poolTheme] = useContext(PoolThemeContext);

  const buttonProps = {
    ...rest,
    onClick,
    icon: (
      <Icon
        icon={ArrowRight}
        size={IconSize.MEDIUM}
        className={styles.icon}
        key="next-button-icon"
      />
    ),
    iconPosition: IconPosition.RIGHT,
    className: clsx(styles.button, className),
    style: {
      backgroundColor: poolTheme.color,
      fontFamily: poolTheme.fontFamily,
    },
  };

  return (
    <Button {...buttonProps} type="primary">
      <span className={styles.text}>{children}</span>
    </Button>
  );
};

export default NextButton;
