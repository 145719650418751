import { AxiosError } from 'axios';
import { IClientRequest, IClientRequestAnswer } from 'store/client-request/client-request.types';
import { RequestState } from 'store/common.types';
import { IOrganization } from 'store/intermediate/intermediate.types';
import { IInstallerRegister } from 'store/register/register.types';
import { SupportedLanguages } from '../../framework/constants';

export type TUserRoles =
  | 'admin'
  | 'user'
  | 'installer'
  | 'installer manager'
  | 'intermediate manager';

export interface IUserCredentials {
  email: string;
  password: string;
  fingerprint?: string;
}

export interface IUserRegister {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  fingerprint?: string;
  inviteToken?: string;
  title: string;
  phone: string;
  country?: string;
}
export interface IDefaultLanguage {
  iso31661Alpha3: string;
  isoCode: SupportedLanguages;
  isoName: string;
  nativeName: string;
}

export interface IUserObject {
  email: string;
  id: string;
  name?: string;
  firstName: string;
  lastName: string;
  organization?: IOrganization;
  exp: number;
  iat: number;
  phone?: string;
  emailVerified?: boolean;
  roles: TUserRoles[];
  installer?: IInstallerRegister;
  displayId?: string;
}

export interface IAuthState {
  user: IUserObject;
  token?: string | null;
  loading: boolean;
  error?: string | null;
  fingerprint?: string | null;

  loginState: RequestState;

  customerRegistrationState: RequestState;
  customerRegistrationError: AxiosError<{ code: string }> | null;

  verifyingEmail: boolean;
  verifyEmailSuccess: boolean | null;
  verifyEmailError: boolean | null;

  changeEmailLoading: boolean;
  changeEmailSuccess: boolean;
  changeEmailError: null | any;

  verifyingInstaller: boolean;
  verifyInstallerSuccess: boolean | null;
  verifyInstallerError: boolean | null;

  passwordResetRequestSuccess?: boolean | null;
  passwordResetRequestError?: string | null;

  passwordResetUpdateSuccess?: boolean | null;
  passwordResetUpdateError?: string | null;

  dataByToken?: IPassResetTypes | null;
  verifyPasswordResetTokenSuccess?: boolean | null;
  verifyPasswordResetTokenError?: string | null;
  verifyPasswordResetTokenLoading: boolean;
}

export interface IPassResetTypes {
  organization: {
    logo: string;
    name: string;
  };
  pool: {
    dataPrivacy: string;
    imprint: string;
  };
  thirdPartyUser: boolean;
}

export interface IVerifyEmail {
  token: string;
}

// Init authentication flow action type
export const AUTH_INIT = 'AUTH_INIT';
export const AUTH_FINGERPRINT = 'AUTH_FINGERPRINT';

// Login action types
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';

// Login with token
export const AUTH_TOKEN_LOGIN = 'AUTH_TOKEN_LOGIN';
export const AUTH_TOKEN_LOGIN_SUCCESS = 'AUTH_TOKEN_LOGIN_SUCCESS';
export const AUTH_TOKEN_LOGIN_ERROR = 'AUTH_TOKEN_LOGIN_ERROR';

// Refresh token action types
export const AUTH_TOKEN_REFRESH = 'AUTH_TOKEN_REFRESH';
export const AUTH_TOKEN_REFRESH_SUCCESS = 'AUTH_TOKEN_REFRESH_SUCCESS';
export const AUTH_TOKEN_REFRESH_ERROR = 'AUTH_TOKEN_REFRESH_ERROR';

// Verify Reset Password Token

export const VERIFY_PASSWORD_RESET_TOKEN = 'VERIFY_PASSWORD_RESET_TOKEN';
export const VERIFY_PASSWORD_RESET_TOKEN_SUCCESS = 'VERIFY_PASSWORD_RESET_TOKEN_SUCCESS';
export const VERIFY_PASSWORD_RESET_TOKEN_ERROR = 'VERIFY_PASSWORD_RESET_TOKEN_ERROR';

// Logout action types
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_ERROR = 'AUTH_LOGOUT_ERROR';

// Register action types
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS';
export const AUTH_REGISTER_ERROR = 'AUTH_REGISTER_ERROR';

// Verify email types
export const AUTH_VERIFY_EMAIL = 'AUTH_VERIFY_EMAIL';
export const AUTH_VERIFY_EMAIL_SUCCESS = 'AUTH_VERIFY_EMAIL_SUCCESS';
export const AUTH_VERIFY_EMAIL_ERROR = 'AUTH_VERIFY_EMAIL_ERROR';

// Verify change email types
export const AUTH_VERIFY_CHANGE_EMAIL = 'AUTH_VERIFY_CHANGE_EMAIL';
export const AUTH_VERIFY_CHANGE_EMAIL_SUCCESS = 'AUTH_VERIFY_CHANGE_EMAIL_SUCCESS';
export const AUTH_VERIFY_CHANGE_EMAIL_ERROR = 'AUTH_VERIFY_CHANGE_EMAIL_ERROR';

// Change email
export const AUTH_CHANGE_EMAIL = 'AUTH_CHANGE_EMAIL';
export const AUTH_CHANGE_EMAIL_SUCCESS = 'AUTH_CHANGE_EMAIL_SUCCESS';
export const AUTH_CHANGE_EMAIL_ERROR = 'AUTH_CHANGE_EMAIL_ERROR';

// Verify installer confirmation
export const AUTH_VERIFY_INSTALLER = 'AUTH_VERIFY_INSTALLER';
export const AUTH_VERIFY_INSTALLER_SUCCESS = 'AUTH_VERIFY_INSTALLER_SUCCESS';
export const AUTH_VERIFY_INSTALLER_ERROR = 'AUTH_VERIFY_INSTALLER_ERROR';

// Reset password action types
// Password reset request action types (first step)
export const AUTH_PASSWORD_RESET_REQUEST = 'AUTH_PASSWORD_RESET_REQUEST';
export const AUTH_PASSWORD_RESET_REQUEST_SUCCESS = 'AUTH_PASSWORD_RESET_REQUEST_SUCCESS';
export const AUTH_PASSWORD_RESET_REQUEST_ERROR = 'AUTH_PASSWORD_RESET_REQUEST_ERROR';

// Password reset confirm action types (confirmation/verification step)
export const AUTH_PASSWORD_RESET_CONFIRM = 'AUTH_PASSWORD_RESET_CONFIRM';
export const AUTH_PASSWORD_RESET_CONFIRM_SUCCESS = 'AUTH_PASSWORD_RESET_CONFIRM_SUCCESS';
export const AUTH_PASSWORD_RESET_CONFIRM_ERROR = 'AUTH_PASSWORD_RESET_CONFIRM_ERROR';

// Password reset update action types (final step)
export const AUTH_PASSWORD_RESET_UPDATE = 'AUTH_PASSWORD_RESET_UPDATE';
export const AUTH_PASSWORD_RESET_UPDATE_SUCCESS = 'AUTH_PASSWORD_RESET_UPDATE_SUCCESS';
export const AUTH_PASSWORD_RESET_UPDATE_ERROR = 'AUTH_PASSWORD_RESET_UPDATE_ERROR';

// Change password
export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';
export const AUTH_CHANGE_PASSWORD_SUCCESS = 'AUTH_CHANGE_PASSWORD_SUCCESS';
export const AUTH_CHANGE_PASSWORD_ERROR = 'AUTH_CHANGE_PASSWORD_ERROR';

export type ChangeEmailPayloadType = {
  newEmail: string;
  onSuccess(): void;
  onError(): void;
};

export type ChangePasswordPayloadType = {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
  onSuccess(): void;
  onError(): void;
};

export interface InitLoginAction {
  type: typeof AUTH_INIT;
  payload: { onSuccess?: any };
}

export interface LoginAction {
  type: typeof AUTH_LOGIN;
  payload: IUserCredentials;
}

export interface TokenLoginAction {
  type: typeof AUTH_LOGIN;
  payload: string;
}

export interface GetFingerprintAction {
  type: typeof AUTH_FINGERPRINT;
  payload: { fingerprint: string };
}

export interface LoginSuccessAction {
  type: typeof AUTH_LOGIN_SUCCESS;
  payload: { user: IUserObject; token: string };
}

export interface LoginErrorAction {
  type: typeof AUTH_LOGIN_ERROR;
  payload: any;
}

export interface LogoutAction {
  type: typeof AUTH_LOGOUT;
}

export interface LogoutSuccessAction {
  type: typeof AUTH_LOGIN_SUCCESS;
  payload: { user: IUserObject; token: string };
}

export interface LogoutErrorAction {
  type: typeof AUTH_LOGIN_ERROR;
  payload: any;
}

export interface RegisterAction {
  type: typeof AUTH_REGISTER;
  payload: {
    user: IUserRegister;
    clientRequest?: IClientRequest;
    answers?: IClientRequestAnswer[];
  };
}

export interface RegisterSuccessAction {
  type: typeof AUTH_REGISTER_SUCCESS;
  payload: { user: IUserObject; token: string };
}

export interface RegisterErrorAction {
  type: typeof AUTH_REGISTER_ERROR;
  payload: any;
}

export interface TokenRefreshAction {
  type: typeof AUTH_TOKEN_REFRESH;
  payload: {
    onSuccess?: any;
  };
}

export interface TokenRefreshSuccessAction {
  type: typeof AUTH_TOKEN_REFRESH_SUCCESS;
  payload: { user: IUserObject; token: string };
}

export interface TokenRefreshErrorAction {
  type: typeof AUTH_TOKEN_REFRESH_ERROR;
  payload?: any;
}

export interface PasswordResetRequestAction {
  type: typeof AUTH_PASSWORD_RESET_REQUEST;
  payload: {
    email: string;
    onSuccess(): void;
  };
}

export interface PasswordResetRequestSuccessAction {
  type: typeof AUTH_PASSWORD_RESET_REQUEST_SUCCESS;
}

export interface PasswordResetRequestErrorAction {
  type: typeof AUTH_PASSWORD_RESET_REQUEST_ERROR;
  payload: any;
}

export interface VerifyEmailAction {
  type: typeof AUTH_VERIFY_EMAIL;
  payload: IVerifyEmail;
}

export interface VerifyEmailErrorAction {
  type: typeof AUTH_VERIFY_EMAIL_ERROR;
  payload: any;
}

export interface VerifyEmailSuccessAction {
  type: typeof AUTH_VERIFY_EMAIL_SUCCESS;
}

// Verify change email types
export interface VerifyChangeEmailAction {
  type: typeof AUTH_VERIFY_CHANGE_EMAIL;
  payload: IVerifyEmail;
}

export interface VerifyChangeEmailErrorAction {
  type: typeof AUTH_VERIFY_CHANGE_EMAIL_ERROR;
  payload: any;
}

export interface VerifyChangeEmailSuccessAction {
  type: typeof AUTH_VERIFY_CHANGE_EMAIL_SUCCESS;
}

export interface ChangeEmailAction {
  type: typeof AUTH_CHANGE_EMAIL;
  payload: ChangeEmailActionTypes;
}

export interface ChangeEmailSuccessAction {
  type: typeof AUTH_CHANGE_PASSWORD_SUCCESS;
}

export interface ChangeEmailErrorAction {
  type: typeof AUTH_CHANGE_PASSWORD_ERROR;
}

export interface VerifyInstallerAction {
  type: typeof AUTH_VERIFY_INSTALLER;
  payload: IVerifyEmail;
}

export interface VerifyInstallerErrorAction {
  type: typeof AUTH_VERIFY_INSTALLER_ERROR;
  payload: any;
}

export interface VerifyInstallerSuccessAction {
  type: typeof AUTH_VERIFY_INSTALLER_SUCCESS;
  payload: boolean;
}

export interface VerifyResetPasswordTokenAction {
  type: typeof VERIFY_PASSWORD_RESET_TOKEN;
  payload: string;
}
export interface VerifyResetPasswordTokenSuccessAction {
  type: typeof VERIFY_PASSWORD_RESET_TOKEN_SUCCESS;
  payload: IPassResetTypes;
}
export interface VerifyResetPasswordTokenErrorAction {
  type: typeof VERIFY_PASSWORD_RESET_TOKEN_ERROR;
  payload: string;
}

export interface PasswordResetConfirmAction {
  type: typeof AUTH_PASSWORD_RESET_CONFIRM;
  payload: string;
}

export interface PasswordResetConfirmSuccessAction {
  type: typeof AUTH_PASSWORD_RESET_CONFIRM_SUCCESS;
}

export interface PasswordResetConfirmErrorAction {
  type: typeof AUTH_PASSWORD_RESET_CONFIRM_ERROR;
  payload: any;
}

export interface PasswordResetUpdateAction {
  type: typeof AUTH_PASSWORD_RESET_UPDATE;
  payload: { token: string; password: string; userId?: string };
}

export interface PasswordResetUpdateSuccessAction {
  type: typeof AUTH_PASSWORD_RESET_UPDATE_SUCCESS;
}

export interface PasswordResetUpdateErrorAction {
  type: typeof AUTH_PASSWORD_RESET_UPDATE_ERROR;
  payload: any;
}

export interface ChangePasswordAction {
  type: typeof AUTH_CHANGE_PASSWORD;
  payload: ChangePasswordPayloadType;
}

export interface ChangePasswordSuccessAction {
  type: typeof AUTH_CHANGE_PASSWORD_SUCCESS;
}

export interface ChangePasswordErrorAction {
  type: typeof AUTH_CHANGE_PASSWORD_ERROR;
}

export type LoginActionTypes =
  | InitLoginAction
  | LoginAction
  | LoginSuccessAction
  | LoginErrorAction
  | TokenLoginAction;

export type TokenRefreshActionTypes =
  | TokenRefreshAction
  | TokenRefreshSuccessAction
  | TokenRefreshErrorAction;

export type LogoutActionTypes = LogoutAction | LogoutSuccessAction | LogoutErrorAction;

export type PasswordResetRequestActionTypes =
  | PasswordResetRequestAction
  | PasswordResetRequestSuccessAction
  | PasswordResetRequestErrorAction;

export type VerifyPasswordResetTypes =
  | VerifyResetPasswordTokenAction
  | VerifyResetPasswordTokenSuccessAction
  | VerifyResetPasswordTokenErrorAction;

export type PasswordResetConfirmActionTypes =
  | PasswordResetConfirmAction
  | PasswordResetConfirmSuccessAction
  | PasswordResetConfirmErrorAction;

export type PasswordResetUpdateActionTypes =
  | PasswordResetUpdateAction
  | PasswordResetUpdateSuccessAction
  | PasswordResetUpdateErrorAction;

export type ChangePasswordActionTypes =
  | ChangePasswordAction
  | ChangePasswordSuccessAction
  | ChangePasswordErrorAction;

export type RegisterActionTypes = RegisterAction | RegisterSuccessAction | RegisterErrorAction;

export type VerifyEmailActionTypes =
  | VerifyEmailAction
  | VerifyEmailErrorAction
  | VerifyEmailSuccessAction;

export type VerifyChangeEmailActionTypes =
  | VerifyChangeEmailAction
  | VerifyChangeEmailErrorAction
  | VerifyChangeEmailSuccessAction;

export type ChangeEmailActionTypes =
  | ChangeEmailAction
  | ChangeEmailSuccessAction
  | ChangeEmailErrorAction;

export type VerifyInstallerActionTypes =
  | VerifyInstallerAction
  | VerifyInstallerErrorAction
  | VerifyInstallerSuccessAction;

export type CommonActionTypes = GetFingerprintAction;

export type AuthActionTypes =
  | LoginActionTypes
  | TokenRefreshActionTypes
  | LogoutActionTypes
  | PasswordResetRequestActionTypes
  | VerifyPasswordResetTypes
  | PasswordResetConfirmActionTypes
  | PasswordResetUpdateActionTypes
  | RegisterActionTypes
  | VerifyEmailActionTypes
  | VerifyChangeEmailActionTypes
  | ChangeEmailActionTypes
  | VerifyInstallerActionTypes
  | CommonActionTypes
  | ChangePasswordActionTypes;
