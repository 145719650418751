import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Form from 'antd/lib/form';
import { FormInstance } from 'antd/lib/form';
import { useSelector } from 'react-redux';
import TextField from 'components/ui/TextField/TextField';
import Checkbox from 'components/ui/Checkbox/Checkbox';
import DropDown from 'components/ui/DropDown/DropDown';
import { ValidationFactory } from 'framework/validations';
import { ICountry } from 'store/intermediate/intermediate.types';
import { countriesListSelector } from 'store/country/country.selectors';

type AddressValue = {
  street: string;
  house: string;
  address2: string;
  city: string;
  postalCode: string;
  country: string;
};

interface Props {
  onChange?: Function;
  value?: AddressValue;
  companyAddress?: any;
  form: FormInstance;
  filteredCountries?: ICountry[];
}

const AddressStep: React.FC<Props> = (props) => {
  const [value, setValue] = useState({
    street: '',
    house: '',
    address2: '',
    city: '',
    postalCode: '',
    country: '',
  } as AddressValue);
  const [useCompanyAddress, setUseCompanyAddress] = useState(false);
  const { t } = useTranslation();
  const countries = useSelector(countriesListSelector);

  const countriesOptions = (props.filteredCountries || countries).map((country) => ({
    value: country.iso31661Alpha3,
    label: t(`common:country:${country.iso31661Alpha3}`),
  }));

  const onInputChange = (value: AddressValue) => {
    setValue(value);
    props.onChange && props.onChange(value);
  };
  const onCheckboxChange = () => {
    if (props.companyAddress) {
      if (!useCompanyAddress) {
        const newValues = {
          ...props.companyAddress,
          house: props.companyAddress?.houseNumber,
          country: props.companyAddress?.country?.iso31661Alpha3,
        };
        props.form.setFieldsValue(newValues);
        onInputChange(newValues);
      } else {
        const newValues = {
          street: '',
          house: '',
          address2: '',
          city: '',
          postalCode: '',
          country: '',
        };
        props.form.setFieldsValue(newValues);
        onInputChange(newValues);
      }
      setUseCompanyAddress(!useCompanyAddress);
    }
  };

  const ADDRESS_FIELDS = [
    {
      label: t('installerFlow:registration:street'),
      name: 'street',
      width: 18,
      value: props.value?.street || value.street,
      rules: [
        ValidationFactory.REQUIRED,
        ValidationFactory.NOT_ONLY_WHITE_SPACE,
        ValidationFactory.INCLUDES_URL,
      ],
    },
    {
      label: t('installerFlow:registration:houseNumber'),
      name: 'house',
      width: 6,
      value: props.value?.house || value.house,
      rules: [
        ValidationFactory.REQUIRED,
        ValidationFactory.NOT_ONLY_WHITE_SPACE,
        ValidationFactory.INCLUDES_URL,
      ],
    },
    {
      label: t('installerFlow:registration:address2'),
      name: 'address2',
      width: 24,
      value: props.value?.address2 || value.address2,
      rules: [ValidationFactory.NOT_ONLY_WHITE_SPACE, ValidationFactory.INCLUDES_URL],
    },
    {
      label: t('installerFlow:registration:postalCode'),
      name: 'postalCode',
      width: 6,
      value: props.value?.postalCode || value.postalCode,
      dependencies: ['country'],
      rules: [
        ValidationFactory.REQUIRED,
        ValidationFactory.POST_CODE,
        ValidationFactory.NOT_ONLY_WHITE_SPACE,
      ],
    },
    {
      label: t('installerFlow:registration:city'),
      name: 'city',
      width: 12,
      value: props.value?.city || value.city,
      rules: [
        ValidationFactory.REQUIRED,
        ValidationFactory.NOT_ONLY_WHITE_SPACE,
        ValidationFactory.INCLUDES_URL,
      ],
    },
    {
      label: t('installerFlow:registration:country'),
      name: 'country',
      width: 6,
      options: countriesOptions,
      value: props.value?.country || value.country,
      rules: [ValidationFactory.REQUIRED],
      dependencies: ['postalCode'],
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      {props.companyAddress && (
        <Col span={24}>
          <Checkbox checked={useCompanyAddress} onChange={onCheckboxChange}>
            Use company address
          </Checkbox>
        </Col>
      )}
      {ADDRESS_FIELDS.map((field) => (
        <Col span={field.width} key={`installer-register-field-${field.name}`}>
          <Form.Item
            key={field.name}
            name={field.name}
            rules={field.rules}
            dependencies={field.dependencies}
          >
            {field.name !== 'country' ? (
              <TextField
                value={field.value}
                label={field.label}
                readOnly={useCompanyAddress}
                onInput={(e) => {
                  const newVal = { ...value, [field.name]: e.currentTarget.value };
                  onInputChange(newVal);
                }}
              />
            ) : (
              <DropDown
                placeholder={field.label}
                options={field.options}
                value={value || null}
                onChange={(newValue) => onInputChange({ ...value, [field.name]: newValue })}
              />
            )}
          </Form.Item>
        </Col>
      ))}
    </Row>
  );
};

export default AddressStep;
