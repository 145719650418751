export const modifiersStyles = {
  today: {
    color: '#1C6EF7',
    backgroundColor: '#ffffff',
  },
  select: {
    color: '#333',
    backgroundColor: '#E8F0FE',
  },
  current: {
    color: '#FFF',
    backgroundColor: '#1C6EF7',
  },
};
