import { all, call, put, takeLatest } from 'redux-saga/effects';
import ProductAPI from 'services/product.service';
import { RequestResult } from 'types/crud-view.types';
import {
  IProduct,
  ProductCreateAction,
  ProductDeleteAction,
  ProductsGetAction,
  ProductUpdateAction,
  PRODUCT_CREATE,
  PRODUCT_UPDATE,
  PRODUCT_DELETE,
  PRODUCTS_GET,
  VendorsGetAction,
  IProductVendor,
  ModelsGetAction,
  IProductModel,
  VENDORS_GET,
  MODELS_GET,
  VendorCreateAction,
  VENDOR_CREATE,
  ModelCreateAction,
  MODEL_CREATE,
} from './product.types';
import {
  getModelsByVendorError,
  getModelsByVendorSuccess,
  getVendorsError,
  getVendorsSuccess,
  productCreateError,
  productCreateSuccess,
  productDeleteError,
  productDeleteSuccess,
  productsGetError,
  productsGetSuccess,
  productUpdateError,
  productUpdateSuccess,
  vendorCreateError,
  vendorCreateSuccess,
} from './product.actions';

export function* createProduct(action: ProductCreateAction) {
  const { product, componentId, onRequestDone } = action.payload;
  try {
    yield call(ProductAPI.createProduct, product, componentId);
    yield put(productCreateSuccess(product));
    onRequestDone && onRequestDone(RequestResult.SUCCESS);
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(productCreateError(message));
    onRequestDone && onRequestDone(RequestResult.ERROR);
  }
}

export function* updateProduct(action: ProductUpdateAction) {
  const { id, body, onRequestDone } = action.payload;
  try {
    const res: IProduct = yield call(ProductAPI.updateProduct, id, body);
    yield put(productUpdateSuccess(res));
    onRequestDone && onRequestDone(RequestResult.SUCCESS);
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(productUpdateError(message));
    onRequestDone && onRequestDone(RequestResult.ERROR);
  }
}

export function* deleteProduct(action: ProductDeleteAction) {
  const { id, onRequestDone } = action.payload;
  try {
    yield call(ProductAPI.deleteProduct, id);
    yield put(productDeleteSuccess(id));
    onRequestDone && onRequestDone(RequestResult.SUCCESS);
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(productDeleteError(message));
    onRequestDone && onRequestDone(RequestResult.ERROR);
  }
}

export function* getProducts(action: ProductsGetAction) {
  try {
    const { componentId, filtersPayload } = action.payload;
    const res: { items: IProduct[]; total: number } = yield call(
      ProductAPI.getProducts,
      componentId,
      filtersPayload,
    );
    yield put(productsGetSuccess(res.items));
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(productsGetError(message));
  }
}

export function* createVendor(action: VendorCreateAction) {
  try {
    const { name, poolId } = action.payload;
    const payload = {
      name,
      pool: {
        id: poolId,
      },
    };
    const res: IProductVendor = yield call(ProductAPI.createVendor, payload);
    yield put(vendorCreateSuccess(res));
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(vendorCreateError(message));
  }
}

export function* createModel(action: ModelCreateAction) {
  try {
    const { name, poolId, vendorId } = action.payload;
    const payload = {
      name,
      pool: {
        id: poolId,
      },
      vendor: {
        id: vendorId,
      },
    };
    const res: IProductModel = yield call(ProductAPI.createModel, payload);
    yield put(vendorCreateSuccess(res));
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(vendorCreateError(message));
  }
}

export function* getProductVendors(action: VendorsGetAction) {
  try {
    const { poolId } = action.payload;
    const res: IProductVendor[] = yield call(ProductAPI.getVendors, poolId);
    yield put(getVendorsSuccess(res));
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(getVendorsError(message));
  }
}

export function* getModelsByVendor(action: ModelsGetAction) {
  try {
    const { vendorId, poolId, category } = action.payload;
    const res: IProductModel[] = yield call(
      ProductAPI.getModelsByVendor,
      poolId,
      vendorId,
      category,
    );
    yield put(getModelsByVendorSuccess(res));
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(getModelsByVendorError(message));
  }
}

export default function* root() {
  yield all([
    takeLatest(PRODUCT_CREATE, createProduct),
    takeLatest(PRODUCT_UPDATE, updateProduct),
    takeLatest(PRODUCT_DELETE, deleteProduct),
    takeLatest(VENDOR_CREATE, createVendor),
    takeLatest(PRODUCTS_GET, getProducts),
    takeLatest(VENDORS_GET, getProductVendors),
    takeLatest(MODELS_GET, getModelsByVendor),
    takeLatest(MODEL_CREATE, createModel),
  ]);
}
