import { produce } from 'immer';
import { RequestState } from 'store/common.types';
import {
  GET_DEFAULT_SETTINGS,
  GET_DEFAULT_SETTINGS_ERROR,
  GET_DEFAULT_SETTINGS_SUCCESS,
  IOrganizationDefaultSettingsState,
  OrganizationDefaultSettingsActionTypes,
  SAVE_DEFAULT_SETTINGS,
  SAVE_DEFAULT_SETTINGS_ERROR,
  SAVE_DEFAULT_SETTINGS_SUCCESS,
} from './organization.types';

const initialState: IOrganizationDefaultSettingsState = {
  defaultSettings: null,
  getDefaultSettingsState: null,
  getDefaultSettingsError: null,

  saveDefaultSettingsState: null,
  saveDefaultSettingsError: null,
};

const OrganizationReducer = (
  state = initialState,
  action: OrganizationDefaultSettingsActionTypes,
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_DEFAULT_SETTINGS:
        draft.getDefaultSettingsState = RequestState.Loading;
        break;
      case GET_DEFAULT_SETTINGS_SUCCESS:
        draft.getDefaultSettingsState = RequestState.Success;
        draft.getDefaultSettingsError = null;
        draft.defaultSettings = action.payload;
        break;
      case GET_DEFAULT_SETTINGS_ERROR:
        draft.getDefaultSettingsState = RequestState.Error;
        draft.getDefaultSettingsError = action.payload;
        break;
      case SAVE_DEFAULT_SETTINGS:
        draft.saveDefaultSettingsState = RequestState.Loading;
        break;
      case SAVE_DEFAULT_SETTINGS_SUCCESS:
        draft.saveDefaultSettingsState = RequestState.Success;
        draft.saveDefaultSettingsError = null;
        draft.defaultSettings = action.payload;
        break;
      case SAVE_DEFAULT_SETTINGS_ERROR:
        draft.saveDefaultSettingsState = RequestState.Error;
        draft.saveDefaultSettingsError = action.payload;
        break;
    }
  });

export default OrganizationReducer;
