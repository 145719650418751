import { produce } from 'immer';
import { DEFAULT_USER_OBJECT } from 'framework/constants';
import { RequestState } from 'store/common.types';
import {
  AUTH_FINGERPRINT,
  AUTH_INIT,
  AUTH_LOGIN,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  AUTH_PASSWORD_RESET_REQUEST_ERROR,
  AUTH_PASSWORD_RESET_REQUEST_SUCCESS,
  AUTH_PASSWORD_RESET_UPDATE_ERROR,
  AUTH_PASSWORD_RESET_UPDATE_SUCCESS,
  AUTH_REGISTER,
  AUTH_REGISTER_ERROR,
  AUTH_REGISTER_SUCCESS,
  AUTH_TOKEN_REFRESH_ERROR,
  AUTH_TOKEN_REFRESH_SUCCESS,
  AUTH_VERIFY_CHANGE_EMAIL,
  AUTH_VERIFY_CHANGE_EMAIL_ERROR,
  AUTH_VERIFY_CHANGE_EMAIL_SUCCESS,
  AUTH_VERIFY_EMAIL,
  AUTH_VERIFY_EMAIL_ERROR,
  AUTH_VERIFY_EMAIL_SUCCESS,
  AUTH_VERIFY_INSTALLER,
  AUTH_VERIFY_INSTALLER_ERROR,
  AUTH_VERIFY_INSTALLER_SUCCESS,
  AuthActionTypes,
  IAuthState,
  VERIFY_PASSWORD_RESET_TOKEN_ERROR,
  VERIFY_PASSWORD_RESET_TOKEN_SUCCESS,
  VERIFY_PASSWORD_RESET_TOKEN,
} from './auth.types';

const initialState: IAuthState = {
  user: DEFAULT_USER_OBJECT,
  token: null,
  // [TODO]: Refactor loading to a more specific one, too general
  loading: true,
  error: null,
  fingerprint: null,

  loginState: RequestState.None,

  customerRegistrationState: RequestState.None,
  customerRegistrationError: null,

  verifyingEmail: false,
  verifyEmailSuccess: null,
  verifyEmailError: null,

  changeEmailLoading: false,
  changeEmailSuccess: false,
  changeEmailError: null,

  verifyingInstaller: false,
  verifyInstallerSuccess: null,
  verifyInstallerError: null,

  passwordResetRequestSuccess: null,
  passwordResetRequestError: null,

  passwordResetUpdateSuccess: null,
  passwordResetUpdateError: null,

  dataByToken: null,
  verifyPasswordResetTokenLoading: false,
  verifyPasswordResetTokenSuccess: null,
  verifyPasswordResetTokenError: null,
};

const LoginReducer = (state = initialState, action: AuthActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case AUTH_INIT:
      case AUTH_LOGIN:
        draft.loading = true;
        draft.loginState = RequestState.Loading;
        break;

      case AUTH_FINGERPRINT:
        draft.fingerprint = action.payload.fingerprint;
        break;

      case AUTH_LOGIN_SUCCESS:
      case AUTH_TOKEN_REFRESH_SUCCESS:
        draft.loading = false;
        draft.loginState = RequestState.Success;
        draft.user = action.payload.user;
        draft.token = action.payload.token;
        break;

      case AUTH_LOGIN_ERROR:
      case AUTH_TOKEN_REFRESH_ERROR:
        draft.loading = false;
        draft.loginState = RequestState.Error;
        draft.error = action.payload;
        break;

      case AUTH_LOGOUT:
        draft.user = DEFAULT_USER_OBJECT;
        draft.error = null;
        draft.token = null;
        draft.customerRegistrationError = null;
        break;

      case AUTH_REGISTER:
        draft.customerRegistrationState = RequestState.Loading;
        draft.customerRegistrationError = null;

        break;
      case AUTH_REGISTER_SUCCESS:
        draft.customerRegistrationState = RequestState.Success;
        draft.user = action.payload.user;
        draft.token = action.payload.token;
        break;
      case AUTH_REGISTER_ERROR:
        draft.customerRegistrationState = RequestState.Error;
        draft.customerRegistrationError = action.payload;
        break;
      case AUTH_VERIFY_EMAIL:
        draft.verifyingEmail = true;
        break;

      case AUTH_VERIFY_EMAIL_SUCCESS:
        draft.verifyingEmail = false;
        draft.verifyEmailSuccess = true;
        break;

      case AUTH_VERIFY_EMAIL_ERROR:
        draft.verifyingEmail = false;
        draft.verifyEmailSuccess = false;
        draft.verifyEmailError = action.payload;
        break;

      case AUTH_VERIFY_CHANGE_EMAIL:
        draft.changeEmailLoading = true;
        break;

      case AUTH_VERIFY_CHANGE_EMAIL_SUCCESS:
        draft.changeEmailLoading = false;
        draft.changeEmailSuccess = true;
        break;

      case AUTH_VERIFY_CHANGE_EMAIL_ERROR:
        draft.changeEmailLoading = false;
        draft.changeEmailSuccess = false;
        draft.changeEmailError = action.payload;
        break;

      case AUTH_VERIFY_INSTALLER:
        draft.verifyingInstaller = true;
        break;

      case AUTH_VERIFY_INSTALLER_SUCCESS:
        draft.verifyingInstaller = false;
        draft.verifyInstallerSuccess = action.payload;
        break;

      case AUTH_VERIFY_INSTALLER_ERROR:
        draft.verifyingInstaller = false;
        draft.verifyInstallerSuccess = false;
        draft.verifyInstallerError = action.payload;
        break;

      case VERIFY_PASSWORD_RESET_TOKEN:
        draft.verifyPasswordResetTokenLoading = true;
        break;

      case VERIFY_PASSWORD_RESET_TOKEN_SUCCESS:
        draft.dataByToken = action.payload;
        draft.verifyPasswordResetTokenSuccess = true;
        draft.verifyPasswordResetTokenLoading = false;
        draft.verifyPasswordResetTokenError = null;
        break;

      case VERIFY_PASSWORD_RESET_TOKEN_ERROR:
        draft.verifyPasswordResetTokenError = action.payload;
        draft.verifyPasswordResetTokenLoading = false;
        draft.verifyPasswordResetTokenSuccess = false;
        break;

      case AUTH_PASSWORD_RESET_REQUEST_SUCCESS:
        draft.passwordResetRequestSuccess = true;
        break;

      case AUTH_PASSWORD_RESET_REQUEST_ERROR:
        draft.passwordResetRequestSuccess = false;
        draft.passwordResetRequestError = action.payload;
        break;

      case AUTH_PASSWORD_RESET_UPDATE_SUCCESS:
        draft.passwordResetUpdateSuccess = true;
        break;

      case AUTH_PASSWORD_RESET_UPDATE_ERROR:
        draft.passwordResetUpdateSuccess = false;
        draft.passwordResetUpdateError = action.payload;
        break;

      default:
        break;
    }
  });

export default LoginReducer;
