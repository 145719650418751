import { produce } from 'immer';
import {
  COUNTRIES_GET,
  COUNTRIES_GET_SUCCESS,
  COUNTRIES_GET_ERROR,
  ICountryState,
  LeadActionTypes,
} from './country.types';
import { RequestState } from '../common.types';

const initialState: ICountryState = {
  countries: [],
  countriesGetState: null,
  countriesGetError: null,
};

const CountryReducer = (state = initialState, action: LeadActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COUNTRIES_GET:
        draft.countriesGetState = RequestState.Loading;
        break;

      case COUNTRIES_GET_ERROR:
        draft.countriesGetState = RequestState.Error;
        draft.countriesGetError = action.payload;
        break;

      case COUNTRIES_GET_SUCCESS:
        draft.countriesGetState = RequestState.Success;
        draft.countriesGetError = null;
        draft.countries = action.payload;
        break;
    }
  });

export default CountryReducer;
