import { createSelector } from 'reselect';
import { IProductState } from './product.types';

export const productSelector = (state: any) => state.products;

export const vendorsGetStateSelector = createSelector(
  productSelector,
  (product: IProductState) => product.vendorsGetState,
);

export const vendorsSelector = createSelector(
  productSelector,
  (product: IProductState) => product.vendors,
);

export const modelsGetStateSelector = createSelector(
  productSelector,
  (product: IProductState) => product.modelsGetState,
);

export const modelsSelector = createSelector(
  productSelector,
  (product: IProductState) => product.models,
);

export const productCreateStateSelector = createSelector(
  productSelector,
  (product: IProductState) => product.productCreateState,
);

export const productsGetStateSelector = createSelector(
  productSelector,
  (product: IProductState) => product.productsGetState,
);

export const productsSelector = createSelector(
  productSelector,
  (product: IProductState) => product.products,
);
