import { produce } from 'immer';
import {
  IRegisterState,
  REGISTER_INSTALLER,
  REGISTER_INSTALLER_ERROR,
  REGISTER_INSTALLER_SUCCESS,
  RegisterActionTypes,
  VALIDATE_INVITE_TOKEN,
  VALIDATE_INVITE_TOKEN_ERROR,
  VALIDATE_INVITE_TOKEN_SUCCESS,
} from './register.types';

const initialState: IRegisterState = {
  loading: false,
  error: null,
  tokenData: null,
};

const RegisterReducer = (state = initialState, action: RegisterActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case REGISTER_INSTALLER:
        draft.loading = true;
        break;

      case REGISTER_INSTALLER_ERROR:
        draft.loading = false;
        draft.error = action.payload;
        break;

      case REGISTER_INSTALLER_SUCCESS:
        draft.loading = false;
        break;

      case VALIDATE_INVITE_TOKEN:
        draft.loading = true;
        break;
      case VALIDATE_INVITE_TOKEN_SUCCESS:
        draft.loading = false;
        draft.tokenData = action.payload;
        break;

      case VALIDATE_INVITE_TOKEN_ERROR:
        draft.loading = false;
        draft.error = 'registrationLinkInvalid';
        break;

      default:
        break;
    }
  });

export default RegisterReducer;
