import { all, call, put, takeLatest } from 'redux-saga/effects';
import ProductComponentAPI from 'services/product-component.service';
import { RequestResult } from 'types/crud-view.types';
import {
  productComponentCreateSuccess,
  productComponentCreateError,
  productComponentDeleteError,
  productComponentDeleteSuccess,
  productComponentsGetError,
  productComponentsGetSuccess,
  productComponentUpdateError,
  productComponentUpdateSuccess,
  productWizardGetError,
  productWizardGetSuccess,
  validateActivePosibilitySuccess,
  validateActivePosibilityError,
} from './product-components.actions';
import {
  IProductComponent,
  ProductComponentCreateAction,
  ProductComponentDeleteAction,
  ProductComponentsGetAction,
  ProductComponentUpdateAction,
  ProductWizardActiveValidationAction,
  ProductWizardGetAction,
  PRODUCT_COMPONENTS_GET,
  PRODUCT_COMPONENT_CREATE,
  PRODUCT_COMPONENT_DELETE,
  PRODUCT_COMPONENT_UPDATE,
  PRODUCT_WIZARD_ACTIVE_VALIDATE,
  PRODUCT_WIZARD_GET,
} from './product-components.types';

export function* createProductComponent(action: ProductComponentCreateAction) {
  try {
    const { product, poolId, onRequestDone } = action.payload;
    const res: IProductComponent = yield call(
      ProductComponentAPI.createProductComponent,
      poolId,
      product,
    );
    if (res) {
      yield put(productComponentCreateSuccess(res));
      onRequestDone && onRequestDone(RequestResult.SUCCESS);
    } else {
      yield put(productComponentCreateError('Empty Response'));
      onRequestDone && onRequestDone(RequestResult.ERROR);
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(productComponentCreateError(message));
  }
}

export function* updateProductComponent(action: ProductComponentUpdateAction) {
  try {
    const { id, body, onRequestDone } = action.payload;
    const res: IProductComponent = yield call(ProductComponentAPI.updateProductComponent, id, body);
    if (res) {
      yield put(productComponentUpdateSuccess(body));
      onRequestDone && onRequestDone(RequestResult.SUCCESS);
    } else {
      yield put(productComponentUpdateError('Empty Response'));
      onRequestDone && onRequestDone(RequestResult.ERROR);
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(productComponentUpdateError(message));
  }
}

export function* deleteProductComponent(action: ProductComponentDeleteAction) {
  try {
    const { id, onRequestDone } = action.payload;
    const res: IProductComponent = yield call(ProductComponentAPI.deleteProductComponent, id);
    if (res) {
      yield put(productComponentDeleteSuccess(id));
      onRequestDone && onRequestDone(RequestResult.SUCCESS);
    } else {
      yield put(productComponentDeleteError('Empty Response'));
      onRequestDone && onRequestDone(RequestResult.ERROR);
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(productComponentDeleteError(message));
  }
}

export function* getProductComponents(action: ProductComponentsGetAction) {
  try {
    const { poolId, search } = action.payload;
    const res: { items: IProductComponent[]; total: number } = yield call(
      ProductComponentAPI.getProductComponents,
      poolId,
      search,
    );
    if (res) {
      yield put(productComponentsGetSuccess(res));
    } else {
      yield put(productComponentsGetError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(productComponentsGetError(message));
  }
}

export function* getProductWizard(action: ProductWizardGetAction) {
  try {
    const res: { items: IProductComponent[]; total: number } = yield call(
      ProductComponentAPI.getProductWizard,
      action.payload,
    );
    yield put(productWizardGetSuccess(res.items));
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(productWizardGetError(message));
  }
}

export function* validateActivePosibility(action: ProductWizardActiveValidationAction) {
  if (!action.payload) {
    yield put(validateActivePosibilitySuccess(false));
  } else {
    try {
      const res: { isActivable: boolean } = yield call(
        ProductComponentAPI.validateActive,
        action.payload,
      );
      yield put(validateActivePosibilitySuccess(res.isActivable));
    } catch (err: any) {
      const { response } = err;
      const { data } = response;
      const message = Array.isArray(data.message) ? data.message[0] : data.message;
      yield put(validateActivePosibilityError(message));
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(PRODUCT_COMPONENT_CREATE, createProductComponent),
    takeLatest(PRODUCT_COMPONENT_UPDATE, updateProductComponent),
    takeLatest(PRODUCT_COMPONENT_DELETE, deleteProductComponent),
    takeLatest(PRODUCT_COMPONENTS_GET, getProductComponents),
    takeLatest(PRODUCT_WIZARD_GET, getProductWizard),
    takeLatest(PRODUCT_WIZARD_ACTIVE_VALIDATE, validateActivePosibility),
  ]);
}
