import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/lib/typography';
import wonderWoman from 'assets/images/wonder-woman.svg';
import NextButton from 'components/ui/NextButton/NextButton';
import styles from './OfferWelcomeStep.module.sass';

export enum OfferWelcomeStepType {
  HAS_OFFERS = 'hasOffers',
  HAS_ONLY_COMMENTS = 'hasOnlyComments',
}

interface IOfferWelcomeStepProps {
  type: OfferWelcomeStepType;
  onNextClick(): void;
}

const OfferWelcomeStep: React.FC<IOfferWelcomeStepProps> = ({
  type = OfferWelcomeStepType.HAS_OFFERS,
  onNextClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        {t(`customerFlow:offerReview:offerArrivedPage:${type}:headline`)}
      </h3>

      <Typography.Paragraph className={styles.text}>
        {t(`customerFlow:offerReview:offerArrivedPage:${type}:text`)}
      </Typography.Paragraph>

      <NextButton className={styles.link} onClick={onNextClick}>
        {t(`customerFlow:offerReview:offerArrivedPage:${type}:next`)}
      </NextButton>

      <img className={styles.womanImage} src={wonderWoman} alt="Wonder woman" />
    </div>
  );
};

export default OfferWelcomeStep;
