import React from 'react';
import { Col, Collapse, Form, Row, Upload } from 'antd';
import { Editor } from 'react-draft-wysiwyg';

import { useTranslation } from 'react-i18next';
import editorStyle from 'components/ui/EditorContainer/Editor.module.sass';
import TextField from 'components/ui/TextField/TextField';
import Button from 'components/ui/Button/Button';
import DropDown from 'components/ui/DropDown/DropDown';
import { ValidationFactory } from 'framework/validations';

import { getEditorValue } from 'utils/product';
import styles from './PDFTemplate.module.sass';

const { Panel } = Collapse;

const blockTypeOptions = ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'];
const inlineOptions = ['bold', 'italic', 'underline'];
const toolbarOptions = [
  'inline',
  'blockType',
  'fontSize',
  'fontFamily',
  'colorPicker',
  'link',
  'emoji',
  'list',
  'textAlign',
];

const editorProps = {
  toolbar: {
    options: toolbarOptions,
    inline: {
      className: editorStyle.inline,
      options: inlineOptions,
    },
    blockType: {
      className: editorStyle.dropdown,
      options: blockTypeOptions,
      dropdownClassName: editorStyle.dropdownContainer,
    },
    fontSize: {
      className: editorStyle.dropdown,
      dropdownClassName: editorStyle.dropdownContainer,
    },
    fontFamily: {
      className: editorStyle.dropdown,
      dropdownClassName: editorStyle.dropdownContainer,
    },
  },
  wrapperClassName: editorStyle.wrapper,
  editorClassName: editorStyle.textAreaWrapper,
  toolbarClassName: editorStyle.toolbar,
};

export const FormBody: React.FC = () => {
  const { t } = useTranslation();
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess();
    }, 0);
  };

  const typeOptions = [{ value: 'order-template', label: 'Order Template' }];

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item name="name" rules={[ValidationFactory.REQUIRED]}>
            <TextField label="Template Name" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="type" rules={[ValidationFactory.REQUIRED]}>
            <DropDown style={{ width: '100%' }} placeholder="Template Type" options={typeOptions} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="files"
        rules={[ValidationFactory.REQUIRED]}
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Upload
          maxCount={1}
          name="templateFile"
          accept=".jpeg,.png,.jpg"
          listType="picture"
          customRequest={dummyRequest}
        >
          <Button type="primary" size="small">
            Upload Template File
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item name="textBoxes">
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const boxes: any[] = getFieldValue('textBoxes');
            return (
              <Collapse ghost className={styles.collapse} defaultActiveKey={['0', '1']}>
                {['textBox1', 'textBox2'].map((_item: string, index: number) => (
                  <Panel
                    header={`${t('intermediate:myProjects:pdfTemplates:editor:textBox')} ${
                      index + 1
                    }`}
                    key={index}
                  >
                    <Form.Item
                      name={['textBoxes', index]}
                      rules={[ValidationFactory.EDITOR_REQUIRED]}
                    >
                      <Editor
                        {...editorProps}
                        initialContentState={getEditorValue(boxes ? boxes[index] : null)}
                      />
                    </Form.Item>
                  </Panel>
                ))}
              </Collapse>
            );
          }}
        </Form.Item>
      </Form.Item>
    </>
  );
};
