import React, { useEffect, useState } from 'react';
import Form from 'antd/lib/form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Descriptions } from 'antd';
import InstallationCommentsQuestion from 'components/questions/InstallationCommentsQuestion/InstallationCommentsQuestion';
import Checkbox from 'components/ui/Checkbox/Checkbox';
import { ValidationFactory } from 'framework/validations';
import clientRequestStyles from 'pages/client-request/ClientRequestPage/ClientRequestPage.module.sass';
import { ClientRequestFields, QuestionKey } from 'store/client-request/client-request.types';

import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import { servicesFeeDescriptionGet } from 'store/intermediate/intermediate.actions';
import { servicesFeeDescriptionSelector } from 'store/intermediate/intermediate.selectors';
import { ICustomDocument } from 'store/intermediate/intermediate.types';
import { formatPrice } from 'utils/currencyUtils';
import styles from './ContactStep.module.sass';
interface IStepProps {
  form?: any;
  clientRequest?: any;
}

const ContactStep: React.FC<IStepProps> = (props: IStepProps) => {
  const { t } = useTranslation();
  const questionCommentsRef = React.useRef<HTMLDivElement>(null);
  const [processingFeeAgreement, setProcessingFeeAgreement] = useState(false);
  const clientRequest = useSelector(currentClientRequestSelector);
  const servicesFeeDescription: ICustomDocument | null = useSelector(
    servicesFeeDescriptionSelector,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (clientRequest?.poolId) dispatch(servicesFeeDescriptionGet(clientRequest.poolId));
  }, [clientRequest, dispatch]);
  return (
    <Form
      className={clientRequestStyles.questionForm}
      layout="vertical"
      form={props.form}
      name="request-availability-form"
    >
      <Form.Item className={clientRequestStyles.question} name={ClientRequestFields.Annotation}>
        <InstallationCommentsQuestion
          clientRequest={props.clientRequest}
          ref={questionCommentsRef}
        />
      </Form.Item>
      {clientRequest?.pool?.isServiceFeeEnabled && (
        <Form.Item
          rules={[
            ValidationFactory.CHECKBOX_VALIDATION(
              processingFeeAgreement,
              t(`customerFlow:questions:${QuestionKey.ProcessingFee}:error`),
            ),
          ]}
          name={QuestionKey.ProcessingFee}
        >
          <Checkbox
            checked={processingFeeAgreement}
            onChange={() => setProcessingFeeAgreement(!processingFeeAgreement)}
          >
            {servicesFeeDescription?.text}
          </Checkbox>
          <Descriptions className={styles.serviceFeeDescription}>
            <Descriptions.Item label={t(`customerFlow:questions:servicesFee:title`)}>
              {formatPrice(clientRequest?.pool?.serviceFeeCost)}
              {clientRequest?.pool?.country?.currencySymbol}
            </Descriptions.Item>
          </Descriptions>
        </Form.Item>
      )}
    </Form>
  );
};

export default ContactStep;
