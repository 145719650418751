import React, { useCallback, useEffect, useMemo } from 'react';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import draftToHtml from 'draftjs-to-html';
import { Button } from 'antd';
import InstallerCard from 'components/ui/InstallerCard/InstallerCard';

import OfferAdditionalInfoCard from 'components/ui/OfferAdditionalInfoCard/OfferAdditionalInfoCard';
import { formatDate } from 'framework/dateUtils';
import { getDataForInstallerCard, getDataForInstallerOrganization } from 'framework/helpUtils';
import { DEFAULT_DATE_FORMAT } from 'framework/constants';
import { IOffer, OfferState } from 'store/offer/offer.types';
import { getOfferFiles } from 'store/offer/offer.actions';
import { getFileLink } from 'store/client-request/client-request.actions';
import { IFile, MediaCategoryType } from 'store/client-request/client-request.types';
import { currentOfferFilesSelector } from 'store/offer/offer.selectors';

import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import { formatCurrency } from 'utils/currencyUtils';
import { checkIsPayByIntermediate } from 'utils/offerHelpers';
import styles from './OfferReviewStep.module.sass';
import SectionText from './SectionText';

const getFile = (requestFiles: any): IFile | null => {
  const hasFiles = Array.isArray(requestFiles) && requestFiles.length;
  if (hasFiles) {
    //TODO: CRAFT-975 Get this from the backend
    return (
      requestFiles.find(
        (i: any) => i.document.category === MediaCategoryType.InstallationOrderConfirmation,
      ) ||
      requestFiles.find((i: any) => i.document.category === MediaCategoryType.OfferHomeCheck) ||
      requestFiles.find((i: any) => i.document.category === MediaCategoryType.OfferBidInstaller) ||
      requestFiles.find(
        (i: any) => i.document.category === MediaCategoryType.OfferHomeCheckInstaller,
      )
    )?.document as IFile;
  } else {
    return null;
  }
};

interface IOfferReviewStepProps {
  price?: string;
  offer: IOffer;

  onConfirm(): void;
}

const OfferReviewStep: React.FC<IOfferReviewStepProps> = ({ offer, onConfirm }) => {
  const { state, offerAnnotation } = offer; // the STATE can be tenderAccepted or tenderAcceptedHomeCheck
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const requestFiles = useSelector(currentOfferFilesSelector);
  const clientRequest = useSelector(currentClientRequestSelector);

  const offerPDF = getFile(requestFiles);
  const offerData = {
    costEstimate: _get(offer, 'costEstimate', 'n/a'),
    costEstimateHomeCheck: _get(offer, 'costEstimateHomeCheck', 'n/a'),
    organizationName: _get(offer, 'installerUser.organization.name', 'n/a'),
    date: formatDate(_get(offer, 'createdAt', null) as Date, DEFAULT_DATE_FORMAT),
  };

  const userData = useMemo(() => {
    const contactPerson = clientRequest?.pool?.contactPersons?.find((item) => item.active);
    return contactPerson ? contactPerson : getDataForInstallerCard(offer);
  }, [offer, clientRequest]);

  const organizationData = getDataForInstallerOrganization(offer);
  const organizationLogo = _get(offer, 'installerUser.organization.logo', '');
  useEffect(() => {
    if (offer.id) {
      dispatch(getOfferFiles(offer.id));
    }
  }, []); // eslint-disable-line

  const htmlAnnotation = offerAnnotation ? draftToHtml(JSON.parse(offerAnnotation)) : '';

  const handleDownloadPDF = useCallback(() => {
    const name = offerPDF?.name || '';
    dispatch(getFileLink(name));
  }, [offerPDF]); // eslint-disable-line

  const renderTop = () => {
    const price =
      state === OfferState.TenderAcceptedHomeCheck ||
      state === OfferState.TenderAcceptedRemoteHomeCheck
        ? offer.costEstimateHomeCheck
        : offer.costEstimate;
    const vatAmount = ((offer?.vatRate || 0) / 100) * price!;
    const isPayByIntermediate = checkIsPayByIntermediate(offer, clientRequest);
    return (
      <div className={styles.top}>
        <div className={styles.topText}>
          <SectionText
            title={t(`customerFlow:offerReview:offerReviewPage:title:${state}`)}
            text={t(`customerFlow:offerReview:offerReviewPage:titleDescription:${state}`)}
            small={false}
          />
          {!isPayByIntermediate && (
            <>
              <SectionText
                title={t(`customerFlow:offerReview:offerReviewPage:priceTitle:${state}`, {
                  price: formatCurrency(+price! + vatAmount, offer.currencySymbol),
                })}
              />
              <div className={styles.price}>
                <span>{t('common:net')}:</span> {formatCurrency(price, offer.currencySymbol)}
                <span>
                  {' '}
                  {t('common:vat')}:({offer?.vatRate || 0}%):
                </span>{' '}
                {formatCurrency(vatAmount, offer.currencySymbol)}
              </div>
            </>
          )}
        </div>
        {userData && <InstallerCard user={userData} organizationLogo={organizationLogo} />}
      </div>
    );
  };

  const offerDetailsList = [
    {
      title: t('customerFlow:offerReview:offerReviewPage:organizationTitle'),
      value: organizationData?.name,
    },
    {
      title: t('customerFlow:offerReview:offerReviewPage:dateTitle'),
      value: offerData.date,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {renderTop()}

        <div className={styles.detailsContainer}>
          {offerDetailsList.map((block, index: number) => (
            <div key={`block-key-value-${index}`}>
              <span className={styles.detailsTitle}>{block.title}:</span>
              <span className={styles.detailsText}>{block.value}</span>
            </div>
          ))}
          {/* will change customer annotations logic when pool owner annotations will be implement*/}
          {offer.poolOwnerAnnotation && <OfferAdditionalInfoCard offer={offer} />}
        </div>

        <SectionText
          title={t('customerFlow:offerReview:offerReviewPage:detailsTitle')}
          text={t(`customerFlow:offerReview:offerReviewPage:detailsText:${state}`)}
        />

        {htmlAnnotation && (
          <div className={styles.additionalInformation}>
            <h4 className={styles.titleSmall}>
              {t('customerFlow:offerReview:offerReviewPage:additionalInformation')}
            </h4>
            <div dangerouslySetInnerHTML={{ __html: htmlAnnotation }} />
          </div>
        )}

        {offerPDF ? (
          <Button type="primary" size="small" onClick={handleDownloadPDF}>
            {t('common:buttons:downloadPDF')}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default OfferReviewStep;
