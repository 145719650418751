/* eslint-disable react/display-name */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import Notification from 'components/ui/Notification/Notification';
import { ClientRequestFields } from 'store/client-request/client-request.types';
import styles from './InstallationCommentsQuestion.module.sass';
interface IQuestionProps {
  onCheckClick?(): void;
  onChange?: Function;
  value?: any;
  id?: string;
  clientRequest?: any;
}

const InstallationCommentsQuestion = React.forwardRef<HTMLDivElement, IQuestionProps>(
  (props, forwardRef) => {
    const { t } = useTranslation();
    let validityDays = props?.clientRequest?.pool?.requestValidity;
    const Content = () => (
      <span>
        {t(`customerFlow:questions:${ClientRequestFields.Annotation}:texts:commentNotification1`)}
        {t(
          `customerFlow:questions:${ClientRequestFields.Annotation}:texts:commentNotification2`,
        )}{' '}
        {t(`customerFlow:questions:${ClientRequestFields.Annotation}:texts:commentNotification3`)}
      </span>
    );

    return (
      <div className={styles.container} ref={forwardRef} id={props.id}>
        <Notification
          className={styles.notification}
          size="small"
          type="info"
          content={<Content />}
        />

        <div className={styles.title}>
          {t(`customerFlow:questions:${ClientRequestFields.Annotation}:texts:commentTitle`)}
        </div>

        <div className={styles.description}>
          {t(`customerFlow:questions:${ClientRequestFields.Annotation}:question`)}
        </div>
        <Input.TextArea
          value={props.value}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            props.onChange && props.onChange(e.target.value);
          }}
        />
        {props.clientRequest?.pool?.requestValidity && (
          <div className={styles.description}>
            {t(`customerFlow:questions:${ClientRequestFields.Annotation}:texts:commentText1`)}{' '}
            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
              {validityDays && validityDays > 0
                ? validityDays
                : t(`customerFlow:questions:${ClientRequestFields.Annotation}:texts:unlimited`)}
            </span>
            {t(`customerFlow:questions:${ClientRequestFields.Annotation}:texts:commentText2`)}
          </div>
        )}
      </div>
    );
  },
);

export default InstallationCommentsQuestion;
