import React, { JSX } from 'react';
import _find from 'lodash/find';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { transformWallboxes } from 'framework/dataTransformations';
import {
  IClientRequest,
  QuestionCalculatedKey,
  QuestionKey,
  QuestionsType,
} from 'store/client-request/client-request.types';
import { wallboxesModels } from 'store/client-request/client-request.selectors';
import styles from './ClientRequestKeyValue.module.sass';
import BlockKeyValue from '../BlockKeyValue/BlockKeyValue';

interface ISurfaceInterfaceRow {
  surfaceCode: string;
  distance: number;
}

interface ISurfaceInterface {
  value: ISurfaceInterfaceRow[];
}

export interface IClientRequestKeyValue {
  questionKey: QuestionKey | QuestionCalculatedKey | string;
  clientRequest: IClientRequest;
  uiSchema?: any;
}

/**
 * This component is responsible to:
 *  -- find the correct answer in the clientRequest Object
 *  -- format the field
 *  -- pass it to the BlockKeyValue component (reformatted and translated)
 */
const ClientRequestKeyValue: React.FC<IClientRequestKeyValue> = ({
  questionKey,
  clientRequest,
  uiSchema,
}) => {
  const { t } = useTranslation(['installerFlow']);

  const wallboxesRaw = useSelector(wallboxesModels);
  const wallboxes = transformWallboxes(wallboxesRaw);
  const questionType: QuestionsType = uiSchema.options.format;
  const ANSWER_NOT_AVAILABLE = t('installerFlow:tenderDetails:answerNotAvailable');

  const renderAffectedSurface = (answerValue: ISurfaceInterface) => {
    if (!answerValue || !answerValue.value) {
      return <span>{ANSWER_NOT_AVAILABLE}</span>;
    }

    return (
      <div>
        {Array.isArray(answerValue.value) ? (
          answerValue.value.map((row) => (
            <div key={`row-${row.surfaceCode}`}>
              <p>
                {row.distance}m -{' '}
                {t(
                  uiSchema.options.surfaceFields.options.find(
                    (item: any) => item.value === row.surfaceCode,
                  ).label,
                )}
              </p>
            </div>
          ))
        ) : (
          <span>{answerValue.value}</span>
        )}
      </div>
    );
  };

  const renderQuestion = () => {
    let answer: any = _find(clientRequest.answers, (answer) => answer.questionKey === questionKey);
    let answerTranslated: string | JSX.Element = ANSWER_NOT_AVAILABLE;

    const label = t(uiSchema.options.question);
    const question = t(uiSchema.options.question);
    const answerValue = answer?.answerValue.value;

    switch (questionType) {
      case QuestionsType.RadioQuestion: {
        const possibleOptions = uiSchema.options.radioFields.options;
        const selectedOption = possibleOptions.find((option: any) => option.value === answerValue);
        answerTranslated = `${t(selectedOption?.label)}  ${t(selectedOption?.message)}`;
        break;
      }

      case QuestionsType.RadioWithCustomInputQuestion: {
        const possibleOptions = uiSchema.options.radioFields.options;
        const selectedOption = possibleOptions.find(
          (option: any) => option.value === answer?.answerValue?.value?.selectedOption,
        );
        const customInput = answerValue?.text;
        answerTranslated =
          customInput || `${t(selectedOption?.label)}  ${t(selectedOption?.message)}`;
        break;
      }

      case QuestionsType.SingleInputQuestion: {
        answerTranslated = answerValue;
        break;
      }

      case QuestionsType.surfaceQuestion:
        answerTranslated = renderAffectedSurface(answer?.answerValue as ISurfaceInterface);
        break;

      case QuestionsType.MultipleInputQuestion:
        if (typeof answerValue === 'object') {
          answerTranslated = (
            <div>
              {Object.keys(answerValue).map((key: any, index: any) => {
                const inputInfo = uiSchema.options?.multipleInputFields?.inputs?.find(
                  (item: any) => item.key === key,
                );

                const booleanInput = uiSchema.options.multipleInputFields?.booleanOption;
                const inputTitle = inputInfo ? inputInfo.title : booleanInput.title;
                return (
                  answerValue[key] && (
                    <div key={`row-${key}`}>
                      <p>
                        {t(inputTitle)} : {t(answerValue[key])}
                      </p>
                    </div>
                  )
                );
              })}
            </div>
          );
        }

        break;

      case QuestionsType.ChargingStatioQuestion: {
        if (!wallboxes) break;
        const possibleOptions = uiSchema.options.chargingStationFields.options;
        const selectedOption = possibleOptions.find(
          (option: any) => option.value === answerValue?.answer,
        );
        const company = answerValue?.compan || ANSWER_NOT_AVAILABLE;
        const model = answerValue?.model || ANSWER_NOT_AVAILABLE;
        const models = wallboxes[company]?.models || ANSWER_NOT_AVAILABLE;
        const wallboxLabel = wallboxes[company]?.label || ANSWER_NOT_AVAILABLE;
        const modelLabel =
          model !== 'model' && Array.isArray(models)
            ? models.find((item) => item.value === model)?.label
            : null;

        answerTranslated = (
          <div>
            <div key={`row-${answer}`}>
              <p>{t(selectedOption?.label)}</p>
            </div>
            {wallboxLabel && (
              <div key={`row-company`}>
                <p>
                  {t('common:texts:company')} : {wallboxLabel}
                </p>
              </div>
            )}
            {modelLabel && (
              <div key={`row-model`}>
                <p>
                  {t('common:texts:model')} : {modelLabel}
                </p>
              </div>
            )}
            {answerValue?.otherCompany && (
              <div key={`row-otherCompany`}>
                <p>
                  {t('common:texts:company')} : {answerValue.otherCompany}
                </p>
              </div>
            )}
            {answerValue?.otherModel && (
              <div key={`row-otherModel`}>
                <p>
                  {t('common:texts:model')} : {answerValue.otherModel}
                </p>
              </div>
            )}
          </div>
        );
        break;
      }
    }

    return answer || answerTranslated ? (
      <BlockKeyValue
        className={styles.block}
        label={label}
        question={question}
        value={answerTranslated}
      />
    ) : null;
  };

  return renderQuestion();
};

export default ClientRequestKeyValue;
