import React, { useCallback, useState } from 'react';
import Typography from 'antd/lib/typography';
import { useDispatch, useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import { Tooltip } from 'antd';
import DropZone from 'components/ui/DropZone/DropZone';
import FilesList from 'components/ui/FilesList/FilesList';

import {
  currentClientRequestFilesSelector,
  currentClientRequestSelector,
} from 'store/client-request/client-request.selectors';
import { MediaCategoryType } from 'store/client-request/client-request.types';
import { uploadFile, deleteFile } from 'store/client-request/client-request.actions';
import { calculateProgress, calculateTimeLeft } from 'utils/fileUploadUtils';

import styles from './UploadDocumentQuestion.module.sass';

interface IUploadDocumentStepProps {
  descriptionClassName?: string;
  category: MediaCategoryType;
  description?: string;
  title?: string;
  className?: string;
  placeholder?: string;
  fotoWizard?: boolean;
  token?: string;
  requestId?: string;
  acceptedFiles?: string;
  accept?: { [key: string]: string[] };
}

const UploadDocumentQuestion: React.FC<IUploadDocumentStepProps> = (props) => {
  const {
    category,
    description,
    title,
    descriptionClassName,
    className,
    placeholder,
    fotoWizard,
    token,
    requestId,
    ...rest
  } = props;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);

  const request = useSelector(currentClientRequestSelector);
  const requestFiles = useSelector(currentClientRequestFilesSelector);
  const uploadedFiles = requestFiles[category] || [];
  let timeStartTemp: any = null;

  const onFileUploadProgress = useCallback((progressEvent: any) => {
    const percentCompleted = calculateProgress(progressEvent.loaded, progressEvent.total);

    if (!timeStartTemp) {
      timeStartTemp = progressEvent.timeStamp; // eslint-disable-line
    } else {
      const timeLeft = calculateTimeLeft(
        timeStartTemp,
        progressEvent.timeStamp,
        progressEvent.loaded,
        progressEvent.total,
      );

      setTimeLeft(timeLeft);
    }

    if (percentCompleted === 100) {
      setLoading(false);
      setProgress(0);
    } else {
      setProgress(percentCompleted);
    }
  }, []);

  const onFileDropAccepted = useCallback(
    (files: File[]) => {
      const id = requestId || request?.id;
      if (files[0] && id) {
        setLoading(true);
        dispatch(
          uploadFile(
            files[0],
            id,
            category,
            {},
            onFileUploadProgress,
            {},
            (error: any) => console.log(error),
            fotoWizard,
            token,
          ),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [request, category, fotoWizard, dispatch, onFileUploadProgress],
  );

  const onFileDeleteClick = useCallback(
    (fileName: string, fileId: string) => {
      dispatch(deleteFile(fileName, fileId, category));
    },
    [category, dispatch],
  );

  const id = category.replace(/:/i, '-');

  return (
    <div className={styles.container} id={id}>
      {title && (
        <div className={styles.title}>
          <Typography.Text>
            {title}
            {description && (
              <Tooltip
                title={description}
                placement="bottom"
                className={clsx(styles.description, { descriptionClassName })}
              >
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            )}
          </Typography.Text>
        </div>
      )}
      <div>
        <DropZone
          acceptedFiles="JPG, PDF, PNG. "
          loading={loading}
          progress={progress}
          timeLeft={timeLeft}
          placeholder={placeholder ? placeholder : ''}
          onFileDropAccepted={onFileDropAccepted}
          {...rest}
        />
        <FilesList className={className} files={uploadedFiles} onFileDelete={onFileDeleteClick} />
      </div>
    </div>
  );
};

export default UploadDocumentQuestion;
