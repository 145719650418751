import React, { useMemo } from 'react';
import { Form, FormInstance, Typography } from 'antd';
import { useSelector } from 'react-redux';
import draftToHtml from 'draftjs-to-html';
import _ from 'lodash';
import { ValidationFactory } from 'framework/validations';
import { productWizardSelector } from 'store/product-component/product-component.selector';

import { IProduct } from 'store/product/product.types';
import styles from './ProductSelection.module.sass';
import ProductWizardOptions from '../../components/WizardProducts/ProductWizardOptions';

interface IProductSelectionProps {
  form: FormInstance;
  initialProducts?: IProduct[];
}

const ProductSelection: React.FC<IProductSelectionProps> = ({ form, initialProducts }) => {
  const wizard = useSelector(productWizardSelector);
  const getDescription = (value: string) => {
    return draftToHtml(JSON.parse(value));
  };

  // Products need to be sorted based on the wizard products, so the default selection works as intended.
  const sortedProducts = useMemo(
    () =>
      _.sortBy(initialProducts, (_product) => {
        const index = _.findIndex(
          wizard,
          (_wizard) =>
            _wizard.products?.some((_wizardProduct) => _wizardProduct.id === _product.id) || false,
        );
        return index === -1 ? Infinity : index; // If the element is not found, push it to the end
      }),
    [wizard, initialProducts],
  );

  return (
    <div className={styles.container}>
      <Form form={form} scrollToFirstError initialValues={{ products: sortedProducts }}>
        {wizard.map((component, index) => (
          <div key={component.id}>
            <Typography.Title level={3}>{component.name}</Typography.Title>
            <div dangerouslySetInnerHTML={{ __html: getDescription(component.description!) }} />
            <Form.Item name={['products', index]} noStyle rules={[ValidationFactory.REQUIRED]}>
              <ProductWizardOptions products={component.products!} />
            </Form.Item>
          </div>
        ))}
      </Form>
    </div>
  );
};

export default ProductSelection;
