import React, { useEffect, useMemo, useState } from 'react';
import { Divider, Form, FormInstance, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from 'components/ui/Button/Button';
import { ValidationFactory } from 'framework/validations';
import { IPool } from 'store/intermediate/intermediate.types';
import {
  getVendors,
  getModelsByVendor,
  vendorCreate,
  modelCreate,
} from 'store/product/product.actions';
import { modelsSelector, vendorsSelector } from 'store/product/product.selector';

import { IProductModel, IProductVendor } from 'store/product/product.types';
import styles from '../../Products.module.sass';

interface IOrganizationProps {
  pool: IPool;
  form?: FormInstance;
}

const key = 'intermediate:myProjects:product:form:organization:';

const Organization: React.FC<IOrganizationProps> = ({ pool, form }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const vendors: IProductVendor[] = useSelector(vendorsSelector);
  const models: IProductModel[] = useSelector(modelsSelector);

  const [selectedVendor, setSelectedVendor] = useState<string>();
  const [selectedCategory, setSelectedCategory] = useState<string>();

  const [name, setName] = useState('');

  const getVendorOptions = useMemo(
    () =>
      vendors.map((vendor) => ({
        value: vendor.id!,
        label: vendor.name,
      })),
    [vendors],
  );

  const getModelOptions = useMemo(
    () =>
      models.map((model) => ({
        value: model.id!,
        label: model.name,
      })),
    [models],
  );

  const CategoryOptions = [
    { label: 'Wallbox', value: 'wallbox' },
    { label: 'Charging Stations', value: 'chargingStations' },
  ];

  useEffect(() => {
    dispatch(getVendors(pool.id));
  }, [dispatch, pool.id]);

  useEffect(() => {
    const vendorId = form?.getFieldValue('vendor')?.id || selectedVendor;
    if (vendorId) {
      dispatch(getModelsByVendor(pool.id, vendorId, selectedCategory));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVendor, selectedCategory]);

  const fields = [
    {
      name: 'category',
      fieldName: 'category',
      options: CategoryOptions,
      onChange: (value: string) => setSelectedCategory(value),
    },
    {
      name: ['vendor', 'id'],
      fieldName: 'vendor',
      options: getVendorOptions,
      onChange: (value: string) => setSelectedVendor(value),
      withAddOption: true,
    },
    {
      name: ['model', 'id'],
      fieldName: 'model',
      options: getModelOptions,
      withAddOption: true,
    },
  ];

  const addItem = (fieldName: string) => {
    if (name.trim()) {
      fieldName === 'vendor'
        ? dispatch(vendorCreate(name, pool.id))
        : dispatch(modelCreate(name, pool.id, selectedVendor!));
    }
  };

  return (
    <>
      {fields.map((field) => (
        <Form.Item
          name={field.name}
          key={field.name[0]}
          rules={[ValidationFactory.REQUIRED]}
          label={t(`${key}${field.fieldName}:label`)}
        >
          <Select
            options={field.options}
            onChange={field.onChange}
            onDropdownVisibleChange={(visible) => visible && setName('')}
            placeholder={t(`${key}${field.fieldName}:placeholder`)}
            dropdownRender={(menu) => (
              <>
                {menu}
                {field.withAddOption && (
                  <div className={styles.addOption}>
                    <Divider style={{ margin: '8px 0' }} />
                    <Input
                      style={{ width: '90%' }}
                      placeholder="Please enter item name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                    <Button
                      type="link"
                      onClick={() => addItem(field.fieldName)}
                      className={styles.addItemButton}
                    >
                      Add item
                    </Button>
                  </div>
                )}
              </>
            )}
          />
        </Form.Item>
      ))}
    </>
  );
};

export default Organization;
