import React, { FC, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import draftToHtml from 'draftjs-to-html';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import DataProtectionModal from 'components/static/DataProtectionModal/DataProtectionModal';
import TermsOfUseModal from 'components/static/TermsOfUseModal/TermsOfUseModal';
import TermsConditionsForCustomer from 'components/static/TermsConditionsForCustomer/TermsConditionsForCustomer';
import { MODAL_DATA_PROTECTION_KEY, MODAL_TERMS_KEY } from 'framework/constants';
import {
  landingPageSelector,
  legalDocumentsSelector,
} from 'store/intermediate/intermediate.selectors';
import { countriesGet } from 'store/country/country.actions';
import RegistrationForm from './RegistrationForm';
import styles from '../../RegistrationPage.module.sass';

interface IRegistrationStepProps {
  onSubmit: () => void;
}

enum ModalTypes {
  MODAL_DATA_PROTECTION_KEY = 'MODAL_DATA_PROTECTION_KEY',
  MODAL_TERMS_KEY = 'MODAL_TERMS_KEY',
}

const RegistrationStep: FC<IRegistrationStepProps> = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const landingPage = useSelector(landingPageSelector);
  const legalDocuments = useSelector(legalDocumentsSelector);

  const [legalModal, setLegalModal] = useState<ModalTypes | null>();
  const { i18n } = useTranslation();

  const landing = useMemo(() => {
    if (!landingPage || (!landingPage.media.length && landingPage.text === 'null')) return null;

    return {
      coverImage: landingPage.media[0]?.mediaUrl,
      content: draftToHtml(JSON.parse(landingPage.text)),
    };
  }, [landingPage]);

  useEffect(() => {
    dispatch(countriesGet());
  }, []); // eslint-disable-line

  const modalProps = {
    width: 800,
    termsComponent: <TermsConditionsForCustomer language={i18n.language} />,
    destroyOnClose: true,
    onOk: () => setLegalModal(null),
    onCancel: () => setLegalModal(null),
    legalDocuments,
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        {landing && (
          <Col xs={24} md={12}>
            <div className={clsx(styles.container, styles.landing)}>
              {landing.coverImage && (
                <img src={landing.coverImage} className={styles.landingImage} alt="landing" />
              )}
              {landing.content && (
                <p
                  className={styles.landingContent}
                  dangerouslySetInnerHTML={{ __html: landing.content }}
                />
              )}
            </div>
          </Col>
        )}
        <Col xs={24} md={landing ? 12 : 24}>
          <RegistrationForm
            openModal={(modalType: ModalTypes) => setLegalModal(modalType)}
            legalDocuments={legalDocuments}
            onSubmit={onSubmit}
          />
        </Col>
      </Row>

      <DataProtectionModal
        {...modalProps}
        open={legalModal === MODAL_DATA_PROTECTION_KEY}
        customer
      />
      <TermsOfUseModal {...modalProps} open={legalModal === MODAL_TERMS_KEY} customer />
    </>
  );
};

export default RegistrationStep;
