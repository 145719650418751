import config from 'config';
import { IProduct, IProductVendor } from 'store/product/product.types';
import { ISearchDto } from 'store/common.types';
import HttpService from './http.service';

class ProductAPI extends HttpService {
  createProduct = (product: IProduct, componentId: string) => {
    return this.post(`product/component/${componentId}`, product);
  };
  createVendor = (vendor: IProductVendor) => {
    return this.post('product-vendors', vendor);
  };
  createModel = (model: any) => {
    return this.post('product-models', model);
  };
  getProducts = (componentId: string, search?: ISearchDto) => {
    const params = {
      sortDirections: search?.sortDirection,
      sortFields: search?.sortField,
      ...search?.filters,
    };
    return this.get(`product/component/${componentId}`, params);
  };
  updateProduct = (productId: string, body: IProduct) => {
    return this.put(`product/${productId}`, body);
  };
  deleteProduct = (productId: string) => {
    return this.delete(`product/${productId}`);
  };
  getVendors = (poolId: string) => {
    return this.get(`product-vendors/pool/${poolId}`);
  };
  getModelsByVendor = (poolId: string, vendorId: string, category?: string) => {
    const params = {
      vendor: vendorId,
      category: category,
    };
    return this.get(`product-models/pool/${poolId}`, params);
  };
}

export default new ProductAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
