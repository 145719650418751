import React from 'react';
import { Checkbox, Col, Form, Input, notification, Row, Typography } from 'antd';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'components/ui/DatePicker/DatePicker';
import RadioGroup from 'components/ui/RadioGroup/RadioGroup';
import { ValidationFactory } from 'framework/validations';
import { WallboxDeliveryStatus } from 'store/client-request/client-request.types';
import { clientRequestUpdate } from 'store/client-request/client-request.actions';

import {
  selectDeliveryDateModalState,
  deliveryDateUpdated,
  DeliveryDateModalPages,
} from 'store/delivery-date/delivery-date.module';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import { isIntermediateManagerSelector } from 'store/auth/auth.selectors';
import ModalContainer from '../ModalContainer';

interface DeliveryDatePayload {
  delivered: string;
  deliveryDate?: Moment;
  isDeliveryDate: boolean;
  trackLink?: string;
  trackNumber?: string;
}

const DeliveryDateModal: React.FC = () => {
  const [form] = Form.useForm();
  const state = useSelector(selectDeliveryDateModalState);
  const clientRequest = useSelector(currentClientRequestSelector);
  const dispatch = useDispatch();
  const wallboxDeliveryDate = clientRequest?.wallboxDeliveryDate;
  const isIntermediateManager = useSelector(isIntermediateManagerSelector);

  const { t } = useTranslation();

  const radioOptions = [
    { value: 'yes', label: t('installerFlow:orderDetails:offerDateModal:yes') },
    { value: 'no', label: t('installerFlow:orderDetails:offerDateModal:no') },
  ];

  const formatPayload = (values: DeliveryDatePayload) => {
    const { isDeliveryDate, delivered, deliveryDate, trackLink, trackNumber } = values;
    const payload: any = {
      wallboxDeliveryStatus: WallboxDeliveryStatus.Defined,
      wallboxDeliveryDate: null,
      trackingValue: null,
    };
    if (delivered === 'yes') {
      return { wallboxDeliveryStatus: WallboxDeliveryStatus.DeliveredManually };
    }
    if (!isDeliveryDate) {
      payload.wallboxDeliveryDate = deliveryDate?.format('YYYY-MM-DD');
    } else {
      payload.trackingValue = trackLink || trackNumber;
    }
    return payload;
  };
  const navigate = useNavigate();

  const handleSuccess = () => {
    if (!clientRequest?.id) return;
    notification.success({ message: 'Delivery date saved!' });
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (state.page === DeliveryDateModalPages.JourneySelection) {
      navigate(`/customer/client-request/${clientRequest.id}/questionnaire`);
    }
    handleCancel();
  };

  const handleError = (message: string) => notification.error({ message });
  const handleSubmit = async () => {
    if (!clientRequest?.step) return;
    const values = await form.validateFields();
    if (!values) return;
    const payload = {
      ...formatPayload(values),
    };
    if (state.page === DeliveryDateModalPages.JourneySelection) {
      payload.products = [];
      payload.step = clientRequest.step + 1;
    }
    dispatch(clientRequestUpdate(clientRequest?.id!, payload, handleSuccess, handleError));
  };

  const handleCancel = () => {
    dispatch(deliveryDateUpdated());
    form.resetFields();
  };

  const modalProps = {
    open: state.isModal,
    onCancel: handleCancel,
    onSubmit: handleSubmit,
    closable: false,
  };

  return (
    <ModalContainer {...modalProps} destroyOnClose>
      <Typography.Title level={2}>{t('customerFlow:deliveryDate:title')}</Typography.Title>
      <Form form={form} layout="vertical">
        <Typography.Title level={5}>
          {t('customerFlow:deliveryDate:question:delivered')}
        </Typography.Title>
        <Form.Item name="delivered" initialValue="yes">
          <RadioGroup options={radioOptions} />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const isDelivery = getFieldValue('isDeliveryDate');
            const delivered = getFieldValue('delivered') === 'no';
            return (
              delivered && (
                <>
                  <Typography.Title level={5}>
                    {t('customerFlow:deliveryDate:question:deliveryDate')}
                  </Typography.Title>
                  <Form.Item
                    name="deliveryDate"
                    initialValue={wallboxDeliveryDate ? moment(wallboxDeliveryDate) : null}
                    rules={isDelivery ? [] : [ValidationFactory.REQUIRED]}
                  >
                    <DatePicker
                      style={{ width: '100%', borderRadius: '5px' }}
                      disabled={isDelivery}
                      placeholder={t('customerFlow:deliveryDate:question:datePicker')}
                    />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    initialValue={false}
                    name="isDeliveryDate"
                    valuePropName="checked"
                  >
                    <Checkbox>
                      <Typography.Title level={5}>
                        {t('customerFlow:deliveryDate:question:isDeliveryDate')}
                      </Typography.Title>
                    </Checkbox>
                  </Form.Item>
                  {isIntermediateManager && (
                    <>
                      <Row>
                        <Col span={11}>
                          <Form.Item
                            name="trackNumber"
                            rules={
                              isDelivery
                                ? [
                                    ValidationFactory.ONE_FILLED('trackLink', 'track number'),
                                    ValidationFactory.ONLY_ONE_FILLED('trackLink'),
                                  ]
                                : []
                            }
                            dependencies={['trackLink']}
                          >
                            <Input
                              disabled={!isDelivery}
                              placeholder={t('customerFlow:deliveryDate:question:trackNumber')}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <div style={{ textAlign: 'center' }}>or</div>
                        </Col>
                        <Col span={11}>
                          <Form.Item
                            name="trackLink"
                            rules={
                              isDelivery
                                ? [
                                    ValidationFactory.ONE_FILLED('trackNumber', 'track link'),
                                    ValidationFactory.ONLY_ONE_FILLED('trackNumber'),
                                    ValidationFactory.IS_URL,
                                  ]
                                : []
                            }
                            dependencies={['trackNumber']}
                          >
                            <Input
                              disabled={!isDelivery}
                              placeholder={t('customerFlow:deliveryDate:question:trackLink')}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )
            );
          }}
        </Form.Item>
      </Form>
    </ModalContainer>
  );
};

export default DeliveryDateModal;
