import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import DropDown from 'components/ui/DropDown/DropDown';
import Rectangle from 'components/layout/Rectangle/Rectangle';
import BlockKeyValue from 'components/ui/BlockKeyValue/BlockKeyValue';
import { ClientRequestFields, IClientRequest } from 'store/client-request/client-request.types';
import { getWallboxes } from 'store/client-request/client-request.actions';
import { bundlesGet } from 'store/bundle/bundle.actions';
import { currentBundlesSelector } from 'store/bundle/bundle.selectors';

import { isCustomerSelector } from 'store/auth/auth.selectors';
import AnswersView from './AnswersView';
import { BundleView } from './BundleView/BundleView';
import styles from '../TenderDetailsPage.module.sass';

interface ISpecificationWidgetProps {
  clientRequest: IClientRequest;
  selectedBundle?: string;
  onBundleSelect?: (bundleId: string) => void;
}

const SpecificationWidget: React.FC<ISpecificationWidgetProps> = ({
  clientRequest,
  selectedBundle,
  onBundleSelect,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const bundles = useSelector(currentBundlesSelector);

  const isCustomer = useSelector(isCustomerSelector);

  useEffect(() => {
    dispatch(getWallboxes());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (clientRequest?.pool?.id && onBundleSelect) {
      dispatch(bundlesGet(clientRequest.pool.id));
    }
  }, []); //eslint-disable-line

  const isBundleSelectVisible = bundles && bundles.length && onBundleSelect;

  const getBundleDropDown = () => {
    return bundles
      .filter((item) => item.active)
      .map((item) => ({ label: item.name, value: item.id }));
  };

  const assignedBundle = useMemo(() => {
    if (clientRequest.bundle && !selectedBundle) {
      return clientRequest.bundle;
    }
    return bundles.find((item) => item.id === selectedBundle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientRequest]);

  return (
    <Rectangle title={t('installerFlow:tenderDetails:specification')}>
      <div className={styles.specifications}>
        <AnswersView clientRequest={clientRequest} />

        {!!isBundleSelectVisible && (
          <div className={clsx(styles.specificationsBlock, styles.bundleBlock)}>
            <span className={styles.specificationsBlockTitle}>
              {t('installerFlow:tenderDetails:productBundle')}
            </span>
            <DropDown
              onChange={onBundleSelect}
              value={selectedBundle || clientRequest?.bundle?.id}
              options={getBundleDropDown()}
              placeholder={t('installerFlow:tenderDetails:selectBundle')}
            />
          </div>
        )}

        {(clientRequest?.bundle || selectedBundle) && !isCustomer && (
          <BundleView bundle={assignedBundle} />
        )}

        {clientRequest.annotation && (
          <div className={styles.specificationsBlock}>
            <span className={styles.specificationsBlockTitle}>
              {t('installerFlow:tenderDetails:annotationFromCustomer')}
            </span>
            <div className={styles.specificationsList}>
              <BlockKeyValue
                label={t(`customerFlow:questions:${ClientRequestFields.Annotation}:label`)}
                question={t(`customerFlow:questions:${ClientRequestFields.Annotation}:question`)}
                value={clientRequest.annotation}
              />
            </div>
          </div>
        )}
      </div>
    </Rectangle>
  );
};

export default SpecificationWidget;
