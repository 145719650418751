import React from 'react';
import clsx from 'clsx';

import { LeftOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import styles from './BackButton.module.sass';

interface IBackButtonProps {
  className?: string;
  children: string;
  onClick?: () => void;
  disabled?: boolean;
}

const BackButton: React.FC<IBackButtonProps & ButtonProps> = ({
  children,
  className,
  onClick,
  disabled,
  size = 'small',
  type = 'text',
  ...rest
}) => {
  const buttonProps = {
    onClick,
    className: clsx(styles.button, className),
    disabled,
    size,
    type,
    ...rest,
  };

  return (
    <Button {...buttonProps} icon={<LeftOutlined />}>
      <span className={styles.text}>{children}</span>
    </Button>
  );
};

export default BackButton;
