import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Navigate, useParams } from 'react-router-dom';
import Loader from 'components/ui/Loader/Loader';
import { tokenLogin } from 'store/auth/auth.actions';
import { isAuthorizedSelector } from 'store/auth/auth.selectors';

const TokenLoginPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const isAuthorized = useSelector(isAuthorizedSelector);
  const navigate = useNavigate();

  const { token } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      // localStorage.setItem(AUTH_TOKEN_KEY, token);
      dispatch(tokenLogin(token));
    } else {
      setIsLoading(false);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (isAuthorized) {
      navigate('/');
    }
  }, [isAuthorized]); // eslint-disable-line

  if (isLoading) {
    return <Loader />;
  }

  return <Navigate to="/" />;
};

export default TokenLoginPage;
