import { createSelector } from 'reselect';
import { IOrganizationDefaultSettingsState } from './organization.types';

export const organizationSelector = (state: any) => state.organization;

export const defaultSettingsSelector = createSelector(
  organizationSelector,
  (organization: IOrganizationDefaultSettingsState) => organization.defaultSettings,
);

export const getDefaultSettingsState = createSelector(
  organizationSelector,
  (organization: IOrganizationDefaultSettingsState) => organization.getDefaultSettingsState,
);
