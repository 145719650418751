import React, { ReactElement } from 'react';
import clsx from 'clsx';

import { Typography } from 'antd';
import DOWNLOAD_ICON from 'assets/icons/direct-download.svg';
import { FileType } from 'store/client-request/client-request.types';
import styles from './PreviewDocument.module.sass';
import Icon, { IconSize } from '../Icon/Icon';
import { FileTypesFactory } from '../FileCard/FileCard';

interface IPreviewDocumentProps {
  className?: string;

  mime: FileType;
  name: string;
  originalName?: string;
  size?: number;
  onDownloadClick: () => void;
}

function PreviewDocument(props: IPreviewDocumentProps): ReactElement {
  const { originalName, size, mime, className, onDownloadClick } = props;
  const fileTypeIcon = FileTypesFactory[mime!] || FileTypesFactory[FileType.PDF];
  const containerClassName = clsx(styles.container, className);

  const fileSize = size && Number.isFinite(size) ? `${(size / 1000).toFixed(1)}kb` : '';

  return (
    <div className={containerClassName} onClick={onDownloadClick}>
      <div className={styles.icons}>
        <div className={styles.document}>
          <Icon icon={fileTypeIcon} className={styles.icon} size={IconSize.LARGE} />
        </div>
        <div className={styles.download}>
          <Icon icon={DOWNLOAD_ICON} className={styles.icon} size={IconSize.MEDIUM} />
        </div>
      </div>
      <div className={styles.info}>
        <Typography.Paragraph className={styles.text}>{originalName}</Typography.Paragraph>
        <Typography.Paragraph className={styles.text}>{fileSize}</Typography.Paragraph>
      </div>
    </div>
  );
}

export default PreviewDocument;
