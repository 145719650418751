import { createSelector } from 'reselect';
import { IOfferState } from './offer.types';

export const offerSelector = (state: any) => state.offer;

//Offer Selector
export const currentOfferSelector = createSelector(
  offerSelector,
  (offer: IOfferState) => offer.offer,
);

//Create Offer Selectors
export const offerCreateErrorSelector = createSelector(
  offerSelector,
  (offer: IOfferState) => offer.createError,
);

export const offerCreateStateSelector = createSelector(
  offerSelector,
  (offer: IOfferState) => offer.offerCreateState,
);

// Found Offers Selector
export const offersListSelector = createSelector(
  offerSelector,
  (offer: IOfferState) => offer.offersList,
);

export const offersListStateSelector = createSelector(
  offerSelector,
  (offer: IOfferState) => offer.offersListState,
);

//Found Offer Selector
export const foundOfferSelector = createSelector(
  offerSelector,
  (offer: IOfferState) => offer.foundOffers.items,
);

export const foundPaginatedOfferSelector = createSelector(
  offerSelector,
  (offer: IOfferState) => offer.foundOffers,
);

export const offerFindStateSelector = createSelector(
  offerSelector,
  (offer: IOfferState) => offer.offerFindState,
);

export const offerFindErrorSelector = createSelector(
  offerSelector,
  (offer: IOfferState) => offer.foundError,
);

//Get Offer Selectors
export const offerGetErrorSelector = createSelector(
  offerSelector,
  (offer: IOfferState) => offer.getError,
);

export const offerGetStateSelector = createSelector(
  offerSelector,
  (offer: IOfferState) => offer.offerGetState,
);

//Transition Offer Selectors
export const offerTransitionErrorSelector = createSelector(
  offerSelector,
  (offer: IOfferState) => offer.transitionError,
);

export const offerTransitionStateSelector = createSelector(
  offerSelector,
  (offer: IOfferState) => offer.offerTransitionState,
);

export const currentOfferFilesSelector = createSelector(
  offerSelector,
  (clientRequest: IOfferState) => clientRequest.files,
);

export const offerFilesStateSelector = createSelector(
  offerSelector,
  (state: IOfferState) => state.filesGetState,
);

export const offersInProgressCountSelector = createSelector(
  offerSelector,
  (offer: IOfferState) => offer.offersInProgressCount,
);

export const offerDocsForInstallerSelector = createSelector(
  offerSelector,
  (offer: IOfferState) => ({
    docsForInstallerState: offer.docsForInstallerState,
    docsForInstaller: offer.docsForInstaller,
  }),
);
