import { DefaultOptionType } from 'antd/es/select';
import { SupportedLanguages } from 'framework/constants';
import { TSupportedLanguagesKey } from 'types/language.types';
export const getLanguageKey = (value: SupportedLanguages) =>
  Object.keys(SupportedLanguages).find(
    (key) => SupportedLanguages[key as TSupportedLanguagesKey] === value,
  ) as TSupportedLanguagesKey;

export const keyBasedLanguageSelectorOptions: DefaultOptionType[] = Object.keys(
  SupportedLanguages,
).map((key: string) => ({
  value: key as TSupportedLanguagesKey,
  label: key,
}));
export const languageSelectorOptions: DefaultOptionType[] = Object.values(SupportedLanguages).map(
  (value: string) => ({
    value: value,
    label: value,
  }),
);
export const headerLanguageSelectorOptions: DefaultOptionType[] = Object.keys(
  SupportedLanguages,
).map((key: string) => ({
  value: SupportedLanguages[key as TSupportedLanguagesKey],
  label: key,
}));
