import { IUserObject } from 'store/auth/auth.types';
import { IClientRequest } from 'store/client-request/client-request.types';
import { RequestState } from 'store/common.types';
import { IOffer } from 'store/offer/offer.types';

export interface IOrder {
  id?: string;
  declineReason?: string;
  displayId?: string;
  state?: OrderState;
  createdAt?: Date;
  deletedDate?: Date;
  updatedAt?: Date;
  clientRequest?: IClientRequest;
  installerUser?: IUserObject;
  installerOrganization?: any;
  offer?: IOffer;
}

export enum OrderState {
  Created = 'created',
  Confirmed = 'confirmed',
}

export enum OrderConfirmationType {
  Confirmed = 'confirmed',
  ConfirmedRemote = 'confirmedRemote',
}

export interface IOrdersState {
  linkOrganizationToRequestState: RequestState | null;
  linkOrganizationToRequestError: string | null;

  order: IOrder | null;
  getOrderByIdState: RequestState | null;
  getOrderByIdError: string | null;

  acceptOrderState: RequestState | null;
  acceptOrderError: string | null;

  rejectOrderState: RequestState | null;
  rejectOrderError: string | null;
}

export const LINK_ORGANIZATION_TO_REQUEST = 'LINK_ORGANIZATION_TO_REQUEST';
export const LINK_ORGANIZATION_TO_REQUEST_SUCCESS = 'LINK_ORGANIZATION_TO_REQUEST_SUCCESS';
export const LINK_ORGANIZATION_TO_REQUEST_ERROR = 'LINK_ORGANIZATION_TO_REQUEST_ERROR';

export const GET_ORDER_BY_ID = 'GET_ORDER_BY_ID';
export const GET_ORDER_BY_ID_SUCCESS = 'GET_ORDER_BY_ID_SUCCESS';
export const GET_ORDER_BY_ID_ERROR = 'GET_ORDER_BY_ID_ERROR';

export const ACCEPT_ORDER = 'ACCEPT_ORDER';
export const ACCEPT_ORDER_SUCCESS = 'ACCEPT_ORDER_SUCCESS';
export const ACCEPT_ORDER_ERROR = 'ACCEPT_ORDER_ERROR';

export const REJECT_ORDER = 'REJECT_ORDER';
export const REJECT_ORDER_SUCCESS = 'REJECT_ORDER_SUCCESS';
export const REJECT_ORDER_ERROR = 'REJECT_ORDER_ERROR';

export interface LinkOrganizationToRequestAction {
  type: typeof LINK_ORGANIZATION_TO_REQUEST;
  payload: {
    clientRequestId: string;
    organizationId: string;
  };
}

export interface LinkOrganizationToRequestSuccessAction {
  type: typeof LINK_ORGANIZATION_TO_REQUEST_SUCCESS;
  payload: IClientRequest;
}

export interface LinkOrganizationToRequestErrorAction {
  type: typeof LINK_ORGANIZATION_TO_REQUEST_ERROR;
  payload: any;
}

export interface AcceptOrderAction {
  type: typeof ACCEPT_ORDER;
  payload: {
    id: string;
    token?: string;
    type?: OrderConfirmationType;
    onSuccess?: (id: string) => void;
  };
}

export interface AcceptOrderSuccessAction {
  type: typeof ACCEPT_ORDER_SUCCESS;
  payload: IOrder;
}

export interface AcceptOrderErrorAction {
  type: typeof ACCEPT_ORDER_ERROR;
  payload: any;
}

export interface GetOrderByIdAction {
  type: typeof GET_ORDER_BY_ID;
  payload: string;
}

export interface GetOrderByIdSuccessAction {
  type: typeof GET_ORDER_BY_ID_SUCCESS;
  payload: IOrder;
}

export interface GetOrderByIdErrorAction {
  type: typeof GET_ORDER_BY_ID_ERROR;
  payload: any;
}
export interface RejectOrderAction {
  type: typeof REJECT_ORDER;
  payload: {
    id: string;
    token?: string;
  };
}

export interface RejectOrderSuccessAction {
  type: typeof REJECT_ORDER_SUCCESS;
  payload: IOrder;
}

export interface RejectOrderErrorAction {
  type: typeof REJECT_ORDER_ERROR;
  payload: any;
}

export type OrdersActionTypes =
  | LinkOrganizationToRequestAction
  | LinkOrganizationToRequestSuccessAction
  | LinkOrganizationToRequestErrorAction
  | GetOrderByIdAction
  | GetOrderByIdSuccessAction
  | GetOrderByIdErrorAction
  | AcceptOrderAction
  | AcceptOrderSuccessAction
  | AcceptOrderErrorAction
  | RejectOrderAction
  | RejectOrderSuccessAction
  | RejectOrderErrorAction;
