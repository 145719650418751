import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

import styles from './JourneyOptions.module.sass';
import { AnswerType } from './JourneyOptions';
import { journeyOptions } from '../../constants';

interface OptionCardProps {
  type: AnswerType;
  title: string;
  image?: any;
  description: string;
  onClick: () => void;
  checked: boolean;
}

const OptionCard: React.FC<OptionCardProps> = (props) => {
  const { title, image, description, type, onClick, checked } = props;
  const { t } = useTranslation();

  const journeyList = type === AnswerType.Installation ? journeyOptions.slice(1) : journeyOptions;

  return (
    <div
      className={clsx(styles.container, { [styles.checked]: checked })}
      onClick={() => onClick()}
    >
      <img className={styles.img} src={image} alt="Wallbox" />
      <Typography.Title level={5} className={styles.title}>
        {title}
      </Typography.Title>
      <Typography.Paragraph>{description}</Typography.Paragraph>
      <div>
        {journeyList.map((item) => (
          <div key={item.title} className={styles.option}>
            <div className={styles.icon}>
              <img src={item.icon} alt="optionIcon" />
            </div>
            {t(`customerFlow:productWizard:journeyPoints:${item.title}`)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OptionCard;
