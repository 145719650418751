import React, { useMemo, useState } from 'react';
import { JsonForms } from '@jsonforms/react';
import { Form, Modal, ModalProps, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from 'components/ui/Button/Button';
import { customRenderer } from 'framework/jsonForm';
import { transformAnswersToFormData } from 'framework/dataTransformations';
import { IClientRequestAnswer } from 'store/client-request/client-request.types';
import { questionnaireSelector } from 'store/intermediate/intermediate.selectors';

interface IEditAnswerValueProps {
  answer: IClientRequestAnswer | null;
  onConfirm: (newAnswer: IClientRequestAnswer) => void;
}

const EditAnswerValue: React.FC<IEditAnswerValueProps & ModalProps> = ({
  answer,
  onConfirm,
  ...rest
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<IClientRequestAnswer | null>(answer);
  const questionnaire = useSelector(questionnaireSelector);

  const handleUpdateValue = (data: any) => {
    setValue(data);
  };

  const questionSchema = useMemo(() => {
    if (!questionnaire && !answer) return undefined;
    let answerProperties: any;
    questionnaire.steps?.forEach((step: any) => {
      step.children.forEach((substep: any) => {
        if (Object.keys(substep.schema.properties).includes(answer?.questionKey!)) {
          answerProperties = substep;
        }
      });
    });
    if (answerProperties) {
      const { schema, uiSchema } = answerProperties;

      const filteredSchema = {
        ...schema,
        properties: { [answer?.questionKey!]: schema.properties[answer?.questionKey!] },
      };
      const filteredUiSchema = {
        ...uiSchema,
        elements: [uiSchema.elements.find((item: any) => item.scope.includes(answer?.questionKey))],
      };
      return {
        schema: filteredSchema,
        uischema: filteredUiSchema,
        initialValue: transformAnswersToFormData(filteredSchema, [answer!]),
      };
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaire, answer]);

  const handleConfirm = () => (value ? onConfirm(value) : null);

  return (
    <Modal {...rest} destroyOnClose width="80vh">
      <div>
        {questionSchema ? (
          <Form>
            <JsonForms
              schema={questionSchema.schema}
              uischema={questionSchema.uischema}
              data={questionSchema.initialValue}
              renderers={customRenderer}
              onChange={({ data }) => handleUpdateValue(data)}
            />
          </Form>
        ) : (
          <Skeleton />
        )}
        <Button type="primary" size="small" onClick={handleConfirm}>
          {t(`common:buttons:confirm`)}
        </Button>
      </div>
    </Modal>
  );
};

export default EditAnswerValue;
