import React from 'react';
import { useTranslation } from 'react-i18next';
import { IOffer } from 'store/offer/offer.types';

import styles from '../../ClientRequestPage.module.sass';

interface IAnnotationsRowProps {
  offer: IOffer;
}

export const AnnotationsRow: React.FC<IAnnotationsRowProps> = ({ offer }) => {
  const { t } = useTranslation();

  const annotations: Array<keyof IOffer> = ['homeCheckAnnotation', 'annotation'];

  const renderData = (key: keyof IOffer) => (
    <div className={styles.annotation}>
      <div className={styles.docTitle}>{t(`intermediate:myProjects:offer:${key}`)}</div>
      {offer[key]}
    </div>
  );
  return (
    <>
      {annotations.map((item) => (
        <React.Fragment key={item}>{offer[item] && renderData(item)}</React.Fragment>
      ))}
    </>
  );
};
